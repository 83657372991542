import { Keyboard } from "@capacitor/keyboard";
import {
  IonButton,
  IonContent,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
  IonNote,
  IonToast,
  IonAlert,
} from "@ionic/react";
import React, { useState, FormEvent, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isPlatform } from "@ionic/core";

import { Store } from "../../store/store.model";
import { reloadAgentProfile, logout } from "../../store/session";
import { checkIfEmailExists } from "../../onboarding/components/api";
import { logEvent, addDataToIdentity } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { get } from "lodash";
import { updateProfile } from "./api";
import { ProfileUpdateStepProps } from "./interface";
import { PROFILE_UPDATE_LOCAL_STORAGE_ITEMS } from "./constants";

const EMAIL_ALREADY_EXISTS = "Email already exists.";

const UpdateEmail: React.FC<ProfileUpdateStepProps> = ({ stepFinished }) => {
  const { firebaseAuth, profile } = useSelector(
    (state: Store) => state.session
  );
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [checkingEmail, setCheckingEmail] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const dispatch = useDispatch();
  const captchaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (agent && agent.email) {
      setEmail(agent.email);
    }
  }, [agent]);

  useEffect(() => {
    if (firebaseAuth && emailError.isError) {
      firebaseAuth.initRecaptchaVerifier(
        "recaptcha-container",
        captchaRef.current
      );
    }
  }, [firebaseAuth, emailError.isError]);

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    if (updatingData) return;
    if (isPlatform("capacitor")) Keyboard.hide();
    try {
      setUpdatingData(true);
      const { exists } = await checkIfEmailExists(email);

      if (!email) {
        setUpdatingData(false);
        setEmailError({
          isError: true,
          message: "Please enter your email address",
        });
        return;
      }
      if (exists) {
        setUpdatingData(false);
        setEmailError({ isError: true, message: EMAIL_ALREADY_EXISTS });
        return;
      }

      setUpdatingData(false);
      setShowConfirmationModal(true);

      logEvent(USER_EVENTS.PROFILE_CHANGE_COMPLETED, {
        type: "EMAIL",
      });
    } catch (error) {
      setUpdatingData(false);
      setEmailError({
        isError: true,
        message: "Error occurred while updating email address.",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const onProcessed = async () => {
    try {
      setUpdatingData(true);
      addDataToIdentity(agent.userId, {
        email: email,
        phone: agent.phone,
        type: "AGENT",
      });

      await updateProfile({ email });
      localStorage.setItem(
        PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_EMAIL_CHANGED,
        "true"
      );
      dispatch(reloadAgentProfile(false));
      setUpdatingData(false);
      stepFinished();
      dispatch(logout());
    } catch (error) {
      setUpdatingData(false);
      setEmailError({
        isError: true,
        message: "Error occurred while checking email address.",
      });
    }
  };

  const onDismiss = () => {
    setEmailError({ isError: false, message: "" });
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
  };

  const onEmailChange = (event: Event) => {
    setEmail(event.target?.["value"]);
  };

  return (
    <IonContent>
      <IonToast
        isOpen={
          emailError.isError && emailError.message !== EMAIL_ALREADY_EXISTS
        }
        onDidDismiss={onDismiss}
        color="danger"
        duration={4000}
        message={emailError.message}
        position="top"
      />
      <IonAlert
        mode="ios"
        isOpen={
          emailError.isError && emailError.message === EMAIL_ALREADY_EXISTS
        }
        backdropDismiss={false}
        message="The email you tried to submit is already in use by another account. Please make sure you are submitting an email you own and that you typed it correctly."
        header="This email is already in use"
        buttons={[
          {
            text: "Try Again",
            handler: onDismiss,
          },
        ]}
      />
      <IonAlert
        mode="ios"
        isOpen={showConfirmationModal}
        backdropDismiss={false}
        header="You need to login again"
        message="To validate your new email, you will need to logout and login again. This step is necessary for us to proper configure your account."
        buttons={[
          {
            text: "Cancel",
            handler: closeModal,
          },
          {
            text: "Confirm",
            handler: onProcessed,
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What&#39;s your new email?</h4>
                </div>

                <div>
                  <IonLabel className="form-label">Email Address</IonLabel>
                  <IonItem lines="none" className="form-item-wrapper">
                    <IonInput
                      name="email"
                      className="form-input"
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onIonChange={onEmailChange}
                      disabled={updatingData}
                      autofocus
                      required
                    />
                    {checkingEmail && <IonSpinner name="crescent" />}
                  </IonItem>
                </div>

                {!agent && emailExists && (
                  <IonItem lines="none">
                    <IonNote slot="end" color="danger">
                      This email already exists !
                    </IonNote>
                  </IonItem>
                )}
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={updatingData || agent.email == email || !email}
            onClick={onNext}
          >
            Continue
            {updatingData && (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            )}
          </IonButton>
          <div ref={captchaRef}></div>
        </div>
      </div>
    </IonContent>
  );
};

export { UpdateEmail };
