import React, { Fragment, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonModal,
  IonToast,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { AppModal } from "../../layout/modal";
import {
  getOrWatchCurrentLocation,
  LocationAlertType,
  LocationAlertTypeReason,
} from "@app/common/location";
import { TimecardUpload } from "./timecardUpload";
import { TimecardList } from "./timecardList";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { Store } from "../../store/store.model";
import { actionRecordShiftTimeFailure } from "../../store/ongoingShifts";
import { getAlertText } from "../../utils/locationHelper";

import { SelectedFile, TimecardProps } from "./model";

const Timecard: React.FC<TimecardProps> = ({
  shift,
  editMode,
  updateShift,
}) => {
  const [showTimecardModal, setShowTimecardModal] = useState<boolean>(false);
  const [showUploadTimecardModal, setShowUploadTimecardModal] =
    useState<boolean>(false);
  const [showImageConfirmation, setShowImageConfirmation] =
    useState<boolean>(false);
  const [locationError, setLocationError] = useState<LocationAlertType>();
  const [showCDPH530RequiredImageModal, setShowCDPH530RequiredImageModal] =
    useState<boolean>(false);
  const [showReviewToaster, setShowReviewToaster] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<SelectedFile>();
  const { agent = {} } = useSelector((state: Store) => state.session);
  const { timecard, _id } = shift;
  const { files = [] } = timecard || {};

  const setTimecardModalVisibility = (status: boolean) => () =>
    setShowTimecardModal(status);
  const closeTimecardUploadModal = () => setShowUploadTimecardModal(false);
  const setShowCDPH530RequiredImageModalVisibility = (status: boolean) => () =>
    setShowCDPH530RequiredImageModal(status);

  const buttonColor = files.length ? "primary" : "danger";
  const buttonTitle = editMode
    ? "(Required) Take a picture of your signed timecard"
    : "View Timecard";

  const onRequiredTimeCardClick = async () => {
    const { error: positionError, errorDetails: positionErrorDetails } =
      await getOrWatchCurrentLocation();
    if (positionError) {
      const failureReason = [
        `Failed to upload timecard to shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}.`,
        `Reason: ${LocationAlertTypeReason[positionError]}.`,
        `Error details: ${positionErrorDetails || "N/A"}`,
      ].join(" ");
      dispatch(
        actionRecordShiftTimeFailure(failureReason, agent, shift._id as string)
      );
      setLocationError(positionError);
      return;
    }
    setShowTimecardModal(true);
  };

  const closeAlert = () => setLocationError(undefined);

  const openLocationSettings = async () => {
    if (locationError !== LocationAlertType.NOT_CLOSE)
      await OpenNativeSettings.open("location");
  };

  return (
    <Fragment>
      <IonButton
        className="button-auto-height"
        size="small"
        expand="block"
        fill="outline"
        onClick={onRequiredTimeCardClick}
        color={buttonColor}
      >
        {buttonTitle}
      </IonButton>

      <IonModal
        isOpen={showTimecardModal}
        onDidDismiss={setTimecardModalVisibility(false)}
      >
        <TimecardList
          shift={shift}
          setSelectedFile={setSelectedFile}
          setShowUploadTimecardModal={setShowUploadTimecardModal}
          setTimecardModalVisibility={setTimecardModalVisibility}
          setShowCDPH530RequiredImageModalVisibility={
            setShowCDPH530RequiredImageModalVisibility
          }
        />
      </IonModal>

      <IonModal
        isOpen={showCDPH530RequiredImageModal}
        onDidDismiss={setShowCDPH530RequiredImageModalVisibility(false)}
      >
        <AppModal
          title="CDPH 530 Sample"
          onBack={setShowCDPH530RequiredImageModalVisibility(false)}
        >
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>Your picture is valid if…</IonCardSubtitle>
            </IonCardHeader>
            <ol>
              <li>
                All yellow fields on the CDPH 530 form pictured below are
                accurately & legibly filled out
              </li>
              <li>
                It is signed by the properly authorized staff member (Usually
                the DON)
              </li>
              <li>There is only one day per form (Use a new form each day)</li>
              <li>The entire sheet visible in your photo</li>
              <li>
                The photo is not fuzzy: all of the text is clear and legible
              </li>
              <li>
                Don’t forget to leave the document with the facility once you’ve
                taken a picture!
              </li>
            </ol>
          </IonCard>
          <IonCard>
            <img alt="CDPH_530" src="assets/images/CDPH530.png" />
          </IonCard>
        </AppModal>
      </IonModal>

      <IonModal
        isOpen={showUploadTimecardModal}
        onDidDismiss={closeTimecardUploadModal}
      >
        <TimecardUpload
          showUploadTimecardModal={showUploadTimecardModal}
          setShowUploadTimecardModal={setShowUploadTimecardModal}
          setShowImageConfirmation={setShowImageConfirmation}
          selectedFile={selectedFile as SelectedFile}
          shiftId={_id as string}
          updateShift={updateShift}
        />
      </IonModal>
      <IonAlert
        isOpen={showImageConfirmation}
        onDidDismiss={() => {
          setShowImageConfirmation(false);
          setShowReviewToaster(true);
        }}
        header="Attention!"
        message="You will not get paid if the picture does not meet regulatory standards"
        buttons={["Never Mind", "Ok"]}
      />
      <IonToast
        isOpen={showReviewToaster}
        color="success"
        onDidDismiss={() => setShowReviewToaster(false)}
        message="Thank you. Your timesheet is in review. Please allow 24 hours to see this reflect on your Stripe balance"
        duration={2000}
        position="top"
      />
      <IonAlert
        message={getAlertText(locationError)}
        isOpen={!!locationError}
        onDidDismiss={closeAlert}
        buttons={[
          {
            text: "Ok",
            handler: openLocationSettings,
          },
        ]}
        mode="ios"
      />
    </Fragment>
  );
};

export { Timecard };
