import React, { useEffect } from "react";
import { IonAlert } from "@ionic/react";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { transformMessage } from "../../../utils/strings";
import { MessageString } from "../../../constants/strings";

const { extraTimePayAlert: etpStrings } = MessageString;

const ExtraTimePayAlert = ({ isOpen, onDidDismiss, onConfirm, shift }) => {
  if (!shift?.facility?.tbioOptions) return null;

  const { tbioOptions } = shift.facility;

  const {
    single,
    backToBack,
    forty,
    multiplier,
    threshold,
    multiplier2,
    threshold2,
  } = tbioOptions;

  useEffect(() => {
    if (isOpen) {
      logEvent(USER_EVENTS.VIEWED_EXTRA_TIME_PAY_ALERT);
    }
  }, [isOpen]);

  const etpTypeComps: string[] = [];
  let headerSuffix = "";

  if (single) {
    etpTypeComps.push("a single shift");
  }

  if (backToBack) {
    etpTypeComps.push("back-to-back shifts");
  }

  if (etpTypeComps.length === 0 && forty) {
    etpTypeComps.push("40+ hours");
    headerSuffix = " in a calendar week";
  }

  const alertHeader = transformMessage(etpStrings.alertHeader, {
    dynamicText: etpTypeComps
      .map((etpTypeMsg) => `<b>${etpTypeMsg}</b>`)
      .join(" or "),
    headerSuffix,
  });

  const multiplierAndThresholds = [{ multiplier, threshold }];

  if (threshold2 && multiplier2) {
    multiplierAndThresholds.push({
      multiplier: multiplier2,
      threshold: threshold2,
    });
  }

  let alertText = "";
  let fortyMessage = "";

  if (single || backToBack) {
    if (forty) {
      fortyMessage = transformMessage(etpStrings.fortyMessage, {
        multiplier,
        dynamicText: etpTypeComps.join(" or "),
      });
    }
    multiplierAndThresholds.forEach((item) => {
      alertText += `<p>${transformMessage(
        etpStrings.thresholdMessage,
        item
      )}</p>`;
    });
  } else if (forty) {
    fortyMessage = transformMessage(etpStrings.onlyFortyMessage, {
      multiplier,
    });
  }

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      message={`
        <h1>${shift.facility.name} offers Extra Time Pay!</h1>
        <p>${alertHeader}</p>
        ${alertText}
        ${fortyMessage}
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: "More info",
          role: "confirm",
          cssClass: "extra-time-confirm-button",
          handler: onConfirm,
        },
        {
          text: "OK",
          cssClass: "extra-time-ok-button",
          role: "cancel",
        },
      ]}
    />
  );
};

export { ExtraTimePayAlert };
