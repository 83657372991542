import "./style.scss";
import { NotificationPreference } from "src/lib/interface";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "antd/es/alert/style/css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowAPIFailError } from "../../404Pages/showAPIFailError";
import { updateAgent } from "../../store/session";
import { Store } from "../../store/store.model";
import { Response, ResponseCodes } from "../../utils/response";
import { getNotificationPreference, setNotificationPreference } from "./api";
import { MyNotificationProps } from "./model";
import { PreferenceItem } from "./preferenceItem";
import "./style.scss";

const NotificationPreferencePage: React.FC<MyNotificationProps> = () => {
  const [preferences, setPreferences] = useState({
    sms: false,
    push: false,
    off: false,
    long_code_sms: true,
    last_minute_notifications: {
      isOn: false,
      snoozedOn: null,
      snoozeForDays: null,
    },
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { networkStatus } = useSelector((state: Store) => state.session);

  /**
   * Do stuff on component mount
   */
  useEffect(() => {
    getUserNotificationPreferences();
  }, []);

  /**
   * Update agent in store with latest notification preferences
   * @param notificationPreferences
   */
  const updateSession = (notificationPreferences: NotificationPreference) => {
    dispatch(updateAgent({ notificationPreferences }));
  };

  /**
   * Fetches user's preferences and updates loader & preference & agent store states
   */
  const getUserNotificationPreferences = async () => {
    setLoading(true);
    try {
      const response: Response = await getNotificationPreference();
      setPreferences(response.data.notificationPreferences);
      updateSession(response.data.notificationPreferences);
    } catch (ex) {
      // Ignore
    }
    setLoading(false);
  };

  /**
   * Updates preference and updates preference & agent store states
   * @param newValue of type {key: string, value: boolean},
   * key can be any of "push", "sms", or "off"
   */
  const updatePreference = async (newValue) => {
    setLoading(true);
    try {
      const response: Response = await setNotificationPreference({
        notificationPreference: { ...newValue },
      });
      if (response.code === ResponseCodes.Success) {
        setPreferences(response.data.notificationPreferences);
        updateSession(response.data.notificationPreferences);
      }
    } catch (ex) {
      // Ignore
    }
    setLoading(false);
  };

  /**
   * Constructs props for all Preference Items
   */
  const createPreferenceProps = () => {
    return [
      {
        name: "Push Notification",
        preferenceKey: "push",
        isBusy: loading,
        initialValue: preferences.push,
        confirmAlertProps: {
          header: `"Clipboard Health" \n would like to send you`,
          subHeader: `Notifications may include alerts, sounds, icons badges. These can be configured in settings`,
          buttonProps: {
            positiveName: `Allow`,
            negativeName: `Don't Alow`,
          },
        },
        onUpdate: updatePreference,
      },
      {
        name: "SMS",
        preferenceKey: "sms",
        isBusy: loading,
        initialValue: preferences.sms,
        confirmAlertProps: {
          header: "Sign up for SMS Shift Alerts ?",
          message: `These notifications help you to stay on top of shifts and the COVID status for our facilities. Message and data rates may apply. Text HELP for help. Text STOP to cancel. Msg freq varies. For healthcare professional terms:
          <a href='https://clipboardhealth.com/professional-terms'>
          https://clipboardhealth.com/professional-terms</a>`,
          buttonProps: {
            positiveName: "Yes",
            negativeName: "No",
          },
        },
        onUpdate: updatePreference,
      },
      {
        name: "Off",
        preferenceKey: "off",
        isBusy: loading,
        initialValue: preferences.off,
        confirmAlertProps: {
          header: "Are you sure?",
          subHeader: `If you turn notification off then we won't be able to provide you important updates about your shifts`,
          buttonProps: {
            positiveName: `Yes, I'm sure`,
            negativeName: `No, I'm not sure`,
          },
        },
        onUpdate: updatePreference,
      },
    ];
  };
  /**
   * Constructs Props for lastMinuteNotifications
   */
  const LastMinuteNotificationProps = {
    name: "Last Minute Notification",
    preferenceKey: "last_minute_notifications",
    isBusy: loading,
    initialValue:
      preferences.last_minute_notifications &&
      preferences.last_minute_notifications.isOn,
    confirmAlertProps: {
      header: `"Clipboard Health" \n would like to send you`,
      subHeader: `Last Minute Notifications may include alerts, sounds, icons badges.`,
      buttonProps: {
        positiveName: `Allow`,
        negativeName: `Don't Alow`,
      },
    },
    onUpdate: updatePreference,
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Privacy"
              defaultHref="/home/account"
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Notification Preferences</IonTitle>
        </IonToolbar>
      </IonHeader>
      {!networkStatus?.connected && (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      )}
      {networkStatus?.connected && (
        <React.Fragment>
          <IonContent class="ion-card-content-main custom-notification-preference">
            <IonItem lines="none" className="ion-margin-top">
              <IonLabel className="ion-top-label">
                Account and shifts updates
              </IonLabel>
            </IonItem>
            <IonCard>
              <IonCardContent class="ion-no-padding custom-padding-notif-card">
                {createPreferenceProps().map((preferenceProp) => {
                  return <PreferenceItem {...preferenceProp} />;
                })}
              </IonCardContent>
            </IonCard>

            <IonCardContent class="ion-no-padding custom-padding-notif-card">
              {<PreferenceItem {...LastMinuteNotificationProps} />}
            </IonCardContent>
            <IonItem lines="none">
              <IonText className="text-muted">
                Sign up to get shift notifications so you can stay in the know
              </IonText>
            </IonItem>
            <div className="dot-spinner">
              {loading && <IonSpinner name="dots" className="dot-spinner" />}
            </div>
          </IonContent>
        </React.Fragment>
      )}
    </IonPage>
  );
};

export { NotificationPreferencePage };
