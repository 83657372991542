import React, { useState, FormEvent } from "react";
import {
  IonButton,
  IonContent,
  IonLabel,
  IonInput,
  IonToast,
  IonSpinner,
  IonAlert,
  IonRow,
  IonCol,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { useSelector } from "react-redux";
import { Keyboard } from "@capacitor/keyboard";
import { get } from "lodash";
import { LocalStorage } from "@store/session";

import { Store } from "../../store/store.model";
import { environment } from "../../../environments/environment";
import { checkIfPhoneExists } from "../../onboarding/components/api";
import { ProfileUpdateStepProps } from "./interface";

import "./style.scss";
import { useTelephoneNumber } from "../../../hooks/useTelephoneNumber";

const EnterPhone: React.FC<ProfileUpdateStepProps> = ({ stepFinished }) => {
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [verificationError, setVerificationError] = useState<boolean>(false);

  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();

    try {
      if (isPlatform("capacitor")) Keyboard.hide();
      setUpdatingData(true);
      const { exists } = await checkIfPhoneExists(phoneNumber);
      setUpdatingData(false);
      if (exists) {
        setVerificationError(true);
        return;
      }

      localStorage.setItem(LocalStorage.NEW_PHONE, phoneNumber);

      stepFinished();
    } catch (error) {
      setPageError({
        isError: true,
        message: "Unexpected error occurred while verifying ssn",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const { formattedPhoneNumber, onChange, onKeyPress, onKeyUp, phoneNumber } =
    useTelephoneNumber({ initialValue: agent.phone, onNext });

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  return (
    <IonContent>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />

      <IonAlert
        mode="ios"
        isOpen={verificationError}
        backdropDismiss={false}
        header="This number is already in use"
        message="The phone number you tried to submit is already in use by another account. Please make sure you are submitting a phone number you own and that you typed it correctly."
        buttons={[
          {
            text: "Try Again",
            handler: () => setVerificationError(false),
          },
        ]}
      />

      <div className="signup-content content-layout adjust-form-height">
        <div className="form-container">
          <form onSubmit={onNext}>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>What&#39;s your new phone number?</h4>
                </div>

                <div>
                  <IonLabel className="form-label hcp-self-label">
                    PHONE NUMBER
                  </IonLabel>
                  <IonInput
                    className="form-input"
                    type="tel"
                    inputmode="tel"
                    placeholder="(XXX) XXX-XXXX"
                    onIonChange={onChange}
                    value={formattedPhoneNumber}
                    onKeyUp={onKeyUp}
                    onKeyPress={onKeyPress}
                    autofocus
                    required
                  />
                </div>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            disabled={
              updatingData ||
              phoneNumber.length !== environment.mobileLength ||
              phoneNumber === agent.phone
            }
            onClick={onNext}
          >
            Continue
            {updatingData && (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            )}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
};

export { EnterPhone };
