import { get } from "lodash";
import { logEvent } from "src/lib/analytics";
import React from "react";
import { USER_EVENTS } from "../../constants/userEvents";
import { cloudy, cloudyNight, moon, partlySunny } from "ionicons/icons";
import { getHasShiftColor, ShiftChip } from "src/lib/ionic-components";
import { GroupItemDescriptionProps, GroupItemProps } from "./model";
import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";

const getShiftIcon = (shiftName: string) => {
  switch (shiftName) {
    case "am":
      return partlySunny;
    case "pm":
      return cloudyNight;
    case "noc":
      return moon;
    default:
      return cloudy;
  }
};

const GroupItemDescription: React.FC<GroupItemDescriptionProps> = ({
  shiftName,
  myShiftLength,
  openShiftLength,
}) => {
  let color = "medium";
  let description = "No open shifts";

  if (myShiftLength > 0) {
    color = "medium";
    description = `You are working an ${shiftName.toUpperCase()} shift`;
  } else if (openShiftLength > 0) {
    color = "dark";
    description = `${openShiftLength} open ${shiftName.toUpperCase()} shifts`;
  }

  return (
    <IonText color={color}>
      <p>{description}</p>
    </IonText>
  );
};

const GroupItem: React.FC<GroupItemProps> = ({
  shiftName,
  myShiftMap,
  openShiftMap,
  onClick,
}) => {
  const myShiftLength = get(myShiftMap, `${shiftName}.length`, 0) as number;
  const openShiftLength = get(openShiftMap, `${shiftName}.length`, 0) as number;
  const isButton = myShiftLength > 0 || openShiftLength > 0;

  const onShiftItemClick = () => {
    isButton && onClick(shiftName);
    logEvent(USER_EVENTS.VIEWED_CALENDAR_DATE_SHIFTS, { shiftName: shiftName });
  };

  const hasShift = {
    hasStaffedShift: myShiftLength > 0,
    hasOpenShift: openShiftLength > 0,
  };

  return (
    <IonItem button={isButton} onClick={onShiftItemClick} detail={isButton}>
      <IonIcon
        icon={getShiftIcon(shiftName)}
        mode="ios"
        color={getHasShiftColor(hasShift, shiftName, false, true)}
        slot="start"
      />
      <IonLabel>
        <ShiftChip name={shiftName} hasShift={hasShift} outline={true} />
        <GroupItemDescription
          myShiftLength={myShiftLength}
          openShiftLength={openShiftLength}
          shiftName={shiftName}
        />
      </IonLabel>
    </IonItem>
  );
};

export { GroupItem };
