export const forgotClockOut = "Looks like you forgot to clock out!";
export const notInstantPay = "Your shift is no longer InstantPay-eligible.";
export const instruction =
  "Don't worry, you will still be paid for this shift. We are contacting the facility, and once we are able to verify the shift, we will issue payment.";
export const supportText = "If you have any questions, please visit the ";
export const clockOutReminderText =
  " Next time, remember to clock out of your shift within two hours of the end time to remain eligible for InstantPay.";
export const forgotClockIn = "Looks like you forgot to clock in!";
export const clockInReminderText =
  "Next time, Clock In at the start of your shift to be eligible for InstantPay!";
