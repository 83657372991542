import moment from "moment-timezone";

export const getClockInEditHelperText = (
  editedClockInTime,
  actualClockInTime
): string => {
  if (moment(editedClockInTime).format() != moment(actualClockInTime).format())
    return actualClockInTime ? actualClockInTime : "skipped clock-in";
  return "";
};

export const getClockOutEditHelperText = (
  editedClockOutTime,
  actualClockOutTime
): string => {
  if (
    moment(editedClockOutTime).format() != moment(actualClockOutTime).format()
  )
    return actualClockOutTime ? actualClockOutTime : "skipped clock-out";
  return "";
};

export const getLunchOutEditHelperText = (
  editedLunchOutTime,
  actualLunchOutTime
): string => {
  if (!actualLunchOutTime && editedLunchOutTime != actualLunchOutTime)
    return "skipped break";
  else if (
    moment(editedLunchOutTime).format() != moment(actualLunchOutTime).format()
  )
    return actualLunchOutTime;
  return "";
};

export const getLunchInEditHelperText = (
  editedLunchInTime,
  actualLunchInTime
): string => {
  if (!actualLunchInTime && editedLunchInTime != actualLunchInTime) return "";
  else if (
    moment(editedLunchInTime).format() != moment(actualLunchInTime).format()
  )
    return actualLunchInTime;
  return "";
};
