import { IonAlert } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

import { Store } from "../../store/store.model";
import { ManualSignSuccessProps } from "./model";

const ManualSignSuccess: React.FC<ManualSignSuccessProps> = ({
  isOpen,
  onClose,
}) => {
  const { agent = {} } = useSelector((state: Store) => state.session);

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onClose}
      message={`Thanks for signing! Please hand the device back to ${agent.name}.`}
      buttons={["Ok"]}
    />
  );
};

export { ManualSignSuccess };
