import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";
import { Shift } from "src/lib/interface";

import { environment } from "../../../environments/environment";
import { getAuthHeader } from "../../superagent";
import { RequestUrgentShiftsOptions, UpcomingShiftApiResponse } from "./model";
import { logFailedApiRetry } from "../../utils/api_retry";

const fetchUrgentShifts = async (
  options: RequestUrgentShiftsOptions
): Promise<Shift[] | undefined> => {
  const url = `${environment.baseUrl}/urgentShifts/list`;
  const response = await request
    .get(url)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/urgentShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body);

  return response?.shifts;
};

const updateAgentData = async (agentId, data): Promise<any> => {
  try {
    const res = await request
      .put(`${environment.baseUrl}/agentProfile/put`)
      .set(await getAuthHeader())
      .send({
        agentId,
        ...data,
      });
    return res;
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
};

const fetchUpcomingShift = async (
  startHourThreshold: number
): Promise<UpcomingShiftApiResponse> => {
  try {
    const body = await request
      .get(`${environment.baseUrl}/urgentShifts/agentUpcomingShift`)
      .set(await getAuthHeader())
      .query({ startHourThreshold })
      .then(({ body }) => body);

    return body;
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
};

export { fetchUrgentShifts, updateAgentData, fetchUpcomingShift };
