import { store } from "@store/index";
import { environment } from "@env/environment";
import { Session } from "./session";

const baseUrl = environment.baseUrl;

function select(state) {
  return state.session;
}

export let NEW_FLOW: boolean | null | undefined = null;

function handleChange() {
  const session: Session = select(store.getState());
  const { agent, env } = session;
  NEW_FLOW = (env && env["enableNewFlow"]) || (agent && agent["enableNewFlow"]);

  if (env) {
    return {
      baseUrl,
      NEW_FLOW: true,
    };
  }
}

export const subscribe = store.subscribe(handleChange);
