import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonToast,
  IonSpinner,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AddLicenseForm } from "src/app/licenseManager/addLicense";
import { ICreateLicense } from "src/app/licenseManager/interfaces";
import { addLicense, getLicenses } from "src/app/licenseManager/api";
import { LICENSE_DETAILS_INIT } from "src/app/licenseManager/constants";
import { logApiFailureEvent } from "src/lib/analytics";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import Alert from "./Alert";
import {
  fireVerifyLicenseZapierHook,
  saveSalesforceContact,
  updateAgentData,
} from "./api";
import "../style.scss";

const AgentOnboardingLicense: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const history = useHistory();

  const [addingLicense, setAddingLicense] = useState<boolean>(false);
  const [gettingLicense, setGettingLicense] = useState<boolean>(true);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [license, setLicense] = useState<ICreateLicense>(LICENSE_DETAILS_INIT);
  const [disableForm, setDisableForm] = useState<boolean>(false);

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setGettingLicense(true);
        const response = await getLicenses();

        if (response && response.length) {
          setDisableForm(true);
          setLicense({
            ...response[0],
          });
        }
      } catch (error) {
        logApiFailureEvent(error);
      } finally {
        setGettingLicense(false);
      }
    })();
  }, []);

  const submitLicense = (license: ICreateLicense) => {
    (async () => {
      setShowErrorModal(false);
      try {
        if (!agent?.userId) {
          throw new Error("agentId not available");
        }
        if (!disableForm) {
          setAddingLicense(true);
          const agentProfileLicenseDetails = {
            ...license,
            expires: license.expiresAt,
          };
          await addLicense({
            ...license,
            agentId: agent?.userId,
          });
          setDisableForm(true);
          const updatedAgent = {
            ...agent,
            license: agentProfileLicenseDetails,
            qualification: license.qualification,
          };
          updateAgentData({
            stage: ONBOARDING_STAGES.LICENSE,
            license: agentProfileLicenseDetails,
            qualification: license.qualification,
            otherQualification: "",
          });
          saveSalesforceContact(agent?.userId);
          fireVerifyLicenseZapierHook(updatedAgent);
        }
        history.push(`${nextStagePath}`);
      } catch (error) {
        if (
          !error.response ||
          !error.response.text ||
          error.response.status > 500
        ) {
          logOnboardingError(
            ONBOARDING_STAGES.QUALIFICATION,
            (error as Error).message,
            agent?.userId
          );
          setShowErrorModal(true);
          return;
        }
        setPageError({
          isError: true,
          message: error.response.text,
        });
      } finally {
        setAddingLicense(false);
      }
    })();
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentSignupInfo2"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />

      <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />
      {gettingLicense ? (
        <div
          className="ion-justify-content-center ion-align-items-center"
          style={{ display: "flex", height: "100%" }}
          data-testid="onboarding-license-loader"
        >
          <IonSpinner color="dark" />
        </div>
      ) : (
        <AddLicenseForm
          licenseDetails={license}
          submitLicense={submitLicense}
          addingLicense={addingLicense}
          forOnboarding={true}
          disableForm={disableForm}
        />
      )}
    </StyledIonPage>
  );
};

export { AgentOnboardingLicense };
