import "./style.scss";
import { CreateAnimation, IonLabel } from "@ionic/react";
import React from "react";
import { InitialTextProps } from "../model";
import { FeedbackPrompt } from "../constants";

const InitialText: React.FC<InitialTextProps> = ({ animate }) => {
  const { titleText, solicitationText } = FeedbackPrompt;
  return (
    <CreateAnimation
      duration={500}
      iterations={1}
      keyframes={[
        { offset: 0, transform: "translateY(0%)", opacity: "1" },
        { offset: 0.25, opacity: "0" },
        { offset: 1, transform: "translateY(-120px)", opacity: "0" },
      ]}
      play={animate}
    >
      <IonLabel className="initial-title">{titleText}</IonLabel>
      <IonLabel className="initial-prompt">{solicitationText}</IonLabel>
    </CreateAnimation>
  );
};

export { InitialText };
