const isRatingDataValid = (
  rating: number,
  reasons: string[],
  otherReason: string | null | undefined,
  review: string | null | undefined
): boolean => {
  if (!rating) {
    return false;
  }
  if (!reasons.length) {
    return false;
  }
  if (
    reasons.includes("Other") &&
    (!otherReason || otherReason.trim().length < 3)
  ) {
    return false;
  }
  if (review && (!review || review.trim().length < 3)) {
    return false;
  }
  return true;
};

export { isRatingDataValid };
