import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logFailedApiRetry } from "../utils/api_retry";
import { logApiFailureEvent } from "src/lib/analytics";

interface Referral {
  referrerName: string;
  referrerCode: string;
  bonusValue?: number;
  bonusPaid?: false;
}

interface ReferralRate {
  referralRate: string;
}

const listReferrals = async (): Promise<Referral[] | undefined> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/agentprofile/referrals`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentprofile/referrals`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const submitReferralCode = async (
  referralCode: string
): Promise<{ referralCodeValid: boolean; message: string } | undefined> => {
  try {
    const { body } = await request
      .post(`${environment.baseUrl}/agentprofile/referral`)
      .send({ referralCode })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    const { response } = error;
    if (response?.body) {
      return response.body;
    }
    logApiFailureEvent(error);
  }
};

export { listReferrals, submitReferralCode, Referral, ReferralRate };
