import { InputChangeEventDetail } from "@ionic/core";
import { IonButton, IonInput, IonItem, IonLabel } from "@ionic/react";
import { xor } from "lodash";
import React, { Fragment } from "react";

import { RatingReasons } from "./constants";
import { CategoryButtonProps, RatingCategoryProps } from "./model";
import { RatingCard } from "./ratingCard";

const CategoryButton: React.FC<CategoryButtonProps> = ({
  category,
  isSelected,
  onChange,
  isAppRating = false,
}) => {
  const onClick = () => onChange(category);

  const fill = isSelected ? "solid" : "outline";
  const className = isAppRating
    ? "button-auto-height app-reasons-category"
    : "button-auto-height";

  return (
    <IonButton
      size="small"
      className={className}
      fill={fill}
      mode="ios"
      onClick={onClick}
    >
      {category}
    </IonButton>
  );
};

const RatingCategory: React.FC<RatingCategoryProps> = ({
  rating,
  reasons,
  setReasons,
  otherReason,
  setOtherReason,
  ratingReasonsToRender,
}) => {
  if (!rating) {
    return <Fragment />;
  }

  const onChange = (category: string) =>
    setReasons((prevState) => xor(prevState, [category]));
  const onOtherReasonChange = (event: CustomEvent<InputChangeEventDetail>) =>
    setOtherReason(event.detail.value);

  const ratingReasons = ratingReasonsToRender
    ? ratingReasonsToRender
    : RatingReasons;
  const title = rating === 5 ? "What went well?" : "What went wrong?";
  const availableReasons = ratingReasons[(rating === 5).toString()];

  return (
    <RatingCard facilityName="" title={title}>
      {availableReasons.map((category) => (
        <CategoryButton
          category={category}
          isSelected={reasons.includes(category)}
          onChange={onChange}
          key={category}
        />
      ))}
      {reasons.includes("Other") && (
        <IonItem>
          <IonLabel position="floating">
            <p>Other, please explain (min 3 characters):</p>
          </IonLabel>
          <IonInput
            value={otherReason}
            onIonChange={onOtherReasonChange}
            autofocus
          />
        </IonItem>
      )}
    </RatingCard>
  );
};

export { RatingCategory, CategoryButton };
