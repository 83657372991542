import "./style.scss";
import "antd/es/alert/style/css";
import React, { useState } from "react";
import { IonActionSheet, IonAlert } from "@ionic/react";
import { setTimeSheetAvailability } from "@store/ongoingShifts/apiV2";
import { useAppSelector } from "@store/index";
import { CancelShiftModal } from "../cancelShiftModal";
import { logEvent } from "src/lib/analytics";
import { Shift } from "src/lib/interface";
import { USER_EVENTS } from "@constants/userEvent";

const reasons = {
  noPhoto: {
    title: "Thanks for letting us know!",
    message:
      "In order to get paid quickly, we require an uploaded timesheet. Without a timesheet, it can take up to a week to verify your shift.",
    text: "I forgot to take a photo",
    event: USER_EVENTS.TIMESHEET_PHOTO_FORGOTTEN,
  },
  otherWay: {
    title: "Thank you!",
    message:
      "We’ll take a look for it. Uploading your timesheet in the app is the quickest way to get paid.",
    text: "I sent it to Clipboard another way",
    event: USER_EVENTS.TIMECARD_SENT_ANOTHER_WAY,
  },
  noShift: {
    title: "Thank you!",
    message:
      "We’ll take a look for it. Uploading your timesheet in the app is the quickest way to get paid.",
    text: "I didn’t work this shift",
    event: USER_EVENTS.SHIFT_NOT_ATTENDED,
  },
};

interface NoTimeSheetOptionsProps {
  shift?: Shift;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  cancel?: Function;
  setCanShowUploadButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoTimeSheetOptions = ({
  visible,
  setVisible,
  cancel,
  shift,
  setCanShowUploadButton,
}: NoTimeSheetOptionsProps) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openNCNS, setOpenNCNS] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const agent = useAppSelector((state) => state?.session?.agent ?? {});
  const cancelModal = () => {
    setVisible(false);
    cancel?.();
  };
  const setTimesheetUnavailableData = async (reason) => {
    const query = {
      shiftId: shift?._id,
      reason: reason,
      region: shift?.facility?.fullAddress?.metropolitanStatisticalArea,
      agentName: agent.name,
    };
    await setTimeSheetAvailability(
      query as Parameters<typeof setTimeSheetAvailability>[0]
    );
    setCanShowUploadButton?.(false);
  };
  const setReasonsAndAlertValues = async (type) => {
    logEvent(reasons[type].event);
    await setTimesheetUnavailableData(reasons[type].event);

    if (type === "noPhoto") {
      window.open(
        "https://intercom.help/clipboard-health-help-center/en/articles/5500436-i-forgot-to-ask-for-a-signature-on-my-timesheet"
      );
    }

    if (type !== "noShift") {
      setTitle(reasons[type].title);
      setMessage(reasons[type].message);
      setOpenAlert(true);
    } else {
      cancelModal();
    }
  };
  const shiftMarkedNCNS = () => {
    setOpenNCNS(false);
    setReasonsAndAlertValues("noShift");
  };

  return (
    <>
      <IonActionSheet
        mode="ios"
        isOpen={visible}
        onDidDismiss={cancelModal}
        cssClass="my-custom-class"
        header={"Why don’t you have a timesheet?"}
        buttons={[
          {
            text: reasons.noPhoto.text,
            role: "noPhoto",
            cssClass: "primary",
            handler: () => {
              setReasonsAndAlertValues("noPhoto");
            },
          },
          {
            text: reasons.otherWay.text,
            role: "otherWay",
            cssClass: "secondary",
            handler: () => {
              setReasonsAndAlertValues("otherWay");
            },
          },
          {
            text: reasons.noShift.text,
            role: "noShift",
            cssClass: "secondary",
            handler: () => {
              setOpenNCNS(true);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonAlert
        mode="ios"
        isOpen={openAlert}
        onDidDismiss={() => cancel}
        cssClass="my-custom-class"
        header={title}
        message={message}
        buttons={[
          {
            text: "OK",
            role: "ok",
            cssClass: "primary",
            handler: () => {
              cancel?.();
            },
          },
        ]}
      />
      {openNCNS && (
        <CancelShiftModal
          isPendingVerification={true}
          shift={shift as Shift}
          onDismiss={() => setOpenNCNS(false)}
          onSuccess={shiftMarkedNCNS}
          onlyModal={true}
        />
      )}
    </>
  );
};
export { NoTimeSheetOptions };
