import { Dispatch } from "redux";
import { GetState } from "../store.model";
import { ActionType } from "./model";
import { updateAgent } from "./api";

const updateDetails =
  (specialities, agentId) => async (dispatch: Dispatch, getState: GetState) => {
    await updateAgent(specialities, agentId);
    dispatch({
      type: ActionType.UPDATE_AGENT,
      data: specialities,
    });
  };

export { updateDetails };
