import { useEffect, useRef } from "react";

const usePrevious = <Type>(value: Type | undefined): Type | undefined => {
  const ref = useRef<Type>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
