import moment from "moment";
import {
  checkmarkCircleOutline,
  timer,
  mail,
  book,
  checkmarkCircle,
} from "ionicons/icons";
import {
  CheckrApplicationStatus,
  RequirementTypes,
} from "../../documents/model";
import { CheckrRequirementData } from "./checkrPill.types";
import { HcpRequirementStatus } from "@store/documents/model";
import { CHECKR_REQUIREMENT_ID } from "@constants/userEvent";

const CheckrRequirementStatusData = Object.freeze({
  [RequirementTypes.EXPIRED]: {
    description: "Document Expired! Tap to get a new invite.",
    listTitleColor: "danger",
    listIcon: mail,
    pillDescription: "Email me an invite",
    pillColor: "primary",
    isButtonEnabled: true,
  },
  [RequirementTypes.PENDING]: {
    description: "Clipboard is waiting for your results.",
    listIcon: timer,
    pillDescription: "Report in progess",
    pillColor: "medium",
  },
  [RequirementTypes.MISSING]: {
    invitationSent: {
      description: "Check your inbox to complete this requirement.",
      listIcon: checkmarkCircle,
      pillDescription: "Invite emailed!",
      pillColor: "medium",
    },
    invitationNotSent: {
      description: "Tap to send an invite to your email inbox",
      listIcon: mail,
      pillDescription: "Email me an invite",
      pillColor: "primary",
      isButtonEnabled: true,
    },
  },
  [RequirementTypes.COMPLETED]: {
    almostExpiredInvitationSent: {
      description: "Document almost expired!",
      listTitleColor: "warning",
    },
    almostExpiredInvitationNotSent: {
      description: "Document almost expired! Tap to get a new invite.",
      listIcon: mail,
      listTitleColor: "warning",
      pillDescription: "Email me an invite",
      pillColor: "primary",
      isButtonEnabled: true,
    },
    completed: {
      description: "Completed!",
      listIcon: checkmarkCircleOutline,
      listTitleColor: "success",
    },
  },
  [RequirementTypes.REJECTED]: {
    description: "Document Rejected! Tap to learn more.",
    pillDescription: "Learn more",
    listIcon: book,
    listTitleColor: "danger",
    pillColor: "primary",
    isButtonEnabled: true,
  },
});

export const getCheckrRequirementStatusData = (
  { invitationStatus, reportStatus }: CheckrApplicationStatus,
  requirementStatusType: string,
  expiry: Date | undefined,
  requirementStatus: HcpRequirementStatus
): CheckrRequirementData | undefined => {
  if (invitationStatus === null && reportStatus === null) {
    return;
  }
  if (requirementStatusType === RequirementTypes.MISSING) {
    if (invitationStatus === "pending") {
      return CheckrRequirementStatusData[RequirementTypes.MISSING]
        .invitationSent;
    }
    return CheckrRequirementStatusData[RequirementTypes.MISSING]
      .invitationNotSent;
  }
  if (requirementStatusType === RequirementTypes.COMPLETED) {
    if (moment(expiry).diff(moment(), "days") < 30) {
      if (
        requirementStatus?.pending?.includes(CHECKR_REQUIREMENT_ID) ||
        requirementStatus?.missing?.includes(CHECKR_REQUIREMENT_ID)
      ) {
        return CheckrRequirementStatusData[RequirementTypes.COMPLETED]
          .almostExpiredInvitationSent;
      } else {
        return CheckrRequirementStatusData[RequirementTypes.COMPLETED]
          .almostExpiredInvitationNotSent;
      }
    }
    return CheckrRequirementStatusData[RequirementTypes.COMPLETED].completed;
  }
  return CheckrRequirementStatusData[
    requirementStatusType
  ] as CheckrRequirementData;
};
