import { ApolloProvider } from "@apollo/client";
import { TestPanel } from "../lib/test-helpers/TestPanel";
import { IonApp } from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { IonReactRouter } from "@ionic/react-router";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Dedupe, Offline } from "@sentry/integrations";
import React from "react";
import { Provider } from "react-redux";
import { environment } from "../environments/environment";
import "./app.scss";
import { GoogleMapProvider } from "./context/googleMapContext";
import { ErrorBoundary } from "./errorBoundary";
import { GraphQlClient } from "./graphql";
import { PopupAlerts } from "./popups";
import { AppRoutes } from "./routing";
import { store } from "./store";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { App as CapacitorApp } from "@capacitor/app";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import { Storage } from "@capacitor/storage";
import * as braze from "@braze/web-sdk";

import handleStripePopup from "./appStateChangeHandlers/handleStripePopup";

CapacitorApp.addListener("appStateChange", ({ isActive }) => {
  handleStripePopup(isActive as boolean);
});

// TODO: Uncomment when android zendesk messaging sdk issue is resolved
// if (isPlatform(window, "capacitor")) {
//   ZendeskSDK.initialize({
//     channelKey: environment.zendesk.channelKey ?? "",
//   });
// }

if (environment.Sentry.allowed) {
  const { dsn, release, tracesSampleRate } = environment.Sentry;

  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new Dedupe(),
      new Offline(),
    ],
    release,
    tracesSampleRate,
    environment: environment.Sentry.environment,
  });
}

Storage.migrate();

if (
  !braze.initialize(environment.braze.key as string, {
    baseUrl: environment.braze.sdkEndpoint as string,
  })
) {
  throw new Error("Braze web SDK initialization failed");
}

const MainApp = () => (
  <IonApp>
    <IonReactRouter>
      <AppRoutes />
      <PopupAlerts />
    </IonReactRouter>
    {(process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) !==
      "production" &&
      !environment.production && (
        <div style={{ zIndex: 1000 }} id="testPanel">
          <TestPanel />
        </div>
      )}
  </IonApp>
);

const LDApp = withLDProvider({
  clientSideID: environment.featureFlag.clientSideID || "",
  user: { anonymous: true },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(MainApp);

export const App = () => {
  return (
    <ErrorBoundary>
      <GoogleMapProvider>
        <ApolloProvider client={GraphQlClient}>
          <Provider store={store}>
            <LDApp />
          </Provider>
        </ApolloProvider>
      </GoogleMapProvider>
    </ErrorBoundary>
  );
};

export default App;
