import { IonContent } from "@ionic/react";
import React, { Fragment } from "react";

import { AppHeader } from "./header";
import { HeaderProps } from "./model";

const AppModal: React.FC<HeaderProps> = ({ title, onBack, children }) => (
  <Fragment>
    <AppHeader title={title} onBack={onBack} />
    <IonContent>{children}</IonContent>
  </Fragment>
);

export { AppModal };
