import React from "react";
import { IonAlert } from "@ionic/react";

const RatingSuccess: React.VFC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const message =
    "We review all feedback and are always working to make your experience even better!";
  const title = "Thanks so much!";

  return (
    <IonAlert
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={onClose}
      cssClass="my-custom-class"
      header={title}
      message={message}
      buttons={[
        {
          text: "OK",
          role: "ok",
          cssClass: "primary",
          handler: onClose,
        },
      ]}
    />
  );
};

export { RatingSuccess };
