import {
  AttendanceStats,
  ErrorProps,
  AttendanceScoreProfile,
  AttendanceScorePolicyConfiguration,
} from "./types";

export const defaultAttendanceStats: AttendanceStats = {
  callOffs: 0,
  noCallNoShows: 0,
  standardCancels: 0,
  workedShifts: 0,
  accountStatus: "",
  reEnrollmentDate: null,
};

export const defaultErrorProps: ErrorProps = {
  isOpen: false,
  message: "",
  duration: 0,
};

export const defaultAttendanceScorePolicy: AttendanceScorePolicyConfiguration =
  {
    workShift: { points: 5 },
    cancelShift: [
      {
        points: -85,
        leadTime: [0, 4],
      },
      {
        points: -70,
        leadTime: [4, 8],
      },
      {
        points: -55,
        leadTime: [8, 16],
      },
      {
        points: -40,
        leadTime: [16, 24],
      },
      {
        points: -25,
        leadTime: [24, 72],
      },
      {
        points: -10,
        leadTime: [72, null],
      },
    ],
    noCallNoShow: { points: -100 },
  };

export const defaultAttendanceScoreProfile: AttendanceScoreProfile = {
  score: 0,
  accountStatus: { status: "", reEnrollmentDate: undefined },
  policy: defaultAttendanceScorePolicy,
};
