import { replace, snakeCase } from "lodash";

export const getDocumentFileName = (
  requirementName: string,
  fileName?: string
) => {
  const trimmedRequirementName = replace(requirementName, /[^\w\-\.]/, "_");
  const documentFilename = fileName
    ? `${snakeCase(trimmedRequirementName)}-${fileName}`
    : `${snakeCase(trimmedRequirementName)}`;

  return documentFilename;
};
