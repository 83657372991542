const StarDescription = [
  "Rate your experience",
  "Unsatisfactory/ Do Not Return Request",
  "Needs Improvement/ Providing Complaint",
  "Average",
  "Good",
  "Great",
];

const RatingReasons = {
  wentWell: [
    "Quality and Safety of Patient Care & Treatment",
    "Industry Knowledge & Training Competency",
    "Followed Healthcare Workplace Standards of Conduct",
    "Overall, Professionalism",
    "Timeliness",
    "Other",
  ],
  wentWrong: [
    "Quality and Safety of Patient Care & Treatment Issue(s)",
    "Industry Knowledge & Training Competency Issue(s)",
    "Disobeyed Healthcare Workplace Standards of Conduct",
    "Overall, Professionalism Issue(s)",
    "Attendance & Timeliness Issue(s)",
    "Other",
  ],
};

export { StarDescription, RatingReasons };
