import { IonPage } from "@ionic/react";
import { AttendancePolicy } from "src/constants/attendancePolicy";
import AttendanceScoreRatingPage from "./attendanceScoreRatingPage";
import MarketplacePolicyRatingPage from "./marketplacePolicyRatingPage";
import { AttendancePolicyRatingPageProps } from "./types";

const AttendancePolicyRatingPage = ({
  attendancePolicy,
}: AttendancePolicyRatingPageProps) => {
  return (
    <IonPage>
      {attendancePolicy === AttendancePolicy.ATTENDANCE_SCORE ? (
        <AttendanceScoreRatingPage />
      ) : attendancePolicy === AttendancePolicy.UPGRADED_STATUS_QUO ? (
        <MarketplacePolicyRatingPage />
      ) : (
        <></>
      )}
    </IonPage>
  );
};

export default AttendancePolicyRatingPage;
