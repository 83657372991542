import React from "react";
import { isEmpty, template } from "lodash";

/**
 * Function that can be used to extract list of react components with replacing templated
 * string.
 * @param component String containing html components
 * @param vars Variables to be used to replace values in string template
 * @returns List of react components
 */
const getReactComponents = (label: string, component: string, vars = {}) => {
  if (!component) return null;

  const doc = new DOMParser().parseFromString(
    `<span>${component}</span>`,
    "application/xml"
  );
  const comp = doc.childNodes[0].childNodes;
  const hasVars = !isEmpty(vars);

  const components = Object.keys(comp).map((key, i) => {
    const elem = comp[key];
    const elemKey = `${label}-${key}`;
    if (!elem?.tagName) {
      return (
        <span key={elemKey}>
          {hasVars ? template(elem.nodeValue)(vars) : elem.nodeValue}
        </span>
      );
    }

    let elemContent: string;

    if (hasVars) {
      try {
        elemContent = template(elem.innerHTML)(vars);
      } catch {
        elemContent = elem.innerHTML;
      }
    } else {
      elemContent = elem.innerHTML;
    }

    switch (elem.tagName) {
      case "b":
        return <b key={elemKey}>{elemContent}</b>;
      case "i":
        return <i key={elemKey}>{elemContent}</i>;
      case "a":
        return (
          <a key={elemKey} href={elem?.attributes?.href?.value || "#"}>
            {elemContent}
          </a>
        );
    }

    if (elemContent) {
      return <span>{elemContent}</span>;
    }

    return undefined;
  });

  return components.filter((comp) => !!comp);
};

export default getReactComponents;
