import { MappingLevel } from "src/lib/constants";
import { CheckrApplicationStatus } from "../../documents/model";

const ActionType = {
  SET_REQUIREMENTS: "SET_REQUIREMENTS",
  SET_REQUIREMENT_LOADING: "SET_REQUIREMENT_LOADING",
  SET_SELECTED_REQUIREMENT: "SET_SELECTED_REQUIREMENT",
  SET_CHECKR_APPLICATION_STATUS: "SET_CHECKR_APPLICATION_STATUS",
};
interface Requirement {
  _id: string;
  reqId?: string;
  name: string;
  description: string;
  instructions: string;
  visibleToHCP: boolean;
  url: string;
  createdAt: Date;
  archived: boolean;
  expiry?: Date;
  level?: MappingLevel;
  requiredBy?: string;
  validity?: Date;
}
interface RequirementMapping {
  _id: string;
  requiredBy: string[];
  hcfType: string[];
  level: MappingLevel;
  requiredForBooking: boolean;
}
type RequirementTypesInResponse =
  | "description"
  | "instructions"
  | "visibleToHCP"
  | "name"
  | "expiry";
interface HcpRequirementResponse
  extends Partial<RequirementMapping>,
    Partial<Pick<Requirement, RequirementTypesInResponse>> {
  reqId: string;
}

interface MiscellaneousRequirementResponse {
  _id: string;
  name: string;
  description: string;
  instructions: string;
}

interface MiscellaneousRequirementExtended
  extends MiscellaneousRequirementResponse {
  reqId: string;
}
interface HcpRequirementStatusResponse {
  requirements: HcpRequirementResponse[];
  optionalRequirements: HcpRequirementResponse[];
  completed: string[];
  pending: string[];
  expired: string[];
  missing: string[];
  missingOptionalRequirements: string[];
  validOptionalRequirements: string[];
  expiredOptionalRequirements: string[];
  optional: string[];
  rejected: string[];
  miscellaneous: MiscellaneousRequirementResponse;
}

interface HcpRequirementStatus
  extends Omit<
    HcpRequirementStatusResponse,
    "requirements" | "miscellaneous" | "optionalRequirements"
  > {
  requirements: { [key: string]: HcpRequirementResponse };
  optionalRequirements:
    | HcpRequirementResponse[]
    | { [key: string]: HcpRequirementResponse };
  missingHCFLevel: string[];
  miscellaneous: string[] | MiscellaneousRequirementExtended;
}

enum DocumentStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

enum ExpiryType {
  DOES_NOT_EXPIRE = "Does not expire",
  EXPIRY_DATE = "Expiry Date",
  ISSUE_PLUS_VALIDITY = "Issue Date + Validity",
}

interface Notification {
  reason?: string;
  pushNotification?: boolean;
  note?: string;
}

interface HcpDocument {
  _id: string;
  archived?: boolean;
  visible?: boolean;
  issueDate?: Date;
  status?: DocumentStatus;
  covidTest?: Date;
  fileUrl?: string;
  name?: string;
  hcpId?: string;
  expiry?: Date;
  rejectionReason?: string;
  rejectionNotifiedAt?: Date;
  uploadedFor?: Requirement;
  fulfilledRequirement?: Requirement;
  notification?: Notification;
  createdAt?: Date;
}

interface DocumentStore {
  loading?: boolean;
  status: {
    requirements: { [key: string]: Requirement };
    missing: Array<string>;
    pending: Array<string>;
    expired: Array<string>;
    completed: Array<string>;
    miscellaneous: Array<string>;
    isStandardMissing?: boolean;
  };
  selectedRequirement?: {
    requirement: Requirement | null;
    requirementType: string | null;
  };
  checkrApplicationStatus?: CheckrApplicationStatus;
}
interface PageError {
  isError: boolean;
  message: string;
}

export {
  ActionType,
  DocumentStore,
  Requirement,
  HcpDocument,
  Notification,
  DocumentStatus,
  ExpiryType,
  HcpRequirementStatus,
  HcpRequirementStatusResponse,
  MiscellaneousRequirementResponse,
  MiscellaneousRequirementExtended,
  HcpRequirementResponse,
  PageError,
};
