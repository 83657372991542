import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";

const updateShowReferralAlert = async (agentId, data): Promise<void> => {
  try {
    const { body } = await request
      .patch(`${environment.baseUrl}/agentprofile/${agentId}`)
      .set(await getAuthHeader())
      .send({ data });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updateShowReferralAlert };
