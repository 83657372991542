import { IonAlert, IonButton, IonSpinner } from "@ionic/react";
import { SHIFT_STRINGS } from "src/constants";
import React, { Fragment, useState } from "react";
import { ShiftStages } from "src/lib/interface";

export const SkipLunchButton: React.VFC<{
  stage: ShiftStages;
  onClick: () => void;
  loading: boolean;
}> = ({ stage, onClick, loading }) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const closeModal = () => setIsConfirm(false);
  const openModal = () => setIsConfirm(true);

  if (stage !== ShiftStages.LUNCH_OUT) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <IonButton
        className="ion-margin"
        fill="outline"
        shape="round"
        size="large"
        expand="block"
        disabled={loading}
        onClick={openModal}
      >
        {loading && <IonSpinner name="crescent" />}
        {SHIFT_STRINGS.SKIP_BREAK}
      </IonButton>
      <IonAlert
        message={SHIFT_STRINGS.ARE_YOU_SURE_YOU_WANT_TO_SKIP_BREAK}
        isOpen={isConfirm}
        backdropDismiss={!loading}
        onDidDismiss={closeModal}
        buttons={[
          {
            text: SHIFT_STRINGS.CANCEL_SKIP_BREAK,
            role: "cancel",
            handler: closeModal,
          },
          {
            text: SHIFT_STRINGS.SKIP_BREAK,
            handler: onClick,
          },
        ]}
      />
    </Fragment>
  );
};
