import "../styles.scss";
import { BackButtonEvent } from "@ionic/core";
import { environment } from "@env/environment";
import { get } from "lodash";
import { logEvent } from "src/lib/analytics";
import { openInAppBrowser } from "src/lib/ionic-components";
import { Store } from "../../store/store.model";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { USER_EVENTS } from "../../../constants/userEvents";
import { useSelector } from "react-redux";
import { useShouldBeOnboarded } from "../hooks";
import { Agent, PaymentServiceAccountStatus } from "src/lib/interface";
import {
  generateDynamicLink,
  generatePaymentServiceOnboardingLink,
} from "../api";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { DISMISSED_STRIPE_POPUP } from "../constants";

const SetUpStripe: React.FC = () => {
  const [toastError, setToastError] = useState({ isOpen: false, message: "" });
  const location = useLocation();
  const history = useHistory<{ showBackButton: boolean }>();
  const [loading, setLoading] = useState(false);
  const agent: Partial<Agent | undefined> = useSelector(
    (state: Store) => state?.session?.agent
  );
  const shouldBeOnboarded = useShouldBeOnboarded();

  const isSecondTimeOnboarding = useMemo(() => {
    const status = agent?.paymentAccountInfo?.status;
    return (
      status === PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING ||
      status === PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING ||
      status === PaymentServiceAccountStatus.ACCOUNT_NEEDS_UPDATE ||
      agent?.paymentAccountInfo?.showOnboard
    );
  }, [agent]);

  useEffect(() => {
    try {
      const preventNavigation = (ev: BackButtonEvent) => {
        ev.detail.register(100, (nextHandler) => {
          if (location.pathname !== TabRouterPath.PAYMENT_SERVICE_ONBOARDING) {
            nextHandler();
          }
        });
      };
      document.addEventListener("ionBackButton", preventNavigation as any);
      return () =>
        document.removeEventListener("ionBackButton", preventNavigation as any);
    } catch (err) {
      console.log(err);
    }
  }, [location]);

  useEffect(() => {
    if (!shouldBeOnboarded) {
      history.replace(TabRouterPath.OPEN_SHIFTS);
    }
  }, [history, shouldBeOnboarded]);

  const handleOnboarding = useCallback(async () => {
    try {
      setLoading(true);

      const [returnUrl, refreshUrl] = await Promise.all([
        generateDynamicLink(
          `${environment.webAppUrl}${TabRouterPath.SUCCESS_ONBOARDING}`
        ),
        generateDynamicLink(`${environment.webAppUrl}${location.pathname}`),
      ]);

      const onboardingLink = await generatePaymentServiceOnboardingLink(
        agent?.userId as string,
        {
          returnUrl,
          refreshUrl,
        }
      );

      setLoading(false);
      openInAppBrowser(onboardingLink, "_system");
    } catch (err) {
      setLoading(false);
      setToastError({
        isOpen: true,
        message:
          "Sorry we are experiencing technical problems. Please contact support. ",
      });
    }
  }, [agent, location.pathname]);

  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_STRIPE_SET_UP, {
      hcpID: agent?.userId as string,
      name: agent?.name,
    });
  }, [agent]);

  const handleNotNow = () => {
    logEvent(USER_EVENTS.DISMISSED_STRIPE_PROMPT, {
      hcpID: agent?.userId as string,
    });
    localStorage.setItem(DISMISSED_STRIPE_POPUP, "true");
    history.replace(TabRouterPath.OPEN_SHIFTS);
  };

  const firstParagraph = useMemo(() => {
    if (isSecondTimeOnboarding) {
      return `Our payments partner still needs some information in order to pay you.`;
    } else if (agent?.isFirstSession) {
      return `We work with Stripe to send you instant payments directly to your account.`;
    } else {
      return `We have a new Instant Payments partner! With Stripe, we’ll
      send instant payments
      directly to your account.`;
    }
  }, [agent, isSecondTimeOnboarding]);

  const secondParagraph = useMemo(() => {
    if (isSecondTimeOnboarding) {
      return `Please click below to complete set-up.`;
    } else {
      return `Please click below to complete set-up and remember to`;
    }
  }, [isSecondTimeOnboarding]);

  if (!shouldBeOnboarded) return null;

  return (
    <IonPage>
      <IonContent>
        <IonHeader no-border>
          <IonToolbar>
            {history?.location?.state?.showBackButton && (
              <IonButtons slot="start">
                <IonBackButton text="" defaultHref="/home" mode="ios" />
              </IonButtons>
            )}
            <IonTitle size="small" className="header">
              Working with Clipboard Health
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonText
            color="title"
            class="secondary-heading ion-margin-bottom"
            style={{ fontSize: "16px" }}
          >
            <div>Set Up Debit Card With Stripe For Instant Payments</div>
          </IonText>
          <img
            style={{ width: "45%", maxWidth: "300px", margin: "0.5rem 2.5rem" }}
            src="assets/logo/bunny.png"
            alt="Clipboard logo"
          />
          <p className="space" style={{ fontSize: "13px" }}>
            {firstParagraph}
          </p>
          <p className="space" style={{ fontSize: "13px" }}>
            {secondParagraph}
            {!isSecondTimeOnboarding && (
              <IonText color="primary" style={{ fontWeight: "bold" }}>
                {" "}
                enter a debit card if you want instant payouts.
              </IonText>
            )}
            {agent?.isFirstSession &&
              !isSecondTimeOnboarding &&
              ` If
            you enter bank information for payments, payments will take ~5 days to process.`}
          </p>
          <div className="container" style={{ justifyContent: "center" }}>
            <IonButton
              fill="outline"
              className="footer"
              onClick={() => {
                logEvent(USER_EVENTS.STARTED_STRIPE_SET_UP, {
                  hcpID: agent?.userId as string,
                });
                handleOnboarding();
              }}
            >
              {loading ? (
                <IonSpinner color="light" name="crescent"></IonSpinner>
              ) : (
                "Finish Set Up To Get Paid"
              )}
            </IonButton>
            <IonText className="not-now" onClick={handleNotNow}>
              Not Now
            </IonText>
          </div>
        </div>
        <IonToast
          color="danger"
          isOpen={toastError.isOpen}
          message={toastError.message}
          onDidDismiss={() => setToastError({ isOpen: false, message: "" })}
          position="top"
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export { SetUpStripe };
