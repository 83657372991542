import { ActionType, ShiftAction, ShiftStore } from "./model";

const initialState: ShiftStore = {
  isLocationAvailable: true,
  undoShiftActions: {},
  showToast: "",
};

const shiftStore = (state = initialState, action: ShiftAction): ShiftStore => {
  switch (action.type) {
    case ActionType.UPDATE_MY_SHIFT_DATE_RANGE:
      return {
        ...state,
        myShiftDateRange: action.data?.myShiftDateRange,
      };
    case ActionType.SET_SHOW_TOAST:
      return {
        ...state,
        showToast: action.data?.showToast ?? "",
      };

    case ActionType.UPDATE_OPEN_SHIFT_DATE_RANGE:
      return {
        ...state,
        openShiftDateRange: action.data?.openShiftDateRange,
      };

    case ActionType.UPDATE_LOCATION_AVAILABILITY:
      return {
        ...state,
        isLocationAvailable: false,
      };

    case ActionType.UNDO_INTEREST:
      return {
        ...state,
        undoShiftActions: action.data
          ?.undoShiftActions as ShiftStore["undoShiftActions"],
      };

    default:
      return state;
  }
};

export { shiftStore };
