import request from "superagent";

import { environment } from "src/environments/environment";
import { getAuthHeader } from "src/app/superagent";
import { logFailedApiRetry } from "src/app/utils/api_retry";
import {
  ICreateLicense,
  ILicenseDetails,
  IWorkTypesResponse,
} from "./interfaces";

export const getLicenses = async (): Promise<ILicenseDetails[]> => {
  return await request
    .get(`${environment.baseUrl}/license`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/license`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const getLicenseDetails = async (
  licenseId: string
): Promise<ILicenseDetails> => {
  return await request
    .get(`${environment.baseUrl}/license/${licenseId}`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/license/${licenseId}`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const deleteLicense = async (
  licenseId: string
): Promise<{ success: true }> => {
  return await request
    .delete(`${environment.baseUrl}/license/${licenseId}`)
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const addLicense = async (
  license: ICreateLicense & { agentId: string }
): Promise<ILicenseDetails> => {
  return await request
    .post(`${environment.baseUrl}/license/create`)
    .set(await getAuthHeader())
    .send(license)
    .then(({ body }) => body);
};

export const getWorkTypes = async (): Promise<IWorkTypesResponse> => {
  return await request
    .get(`${environment.baseUrl}/workerAndWorkPlaceTypes`)
    .retry(1, (err) => {
      if (!err) return false;
      logFailedApiRetry(err, `/workerAndWorkPlaceTypes`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};
