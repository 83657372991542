import React, { Fragment } from "react";

import { SignModeContainerProps } from "./model";

const SignModeContainer: React.FC<SignModeContainerProps> = ({
  mode,
  requiredMode,
  children,
}) => {
  if (requiredMode === mode) {
    return <Fragment>{children}</Fragment>;
  }

  return <Fragment />;
};

export { SignModeContainer };
