import { useApolloClient } from "@apollo/client";
import { IonCard, IonSpinner, IonItem, IonLabel, IonToast } from "@ionic/react";
import React, { useState, useEffect, useCallback } from "react";
import { isPlatform } from "@ionic/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { useFlags } from "launchdarkly-react-client-sdk";
import { openInAppBrowser } from "src/lib/ionic-components";
import { readBlobAsBase64 } from "@app/utils/mediaUpload";
import { DocumentPreviewProps } from "./model";
import { last } from "lodash";
import {
  getCloudinaryDocument,
  getDocumentFromS3UsingDistributionUrl,
} from "./api";
import { FeatureFlag } from "../../constants/FEATURE_FLAGS";
import { GET_DISTRIBUTION_URL } from "./gql";

const getExtension = (type: string | undefined): string => {
  if (!type) {
    return "";
  }
  const urlArray = type.toLowerCase().split("/");
  const extension = last(urlArray);
  return extension ? extension : "";
};

const getExtensionFromDistributionUrl = (distributionUrl: string): string => {
  if (!distributionUrl) {
    return "";
  }
  const urlArray = distributionUrl.toLowerCase().split(".");
  const extension = last(urlArray).split("?")[0];
  return extension;
};

const getDistributionUrl = async (client, docId: string) => {
  const {
    data: {
      getDistributionUrl: { distributionUrl },
    },
  } =
    (await client.query({
      query: GET_DISTRIBUTION_URL,
      variables: { docId },
    })) || {};
  return distributionUrl;
};

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  document,
  setPageError,
  documentName,
}) => {
  if (!document) {
    return null;
  }

  let { _id } = document;

  const [file, setFile] = useState<string>("");
  const [fileExtension, setExtension] = useState<string>("");
  const [fileData, setFileData] = useState<Blob>(new Blob());
  const [showDownloadSuccess, setShowDownloadSuccess] =
    useState<boolean>(false);
  const [showDownloadError, setShowDownloadError] = useState<boolean>(false);

  const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);
  const isPDF = fileExtension === "pdf";
  const isDesktop = isPlatform("desktop");
  const isMobileWeb = isPlatform("mobileweb");
  const isIOS = isPlatform("ios");
  const fileName = `${documentName.replace(/\//g, "-")}.pdf`;

  const ldFlags = useFlags();
  let shouldFetchFromFss = ldFlags[FeatureFlag.READS_FILE_STORAGE_MOBILE];
  const client = useApolloClient();

  const [updatingData, setUpdatingData] = useState<boolean>(false);

  const fetchFile = useCallback(async () => {
    setUpdatingData(true);
    try {
      if (!_id) return;
      if (shouldFetchFromFss) {
        try {
          const distributionUrl = await getDistributionUrl(client, _id);
          setExtension(getExtensionFromDistributionUrl(distributionUrl));
          setFile(distributionUrl);
          if (!isMobileWeb && !isIOS) {
            const fileBlob = await getDocumentFromS3UsingDistributionUrl(
              distributionUrl
            );
            setFileData(fileBlob);
          }
        } catch (error) {
          shouldFetchFromFss = false;
        }
      }
      if (!shouldFetchFromFss) {
        let fileDataUrl = await getCloudinaryDocument(_id);
        setFileData(fileDataUrl);
        setExtension(getExtension(fileDataUrl?.type));
        setFile(URL.createObjectURL(fileDataUrl));
      }
    } catch (error) {
      setPageError({
        isError: true,
        message:
          "The file could not be shown at the moment, Please try again later.",
      });
    } finally {
      setUpdatingData(false);
    }
  }, [_id]);

  useEffect(() => {
    fetchFile();
  }, [_id]);

  const handleViewDocument = async () => {
    try {
      if (isMobileWeb || isIOS) {
        openInAppBrowser(file);
      } else {
        const base64Data = await readBlobAsBase64(fileData);

        await Filesystem.writeFile({
          path: fileName,
          data: base64Data,
          directory: Directory.Documents,
        });

        setShowDownloadSuccess(true);
      }
    } catch (error) {
      setShowDownloadError(true);
    }
  };

  return updatingData ? (
    <IonSpinner class="ion-margin" name="lines" />
  ) : (
    <>
      {(isImage || (isDesktop && isPDF)) && (
        <IonCard>
          {isImage && <img alt="Document Preview" src={file} />}
          {isDesktop && isPDF && (
            <embed
              src={file}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          )}
        </IonCard>
      )}
      {!isDesktop && isPDF && (
        <>
          <IonToast
            isOpen={showDownloadSuccess}
            onDidDismiss={() => setShowDownloadSuccess(false)}
            message={`${fileName} has been downloaded correctly. Check your file manager`}
            duration={2500}
            color="success"
            position="top"
          />
          <IonToast
            isOpen={showDownloadError}
            onDidDismiss={() => setShowDownloadError(false)}
            message={`Error occurred while processing ${fileName}`}
            duration={2500}
            color="danger"
            position="top"
          />
          <IonItem detail={false} onClick={handleViewDocument}>
            <IonLabel class="ion-text-wrap" color="primary">
              {isMobileWeb || isIOS
                ? "Click to view document"
                : "Click to download document"}
            </IonLabel>
          </IonItem>
        </>
      )}
    </>
  );
};

export { DocumentPreview };
