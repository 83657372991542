import { Geolocation } from "@capacitor/geolocation";
import { Shift } from "src/lib/interface";
import { logEvent } from "src/lib/analytics";
import {
  IonContent,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonIcon,
  isPlatform,
  IonListHeader,
  IonButton,
} from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { get, orderBy, groupBy, map } from "lodash";
import { environment } from "../../../environments/environment";
import RadarSdkTestPanel from "../../../lib/test-helpers/components/radarSdkTestPanel";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { Store } from "../../store/store.model";
import { fetchUpcomingShift, fetchUrgentShifts } from "./api";
import { ShiftsList } from "./shiftsList";
import { BackgroundImageLayout } from "./backgroundImageLayout";

import { USER_EVENTS, SEARCH_MODE } from "src/constants";
import { RefresherEventDetail } from "@ionic/core";
import { UrgentShiftPageProps } from "./model";
import { hasLocationPermission, startTracking } from "./utils";
import { showToast } from "../../layout/toast";
import ShiftBookingInfoModal from "./shiftBookingInfoModal";
import { App } from "@capacitor/app";
import moment from "moment-timezone";
import { Diagnostic } from "@ionic-native/diagnostic";
import { logErrors } from "../../errorBoundary/api";
import { locationService } from "./locationService";
import { DestinationGeofenceTag, TripMode } from "./constants";

const UrgentShifts: React.FC<UrgentShiftPageProps> = ({ segmentView }) => {
  const history = useHistory();
  const agent = useSelector((state: Store) => get(state, "session.agent", {}));

  const [agentCoordinates, setAgentCoordinates] = useState<
    number[] | undefined
  >(undefined);

  const [loader, setLoader] = useState(true);
  const [urgentShifts, setUrgentShifts] = useState<Array<Shift>>([]);
  const [isShiftInfoModalOpen, setIsShiftInfoModalOpen] =
    useState<boolean>(false);

  const ldClient = useLDClient();

  const urgentShiftsConfig = ldClient?.variation("urgent-shifts-config");
  const radarSdkConfig = ldClient?.variation("radar-sdk-config");

  const onUrgentShiftsTab = segmentView === "urgentShifts";

  const sortShiftsByTimeAndDistance = (shifts: Shift[]) => {
    const shiftsGrouped = groupBy(
      shifts,
      (shift) =>
        (shift.facility?.name as string) +
        shift.start +
        shift.end +
        shift.agentReq
    );

    let orderedShifts = orderBy(
      Object.values(shiftsGrouped).map(
        (shiftGroup) => (shiftGroup as any[])[0]
      ),
      ["urgency", "start", "distance"],
      ["asc", "asc", "asc"]
    );
    return orderedShifts;
  };

  const updateUrgentShifts = () => {
    if (agentCoordinates === undefined) return;

    const query: { coordinates?: number[] } = { coordinates: agentCoordinates };

    setLoader(true);
    fetchUrgentShifts(query)
      .then((shifts) => {
        setUrgentShifts(sortShiftsByTimeAndDistance(shifts || []));
      })
      .catch((err) => {
        showToast(
          urgentShiftsConfig.urgentShiftPageQueryErrorMessage ||
            "Could not load Urgent Shifts! Please Refresh the page again!"
        );
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updateAgentsCurrentLocation = async () => {
    try {
      if (!isPlatform("capacitor")) throw new Error();

      const enabled = await hasLocationPermission();
      if (!enabled) throw new Error();

      const location = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 100,
      });
      if (!location) throw new Error();
      const { coords } = location;
      setAgentCoordinates([coords.longitude, coords.latitude]);
    } catch (err) {
      setAgentCoordinates(agent?.geoLocation?.coordinates);
    }
  };

  useEffect(() => {
    if (!onUrgentShiftsTab) return;

    logEvent(USER_EVENTS.VIEWED_URGENT_SHIFTS, {
      hcpUserId: agent.userId,
      hcpName: agent.name,
      timestamp: moment().format(),
    });

    updateAgentsCurrentLocation();
  }, [segmentView, agent]);

  useEffect(() => {
    if (!onUrgentShiftsTab) return;

    updateUrgentShifts();
  }, [agentCoordinates]);

  useEffect(() => {
    const checkForUpcomingShiftAndStartTracking = async () => {
      if (!isPlatform("capacitor")) return;

      const locationAvailable = await Diagnostic.isLocationAvailable();

      if (!locationAvailable) return;

      const response = await fetchUpcomingShift(
        radarSdkConfig.upcomingShift?.startHourThreshold
      );

      if (!response?.upcomingShift) return;

      const { _id, agentId, facilityId, start } = response.upcomingShift;

      logEvent(USER_EVENTS.TRIP_TRACKING_CALLED_ON_APP_OPEN, {
        shiftId: _id,
        workerId: agentId,
        startTime: new Date(start),
      });

      const radarConfigsByPlatform = isPlatform("ios")
        ? radarSdkConfig?.ios
        : radarSdkConfig?.android;

      try {
        locationService.initialize(environment.radarSdkPublishableKey);
        await locationService.startTrip({
          options: {
            externalId: `${_id}-${agentId}`,
            destinationGeofenceTag: DestinationGeofenceTag,
            destinationGeofenceExternalId: facilityId,
            mode: TripMode,
            metadata: {
              shiftId: _id,
            },
          },
        });
        await startTracking(radarConfigsByPlatform);

        logEvent(USER_EVENTS.TRIP_TRACKING_STARTED_ON_APP_OPEN, {
          shiftId: _id,
          workerId: agentId,
          startTime: new Date(start),
        });
      } catch (error) {
        logErrors({
          message: `Error while starting trip in foreground, ${JSON.stringify(
            error?.stack || error
          )}`,
          app: "hcpMobile",
        });
      }
    };

    const listener = App.addListener("appStateChange", async (state) => {
      if (!state.isActive) return;

      await checkForUpcomingShiftAndStartTracking();
    });

    (async () => {
      checkForUpcomingShiftAndStartTracking();
    })();

    return () => {
      listener?.remove();
    };
  }, []);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await updateUrgentShifts();
    event.detail.complete();
  };

  const goToDocumentsPage = () => {
    history.push("/home/account/documents");
  };

  const goToMyShiftsPage = () => {
    history.push("/home/myShifts");
  };

  const onShiftClaim = async (shiftId, isAlreadyGrabbed, startAt) => {
    updateUrgentShifts();

    const hasShiftWithSameStart = [
      ...map(
        urgentShifts.filter((shift) => shift._id !== shiftId),
        "start"
      ),
    ].includes(startAt);

    if (hasShiftWithSameStart && isAlreadyGrabbed) {
      logEvent("Viewed Instant Book Fail Msg - available", {
        shiftId,
        message:
          "User viewed Instant book failed message and similar shifts were available",
      });
    } else if (!hasShiftWithSameStart && isAlreadyGrabbed) {
      logEvent("Viewed Instant Book Fail Msg - none", {
        shiftId,
        message:
          "User viewed Instant book failed message and no similar shifts were available",
      });
    } else {
      goToMyShiftsPage();
    }
  };

  const hideShiftInfoModal = () => {
    setIsShiftInfoModalOpen(false);
  };

  const showShiftInfoModal = () => {
    setIsShiftInfoModalOpen(true);
  };

  return (
    <Fragment>
      {(process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) !==
        "production" &&
        !environment.production && (
          <div style={{ zIndex: 1000 }}>
            <RadarSdkTestPanel />
          </div>
        )}

      {loader ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            width="250"
            height="250"
            src="/assets/gifs/loadingAvailableShifts.gif"
            alt="Loading available shifts"
          />
        </div>
      ) : (
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <ShiftBookingInfoModal
            hideModal={hideShiftInfoModal}
            visible={isShiftInfoModalOpen}
          />

          <BackgroundImageLayout />
          <div className="urgent-shifts-content container">
            {urgentShifts && urgentShifts.length > 0 ? (
              <>
                <IonLabel className="urgent-shifts-content-heading">
                  These facilities need a healthcare professional like you as
                  soon as possible to care for their patients.
                  <br />
                  <br /> Book now!
                </IonLabel>
                <IonListHeader className="shift-list-heading">
                  <IonLabel className="shift-list-heading-label">
                    Urgent shifts available to book
                  </IonLabel>
                  <IonButton
                    onClick={showShiftInfoModal}
                    fill="clear"
                    className="shift-list-heading-info"
                  >
                    <IonIcon icon={informationCircleOutline} />
                  </IonButton>
                </IonListHeader>
                <ShiftsList
                  shifts={urgentShifts}
                  goToDocumentsPage={goToDocumentsPage}
                  onShiftClaim={onShiftClaim}
                  searchMode={SEARCH_MODE.URGENT_SHIFTS}
                />
              </>
            ) : (
              <div className="no-urgent-shift-container">
                <div className="title">
                  <span>
                    No Urgent Shifts right now
                    <IonIcon
                      onClick={showShiftInfoModal}
                      className="icon information-icon"
                      icon={informationCircleOutline}
                    />
                  </span>
                </div>
                <div className="subtitle">
                  Sorry, there are no Urgent Shifts available to book at this
                  time. Please check back later.
                </div>
              </div>
            )}
          </div>
        </IonContent>
      )}
    </Fragment>
  );
};

export default UrgentShifts;
