import { NFC, NfcTag, Ndef } from "@ionic-native/nfc";
import { isPlatform } from "@ionic/react";
import {
  DeviceNFCCapabilityForShift,
  NFCReadStatus,
} from "src/lib/interface/src/lib/shift";
import { USE_MOCKED_NFC_FOR_DEVELOPMENT } from "../../constants";
import { MockNFCHelper } from "./mockNfcHelper";

const parseNfcTag = (tag: NfcTag, isNFCExternalWriteEnabled: boolean) => {
  let payload = "";
  if (tag.ndefMessage) {
    let firstTagMessage = tag.ndefMessage[0];
    payload = NFC.bytesToString(firstTagMessage?.payload);
    if (
      isNFCExternalWriteEnabled &&
      getReadStatus(payload) !== NFCReadStatus.SUCCESS
    )
      payload = Ndef.textHelper.decodePayload(firstTagMessage?.payload);
  }
  return payload;
};

const startReaderModeForAndroid = async (
  isNFCExternalWriteEnabled: boolean,
  onSuccessRead
): Promise<string | undefined> => {
  let flags = NFC.FLAG_READER_NFC_A | NFC.FLAG_READER_NFC_V;
  return new Promise((resolve) => {
    NFC.readerMode(flags).subscribe(
      (data) => {
        resolve(onSuccessRead(parseNfcTag(data, isNFCExternalWriteEnabled)));
      },
      (error) => {
        console.error("Error when reading NFC:", error);
        resolve("");
      }
    );
  });
};

const startNDEFScan = async (
  isNFCExternalWriteEnabled: boolean,
  onSuccessRead
) => {
  try {
    let tag = await NFC.scanNdef();
    return onSuccessRead(parseNfcTag(tag, isNFCExternalWriteEnabled));
  } catch (err) {
    console.error("Error when reading NFC:", err);
    return "";
  }
};

const readNFCTagInformation = async (
  isNFCExternalWriteEnabled: boolean,
  onSuccessRead
): Promise<string | undefined> => {
  if (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
    await startNDEFScan(isNFCExternalWriteEnabled, onSuccessRead);
    return;
  } else if (isPlatform("android")) {
    await startReaderModeForAndroid(isNFCExternalWriteEnabled, onSuccessRead);
    return;
  }
  return "";
};

const cancelNfcScan = () => {
  if (isPlatform("ios") || isPlatform("iphone") || isPlatform("ipad")) {
    NFC.cancelScan();
  }
};

const deviceNfcCapabilityForShift = async () => {
  let isNFCEnabled = DeviceNFCCapabilityForShift.NO_NFC;
  await NFC.enabled()
    .then(() => {
      isNFCEnabled = DeviceNFCCapabilityForShift.NFC_ENABLED;
    })
    .catch((err) => {
      console.error("Error in checking NFC capabilities: " + err);
      isNFCEnabled = err;
    });
  return isNFCEnabled;
};

const getReadStatus = (facilityNFCHash: string | undefined): NFCReadStatus => {
  // NFC not read
  if (facilityNFCHash === undefined || facilityNFCHash === null) {
    return NFCReadStatus.FAILED;
  }
  // NFC hash invalid
  if (!facilityNFCHash.startsWith("CBH-")) {
    return NFCReadStatus.INVALID_HASH;
  }
  return NFCReadStatus.SUCCESS;
};

export const getNFCReadStatus = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.getReadStatus
  : getReadStatus;
export const deviceNFCCapabilityForShift = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.deviceNFCCapabilityForShift
  : deviceNfcCapabilityForShift;
export const readNFCTagInfo = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.readNFCTagInfo
  : readNFCTagInformation;
export const cancelNFCScan = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.cancelNFCScan
  : cancelNfcScan;
