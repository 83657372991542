enum FeatureFlag {
  CAMERA_IMAGE_QUALITY = "camera-image-quality-config",
  ENABLE_CHECKR_PILL_STATUS = "enable-checkr-pill-status",
  DUAL_WRITES_FILE_STORAGE_SERVICE = "dual-writes-file-storage-service",
  ATTENDANCE_POLICY = "attendance-policy",
  STATUS_QUO_ATTENDANCE_POLICY_LINKS = "status-quo-attendance-policy-links",
  DOCUMENT_VERIFICATION_PROCESS = "document-verification-process",
  ATTENDANCE_SCORE_POLICY_LINKS = "attendance-score-policy-links",
  MINIMUM_PAY_FILTER_ACTIVE = "minimum-pay-filter-active",
  BASE_RATE_PAY_FILTERS = "base-rate-pay-filters",
  BASE_RATE_MULTIPLE_PAY_FILTERS = "base-rate-multiple-pay-filters",
  ENABLE_EXTERNAL_NFC_WRITE = "enable-external-nfc-write",
  READS_FILE_STORAGE_MOBILE = "reads-file-storage-mobile",
  TIMESHEETSV2_REFACTORED_VERSION = "timesheetsv2-refactored-version",
  TIMESHEETS_V2_NON_IP = "timesheets-v2-non-IP",
  DISPLAY_TIME_SHEET_SUMMARY = "display-time-sheet-summary",
  DIGITAL_SIGNATURE = "digital-signature",
  USE_ORIGINALAMOUNT_FIELD_NON_IP = "use-originalamount-field-non-ip",
  MINIMUM_PAY_HIDDEN_SHIFTS = "minimum-pay-hidden-shifts",
  CHAT = "chat",
  LICENSE_MANAGER_ACTIVATION = "license-manager-activation",
  AUTO_VERIFICATION_DOCUMENT_CLEANUP_ENABLED = "auto-id-deletes-abandoned-documents",
}

export { FeatureFlag };
