import "../style.scss";

import { IonAvatar, IonIcon, IonModal } from "@ionic/react";
import { camera } from "ionicons/icons";
import React, { Fragment, useState } from "react";

import { UpdateProfileImage } from "./updateImage";

const ProfileImage = ({ url }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <div className="profilePic" onClick={handleShowModal}>
        <IonAvatar
          style={{
            width: 80,
            height: 80,
            backgroundColor: "var(--ion-color-light-shade)",
          }}
        >
          {url && <img src={url} alt="profile" />}
        </IonAvatar>
        <div className="profileButton">
          <IonIcon icon={camera} style={{ fontSize: "26px" }} />
        </div>
      </div>
      <IonModal isOpen={showModal} onDidDismiss={onClose}>
        <UpdateProfileImage url={url} onClose={onClose} />
      </IonModal>
    </Fragment>
  );
};

export { ProfileImage };
