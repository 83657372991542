import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";
import {
  AttendanceScoreCancellationPolicy,
  AttendanceScoreProfile,
  AttendanceStats,
} from "./types";

export const getAttendanceStats = async (
  agentId: string
): Promise<AttendanceStats> => {
  try {
    const {
      body: { data },
    } = await request
      .get(`${environment.baseUrl}/attendanceStats/${agentId}`)
      .set(await getAuthHeader());
    return data;
  } catch (error) {
    logApiFailureEvent(error);

    throw error;
  }
};

export const getAttendanceScoreProfile = async (
  agentId: string
): Promise<AttendanceScoreProfile> => {
  try {
    const { body } = await request
      .get(
        `${environment.attendanceScoreServiceUrl}/api/workers/${agentId}/profile`
      )
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export const getAttendanceScoreCancellationPolicy = async (
  agentId: string,
  leadTime: number
): Promise<AttendanceScoreCancellationPolicy> => {
  try {
    const { body } = await request
      .get(
        `${environment.attendanceScoreServiceUrl}/api/cancellations/policy?workerUserId=${agentId}&leadTime=${leadTime}`
      )
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};
