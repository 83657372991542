import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import { environment } from "@env/environment";
import { SessionEnv } from "@store/session";
import { getAuthHeader } from "@app/superagent";
import { logFailedApiRetry } from "@app/utils/api_retry";

const { baseUrl } = environment;

// TODO merge this with fetchEnvVariagbles inside store API
export const fetchEnvVariablesWithPushNotificationOnAccountTab =
  async (): Promise<SessionEnv> => {
    return await request
      .get(`${baseUrl}/envVariables`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/envVariables`);
        return true;
      })
      .set(await getAuthHeader())
      .then(({ body }) => {
        if (typeof body.PushNotificationOnAccountTab == "string")
          body.PushNotificationOnAccountTab = JSON.parse(
            body.PushNotificationOnAccountTab
          );
        return body;
      })
      .catch(logApiFailureEvent);
  };
