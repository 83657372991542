import { IonItem } from "@ionic/react";
import { StatsContainerProps } from "./types";
import "./style.scss";

export const StatsContainer = ({ stats }: StatsContainerProps) => {
  return (
    <IonItem lines="none">
      <div className="ratings-stats-container">
        {stats.map(({ count, label }, index) => (
          <div key={index} className="ratings-stat">
            <h1>{count}</h1>
            <h6>{label}</h6>
          </div>
        ))}
      </div>
    </IonItem>
  );
};
