import { logErrors } from "../../errorBoundary/api";
import { USER_EVENTS } from "../../../constants/userEvents";
import OnboardingErrorLog from "../../../types/onboarding/OnboardingErrorLog";

function logOnboardingError(
  stage: string,
  message: string,
  hcpId?: string,
  eventMeta?: Record<string, string | undefined>
) {
  const payload: OnboardingErrorLog = {
    title: `Signup API failed to complete ${stage} stage`,
    details: {
      error: message,
    },
    logArea: "SERVER",
    logType: USER_EVENTS.ONBOARDING_ERROR,
    featureName: "SIGNUP",
  };

  if (hcpId) {
    payload["hcpId"] = hcpId;
  }

  if (eventMeta) {
    Object.assign(payload.details, eventMeta);
  }

  logErrors({
    message: JSON.stringify(payload),
    app: "hcpMobile",
  });
}

export { logOnboardingError };
