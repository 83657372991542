const SIZE = 40;
const RADIO = SIZE / 2;
const PADDING = 5;

interface CircleProps {
  percentage: number;
  color: string;
}
const Circle = ({ color, percentage }: CircleProps) => {
  const c = Math.PI * RADIO * 2;
  const pct = ((100 - percentage) / 100) * c;

  return (
    <circle
      transform="rotate(-90, 20, 20)"
      r={RADIO}
      cx={RADIO}
      cy={RADIO}
      fill="transparent"
      stroke={color}
      strokeWidth={".5rem"}
      strokeDasharray={c}
      strokeDashoffset={pct}
    ></circle>
  );
};

const Text = ({ progress, steps }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={".7em"}
    >
      {progress}/{steps}
    </text>
  );
};

interface PieProps {
  steps: number;
  progress: number;
}

export const Pie = ({ steps, progress }: PieProps) => {
  return (
    <svg width={SIZE + PADDING * 2} height={SIZE + PADDING * 2}>
      <g transform={`translate(${PADDING}, ${PADDING})`}>
        <Circle color="lightgrey" percentage={100} />
        <Circle color="#10B981" percentage={(progress / steps) * 100} />
      </g>
      <Text steps={steps} progress={progress} />
    </svg>
  );
};
