import { useHistory } from "react-router-dom";
import { Address as AddressBody, Location } from "src/lib/interface";
import { get, isEmpty } from "lodash";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddressViewMap } from "./addressViewMap";
import { logEvent } from "src/lib/analytics";
import { MapMarkerIcon } from "../../icons/mapMarker";
import { setAddressEditDetails } from "../store/accountSettings";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { Store } from "../store/store.model";
import { USER_EVENTS } from "../../constants/userEvents";

const AddressView: React.FC = () => {
  const [address, setAddress] = useState<AddressBody>({});
  const [location, setLocation] = useState<Location | null>(null);

  const { agent } = useSelector((state: Store) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const { networkStatus } = useSelector((state: Store) => state.session);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_ADDRESS);
  });

  const onEdit = () => {
    const formattedAddress = get(address, "formatted", "");
    if (formattedAddress && location) {
      dispatch(
        setAddressEditDetails({
          searchLocation: formattedAddress,
          address,
          location,
          addressLine1: address.line1 || "",
        })
      );
    } else {
      dispatch(setAddressEditDetails({}));
    }
    history.push(`/home/account/searchAddressLocation`);
  };

  useEffect(() => {
    if (agent) {
      const { geoLocation, address } = agent;
      const lat = get(geoLocation, "coordinates[1]");
      const lng = get(geoLocation, "coordinates[0]");
      if (address && !isEmpty(address) && lat && lng) {
        setAddress(address);
        setLocation({ lat, lng });
      } else {
        setAddress({});
        setLocation(null);
        history.push(`/home/account/searchAddressLocation`);
      }
    } else {
      setAddress({});
      setLocation(null);
      history.push(`/home/account/searchAddressLocation`);
    }
  }, [agent, history]);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Address</IonTitle>
        </IonToolbar>
      </IonHeader>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <>
          <IonContent>
            <AddressViewMap location={location} MapMarkerIcon={MapMarkerIcon} />
          </IonContent>
          <IonFooter>
            <IonCard>
              <IonItem>
                <IonLabel>
                  <strong>Address</strong>{" "}
                </IonLabel>
                <IonButton fill="outline" slot="end" onClick={onEdit}>
                  Edit
                </IonButton>
              </IonItem>

              <IonCardContent>
                {address && address.formatted
                  ? `${address.line1 || ""} ${address.formatted}`
                  : "No Saved Address"}
              </IonCardContent>
            </IonCard>
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export { AddressView };
