import React, { useMemo, useState } from "react";
import {
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonLabel,
  IonRadio,
  IonItem,
  IonContent,
  IonModal,
  IonIcon,
  IonButton,
  IonTextarea,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import {
  FACILITY_CANCELLED_ME_OPTIONS,
  FACILITY_CANCEL_ME_FAILURE_MESSAGE_OPTION,
  FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION,
} from "../constants";
import { closeCircleSharp, alertCircleOutline } from "ionicons/icons";
import { TextareaChangeEventDetail } from "@ionic/core";
import { Shift } from "src/lib/interface/src";
import { useHistory } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { AttendancePolicy } from "src/constants/attendancePolicy";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";
import {
  FacilityCancelledMeRequestPayload,
  requestFacilityCancelledMeConfirmation,
} from "../../api/shift";
import { useDispatch } from "react-redux";
import { setShowToast } from "@src/app/store/shift";

export const FacilityCancelledMeModal: React.VFC<{
  isOpen: boolean;
  closeModal: () => void;
  shift: Shift;
}> = ({ isOpen, closeModal, shift }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [cancelReasonDescription, setCancelReasonDescription] =
    useState<string>("");
  const [isTextAreaActive, setIsTextAreaActive] = useState<boolean>(false);

  const ldClient = useLDClient();
  const isAttendanceScorePolicyEnabled =
    ldClient?.variation(FeatureFlag.ATTENDANCE_POLICY) ===
    AttendancePolicy.ATTENDANCE_SCORE;

  const cancelButtonText = useMemo<string>(() => {
    if (!cancellationReason) {
      return "Please select a reason";
    }
    return cancelReasonDescription
      ? "Request Facility Confirmation"
      : "Add description to cancel";
  }, [cancellationReason, cancelReasonDescription]);

  const onSubmitFacilityCancelShift = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const payload: FacilityCancelledMeRequestPayload = {
      shiftId: shift._id as string,
      reason: cancellationReason,
      description: cancelReasonDescription,
    };

    try {
      await requestFacilityCancelledMeConfirmation(payload);
      dispatch(
        setShowToast(FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION.toastName)
      );
      onDismiss();
      history.push("/home/myShifts");
    } catch (error) {
      setErrorMessage(FACILITY_CANCEL_ME_FAILURE_MESSAGE_OPTION.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onDismiss = () => {
    setCancellationReason("");
    setCancelReasonDescription("");
    setSuccessMessage(null);
    closeModal();
  };

  return (
    <IonModal
      cssClass="attendance-policy-cancel-modal"
      data-testid="facility-cancelled-me-modal"
      isOpen={isOpen}
      swipeToClose
      backdropDismiss
      onDidDismiss={onDismiss}
    >
      <IonContent data-testid="facility-cancelled-me-modal-content">
        <IonList>
          <IonRadioGroup
            value={cancellationReason}
            onIonChange={(e) => setCancellationReason(e.detail.value)}
            data-testid="cancellation-reason-radio-group"
          >
            <IonListHeader>
              <IonLabel>Select facility's cancellation reason</IonLabel>
              <IonIcon
                data-testid="cancel-button-attendance-policy-facility-cancel"
                onClick={onDismiss}
                icon={closeCircleSharp}
                mode="ios"
                class="ion-float-right"
                className="cancel-button-attendance-policy-cancel-modal"
              />
            </IonListHeader>

            {FACILITY_CANCELLED_ME_OPTIONS.map(([value, text]) => (
              <IonItem key={value} className="modal-items" lines="none">
                <IonLabel>{text}</IonLabel>
                <IonRadio
                  value={value}
                  slot="start"
                  mode="md"
                  className="modal-radio"
                />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
        {cancellationReason && (
          <div className="description-container ion-text-area-80">
            <h4 className="tell-us font-600">Tell us what happened</h4>
            <IonTextarea
              onIonFocus={() => setIsTextAreaActive(true)}
              onIonBlur={() => setIsTextAreaActive(false)}
              className={`reason-description ${
                isTextAreaActive ? "active" : ""
              }`}
              placeholder="Describe why the facility cancelled your shift. Please include the name of who at the facility cancelled your shift. (required)"
              data-testid="cancel-shift-reason-textarea"
              onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) =>
                setCancelReasonDescription(e.detail.value ?? "")
              }
              value={cancelReasonDescription}
            />
            <IonItem
              className="facility-cancelled-share-warning-container"
              lines="none"
            >
              <div className="facility-cancelled-share-warning">
                This information will be shared with the facility.
              </div>
              <IonIcon slot="start" icon={alertCircleOutline} mode="ios" />
            </IonItem>
          </div>
        )}
      </IonContent>
      {isAttendanceScorePolicyEnabled && (
        <div
          className="facility-cancelled-message"
          data-testid="cancellation-policy-message"
        >
          Your Attendance Score will be penalized if the facility rejects they
          cancelled your shift. Please make this cancellation request at the
          facility for a prompt response.
        </div>
      )}
      <IonButton
        data-testid="cancel-shift-button"
        size="large"
        expand="block"
        shape="round"
        color="primary"
        disabled={!cancelReasonDescription}
        onClick={onSubmitFacilityCancelShift}
        className="shift-cancel-button margin-bottom-30"
      >
        {isLoading ? (
          <IonSpinner name="lines" data-testid="cancel-shift-button-loading" />
        ) : (
          cancelButtonText
        )}
      </IonButton>
      <IonToast
        isOpen={!!errorMessage}
        header={FACILITY_CANCEL_ME_FAILURE_MESSAGE_OPTION.header}
        onDidDismiss={() => setErrorMessage(null)}
        message={errorMessage ?? ""}
        duration={FACILITY_CANCEL_ME_FAILURE_MESSAGE_OPTION.duration}
        color="danger"
        position="top"
        data-testid="fcf-error-message"
      />
    </IonModal>
  );
};
