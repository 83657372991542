export enum LICENSE_STATUSES {
  PENDING = "pending",
  ACTIVE = "active",
  EXPIRED = "expired",
  REJECTED = "rejected",
  ARCHIVED = "archived",
  EXPIRING = "expiring",
}

export interface RemoveLicenseAlertProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}

export interface ICreateLicense {
  qualification: string;
  state: string;
  multiState: boolean;
  number: string;
  expiresAt: string;
}

export interface ILicenseDetails extends ICreateLicense {
  _id: string;
  status: LICENSE_STATUSES;
  createdAt: string;
  rejectedAt?: string;
  hasPendingLicense?: boolean;
}

export interface AddLicenseFormProps {
  licenseDetails: ICreateLicense;
  submitLicense: (license: ICreateLicense) => void;
  addingLicense: boolean;
  forOnboarding: boolean;
  disableForm: boolean;
}

export interface IWorkerType {
  id: number | string;
  name: string;
  description: string;
}
export interface IWorkTypesResponse {
  data: {
    workerTypes: IWorkerType[];
  };
}
