import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { PublicRouteProps } from "./model";
import { StatusRoute } from "./statusRoute";
import { GlobalRouterPath } from "./constant/globalRoute";

const PublicRoute: React.FC<PublicRouteProps> = ({
  isAuthorized,
  component,
  ...rest
}) => {
  const renderRoute = (props: RouteComponentProps) => {
    if (isAuthorized) {
      return <Redirect to={GlobalRouterPath.HOME} />;
    }
    // component will always be defined when passed
    // from PublicRoute
    const Component = component!;
    return <Component {...props} />;
  };
  return <StatusRoute {...rest} render={renderRoute} />;
};

export { PublicRoute };
