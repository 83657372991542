import React, { useMemo, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonButton,
  IonItem,
  IonLabel,
  IonLoading,
  IonSpinner,
} from "@ionic/react";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "src/constants/userEvents";
import { logApiFailureEvent } from "src/lib/analytics";
import { showModal } from "src/app/shared/modal";
import { ILicenseDetails, LICENSE_STATUSES } from "./interfaces";
import { getLicenseDetails, deleteLicense } from "./api";
import { RemoveLicenseAlert } from "./removeLicense";
import { LICENSE_DETAILS_HEADER_TEXT } from "./constants";
import "./style.scss";

const LicenseDetails: React.FC<{}> = () => {
  const history = useHistory();

  const [openRemoveLicenseModal, setOpenRemoveLicenseModal] =
    useState<boolean>(false);
  const [license, setLicense] = useState<ILicenseDetails | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingLicense, setDeletingLicense] = useState<boolean>(false);

  const { licenseId } = useParams<{ licenseId: string }>();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getLicenseDetails(licenseId);
        setLicense(response);
        logEvent(USER_EVENTS.LICENSE_VIEWED, {
          licenseStatus: response.status,
          daysUntilExpired: moment(response?.expiresAt).diff(moment(), "days"),
        });
      } catch (error) {
        logApiFailureEvent(error);
        showModal("error", "Error while getting license details.");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const headerTextContent = useMemo(() => {
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days");
    let headerText = LICENSE_DETAILS_HEADER_TEXT.ACTIVE;
    if (license?.status === LICENSE_STATUSES.PENDING) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.PENDING.replace(
        "{LICENSE_CREATED_AT}",
        moment(license?.createdAt).format("MM/DD/YYYY [at] hh:mm A")
      );
    } else if (
      daysUntilExpired <= 0 ||
      license?.status === LICENSE_STATUSES.EXPIRED
    ) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.EXPIRED;
      if (license?.hasPendingLicense) {
        headerText =
          LICENSE_DETAILS_HEADER_TEXT.EXPIRED_AND_HAS_PENDING_LICENSE;
      }
    } else if (license?.status === LICENSE_STATUSES.REJECTED) {
      headerText = LICENSE_DETAILS_HEADER_TEXT.REJECTED.replace(
        "{LICENSE_REJECTED_AT}",
        moment(license?.rejectedAt).format("MM/DD/YYYY [at] hh:mm A")
      );
      if (license?.hasPendingLicense) {
        headerText =
          LICENSE_DETAILS_HEADER_TEXT.REJECTED_AND_HAS_PENDING_LICENSE.replace(
            "{LICENSE_REJECTED_AT}",
            moment(license?.rejectedAt).format("MM/DD/YYYY [at] hh:mm A")
          );
      }
    }
    return headerText;
  }, [license]);

  const computedLicenseProps = useMemo(() => {
    const licenseProps = {
      status: license?.status,
      expirationTextColor: "",
    };
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days");
    if (daysUntilExpired <= 0 || license?.status === LICENSE_STATUSES.EXPIRED) {
      licenseProps.status = LICENSE_STATUSES.EXPIRED;
      licenseProps.expirationTextColor = "danger";
    } else if (daysUntilExpired <= 90) {
      licenseProps.status = LICENSE_STATUSES.EXPIRING;
      licenseProps.expirationTextColor = "warning";
    }
    return licenseProps;
  }, [license]);

  const handleDeleteLicense = () => {
    (async () => {
      try {
        setOpenRemoveLicenseModal(false);
        setDeletingLicense(true);
        const response: { success: boolean } = await deleteLicense(licenseId);
        logEvent(USER_EVENTS.LICENSE_REMOVED, {
          licenseStatus: license?.status,
          daysUntilExpired: moment(license?.expiresAt).diff(moment(), "days"),
        });
        history.push(`/home/account/licenses`);
      } catch (error) {
        logApiFailureEvent(error);
        showModal("error", "Error while deleting license.");
        setDeletingLicense(false);
      }
    })();
  };

  return (
    <IonPage>
      <IonLoading isOpen={loading} backdropDismiss={true} />
      {license?._id ? (
        <>
          <IonHeader no-border>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton
                  text=""
                  defaultHref="/home/account/licenses"
                  mode="ios"
                />
              </IonButtons>
              <IonTitle data-testid="license-details-title">{`${license.qualification} License (${computedLicenseProps.status})`}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="license-details">
            <IonText
              className="license-details-header"
              data-testid="license-details-header-text"
            >
              {headerTextContent}
            </IonText>
            <IonCard className="license-details-card">
              <IonCardContent className="license-details-card-content">
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>TYPE</p>
                    <IonText>
                      <h3>{license.qualification}</h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>STATE</p>
                    <IonText>
                      <h3>
                        {license.state} {license.multiState && "(multi-state)"}
                      </h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>LICENSE NUMBER</p>
                    <IonText>
                      <h3>{license.number || "--"}</h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel class="ion-text-wrap">
                    <p>EXPIRATION DATE</p>
                    <IonText
                      color={computedLicenseProps.expirationTextColor}
                      data-testid="license-expiration-date"
                    >
                      <h3>{moment(license.expiresAt).format("MM/DD/YYYY")}</h3>
                    </IonText>
                  </IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <IonButton
              expand="block"
              className="remove-license-button"
              fill="outline"
              onClick={() => {
                setOpenRemoveLicenseModal(true);
              }}
              disabled={deletingLicense}
            >
              {deletingLicense ? (
                <IonSpinner
                  name="lines"
                  data-testid="delete-license-button-loading"
                />
              ) : (
                "Remove License"
              )}
            </IonButton>
          </IonContent>
        </>
      ) : (
        <IonText className="ion-text-center">
          <p style={{ marginTop: "20px" }}>
            {loading
              ? "Loading License Details..."
              : "Unable to get license details"}
          </p>
        </IonText>
      )}
      ;
      {openRemoveLicenseModal && (
        <RemoveLicenseAlert
          isOpen={openRemoveLicenseModal}
          closeModal={() => setOpenRemoveLicenseModal(false)}
          onSubmit={handleDeleteLicense}
        />
      )}
    </IonPage>
  );
};

export { LicenseDetails };
