import React, { useEffect, useState, FormEvent } from "react";
import {
  IonButtons,
  IonButton,
  IonBackButton,
  IonContent,
  IonHeader,
  IonLabel,
  IonInput,
  IonToolbar,
  IonToast,
  IonSpinner,
  IonRow,
  IonCol,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { openBrowser } from "src/lib/ionic-components";
import { Keyboard } from "@capacitor/keyboard";
import crypto from "crypto";
import moment from "moment-timezone";

import { reloadAgentProfile } from "../../store/session";
import { Store } from "../../store/store.model";

import { StyledIonPage } from "./style";
import { updateAgentData } from "./api";
import { OnBoardingComponentProps } from "./model";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { addDataToIdentity } from "src/lib/analytics";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import "../style.scss";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getOigState } from "../util/oig";
import { OIG, OIG_VALIDATION } from "../constants/OIG_VALIDATION";

const AgentSSN: React.FC<OnBoardingComponentProps> = ({ nextStagePath }) => {
  const dispatch = useDispatch();
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [ssn, setSSN] = useState<string | null>(null);

  const { agent, profile } = useSelector((state: Store) => state.session);

  const maskedSSN =
    agent?.socialSecurityNumber && `xxx-xx-${agent.socialSecurityNumber}`;

  const history = useHistory();
  const { [OIG_VALIDATION]: oigValidationEnabled } = useFlags();

  const onNext = async (event?: FormEvent | MouseEvent): Promise<void> => {
    event && event.preventDefault();
    setShowErrorModal(false);
    try {
      if (ssn !== maskedSSN) {
        if (
          !ssn?.match("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$")
        ) {
          setPageError({ isError: true, message: "SSN is invalid" });
          return;
        }
        if (isPlatform("capacitor")) Keyboard.hide();
        setUpdatingData(true);

        const oig = oigValidationEnabled ? getOigState() : null;

        await updateAgentData({
          stage: ONBOARDING_STAGES.SSN,
          ssn: ssn.replace(/\D/g, ""),
          ...(oig?.ssnRequired && {
            validationId: oig.validationId,
            requestFurtherValidation: oig.ssnRequired,
          }),
        });

        fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_SSN, {
          hcpId: agent?.userId as string,
        });
      }
      dispatch(reloadAgentProfile());
      history.push(`${nextStagePath}`);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.SSN,
          (error as Error).message,
          agent?.userId
        );
        setShowErrorModal(true);
        return;
      }
      setPageError({
        isError: true,
        message: "Unexpected error occurred while saving ssn",
      });
    } finally {
      setUpdatingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  //Function to format input field to SSN
  const formatToSSN = (value: string) => {
    return value
      .replace(/[^0-9x]/g, "")
      .replace(/^(\w{3})/, "$1-")
      .replace(/-(\w{2})/, "-$1-")
      .replace(/(\w)-(\w{4}).*/, "$1-$2");
  };

  const handleOnChange = (event: Event) => {
    const target = event.target as EventTarget;
    target["value"] = target["value"].replace(/[^0-9x-]/g, "");
    setSSN(target["value"]);
  };

  //To format the input only when the key pressed is not backspace and delete
  const handleKeyUp = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    let ssNo = event.target["value"] as string;
    if (event.key !== "Backspace" && event.key !== "Delete") {
      ssNo = formatToSSN(ssNo);
    }
    setSSN(ssNo);
  };

  //To prevent inputs which are not number
  const handleKeyPress = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (maskedSSN) {
      setSSN(maskedSSN);
    }
  }, [agent]);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentAddress"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What is your SSN?</h4>
                  </div>

                  <div>
                    <IonLabel className="form-label">
                      Social Security Number
                    </IonLabel>
                    <IonInput
                      name="ssn"
                      className="form-input"
                      type="text"
                      onIonChange={handleOnChange}
                      onKeyUp={handleKeyUp}
                      onKeyPress={handleKeyPress}
                      value={ssn}
                      maxlength={11}
                      disabled={updatingData}
                      autofocus
                      required
                    />
                  </div>
                  <p className="ssn-text">
                    We take data privacy seriously. We store an encrypted
                    version of your SSN, and we only provide it to government
                    agencies and background check services to stay compliant
                    with relevant State and Federal laws.
                    <a
                      href="https://intercom.help/clipboard-health-help-center/en/articles/5539659-why-does-clipboard-request-my-ssn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Click here{" "}
                    </a>
                    to learn more about why we need your SSN, and what we do to
                    keep your personal information secure.
                  </p>

                  <p>Start typing to see what we see:</p>
                  <div className={"ssn-crypted-text"}>
                    <p>{ssn && crypto.randomBytes(200).toString("hex")}</p>
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              disabled={updatingData || ssn?.length !== 11}
              onClick={onNext}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentSSN };
