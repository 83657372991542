import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
  IonBackButton,
  IonButtons,
  IonListHeader,
  IonItem,
} from "@ionic/react";
// import ExpertiseForm from './expertise'
import SedationForm from "./sedation";
import { ExperienceProps } from "../model";
import TrayAssemblyForm from "./trayAssembly";
import { workerTypeObj } from "src/lib/constants";
import { SpecialityDropDown } from "src/lib/ionic-components";

const Experience: React.FC<ExperienceProps> = ({
  agent,
  updateDetails,
  specialities,
}) => {
  // const [showExpertise, setShowExpertise] = useState(false)
  const [showSedation, setShowSedation] = useState<boolean>(false);
  const [showTrayAssembly, setShowTrayAssembly] = useState<boolean>(false);

  const {
    experience,
    hasSedationExperience,
    hasTrayAssemblyExperience,
    experienceDetails,
  } = specialities;
  const agentId = agent?.userId;

  useEffect(() => {
    if (!agent) {
      return;
    }
    switch (agent.qualification) {
      // case workerTypeObj.OR_RN:
      //   setShowExpertise(true)
      //   break
      case workerTypeObj.PACU_RN:
        setShowSedation(true);
        break;
      case workerTypeObj.SPD_TECH:
        setShowTrayAssembly(true);
        break;
      default:
        break;
    }
  }, [agent]);

  // const isSelected = value => experience.includes(value)
  const onChange = async (values: string[]) => {
    await updateDetails({ ...specialities, experience: values }, agentId);
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Specialities</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonListHeader style={{ justifyContent: "center", padding: 0 }}>
          <h5>Please Select your expertise</h5>
        </IonListHeader>
        {/* {showExpertise && <ExpertiseForm experience={experience}
          updateDetails={updateDetails} experienceDetails={experienceDetails} agentId={agentId} />} */}
        {showSedation && (
          <SedationForm
            hasSedationExperience={hasSedationExperience}
            experience={experience}
            updateDetails={updateDetails}
            agentId={agentId}
          />
        )}
        {showTrayAssembly && (
          <TrayAssemblyForm
            experience={experience}
            hasTrayAssemblyExperience={hasTrayAssemblyExperience}
            updateDetails={updateDetails}
            agentId={agentId}
          />
        )}
        <IonItem lines="none">Select Specialities</IonItem>
        <IonItem lines="none">
          {experience && (
            <SpecialityDropDown
              onChange={onChange}
              multiple={true}
              defaultSelected={experience}
            />
          )}
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export { Experience };
