import "./style.scss";
import React, { useEffect, useState } from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonText,
} from "@ionic/react";
import moment from "moment";
import { checkmarkCircle, closeCircle } from "ionicons/icons";
import { transformMessage } from "../../../utils/strings";
import { MessageString } from "../../../constants/strings";

const { extraTimePayCard: etpStrings } = MessageString;

const getOfferLine = (multiplier: Number, threshold: Number) =>
  `${multiplier}x the pay rate for every hour over ${threshold} hours`;

const ExtraTimePayCard = ({ facility, shift }) => {
  const date = moment().format("YYYY-MM-DD");

  const {
    enabled,
    multiplier,
    threshold,
    multiplier2,
    threshold2,
    single,
    backToBack,
    forty,
  } = facility.tbioOptions;

  const extraTimePayOptions: {
    offered: boolean;
    header: string;
    lines: string[];
  }[] = [];

  const singleBackToBackOfferLines = [
    getOfferLine(multiplier, threshold),
    multiplier2 && threshold2 && getOfferLine(multiplier2, threshold2),
  ];

  if ((single && backToBack) || (!single && !backToBack)) {
    extraTimePayOptions.push({
      offered: single,
      header: etpStrings.singleAndBackToBackHeader,
      lines: single
        ? singleBackToBackOfferLines
        : [etpStrings.singleOrBackToBackNotOfferedText],
    });
  } else {
    extraTimePayOptions.push(
      {
        offered: single,
        header: etpStrings.singleHeader,
        lines: single
          ? singleBackToBackOfferLines
          : [etpStrings.singleNotOfferedText],
      },
      {
        offered: backToBack,
        header: etpStrings.backToBackHeader,
        lines: backToBack
          ? singleBackToBackOfferLines
          : [etpStrings.backToBackNotOfferedText],
      }
    );
  }

  const fortySuuffx = ", if not already earned working ";
  const fortySuffixLabels: string[] = [];

  if (single) {
    fortySuffixLabels.push("a single shift");
  }

  if (backToBack) {
    fortySuffixLabels.push("back-to-back shifts");
  }

  extraTimePayOptions.push({
    header: etpStrings.fortyHeader,
    offered: forty,
    lines: forty
      ? [
          transformMessage(etpStrings.fortyOfferedText, { multiplier }) +
            (fortySuffixLabels.length > 0
              ? fortySuuffx + fortySuffixLabels.join(" or ")
              : ""),
        ]
      : [etpStrings.fortyNotOfferedText],
  });

  const today = moment(date).format("MMMM DD, YYYY");

  const etpOffers = extraTimePayOptions.filter((option) => option.offered);
  const etpNoOffers = extraTimePayOptions.filter((option) => !option.offered);

  return (
    <div className="extra-pay-content">
      <IonText className="ion-no-padding extra-pay-period">
        As of {today}
      </IonText>

      {enabled && etpOffers.length > 0 && (
        <>
          <IonListHeader className="extra-pay-header">
            <IonLabel
              color="text"
              className="ion-no-margin ion-no-padding text"
            >
              Types of Extra Time Pay offered
            </IonLabel>
          </IonListHeader>

          <IonList className="ion-no-padding extra-pay-list">
            {etpOffers.map(({ header, lines }) => (
              <IonItem
                key={header}
                lines="none"
                className="ion-no-padding extra-pay-item"
              >
                <IonText>
                  <IonRow className="ion-align-items-center">
                    <IonIcon
                      icon={checkmarkCircle}
                      style={{ color: "#1c69d1" }}
                    />
                    <span className="extra-pay-title">{header}</span>
                  </IonRow>
                  {lines &&
                    lines.map(
                      (line) => line && <p className="extra-pay-text">{line}</p>
                    )}
                </IonText>
              </IonItem>
            ))}
          </IonList>
        </>
      )}

      {etpNoOffers.length > 0 && (
        <>
          <IonListHeader className="ion-margin-top extra-pay-header">
            <IonLabel
              color="text"
              className="ion-no-margin ion-no-padding text"
            >
              Types of Extra Time Pay not offered
            </IonLabel>
          </IonListHeader>
          <IonList className="ion-no-padding extra-pay-list">
            {etpNoOffers.map(({ header, lines }) => (
              <IonItem
                key={header}
                lines="none"
                className="ion-no-padding extra-pay-item"
              >
                <IonText>
                  <IonRow className="ion-align-items-center">
                    <IonIcon icon={closeCircle} style={{ color: "#BDBDBD" }} />
                    <span className="extra-pay-title">{header}</span>
                  </IonRow>
                  {lines.map(
                    (line) =>
                      line && (
                        <p key={line} className="extra-pay-text">
                          {line}
                        </p>
                      )
                  )}
                </IonText>
              </IonItem>
            ))}
          </IonList>
        </>
      )}
    </div>
  );
};

export { ExtraTimePayCard };
