import React, { useEffect, useState, useMemo } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonFab,
  IonText,
  IonLoading,
  useIonViewDidEnter,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "src/constants/userEvents";
import { logApiFailureEvent } from "src/lib/analytics";
import { showModal } from "src/app/shared/modal";
import { ILicenseDetails, LICENSE_STATUSES } from "./interfaces";
import { TabRouterPath } from "src/app/routing/constant/tabRoute";
import { getLicenses } from "./api";
import { License } from "./license";
import { getLicensesSummaryText, getLicensesCountByStatus } from "./utils";

const LicenseManager: React.FC<{}> = () => {
  const history = useHistory();
  const [licenses, setLicenses] = useState<ILicenseDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onAddLicenseClick = () => {
    logEvent(USER_EVENTS.ADD_LICENSE_TAPPED);
    history.push(TabRouterPath.ADD_LICENSE);
  };

  const fetchLicenses = async () => {
    try {
      setLoading(true);
      const response = await getLicenses();
      setLicenses(response);
      const licensesCountByStatus = getLicensesCountByStatus(response);
      logEvent(USER_EVENTS.LICENSES_VIEWED, {
        activeCount:
          (licensesCountByStatus[LICENSE_STATUSES.ACTIVE] || 0) +
          (licensesCountByStatus[LICENSE_STATUSES.EXPIRING] || 0),
      });
    } catch (error) {
      logApiFailureEvent(error);
      showModal("error", "Error while getting licenses.");
    } finally {
      setLoading(false);
    }
  };

  useIonViewDidEnter(() => {
    fetchLicenses();
  }, []);

  const licensesSummaryText = useMemo(() => {
    const licensesCountByStatus = getLicensesCountByStatus(licenses);
    return getLicensesSummaryText(licensesCountByStatus);
  }, [licenses]);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Licenses</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading isOpen={loading} backdropDismiss={true} />
      <IonContent className="license-manager">
        {licenses?.length ? (
          <>
            <IonText className="licenses-summary-text">
              <p style={{ marginTop: "20px", marginBottom: "0px" }}>
                {licensesSummaryText.map((summaryText, index) => {
                  return (
                    <span key={index}>
                      {!!index && ", "}
                      <span style={{ whiteSpace: "nowrap" }}>
                        {summaryText}
                      </span>
                    </span>
                  );
                })}
                {}
              </p>
            </IonText>
            {licenses.map((license) => {
              return <License license={license} key={license._id} />;
            })}
            <div style={{ height: "60px" }}></div>
            {/* Empty div to scroll license card above fab button*/}
          </>
        ) : (
          <IonText className="add-license-text">
            <p style={{ marginTop: "20px" }}>
              {loading
                ? "Loading Licenses..."
                : "Add a License to start working!"}
            </p>
          </IonText>
        )}
        <IonFab vertical="bottom" slot="fixed" className="add-license-fab">
          <IonButton
            expand="block"
            className="add-license-button"
            fill="outline"
            onClick={onAddLicenseClick}
          >
            Add License
          </IonButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export { LicenseManager };
