import { Address, Location, Shift } from "src/lib/interface";

interface ShiftFiltersInterface {
  totalShifts?: number;
  hiddenShifts?: number;
  baseRate: number;
  distancePreference: number;
  minPayHourlyPreference: number;
  minPayShiftPreference: number;
  searchMode: string;
  updateOpenShifts: () => void;
}

interface ShiftDateMap {
  [date: string]: Shift[];
}

interface DistancePreferenceModalProps {
  isOpen: boolean;
  distancePreference: number;
  closeModal: () => void;
  updateOpenShifts: () => void;
}

interface HiddenShiftsModalProps {
  isOpen: boolean;
  hiddenShifts: number;
  closeModal: () => void;
  openMinPayPreferenceModal: () => void;
}

interface MinPayPreferenceModalProps {
  isOpen: boolean;
  minPayHourlyPreference: number;
  minPayShiftPreference: number;
  baseRate: number;
  closeModal: () => void;
  updateOpenShifts: () => void;
  searchMode: string;
}

enum MinimumPayView {
  PER_SHIFT = "PER_SHIFT",
  PER_HOUR = "PER_HOUR",
}

export {
  ShiftFiltersInterface,
  DistancePreferenceModalProps,
  HiddenShiftsModalProps,
  MinPayPreferenceModalProps,
  MinimumPayView,
};
