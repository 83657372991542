import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { school, create } from "ionicons/icons";
import "./style.scss";
import { Pages, PageMetaMap } from "./constants";

/* eslint-disable react/jsx-filename-extension */

const TrainingResourcesPage = () => {
  const history: any = useHistory();
  const location: any = useLocation();
  const [title, url] = [location.state.title, location.state.url];

  const handleHelp = (page: Pages) => {
    const pageMeta = PageMetaMap.get(page);
    history.push("/home/account/needhelp/details", pageMeta);
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.HARRASSMENT)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>Harrassment Training</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.DEMENTIA)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>Dementia Course</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.HIPAA)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>HIPAA Course</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.INFECTION)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>Infection Control Course</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.ELDER)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>Elder Abuse Course</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.LGBTQ)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <h2>LGBTQ Course</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.TRAININGS)}
            detail
          >
            <IonIcon className="listIcons" icon={create} />
            <IonLabel>
              <p>Test your knowledge!</p>
              <h2>Trainings Quiz</h2>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TrainingResourcesPage;
