import Compressor from "compressorjs";
import { logEvent } from "src/lib/analytics";
import { SelectedFile } from "@app/shiftSignature/timecard/model";
import { IonText, isPlatform } from "@ionic/react";
import React, { useRef } from "react";
import { USER_EVENTS } from "../../../constants/userEvents";
import { capturePhoto, dataURLToBlob } from "@app/utils/mediaUpload";
import { CameraSource } from "@capacitor/camera";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "@src/constants/FEATURE_FLAGS";

const imgCompressConfig = {
  convertSize: 2000000, // max compress size 2MB
  quality: 0.8,
};

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

const compressAndGetDataURL = (fileBlob: Blob) => {
  return new Promise((resolve) => {
    new Compressor(fileBlob, {
      ...imgCompressConfig,
      async success(compressedFile) {
        const compressedFileDataURL = await blobToDataURL(compressedFile);
        resolve(compressedFileDataURL);
      },
      async error(error) {
        // fallback to non-compressed file
        const originalFileDataURL = await blobToDataURL(fileBlob);
        logEvent(USER_EVENTS.COMPRESS_TIMESHEET_PHOTO_FAILED, {
          originalFileBlob: `${fileBlob}`,
          error: `${error}`,
          file: originalFileDataURL,
        });
        resolve(originalFileDataURL);
      },
    });
  });
};

const FileExplorer: React.VFC<{
  onFileSelected: (x: SelectedFile) => void;
}> = ({ onFileSelected }) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const ldClient = useLDClient();
  const isNewTimeSheetEnabled = ldClient?.variation(
    FeatureFlag.DISPLAY_TIME_SHEET_SUMMARY,
    false
  );

  const getFileFromPhoneGallery = async (hasOpenedGallery = false) => {
    let cameraSource = CameraSource.Prompt;
    if (isNewTimeSheetEnabled) {
      cameraSource = hasOpenedGallery
        ? CameraSource.Photos
        : CameraSource.Camera;
    }

    const { type, fileBlob } = await capturePhoto(
      undefined,
      cameraSource,
      true
    );
    const compressedDataURL = await compressAndGetDataURL(fileBlob!);
    onFileSelected({ file: compressedDataURL, type });
  };

  const getFileFromSystem = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (!event.target.files) {
      return;
    }

    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    const compressedDataURL = await compressAndGetDataURL(file);
    onFileSelected({ file: compressedDataURL, type });
    event.target.value = "";
  };

  const handleUploadClick =
    (hasOpenedGallery = false) =>
    () => {
      const isNative = isPlatform("capacitor");
      const event =
        !isNative || hasOpenedGallery
          ? USER_EVENTS.CHOSE_TIMESHEET_PHOTO
          : USER_EVENTS.TOOK_TIMESHEET_PHOTO;
      logEvent(event);
      if (isNative) {
        getFileFromPhoneGallery(hasOpenedGallery);
      } else {
        // will trigger getFileFromSystem()
        inputFileRef.current?.click();
      }
    };

  return (
    <>
      <div className="ion-text-center ion-margin file-upload-container">
        <IonText class="file-upload-lbl" onClick={handleUploadClick(false)}>
          Take a Photo
        </IonText>
        <hr />
        <IonText class="file-upload-lbl" onClick={handleUploadClick(true)}>
          Choose from Library
        </IonText>
      </div>
      <input
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </>
  );
};

export { FileExplorer };
