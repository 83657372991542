import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";

import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logFailedApiRetry } from "../utils/api_retry";

const fetchFacilityProfile = async (facilityId: string): Promise<any> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/facilityprofile/${facilityId}`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/facilityprofile/${facilityId}`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { fetchFacilityProfile };
