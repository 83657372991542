import { CreatePaymentServiceAccountDTO } from "./model";
import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";
import { Agent, PaymentAccountInfo } from "src/lib/interface";

const generateDynamicLink = async (link: string): Promise<string> => {
  try {
    const { body } = await request
      .post(`${environment.firebaseConfig.dynamicShortLinkApiUrl}`)
      .query({ key: environment.firebaseConfig.apiKey })
      .send({
        dynamicLinkInfo: {
          domainUriPrefix: environment.dynamicLinkDomain,
          link,
          androidInfo: {
            androidPackageName: environment.bundleId,
          },
          iosInfo: {
            iosBundleId: environment.bundleId,
          },
        },
      });
    return body.shortLink;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};
const updateAgentProfile = async (
  agentId: string,
  data: Partial<Agent>
): Promise<void> => {
  try {
    const { body } = await request
      .patch(`${environment.baseUrl}/agentprofile/${agentId}`)
      .set(await getAuthHeader())
      .send({ data });
    return body;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};
const signedContractorAgreement = async (agentId: string): Promise<Agent> => {
  try {
    const { body } = await request
      .patch(`${environment.baseUrl}/agentprofile/signContractorAgreement`)
      .set(await getAuthHeader())
      .send({ agentId });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

const generatePaymentServiceOnboardingLink = async (
  id: string,
  data: { refreshUrl: string; returnUrl: string }
): Promise<string> => {
  try {
    const paymentServiceUser = await request
      .post(
        `${environment.api.paymentsBaseUrl}/accounts/${id}/generate-account-link`
      )
      .set(await getAuthHeader())
      .send(data)
      .then(({ text }) => text);
    return paymentServiceUser;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

const createPaymentServiceAccount = async (
  data: Partial<CreatePaymentServiceAccountDTO>
): Promise<PaymentAccountInfo> => {
  try {
    const paymentServiceAccount = await request
      .post(`${environment.api.paymentsBaseUrl}/accounts`)
      .set(await getAuthHeader())
      .send(data)
      .then(({ body }) => body);
    return paymentServiceAccount;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

export {
  generatePaymentServiceOnboardingLink,
  createPaymentServiceAccount,
  updateAgentProfile,
  generateDynamicLink,
  signedContractorAgreement,
};
