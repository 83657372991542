import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { fetchDetailedRatingsList } from "../api";
import { ResponseDetailedRatings } from "../model";
import { FacilityReviewsItem } from "./facilityReviewsItem";
import { StarRating } from "./starRating";
import { filterReviews } from "./utils";

type PropTypes = {
  facilityId: string;
  avgRating: number;
  totalRating: number;
};

export const FacilityReviews = ({
  avgRating,
  totalRating,
  facilityId,
}: PropTypes): JSX.Element => {
  const [reviews, setReviews] = useState<ResponseDetailedRatings[]>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);

  const getReviews = async () => {
    const fetchedReviews = await fetchDetailedRatingsList({
      filter: { reviewFor: "FACILITY", facilityId },
      page,
    });

    const filteredReviews = filterReviews(fetchedReviews);
    if (filteredReviews.length > 0) {
      setReviews([...reviews, ...filteredReviews]);
      setPage((page) => page + 1);
      setDisableInfiniteScroll(filteredReviews.length < 10);
    } else {
      setDisableInfiniteScroll(true);
    }
  };

  async function searchNext($event: CustomEvent<void>) {
    await getReviews();
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  useEffect(() => {
    getReviews();
    logEvent(USER_EVENTS.VIEWED_FACILITY_RATINGS, {
      number: totalRating,
      rating: avgRating,
    });
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: 18,
          paddingBottom: 9,
        }}
      >
        <StarRating value={avgRating} size={40} />
        <span style={{ color: "#032E68" }}>
          {totalRating} {totalRating > 1 ? "ratings" : "rating"}, average{" "}
          {avgRating.toFixed(1)}
        </span>
      </div>
      <IonContent
        style={{
          borderTop: "1px solid #DEDEDE",
          borderBottom: "1px solid #DEDEDE",
        }}
      >
        <IonList lines="full" inset>
          {reviews &&
            reviews.map((review) => (
              <FacilityReviewsItem
                createdAt={review.createdAt}
                key={review.createdAt}
                rating={review.rating}
                review={review.review}
                reasons={review.reasons}
                agentId={review.agent.userId}
              />
            ))}
        </IonList>
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading reviews..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </>
  );
};
