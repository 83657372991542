import { logEvent } from "src/lib/analytics";

import { IonContent, IonPopover, IonToast } from "@ionic/react";
import { NEW_FLOW, subscribe } from "@store/getters";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AppPage } from "../layout/page";
import { Footer } from "./footer";
import { isRatingDataValid } from "./validation";
import { RatingCategory } from "./ratingCategory";
import { RatingPrompt } from "./ratingPrompt";
import { Review } from "./review";
import { ReviewPrompt } from "./reviewPrompt";
import { StarRating } from "./starRating";
import { actionAddRating, actionAddRatingV2 } from "../store/ongoingShifts";
import { USER_EVENTS } from "../../constants/userEvents";
import {
  enterAnimation,
  leaveAnimation,
} from "./reviewPrompt/popoverAnimation";
import { Store } from "@store/store.model";

const RatingPage: React.FC<{}> = () => {
  subscribe();
  const [rating, setRating] = useState<number>(0);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string | null | undefined>("");
  const [review, setReview] = useState<string | null | undefined>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isValid, setValidation] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { shiftId } = useParams<{ shiftId: string }>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setReasons([]);
    setOtherReason("");
  }, [rating]);

  useEffect(() => {
    setValidation(isRatingDataValid(rating, reasons, otherReason, review));
  }, [rating, reasons, otherReason, review]);

  const onRatingAdded = () => {
    setLoading(false);
    if (NEW_FLOW) {
      logEvent(USER_EVENTS.SUBMITTED_FACILITY_REVIEW);
      return history.replace(`/home/openShifts`, location.state);
    }
    history.replace(`/home/myShift/${shiftId}/signature`, location.state);
  };

  const hideToast = () => setError("");

  const { shiftList } = useSelector((state: Store) => state.ongoingShiftStore);
  let facilityName;
  if (shiftList && shiftList.length) {
    let shift = shiftList.find((shift) => shift._id === shiftId);
    facilityName = shift?.facility?.name;
  }

  const onSubmit = async () => {
    setLoading(true);
    if (NEW_FLOW) {
      await dispatch<any>(
        actionAddRatingV2({ rating, reasons, otherReason, review, shiftId })
      );
    } else {
      await dispatch<any>(
        actionAddRating({ rating, reasons, otherReason, review, shiftId })
      );
    }
    onRatingAdded();
  };

  return (
    <Fragment>
      <IonContent>
        <IonPopover
          isOpen={true}
          showBackdrop={true}
          backdropDismiss={false}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="review-prompt"
        >
          <StarRating
            rating={rating}
            facilityName={facilityName}
            onChange={setRating}
          />
          <RatingCategory
            rating={rating}
            reasons={reasons}
            setReasons={setReasons}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
          />
          <Review review={review} setReview={setReview} rating={rating} />
        </IonPopover>
      </IonContent>
      <IonToast
        isOpen={!!error}
        onDidDismiss={hideToast}
        color="danger"
        message={error}
        duration={1000}
        position="top"
      />
    </Fragment>
  );
};

export { RatingPage, ReviewPrompt, RatingPrompt };
