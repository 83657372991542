import request from "superagent";

import { environment } from "../../environments/environment";
import { GET_PRESIGNED_UPLOAD_URL } from "../documents/gql";
import { IFileUpload } from "../documents/model";

export const uploadFileToFileStorage = async (
  file: Blob,
  fileType: string,
  reqId: string,
  client
): Promise<
  Pick<IFileUpload, "fileStorageRootFolder" | "fileStorageFileKey">
> => {
  const {
    data: {
      getPresignedUploadUrl: {
        preSignedUrl,
        fileStorageRootFolder,
        fileStorageFileKey,
      },
    },
  } =
    (await client.query({
      query: GET_PRESIGNED_UPLOAD_URL,
      variables: { reqId, fileType: fileType?.toLowerCase() },
    })) || {};
  if (!preSignedUrl) {
    throw new Error("Not able to fetch preSignedUrl");
  }
  await request.put(preSignedUrl).send(file);

  return { fileStorageRootFolder, fileStorageFileKey };
};

export const uploadFileToCloudinary = async (
  file: string
): Promise<{ url: string; filename: string }> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", environment.fileUploadPreset);

  const result = await request.post(environment.fileUploadURL).send(formData);
  const {
    body: { url, original_filename: filename },
  } = result;
  return { url, filename };
};
