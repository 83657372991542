import "./style.scss";
import { CreateAnimation, IonButton, IonButtons, IonLabel } from "@ionic/react";
import React from "react";
import { BottomButtonsProps } from "../model";

const BottomButtons: React.FC<BottomButtonsProps> = ({
  setDismiss,
  handleSubmit,
  disabled = true,
}) => {
  return (
    <CreateAnimation
      duration={500}
      iterations={1}
      keyframes={[
        { offset: 0, transform: "translateY(200px)", opacity: "0" },
        { offset: 0.99, opacity: "0" },
        { offset: 1, transform: "translateY(0%)", opacity: "1" },
      ]}
      play
    >
      <IonButtons className="bottom-buttons">
        <IonButton
          fill="clear"
          expand="full"
          className="bottom-button"
          onClick={() => {
            setDismiss(true);
          }}
        >
          <IonLabel>Not now</IonLabel>
        </IonButton>
        <IonButton
          fill="clear"
          expand="full"
          className="bottom-button"
          onClick={handleSubmit}
          disabled={disabled}
        >
          <IonLabel className="bottom-button-text">Ok!</IonLabel>
        </IonButton>
      </IonButtons>
    </CreateAnimation>
  );
};

export { BottomButtons };
