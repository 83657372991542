export const makeInstantpayLogParameters = (shift, isInstantPay, agent) => {
  const realAgent = agent || shift.agent;
  return {
    shiftId: shift._id,
    HCFid: shift.facility?._id,
    HCFname: shift.facility?.name,
    HCFMSA: shift.facility?.fullAddress?.metropolitanStatisticalArea,
    HCPid: realAgent?._id,
    HCPname: realAgent?.name,
    HCPqualification: realAgent?.agentReq,
    isInstantPay: `${isInstantPay}`,
  };
};
