import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Store } from "@store/store.model";
import { useSelector } from "react-redux";
import useFetchBonuses from "./useFetchHcpBonuses";
import "./style.scss";
import { helpCircleOutline } from "ionicons/icons";
import { HcpBonusRow } from "./hcpBonusRow";

function HcpBonusesPage() {
  const tmz = useSelector((state: Store) => state.session.agent?.tmz);
  const { bonuses } = useFetchBonuses(tmz);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonButtons slot="secondary">
            <IonButton
              href="http://help.clipboard.health/en/articles/6176946-clipboard-rewards-faq"
              target="_blank"
            >
              <IonIcon slot="icon-only" icon={helpCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>My Promotions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {bonuses.map((bonus) => (
            <HcpBonusRow key={bonus.id} bonus={bonus} />
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export { HcpBonusesPage };
