import { IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";
import React from "react";

import { ShiftViewItemProps } from "./model";

const ShiftViewItem: React.FC<ShiftViewItemProps> = ({ items }) => {
  const span = (12 / items.length).toString();
  return (
    <IonRow>
      {items.map((item) => (
        <IonCol size={span} key={item.title}>
          <IonItem>
            <IonLabel>
              <p>{item.title}</p>
              {item.isReact ? (
                item.description
              ) : (
                <h4 className="ion-text-wrap">{item.description}</h4>
              )}
            </IonLabel>
          </IonItem>
        </IonCol>
      ))}
    </IonRow>
  );
};

export { ShiftViewItem };
