import { get } from "lodash";
import moment from "moment-timezone";
import { Diagnostic } from "@ionic-native/diagnostic";
import { isPlatform } from "@ionic/react";
import { PrimerPage, UrgentShiftLocalStorage } from "./constants";
import { logErrors } from "@app/errorBoundary/api";
import { locationService } from "./locationService";
import { Geolocation } from "@capacitor/geolocation";

export const hasLocationPermission = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();
  return (
    locationStatus === Diagnostic.permissionStatus.GRANTED ||
    locationStatus === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
  );
};

export const hasAlwaysOnLocationPermission = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();
  return locationStatus === Diagnostic.permissionStatus.GRANTED;
};

export const hasDeniedAlwaysLocationPermission = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();
  return locationStatus === Diagnostic.permissionStatus.DENIED_ALWAYS;
};

export const canAskLocationPermission = (frequency: number | undefined) => {
  const lastLocationPermissionAsked = localStorage.getItem(
    UrgentShiftLocalStorage.LAST_LOCATION_ACCESS_ASKED
  );

  if (!lastLocationPermissionAsked) return true;

  let lastAccessAskedDate: undefined | Date | moment;
  try {
    lastAccessAskedDate = moment(lastLocationPermissionAsked);
  } catch (err) {}

  if (!lastAccessAskedDate?.isValid()) {
    return true;
  }

  return moment().isAfter(
    moment(lastLocationPermissionAsked).add(frequency || 48, "hours")
  );
};

export const canAskFullLocationPermission = (frequency: number | undefined) => {
  const lastFullAccessAsked = localStorage.getItem(
    UrgentShiftLocalStorage.LAST_FULL_LOCATION_ACCESS_ASKED
  );

  if (!lastFullAccessAsked) return true;

  let lastFullAccessAskedDate: Date | moment;
  try {
    lastFullAccessAskedDate = moment(lastFullAccessAsked);
  } catch (err) {}

  if (!lastFullAccessAskedDate?.isValid()) {
    return true;
  }

  return moment().isAfter(
    lastFullAccessAskedDate.add(frequency || 48, "hours")
  );
};

export const isLocationEnabled = async () => {
  const enabled = await Diagnostic.isLocationEnabled();
  return enabled;
};

export const isLocationEnabledAndHasFullLocationPermission = async () => {
  const hasAlwaysOnLocation = await hasAlwaysOnLocationPermission();
  if (!hasAlwaysOnLocation) return false;

  const locationEnabled = await isLocationEnabled();
  return locationEnabled;
};

export const switchToAppLocationSettings = () => {
  Diagnostic.switchToSettings();
};

export const requestFullLocationAccess = async () => {
  Diagnostic.switchToSettings();
};

export const requestLocationAccess = async () => {
  const res = await Diagnostic.requestLocationAuthorization();
  return res;
};

export const requestLocationServiceEnable = () => {
  Diagnostic.switchToLocationSettings();
};

export const hasSeenGeneralPrimerPage = () =>
  !!localStorage.getItem(UrgentShiftLocalStorage.GENERAL_PRIMER_PAGE_VIDEWED);

export const setSeenGeneralPrimerPage = () => {
  localStorage.setItem(
    UrgentShiftLocalStorage.GENERAL_PRIMER_PAGE_VIDEWED,
    "1"
  );
};

export const wasLocationAccessDenied = () =>
  !!localStorage.getItem(UrgentShiftLocalStorage.LOCATION_ACCESS_WAS_DENIED);

export const setLocationAccessDenied = () => {
  localStorage.setItem(UrgentShiftLocalStorage.LOCATION_ACCESS_WAS_DENIED, "1");
};

export const getLocationPermissionFlowModal = async (config: {
  locationPermissionAskFrequency?: number | undefined;
}): Promise<number | undefined> => {
  if (!hasSeenGeneralPrimerPage()) {
    return PrimerPage.GENERAL;
  }

  const alwaysOnLocationEnabled = await hasAlwaysOnLocationPermission();
  if (alwaysOnLocationEnabled) return undefined;

  const locationPermissionAllowed = await hasLocationPermission();

  if (!locationPermissionAllowed) {
    if (wasLocationAccessDenied()) return undefined;

    const deniedAlways = await hasDeniedAlwaysLocationPermission();
    if (deniedAlways) return undefined;

    const _canAskLocationPermission = await canAskLocationPermission(
      config.locationPermissionAskFrequency
    );

    if (!_canAskLocationPermission) {
      return undefined;
    }

    return PrimerPage.GENERAL;
  }

  const _canAskFullLocationAccess = await canAskFullLocationPermission(
    config.locationPermissionAskFrequency
  );

  if (!_canAskFullLocationAccess) return undefined;

  return PrimerPage.ALWAYS_ALLOW;
};

export const getOS = () => (isPlatform("ios") ? "ios" : "android");

export const getLabelsFromPageConfig = (pageConfig) => {
  return Object.keys(pageConfig).reduce((acc, key) => {
    const value = pageConfig[key];
    if (typeof value === "string") {
      return { ...acc, [key]: value };
    }

    const type = value?.type;
    const selector = value?.selector;
    const values = value?.values;

    if (type === "variable" && selector && values) {
      switch (selector) {
        case "os":
          return {
            ...acc,
            [key]: values[getOS()],
          };
      }
    }
    return acc;
  }, {});
};

export const getLocationPermissionStatus = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();
  return locationStatus;
};

export const getCurrentLocationStatus = async () => {
  const status = await getLocationPermissionStatus();
  const serviceEnabled = await isLocationEnabled();

  const isLocationServicesEnabled =
    status === Diagnostic.permissionStatus.GRANTED && serviceEnabled;

  return { isLocationServicesEnabled, locationServicesPermission: status };
};

export const handleException =
  (fn: Function) =>
  async (args = []) => {
    try {
      return await fn(...args);
    } catch (err: any) {
      await logErrors({
        app: "hcpMobile",
        message: JSON.stringify(err?.stack || err),
      });
    }
  };

export const startTracking = async (config): Promise<void> => {
  if (isPlatform("android")) {
    locationService.setForegroundServiceOptions({
      options: {
        ...config?.foregroundService,
      },
    });
  }
  locationService.startTracking({
    options: {
      ...config,
    },
  });

  const currentPosition = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
  });

  const lat = get(currentPosition, "coords.latitude");
  const lng = get(currentPosition, "coords.longitude");
  const accuracy = get(currentPosition, "coords.accuracy");

  await locationService.trackOnce(lat, lng, accuracy);
};
