import { logApiFailureEvent } from "src/lib/analytics";
import { environment } from "@env/environment";
import request from "superagent";
import { getAuthHeader } from "../superagent";
import { logFailedApiRetry } from "../utils/api_retry";

const getICAgreementStatus = async (
  agentId: string
): Promise<{ shouldSignAgreement: boolean } | undefined> => {
  try {
    return await request
      .get(
        `${environment.baseUrl}/agentprofile/featureFlag/ICAgreementStatus/${agentId}`
      )
      .retry(1, (err) => {
        logFailedApiRetry(
          err,
          `/agentprofile/featureFlag/ICAgreementStatus/${agentId}`
        );
        return true;
      })
      .set(await getAuthHeader())
      .send()
      .then(({ body }) => body);
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { getICAgreementStatus };
