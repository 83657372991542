import { ShiftStages } from "src/lib/interface/src";

export const nfcSuccessMessage = (currentStage: ShiftStages) => {
  return currentStage === ShiftStages.CLOCK_IN
    ? "You clocked in."
    : currentStage === ShiftStages.CLOCK_OUT
    ? "You clocked out."
    : currentStage === ShiftStages.LUNCH_IN
    ? "You ended your break."
    : currentStage === ShiftStages.LUNCH_OUT
    ? "You started a break."
    : currentStage === ShiftStages.SKIP_LUNCH
    ? "You skipped your break."
    : currentStage === ShiftStages.GET_FACILITY_SIGNATURE
    ? "You edited your times."
    : "You performed an event.";
};
