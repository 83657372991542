import filter from "leo-profanity";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { ResponseDetailedRatings } from "../model";

const invalidWords = [
  "true",
  "false",
  "other",
  "others",
  "no thanks",
  "see above",
  "n/a",
  "no comment",
  "nothing",
  "none",
  "thanks",
];

const isValidReview = (reviewText: string) =>
  reviewText &&
  typeof reviewText === "string" &&
  !invalidWords.includes(reviewText.toLowerCase());

export const filterReviews = (
  reviews: ResponseDetailedRatings[]
): ResponseDetailedRatings[] => {
  return reviews.map((reviewEntry) => {
    let validReview = "";
    if (
      isValidReview(reviewEntry?.review) &&
      !isValidReview(reviewEntry?.otherReason)
    ) {
      validReview = reviewEntry?.review;
    } else if (
      !isValidReview(reviewEntry?.review) &&
      isValidReview(reviewEntry?.otherReason)
    ) {
      validReview = reviewEntry?.otherReason;
    } else if (
      isValidReview(reviewEntry?.review) &&
      isValidReview(reviewEntry?.otherReason)
    ) {
      validReview = `${reviewEntry?.otherReason}. ${reviewEntry?.review}`;
    }

    try {
      let didCatchBadWord = filter.check(validReview);
      if (didCatchBadWord) {
        logEvent(USER_EVENTS.FACILITY_REVIEW_FILTERED);
      }
    } catch (e) {
      console.log(
        `An error occurred while trying to log FACILITY_REVIEW_FILTERED event: ${e}`
      );
    }
    return { ...reviewEntry, review: filter.clean(validReview) };
  });
};
