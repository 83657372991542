import { ActionType, OngoingShiftStore } from "./model";

const initialState: OngoingShiftStore = {
  isLoading: true,
  shiftList: [], // Will contain basic info for the ongoing list
  shiftInfoList: [], // Will contain detailed shift info needed for signature
  actionList: {
    RECORD_SHIFT_TIME: [],
    RECORD_SHIFT_TIME_FAILURE: [],
    RECORD_DISPUTED_TIME: [],
    UPDATE_SHIFT_UNIT: [],
    UPDATE_WORKER_SIGNATURE: [],
    UPLOAD_TIMECARD: [],
    REQUEST_AUTHORIZED_SIGNATURE: [],
    SIGN_WITH_SIGNATURE: [],
    ADD_RATING: [],
    SET_SHIFT_STAGE: [],
  },
};

const ongoingShiftStore = (state = initialState, action): OngoingShiftStore => {
  switch (action.type) {
    case ActionType.SET_STATE_FROM_LOCAL:
      return {
        ...state,
        ...action.data,
      };

    case ActionType.SET_ACTION_LIST:
      return {
        ...state,
        actionList: action.data,
      };

    case ActionType.SET_SHIFT_LIST:
      return {
        ...state,
        shiftList: action.data,
      };

    case ActionType.SET_SHIFT_INFO_LIST:
      return {
        ...state,
        shiftInfoList: action.data,
      };

    case ActionType.UPDATE_SHIFT: {
      const updatedInfo = action.data.updatedShift;
      let updatedList = state.shiftList;
      if (!state.shiftList.find((x) => x?._id === updatedInfo?._id)) {
        updatedList = [updatedInfo, ...updatedList];
      }
      return {
        ...state,
        shiftList: updatedList.map((shift) => {
          if (shift?._id === action.data.shiftId) {
            return { ...shift, ...updatedInfo };
          }
          return shift;
        }),
      };
    }

    case ActionType.FILTER_RECORD_SHIFT_TIME: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          RECORD_SHIFT_TIME: actionList.RECORD_SHIFT_TIME.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_RECORD_SHIFT_TIME_FAILURE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          RECORD_SHIFT_TIME_FAILURE:
            actionList.RECORD_SHIFT_TIME_FAILURE.filter(
              (query) => query.timestamp !== action.data.timestamp
            ),
        },
      };
    }

    case ActionType.FILTER_RECORD_DISPUTED_TIME: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          RECORD_DISPUTED_TIME: actionList.RECORD_DISPUTED_TIME.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_ADD_RATING: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          ADD_RATING: actionList.ADD_RATING.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_UPLOAD_TIMECARD: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          UPLOAD_TIMECARD: actionList.UPLOAD_TIMECARD.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_UPDATE_WORKER_SIGNATURE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          UPDATE_WORKER_SIGNATURE: actionList.UPDATE_WORKER_SIGNATURE.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_UPDATE_SHIFT_UNIT: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          UPDATE_SHIFT_UNIT: actionList.UPDATE_SHIFT_UNIT.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_SIGN_WITH_SIGNATURE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          SIGN_WITH_SIGNATURE: actionList.SIGN_WITH_SIGNATURE.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.FILTER_REQUEST_AUTHORIZED_SIGNATURE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          REQUEST_AUTHORIZED_SIGNATURE:
            actionList.REQUEST_AUTHORIZED_SIGNATURE.filter(
              (query) => query.timestamp !== action.data.timestamp
            ),
        },
      };
    }

    case ActionType.FILTER_SET_SHIFT_STAGE: {
      const { actionList } = state;
      return {
        ...state,
        actionList: {
          ...actionList,
          SET_SHIFT_STAGE: actionList.SET_SHIFT_STAGE.filter(
            (query) => query.timestamp !== action.data.timestamp
          ),
        },
      };
    }

    case ActionType.PUSH_SET_SHIFT_STAGE: {
      const { actionList } = state;
      actionList.SET_SHIFT_STAGE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_RECORD_SHIFT_TIME: {
      const { actionList } = state;
      actionList.RECORD_SHIFT_TIME.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_RECORD_SHIFT_TIME_FAILURE: {
      const { actionList } = state;
      actionList.RECORD_SHIFT_TIME_FAILURE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_RECORD_DISPUTED_TIME: {
      const { actionList } = state;
      actionList.RECORD_DISPUTED_TIME.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_ADD_RATING: {
      const { actionList } = state;
      actionList.ADD_RATING.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_REQUEST_AUTHORIZED_SIGNATURE: {
      const { actionList } = state;
      actionList.REQUEST_AUTHORIZED_SIGNATURE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_SIGN_WITH_SIGNATURE: {
      const { actionList } = state;
      actionList.SIGN_WITH_SIGNATURE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_UPDATE_SHIFT_UNIT: {
      const { actionList } = state;
      actionList.UPDATE_SHIFT_UNIT.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_UPLOAD_TIMECARD: {
      const { actionList } = state;
      actionList.UPLOAD_TIMECARD.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    case ActionType.PUSH_UPDATE_WORKER_SIGNATURE: {
      const { actionList } = state;
      actionList.UPDATE_WORKER_SIGNATURE.push(action.data);
      return {
        ...state,
        actionList: { ...actionList },
      };
    }

    default: {
      return state;
    }
  }
};

export { ongoingShiftStore };
