import "./style.scss";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import React from "react";

import { RatingCardProps } from "./model";

const RatingCard: React.FC<RatingCardProps> = ({
  title,
  facilityName,
  children,
}) => (
  <div className="ion-margin ion-text-center">
    <IonCardSubtitle className="title">
      <IonLabel>{title}</IonLabel>
      <IonLabel>{facilityName}</IonLabel>
    </IonCardSubtitle>

    <IonCardContent>{children}</IonCardContent>
  </div>
);

export { RatingCard };
