import { useFlags } from "launchdarkly-react-client-sdk";
import { TabRouterPath } from "@app/routing/constant/tabRoute";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  useIonViewDidEnter,
} from "@ionic/react";
import { useAppSelector } from "@store/index";
import React from "react";
import { useParams } from "react-router";
import { Channel, useSendbirdStateContext } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import moment from "moment-timezone";

import "./style.scss";
import { ChatChannel } from "@store/chat/model";
import { fetchChannels } from "./chatProvider";
import { useDispatch } from "react-redux";
import { SentHomeResponseMessage } from "./sentHomeResponseMessage";
import { SentHomeRequestMessage } from "./SentHomeRequestMessage";

type ShiftStatus =
  | "tomorrow"
  | "ahead"
  | "in progress"
  | "nothing"
  | "closed_after_30_days"
  | "past"
  | "loading"
  | "today";

export enum MessageTypes {
  FcmRequest = "FCM_REQUEST",
  FcmApproval = "FCM_APPROVAL",
  FcmReject = "FCM_REJECT",
}

const getStatus = (
  isChatOpen30DaysAfterLastShift: boolean,
  channel?: ChatChannel
): ShiftStatus => {
  if (!channel) return "loading";
  if (!channel.shift) {
    // if the feature flag is disabled or there's no metadata
    if (!isChatOpen30DaysAfterLastShift || !channel.metadata.lastBooked) {
      return "nothing";
    }

    const lastBookedDate = moment(channel.metadata.lastBooked);
    if (moment().diff(lastBookedDate, "days") > 30) {
      return "closed_after_30_days";
    } else {
      return "past";
    }
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const tomorrowEndOfDay = moment().add(1, "day").endOf("day");
  const todayEndOfDay = moment().endOf("day");

  if (end.isBefore(moment())) return "past";
  if (start.isAfter(moment())) {
    if (start.isBefore(todayEndOfDay)) return "today";
    if (start.isBefore(tomorrowEndOfDay)) return "tomorrow";
    return "ahead";
  }

  return "in progress";
};

export const getChannelShiftTime = (
  isChatOpen30DaysAfterLastShift: boolean,
  channel?: ChatChannel
) => {
  if (!channel) return "Loading...";
  if (!channel.shift) return "No upcoming shifts";

  const status = getStatus(isChatOpen30DaysAfterLastShift, channel);
  if (["nothing", "closed_after_30_days", "past"].includes(status))
    return "No upcoming shifts";

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const until =
    "until " +
    end.format("hh:mm A") +
    ", " +
    end.diff(start, "hours", true) +
    "hrs";
  const at = `at ${start.format("hh:mm A")} ${until}`;

  if (status === "in progress") return `Shift in progress ${until}`;

  let date = "";
  if (status === "today") date = `today ${at}`;
  if (status === "tomorrow") date = `tomorrow, ${start.format("MMM DD")} ${at}`;
  if (status === "ahead") date = `${start.format("dddd[,] MMM DD")} ${at}`;

  return "Next shift " + date;
};

export const ChatPage: React.FC = () => {
  const userId = useAppSelector(
    (store) => store?.session?.agent?.userId
  ) as string;
  const { facilityId } = useParams<{ facilityId: string }>();
  const channels = useAppSelector((store) => store.chatStore.channels);
  const currentChannelUrl = facilityId + "_" + userId;
  const currentChannel = channels.find(
    (channel) => channel.url === currentChannelUrl
  );
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const dispatch = useDispatch();

  useIonViewDidEnter(() => {
    fetchChannels(sdk, dispatch);
  }, [sdk]);

  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags["channel-open-30-days-after-last-booked-shift"];

  const { facilityName } = currentChannel?.metadata || {};
  const channelStatus = getStatus(
    isChatOpen30DaysAfterLastShift,
    currentChannel
  );

  const header = () => (
    <div className="sendbird-custom-chat-header">
      <div className="header-title">
        {currentChannel?.shift?.facility?.name ||
          facilityName ||
          currentChannel?.name}
      </div>
      <div className="header-description">
        {getChannelShiftTime(isChatOpen30DaysAfterLastShift, currentChannel)}
      </div>
    </div>
  );

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={TabRouterPath.MY_SHIFTS}
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Chat</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div
          className={`chat-container ${
            channelStatus === "nothing" ? "disable-chat" : ""
          } ${
            channelStatus === "closed_after_30_days"
              ? "disable-chat after-30-days"
              : ""
          }`}
        >
          <Channel
            renderChatHeader={header}
            channelUrl={currentChannelUrl}
            // @ts-ignore
            renderCustomMessage={(message, channel) => {
              if (message.customType === MessageTypes.FcmRequest) {
                const metaData = channel.getCachedMetaData() as {
                  facilityName: string;
                };
                return () => (
                  <SentHomeRequestMessage
                    facilityName={metaData?.facilityName}
                  />
                );
              }
              if (
                message.customType === MessageTypes.FcmApproval ||
                message.customType === MessageTypes.FcmReject
              ) {
                return () => <SentHomeResponseMessage message={message} />;
              }
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
