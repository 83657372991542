import "./style.scss";

import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import React from "react";
import { IonCard, IonCardContent, IonLabel } from "@ionic/react";
import { LastMinuteShiftsCardProps } from "./model";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants";

const LastMinuteShiftsCard: React.FC<LastMinuteShiftsCardProps> = ({
  agentUserId,
  agentName,
  onClose,
}) => {
  const history = useHistory();

  const redirectToLastMinuteShifts = () => {
    logEvent(USER_EVENTS.TAPPED_LAST_MINUTE_SHIFTS_BANNER, {
      agentUserId: agentUserId as string,
      agentName: agentName as string,
      timestamp: moment().format(),
    });
    history.push("/home/lastMinuteShifts");
  };

  return (
    <IonCard
      className="last-minute-shifts-card"
      onClick={redirectToLastMinuteShifts}
    >
      <IonCardContent className="last-minute-shifts-card-content">
        <IonLabel className="last-minute-shifts-card-label">
          Tap here for shifts that were just added
        </IonLabel>
        <IonLabel
          className="last-minute-shifts-card-cancel"
          onClick={(e) => {
            e.stopPropagation();
            if (onClose) onClose();
          }}
        >
          X
        </IonLabel>
      </IonCardContent>
    </IonCard>
  );
};

export { LastMinuteShiftsCard };
