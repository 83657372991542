import { ShiftStages } from "src/lib/interface";
import moment from "moment-timezone";

const getShiftTimeKey = ({
  stage,
  location,
  shift,
  timecard,
  locationType,
  appType,
  connectivityMode,
  now = moment().toDate(),
}) => {
  const geoLocation = {
    type: "Point",
    coordinates: location,
  };

  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return {
        clockInOut: {
          start: now,
          startLocation: geoLocation,
          startCard: timecard,
          locationType,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.LUNCH_OUT:
      return {
        lunchInOut: {
          start: now,
          startLocation: geoLocation,
          startCard: timecard,
          locationType,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.LUNCH_IN:
      return {
        lunchInOut: {
          start: shift.lunchInOut.start,
          startLocation: shift.lunchInOut.startLocation,
          startCard: shift.lunchInOut.startCard,
          end: now,
          endLocation: geoLocation,
          endCard: timecard,
          locationType,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.CLOCK_OUT:
      return {
        clockInOut: {
          start: shift.clockInOut.start,
          startLocation: shift.clockInOut.startLocation,
          startCard: shift.clockInOut.startCard,
          end: now,
          endLocation: geoLocation,
          endCard: timecard,
          locationType,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.SKIP_LUNCH:
      return {
        lunchInOut: {
          isSkipped: true,
          skipLocation: geoLocation,
          locationType,
          appType,
          connectivityMode,
        },
      };
    default:
      return {};
  }
};

const getShiftTimeKeyWithNfc = ({
  stage,
  shift,
  appType,
  connectivityMode,
  now = moment().toDate(),
}) => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return {
        clockInOut: {
          start: now,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.LUNCH_OUT:
      return {
        lunchInOut: {
          start: now,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.LUNCH_IN:
      return {
        lunchInOut: {
          start: shift.lunchInOut.start,
          startLocation: shift.lunchInOut.startLocation,
          startCard: shift.lunchInOut.startCard,
          end: now,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.CLOCK_OUT:
      return {
        clockInOut: {
          start: shift.clockInOut.start,
          startLocation: shift.clockInOut.startLocation,
          startCard: shift.clockInOut.startCard,
          end: now,
          appType,
          connectivityMode,
        },
      };
    case ShiftStages.SKIP_LUNCH:
      return {
        lunchInOut: {
          isSkipped: true,
          appType,
          connectivityMode,
        },
      };
    default:
      return {};
  }
};
const getShiftTime = (clockInOut, lunchInOut) => {
  const startTime = moment(clockInOut.start);
  const endTime = moment(clockInOut.end);

  const workedTime = endTime.diff(startTime, "minutes");
  let lunchTime = 0;

  if (lunchInOut && !lunchInOut.isSkipped) {
    lunchTime = moment(lunchInOut.end).diff(lunchInOut.start, "minutes");
  }

  const shiftTime = (workedTime - lunchTime) / 60;
  return Number(shiftTime.toFixed(2));
};

export { getShiftTimeKey, getShiftTime, getShiftTimeKeyWithNfc };
