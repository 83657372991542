import { CbhSegment } from "@clipboard-health/cbh-segment";
import { Dispatch } from "redux";
import { environment } from "../../../environments/environment";
import { fetchEnvVariables, triggerAdjustEventTracking } from "./api";
import { get } from "lodash";
import { isPlatform } from "@ionic/react";
import moment from "moment-timezone";
import request from "superagent";
import { ActionType as SpecialityAction } from "../specialities/model";
import { User } from "firebase";
import { USER_EVENTS } from "../../../constants/userEvents";
import { ActionType, LocalStorage, PayVersion } from "./session.model";
import { Storage } from "@capacitor/storage";
import { Network } from "@capacitor/network";
import { Device } from "@capacitor/device";
import { App, AppState } from "@capacitor/app";
import { ConnectionStatus } from "@capacitor/network";
import {
  createSegmentInstance,
  identifyUser,
  logApiFailureEvent,
  logEvent,
} from "src/lib/analytics";
import { firebaseAppAnalytics, FirebaseAuthentication } from "src/lib/firebase";
import { getUserByEmail } from "./api";
import {
  Adjust,
  AdjustConfig,
  AdjustEnvironment,
  AdjustEvent,
  AdjustLogLevel,
} from "@ionic-native/adjust";
import { AdjustEvents, getAdjustEventToken } from "../../utils/adjust";
import { hcpAppLogger } from "@app/remoteLoggers";
import {
  identifyDatadogRUMUser,
  removeDatadogRUMUser,
} from "../../utils/datadogRUM";
import { logFailedApiRetry } from "../../utils/api_retry";
import { CapacitorAppAnalytics } from "@clipboard-health/capacitor-app-analytic";
import { ZendeskSDK } from "capacitor-zendesk-sdk";

let firebaseAuth: FirebaseAuthentication;

const IS_TEST_ENV = process.env.NODE_ENV === "test";

const initSession =
  (pathname: string, email: string) =>
  (dispatch: Dispatch): void => {
    loadCustomFlags(dispatch);
    initFirebaseAuth(dispatch, pathname, email);
    initDeviceFlags(dispatch);
    initNetworkStatus(dispatch);
    initAppState(dispatch);
    setEnvVariables(dispatch);
    initAnalytics();
    logEvent(USER_EVENTS.APP_LAUNCHED);
    initAdjust();
  };

const loadCustomFlags = (dispatch): void => {
  const isSignupFlag = localStorage.getItem("isSignup");
  if (isSignupFlag) {
    dispatch({
      type: ActionType.SET_IS_SIGNUP,
    });
  }
};

// Zendesk sdk uses token to call backend main to verify user
// this serves as fallback to when user token is refreshed/generated
// we are also setting identity before opening any sdk

// TODO: Uncomment when android zendesk messaging sdk issue is resolved
// const setIdentityZendesk = async (user: User) => {
//   if (!(user && isPlatform("capacitor"))) return;
//   const token = await user.getIdToken();
//   await ZendeskSDK.loginUser({ token });
// };

const initFirebaseAuth = (
  dispatch: Dispatch,
  pathname: string,
  email: string
): void => {
  const { firebaseConfig, bundleId, dynamicLinkDomain, webAppUrl } =
    environment;
  const appDomain = isPlatform("capacitor") ? webAppUrl : "";

  firebaseAuth = new FirebaseAuthentication(firebaseConfig, {
    bundleId,
    dynamicLinkDomain,
    appDomain,
  });

  if (!IS_TEST_ENV) {
    firebaseAppAnalytics.init(firebaseConfig);
  }

  if (pathname === "/welcome/login/emailVerify" && !email) {
    localStorage.setItem("isInitAutoLogin", "true");
  }
  const authChangeListener = onAuthChanged(dispatch, pathname, email);
  firebaseAuth.onAuthStateChanged(authChangeListener);
  firebaseAuth.onIdTokenChanged(authChangeListener);

  dispatch({ type: ActionType.FIREBASE_INIT, data: { firebaseAuth } });
};

const initAnalytics = (): void => {
  if (IS_TEST_ENV) {
    return;
  }
  const { UXCamKey } = environment;
  createSegmentInstance(environment.segment as Record<string, string>);
  CapacitorAppAnalytics.startWithKey({ UXCamKey });
};

const initDeviceFlags = (dispatch: Dispatch): void => {
  const deviceFlags = localStorage.getItem(LocalStorage.DEVICE_FLAGS);

  if (deviceFlags === null || deviceFlags === "null") {
    return;
  }

  dispatch({
    type: ActionType.UPDATE_DEVICE_FLAGS,
    data: { deviceFlags: JSON.parse(deviceFlags) },
  });
};

const initNetworkStatus = (dispatch: Dispatch): void => {
  Network.getStatus().then(onNetworkStatusChanged(dispatch));
  Network.addListener("networkStatusChange", onNetworkStatusChanged(dispatch));
};

const initAppState = (dispatch: Dispatch): void => {
  const onStateChange = (state: AppState): void => {
    if (!state.isActive) return;

    const token = localStorage.getItem(LocalStorage.AUTH_TOKEN);
    if (token) {
      offlineStoreAgent(dispatch);
    }
    logEvent(USER_EVENTS.APP_RESTORED);
  };

  App.addListener("appStateChange", onStateChange);
};

const setEnvVariables = async (dispatch: Dispatch): Promise<void> => {
  const data = await fetchEnvVariables();
  dispatch({
    type: ActionType.SET_ENV_VARIABLES,
    data,
  });
};

const onNetworkStatusChanged =
  (dispatch: Dispatch) =>
  (status: ConnectionStatus): void => {
    dispatch({
      type: ActionType.UPDATE_NETWORK_STATUS,
      data: { networkStatus: status },
    });
  };

const onLoggedIn = async (
  dispatch: Dispatch,
  user: User,
  forceRefresh?: boolean
): Promise<void> => {
  if (!user) {
    return;
  }

  try {
    const IdTokenResult = await user.getIdTokenResult(forceRefresh);
    const { token, claims } = IdTokenResult;
    const userAgent = await getUserByEmail(token);
    const { agent } = userAgent || {};
    if (user.email && agent) {
      initApp(token, userAgent.tmz);
      let value;
      try {
        value = await fetchAgent(dispatch, token);
      } catch (error) {
        const err = error as Error;
        logEvent(USER_EVENTS.ONBOARDING_ERROR, {
          message: "Error fetching agent onLoggedIn",
          error: err?.message,
        });
      }
      value ? offlineStoreAgent(dispatch) : await offlineStoreAgent(dispatch);
      dispatch({
        type: ActionType.LOGGED_IN,
        data: {
          userId: userAgent._id,
          sendBirdAccessToken: userAgent.sendBirdAccessToken,
          profile: {
            name: agent.name,
            email: agent.email,
            phone: agent.phone,
          },
        },
      });
    } else if (claims?.syncEmail) {
      // claims used for login with OTP for user first time sync with firebase
      const userAgent = await getUserByEmail(token);
      const { agent } = userAgent;
      initApp(token, userAgent.tmz);
      const value = await fetchAgent(dispatch, token);
      value ? offlineStoreAgent(dispatch) : await offlineStoreAgent(dispatch);
      dispatch({
        type: ActionType.LOGGED_IN,
        data: {
          userId: userAgent._id,
          sendBirdAccessToken: userAgent.sendBirdAccessToken,
          profile: {
            name: agent.name,
            email: agent.email,
            phone: agent.phone,
          },
        },
      });
    } else {
      // Only phone number entered by new Agent that has started Signup flow
      initApp(token);
      const value = await fetchAgent(dispatch, token);
      value ? offlineStoreAgent(dispatch) : await offlineStoreAgent(dispatch);
      dispatch({
        type: ActionType.LOGGED_IN,
        data: {
          userId: undefined,
          sendBirdAccessToken: undefined,
          profile: {
            name: undefined,
            email: undefined,
            phone: user.phoneNumber,
          },
        },
      });
    }

    setEnvVariables(dispatch);
  } catch (err) {
    onLoggedOut(dispatch);
  }
};

const initApp = (token, tmz?: string): void => {
  if (token) localStorage.setItem(LocalStorage.AUTH_TOKEN, token);
  if (tmz) moment.tz.setDefault(tmz);
};

const onLoggedOut = (dispatch: Dispatch): void => {
  dispatch({
    type: ActionType.LOGGED_OUT,
  });
  localStorage.removeItem(LocalStorage.AUTH_TOKEN);
  localStorage.removeItem("holidayCheck");
  localStorage.removeItem("claimCheck");
  localStorage.removeItem("isSignup");
  Storage.clear();
  CapacitorAppAnalytics?.stopSession?.();
  removeDatadogRUMUser();
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const onAuthChanged = (dispatch: Dispatch, pathname: string, email: string) => {
  let inProcess = false;
  let timeout: ReturnType<typeof setTimeout> | null;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return async (user: User) => {
    if (inProcess) {
      return;
    }
    inProcess = true;
    if (timeout) {
      clearTimeout(timeout);
    }
    const isInitAutoLogin = localStorage.getItem("isInitAutoLogin");
    if (
      isInitAutoLogin === "true" &&
      user &&
      pathname === "/welcome/login/emailVerify" &&
      !email
    ) {
      // this will return in signin page, and not automatically login
      firebaseAuth.signOut().then(() => {
        inProcess = false;
        indexedDB.deleteDatabase("firebaseLocalStorage");
      });
    } else if (user) {
      await onLoggedIn(dispatch, user);
      inProcess = false;
    } else {
      timeout = setTimeout(() => {
        onLoggedOut(dispatch);
        timeout = null;
      }, 1000);
      inProcess = false;
    }
    localStorage.removeItem("isInitAutoLogin");
  };
};

const reloadFirebaseUser = async (
  dispatch: Dispatch,
  user: User
): Promise<void> => {
  dispatch({
    type: ActionType.CHECKING_AUTHORIZATION,
    data: {
      hasSession: false,
    },
  });
  await onLoggedIn(dispatch, user, true);
};

const fetchAgent = async (
  dispatch: Dispatch,
  token: string
): Promise<boolean | undefined> => {
  const { value } = await Storage.get({ key: LocalStorage.AGENT });

  if (!value) return false;
  const agent = JSON.parse(value);
  if (agent) {
    if (agent && agent.isFirstSession) {
      if (agent.isIdentifiedInSegment !== true) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cbhSegment: any = "";
        cbhSegment = new CbhSegment(environment.segment.key as string);

        cbhSegment.identify({
          userId: agent.userId.toString(),
          traits: {
            createdAt: agent.createdAt,
            email: agent.email,
            name: agent.name,
            phone: agent.phone,
            type: "Agent",
          },
        });
      }

      try {
        await request
          .put(`${environment.baseUrl}/agentProfile/put`)
          .set("Authorization", token)
          .send({
            agentId: agent.userId,
            isFirstSession: false,
            isIdentifiedInSegment: true,
          });
      } catch (error) {
        logApiFailureEvent(error);
      }
    }
    CapacitorAppAnalytics.setUserProperty({
      userId: agent.userId,
      name: agent.name,
      userInfo: {
        email: agent.email,
        qualification: agent.qualification,
      },
    });
    firebaseAppAnalytics.setUserProperties({
      userId: agent.userId,
      name: agent.name,
      userInfo: {
        email: agent.email,
        qualification: agent.qualification,
      },
    });
    identifyDatadogRUMUser(agent);
    dispatch({
      type: ActionType.AGENT_PROFILE,
      data: { agent },
    });
    dispatch({
      type: SpecialityAction.GET_SPECIALITIES_DATA,
      data: {
        experienceDetails: get(agent, "specialities.experienceDetails", {}),
        hasSedationExperience: get(
          agent,
          "specialities.hasSedationExperience",
          false
        ),
        hasTrayAssemblyExperience: get(
          agent,
          "specialities.hasTrayAssemblyExperience",
          false
        ),
        experience: get(agent, "specialities.experience", []),
      },
    });
    identifyUser(agent);
    getHcpPayVersion(dispatch, agent.userId, token);
    return true;
  }
};

async function updateUserSession(): Promise<string | undefined> {
  if (!firebaseAuth.currentUser) return;
  const IdTokenResult = await firebaseAuth.currentUser.getIdTokenResult();
  initApp(IdTokenResult?.token);
}

const offlineStoreAgent = async (dispatch: Dispatch): Promise<void> => {
  await updateUserSession();
  const token = localStorage.getItem(LocalStorage.AUTH_TOKEN) as string;

  try {
    const { body: agent } = await request
      .get(`${environment.baseUrl}/agentProfile`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentProfile`);
        return true;
      })
      .set("Authorization", token);
    await Storage.set({
      key: LocalStorage.AGENT,
      value: JSON.stringify(agent),
    });
    dispatch({
      type: ActionType.AGENT_PROFILE,
      data: { agent },
    });
    fetchAgent(dispatch, token);
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const getHcpPayVersion = async (
  dispatch: Dispatch,
  userId: string,
  token: string
): Promise<void> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/agentProfile/pay-version/` + userId)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentProfile/pay-version/`);
        return true;
      })
      .set("Authorization", token);

    const payVersion: PayVersion = body;

    dispatch({
      type: ActionType.SET_PAY_VERSION,
      data: { payVersion: payVersion.payVersion },
    });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

/**
 * initializes adjust sdk for ios and android
 */
const initAdjust = async (): Promise<void> => {
  const CONTEXT = "[initAdjust]";
  try {
    if (!isPlatform("capacitor")) return;
    const { triggerAdjustEvent } = await triggerAdjustEventTracking();
    if (!triggerAdjustEvent) return;
    const { osVersion, model } = await Device.getInfo();
    const { adjustToken } = environment;
    const adjustEnvironment =
      process.env.NODE_ENV === "production"
        ? AdjustEnvironment.Production
        : AdjustEnvironment.Sandbox;
    const adjustConfig = new AdjustConfig(adjustToken!, adjustEnvironment);
    adjustConfig.setLogLevel(AdjustLogLevel.Verbose);

    if (isPlatform("ios") && parseInt(osVersion?.split(".")[0]) > 13) {
      const status =
        await Adjust.requestTrackingAuthorizationWithCompletionHandler();
      if (status >= 0 && status < 3) {
        console.log(
          `${CONTEXT} App tracking not authorized. Status - ${JSON.stringify(
            status
          )}`
        );
        hcpAppLogger({
          logType: "INFO",
          title: `${CONTEXT} Adjust tracking status`,
          logArea: "SERVER",
          featureName: "SIGNUP",
          hcpEmail: "Unknown",
          details: {
            info: `adjust tracking status - ${status}, device model- ${model}`,
          },
        });
      }
    }

    Adjust.create(adjustConfig);

    const adid = await Adjust.getAdid();

    hcpAppLogger({
      logType: "INFO",
      title: `${CONTEXT} Adjust initialized`,
      logArea: "SERVER",
      featureName: "SIGNUP",
      hcpEmail: "Unknown",
      details: {
        info: `adjust initialized with adid:- ${adid}`,
      },
    });

    const value = localStorage.getItem("firstOpen");
    if (!value) {
      localStorage.setItem("firstOpen", "adjust");
      const eventToken = getAdjustEventToken[AdjustEvents.FIRST_OPEN];
      const adjustEvent = new AdjustEvent(eventToken);
      Adjust.trackEvent(adjustEvent);
    }
  } catch (error) {
    const err = error as Error;
    hcpAppLogger({
      logType: "ERROR",
      title: `${CONTEXT} Error could not intialize adjust`,
      logArea: "SERVER",
      featureName: "SIGNUP",
      hcpEmail: "Unknown",
      details: {
        error: err?.message,
        errorStack: err?.stack,
      },
    });
  }
};

export {
  initSession,
  reloadFirebaseUser,
  initApp,
  firebaseAuth,
  onLoggedOut,
  initAdjust,
};
