import { createAnimation } from "@ionic/react";

const PopoverContentAnimation = (el: Element) => {
  const ratingAnimation = createAnimation()
    .addElement(el)
    .iterations(1)
    .keyframes([
      { offset: 0, height: "192px" },
      { offset: 1, height: "340px" },
    ])
    .easing("ease-in")
    .afterStyles({ height: "340px" })
    .duration(500);

  const otherRatingAnimation = createAnimation()
    .addElement(el)
    .iterations(1)
    .fromTo("height", "340px", "430px")
    .easing("ease-in")
    .afterStyles({ height: "430px" })
    .duration(500);

  const reverseOtherAnimation = createAnimation()
    .addElement(el)
    .iterations(1)
    .fromTo("height", "430px", "340px")
    .easing("ease-in")
    .afterStyles({ height: "340px" })
    .duration(500);

  return {
    ratingAnimation,
    otherRatingAnimation,
    reverseOtherAnimation,
  };
};

const enterAnimation = (baseEl: HTMLObjectElement) => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector("ion-backdrop")!)
    .keyframes([
      { offset: 0, transform: "scale(0.75)", opacity: "0.1" },
      { offset: 0.99, opacity: "0.2" },
      { offset: 1, transform: "scale(1)", opacity: "0.5" },
    ]);

  const popoverAnimation = createAnimation()
    .addElement(baseEl.querySelector(".popover-wrapper")!)
    .fromTo("opacity", "0/75", "1");

  return createAnimation()
    .addElement(baseEl)
    .easing("ease-in")
    .duration(750)
    .fromTo("transform", "translateY(40%)", "translateY(0%)")
    .addAnimation([backdropAnimation, popoverAnimation]);
};

const leaveAnimation = (baseEl: HTMLObjectElement) => {
  return enterAnimation(baseEl).direction("reverse");
};

export { PopoverContentAnimation, enterAnimation, leaveAnimation };
