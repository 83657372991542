import { IonAlert } from "@ionic/react";

import React from "react";

const WaitingStripeResponseAlert = ({
  isOpen,
  onDidDismiss,
  onConfirm,
  shift,
  isInstantBookingShift,
}) => {
  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      message={`
        <h1>Stripe is currently verifying your account</h1>
        <p>You can start booking shifts once verification is complete</p>
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: "Ok",
          role: "confirm",
          handler: () => false,
        },
      ]}
    />
  );
};

export { WaitingStripeResponseAlert };
