import "./style.scss";

import { Store } from "@store/store.model";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { chevronForward, newspaper } from "ionicons/icons";
import { IonCard, IonIcon, IonItem, IonLabel } from "@ionic/react";
import React, { Fragment } from "react";
import { LATEST_CONTRACTOR_AGREEMENT } from "../../../constants/CONTRACTOR_AGREEMENTS";
import { Agent } from "src/lib/interface";

const ContractorAgreement = () => {
  const agent = useSelector((state: Store) => state.session.agent);
  const { signedContractorAgreements = [] } = agent as Agent;
  let lastAgreement =
    signedContractorAgreements.find(
      (agreement) => agreement.agreementVersion === LATEST_CONTRACTOR_AGREEMENT
    ) || signedContractorAgreements[0];

  const signedDate = lastAgreement?.signedDate;

  const history = useHistory();

  if (!signedDate) {
    return <Fragment />;
  }

  const openContract = () => {
    history.push(TabRouterPath.PROFILE_CONTRACT);
  };

  return (
    <IonCard>
      <IonItem class="ion-margin-bottom">
        <IonIcon icon={newspaper} />
        <IonLabel>
          <h2 style={{ marginLeft: 15 }}>Contractor Agreement</h2>
        </IonLabel>
      </IonItem>
      <IonItem
        class="license-content ion-margin-horizontal"
        lines="none"
        onClick={openContract}
        style={{ cursor: "pointer" }}
      >
        <h6 style={{ marginBottom: 0 }} slot="start">
          View My Agreement
        </h6>
        <IonIcon slot="end" icon={chevronForward} />
      </IonItem>
    </IonCard>
  );
};

export { ContractorAgreement };
