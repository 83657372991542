import React from "react";
import { IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";

import { SummaryProps } from "./models";
import { dolars } from "../../utils/format";
const Summary: React.FC<SummaryProps> = ({ earnings, shifts }) => {
  return (
    <div className="ion-text-center">
      <IonGrid style={{ borderBottom: "1px dashed" }}>
        <IonRow>
          <IonCol>
            <h6>Lifetime Earnings</h6>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel style={{ fontSize: "-webkit-xxx-large" }}>
              {dolars(earnings) || "0.00"}
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h4>{shifts || "No "} Shifts</h4>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export { Summary };
