import {
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonRow,
  IonCol,
  IonSpinner,
  IonToast,
  IonBackButton,
  IonAlert,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, FormEvent, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openBrowser } from "src/lib/ionic-components";
import { mail } from "ionicons/icons";

import { reloadAgentAuth } from "../../store/session";
import { Store } from "../../store/store.model";
import {
  completeSignup,
  createCheckrInvite,
  showCheckrInviteLink,
} from "./api";
import { StyledIonPage } from "./style";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { OnBoardingRouteProps } from "../model";
import { environment } from "../../../environments/environment";
import { PackageType } from "../constants/BACKGROUND_CHECK_CONSTANTS";
import { trackAdjustEvent, AdjustEvents } from "../../utils/adjust";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";

import {
  DISMISSED_STRIPE_POPUP,
  WAS_APP_PAUSED,
} from "../../onboardingStripe/constants";
const WelcomeAgent: React.FC<OnBoardingRouteProps> = ({ agent }) => {
  const [gettingData, setGettingData] = useState<boolean>(false);
  const [checkrLoader, setCheckrLoader] = useState<boolean>(false);
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [invitationSent, setInvitationSent] = useState<boolean>(false);
  const [showCheckrErrorModal, setShowCheckrErrorModal] =
    useState<boolean>(false);
  const [showCheckrBCInvite, setShowCheckrBCInvite] = useState<boolean>(false);
  const { profile } = useSelector((state: Store) => state.session);
  const history = useHistory();
  const dispatch = useDispatch();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event && event.preventDefault();
    setShowErrorModal(false);
    try {
      setGettingData(true);
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_APP, {
        hcpId: agent?.userId as string,
        email: agent?.email as string,
      });
      trackAdjustEvent(AdjustEvents.ENTERED_APP, {
        hcpId: agent?.userId?.toString() || "",
      });
      await completeSignup();
      trackAdjustEvent(AdjustEvents.SIGNED_UP, {
        hcpId: agent?.userId?.toString() || "",
      });
      dispatch(reloadAgentAuth());

      // Used to track if user is coming from signup page for stripe popup
      localStorage.removeItem(DISMISSED_STRIPE_POPUP);
      localStorage.removeItem(WAS_APP_PAUSED);

      history.push("/home/openShifts");
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.FINAL,
          (error as Error).message,
          agent?.userId
        );
        setShowErrorModal(true);
        return;
      }
      setPageError({
        isError: true,
        message: error.response.text,
      });
    } finally {
      setGettingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
    setGettingData(false);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleSupportCall = () => {
    window.open(`tel:${environment.support.phone}`);
  };

  const handleCheckrInviteClick = useCallback(async () => {
    try {
      setCheckrLoader(true);
      setShowCheckrErrorModal(false);
      const resp = await createCheckrInvite(
        agent?.email,
        PackageType.BASIC_CRIMINAL
      );
      if (
        (process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV) !==
        "production"
      ) {
        console.log(`invitation url:- ${resp?.invitation_url}`);
      }
      setInvitationSent(true);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.CHECKR,
          (error as Error).message,
          agent?.userId
        );
        return;
      }
      setShowCheckrErrorModal(true);
    } finally {
      setCheckrLoader(false);
    }
  }, [profile]);

  const fetchCheckrInviteStatus = useCallback(async () => {
    try {
      setCheckrLoader(true);
      const { showCheckrInvite } = await showCheckrInviteLink();
      setShowCheckrBCInvite(showCheckrInvite);
    } catch (error) {
      if (
        !error.response ||
        !error.response.text ||
        error.response.status > 500
      ) {
        logOnboardingError(
          ONBOARDING_STAGES.CHECKR,
          (error as Error).message,
          agent?.userId
        );
        return;
      }
    } finally {
      setCheckrLoader(false);
    }
  }, [agent?.userId]);

  useEffect(() => {
    fetchCheckrInviteStatus();
  }, [agent]);

  useEffect(() => {
    if (
      agent &&
      agent.backgroundCheckApplications?.some(
        (bcApplication) => bcApplication?.applicationType === "checkr"
      )
    ) {
      setInvitationSent(true);
    }
  }, [agent]);

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentLicense"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <IonAlert
          mode="ios"
          isOpen={showCheckrErrorModal}
          backdropDismiss={false}
          header="Uh oh!"
          message="We ran into a problem. Please try again, or call our Support Team if the issue persists."
          buttons={[
            {
              text: "Support",
              handler: handleSupportCall,
            },
            {
              text: "Try Again",
              handler: handleCheckrInviteClick,
            },
          ]}
        />

        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="cbh-logoh-wrapper">
                  <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
                </div>
                <div className="form-heading">
                  <h4>Welcome to Clipboard Health</h4>
                  <p>
                    Congratulations on joining the medical professional
                    marketplace where you can set your own schedule!
                    <br />
                    <br />
                    Here’s what happens next:
                  </p>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <ul className="cbh-welcome-text">
                  {showCheckrBCInvite && (
                    <li>
                      <h4>Background check</h4>
                      <p>
                        Request and complete a background check from our partner
                        service (Checkr)
                      </p>
                      <IonButton
                        expand="block"
                        size="large"
                        class="ion-margin-top ion-margin-bottom continue-button"
                        disabled={checkrLoader || invitationSent}
                        onClick={handleCheckrInviteClick}
                      >
                        {checkrLoader && !invitationSent ? (
                          <IonSpinner name="lines" className="invite-icon" />
                        ) : (
                          <IonIcon icon={mail} className="invite-icon" />
                        )}
                        {invitationSent ? "Sent!" : "Email me an invite"}
                      </IonButton>
                    </li>
                  )}
                  <li>
                    <h4>Submitting Requirements</h4>
                    <p>
                      Some facilities may have additional requirements that are
                      unique to them. You’ll see them when you start shopping
                      for shifts!
                    </p>
                  </li>
                  <li>
                    <h4>Start finding shifts</h4>
                    <p>
                      You can immediately start finding shifts that interest
                      you! You’ll be working those shifts and making money in no
                      time!
                    </p>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                disabled={
                  gettingData ||
                  checkrLoader ||
                  (showCheckrBCInvite && !invitationSent)
                }
                onClick={onNext}
              >
                Show me some shifts
                {gettingData && (
                  <IonSpinner
                    slot="end"
                    class="ion-margin-start"
                    name="lines"
                  />
                )}
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { WelcomeAgent };
