import "../style.scss";
import "react-image-crop/dist/ReactCrop.css";

import ReactCrop from "react-image-crop";
import { useDispatch, useSelector } from "react-redux";
import { add, arrowBack, cloudUpload, create } from "ionicons/icons";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import React, { Fragment, useRef, useState } from "react";

import { logEvent } from "src/lib/analytics";
import { updateAgent } from "../../../store/session";
import { uploadProfileImage } from "./api";
import { USER_EVENTS } from "../../../../constants/userEvents";
import { capturePhoto } from "@app/utils/mediaUpload";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getUserId } from "@src/app/store/selectors/app.selector";
import { FeatureFlag } from "@src/constants/FEATURE_FLAGS";

const UpdateProfileImage = ({ url, onClose }) => {
  const [isLoading, setLoading] = useState(false);
  const ldFlags = useFlags();
  const userId = useSelector(getUserId);
  const [selectedFile, setSelectedFile] = useState<{
    file: string | ArrayBuffer | undefined | null;
    type: string;
  } | null>(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 60,
    aspect: 16 / 16,
    x: 20,
    y: 20,
  });
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState<unknown>();

  const dispatch = useDispatch();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  let initialCroppedImageRef = null;

  const onImageLoaded = (image) => {
    initialCroppedImageRef = image;
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);
  };

  const makeClientCrop = async (crop) => {
    if (crop.width && crop.height) {
      const croppedImageFile = await getCroppedImg(
        imageRef || initialCroppedImageRef,
        crop
      );
      setCroppedImageFile(croppedImageFile);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const openGallery = async () => {
    const { file, type } = await capturePhoto();
    setSelectedFile({ file, type });
  };

  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) return;

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [_, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFile({ file: reader.result, type });
    };
    event.target.value = null;
  };

  const handleTakePhoto = () => {
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery() : inputFileRef?.current?.click();
  };

  const handleOnClose = () => {
    onClose();
    setSelectedFile(null);
    setCroppedImageFile(null);
  };

  const handleUpload = async () => {
    setLoading(true);
    const profileImageUrl = await uploadProfileImage({
      croppedImageFile,
      dualWriteEnabled: ldFlags[FeatureFlag.DUAL_WRITES_FILE_STORAGE_SERVICE],
      userId,
    });
    logEvent(USER_EVENTS.UPDATED_PROFILE_PHOTO);
    dispatch(updateAgent({ profileImageUrl }));
    setLoading(false);
    onClose();
    setSelectedFile(null);
    setCroppedImageFile(null);
  };

  return (
    <Fragment>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerDirection="back"
              fill="clear"
              size="small"
              color="light"
              onClick={handleOnClose}
            >
              <IonIcon icon={arrowBack} mode="ios" />
            </IonButton>
          </IonButtons>
          <IonTitle>Profile Photo</IonTitle>
          {url && (
            <IonButton /** First time user is shown different screen to upload his profile image. */
              slot="end"
              fill="clear"
              size="small"
              color="light"
              onClick={handleTakePhoto}
            >
              <IonIcon icon={create} mode="ios" />
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!selectedFile &&
          (url ? (
            <IonImg
              class="ion-margin profile-image-view"
              src={url}
              alt="Profile"
            />
          ) : (
            <div className="profile-image-editor-placeholder">
              <div onClick={handleTakePhoto}>
                <IonIcon icon={add} mode="ios" style={{ fontSize: "32px" }} />
                Upload
              </div>
            </div>
          ))}
        {selectedFile && (
          <Fragment>
            <ReactCrop
              className="ion-margin"
              src={selectedFile.file}
              crop={crop}
              ruleOfThirds
              keepSelection={true}
              minWidth={150}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
            <IonButton
              class="ion-margin"
              expand="block"
              color="primary"
              disabled={isLoading}
              onClick={handleUpload}
            >
              {isLoading ? (
                <Fragment>
                  <IonSpinner slot="start" name="crescent" />
                  Uploading
                </Fragment>
              ) : (
                <Fragment>
                  <IonIcon slot="start" icon={cloudUpload} mode="ios" />
                  Upload
                </Fragment>
              )}
            </IonButton>
          </Fragment>
        )}
      </IonContent>
      <input
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </Fragment>
  );
};

export { UpdateProfileImage };
