import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";
import { PrivateRouteProps } from "./model";
import { StatusRoute } from "./statusRoute";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isAuthorized,
  component,
  ...rest
}) => {
  const renderRoute = (props: RouteComponentProps) => {
    const state = {
      from: props.location,
    };

    if (!isAuthorized) {
      return <Redirect to={{ pathname: GlobalRouterPath.WELCOME, state }} />;
    }
    const Component = component!;
    return <Component {...props} />;
  };

  return <StatusRoute {...rest} render={renderRoute} />;
};

export { PrivateRoute };
