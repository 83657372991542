import { logEvent } from "src/lib/analytics";
import {
  Facility,
  Shift,
  TimeSystems,
  ClockInMethods,
  SHIFT_MARKED_NON_IP_REASONS,
  DeviceNFCCapabilityForShift,
  NFCShiftConversionReason,
} from "src/lib/interface";
import { isValidObjectId } from "src/lib/utils";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAlert,
  isPlatform,
} from "@ionic/react";
import { SessionEnv } from "@store/session";
import moment from "moment-timezone";
import { ShiftLoader } from "src/lib/ionic-components";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "@store/index";
import { USER_EVENTS } from "../../../constants/userEvents";
import { useMissingDocuments } from "../../dayView/custom-hooks/useMissingDocuments";
import { FacilityDetails as DetailedFacility } from "../../dayView/facilityDetails";
import { ShiftTimeline } from "./shiftTimeline";
import { NoTimeSheetOptions } from "@app/components/shift/noTimeSheetOptions";
import { RatingPrompt } from "../../rating";
import { FacilityDetails } from "../components/facilityDetails";
import { ShiftTitle } from "../components/shiftTitle";
import { getShiftTitleString } from "../helper";
import { MissingDocsDetails } from "./missingDocs";
import { RatingSuccess } from "./ratingSuccess";
import { ShiftInstructions } from "./shiftInstructions";
import { UploadFileModal } from "../../components/uploadFile";
import { useAlertsForShiftDetails } from "./alerts";
import { TimeSheetSummary } from "../components/timesheetSummary";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { api } from "@app/api";
import { NonIPConversionText } from "@app/components/shift/nonIPConversionMessage";
import { DefaultPolicyShiftActionFooter } from "./defaultPolicyShiftActionFooter";
import { AttendancePolicy } from "src/constants/attendancePolicy";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";
import { AttendancePolicyShiftActionFooter } from "./attendancePolicyShiftActionFooter";
import { ShiftActionFooterProps } from "./types";
import { StatusBar } from "@capacitor/status-bar";
import { NFC_FAIL_STATUS } from "../constants";
import { actionRecordShiftTimeFailure } from "@src/app/store/ongoingShifts";
import { deviceNFCCapabilityForShift } from "../components/nfc/nfcHelper";
import { useDispatch } from "react-redux";
import { getNFCShiftConversionLogReason } from "../components/nfc/utils/nfcLogReasons";

const MyShiftDetailsPage = () => {
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] =
    useState<boolean>(false);
  const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);
  const [shift, setShift] = useState<Shift | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [title, setTitle] = useState("My Shift");
  const [openNoTimeSheetOptions, setOpenNoTimeSheetOptions] =
    useState<boolean>(false);
  const [openRating, setOpenRating] = useState<boolean>(false);
  const [hasMissedClockIn, setHasMissedClockIn] = useState<boolean>(false);
  const [isRatingSuccess, setIsRatingSuccess] = useState<boolean>(false);
  const [canShowUploadButton, setCanShowUploadButton] = useState<boolean>(true);
  const [isTimeSheetRequired, setIsTimeSheetRequired] = useState<boolean>(true);
  const { shiftId } = useParams<{ shiftId: string }>();
  const [facilityDetails, setFacilitydetails] = useState<Facility | null>(null);
  const [shiftDetails, setShiftDetails] = useState<Shift | null>(null);
  const isMobileApp = isPlatform("capacitor");
  const ldClient = useLDClient();
  const [isDigitalSignatureEnabled, setIsDigitalSignatureEnabled] =
    useState<boolean>(true);
  const [isNFCExternalWriteEnabled, setIsNFCExternalWriteEnabled] =
    useState<boolean>(false);

  const setLDToggles = async () => {
    if (!shift?.facility?.userId) return;
    const previousLDUser = ldClient?.getUser();

    await ldClient?.identify({
      key: shift?.facility?.userId,
      name: shift?.facility?.name,
      custom: {
        state: shift?.facility?.fullAddress?.state || "",
      },
    });

    setIsDigitalSignatureEnabled(
      ldClient?.variation(FeatureFlag.DIGITAL_SIGNATURE, false)
    );

    setIsNFCExternalWriteEnabled(
      ldClient?.variation(FeatureFlag.ENABLE_EXTERNAL_NFC_WRITE, false)
    );

    await ldClient?.identify({ ...previousLDUser });
  };

  useEffect(() => {
    setLDToggles();
  }, [shift]);

  const isNewTimeSheetEnabled = ldClient?.variation(
    FeatureFlag.DISPLAY_TIME_SHEET_SUMMARY,
    false
  );
  const attendancePolicyVariation = ldClient?.variation(
    FeatureFlag.ATTENDANCE_POLICY
  );
  const isAttendancePolicyEnabled = [
    AttendancePolicy.ATTENDANCE_SCORE,
    AttendancePolicy.UPGRADED_STATUS_QUO,
  ].includes(attendancePolicyVariation);

  const isRefactoredVersionEnabled = ldClient?.variation(
    FeatureFlag.TIMESHEETSV2_REFACTORED_VERSION,
    false
  );

  const showTimeSheetSummaryModel = isTimeSheetSummaryModelEnabled();

  const isTimeSheetsNonIpEnabled = ldClient?.variation(
    FeatureFlag.TIMESHEETS_V2_NON_IP,
    false
  );
  const env = useAppSelector(
    (state) => state?.session?.env ?? ({} as SessionEnv)
  );
  const shiftList = useAppSelector(
    (state) => state?.ongoingShiftStore?.shiftList ?? []
  );

  const openTimesheetModel = async (isFromAlert?: boolean) => {
    if (showTimeSheetSummaryModel && isMobileApp) {
      await ScreenOrientation.lock(
        ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY
      );
      await StatusBar.hide();
    }
    if (isFromAlert) {
      setShowFileExplorer(true);
    }
    setShowUploadTimesheetModal(true);
  };

  const isNFCEnabled = isRefactoredVersionEnabled
    ? shift?.nfcTag || false
    : shift?.facility?.timeSystem === TimeSystems.NFC &&
      shift.clockInMethod === ClockInMethods.PRIMARY;

  const isSignatureSubmission = isRefactoredVersionEnabled
    ? isNewTimeSheetEnabled && isDigitalSignatureEnabled
    : shift?.facility?.timeSystem == TimeSystems.IPAD ||
      shift?.facility?.timeSystem == TimeSystems.NFC;

  const closeTimesheetModel = async () => {
    if (showTimeSheetSummaryModel && isMobileApp) {
      await ScreenOrientation.unlock();
      await ScreenOrientation.lock(
        ScreenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY
      );
      await StatusBar.show();
    }
    setShowUploadTimesheetModal(false);
  };

  const history = useHistory();
  const { userId } = useAppSelector((state) => state.session);
  const { agent = {} } = useAppSelector((state) => state.session);

  const { cbh, state, msa, county, hcf } = useMissingDocuments(shift);

  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);

  const shiftDetailsAlerts = useAlertsForShiftDetails();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isValidObjectId(shiftId)) return;
    (async function () {
      await loadShiftDetails(shiftId);
    })();
  }, [shiftId]);

  const convertToBackUp = async () => {
    await api.shift.changeShiftClockInMethodToBackUp(
      shiftId as string,
      NFC_FAIL_STATUS.nfc_not_supported,
      isRefactoredVersionEnabled,
      createAccountLogForShiftConversion
    );
  };

  const createAccountLogForShiftConversion = (
    nfcShiftConversionReason: NFCShiftConversionReason
  ) => {
    const conversionReason = getNFCShiftConversionLogReason(
      nfcShiftConversionReason,
      shiftId
    );
    dispatch(
      actionRecordShiftTimeFailure(conversionReason, agent, shiftId as string)
    );
    return;
  };

  const loadShiftDetails = async (shiftId: string): Promise<Shift> => {
    setLoading(true);
    let res = await api.shift.fetchShiftDetails({ shiftId });
    const deviceNFCCapability = await deviceNFCCapabilityForShift();
    if (deviceNFCCapability === DeviceNFCCapabilityForShift.NO_NFC) {
      if (
        (isRefactoredVersionEnabled && res?.nfcTag === true) ||
        (!isRefactoredVersionEnabled &&
          res?.clockInMethod === ClockInMethods.PRIMARY)
      ) {
        await convertToBackUp();
        res = await api.shift.fetchShiftDetails({ shiftId });
      }
    }
    const facility = res.facility as Facility;
    facility.verification = res.facility?.verificationPreference;
    if (res?.facility?.userId) {
      const { data } = await api.facility.fetchFacilityDetails(
        res.facility.userId
      );
      res.facility.verificationPreference = data;
    }
    setShift(res);
    const shiftTitle = getShiftTitleString({
      shiftStart: res.start,
      shift: res,
    });
    setTitle(shiftTitle);
    setLoading(false);
    return res; // if someone needs it before state updates
  };

  useEffect(() => {
    const updatedShift = shiftList?.find((item) => item?._id === shiftId);
    if (!shift || !updatedShift) return;
    setShift({
      ...shift,
      ...updatedShift,
      facility: { ...shift?.facility, ...updatedShift.facility }, // updated shifts returns fewer object keys on refresh
    });
  }, [shiftList, shiftId]);

  const triggerMissedClockIn = useCallback(async () => {
    if (shift?.isInstantPay && !shift.clockInOut?.start) {
      logEvent(USER_EVENTS.MISSED_INSTANTPAY_CLOCK_IN, {
        shift,
      });
      await api.shift.markShiftAsNonInstantPay(
        shift?._id as string,
        SHIFT_MARKED_NON_IP_REASONS.MOBILE_FORGOT_TO_CLOCK_IN
      );
      loadShiftDetails(shift?._id as string);
      if (shift?.isChangedToNonInstantPay) setHasMissedClockIn(true);
    }
  }, [shift]);

  useEffect(() => {
    if (!shift) {
      return () => {};
    }

    let timeout: NodeJS.Timeout | null = null;

    if (
      (isRefactoredVersionEnabled &&
        (!isNewTimeSheetEnabled || !isTimeSheetsNonIpEnabled)) ||
      !isRefactoredVersionEnabled
    ) {
      const clockInTimeDiff = moment(shift?.start)
        .add(env?.minToAllowTimeCardUpload, "minutes")
        .diff(moment());

      if (clockInTimeDiff < 0) {
        triggerMissedClockIn();
      } else {
        timeout = setTimeout(() => {
          triggerMissedClockIn();
        }, clockInTimeDiff);
      }
    }
    if (shift?.timecardNotAvailable?.reason || shift?.agentId !== userId) {
      setCanShowUploadButton(false);
    }

    if (
      !(
        shift.facility?.verificationPreference?.usesTimesheets ||
        shift.facility?.requireTimecardPhoto
      )
    ) {
      setIsTimeSheetRequired(false);
    }

    if (
      !(
        shift.facility?.verificationPreference?.usesTimesheets ||
        shift.facility?.requireTimecardPhoto
      )
    )
      setIsTimeSheetRequired(false);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shift, env, triggerMissedClockIn, userId]);

  const formattedDate = shift && moment(shift?.start).format("ddd, MMMM DD");

  const uploadTimeSheet = (isFromAlert?: boolean) =>
    openTimesheetModel(isFromAlert);

  const cancelNoTimeSheet = () => setOpenNoTimeSheetOptions(false);

  const handleClose = () => {
    setIsRatingSuccess(false);
    history.push("/home/openShifts");
  };

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard = false
  ) => {
    if (shift?.isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilitydetails(facility ?? null);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(null);
    setShiftDetails(null);
  };

  const hasMissingDocs = !!(cbh || state || msa || county || hcf);
  const timeSheetExists =
    (shift?.timecard?.files?.length as number) > 0 ||
    !!shift?.timecardNotAvailable?.reason;

  const { alert } = shiftDetailsAlerts;

  const onSuccessfulUpload = async () => {
    closeTimesheetModel();
    setCanShowUploadButton(false);
    // we can't just wait for React's setState updates, so we get it directly
    const updatedShift = await loadShiftDetails(shiftId);
    if (
      updatedShift.instantPayDetails?.is100InstantPayEnabled &&
      updatedShift.isInstantPay
    ) {
      shiftDetailsAlerts.alertShiftFinishedSuccessfully({
        shift: updatedShift,
        continueBtnHandler: () => {
          setOpenRating(true);
        },
      });
    } else {
      setOpenRating(true);
    }
  };

  const shiftFooterProps: ShiftActionFooterProps = {
    shift,
    hasMissingDocs,
    hasMissedClockIn,
    isTimeSheetRequired,
    timeSheetExists,
    canShowUploadButton,
    loadShiftDetails,
    uploadTimeSheet,
    setOpenNoTimeSheetOptions,
    isNewTimeSheetEnabled,
    shiftDetailsAlerts,
    isSignatureSubmission,
    isNFCEnabled,
    isShiftLoading: isLoading,
    isDigitalSignatureEnabled,
    isRefactoredVersionEnabled,
    isNFCExternalWriteEnabled,
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" />
          </IonButtons>
          <IonTitle>{title || formattedDate}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ShiftLoader loading={isLoading} count={1} />
        <IonCard className="shift-details-card no-text-transform new-flow shifts ongoing">
          {!isLoading && (
            <>
              <ShiftTitle
                shift={shift}
                hasMissingDocs={hasMissingDocs}
                showMissingDocs={!shift?.agent}
                onShiftClick={() => void 0}
              />
              <FacilityDetails
                onFacilityDetailsClick={onClickOnItemFacilityDetails}
                shift={shift}
                showCheckInInstructions={false}
                onCardClick={() => void 0}
                showMinimalDetails={Boolean(shift?.clockInOut)}
              />
              <ShiftInstructions
                hasMissingDocs={hasMissingDocs}
                hasMissedClockIn={hasMissedClockIn}
                shift={shift!}
                isTimeSheetRequired={isTimeSheetRequired}
                timeSheetExists={timeSheetExists}
                isNewTimeSheetEnabled={isNewTimeSheetEnabled}
                isSignatureSubmission={isSignatureSubmission}
                isRefactoredVersionEnabled={isRefactoredVersionEnabled}
              />
              <MissingDocsDetails
                agent={shift?.agent}
                hasMissingDocs={hasMissingDocs}
              />
              {shift?.isChangedToNonInstantPay && (
                <NonIPConversionText
                  hasMissedClockIn={hasMissedClockIn}
                  isAutoClockedOut={!!shift.autoClockedOut}
                />
              )}
            </>
          )}
        </IonCard>
        <NoTimeSheetOptions
          visible={openNoTimeSheetOptions}
          setVisible={setOpenNoTimeSheetOptions}
          cancel={cancelNoTimeSheet}
          shift={shift as Shift}
          setCanShowUploadButton={setCanShowUploadButton}
        />
      </IonContent>
      {!isLoading &&
        shift &&
        shift?.isInstantPay &&
        (shift?.clockInOut || !hasMissedClockIn) && (
          <ShiftTimeline shift={shift} />
        )}
      {isRatingSuccess && (
        <RatingSuccess isOpen={isRatingSuccess} onClose={handleClose} />
      )}
      {openRating && (
        <RatingPrompt
          shift={shift as Shift | undefined}
          onDidDismiss={() => void 0}
          setIsRatingSuccess={setIsRatingSuccess}
        />
      )}
      {isAttendancePolicyEnabled ? (
        <AttendancePolicyShiftActionFooter {...shiftFooterProps} />
      ) : (
        <DefaultPolicyShiftActionFooter
          {...shiftFooterProps}
          isShiftLoading={isLoading}
        />
      )}
      {showUploadTimesheetModal &&
        shift &&
        (showTimeSheetSummaryModel ? (
          <TimeSheetSummary
            shift={shift}
            isSignatureSubmission={isSignatureSubmission}
            showFileExplorer={showFileExplorer}
            onCloseOrCancel={closeTimesheetModel}
            onSuccessfulUpload={onSuccessfulUpload}
            loadShiftDetails={loadShiftDetails}
            isNewTimeSheetEnabled={isNewTimeSheetEnabled}
            isRefactoredVersionEnabled={isRefactoredVersionEnabled}
            isNFCExternalWriteEnabled={isNFCExternalWriteEnabled}
          />
        ) : (
          <UploadFileModal
            shift={shift}
            onCloseOrCancel={() => setShowUploadTimesheetModal(false)}
            onSuccessfulUpload={onSuccessfulUpload}
          />
        ))}
      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && (
          <DetailedFacility
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails as Shift | undefined}
            displayExtraTimePayCard={displayExtraTimePayCard}
          />
        )}
      </IonModal>
      <IonAlert
        header={alert?.header}
        message={alert?.message}
        isOpen={!!alert}
        // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
        onWillDismiss={shiftDetailsAlerts.dismissAlert}
        buttons={alert?.buttons}
        mode="ios"
      />
    </IonPage>
  );

  function isTimeSheetSummaryModelEnabled() {
    return isRefactoredVersionEnabled
      ? isNewTimeSheetEnabled && shift?.isInstantPay
      : isNewTimeSheetEnabled &&
          shift?.isInstantPay &&
          ((shift?.facility?.timeSystem === TimeSystems.PAPER &&
            shift?.clockInMethod !== ClockInMethods.PRIMARY) ||
            shift?.facility?.timeSystem === TimeSystems.NFC ||
            shift?.facility?.timeSystem === TimeSystems.IPAD);
  }
};

export { MyShiftDetailsPage };
