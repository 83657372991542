import { AuthorizedSignatory, Shift, Facility } from "src/lib/interface";

import { Dispatch, SetStateAction } from "react";

interface ShiftSignatureProps {
  shift: Shift;
  onClose: () => void;
  onShiftUpdate: (updatedShift: Shift) => void;
}

enum ShiftSignMode {
  WORKER = "worker",
  AUTHORIZED = "authorized",
  REQUESTED = "requested",
  MANUAL = "manual",
  RATING = "rating",
  MANUAL_SIGNATURE = "manualSignature",
}

interface ReviewData {
  rating: number;
  reasons: string[];
  otherReason: string;
  review: string;
}

interface SignatureHeaderProps {
  onClose: () => void;
}

interface UpdateShiftTimeRequest {
  shiftId: string;
  key: string;
  start: string;
  end: string;
}

interface SignModeContainerProps {
  mode: ShiftSignMode;
  requiredMode: ShiftSignMode;
}

interface SignatureContainerProps {
  onChange: (signature: string) => void;
  getHeight?: () => number;
}

interface SignatureContainerRef {
  clearSignature: () => void;
}

interface WorkerSignatureProps extends SignatureContainerProps {
  shift: Shift;
}

interface SignButtonProps {
  signMode: ShiftSignMode;
  loading: boolean;
  shift: Shift;
  signatory: AuthorizedSignatory;
  signature: string;
  isDisabled: boolean;
  onClick: () => void;
}

type SignButtonIsDisabled = (
  signMode: ShiftSignMode,
  options: { shift: Shift; signatory: AuthorizedSignatory; signature: string }
) => boolean;

interface ShiftTimeConfirmAlertProps {
  isOpen: boolean;
  time: number;
  onClose: (isSuccess: boolean) => () => void;
}

interface SwitchManualSignatoryProps {
  facility: Facility;
  setSignMode: Dispatch<SetStateAction<ShiftSignMode>>;
}

export {
  ShiftSignatureProps,
  ShiftSignMode,
  SignatureHeaderProps,
  UpdateShiftTimeRequest,
  SignModeContainerProps,
  WorkerSignatureProps,
  SignatureContainerProps,
  SignButtonProps,
  SignButtonIsDisabled,
  ShiftTimeConfirmAlertProps,
  SwitchManualSignatoryProps,
  ReviewData,
  SignatureContainerRef,
};
