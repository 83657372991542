import { keyBy, isEmpty } from "lodash";
import { NOTES_CATEGORY } from "../constants/facility";

const getNotesByCategory = (notes, key) => {
  if (isEmpty(notes)) return "";
  const obj = keyBy(notes, "identifier");
  return (obj && obj[key]) || "";
};

const getCheckInInstructionsObj = (facility) => {
  return {
    CHECK_IN_INST: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST
    ),
    CHECK_IN_INST_PARKING: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_PARKING
    ),
    CHECK_IN_INST_ENTRANCE: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_ENTRANCE
    ),
    CHECK_IN_INST_ORIENTATION: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_ORIENTATION
    ),
    CHECK_IN_INST_EARLYCHECKIN: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_EARLYCHECKIN
    ),
    CHECK_IN_INST_FIRSTSHIFT: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_FIRSTSHIFT
    ),
    CHECK_IN_INST_TIMECARD: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_TIMECARD
    ),
    CHECK_IN_INST_DRESS: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_DRESS
    ),
    CHECK_IN_INST_OTHER: getNotesByCategory(
      facility.facilityNotes,
      NOTES_CATEGORY.CHECK_IN_INST_OTHER
    ),
  };
};

export { getNotesByCategory, getCheckInInstructionsObj };
