import { RequestedSignatory } from "src/lib/interface";
import { stringSorter } from "src/lib/utils";
import { IonItem, IonLabel } from "@ionic/react";
import Alert from "antd/es/alert";
import { filter, isEmpty, last } from "lodash";
import React, { Fragment, useEffect, useState } from "react";

import { ShiftSignMode } from "../model";
import { RequestedContainerProps } from "./model";

const RequestedContainer: React.FC<RequestedContainerProps> = ({
  setSignMode,
  shiftInfo,
}) => {
  const [signatoryInfo, setSignatoryInfo] = useState<
    Partial<RequestedSignatory>
  >({});

  useEffect(() => {
    const requestedSignatories = filter(
      shiftInfo.requestedSignatories,
      (signatory) => signatory.status === "REQUESTED"
    );

    requestedSignatories.sort(stringSorter("createdAt"));

    setSignatoryInfo(Object.assign({}, last(requestedSignatories)));
  }, [shiftInfo]);

  const onRequest = () => setSignMode(ShiftSignMode.WORKER);

  const sentVia: string[] = [];

  if (!isEmpty(signatoryInfo.email)) {
    sentVia.push("email");
  }

  if (!isEmpty(signatoryInfo.phone)) {
    sentVia.push("text");
  }

  return (
    <Fragment>
      <IonItem lines="none">
        <Alert
          message={`This shift has been sent to ${signatoryInfo.name}, ${
            signatoryInfo.role
          } via ${sentVia.join(" & ")} to be signed. Refresh page to update.`}
          type="info"
          className="ion-margin-horizontal"
          showIcon={true}
        />
      </IonItem>
      <IonItem class="ion-margin-vertical" lines="none">
        <IonLabel>
          <h4 className="ion-text-wrap ion-text-center">
            Not working?{" "}
            <a color="primary" onClick={onRequest}>
              Request a new signature
            </a>
          </h4>
        </IonLabel>
      </IonItem>
    </Fragment>
  );
};

export { RequestedContainer };
