import { surgeryWorkerTypes } from "src/lib/constants";
import { Shift } from "src/lib/interface";
import { RefresherEventDetail } from "@ionic/core";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { get, groupBy } from "lodash";
import moment from "moment-timezone";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { groupByShiftName } from "../store/shift";
import { ShiftDateMap } from "../store/shift/model";
import { Store } from "../store/store.model";
import { fetchCurrentTimezone, fetchOpenShifts, fetchYourShifts } from "./api";
import { GroupItem } from "./groupItem";
import { ShiftListPage } from "./shiftList";
import { ShiftNameLoader } from "./shiftNameLoader";
import { ShiftListGroupState, RequestOpenShiftOptions } from "./model";
import {
  DISTANCE_PREFERENCE_MAX_VALUE,
  SHIFT_PREFERENCE,
  SEARCH_MODE,
} from "src/constants";
import useFetchHcpBonuses, {
  getBonusesForDate,
  HcpBonus,
} from "src/app/hcpBonuses/useFetchHcpBonuses";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "@src/constants/FEATURE_FLAGS";
import { ribbon } from "ionicons/icons";
import "./shiftList.scss";
import "swiper/css";

interface BonusInfoSlideProps {
  bonus: HcpBonus;
}
const BonusInfoSlide = (props: BonusInfoSlideProps) => {
  return (
    <div>
      <h3>{props.bonus.title}</h3>
      <div className="hcp-bonus-info-modal-text">
        <p>{props.bonus.description}</p>
      </div>
    </div>
  );
};

interface BonusInfoSliderControllerProps {
  numberOfSlides: number;
}
const BonusInfoSliderController = (props: BonusInfoSliderControllerProps) => {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(swiper.activeIndex);

  React.useEffect(() => {
    swiper.on("slideChange", () => {
      setActiveIndex(swiper.activeIndex);
    });
  }, []);

  return (
    <div>
      {props.numberOfSlides > 1 && (
        <div className="hcp-bonus-info-modal-pagination">
          {Array.from({ length: props.numberOfSlides }).map((bonus, i) => (
            <button
              key={i}
              className={`hcp-bonus-info-modal-pagination-dot ${
                activeIndex === i ? "active" : ""
              }`}
              onClick={() => {
                swiper.slideTo(i);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const OpenShiftDayView: React.FC = () => {
  const { date } = useParams() as any;
  const agent = useSelector((state: Store) => get(state, "session.agent", {}));
  const qualification = useSelector((state: Store) =>
    get(state, "session.agent.qualification", "")
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [myShiftMap, setMyShiftMap] = useState<ShiftDateMap>({});
  const [openShiftMap, setOpenShiftMap] = useState<ShiftDateMap>({});
  const [shiftName, setShiftName] = useState<string | null>(null);
  const [totalShiftsCount, setTotalShiftsCount] = useState<number>(0);
  const [hiddenShiftsCount, setHiddenShiftsCount] = useState<number>(0);
  const [shiftList, setShiftList] = useState<ShiftListGroupState>({
    open: [],
    assigned: [],
  });
  const history = useHistory();
  const [showHcpRewardAlert, setShowHcpRewardAlert] = useState<boolean>(false);
  const [didFetchShifts, setDidFetchShifts] = useState(false);

  const ldFlags = useFlags();
  const minimumPayRollout = ldFlags[FeatureFlag.MINIMUM_PAY_FILTER_ACTIVE];

  const distancePreference = useMemo(() => {
    return get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE);
  }, [agent?.preference?.distance]);

  const minPayHourlyPreference = useMemo(() => {
    if (!minimumPayRollout) return SHIFT_PREFERENCE.PAY_HOUR;
    return get(agent, "preference.minPayHourly", SHIFT_PREFERENCE.PAY_HOUR);
  }, [agent?.preference?.minPayHourly, minimumPayRollout]);

  const minPayShiftPreference = useMemo(() => {
    if (!minimumPayRollout) return SHIFT_PREFERENCE.PAY_SHIFT;
    return get(agent, "preference.minPayShift", SHIFT_PREFERENCE.PAY_SHIFT);
  }, [agent?.preference?.minPayShift, minimumPayRollout]);

  const baseRate = useMemo(() => {
    return get(agent, "baseRate", SHIFT_PREFERENCE.BASE_RATE);
  }, [agent?.baseRate]);

  const shiftNames =
    qualification && surgeryWorkerTypes.includes(qualification)
      ? ["surg"]
      : ["am", "pm", "noc"];

  const getOpenAndMyShifts = useCallback(async () => {
    if (date && get(agent, "geoLocation.coordinates")) {
      (async function () {
        try {
          setLoading(true);
          const options: RequestOpenShiftOptions = {
            range: { start: date, end: date },
            coordinates: get(agent, "geoLocation.coordinates", []),
            distance: distancePreference,
            minPayHourly: minPayHourlyPreference,
            minPayShift: minPayShiftPreference,
            qualification,
            specialities: get(agent, "specialities", {}),
          };
          let [openShifts, myShifts]: [Shift[], void | ShiftDateMap, string] =
            await Promise.all([
              fetchOpenShifts(options),
              fetchYourShifts({ start: date, end: date }),
              fetchCurrentTimezone(),
            ]);
          if (Array.isArray(myShifts)) return;
          const myTodayShifts = myShifts[date] || [];
          setMyShiftMap(groupBy(myTodayShifts, groupByShiftName));

          const totalShiftsCountWithFiltered = openShifts.length;

          openShifts = openShifts.filter((shift) => !shift.filtered);

          const openShiftsCount = openShifts.length;
          setTotalShiftsCount(openShiftsCount);
          setHiddenShiftsCount(totalShiftsCountWithFiltered - openShiftsCount);

          setOpenShiftMap(groupBy(openShifts, groupByShiftName));
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      })();
    }
  }, [
    date,
    setLoading,
    setMyShiftMap,
    setOpenShiftMap,
    agent,
    minimumPayRollout,
  ]);

  useEffect(() => {
    const isValidDate = moment(date, "YYYY-MM-DD", true);
    if (!isValidDate) {
      return history.goBack();
    }
  }, [date, history]);

  useEffect(() => {
    if (!didFetchShifts) {
      getOpenAndMyShifts();
      setDidFetchShifts(true);
    }
  }, [distancePreference, minPayHourlyPreference, minPayShiftPreference]);

  useIonViewDidEnter(() => {
    getOpenAndMyShifts();
  }, []);

  useEffect(() => {
    if (shiftName) {
      setShiftList({
        open: openShiftMap[shiftName] || [],
        assigned: myShiftMap[shiftName] || [],
      });
    } else {
      setShiftList({ open: [], assigned: [] });
    }
  }, [myShiftMap, openShiftMap, shiftName]);

  const closeShiftList = () => {
    setShiftName(null);
  };

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await getOpenAndMyShifts();
    event.detail.complete();
  };

  const goToDocumentsPage = () => {
    setShiftName(null);
    history.push(`/home/account/documents`);
  };
  useIonViewDidLeave(() => {
    setShiftName(null);
  }, []);

  const isHcpBonusesEnabled = ldFlags["hcp-bonuses"] as boolean;
  const { bonuses } = useFetchHcpBonuses(agent.tmz);

  const availableBonus = getBonusesForDate(bonuses, moment(date));

  const dismissHcpBonusesInfoModal = React.useCallback(() => {
    setShowHcpRewardAlert(false);
  }, []);

  return (
    <Fragment>
      <IonPage>
        <IonHeader no-border>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/openShifts"
                mode="ios"
              />
            </IonButtons>
            <IonTitle>{moment(date).format("dddd, MMMM DD YYYY")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isHcpBonusesEnabled && availableBonus.length > 0 && (
            <>
              <IonModal
                isOpen={showHcpRewardAlert}
                onDidDismiss={dismissHcpBonusesInfoModal}
                cssClass="hcp-bonus-info-modal"
              >
                <div className="hcp-bonus-info-modal-container">
                  <Swiper slidesPerView={1}>
                    {availableBonus.map((bonus) => (
                      <SwiperSlide key={bonus.id}>
                        <BonusInfoSlide bonus={bonus} />
                      </SwiperSlide>
                    ))}

                    <IonButton
                      expand="full"
                      shape="round"
                      fill="outline"
                      onClick={() => {
                        dismissHcpBonusesInfoModal();
                        history.push("/home/account/hcp-bonuses");
                      }}
                    >
                      Go to My Promotions
                    </IonButton>
                    <IonButton
                      expand="full"
                      shape="round"
                      style={{
                        "--border-color": "#828282",
                        "--color": "#828282",
                        marginTop: "10px",
                      }}
                      fill="outline"
                      onClick={dismissHcpBonusesInfoModal}
                    >
                      Dismiss
                    </IonButton>
                    <BonusInfoSliderController
                      numberOfSlides={availableBonus.length}
                    />
                  </Swiper>
                </div>
              </IonModal>

              <div
                className="hcp-bonus-banner"
                onClick={() => setShowHcpRewardAlert(true)}
              >
                <IonIcon icon={ribbon} />
                <span>ongoing promotion available</span>
                <IonIcon icon={ribbon} />
              </div>
            </>
          )}
          <ShiftFilters
            totalShifts={totalShiftsCount}
            hiddenShifts={hiddenShiftsCount}
            baseRate={baseRate}
            distancePreference={distancePreference}
            minPayHourlyPreference={minPayHourlyPreference}
            minPayShiftPreference={minPayShiftPreference}
            searchMode={SEARCH_MODE.DATE}
            updateOpenShifts={() => setDidFetchShifts(false)}
          />
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {loading ? (
            <ShiftNameLoader listLength={shiftNames.length} />
          ) : (
            <IonList>
              {shiftNames.map((name) => (
                <GroupItem
                  shiftName={name}
                  key={name}
                  myShiftMap={myShiftMap}
                  openShiftMap={openShiftMap}
                  onClick={setShiftName}
                />
              ))}
            </IonList>
          )}
        </IonContent>
        <IonModal isOpen={!!shiftName} onDidDismiss={closeShiftList}>
          {shiftName && (
            <ShiftListPage
              onClose={closeShiftList}
              goToDocumentsPage={goToDocumentsPage}
              day={date}
              shiftName={shiftName}
              shifts={shiftList}
              getOpenAndMyShifts={() => setDidFetchShifts(false)}
              loadingShiftList={loading}
              searchMode={SEARCH_MODE.TIME_SLOT}
              filterDistance={distancePreference}
              filterMinPayHourly={minPayHourlyPreference}
              filterMinPayShift={minPayShiftPreference}
              filterDays={1}
              baseRate={baseRate}
            />
          )}
        </IonModal>
      </IonPage>
    </Fragment>
  );
};

export { OpenShiftDayView };
