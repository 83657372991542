import { IonItem } from "@ionic/react";
import { isEmpty, get } from "lodash";
import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { StarDescription, RatingReasons } from "./constants";
import { StarRating } from "../rating/starRating";
import { RatingCategory } from "../rating/ratingCategory";
import { Review } from "../rating/review";
import { useSelector } from "react-redux";
import { Store } from "redux";

const FacilityRating = ({ setReviewData, setDisabled }) => {
  const [rating, setRating] = useState<number>(0);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string | null | undefined>("");
  const [review, setReview] = useState<string | null | undefined>("");
  const { shiftId } = useParams() as any;

  const showNewUI = useSelector((state: Store) =>
    get(state, "session.agent.enabledFeatures.timecardFeature", false)
  );

  useEffect(() => {
    const reviewData = { rating, reasons, otherReason, review };
    setReviewData(reviewData);
    const getDisableStatus = () => {
      if (!rating || isEmpty(reasons)) return true;

      if (reasons.includes("Other") && !otherReason) return true;
    };
    const isDisabled = getDisableStatus();
    setDisabled(isDisabled);
  }, [rating, reasons, otherReason, review, setDisabled, setReviewData]);

  return (
    <Fragment>
      <IonItem lines="none">
        <StarRating
          facilityName=""
          rating={rating}
          onChange={setRating}
          starDescription={StarDescription}
        />
      </IonItem>
      {rating > 0 && (
        <Fragment>
          <IonItem lines="none">
            <RatingCategory
              rating={rating}
              reasons={reasons}
              setReasons={setReasons}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
              ratingReasonsToRender={RatingReasons}
            />
          </IonItem>
          <IonItem lines="none">
            <Review review={review} setReview={setReview} rating={rating} />
          </IonItem>
        </Fragment>
      )}
    </Fragment>
  );
};

export { FacilityRating };
