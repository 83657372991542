const SHIFT_ACTION_DEFAULT_YAML_VALUE = `shiftActions:
    -
        shiftId: 5b4caabf4e6a1300149cc802
        action: CLOCK_IN
        actionTime: 2021-04-19T15:32:59.899Z
    -
        shiftId: 5b4caabf4e6a1300149cc802
        action: CLOCK_OUT
        actionTime: 2021-04-29T15:32:59.899Z`;

export { SHIFT_ACTION_DEFAULT_YAML_VALUE };
