import "./style.scss";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { OTPVerification } from "./otpVerification";
import { ConfirmDeletion } from "./confirmDeletion";
import { useSelector } from "react-redux";
import { Store } from "../../store/store.model";
import { AccountDeletionSteps } from "./constants";

const DeleteData: React.FC = () => {
  const [step, setStep] = useState(AccountDeletionSteps.INITIAL);
  const history = useHistory();

  const agent = useSelector((state: Store) => state?.session?.agent || {});

  const isRequested = agent?.requestedAccountDeletion?.requested;
  useEffect(() => {
    if (isRequested) {
      history.replace("/home/account/profile");
      return;
    }
    setStep(AccountDeletionSteps.OTP_VERIFICATION);
  }, [isRequested]);

  const onNext = () => {
    if (step === AccountDeletionSteps.CONFIRM_DELETION) {
      setStep(AccountDeletionSteps.INITIAL);
      history.replace("/home/account/profile");
    } else {
      setStep(AccountDeletionSteps.CONFIRM_DELETION);
    }
  };

  const pageTitle = "Delete Account";
  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle className="ion-text-center">{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        {step === AccountDeletionSteps.OTP_VERIFICATION && (
          <OTPVerification stepFinished={onNext} showInputBorder={true} />
        )}
        {step === AccountDeletionSteps.CONFIRM_DELETION && (
          <ConfirmDeletion stepFinished={onNext} />
        )}
      </IonContent>
    </IonPage>
  );
};

export { DeleteData };
