import { Shift, ShiftStages, Specialities } from "src/lib/interface";
import { DateRange } from "src/lib/utils";

interface OngoingShiftStore {
  isLoading: boolean;
  shiftList: Shift[];
  shiftInfoList: Shift[];
  actionList: {
    RECORD_SHIFT_TIME: ParamsList[];
    RECORD_SHIFT_TIME_FAILURE: ParamsList[];
    RECORD_DISPUTED_TIME: ParamsList[];
    UPDATE_SHIFT_UNIT: ParamsList[];
    UPDATE_WORKER_SIGNATURE: ParamsList[];
    UPLOAD_TIMECARD: ParamsList[];
    REQUEST_AUTHORIZED_SIGNATURE: ParamsList[];
    SIGN_WITH_SIGNATURE: ParamsList[];
    ADD_RATING: ParamsList[];
    SET_SHIFT_STAGE: ParamsList[];
  };
}

interface ParamsList {
  timestamp: string;
}

const OnWorkingShiftStages = [
  ShiftStages.LUNCH_IN,
  ShiftStages.LUNCH_OUT,
  ShiftStages.CLOCK_OUT,
];

const ActionType = {
  CLEAR_SHIFTS: "CLEAR_SHIFTS",
  SET_STATE_FROM_LOCAL: "SET_STATE_FROM_LOCAL",
  SET_ACTION_LIST: "SET_ACTION_LIST",
  SET_SHIFT_LIST: "SET_SHIFT_LIST",
  SET_SHIFT_INFO_LIST: "SET_SHIFT_INFO_LIST",
  UPDATE_SHIFT: "UPDATE_SHIFT",
  FILTER_RECORD_SHIFT_TIME: "FILTER_RECORD_SHIFT_TIME",
  FILTER_RECORD_SHIFT_TIME_FAILURE: "FILTER_RECORD_SHIFT_TIME_FAILURE",
  FILTER_RECORD_DISPUTED_TIME: "FILTER_RECORD_DISPUTED_TIME",
  FILTER_UPDATE_SHIFT_UNIT: "FILTER_UPDATE_SHIFT_UNIT",
  FILTER_UPDATE_WORKER_SIGNATURE: "FILTER_UPDATE_WORKER_SIGNATURE",
  FILTER_UPLOAD_TIMECARD: "FILTER_UPLOAD_TIMECARD",
  FILTER_REQUEST_AUTHORIZED_SIGNATURE: "FILTER_REQUEST_AUTHORIZED_SIGNATURE",
  FILTER_SIGN_WITH_SIGNATURE: "FILTER_SIGN_WITH_SIGNATURE",
  FILTER_ADD_RATING: "FILTER_ADD_RATING",
  FILTER_SET_SHIFT_STAGE: "FILTER_SET_SHIFT_STAGE",
  PUSH_RECORD_SHIFT_TIME: "PUSH_RECORD_SHIFT_TIME",
  PUSH_UPDATE_SHIFT_UNIT: "PUSH_UPDATE_SHIFT_UNIT",
  PUSH_UPDATE_WORKER_SIGNATURE: "PUSH_UPDATE_WORKER_SIGNATURE",
  PUSH_UPLOAD_TIMECARD: "PUSH_UPLOAD_TIMECARD",
  PUSH_REQUEST_AUTHORIZED_SIGNATURE: "PUSH_REQUEST_AUTHORIZED_SIGNATURE",
  PUSH_SIGN_WITH_SIGNATURE: "PUSH_SIGN_WITH_SIGNATURE",
  PUSH_ADD_RATING: "PUSH_ADD_RATING",
  PUSH_RECORD_DISPUTED_TIME: "PUSH_RECORD_DISPUTED_TIME",
  PUSH_SET_SHIFT_STAGE: "PUSH_SET_SHIFT_STAGE",
  PUSH_RECORD_SHIFT_TIME_FAILURE: "PUSH_RECORD_SHIFT_TIME_FAILURE",
};

interface OngoingShiftAction {
  type: string;
  data?: object;
}

interface RequestOpenShiftOptions {
  range: DateRange;
  coordinates: number[];
  distance: number;
  qualification: string;
  specialities?: Specialities;
  isPlain?: boolean;
}

interface FetchAgentShiftRequest {
  upcoming?: boolean;
  shiftDate?: string;
  ongoing?: boolean;
}

interface SlotDetailsResponse {
  [date: string]: SlotData[];
}

interface SlotData {
  claimedSlot: ClaimedSlots;
}

interface ClaimedSlots {
  timeSlot: string;
  slot: string;
  startingTime: string;
  endingTime: string;
  cost: number;
}

interface RatingRequest {
  rating: number;
  reviewFor?: string;
  reasons: string[];
  otherReason: string;
  review: string;
  shiftId: string;
}

interface NoTimeSheetAvailableReq {
  shiftId: string;
  reason: string;
}

type UpdateShift = (updatedInfo: Shift) => void;

export {
  UpdateShift,
  OngoingShiftStore,
  ActionType,
  OnWorkingShiftStages,
  RatingRequest,
  FetchAgentShiftRequest,
  SlotDetailsResponse,
  OngoingShiftAction,
  NoTimeSheetAvailableReq,
};
