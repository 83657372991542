import { connect } from "react-redux";

import { Store } from "../../store/store.model";
import { HomeRouter } from "./router";

const mapStateToProps = (state: Store) => ({
  agent: state.session.agent,
  userId: state.session.userId,
  isSignup: state.session.isSignup,
});

const HomeRoutes = connect(mapStateToProps)(HomeRouter);

export { HomeRoutes };
