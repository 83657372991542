export const getIsEarlyOrIsLateMessage = (seconds = 0): string => {
  const minutes = seconds / 60;
  if (minutes >= 1)
    return (
      Math.floor(minutes) +
      (Math.floor(minutes) == 1 ? " minute" : " minutes") +
      " late"
    );
  else if (minutes < 0)
    return (
      Math.ceil(Math.abs(minutes)) +
      (Math.ceil(Math.abs(minutes)) == 1 ? " minute" : " minutes") +
      " early"
    );
  return "";
};
