import { PlacesAutoComplete } from "src/lib/ionic-components";
import { RangeChangeEventDetail, RangeValue } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { AddressResponse, Location } from "src/lib/interface";
import { home, medkit } from "ionicons/icons";
import { get, isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getWorkerShiftTypeLabel } from "../../constants/qualificationTypes";
import { environment } from "../../environments/environment";
import { setAddressEditDetails } from "../store/accountSettings";
import { updateAgent } from "../store/session";
import { Store } from "../store/store.model";
import { updateAddress, updateDistancePreference } from "./api";
import { AddressAndDistanceProps, Place } from "./model";
import "./style.scss";
import { DISTANCE_PREFERENCE_MAX_VALUE } from "../../constants";

const { googleMapApi } = environment;
export const AddressAndDistance: React.FC<AddressAndDistanceProps> = ({
  isOpen,
  closeModal,
  updateCalender,
}) => {
  const history = useHistory();
  const agent = useSelector((state: Store) => state.session.agent);
  const [distance, setDistance] = useState<RangeValue>(
    get(agent, "preference.distance", DISTANCE_PREFERENCE_MAX_VALUE)
  );
  const { addressEditDetails } = useSelector(
    (state: Store) => state.accountSettingsStore
  );
  const dispatch = useDispatch();
  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    dispatch(
      setAddressEditDetails({
        searchLocation: place.address.formatted,
        address: place.address,
        location: place.location,
        addressLine1: place.address.line1 || "",
      })
    );
    // history.push(editPath || `/home/account/editAddress`)
  };
  const onChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const { value } = event.detail;
    setDistance(value);
  };

  const onSave = async () => {
    const { geoLocation, address } = (await updateAddress({
      address: {
        ...addressEditDetails?.address,
        line1: addressEditDetails?.addressLine1 || "",
      },
      location: addressEditDetails?.location as Location,
    })) as AddressResponse;

    const agent = await updateDistancePreference(distance as number);

    dispatch(updateAgent({ geoLocation, address }));
    dispatch(updateAgent(agent));
    history.push("/home/openShifts");
    closeModal?.();
  };
  const closeEditDetail = () => {
    if (isEmpty(addressEditDetails)) {
      closeModal?.();
    } else {
      onSave();
    }
  };

  return (
    <IonModal isOpen={isOpen as boolean}>
      <>
        <IonRouterLink onClick={closeEditDetail} className="close-link">
          {isEmpty(addressEditDetails) ? "Close" : "Done"}
        </IonRouterLink>
        <IonContent className="modal-container">
          <div className="input-area">
            <IonText color="dark" className="modal-title">
              <h1>My Address</h1>
            </IonText>
            <IonText color="dark" className="modal-subtitle">
              <p>
                {`Please enter your exact physical address so that we can best show you ${getWorkerShiftTypeLabel(
                  agent?.qualification
                )} shifts
                near you`}
              </p>
            </IonText>
            <div className="address-input-container">
              {/*<IonInput className="address-input" placeholder="Your address"/>*/}
              <IonItem>
                <PlacesAutoComplete
                  defaultValue={addressEditDetails?.searchLocation || ""}
                  onChange={onPlaceChange}
                  googleApiKey={googleMapApi}
                />
              </IonItem>
            </div>
          </div>
        </IonContent>
        {!isEmpty(addressEditDetails) && (
          <IonContent className="modal-container">
            <div className="distance-slider-container">
              <IonText color="dark" className="modal-subtitle">
                <h3>How far are you willing to travel to work</h3>
              </IonText>
              <IonRange
                min={1}
                max={DISTANCE_PREFERENCE_MAX_VALUE}
                pin
                value={distance}
                debounce={100}
                onIonChange={onChange}
              >
                <IonIcon slot="start" icon={home} />
                <IonIcon slot="end" icon={medkit} />
              </IonRange>
              <IonItem>
                <IonLabel className="ion-text-center">
                  <p>{distance} miles</p>
                </IonLabel>
              </IonItem>
            </div>
            <div className="address-input-container">
              <IonButton expand="full" onClick={onSave}>
                Done
              </IonButton>
            </div>
          </IonContent>
        )}
      </>
    </IonModal>
  );
};
