import { ActionType, NotificationSettings } from "./model";

const initialState: NotificationSettings = {
  status: false,
  isPushSet: false,
  msg: [],
  messageId: null,
  unreadCount: 0,
};

const notificationStore = (
  state: NotificationSettings = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.SET_TOAST:
      return {
        ...state,
        status: true,
        msg: [...state.msg, ...action.data],
        messageId: action.messageId,
      };
    case ActionType.REMOVE_TOAST:
      return {
        ...state,
        status: true,
        msg: [...action.data],
        messageId: null,
      };
    case ActionType.CHANGE_TOAST_BOOLEAN:
      return {
        ...state,
        status: !state.status,
        messageId: null,
      };
    case ActionType.SET_PUSH_NOTIFICATION_PREFERENCE:
      return {
        ...state,
        isPushSet: action.data,
      };
    case ActionType.SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.count,
      };
    default:
      return state;
  }
};

export { notificationStore };
