import { Address, Location } from "src/lib/interface";
import _ from "lodash";
import { getGeocode } from "use-places-autocomplete";

type AddressMapType = { id: string; values: Array<string> };
type AddressMapTypeList = Array<AddressMapType>;

const LA_CENTER = { lat: 34.0522, lng: -118.2437 };

const addressMap: AddressMapTypeList = [
  { id: "streetNumber", values: ["street_number"] },
  { id: "streetName", values: ["street_address", "route"] },
  { id: "postalCode", values: ["postal_code"] },
  {
    id: "region",
    values: [
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
    ],
  },
  { id: "city", values: ["locality"] },
  { id: "state", values: ["administrative_area_level_1"] },
  { id: "country", values: ["country"] },
];

const getLocationFromPlace = (place) => {
  const { location } = place.geometry;
  return {
    lat: location.lat(),
    lng: location.lng(),
  };
};

const getAddressType = (type): AddressMapType => {
  const addressObj: AddressMapType = _.find(addressMap, ({ values }) =>
    values.includes(type)
  );
  return addressObj;
};

const isLowLevelType = (parsedAddress, values, type) => {
  const address = parsedAddress[type];
  if (!address) return false;

  return values.index(type) > values.index(address.type);
};

const parseAddressComponents = (addressComponents: any): Address => {
  const address: any = {};
  addressComponents.forEach((component) => {
    const [type] = component.types;

    const addressType = getAddressType(type);

    if (_.isEmpty(addressType)) return;

    const isLowLevel = isLowLevelType(address, addressType.values, type);

    if (!isLowLevel) {
      address[addressType.id] = { type, value: component.long_name };
    }

    if (addressType.id === "state") {
      address.stateCode = { value: component.short_name };
    }
    if (addressType.id === "country") {
      address.countryCode = { value: component.short_name };
    }
  });

  return _.mapValues(address, ({ value }) => value);
};

const getAddressByGeoCode = async ({
  lat,
  lng,
}: Location): Promise<Address> => {
  try {
    const [place] = await getGeocode({
      location: { lat: parseFloat(lat), lng: parseFloat(lng) },
    });
    const geocodeAddress = parseAddressComponents(place.address_components);
    return { ...geocodeAddress, formatted: place.formatted_address };
  } catch {
    console.error("Invalid Address");
    return {};
  }
};

export {
  LA_CENTER,
  getLocationFromPlace,
  parseAddressComponents,
  getAddressByGeoCode,
};
