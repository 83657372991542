import { logEvent } from "src/lib/analytics";
import { IonPopover, IonTextarea } from "@ionic/react";
import _ from "lodash";
import React, { useEffect, useState, SetStateAction, Dispatch } from "react";
import { useDispatch } from "react-redux";
import { USER_EVENTS } from "../../../constants/userEvents";
import { actionAddRatingV2 } from "../../store/ongoingShifts";
import {
  FacilityRatingReasons,
  ShiftRatingPrompt,
  StarDescriptionApp,
} from "../constants";
import { RatingPromptProps } from "../model";
import { AppStarRating } from "../starRating";
import { BottomButtons } from "./bottomButtons";
import { DetailReview } from "./detailReview";
import { InitialText } from "./initialText";
import {
  enterAnimation,
  leaveAnimation,
  PopoverContentAnimation,
} from "./popoverAnimation";
import "./style.scss";

const RatingPrompt: React.FC<RatingPromptProps> = ({
  onDidDismiss,
  shift,
  setIsRatingSuccess,
}) => {
  const [rating, setRating] = useState<number>(0);
  const [dismissed, setDismiss] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const { prompt } = ShiftRatingPrompt;
  const dispatch = useDispatch();
  const [playedLowRateAnimation, setPlayedLowRateAnimation] =
    useState<boolean>(false);
  const [playRevAnimation, setPlayRevAnimation] = useState<boolean>(false);
  const [shouldOtherAnimation, setShouldOtherAnimation] =
    useState<boolean>(false);

  const el = document.querySelector(".review-prompt .popover-content");
  const { ratingAnimation, otherRatingAnimation, reverseOtherAnimation } =
    PopoverContentAnimation(el!);

  const handleStarClick = (starValue: number) => {
    setRating(starValue);
  };

  const handleReasonChange = (category: string) => {
    if (category === "Other Reason" && reasons.includes("Other Reason")) {
      setPlayRevAnimation(true);
    } else if (category === "Other Reason") {
      setShouldOtherAnimation(true);
    }
    setReasons((prevState) => _.xor(prevState, [category]));
  };

  const handleOtherReasonChange = (event: any) => {
    setOtherReason(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    await dispatch<any>(
      actionAddRatingV2({
        rating,
        reasons,
        otherReason: reasons.includes("Other Reason"),
        review: otherReason,
        shiftId: shift?._id,
      })
    );
    setDismiss(true);
    logEvent(USER_EVENTS.SUBMITTED_FACILITY_REVIEW);
    setIsRatingSuccess?.(true);
  };

  useEffect(() => {
    if (playRevAnimation) {
      reverseOtherAnimation.onFinish(() => setPlayRevAnimation(false)).play();
    } else if (
      reasons.includes("Other Reason") &&
      !playRevAnimation &&
      shouldOtherAnimation
    ) {
      otherRatingAnimation
        .onFinish(() => setShouldOtherAnimation(false))
        .play();
    } else if (!playedLowRateAnimation && rating > 0 && reasons.length === 0) {
      ratingAnimation.onFinish(() => setPlayedLowRateAnimation(true)).play();
    }
  }, [
    reasons,
    otherRatingAnimation,
    playedLowRateAnimation,
    ratingAnimation,
    reverseOtherAnimation,
    rating,
    playRevAnimation,
    shouldOtherAnimation,
  ]);

  const hasRated5 = (rating === 5).toString();

  return (
    <>
      <IonPopover
        isOpen={!dismissed}
        showBackdrop={true}
        backdropDismiss={false}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        cssClass="review-prompt"
        onDidDismiss={onDidDismiss}
      >
        <InitialText
          animate={rating !== 0}
          facilityName={shift?.facility?.name}
        />
        <AppStarRating
          facilityName={shift?.facility?.name as string}
          rating={rating}
          onChange={handleStarClick as Dispatch<SetStateAction<number>>}
          starDescription={StarDescriptionApp}
          animate={rating !== 0}
        />
        {rating > 0 && (
          <>
            <DetailReview
              feedbackText={prompt[hasRated5]}
              reasons={reasons}
              appFeedback={FacilityRatingReasons[hasRated5]}
              showReasons={rating <= 5}
              onReasonChange={handleReasonChange}
            />
            <BottomButtons
              setDismiss={setDismiss}
              handleSubmit={handleSubmit}
              disabled={reasons.length === 0}
            />
          </>
        )}
        {reasons.includes("Other Reason") && (
          <IonTextarea
            className="other-text"
            placeholder="Other Reason"
            value={otherReason}
            onIonChange={handleOtherReasonChange}
            autofocus
          />
        )}
      </IonPopover>
    </>
  );
};

export { RatingPrompt };
