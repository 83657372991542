import { TimeRange } from "src/lib/interface";
import { padStart } from "lodash";
import moment from "moment-timezone";

const getHoursFromRange = ({ start, end }: TimeRange): string => {
  const totalMinutes = moment(end).diff(start, "minutes");

  const hour = Math.floor(totalMinutes / 60).toString();
  const minute = (totalMinutes % 60).toString();

  return `${padStart(hour, 2, "0")}:${padStart(minute, 2, "0")} hrs`;
};

const getShiftInOutRange = (
  { start, end }: TimeRange,
  includeDifference = true
): string => {
  let range = "";
  if (start) {
    range += moment(start).format("hh:mm a");
  }
  if (end) {
    range += " - ";
    range += moment(end).format("hh:mm a");
  }

  let hours = "";

  if (includeDifference) {
    hours = ` (${getHoursFromRange({ start, end })})`;
  }

  return `${range}${hours}`;
};

export { getShiftInOutRange };
