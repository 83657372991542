import { ActionType, LocalStorage } from "@store/session";
import { Facility } from "src/lib/interface";
import { Dispatch } from "redux";

const showFacilityCovidTestingPopups = async (
  facility: Facility,
  dispatch: Dispatch
) => {
  const seenCovidTestings: Array<string> = JSON.parse(
    localStorage.getItem(LocalStorage.SEEN_FACILITY_COVID_TESTINGS) || "[]"
  );
  const seenCovidTestingForFacility = seenCovidTestings.some(
    (seenFacilityId) => seenFacilityId === facility?.userId
  );
  if (
    !seenCovidTestingForFacility &&
    facility?.covidData?.testingAvailabilityOnsite
  ) {
    const { name: facilityName = "", userId: facilityId = "" } = facility || {};
    dispatch({
      type: ActionType.SHOW_COVID_TESTING_POPUP,
      data: { facilityName, facilityId },
    });
  }
};

export { showFacilityCovidTestingPopups };
