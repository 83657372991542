import { Agent, PaymentServiceAccountStatus } from "src/lib/interface";
import { Store } from "../store/store.model";
import { useSelector } from "react-redux";
import { ShouldBeOnboardedStatuses, StripeEnabledStatuses } from "./model";

export function useShouldBeOnboarded() {
  const agent: Partial<Agent | undefined> = useSelector(
    (state: Store) => state?.session?.agent
  );

  const isAgentPendingInPaymentService =
    agent?.canBeOnboardedInStripe &&
    ShouldBeOnboardedStatuses.includes(
      agent?.paymentAccountInfo?.status as PaymentServiceAccountStatus
    );

  if (!isAgentPendingInPaymentService) return false;

  return true;
}
