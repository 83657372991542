/* eslint-disable max-len */
/* eslint-disable no-template-curly-in-string */
const MessageString = {
  shiftBookingInfo:
    "Tap on a shift to start the booking process, we will reach out to that facility to confirm you're booking and let you know via text message.",
  extraTimePayAlert: {
    thresholdMessage:
      "<strong>${multiplier}x</strong> the pay rate for every hour over <strong>${threshold}</strong> hours",
    fortyMessage:
      "<p>This facility offers <strong>${multiplier}x</strong> the pay rate for every hour worked <strong>over 40 hours</strong> in the calendar week, if not already earned working ${dynamicText}</p>",
    onlyFortyMessage:
      "<p><strong>${multiplier}x</strong> the pay rate for every hour worked <strong>over 40 hours</strong>",
    threshold2Message:
      "<p><strong>${multiplier2}x</strong> the pay rate for every hour over <strong>${threshold2}</strong> hours</p>",
    alertHeader:
      "When working ${dynamicText} at this facility${headerSuffix}, you'll earn:",
  },
  extraTimePayCard: {
    fortyHeader: "40+ hours in calendar week",
    singleHeader: "Single shift",
    backToBackHeader: "Back-to-back shifts",
    singleAndBackToBackHeader: "Single shift and back-to-back shifts",
    fortyNotOfferedText:
      "This facility does not offer Extra Time Pay for working more than 40 hours in a calendar week from Sunday to Saturday",
    backToBackNotOfferedText:
      "This facility does not offer Extra Time Pay when working back-to-back shifts for any duration of time",
    singleNotOfferedText:
      "This facility does not offer Extra Time Pay when working a single shift for any duration of time",
    singleOrBackToBackNotOfferedText:
      "This facility does not offer Extra Time Pay when working a single shift or back-to-back shifts for any duration of time",
    fortyOfferedText:
      "${multiplier}x the pay rate for every hour worked over 40 hours in a calendar week from Sunday to Saturday",
  },
  importantInformationFromHCFDisclaimerText:
    "Important information is not verified by Clipboard Health for accuracy, and may be incorrect or misleading.",
};

export { MessageString };
