/* eslint-disable sort-imports */
import { ConnectionStatus } from "@capacitor/network";
import { logEvent } from "src/lib/analytics";
import { Events, MessageString } from "src/lib/constants";
import {
  IonAlert,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { updateAgent } from "../store/session";
import { get, isEmpty } from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { USER_EVENTS } from "../../constants";
import { AddressAndDistance } from "../addressAndDistance";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { Store } from "../store/store.model";
import {
  removeRecommendedDistance,
  sendCalendarOpenActivity,
  updateDistancePreference,
} from "./api";
import { OpenShiftCalendar } from "./openShiftCalendar";
import { OpenShiftMap } from "./openShiftMap";
import UrgentShifts from "./urgentShifts";

import "./style.scss";

import {
  DISMISSED_STRIPE_POPUP,
  IS_SIGNUP,
} from "../onboardingStripe/constants";
import { App } from "@capacitor/app";
import { HeaderMessagesIcon } from "../hcpShifts/headerMessagesIcon";

export type FacilityShiftsProcessType = {
  isLoading: boolean;
  errMessage: string;
};

const OpenShifts = () => {
  const [segmentView, setSegmentView] = useState("");
  const [showModal, setShowModal] = useState(false);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_OPEN_SHIFTS);
    const savedFindShiftsSegment = localStorage.getItem(
      "savedFindShiftsSegment"
    );
    if (savedFindShiftsSegment === "map" && segmentView !== "map") {
      logEvent(USER_EVENTS.VIEW_MAP);
      setSegmentView("map");
    } else {
      logEvent(USER_EVENTS.VIEW_CALENDAR);
      setSegmentView("calendar");
    }

    if (agent && isEmpty(agent.geoLocation)) {
      openLocationAlert();
    }
  });

  const onSegmentChanged = (e) => {
    const selectedSegment = e.detail.value;
    if (selectedSegment === segmentView) return;
    if (selectedSegment === "map") {
      logEvent(USER_EVENTS.VIEW_MAP);
      localStorage.setItem("savedFindShiftsSegment", selectedSegment);
    }
    if (selectedSegment === "calendar") {
      // Saved last-visited even user logout and login again
      localStorage.removeItem("savedFindShiftsSegment");
      logEvent(USER_EVENTS.VIEW_CALENDAR);
    }

    setSegmentView(e.detail.value);
  };

  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [showLastMinuteShiftsCard, setShowLastMinuteShiftsCard] =
    useState<boolean>(false);
  const userId = useSelector((state: Store) =>
    get(state, "session.userId", "")
  );
  const [locationAlertOpen, setLocationAlertOpen] = useState<boolean>(false);
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const shouldBeOnboarded = useShouldBeOnboarded();
  const { networkStatus } = useSelector((state: Store) => state.session);
  const isSignup = useSelector((state: Store) => state?.session?.isSignup);
  const ldClient = useLDClient();

  // Prevents urgentShiftsEnabled to be updated when optnShifts is not visible
  const urgentShiftsEnabled = useMemo(() => {
    return ldClient?.variation("urgent-shifts", false);
  }, []);

  useEffect(() => {
    if (!agent?.preference?.recommendedDistance) {
      return;
    }

    if (
      (agent?.preference?.recommendedDistance || 0) <=
      (agent.preference.distance || 0)
    ) {
      const remove = async () => await removeRecommendedDistance();
      remove();
      return;
    }

    setShowModal(true);
  }, [agent?.preference?.recommendedDistance]);

  // For business logic refer https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2457534599/Stripe+onboarding+popup
  useEffect(() => {
    const dismissedStripePopup = localStorage.getItem(DISMISSED_STRIPE_POPUP);
    if (shouldBeOnboarded && !isSignup && !dismissedStripePopup) {
      localStorage.setItem(IS_SIGNUP, "false");
      history.replace(TabRouterPath.PAYMENT_SERVICE_ONBOARDING);
    }
  }, []);

  useEffect(() => {
    logNoAddressForHCPEvent();
  }, [locationAlertOpen]);

  useEffect(() => {
    if (
      !urgentShiftsEnabled &&
      segmentView === "urgentShifts" &&
      location.pathname === "/home/openShifts"
    ) {
      setSegmentView("calendar");
    }
  }, [urgentShiftsEnabled]);

  const logNoAddressForHCPEvent = () => {
    if (locationAlertOpen)
      logEvent(Events.NO_SHIFT_ALERT, {
        "No Address For HCP": MessageString.noAddressForHCP,
      });
  };

  useIonViewWillLeave(() => {
    setLocationAlertOpen(false);
    setSegmentView("");
  });

  useEffect(() => {
    if (userId) {
      sendCalendarOpenActivity();
    }
  }, [userId]);

  useEffect(() => {
    const listener = App.addListener("appStateChange", (state) => {
      if (state.isActive) {
        sendCalendarOpenActivity();
      }
    });

    return () => {
      listener.remove();
    };
  }, []);

  const closeLocationAlert = () => setLocationAlertOpen(false);
  const openLocationAlert = () => setLocationAlertOpen(true);

  const openLocationModal = () => {
    setLocationModal(true);
    setLocationAlertOpen(false);
  };

  const showUrgentShiftsClass =
    segmentView === "urgentShifts" && location.pathname === "/home/openShifts"
      ? "shift-segment-visible"
      : "shift-segment-hidden";

  const showMapClass = `${
    segmentView === "map" && location.pathname === "/home/openShifts"
      ? "shift-segment-visible"
      : "shift-segment-hidden"
  }`;
  const showCalendarClass = `${
    segmentView === "calendar" && location.pathname === "/home/openShifts"
      ? "shift-segment-visible"
      : "shift-segment-hidden"
  }`;

  const updateRecDistance = async (okay: boolean) => {
    if (okay) {
      const recDist = agent?.preference?.recommendedDistance;
      let updatedAgent = await updateDistancePreference(recDist);
      dispatch(updateAgent(updatedAgent));
    }
    await removeRecommendedDistance();
    setShowModal(false);
  };

  return (
    <Fragment>
      <IonPage className={showMapClass}>
        <IonHeader no-border>
          <IonToolbar mode="ios">
            <IonSegment
              mode="ios"
              onIonChange={onSegmentChanged}
              value={segmentView}
              className="segment-map-calendar"
              style={{
                width: "100%",
                backgroundColor: "#59647A",
                margin: "0em 0.5em 0em 0.5em",
              }}
            >
              {urgentShiftsEnabled && (
                <>
                  <IonSegmentButton mode="ios" value="urgentShifts">
                    Urgent Shifts
                  </IonSegmentButton>
                  <span className="ion-segment-button-separator"></span>
                </>
              )}
              <IonSegmentButton mode="ios" value="map">
                Map
              </IonSegmentButton>
              <span className="ion-segment-button-separator"></span>
              <IonSegmentButton mode="ios" value="calendar">
                Calendar
              </IonSegmentButton>
            </IonSegment>
            <HeaderMessagesIcon />
          </IonToolbar>
        </IonHeader>
        <OpenShiftMap
          useIonViewWillLeave={useIonViewWillLeave}
          segmentView={segmentView}
        />
      </IonPage>
      <IonPage className={showCalendarClass}>
        <IonHeader no-border>
          <IonToolbar mode="ios">
            <IonSegment
              mode="ios"
              onIonChange={onSegmentChanged}
              value={segmentView}
              className="segment-map-calendar"
              style={{
                width: "100%",
                backgroundColor: "#59647A",
                margin: "0em 0.5em 0em 0.5em",
              }}
            >
              {urgentShiftsEnabled && (
                <>
                  <IonSegmentButton mode="ios" value="urgentShifts">
                    Urgent Shifts
                  </IonSegmentButton>
                  <span className="ion-segment-button-separator"></span>
                </>
              )}

              <IonSegmentButton mode="ios" value="map">
                Map
              </IonSegmentButton>
              <span className="ion-segment-button-separator"></span>
              <IonSegmentButton mode="ios" value="calendar">
                Calendar
              </IonSegmentButton>
            </IonSegment>
            <HeaderMessagesIcon />
          </IonToolbar>
        </IonHeader>
        <OpenShiftCalendar
          networkStatus={networkStatus as ConnectionStatus}
          showLastMinuteShiftsCard={showLastMinuteShiftsCard}
          setShowLastMinuteShiftsCard={setShowLastMinuteShiftsCard}
          segmentView={segmentView}
        />
      </IonPage>
      {urgentShiftsEnabled && (
        <IonPage className={showUrgentShiftsClass}>
          <IonHeader no-border>
            <IonToolbar mode="ios">
              <IonSegment
                mode="ios"
                onIonChange={onSegmentChanged}
                value={segmentView}
                className="segment-map-calendar"
                style={{
                  width: "100%",
                  backgroundColor: "#59647A",
                  margin: "0em 0.5em 0em 0.5em",
                }}
              >
                <IonSegmentButton mode="ios" value="urgentShifts">
                  Urgent Shifts
                </IonSegmentButton>
                <span className="ion-segment-button-separator"></span>
                <IonSegmentButton mode="ios" value="map">
                  Map
                </IonSegmentButton>
                <span className="ion-segment-button-separator"></span>
                <IonSegmentButton mode="ios" value="calendar">
                  Calendar
                </IonSegmentButton>
              </IonSegment>
              <HeaderMessagesIcon />
            </IonToolbar>
          </IonHeader>
          <UrgentShifts segmentView={segmentView} />
        </IonPage>
      )}
      <IonAlert
        isOpen={locationAlertOpen}
        header="Where are you?"
        message={`We need your address to show you
                ${
                  agent.qualification ? agent.qualification : ""
                } shifts near you.`}
        backdropDismiss={false}
        buttons={[
          {
            text: "Later",
            handler: closeLocationAlert,
          },
          {
            text: "Set my address",
            handler: openLocationModal,
          },
        ]}
      />
      <AddressAndDistance
        isOpen={locationModal}
        closeModal={() => setLocationModal(false)}
      />

      {/* modal to suggest change of recommended distance */}

      <IonAlert
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        header="Distance Preference"
        message={`Because we’ve improved how we calculate driving distance, some 
          facilities where you’ve worked recently are now out of range, so you won’t 
          see their shifts. To see them again, we recommend increasing your Distance 
          Preference to ${agent?.preference?.recommendedDistance} miles.
          You can change this again at any time, but would you like us to do it for you now?`}
        buttons={[
          {
            text: "No thanks",
            role: "cancel",
            handler: () => {
              updateRecDistance(false);
            },
          },
          {
            text: "Yes please",
            handler: () => {
              updateRecDistance(true);
            },
          },
        ]}
      />
    </Fragment>
  );
};

export { OpenShifts };
