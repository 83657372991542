import faker from "faker/locale/en_US";
import request from "superagent";
import axios from "axios";
import {
  timezones,
  workerTypes,
  shiftTypes,
  facilityDataObject,
} from "../constants";
// import { getAuthHeader } from './../../../../utils/superagent'
import moment from "moment-timezone";
import "moment-timezone";
const TIME_ZONE = "America/Los_Angeles";

const _getMSAs = async () => {
  let msas = await axios.get(`${global.api}/msa`);
  msas = await msas.data;
  return msas;
};

const _getLocations = async () => {
  let locations = await axios.get(`${global.api}/location`);
  locations = await locations.data;
  return locations;
};
const getHCPData = async (payload = {}) => {
  const msas = await _getMSAs();
  const locations = await _getLocations();
  let name = faker.name;
  let firstName = name.firstName();
  let lastName = name.lastName();
  let phone = faker.phone.phoneNumber().replace(/\D/g, "");
  let email = `${lastName}.${firstName.substr(0, 2)}${Math.floor(
    Math.random() * 5000
  )}@yopmail.com`;

  const hcpData = {
    firstName,
    lastName,
    phone: "336" + phone.substring(0, 7),
    email: email.toLowerCase(),
    password: email.toLowerCase(),
    type: "AGENT",
    employmentStatus: "1099",
    tmz: TIME_ZONE,
    qualification:
      workerTypes[Math.floor(Math.random() * workerTypes.length)].value,
  };

  for (let key in payload) {
    hcpData[key] = payload[key];
  }
  console.log("HCP DATAAA: ", hcpData, payload);
  return hcpData;
};

const getHCFData = async (payload = {}) => {
  let facility = facilityDataObject;
  for (let key in payload) {
    facility[key] = payload[key];
  }
  let name = faker.company.companyName();
  let email =
    name.replace(/[^a-z0-9]/gi, "") +
    "_" +
    parseInt(Math.random() * 9999) +
    "@yopmail.com";

  facility.name = name;
  facility.email = email;
  facility.phone = faker.phone.phoneNumber().replace(/\D/g, "").substr(0, 10);
  return facility;
};

const getShiftData = async (data) => {
  const agentType = workerTypes[Math.floor(Math.random() * workerTypes.length)]
    ? workerTypes[Math.floor(Math.random() * workerTypes.length)].value
    : "CNA";
  let name = shiftTypes[Math.floor(Math.random() * shiftTypes.length)]
    ? shiftTypes[Math.floor(Math.random() * shiftTypes.length)]
    : "AM";
  name = name.toLowerCase();
  const { facilityId } = data;

  const now = moment.tz("America/Los_Angeles");
  const start = now.subtract(3, "h").format();
  const end = now.add(195, "m").format();
  return {
    facilityId,
    start,
    end,
    name,
    agentReq: agentType,
    surgerySpeciality: {},
  };
};

const _createHCP = async (data) => {
  console.log("HCP Data: ", data);
  let hcpResp = await request
    .post(`${global.api}/user/create`)
    // .set(await getAuthHeader())
    .send(data);
  hcpResp = await hcpResp;
  console.log("New HCP Resp: ", hcpResp);
  return hcpResp.body;
};
const _queryHCP = async (email) => {
  const HCPQueryURL = `/api/testHelpers/getHCP?email=${email}`;

  let hcpResp = await request.get(HCPQueryURL).set(await getAuthHeader());
  hcpResp = await hcpResp;

  console.log("Query HCP Resp: ", hcpResp);

  return hcpResp.body.agentObj;
};

const _updateHCPAddress = async (hcpId) => {
  let data = {
    session: hcpId,
    location: { lat: 37.77478199999999, lng: -122.426094 },
    address: {
      streetNumber: "400",
      streetName: "Oak Street",
      city: "San Francisco",
      region: "San Francisco County",
      state: "California",
      stateCode: "CA",
      country: "United States",
      countryCode: "US",
      postalCode: "94102",
      formatted: "400 Oak St, San Francisco, CA 94102, USA",
    },
  };
  let hcpResp = await request
    .post(`${global.api}/agentprofile/address`)
    // .set(await getAuthHeader())
    .send(data);
  // hcpResp = await hcpResp;
  // let hcpRespL = await request.post(`${global.api}/agentprofile/${hcpId}/address`)
  // .set(await getAuthHeader())
  // .send({"agentId":agentId,"locations":["5bdca77365d4f30004b9c8a6"]})
  // hcpRespL = await hcpResp;

  return hcpResp;
};

const _updateHCPStatus = async (agentId, stage = "ENROLLED") => {
  let hcpUpdate = await request
    .put(`${global.api}/agentProfile/stage`)
    // .set(await getAuthHeader())
    .send({ agentId, stage });
  hcpUpdate = await hcpUpdate;
};

const _updateHCPType = async (agentId, qualification = "CNA") => {
  let hcpUpdate = await request
    .put(`${global.api}/agentProfile/put`)
    // .set(await getAuthHeader())
    .send({ agentId, qualification });
  hcpUpdate = await hcpUpdate;
};

/**
 *  HCF Handlers
 */

const _createHCF = async (data) => {
  let hcfResp = await request
    .post(`${global.api}/facilityprofile/create`)
    // .set(await getAuthHeader())
    .send(data);
  hcfResp = await hcfResp;

  const facilityQueryURL = `/api/testHelpers/getFacility?email=${data.email}`;

  hcfResp = await request.get(facilityQueryURL);
  // .set(await getAuthHeader())
  hcfResp = await hcfResp;
  return hcfResp.body.facilityObj;
};

const _queryHCF = async (email) => {
  const facilityQueryURL = `/api/testHelpers/getFacility?email=${email}`;

  let hcfResp = await request.get(facilityQueryURL);
  // .set(await getAuthHeader())
  hcfResp = await hcfResp;

  return hcfResp.body.facilityObj;
};

const _updateHCFStatus = async (facilityId, status = "enrolled") => {
  let body = {
    facilityId: facilityId,
    status: status,
    reason: "",
    note: "",
  };

  let statusResp = await request
    .put(`${global.api}/testHelpers/facility/status`)
    // .set(await getAuthHeader())
    .send(body);

  statusResp = await statusResp;
  return statusResp;
};

/**
 * SHIFT HANDLERS
 */

const _createShift = async (data = {}) => {
  const shiftData = await getShiftData(data);
  shiftData.agentReq = data.agentReq ? data.agentReq : shiftData.agentReq;
  console.log("Get Shift Data: ", shiftData);
  let shiftResp = axios.post(`/api/shift/create`, {
    ...shiftData,
  });

  shiftResp = await shiftResp;
  return shiftResp;
};

const _assignShift = async (hcpId, shiftId) => {
  const assignData = {
    agentId: hcpId,
    shiftId: shiftId,
    missingStandardDocs: [],
    missingCoreDocs: [],
    override: true,
  };
  let shiftAssignResp = await request
    .post(`${global.api}/shift/claim`)
    // .set(await getAuthHeader())
    .send(assignData);
  shiftAssignResp = await shiftAssignResp;
  return shiftAssignResp;
};

const _recordShiftTime = async (shiftId, actionTime, action) => {
  let data = {
    stage: action,
    location: [73.0702553, 33.6717341],
    timecard: "",
    locationType: "LIVE",
    appType: "WEB",
    connectivityMode: "ONLINE",
    actionTime,
  };

  let actionRes = await request
    .post(
      `${
        process.env.REACT_APP_API_MAIN_URL || ""
      }/api/testHelpers/shift/recordTime/${shiftId}`
    )
    // .set(await getAuthHeader())
    .send(data);
  actionRes = await actionRes;
  return actionRes;
};

export {
  getHCPData,
  getHCFData,
  getShiftData,
  _createHCP,
  _createHCF,
  _createShift,
  _assignShift,
  _updateHCFStatus,
  _updateHCPType,
  _updateHCPStatus,
  _updateHCPAddress,
  _recordShiftTime,
  _queryHCF,
  _queryHCP,
};
