import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logFailedApiRetry } from "../utils/api_retry";

import { logApiFailureEvent } from "src/lib/analytics";
const { baseUrl } = environment;

interface RecommendationsResponse {
  recommendations: [];
}

interface ShiftResponse {
  shiftInfo: any;
}

const getRecommendations = async (
  shiftId,
  agentId
): Promise<RecommendationsResponse> => {
  return await request
    .get(`${environment.baseUrl}/shift/recommendations`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/recommendations`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ shiftId, agentId })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getShift = async (shiftId): Promise<ShiftResponse> => {
  return await request
    .get(`${environment.baseUrl}/shift/get/${shiftId}`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/get/${shiftId}`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { getRecommendations, getShift };
