import "../styles.scss";

import { Agent } from "src/lib/interface";
import { allAgreements } from "../Agrements";

import { get } from "lodash";
import { logEvent } from "src/lib/analytics";
import { Store } from "../../store/store.model";
import { USER_EVENTS } from "../../../constants";
import { useSelector } from "react-redux";

import { addOutline, removeOutline } from "ionicons/icons";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToast,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { TabRouterPath } from "../../routing/constant/tabRoute";

interface AgreementsProps {
  handleAgreementAcceptance?: () => void;
  loading: boolean;
  toastError: { isOpen: boolean; message: string };
  setToastError?: (error: { isOpen: boolean; message: string }) => void;
  disableBackButton?: boolean;
}

const Agreements: React.FC<AgreementsProps> = ({
  handleAgreementAcceptance,
  loading,
  toastError,
  setToastError,
  disableBackButton,
}) => {
  const [allAgreement, setAllAgreements] = useState(allAgreements);
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const handleAgreement = (index) => {
    const newArr = [...allAgreement];
    newArr[index].showAgreement = !newArr[index].showAgreement;
    setAllAgreements(newArr);
    logEvent(USER_EVENTS.VIEWED_CONTRACT_COMPONENT, {
      hcpID: agent?.userId as string,
      componentName: newArr[index].title,
    });
  };

  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_TERMS, {
      hcpId: agent?.userId as string,
      name: agent?.name,
    });
  }, [agent]);

  const textContent = useMemo(() => {
    return `The agreement between you and Clipboard is important.
      We have made it easy to navigate but we encourage you to consult
      with legal counsel if any portion is confusing. After signing,
      this agreement will be accessible from the app.
      Please review and let us know if you have any questions!`;
  }, []);

  return (
    <IonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            {!disableBackButton && (
              <IonBackButton
                text=""
                defaultHref={
                  get(agent, "onboardingFlags.isSignupCompleted")
                    ? TabRouterPath.OPEN_SHIFTS
                    : "/home/agentAddress"
                }
                mode="ios"
                color="dark"
              />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container content-layout">
          <div className="agreement-header">
            <div className="heading">
              <h4>Your Clipboard Health Agreement</h4>
            </div>
            <p>{textContent}</p>
          </div>
          <IonList className="list-container">
            {allAgreement.map((agreement, index) => (
              <div className="agreement" key={index}>
                <div>
                  <IonItem lines="none" onClick={() => handleAgreement(index)}>
                    <IonLabel color="medium" style={{ fontSize: "14px" }}>
                      {" "}
                      {agreement.title}
                    </IonLabel>
                    <IonButtons>
                      <IonButton>
                        <IonIcon
                          slot="icon-only"
                          color="medium"
                          icon={
                            agreement.showAgreement ? removeOutline : addOutline
                          }
                        />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                </div>
                {agreement.showAgreement ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: agreement.details(agent),
                    }}
                    className="agreement-details"
                  ></div>
                ) : null}
              </div>
            ))}
          </IonList>
          <IonFooter>
            <IonButton
              expand="block"
              size="large"
              className="ion-text-wrap ion-margin-top ion-margin-bottom continue-button"
              onClick={() => {
                logEvent(USER_EVENTS.SIGNED_CONTRACT, {
                  hcpID: agent?.userId as string,
                });
                handleAgreementAcceptance?.();
              }}
              disabled={loading}
            >
              {loading ? (
                <IonSpinner color="light" name="crescent"></IonSpinner>
              ) : (
                "I have read and agree to this Independent Contractor Agreement"
              )}
            </IonButton>
          </IonFooter>
        </div>
      </IonContent>
      <IonToast
        color="danger"
        isOpen={toastError.isOpen}
        message={toastError.message}
        onDidDismiss={() => setToastError?.({ isOpen: false, message: "" })}
        position="top"
        duration={2000}
      />
    </IonPage>
  );
};
export { Agreements };
