import { IonItem } from "@ionic/react";
import React, { Fragment } from "react";

import { InfoTitleItem } from "../infoTitleItem";
import { SignatureContainer } from "../signatureContainer";
import { ManualSignatureProps } from "./model";

const ManualSignature: React.FC<ManualSignatureProps> = ({
  signatoryInfo,
  onChange,
}) => (
  <Fragment>
    <InfoTitleItem>
      {signatoryInfo.name}, {signatoryInfo.role} - Please sign below using your
      finger
    </InfoTitleItem>
    <IonItem lines="none">
      <SignatureContainer onChange={onChange} />
    </IonItem>
  </Fragment>
);

export { ManualSignature };
