import React from "react";
import * as braze from "@braze/web-sdk";
import moment from "moment";
import momentTmz from "moment-timezone";

export interface HcpBonus {
  id: string;
  steps: number;
  progress: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  title: string;
  description: string;
  amount: number;
}

export const transformCarToBonus = (
  card: braze.Card,
  timezone = "America/Los_Angeles"
): HcpBonus => {
  return {
    id: card.extras?.bonusId,
    steps: parseInt(card.extras?.steps, 10),
    progress: 0,
    startDate: momentTmz.tz(card.extras?.startDate, timezone),
    endDate: momentTmz.tz(card.extras?.endDate, timezone),
    title: (card as any).title,
    description: (card as any).description,
    amount: card.extras?.amount,
  };
};

export const getBonusesFromCards = (cards: braze.Card[], timezone?: string) => {
  const bonuses = new Map<string, HcpBonus>();
  for (const card of cards) {
    if (card.extras?.type === "bonusAnnouncement") {
      const bonus = transformCarToBonus(card, timezone);
      bonuses.set(card.extras.bonusId, bonus);
    }
  }

  for (const card of cards) {
    if (card.extras?.type === "bonusProgress") {
      const bonus = bonuses.get(card.extras.bonusId);
      if (bonus) {
        bonus.progress = Math.max(
          bonus?.progress,
          parseInt(card.extras.progress, 10)
        );
      }
    }
  }
  return Array.from(bonuses.values());
};

export default function useFetchHcpBonuses(tmz?: string) {
  const [cards, setCards] = React.useState<braze.Card[]>([]);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    braze.subscribeToContentCardsUpdates((updates) => {
      const cards = updates.cards;
      setCards(cards);
    });
    braze.requestContentCardsRefresh(
      () => {
        setIsLoading(false);
      },
      () => {
        setIsError(true);
        setIsLoading(false);
      }
    );
  }, []);

  const bonuses = React.useMemo(
    () => getBonusesFromCards(cards, tmz),
    [cards, tmz]
  );

  return {
    bonuses,
    isError,
    /**
     * loading state for the content card refresh request. The cards may not be
     * available yet at the moment this flag becomes false.
     */
    isLoading,
  };
}

export function getBonusesForDate(bonuses: HcpBonus[], date: moment.Moment) {
  const applicableBonuses = [] as HcpBonus[];

  for (const bonus of bonuses) {
    if (date.isBetween(bonus.startDate, bonus.endDate, "day", "[]")) {
      applicableBonuses.push(bonus);
    }
  }
  return applicableBonuses;
}
