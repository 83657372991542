import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IonContent,
  IonHeader,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonToast,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Store } from "../store/store.model";
import { getAttendanceScoreProfile } from "./api";
import { MyAttendanceScoreContainer } from "./myAttendanceScoreContainer";
import { FeatureFlag } from "../../constants/FEATURE_FLAGS";
import { defaultErrorProps, defaultAttendanceScoreProfile } from "./constants";
import "./style.scss";
import { AttendanceScorePolicyDetailCard } from "./attendanceScorePolicyDetailCard";
import { LearnMoreCard } from "./learnMoreCard";
import { RefresherEventDetail } from "@ionic/core";

const AttendanceScoreRatingPage = () => {
  const agent = useSelector((state: Store) => state.session.agent);

  const [attendanceScoreProfile, setAttendanceScoreProfile] = useState(
    defaultAttendanceScoreProfile
  );
  const [errorProps, setErrorProps] = useState(defaultErrorProps);
  const [loading, setIsLoading] = useState(false);

  const ldFlags = useFlags();
  const attendanceScorePolicyLinks =
    ldFlags[FeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS];

  const fetchAttendanceScoreProfile = async (
    refreshEvent?: CustomEvent<RefresherEventDetail>
  ) => {
    if (!agent?.userId) {
      return;
    }
    setIsLoading(!refreshEvent);
    try {
      const attendanceScoreProfile = await getAttendanceScoreProfile(
        agent.userId
      );
      setAttendanceScoreProfile(attendanceScoreProfile);
    } catch (error) {
      setErrorProps({
        isOpen: true,
        message:
          "Oops, we can't get your attendance score. Please refresh the page, and if the issue persists contact Clipboard Health",
        duration: 4500,
      });
    } finally {
      setIsLoading(false);
    }
    refreshEvent?.detail.complete();
  };

  useEffect(() => {
    fetchAttendanceScoreProfile();
  }, [agent?.userId]);

  return (
    <>
      <IonHeader no-border>
        <IonToolbar>
          <IonTitle
            class="ion-no-padding ion-text-center"
            data-testid="ratings-title"
          >
            My Ratings
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={fetchAttendanceScoreProfile}>
          <IonRefresherContent />
        </IonRefresher>
        {!agent || loading ? (
          <IonContent className="ion-text-center ion-padding-top">
            <IonSpinner
              name="crescent"
              color="dark"
              data-testid="ratings-spinner"
            />
          </IonContent>
        ) : (
          <IonContent>
            <MyAttendanceScoreContainer
              score={attendanceScoreProfile.score}
              accountStatus={attendanceScoreProfile.accountStatus}
              policyDetailUrl={
                attendanceScorePolicyLinks?.howAttendanceWorks?.url ?? ""
              }
              setErrorProps={setErrorProps}
              tmz={agent.tmz}
            />
            <AttendanceScorePolicyDetailCard
              policy={attendanceScoreProfile?.policy}
            />
            <LearnMoreCard
              links={[
                {
                  label:
                    attendanceScorePolicyLinks?.howAttendanceWorks?.label ??
                    "How the Attendance Score Works",
                  url: attendanceScorePolicyLinks?.howAttendanceWorks?.url,
                },
                {
                  label:
                    attendanceScorePolicyLinks?.suspensionDeactivationPolicy
                      ?.label ?? `Suspension & Deactivation Policy`,
                  url: attendanceScorePolicyLinks?.suspensionDeactivationPolicy
                    ?.url,
                },
                {
                  label:
                    attendanceScorePolicyLinks?.doNotReturnPolicy?.label ??
                    "Do Not Return (DNR) Policy",
                  url: attendanceScorePolicyLinks?.doNotReturnPolicy?.url,
                },
              ]}
              setErrorProps={setErrorProps}
            />
          </IonContent>
        )}
      </IonContent>
      <IonToast
        isOpen={errorProps.isOpen}
        onDidDismiss={() => setErrorProps({ ...defaultErrorProps })}
        message={errorProps.message}
        duration={errorProps.duration}
        color="danger"
        position="top"
        data-testid="ratings-error-message"
      />
    </>
  );
};

export default AttendanceScoreRatingPage;
