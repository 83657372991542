import { Pie } from "./progressCircle";
import React from "react";
import { HcpBonus } from "./useFetchHcpBonuses";
import { IonItem, IonText } from "@ionic/react";

export function HcpBonusRow(props: { bonus: HcpBonus }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <IonItem>
      <div className="hcp-promotion-container">
        <div
          data-testid="hcp-bonus-row-header"
          className="hcp-promotion-header"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <h3>
            {props.bonus.title}
            <IonText color="success"> ${props.bonus.amount}</IonText>
          </h3>
          <Pie steps={props.bonus.steps} progress={props.bonus.progress} />
        </div>
        <p
          className={isOpen ? "open" : "close"}
          data-testid="hcp-bonus-row-body"
        >
          {props.bonus.description}
        </p>
      </div>
    </IonItem>
  );
}
