import { logErrors } from "@app/errorBoundary/api";
import { HcpDocument, HcpRequirementStatus } from "@store/documents/model";
import { CHECKR_REQUIREMENT_ID } from "@constants/userEvent";
import { CheckrApplicationStatus } from "@app/documents/model";
import moment from "moment";

const updateStatusForCheckrRequirement = (
  status: HcpRequirementStatus,
  checkrApplicationStatus: CheckrApplicationStatus | undefined,
  rejectedDocumentList: HcpDocument[]
) => {
  if (!status) {
    return status;
  }
  try {
    const updatedStatus = { ...status };

    //missing state handling condition
    if (
      status?.missing?.includes(CHECKR_REQUIREMENT_ID) ||
      checkrApplicationStatus?.invitationStatus === "pending"
    ) {
      updatedStatus.missing.push(CHECKR_REQUIREMENT_ID);
    }

    //rejected state handling condition
    if (
      !rejectedDocumentList.length &&
      updatedStatus?.rejected?.includes(CHECKR_REQUIREMENT_ID)
    ) {
      updatedStatus.missing.push(CHECKR_REQUIREMENT_ID);
      updatedStatus.rejected = updatedStatus?.rejected?.filter(
        (reqId) => reqId !== CHECKR_REQUIREMENT_ID
      );
    }

    //pending state handling condition
    if (
      checkrApplicationStatus?.invitationStatus === "completed" &&
      (checkrApplicationStatus?.reportStatus === "pending" ||
        ((checkrApplicationStatus?.reportStatus === "clear" ||
          checkrApplicationStatus?.reportStatus === "consider") &&
          !(
            updatedStatus?.completed?.includes(CHECKR_REQUIREMENT_ID) ||
            updatedStatus?.expired?.includes(CHECKR_REQUIREMENT_ID) ||
            updatedStatus?.rejected?.includes(CHECKR_REQUIREMENT_ID)
          )))
    ) {
      updatedStatus.missing = updatedStatus.missing.filter(
        (reqId) => reqId !== CHECKR_REQUIREMENT_ID
      );
      updatedStatus.pending = [CHECKR_REQUIREMENT_ID, ...updatedStatus.pending];
    }

    //expired state handling condition
    if (
      checkrApplicationStatus?.invitationStatus === "pending" ||
      updatedStatus?.pending?.includes(CHECKR_REQUIREMENT_ID)
    ) {
      updatedStatus.expired = updatedStatus?.expired?.filter(
        (reqId) => reqId !== CHECKR_REQUIREMENT_ID
      );
    }

    //expired state handling condition
    if (
      checkrApplicationStatus?.invitationStatus === "pending" ||
      updatedStatus?.pending?.includes(CHECKR_REQUIREMENT_ID)
    ) {
      updatedStatus.expired = updatedStatus?.expired?.filter(
        (reqId) => reqId !== CHECKR_REQUIREMENT_ID
      );
    }

    /**
     * special case where we override expired state by rejected state
     * if the rejected document has workerUploaded as false and createdAt
     * greater than requirement expiry.
     * For reference:- https://clipboardhealth.atlassian.net/browse/CH-9754
     */
    if (
      updatedStatus?.expired?.includes(CHECKR_REQUIREMENT_ID) &&
      rejectedDocumentList.length &&
      moment(updatedStatus.requirements[CHECKR_REQUIREMENT_ID].expiry).isBefore(
        rejectedDocumentList[0].createdAt
      )
    ) {
      updatedStatus.rejected.push(CHECKR_REQUIREMENT_ID);
      updatedStatus.expired = updatedStatus?.expired?.filter(
        (reqId) => reqId !== CHECKR_REQUIREMENT_ID
      );
    }

    return updatedStatus;
  } catch (error: any) {
    logErrors({
      message: `Error while updating checkr requirement status. ${JSON.stringify(
        error?.stack || error
      )}`,
      app: "hcpMobile",
    });
    return status;
  }
};

export default updateStatusForCheckrRequirement;
