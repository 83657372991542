import moment from "moment-timezone";
import { openInAppBrowser } from "src/lib/ionic-components";
import { MyAttendanceScoreProps } from "./types";
import { AgentStages } from "src/lib/interface";
import "./style.scss";

const ScoreDescription = ({
  score,
  accountStatus,
  policyDetailUrl,
  setErrorProps,
  tmz,
}: MyAttendanceScoreProps) => {
  const { status, reEnrollmentDate } = accountStatus;

  const onClick = () => {
    if (policyDetailUrl) {
      openInAppBrowser(policyDetailUrl);
    } else {
      setErrorProps({
        isOpen: true,
        message: "Oops, the link you clicked can't be resolved",
        duration: 2500,
      });
    }
  };

  if (score > 0) {
    return (
      <>
        An Attendance Score of 0 or below will result in account suspension or
        deactivation.{" "}
        <label className="ratings-my-attendance-score-link" onClick={onClick}>
          Learn More
        </label>
      </>
    );
  }

  if (status === AgentStages.Suspended) {
    return (
      <>
        Your account is suspended until{" "}
        {moment(reEnrollmentDate)
          .tz(tmz || "America/Los_Angeles")
          .format("MM/DD/YYYY")}
        . Your Attendance Score will reset to 70 after the suspension.
      </>
    );
  }

  if (status === AgentStages.Deactivated) {
    return (
      <>
        Your account is deactivated until{" "}
        {moment(reEnrollmentDate)
          .tz(tmz || "America/Los_Angeles")
          .format("MM/DD/YYYY")}
      </>
    );
  }

  return <></>;
};

export const MyAttendanceScoreContainer = ({
  score,
  accountStatus,
  policyDetailUrl,
  setErrorProps,
  tmz,
}: MyAttendanceScoreProps) => {
  const getScoreStyle = () => {
    if (score >= 70) return "ratings-green";
    if (score >= 30) return "ratings-yellow";
    if (score >= 1) return "ratings-red";
    return "ratings-black";
  };

  return (
    <div
      className="ratings-my-attendance-score-container"
      data-testid="my-attendance-score-container"
    >
      <div className={`ratings-score ${getScoreStyle()}`}>{score}</div>
      <div className="ratings-my-attendance-score-title">
        My Attendance Score
      </div>
      <div className="ratings-my-attendance-score-description">
        <ScoreDescription
          score={score}
          accountStatus={accountStatus}
          policyDetailUrl={policyDetailUrl}
          setErrorProps={setErrorProps}
          tmz={tmz}
        />
      </div>
    </div>
  );
};
