import { IonAlert, IonItem, IonLabel } from "@ionic/react";
import React, { Fragment, useState } from "react";

import { ShiftSignMode, SwitchManualSignatoryProps } from "./model";

const SwitchManualSignatory: React.FC<SwitchManualSignatoryProps> = ({
  facility,
  setSignMode,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const onConfirm = () => setSignMode(ShiftSignMode.MANUAL);

  return (
    <Fragment>
      <IonItem lines="none" class="ion-padding-top ion-text-center">
        <IonLabel>
          <h4 className="ion-text-wrap">
            Is your charge nurse not listed?
            <a color="primary" className="ion-margin-start" onClick={openModal}>
              Get a manual signature
            </a>
          </h4>
        </IonLabel>
      </IonItem>
      <IonAlert
        isOpen={isOpen}
        onDidDismiss={closeModal}
        message={`Please hand your device to an authorized shift signer at ${
          facility ? facility.name : ""
        }.`}
        buttons={[
          { text: "Cancel", role: "cancel" },
          {
            text: "I am an authorized signer",
            handler: onConfirm,
          },
        ]}
      />
    </Fragment>
  );
};

export { SwitchManualSignatory };
