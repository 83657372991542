import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";

const claimBonus = async (): Promise<{}> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/claimBonus`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { claimBonus };
