import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logFailedApiRetry } from "../utils/api_retry";

import { logApiFailureEvent } from "src/lib/analytics";
import { PayrollList, PayrollSummary } from "src/lib/interface";

const fetchPayrollData = async (queryData: object): Promise<PayrollList[]> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/mobile`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/mobile`);
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchLifeTimeEarnings = async (): Promise<PayrollSummary> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/summary`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/summary`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { fetchPayrollData, fetchLifeTimeEarnings };
