import { createHttpLink } from "@apollo/client";
import { mockSingleLink } from "@apollo/client/testing";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { defaultEmail } from "../constants";

const httpLink =
  // HACK - using the actual createHttpLink() makes Jest tests fail
  process.env.NODE_ENV === "test"
    ? mockSingleLink()
    : createHttpLink({
        uri: process.env.REACT_APP_DOCUMENTS_SERVICE_URL,
      });

const authLink = setContext(async () => {
  const getToken = await axios.post(
    `${process.env.REACT_APP_API_MAIN_URL}/api/testHelpers/token?email=${defaultEmail}`
  );
  return {
    headers: {
      Authorization: getToken.data.token ?? localStorage.getItem("authToken"),
    },
  };
});

const link = authLink.concat(httpLink);

export { link };
