import { surgeryWorkerTypes, workerTypeObj } from "src/lib/constants";
import {
  IonCard,
  IonCheckbox,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { medkit } from "ionicons/icons";
import React, { Fragment } from "react";

const Specialities = ({ specialities, qualification }) => {
  if (
    !specialities ||
    !qualification ||
    !surgeryWorkerTypes.includes(qualification)
  ) {
    return <Fragment />;
  }

  const { experience, hasSedationExperience, hasTrayAssemblyExperience } =
    specialities || {};

  return (
    <IonCard>
      <IonItem>
        <IonIcon icon={medkit} />
        <IonLabel>
          <h2 style={{ marginLeft: 15 }}>Surgical Specialities</h2>
        </IonLabel>
      </IonItem>
      {qualification === workerTypeObj.PACU_RN && (
        <IonItem>
          <IonCheckbox
            mode="ios"
            disabled={true}
            checked={hasSedationExperience}
            color="dark"
          />
          <p className="ion-margin-top">
            Conscious/Moderate Sedation Experience
          </p>
        </IonItem>
      )}
      {qualification === workerTypeObj.SPD_TECH && (
        <IonItem>
          <IonCheckbox
            disabled={true}
            checked={hasTrayAssemblyExperience}
            color="dark"
          />
          <p className="ion-margin-top">Tray Assembly Experience</p>
        </IonItem>
      )}
      <div className="ion-margin">
        {experience.map((experience) => (
          <IonChip key={experience} color="dark">
            <IonLabel>{experience}</IonLabel>
          </IonChip>
        ))}
      </div>
    </IonCard>
  );
};

export { Specialities };
