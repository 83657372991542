import React, { useState, useCallback } from "react";
import {
  IonPopover,
  IonButton,
  IonContent,
  IonInput,
  IonLabel,
  IonItem,
  isPlatform,
} from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { locationService } from "../../../app/openShifts/urgentShifts/locationService";
import { Store } from "../../../app/store/store.model";
import { startTracking } from "../../../app/openShifts/urgentShifts/utils";
import { useSelector } from "react-redux";

const RadarSdkTestPanel = () => {
  const [showPopover, setShowPopover] = useState(false);
  const agent = useSelector((state: Store) => state?.session?.agent);
  const ldClient = useLDClient();
  const [tag, setTag] = React.useState("");
  const [externalId, setExternalId] = React.useState("");

  const radarSdkConfig = ldClient?.variation("radar-sdk-config");

  const startLocationTracking = useCallback(() => {
    const radarConfigsByPlatform = isPlatform("ios")
      ? radarSdkConfig?.ios
      : radarSdkConfig?.android;

    startTracking(radarConfigsByPlatform);
  }, [radarSdkConfig]);

  return (
    <div
      style={{ position: "fixed" }}
      onClick={() => Keyboard.hide()}
      data-testid="radar-sdk-testpanel"
    >
      <IonButton onClick={() => setShowPopover(true)}>Radar SDK</IonButton>

      <IonPopover
        isOpen={showPopover}
        showBackdrop
        backdropDismiss={true}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <IonContent className="ion-padding" style={{ padding: "5px" }}>
          <IonButton
            expand="block"
            size="small"
            onClick={() => startLocationTracking()}
          >
            Start tracking
          </IonButton>
          <IonButton
            size="small"
            expand="block"
            onClick={() => locationService.stopTracking()}
          >
            Stop tracking
          </IonButton>

          <IonItem lines="none" className="form-item-wrapper">
            <IonLabel position="stacked">Geofence tag</IonLabel>
            <IonInput
              className="form-input"
              placeholder="Geofence tag"
              onIonChange={(e) => setTag(e.detail.value!)}
              value={tag}
            />
          </IonItem>

          <IonItem lines="none" className="form-item-wrapper">
            <IonLabel position="stacked">Geofence external id</IonLabel>
            <IonInput
              className="form-input"
              placeholder="Geofence external id"
              onIonChange={(e) => setExternalId(e.detail.value!)}
              value={externalId}
            />
          </IonItem>

          <IonButton
            expand="block"
            size="small"
            onClick={() =>
              locationService.startTrip({
                options: {
                  externalId: (Math.random() + 1).toString(36).substring(7), // to generate random id
                  destinationGeofenceTag: tag,
                  destinationGeofenceExternalId: externalId,
                  mode: "car",
                },
              })
            }
          >
            Start trip
          </IonButton>
          <IonButton
            size="small"
            expand="block"
            onClick={() => locationService.completeTrip()}
          >
            Complete trip
          </IonButton>
        </IonContent>
      </IonPopover>
    </div>
  );
};

export default RadarSdkTestPanel;
