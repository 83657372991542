import Icon from "@ant-design/icons";
import styled from "@emotion/styled";
import React from "react";

const MapMarkerSvg = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 1024 1024"
    p-id="1912"
    width="1em"
    height="1em"
  >
    <path
      d="M650.88112 365.728q0-60.576-42.848-103.424t-103.424-42.848-103.424 42.848-42.848 103.424 42.848 103.424 103.424 42.848 103.424-42.848 42.848-103.424zM797.18512 365.728q0 62.272-18.848 102.272l-208 442.272q-9.152 18.848-27.136 29.728t-38.56 10.848-38.56-10.848-26.56-29.728l-208.576-442.272q-18.848-40-18.848-102.272 0-121.152 85.728-206.848t206.848-85.728 206.848 85.728 85.728 206.848z"
      p-id="1913"
    />
  </svg>
);

const Wrapper = styled(Icon)`
  position: absolute;
  user-select: none;
  font-size: 40px;
  color: ${"#1890ff"} !important;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: -20px;
  }
`;

export const MapMarkerIcon = () => <Wrapper component={MapMarkerSvg} />;
