/* eslint-disable prefer-destructuring */
import "./style.scss";
import { get } from "lodash";
import { Geolocation } from "@capacitor/geolocation";
import { useSelector } from "react-redux";
import { GoogleMap, Marker, OverlayView } from "@react-google-maps/api";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { Facility } from "src/lib/interface";
import { InlineLoader } from "src/lib/ionic-components";
import { logEvent } from "src/lib/analytics";

import { FacilityShiftsProcessType } from "..";
import { GoogleMapContext } from "../../context/googleMapContext";
import { MAP_EVENTS } from "./events";
import { Store } from "../../store/store.model";
import { USER_EVENTS } from "../../../constants";
import {
  createCustomAlert,
  createCustomControl,
  defaultlongLat,
  googleMapContainerStyle,
  googleMapOptions,
} from "./googleMapShifts.const";

import iconMarkerCurrentLocation from "../../../assets/icons/google-map/markerCurrentLocation.svg";
import iconMarkerEmptyShiftHCF from "../../../assets/icons/google-map/markerEmptyShiftHCF.svg";
import iconMarkerHome from "../../../assets/icons/google-map/markerHome.svg";
import iconMarkerShiftHCF from "../../../assets/icons/google-map/markerShiftHCF.svg";

interface GoogleMapShiftsComponentInterface {
  useIonViewWillLeave: Function;
  segmentView: string;
  facilityShiftsProcess: FacilityShiftsProcessType;
  facilityShifts: Facility[];
  onHCFMarkerClick: (facility: Facility) => void;
}
export const GoogleMapShifts: React.FC<GoogleMapShiftsComponentInterface> = ({
  useIonViewWillLeave,
  segmentView,
  facilityShiftsProcess,
  facilityShifts,
  onHCFMarkerClick,
}) => {
  const { isMapLoaded, mapLoadError } = useContext(GoogleMapContext);
  const [isDoneInitBounds, setIsDoneInitBounds] =
    React.useState<boolean>(false);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [boundCount, setBoundCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [isShowHomePinDesc, setIsShowHomePinDesc] =
    React.useState<boolean>(false);
  const { agent } = useSelector((state: Store) => state.session);

  const onBoundsChanged = () => {
    if (map && !facilityShiftsProcess.isLoading) {
      const visible = areThereVisibleFacilitiesInBound();
      if (!visible) {
        setBoundCount((count) => count + 1);
      } else {
        setBoundCount(0);
      }
      createCustomAlert(map, visible, boundCount);
    }
  };

  const onLoadGoogleMap = async (gMap: google.maps.Map) => {
    // Load the Google Map Custom Control
    // Read https://developers.google.com/maps/documentation/javascript/examples/control-custom
    createCustomControl(
      gMap,
      computeGoogleMapCurrentLocation,
      computeGoogleMapHome,
      computeMapBounds
    );
    const center = computeGoogleMapHome();
    if (facilityShifts?.length < 1) {
      gMap.setZoom(10);
      gMap.setCenter(center);
    }
    setMap(gMap);
  };
  const clearEventListeners = () => {
    // cloning element will remove eventListeners
    const controlUi = document.getElementById("iconLocationDiv");
    if (controlUi?.parentNode) {
      const newControlUi = controlUi.cloneNode(true);
      controlUi.parentNode.replaceChild(newControlUi, controlUi);
    }
  };

  useEffect(() => {
    return () => {
      clearEventListeners();
      setIsShowHomePinDesc(false);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const computeGoogleMapHome = useCallback(() => {
    let center = defaultlongLat;
    if (agent?.geoLocation?.coordinates) {
      center = {
        lng: agent.geoLocation.coordinates[0],
        lat: agent.geoLocation.coordinates[1],
      };
    }
    return center;
  }, [agent]);

  const computeMapBounds = useCallback(() => {
    if (
      !facilityShiftsProcess.isLoading &&
      map?.fitBounds &&
      window?.google?.maps &&
      facilityShifts?.length > 0 &&
      segmentView === "map" &&
      isDoneInitBounds === false
    ) {
      makeMapBoundsIntoHCF({
        facilityShifts,
        center: computeGoogleMapHome(),
        map: map,
        googleMaps: window.google.maps,
        setIsDoneInitBounds,
      });
    }
  }, [
    facilityShiftsProcess.isLoading,
    facilityShifts,
    map,
    computeGoogleMapHome,
    segmentView,
    isDoneInitBounds,
  ]);

  useEffect(() => {
    if (map) {
      computeMapBounds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computeMapBounds]);

  const computeGoogleMapCurrentLocation = async () => {
    logEvent(MAP_EVENTS.REQUESTED_LOCATION_PERMISSION);
    try {
      const currentPosition = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      const center = {
        lng: get(currentPosition, "coords.longitude", undefined),
        lat: get(currentPosition, "coords.latitude", undefined),
      };
      logEvent(MAP_EVENTS.GRANTED_LOCATION_PERMISSION);
      setCurrentLocation(center);
      return center;
    } catch {
      logEvent(MAP_EVENTS.DECLINED_LOCATION_PERMISSION);
    }
  };

  const onDragEndOrOnZoomChanged = () => {
    setIsShowHomePinDesc(false);
    onBoundsChanged();
    makeMapLogger();
  };

  const makeMapLogger = () => {
    if (!facilityShiftsProcess.isLoading && map && isDoneInitBounds) {
      const homePoint = computeGoogleMapHome();
      const currentMapCenter = {
        lat: map.getCenter()?.lat() || 0,
        lng: map.getCenter()?.lng() || 0,
      };
      let distance = 0;
      if (JSON.stringify(homePoint) !== JSON.stringify(defaultlongLat)) {
        distance = calculateDistanceInMiles(homePoint, currentMapCenter);
      }
      type UnreadableboundsType = {
        Eb: { g: number; i: number };
        mc: { g: number; i: number };
      };
      const unreadablebounds = map.getBounds();

      let shiftsCount = 0;
      shiftsCount = countShiftsInBound(
        facilityShifts,
        convertBounds(unreadablebounds)
      );
      logEvent(USER_EVENTS.MOVED_MAP, {
        "location.lat": currentMapCenter.lat,
        "location.lng": currentMapCenter.lng,
        distance,
        zoom: map.getZoom() as unknown as string,
        shifts: shiftsCount,
      });
    }
  };

  const convertBounds = (unreadablebounds) => {
    const latLngBounds = {
      lat: {
        // the mapbounds returned .mc or .lc
        // 11.468 Southest lat of Texas, 71.218 Northest lat of Alaska
        min: 11.468,
        max: 71.218,
      },
      lng: {
        min: -164.383,
        max: -49.157,
      },
    };
    if (!unreadablebounds.getNorthEast || !unreadablebounds.getSouthWest) {
      return latLngBounds;
    }
    const NEPoint = unreadablebounds.getNorthEast();
    const SWPoint = unreadablebounds.getSouthWest();
    if (!NEPoint.lat || !NEPoint.lng || !SWPoint.lat || !SWPoint.lng) {
      return latLngBounds;
    }
    latLngBounds.lat.min = SWPoint.lat() || latLngBounds.lat.min;
    latLngBounds.lat.max = NEPoint.lat() || latLngBounds.lat.max;
    latLngBounds.lng.min = SWPoint.lng() || latLngBounds.lng.min;
    latLngBounds.lng.max = NEPoint.lng() || latLngBounds.lng.max;
    return latLngBounds;
  };

  const areThereVisibleFacilitiesInBound = () => {
    const unreadablebounds = map?.getBounds();
    const bounds = convertBounds(unreadablebounds);
    return facilityShifts.some((facility) => {
      if ((facility?.geoLocation?.coordinates?.length as number) > 1) {
        const facilityLat = facility.geoLocation?.coordinates[1];
        const facilityLng = facility.geoLocation?.coordinates[0];

        if (!facilityLat || !facilityLng) return false;

        const isLatInsideBonds =
          bounds.lat.min <= facilityLat && facilityLat <= bounds.lat.max;
        const isLngInsideBonds =
          bounds.lng.min <= facilityLng && facilityLng <= bounds.lng.max;
        if (isLatInsideBonds && isLngInsideBonds) {
          return true;
        } else {
          return false;
        }
      }
    });
  };

  const onOpenHomePinDesc = () => {
    setIsShowHomePinDesc(true);
  };
  const onCloseHomePinDesc = () => {
    setIsShowHomePinDesc(false);
  };
  const onHCFMarkerClickHook = (facility: Facility) => () => {
    setIsShowHomePinDesc(false);
    onHCFMarkerClick(facility);
  };

  useIonViewWillLeave(() => {
    setIsShowHomePinDesc(false);
  });

  const renderMap = () => {
    return (
      <>
        <GoogleMap
          onLoad={onLoadGoogleMap}
          mapContainerStyle={googleMapContainerStyle}
          options={googleMapOptions}
          onDragEnd={onDragEndOrOnZoomChanged}
          onZoomChanged={onDragEndOrOnZoomChanged}
          onClick={onCloseHomePinDesc}
        >
          {currentLocation.lat && currentLocation.lng && (
            <Marker
              icon={iconMarkerCurrentLocation}
              position={currentLocation}
              zIndex={1}
            />
          )}
          {agent?.geoLocation?.coordinates && (
            <>
              {isShowHomePinDesc && (
                <OverlayView
                  position={computeGoogleMapHome()}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div className="home-pin-overlay">
                    <div className="pin-box">
                      <h5>My Home</h5>
                      <div>{formatAgentAddress(agent)}</div>
                    </div>
                    <div className="pin-arrow"></div>
                  </div>
                </OverlayView>
              )}
              <Marker
                icon={iconMarkerHome}
                position={computeGoogleMapHome()}
                onClick={onOpenHomePinDesc}
                zIndex={1}
              />
            </>
          )}
          {facilityShifts.map((facility) => {
            if ((facility?.shiftsCount as number) < 1) {
              return (
                // @ts-ignore
                <Marker
                  key={facility._id}
                  onClick={onHCFMarkerClickHook(facility)}
                  icon={iconMarkerEmptyShiftHCF}
                  position={{
                    lng: facility?.geoLocation?.coordinates[0],
                    lat: facility?.geoLocation?.coordinates[1],
                  }}
                  zIndex={facility.shiftsCount}
                />
              );
            }
            return (
              // @ts-ignore
              <Marker
                key={facility._id}
                onClick={onHCFMarkerClickHook(facility)}
                icon={iconMarkerShiftHCF}
                label={{
                  text: `${facility.shiftsCount}`,
                  color: "white",
                  fontSize: "0.75em",
                  fontWeight: "bold",
                }}
                position={{
                  lng: facility?.geoLocation?.coordinates[0],
                  lat: facility?.geoLocation?.coordinates[1],
                }}
                zIndex={facility.shiftsCount}
              />
            );
          })}
        </GoogleMap>
      </>
    );
  };

  if (mapLoadError || (!isMapLoaded && !facilityShiftsProcess.isLoading)) {
    return (
      <div style={{ textAlign: "center", marginTop: "10em" }}>
        The map cannot be loaded right now. Please close and reopen the app
      </div>
    );
  }

  if (isMapLoaded) {
    return renderMap();
  }

  return (
    <div style={{ textAlign: "center", marginTop: "10em" }}>
      <InlineLoader loading={true} />
    </div>
  );
};

// https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
const calculateDistanceInMiles = (point0, point1) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = point0.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = point1.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (point1.lng - point0.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
};

const countShiftsInBound = (facilityShifts, bounds) => {
  let totalShiftsCount = 0;
  facilityShifts.forEach((facility) => {
    if (
      facility?.geoLocation?.coordinates?.length > 1 &&
      facility.shiftsCount
    ) {
      const {
        geoLocation: { coordinates },
      } = facility;
      const [facilityLng, facilityLat] = coordinates;

      const isLatInsideBonds =
        bounds.lat.min <= facilityLat && facilityLat <= bounds.lat.max;
      const isLngInsideBonds =
        bounds.lng.min <= facilityLng && facilityLng <= bounds.lng.max;
      if (isLatInsideBonds && isLngInsideBonds) {
        totalShiftsCount += facility.shiftsCount;
      }
    }
  });

  return totalShiftsCount;
};

const mapHCFDistanceAndSortSplice = ({ facilityShifts, center }) => {
  const facilitiesWithDistance: {
    lat: number;
    lng: number;
    distance: number;
    _id: string | undefined;
    userId: string | undefined;
    name: string | undefined;
    shiftsCount: number | undefined;
  }[] = [];
  facilityShifts?.map((facility: Facility) => {
    const facilityLat = facility?.geoLocation?.coordinates[1] || 0;
    const facilityLng = facility?.geoLocation?.coordinates[0] || 0;
    const distance = calculateDistanceInMiles(center, {
      lat: facilityLat,
      lng: facilityLng,
    });
    if (
      distance > 0 &&
      distance <= 100 &&
      (facility.shiftsCount as number) > 0
    ) {
      facilitiesWithDistance.push({
        lat: facilityLat,
        lng: facilityLng,
        distance: distance,
        _id: facility._id,
        userId: facility.userId,
        name: facility.name,
        shiftsCount: facility.shiftsCount,
      });
    }
  });
  facilitiesWithDistance.sort((a, b) => a.distance - b.distance);
  if (facilitiesWithDistance.length >= 5) {
    // get top 5 nearest
    facilitiesWithDistance.splice(5, facilitiesWithDistance.length - 4);
  }
  return facilitiesWithDistance;
};

const makeMapBoundsIntoHCF = ({
  facilityShifts,
  center,
  map,
  googleMaps,
  setIsDoneInitBounds,
}) => {
  const facilitiesWithDistance = mapHCFDistanceAndSortSplice({
    facilityShifts,
    center,
  });

  const bounds = new googleMaps.LatLngBounds();

  let gmapLatLong = new googleMaps.LatLng(center.lat, center.lng);
  bounds.extend(gmapLatLong);
  facilitiesWithDistance.forEach((facility) => {
    gmapLatLong = new googleMaps.LatLng(facility.lat, facility.lng);
    bounds.extend(gmapLatLong);
  });

  map.fitBounds(bounds, 30);

  const oldZoom = map.getZoom();
  if (oldZoom < 6 || !oldZoom || oldZoom >= 20) {
    // handle web behavior that show global map when fitBounds
    map.setZoom(10);
  }
  setIsDoneInitBounds(true);
};

const formatAgentAddress = (agent) => {
  let formatted = "";
  if (agent?.address?.streetNumber) {
    formatted += `${agent.address.streetNumber} `;
  }
  if (agent?.address?.streetName) {
    formatted += `${agent.address.streetName}, `;
  }
  if (agent?.address?.city) {
    formatted += `${agent.address.city}, `;
  }
  if (agent?.address?.stateCode) {
    formatted += `${agent.address.stateCode} `;
  }
  if (agent?.address?.postalCode) {
    formatted += `${agent.address.postalCode}`;
  }

  return formatted;
};
