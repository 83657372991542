import { Dispatch } from "redux";
import { ActionType, AddressEditDetails } from "./model";

const setAddressEditDetails =
  (details: AddressEditDetails) => (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_ADDRESS_EDIT_DETAILS,
      data: details,
    });
  };

const updateAddressLine1 = (line1: string) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.UPDATE_ADDRESS_LINE_1,
    data: line1,
  });
};

export { setAddressEditDetails, updateAddressLine1 };
