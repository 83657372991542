import { OnboardingErrors } from "src/constants/loginErrorConstants";

class FirebaseUpdateError extends Error {
  private errorType: OnboardingErrors;
  constructor(type: OnboardingErrors, message: string) {
    super(message);
    this.errorType = type;
  }
  getErrorType() {
    return this.errorType;
  }
}

export { FirebaseUpdateError };
