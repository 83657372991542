import { NFCReadStatus } from "@src/lib/interface/src";

export const alertSkipNFCScanMessage =
  "If we cannot complete the NFC tag verification, your shift will be converted to Non-InstantPay. That means you will receive payment only when the shift is verified.";
export const alertNFCAccessMessage =
  "We need to verify that you’re at the facility. Please grant us NFC access in your device settings.";
export const alertBrowserNFCAccessMessage =
  "Please open your browser settings and re-enable nfc access to this site";
export const alertUserToRequestHCFToChangeToBackupMessage =
  "Your device does not support reading NFC tags. Please ask a facility administrator to disable the NFC device for you to clock in.";
export const alertNoNetworkConnectionMessage =
  "Connect to WiFi or cellular data to record time. Otherwise, you cannot perform action.";
export const alertNFCNotWorkingMessage =
  "Just to confirm, you are reporting that the NFC tag is not working?";
export const alertOtherIssueMessage = "Please wait a moment and try again.";
export const alertContactHCFAdminMessage =
  "Please contact a facility administrator to disable the NFC tag.";
export const alertCancelEditedTimeMessage =
  "If we cannot perform NFC check, you cannot edit your times.";

export const getAlertNFCErrorMessage = (
  nfcReadStatus: NFCReadStatus
): string => {
  let errorMessage = "We could not complete the action. Please try again";
  if (nfcReadStatus == NFCReadStatus.INVALID_HASH)
    errorMessage =
      "We could not complete the action. Please confirm you are using the right NFC tag and try again";
  else if (nfcReadStatus == NFCReadStatus.FAILED)
    errorMessage =
      "We could not complete the action. Please make sure to tap your phone on the NFC tag on the poster.";
  return errorMessage;
};
