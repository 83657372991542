import { jsPDF } from "jspdf";
import { logErrors } from "@app/errorBoundary/api";

import { SelectedFile } from "../shiftSignature/timecard/model";
import { isImage } from "./isImage";

/**
 * constructs a pdf from multiple image files
 * @param selectedImages base64 encoded images
 */
const constructPDFFromMultipleImages = async (
  selectedImages: SelectedFile[],
  getFileBlob: boolean = false
): Promise<{ file: string; fileBlob?: Blob }> => {
  try {
    if (!selectedImages?.length) return { file: "" };

    const documentPDFFile = new jsPDF({ compress: true });
    documentPDFFile.deletePage(1);
    for (let selectedImage of selectedImages) {
      if (!isImage(selectedImage.type)) return { file: "" };
      const [width, height] = await new Promise((resolve, reject) => {
        try {
          const htmlImageElement = new Image();
          htmlImageElement.onload = function () {
            // scale the image size to fit A4 size, assuming the maximum size
            // is 1080 * 1527.428571429, using the width of FHD resolution
            const scale =
              htmlImageElement.width > 1080 ? 1080 / htmlImageElement.width : 1;
            const widthInMM = (htmlImageElement.width * scale * 200) / 1080;
            const heightInMM =
              (htmlImageElement.height * scale * 287) / 1527.428571429;
            resolve([widthInMM, heightInMM]);
          };
          htmlImageElement.src = selectedImage.file;
        } catch (error) {
          reject(error);
        }
      });
      documentPDFFile
        .addPage()
        .addImage(selectedImage.file, selectedImage.type, 5, 5, width, height);
    }
    // remove the default file name added to the dataURI to pass cloudinary validation
    const base64EncodedPDF = documentPDFFile
      ?.output("datauristring")
      ?.replace("filename=generated.pdf;", "");

    const fileBlob = getFileBlob ? documentPDFFile?.output("blob") : undefined;
    return { file: base64EncodedPDF, fileBlob };
  } catch (error) {
    logErrors({
      app: "workerMobile",
      message: `[constructPDFFromMultipleImages]: Unable to generate PDF file ${JSON.stringify(
        error?.stack
      )}`,
    });
    return { file: "" };
  }
};

export { constructPDFFromMultipleImages };
