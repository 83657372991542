import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonImg,
} from "@ionic/react";
import React, { ErrorInfo } from "react";

type PropTypes = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export const Fallback = ({ error, errorInfo }: PropTypes): JSX.Element => {
  return (
    <>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <div style={{ width: "80%" }}>
          <p style={{ color: "black", fontSize: 18, fontWeight: "bold" }}>
            Oops, Something went wrong.
          </p>
          <p style={{ color: "gray" }}>You can go back or restart the app.</p>
          <p style={{ color: "gray" }}>
            If you keep having trouble, please reach out to our Support team!
          </p>
          <p>
            <a href="tel:4088370116">(408) 837-0116</a>
          </p>
          <p>
            <a href="mailto:support@clipboardhealth.com">
              support@clipboardhealth.com
            </a>
          </p>
          <div className="cbh-logoh-wrapper">
            <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
          </div>
          {/* Error Stack Trace */}
          {/* <div
            style={{
              backgroundColor: "#f2f2f2",
              borderRadius: "12px",
              padding: "16px",
            }}
          >
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </div> */}
        </div>
      </div>
    </>
  );
};
