import "./style.scss";
import "antd/es/alert/style/css";
import Alert from "antd/es/alert";
import { ellipsisVertical } from "ionicons/icons";
import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import { get } from "lodash";
import {
  DistanceChip,
  DistanceNote,
  PayRateChip,
} from "src/lib/ionic-components";
import { holidayEmojis, surgeryWorkerTypes } from "src/lib/constants";
import React, { Fragment, useEffect, useState, useMemo } from "react";
import { geoDistance } from "src/lib/utils";
import { logApiFailureEvent } from "src/lib/analytics";
import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";

import { showModal } from "../shared/modal";
import { showToast } from "../layout/toast";
import { claimAgentShift } from "./api";
import InstantBookConfirmationModal from "./instantBookConfirmationModal";
import InstantBookSaveConfirmationModal from "./InstantBookSaveConfirmationModal";
import { ShiftItemProps } from "./model";
import { USER_EVENTS } from "../../constants";
import { useSelector } from "react-redux";
import { Store } from "@store/store.model";
import { Facility } from "src/lib/interface";

const timeFormat = "hh:mm a";
const lastMinuteTimeFormat = "ddd MMM Do, h:mm a";
const timeRange = (shift) =>
  `${moment(shift.start).format(timeFormat)} - ${moment(shift.end).format(
    timeFormat
  )}`;
const lastMinuteTimeRange = (shift) =>
  `${moment(shift.start).format(lastMinuteTimeFormat)} - ${moment(
    shift.end
  ).format(timeFormat)}`;

const InstantBookShiftItem: React.FC<ShiftItemProps> = ({
  shiftPay,
  shift,
  shiftName,
  handleShiftFlagFound,
  onFacilityDetailsClick,
  isAnimated,
  onShiftClaim,
  isHcpInstantPayEnabled,
  isDisabled,
  payVersion,
  handleShiftActivityLog,
  isLastMinuteShift,
}) => {
  const { agent } = useSelector((state: Store) => state.session);

  useEffect(() => {
    logEvent(USER_EVENTS.DISCOVERED_AN_INSTANT_BOOK_SHIFT);
  }, []);

  const saveConfirmationModalHandler = () => {
    setInstantBookSaveConfirmationModal(false);
  };

  const handleResponse = (shiftResponse, isLastMinuteShift) => {
    if (isLastMinuteShift && shiftResponse && shiftResponse.isAlreadyGrabbed) {
      showToast(shiftResponse.message, 3000, "bottom", "danger");
      onShiftClaim?.(
        shift._id as string,
        shiftResponse.isAlreadyGrabbed,
        shift.start
      );
      return;
    } else if (isLastMinuteShift && shiftResponse) {
      logEvent(USER_EVENTS.SUCCESSFULLY_INSTANT_BOOKED_LAST_MINUTE_SHIFT, {
        hcpId: agent?.userId as string,
        email: agent?.email as string,
        hcpName: agent?.name as string,
        shiftId: shift._id as string,
        shiftPay: shiftPay as number,
        facilityName: shift.facility?.name as string,
        facilityId: shift.facility?._id as string,
        startAt: shift.start as string,
        endAt: shift.end as string,
        timestamp: moment().format(),
        instant_book: true,
        last_minute: true,
        instant_pay: false,
      });
    } else {
      logEvent(USER_EVENTS.SUCCESSFULLY_INSTANT_BOOKED_A_SHIFT, {
        hcpId: agent?.userId as string,
        email: agent?.email as string,
        instant_book: true,
        last_minute: false,
        instant_pay: false,
      });
    }
    setInstantBookSaveConfirmationModal(true);
    setTimeout(() => {
      onShiftClaim?.(shiftResponse["_id"]);
      handleShiftActivityLog?.(shiftResponse["_id"]);
    }, 4000);
  };

  const confirmationModalHandler = async () => {
    try {
      const shiftId = shift["shiftId"] || shift["_id"];
      const updatedShift = await claimAgentShift({
        shiftId: shiftId as string,
        hasLastMinuteGrabCheck: isLastMinuteShift as boolean,
      });
      handleResponse(updatedShift, isLastMinuteShift);
    } catch (error) {
      logApiFailureEvent(error);
      showModal("error", "Unable to claim this shift.");
    }
  };
  const hideModal = () => {
    setInstantBookConfirmationModal(false);
  };

  const [
    isInstantBookSaveConfirmationModal,
    setInstantBookSaveConfirmationModal,
  ] = useState(false);
  const [instantBookConfirmationModal, setInstantBookConfirmationModal] =
    useState(false);

  const instantBookNowHandler = () => {
    logEvent(USER_EVENTS.TAPPED_INSTANT_BOOK, {
      instant_book: true,
      instant_pay: shift?.isInstantPay,
      last_minute: isLastMinuteShift,
    });
    setInstantBookConfirmationModal(true);
  };

  if (shift.agentId) {
    return (
      <IonItem lines="none">
        <Alert
          type="warning"
          className="ion-margin-horizontal"
          message={`You are working a ${shiftName.toUpperCase()} from ${timeRange(
            shift
          )} 
                    at ${shift.facility?.name}.`}
        />
      </IonItem>
    );
  }

  if (shift.isHolidayShift) {
    handleShiftFlagFound?.("holiday");
  }

  const isInstantPayAllowed = () => {
    const hcfInstantPayStatus =
      shift.isHCFInstantPayProhibited === null
        ? false
        : !shift.isHCFInstantPayProhibited;
    return hcfInstantPayStatus && isHcpInstantPayEnabled;
  };

  const onClickOnFacilityDetails = () => {
    const facility = shift.facility as Facility;
    facility.isInstantPayEnabled = isInstantPayAllowed();
    onFacilityDetailsClick?.(shift, true);
  };

  const hcpDistanceFromFacility: {
    distance: number;
    isApproxDistance: boolean;
  } = useMemo(() => {
    const facilityCoordinates: number[] = get(
      shift,
      "facility.geoLocation.coordinates",
      []
    );
    const agentCoordinates: number[] = get(
      agent,
      "geoLocation.coordinates",
      []
    );
    let distanceFromFacility: number;
    let isApproxDistance: boolean;
    if (shift.facility?.distance) {
      distanceFromFacility = shift.facility?.distance;
      isApproxDistance = shift.facility?.isApproxDistance ?? false;
    } else {
      distanceFromFacility = geoDistance(agentCoordinates, facilityCoordinates);
      isApproxDistance = true;
    }
    if (isApproxDistance) {
      distanceFromFacility = Math.round(2 * distanceFromFacility) / 2;
    }
    return { distance: distanceFromFacility, isApproxDistance };
  }, [shift.facility, agent?.geoLocation]);

  return (
    <Fragment>
      {
        <InstantBookConfirmationModal
          visible={instantBookConfirmationModal}
          confirmationModalHandler={confirmationModalHandler}
          shift={shift}
          hideModal={hideModal}
        />
      }
      {
        <InstantBookSaveConfirmationModal
          visible={isInstantBookSaveConfirmationModal}
          confirmationModalHandler={saveConfirmationModalHandler}
          shift={shift}
          hideModal={hideModal}
        />
      }
      <IonItem disabled={isDisabled} class="ion-no-padding">
        <IonGrid>
          {isLastMinuteShift && (
            <IonRow>
              <IonCol size="11">
                <p className="shift-item-date-heading">
                  {`${lastMinuteTimeRange(shift)} (${shift.time?.toFixed(
                    2
                  )} hrs)`}
                </p>
              </IonCol>
              <IonCol size="1">
                <IonButton
                  onClick={onClickOnFacilityDetails}
                  style={{ zIndex: 100 }}
                  fill="clear"
                >
                  <IonIcon
                    icon={ellipsisVertical}
                    style={{ fontSize: "20px", color: "#6E6E6E" }}
                  />
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol size="8">
              <IonLabel class="ion-opacity">
                {!isLastMinuteShift && (
                  <h4>{`${timeRange(shift)} 
                                    (${shift.time?.toFixed(2)} hrs)`}</h4>
                )}
                {surgeryWorkerTypes.includes(shift.agentReq as string) && (
                  <p className="ion-text-wrap">
                    Surgery Speciality: {shift.surgerySpeciality?.speciality}
                    {shift.surgerySpeciality?.hasSedationExperience &&
                      ", Requires Conscious Sedation"}
                    {shift.surgerySpeciality?.hasTrayAssemblyExperience &&
                      ", Requires Tray Assembly"}
                  </p>
                )}
                {!isLastMinuteShift && shift.description ? (
                  <p style={{ margin: "4px 0" }} className="ion-text-wrap">
                    About this shift: {shift.description}
                  </p>
                ) : null}
                {!isLastMinuteShift && shift.facility?.description ? (
                  <p style={{ margin: "4px 0" }} className="ion-text-wrap">
                    Facility Details: {shift.facility.description}
                  </p>
                ) : null}
                {!isLastMinuteShift && (
                  <p className="ion-text-wrap ion-instant-text">
                    {shift.facility?.name}
                    <DistanceNote
                      distance={hcpDistanceFromFacility.distance}
                      isApproxDistance={
                        hcpDistanceFromFacility.isApproxDistance
                      }
                    />
                  </p>
                )}
                {isLastMinuteShift && (
                  <Fragment>
                    <p
                      className="ion-text-wrap"
                      style={{ fontSize: "14px", color: " #707070" }}
                    >
                      {shift.facility?.name}{" "}
                    </p>
                    <DistanceNote
                      distance={hcpDistanceFromFacility.distance}
                      isApproxDistance={
                        hcpDistanceFromFacility.isApproxDistance
                      }
                      isLastMinuteShift={true}
                    />
                  </Fragment>
                )}
                <p>
                  {!payVersion && (
                    <DistanceChip
                      distance={shift.facility?.distance as number}
                    />
                  )}
                  {payVersion && (
                    <PayRateChip
                      payVersion={payVersion}
                      pay={shiftPay}
                      time={shift.time}
                    />
                  )}
                  {shift.isHolidayShift && (
                    <IonChip color="dark" className="small-chip">
                      <IonLabel>It's {shift.holidayName}</IonLabel>
                    </IonChip>
                  )}
                  {isInstantPayAllowed() && (
                    <IonChip color="success" className="small-chip">
                      <IonLabel>InstantPay Facility</IonLabel>
                    </IonChip>
                  )}
                </p>
              </IonLabel>
            </IonCol>
            <IonCol size="4">
              <div className="instant-book-action-content">
                {!isLastMinuteShift && (
                  <IonButton
                    onClick={onClickOnFacilityDetails}
                    style={{ zIndex: 100 }}
                    fill="clear"
                    expand="block"
                    className="instant-action-btn"
                  >
                    <IonIcon
                      slot="end"
                      icon={ellipsisVertical}
                      style={{ fontSize: "25px", color: "#707070" }}
                    />
                  </IonButton>
                )}
                <div className={`${isAnimated && "border"}`}></div>
                <IonButton
                  onClick={instantBookNowHandler}
                  fill="outline"
                  size="default"
                  expand="block"
                  className={`instant-book-btn`}
                >
                  BOOK NOW
                </IonButton>
                <span>
                  {shift.isHolidayShift && (
                    <span slot="end" role="img" aria-label="holidayEmoji">
                      {holidayEmojis[shift.holidayName as string] ||
                        holidayEmojis.default}
                    </span>
                  )}
                </span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </Fragment>
  );
};

export { InstantBookShiftItem };
