import { useMemo } from "react";
import { IonAlert } from "@ionic/react";
import { MissingDocumentsItemProps } from "../model";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "@constants/userEvent";
import { STATE_LICENSE_REQUIREMENT_ID } from "src/constants/index";
import { MISSING_DOCS_ALERT_INFO_MESSAGES } from "./constants";

const MissingDocumentsAlert = ({
  isOpen,
  onDidDismiss,
  onConfirm,
  missingDocs,
  expiringInFuture,
}: MissingDocumentsItemProps) => {
  if (!missingDocs?.size) {
    return null;
  }

  const {
    visibleDocumentsCount,
    missingDocsInfoText,
    isPlural,
    hasMoreDocuments,
  } = useMemo(() => {
    const isPlural = missingDocs.size > 1;
    const hasMoreDocuments = missingDocs.size > 6;
    const visibleDocs: string[] = [];
    const invisibleDocs: string[] = [];
    for (const requirement of missingDocs) {
      if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
        visibleDocs.push(`State License (upload as Miscellaneous)`);
      } else if (requirement.visibleToHCP || !expiringInFuture) {
        // Considering currently expired docs as visible
        visibleDocs.push(requirement.name);
      } else {
        invisibleDocs.push(requirement.name);
      }
    }

    const visibleDocsCount = visibleDocs.length;

    // Getting missing docs info message
    let visibleDocsMessage = isPlural
      ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_DOCS_MISSING_CURRENTLY_MESSAGE
      : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_DOC_MISSING_CURRENTLY_MESSAGE;
    let invisibleDocsMessage = "";
    if (expiringInFuture) {
      visibleDocsMessage =
        visibleDocsCount > 1
          ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_VISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
          : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_VISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
      if (visibleDocsCount && invisibleDocs.length) {
        invisibleDocsMessage =
          invisibleDocs.length > 1
            ? MISSING_DOCS_ALERT_INFO_MESSAGES.MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
            : MISSING_DOCS_ALERT_INFO_MESSAGES.SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
      } else if (invisibleDocs.length) {
        visibleDocsMessage = "";
        invisibleDocsMessage =
          invisibleDocs.length > 1
            ? MISSING_DOCS_ALERT_INFO_MESSAGES.ONLY_MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE
            : MISSING_DOCS_ALERT_INFO_MESSAGES.ONLY_SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE;
      }
    }

    let missingDocsInfo = "";
    if (visibleDocsCount) {
      missingDocsInfo += `<p>
      ${visibleDocsMessage}</p>
      <div class='information'>
        ${
          visibleDocs
            .slice(0, 6)
            .map((name) => `<p>${name}</p>`)
            .join(
              ""
            ) /* As it's not react-jsx and doesn't maintain virtual-dom, no need of key prop. */
        }
      </div>`;
    }
    if (invisibleDocs.length && visibleDocsCount < 6) {
      missingDocsInfo += `<p>
      ${invisibleDocsMessage}</p>
      <div class='information'>
        ${
          invisibleDocs
            .slice(0, 6 - visibleDocsCount)
            .map((name) => `<p>${name}</p>`)
            .join(
              ""
            ) /* As it's not react-jsx and doesn't maintain virtual-dom, no need of key prop. */
        }
      </div>`;
    }
    return {
      visibleDocumentsCount: visibleDocsCount,
      missingDocsInfoText: missingDocsInfo,
      isPlural,
      hasMoreDocuments,
    };
  }, [expiringInFuture, missingDocs]);

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      data-testid="missing-docs-msg"
      message={`
        <h1><img alt='alert-circle' src='assets/icons/add-circle.svg'/> You're Missing <span class='missing-doc-count'>${
          missingDocs.size
        }</span> Document${isPlural ? "s" : ""}</h1>
        ${missingDocsInfoText}
        ${
          hasMoreDocuments
            ? "<p>Click to upload your documents to view the remaining missing requirements </p>"
            : ""
        }
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: visibleDocumentsCount ? "Cancel" : "Okay",
          role: "cancel",
          handler: () =>
            logEvent(USER_EVENTS.TAPPED_CANCEL_MISSING_DOCS_POPUP_BUTTON),
        },
        ...(visibleDocumentsCount
          ? [
              {
                text: "Upload Docs",
                handler: onConfirm,
              },
            ]
          : []),
      ]}
    />
  );
};

export { MissingDocumentsAlert };
