import "./style.scss";
import _ from "lodash";
import {
  IonButton,
  IonCol,
  IonContent,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { get } from "lodash";

import { ActionType } from "@store/session";
import { validateAgentOTP } from "@store/session/api";
import { OtpInputs } from "../../components/auth/otpInputs";
import { Store } from "../../store/store.model";
import { requestOTP } from "./api";
import { ProfileUpdateStepProps } from "./interface";
import { formatNumberToPhoneNumber } from "../../../hooks/useTelephoneNumber";

const CODE_LENGTH = 6;

const OTPVerification: React.FC<ProfileUpdateStepProps> = ({
  stepFinished,
  showInputBorder = false,
}) => {
  const [code, setCode] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [otpResendStatus, setOtpResendStatus] = useState(false);
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    requestOTP(agent.phone);
  }, []);

  const verify = async (newCode?) => {
    const otpCode = newCode + "";
    if (isVerifying) return;
    if (otpCode.trim().length !== CODE_LENGTH) return;
    if (isButtonDisabled) setButtonDisabled(false);
    try {
      setIsVerifying(true);
      const { error } = await validateAgentOTP(agent.phone, otpCode);
      setIsVerifying(false);
      if (error) {
        setPageError({
          isError: true,
          message: "Invalid OTP",
        });
        return;
      }

      stepFinished();
    } catch (error) {
      setPageError({
        isError: true,
        message: "Unexpected error occurred while verifying OTP",
      });
    } finally {
      dispatch({
        type: ActionType.VERIFICATION_COMPLETED,
      });
    }
  };

  const resendOTP = async () => {
    const { error } = await requestOTP(agent.phone);
    // @ts-ignore
    if (!error) {
      setOtpResendStatus(true);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  return (
    <IonContent>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />
      <div className="content-layout signup-content adjust-form-height verifyotp-content ">
        <div className="form-container">
          <IonRow>
            <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
              <div className="form-heading">
                <h4>Enter your verification code</h4>
                <p>
                  We’ve just sent a verification code to{" "}
                  {formatNumberToPhoneNumber(agent.phone)}{" "}
                </p>
              </div>
              <div>
                <IonLabel className="form-label hcp-self-label">
                  Verification Code
                </IonLabel>
                <OtpInputs
                  verify={verify}
                  code={code}
                  setCode={setCode}
                  noBorder={!showInputBorder}
                />
              </div>
              <a className="resend-otp-link" onClick={resendOTP}>
                Didn't get a code? Resend it now.
              </a>
            </IonCol>
          </IonRow>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            onClick={verify}
            disabled={isVerifying || isButtonDisabled}
            id="verify-button"
          >
            Continue
            {isVerifying && (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            )}
          </IonButton>
        </div>
      </div>
      <IonToast
        isOpen={otpResendStatus}
        onDidDismiss={() => setOtpResendStatus(false)}
        message="OTP re-sent successfully."
        duration={5000}
        color="success"
        position="top"
      />
    </IonContent>
  );
};

export { OTPVerification };
