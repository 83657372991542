import {
  checkmarkCircleOutline,
  timer,
  warning,
  closeCircle,
} from "ionicons/icons";

import {
  HcpDocument,
  HcpRequirementResponse,
  HcpRequirementStatus,
  PageError,
} from "../store/documents/model";
import { SelectedFile } from "../shiftSignature/timecard/model";
import { CameraSource } from "@capacitor/camera";

const SegmentTypes = Object.freeze({
  CORE: "core",
  RECOMMENDED: "recommended",
});

const RequirementTypes = Object.freeze({
  OPTIONAL: "optional",
  EXPIRED: "expired",
  REJECTED: "rejected",
  MISSING: "missing",
  REQUIRED_BY_FACILITIES: "missingHCFLevel",
  PENDING: "pending",
  COMPLETED: "completed",
  MISCELLANEOUS: "miscellaneous",
});

const RequirementStatusData = Object.freeze({
  [RequirementTypes.EXPIRED]: {
    status: "Expired",
    description: "",
    listTitle: "Document Expired! Tap to upload new one...",
    listTitleColor: "danger",
    listIcon: warning,
  },
  [RequirementTypes.PENDING]: {
    status: "Submitted",
    description: "Pending approval!",
    listTitle: "Pending Approval! View Doc",
    listTitleColor: "medium",
    listIcon: timer,
  },
  [RequirementTypes.MISSING]: {
    status: "Missing",
    description: "Not yet uploaded",
    listTitle: "Tap to upload",
  },
  [RequirementTypes.COMPLETED]: {
    status: "Completed",
    description: "Submitted and approved!",
    listTitle: "Completed! View Doc",
    listTitleColor: "success",
    listIcon: checkmarkCircleOutline,
  },
  [RequirementTypes.MISCELLANEOUS]: {
    status: "Miscellaneous",
    description: "Upload new document",
    listTitle: "Tap to upload",
  },
  [RequirementTypes.OPTIONAL]: {
    status: "Optional",
    description: "Optional",
    listTitle: "Tap to upload",
  },
  [RequirementTypes.REJECTED]: {
    status: "Rejected",
    listTitle: "Document Rejected! Tap to upload new one...",
    listTitleColor: "danger",
    listIcon: closeCircle,
  },
  [RequirementTypes.REQUIRED_BY_FACILITIES]: {
    status: "Required by some facilities",
    description: "Not yet uploaded",
    listTitle: "Tap to upload",
  },
});

interface DocumentListExtraData {
  potentialNewShiftsCount: number;
}
interface DocumentsListProps {
  status: HcpRequirementStatus;
  type: string;
  loading: boolean;
  onClick: (
    requirement: HcpRequirementResponse,
    requirementType: string
  ) => () => void;
  extraData?: DocumentListExtraData;
  isCheckrPillStatusEnabled: boolean;
}

interface DocumentViewFooterProps {
  allowMultipleUpload: boolean;
  selectedFiles: SelectedFile[];
  uploading: boolean;
  handleFileUpload: () => Promise<void>;
  handleTakePhotoClick: (source: CameraSource) => void;
  handleSelectDocumentClick: () => void;
  disableUpload: boolean;
}

interface DocumentPreviewProps {
  document: HcpDocument;
  setPageError: (pageError: PageError) => void;
  documentName: string;
}

interface CheckrApplicationStatus {
  invitationStatus: string | null;
  reportStatus: string | null;
}

interface IFileUpload {
  url?: string;
  fileStorageRootFolder?: string;
  fileStorageFileKey?: string;
  name: string;
}

export {
  SegmentTypes,
  RequirementTypes,
  RequirementStatusData,
  DocumentsListProps,
  DocumentViewFooterProps,
  DocumentPreviewProps,
  CheckrApplicationStatus,
  IFileUpload,
};
