import React from "react";
import { ConfirmationModalProps } from "./model";
import moment from "moment-timezone";
import { IonAlert } from "@ionic/react";
import "./style.scss";

const InstantBookSaveConfirmationModal: React.FC<ConfirmationModalProps> = ({
  shift,
  visible,
  confirmationModalHandler,
  hideModal,
}) => {
  const timeFormat = "hh:mm a";
  const timeRange = (shift) =>
    `${moment(shift.start).format(timeFormat)} - ${moment(shift.end).format(
      timeFormat
    )}`;
  return (
    <>
      <IonAlert
        animated
        isOpen={visible}
        onDidDismiss={() => hideModal?.()}
        cssClass="popup-info-modal"
        message={`
                    <span> Great. You are all set for your Instant Book Shift. See you there!</span>
                    <span>
                        <b> ${timeRange(shift)} (${shift?.["time"]} hrs) at ${
          shift?.["facility"].name
        } </b>
                    </span>
                    <a>You’ll see it now in My Shifts in your app.</a>
                `}
        buttons={[
          {
            text: "Ok",
            handler: () => {
              confirmationModalHandler?.();
            },
          },
        ]}
      />
    </>
  );
};

export default InstantBookSaveConfirmationModal;
