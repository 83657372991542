import "./styles.scss";
import { Agent } from "src/lib/interface";
import { Agreements } from "./components/agreements";
import { get } from "lodash";
import { Store } from "../store/store.model";
import { updateAgentProfileState } from "./actions";
import {
  createPaymentServiceAccount,
  signedContractorAgreement,
  updateAgentProfile,
} from "./api";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { OnBoardingComponentProps } from "../onboarding/components/model";

const OnboardToStripe: React.FC<OnBoardingComponentProps> = ({
  nextStagePath,
  disableBackButton,
}) => {
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState({ isOpen: false, message: "" });
  const dispatch = useDispatch();
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const history = useHistory<{ redirectTo: "" }>();

  const handleAccountCreate = useCallback(async () => {
    const splittedName = agent?.name?.split(" ") as string[];
    let firstName: string = "";
    let lastName: string = "";
    if (splittedName.length > 0) {
      firstName = splittedName[0] || "";
      lastName = splittedName[splittedName?.length - 1] || "";
    }

    const hcpAddress = {
      line1: `${agent?.address?.streetNumber || ""} ${
        agent?.address?.streetName || ""
      }`,
      city: agent?.address?.city,
      state: agent?.address?.stateCode,
      postalCode: agent?.address?.postalCode,
    };
    const createdStripeAccount = await createPaymentServiceAccount({
      agentId: agent?.userId,
      email: agent?.email as string,
      msa: agent?.address?.metropolitanStatisticalArea,
      individual: {
        firstName: agent?.firstName || firstName,
        lastName: agent?.lastName || lastName,
        phone: agent?.phone,
        birthday: agent?.dob,
        address: hcpAddress,
      },
    });
    return createdStripeAccount;
  }, [agent]);

  const handleAgreementAcceptance = useCallback(async () => {
    try {
      setLoading(true);
      const updatedAgent = await signedContractorAgreement(
        agent.userId as string
      );
      const { signedContractorAgreements } = updatedAgent;
      if (!agent?.paymentAccountInfo) {
        const paymentAccountInfo = await handleAccountCreate();
        dispatch(
          updateAgentProfileState({
            signedContractorAgreements,
            paymentAccountInfo,
            shouldSignLatestAgreement: false,
          })
        );
      }
      dispatch(
        updateAgentProfileState({
          signedContractorAgreements,
          shouldSignLatestAgreement: false,
        })
      );
      setLoading(false);
      const { redirectTo } = history?.location?.state;
      if (nextStagePath) {
        history.push(nextStagePath);
        return;
      }
      if (redirectTo) {
        history.replace(redirectTo);
      }
    } catch (err) {
      setLoading(false);
      setToastError({
        isOpen: true,
        message:
          "Sorry we are experiencing technical problems. Please contact support. ",
      });
    }
  }, [agent, dispatch, handleAccountCreate, history, nextStagePath]);

  return (
    <Agreements
      handleAgreementAcceptance={handleAgreementAcceptance}
      loading={loading}
      toastError={toastError}
      setToastError={setToastError}
      disableBackButton={disableBackButton}
    />
  );
};
export { OnboardToStripe };
