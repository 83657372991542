import React from "react";
import { ConfirmationModalProps } from "./model";
import { IonAlert } from "@ionic/react";
import "./style.scss";
import { MessageString } from "../../constants/strings";

const MarkInterestInfoModal: React.FC<ConfirmationModalProps> = ({
  visible,
  hideModal,
}) => (
  <IonAlert
    animated
    isOpen={visible}
    onDidDismiss={() => hideModal()}
    cssClass="popup-info-modal"
    message={`
                <span>
                ${MessageString.shiftBookingInfo}
                </span>
            `}
    buttons={[
      {
        text: "Ok",
        handler: () => hideModal(),
      },
    ]}
  />
);

export default MarkInterestInfoModal;
