import {
  PaymentServiceAccountStatus,
  PayrollLineItem,
} from "src/lib/interface";

interface SummaryProps {
  balance: Balance;
  loading: boolean;
  setBalance: (balance: Balance) => void;
  handleBackClick: (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => void;
  handleForwardClick: (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => void;
  items: PayrollLineItem[];
  relativeWeek: number;
}

interface PayrollSettingsDropdownProps {
  balance: Balance;
}

interface Balance {
  availableNow: number;
  availableNowMinusFee: number;
  availableSoon: number;
  lifetimeEarnings: number;
  hasDebitCard: boolean;
  withdrawalFee: number;
  autoWithdrawEnabled: boolean;
}

interface ShiftListHeaderProps {
  handleBackClick: (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => void;
  handleForwardClick: (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => void;
  relativeWeek: number;
  items: PayrollLineItem[];
}

type StoreFunc = (fn: () => void) => () => void;

interface BubbleProps {
  icone: string;
  description: string;
  loading: boolean;
  value: React.Attributes;
  dropdown?: React.Attributes;
  valueFontSize?: number;
  storeHideDropDown?: StoreFunc;
}

interface PayrollItemProps {
  icone: string;
  name: string;
  onClick: () => void;
}

interface ChevronProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const InvalidPaymentAccountStatus = [
  PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING,
  PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING,
];

export {
  ShiftListHeaderProps,
  SummaryProps,
  BubbleProps,
  ChevronProps,
  Balance,
  InvalidPaymentAccountStatus,
  PayrollSettingsDropdownProps,
  PayrollItemProps,
};
