import { isPlatform } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import { logErrors } from "../errorBoundary/api";
import { BackgroundMode } from "@ionic-native/background-mode";
import { App } from "@capacitor/app";
import { BackgroundTask } from "@robingenz/capacitor-background-task";
import { locationService } from "../openShifts/urgentShifts/locationService";
import { CommuteMethod } from "./constants";
import { startTracking } from "../openShifts/urgentShifts/utils";
import { environment } from "../../environments/environment";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../constants/userEvents";

export const handleLocationEvent = async (payload, radarSdkConfig) => {
  const { method, tag, externalId, shiftId, workerId, mode, startTime } =
    payload;

  if (!method) {
    logErrors({
      message: `handleLocationEvent: no method defined ${payload.workerId}`,
      app: "hcpMobile",
    });
    return;
  }

  if (method === CommuteMethod.start) {
    const locationAvailable = await Diagnostic.isLocationAvailable();

    if (!locationAvailable) {
      logErrors({
        message: `handleLocationEvent: location not available ${payload.workerId}`,
        app: "hcpMobile",
      });
      return;
    }

    try {
      locationService.initialize(environment.radarSdkPublishableKey);

      await locationService.startTrip({
        options: {
          externalId: `${shiftId}-${workerId}`, // to generate unique id
          destinationGeofenceTag: tag,
          destinationGeofenceExternalId: externalId,
          mode: mode ?? "car",
          metadata: {
            shiftId,
          },
        },
      });

      await startTracking(radarSdkConfig);

      logEvent(USER_EVENTS.TRIP_TRACKING_STARTED_ON_SILENT_PUSH, {
        shiftId,
        workerId,
        startTime,
      });
    } catch (error) {
      logErrors({
        message: `handleLocationEvent: error starting trip ${JSON.stringify(
          error?.stack || error
        )}`,
        app: "hcpMobile",
      });
    }
  }
};

export const handleCommuteEvent = async (payload, radarSdkConfig) => {
  const { isActive } = await App.getState();

  if (isActive) {
    await handleLocationEvent(payload, radarSdkConfig);
    return;
  }

  if (isPlatform("android")) {
    try {
      BackgroundMode.setDefaults({
        title: "You have an upcoming shift",
        text: "Using your location",
      });

      BackgroundMode.enable();
      BackgroundMode.disableBatteryOptimizations();
      BackgroundMode.disableWebViewOptimizations();
      await handleLocationEvent(payload, radarSdkConfig);
    } catch (err) {
      logErrors({
        message: `Unable to send location for user: ${payload.workerId}`,
        app: "hcpMobile",
      });
    } finally {
      BackgroundMode.disable();
    }

    return;
  }

  /*
   * We're using background task in order to send the location while the app is in the background.
   * We need to explicitly terminate the task, otherwise we risk our app being terminated or flagged as impacting battery life.
   * Library itself does not support android platform anymore
   */
  const taskId = await BackgroundTask.beforeExit(async () => {
    await handleLocationEvent(payload, radarSdkConfig).then(() => {
      BackgroundTask.finish({
        taskId,
      });
    });
  });
};
