import React, { useEffect } from "react";
import { IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import {
  GeneralLocationPrimerModalProps,
  GeneralPrimerPageLabels,
} from "./model";
import getReactComponents from "./getReactComponents";
import { getLabelsFromPageConfig, setSeenGeneralPrimerPage } from "./utils";

import "./style.scss";

const defaultGeneralPrimerPageLabels: GeneralPrimerPageLabels = {
  title: "Enable Location Services",
  reasonText:
    "Allow us to access your location so we can provide the best experience possible and build trust between you and the facilities you work with",
  instantPayLabel:
    "Receive your shift earnings immediately after you clock out with InstantPay",
  urgentShiftsLabel:
    "Access high-paying Urgent Shifts that are close by and need a healthcare professional like you to fill them",
  commuteModuleLabel:
    "Provide an attendance guarantee to the healthcare facilities that need reliable staffing",
  allowButtonLabel: "Allow Location Access",
  denyButtonLabel: "Not Now",
};

const GeneralLocationPrimerModal: React.FC<GeneralLocationPrimerModalProps> = ({
  isOpen,
  onAllowLocationClick,
  onDeny,
  onDidDismiss,
}) => {
  if (!isOpen) return null;

  const ldClient = useLDClient();

  const urgentShiftsConfig = ldClient?.variation("urgent-shifts-config");

  useEffect(() => {
    if (isOpen) {
      setSeenGeneralPrimerPage();
    }
  }, [isOpen]);

  const pageConfig = (urgentShiftsConfig?.generalPrimerPage ||
    {}) as GeneralPrimerPageLabels;

  const labels = getLabelsFromPageConfig(pageConfig) as GeneralPrimerPageLabels;

  const templateVars = {
    ...labels,
  };

  const getLabel = (key: string): string =>
    key in labels
      ? getReactComponents(key, labels[key], templateVars)
      : defaultGeneralPrimerPageLabels[key];

  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      cssClass="general-primer-modal"
      mode="ios"
      onDidDismiss={() => onDidDismiss()}
      data-testid="general-primer-modal"
    >
      <IonContent>
        <div className="modal-body primer-modal">
          <div className="bunny-icon">
            <img src="assets/logo/bunny.png" alt="Clipboard logo" />
          </div>
          <span className="modal-title">{getLabel("title")}</span>
          <IonText className="message">{getLabel("reasonText")}</IonText>
          <div className="list">
            <div className="item">
              <img
                src="../../assets/icons/dollarsign.circle.fill.svg"
                alt="dollar"
                className="instant-pay-icon"
              />
              <span>{getLabel("instantPayLabel")}</span>
            </div>
            <div className="item">
              <img
                src="../../assets/icons/magnifyingglass.circle.fill.svg"
                alt="search"
                className="urgent-shifts-icon"
              />
              <span>{getLabel("urgentShiftsLabel")}</span>
            </div>
            <div className="item">
              <img
                src="../../assets/icons/building.2.fill.svg"
                alt="building"
                className="commute-module-icon"
              />
              <span>{getLabel("commuteModuleLabel")}</span>
            </div>
          </div>
          <div className="action-container">
            <IonButton
              expand="block"
              color="primary"
              mode="ios"
              className="allow-location-btn"
              onClick={() => onAllowLocationClick?.()}
            >
              {getLabel("allowButtonLabel")}
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              onClick={() => onDeny?.()}
              className="deny-button"
            >
              {getLabel("denyButtonLabel")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default GeneralLocationPrimerModal;
