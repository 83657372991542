import { toastController } from "@ionic/core";

const showToast = async (
  message: string,
  duration?: number,
  position?: "top" | "bottom" | "middle",
  color?: string
) => {
  const toast = await toastController.create({
    message,
    duration: duration || 3000,
    position: position || "bottom",
    color: color || "",
  });
  await toast.present();
};

export { showToast };
