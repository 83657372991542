import styled from "@emotion/styled";

export const Container = styled.div``;
export const Card = styled.div``;
export const Header = styled.div``;
export const Title = styled.span``;
export const Divider = styled.div``;
export const ValidationContainer = styled.div<{ isValid: boolean }>`
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -14px;
  span {
    font-size: 12px;

    color: ${(props) => (props.isValid ? "#18DC68" : "#DC3028")} !important;
  }
  ion-icon {
    padding-top: 2px;
    margin-right: 4px;
    color: ${(props) => (props.isValid ? "#18DC68" : "#DC3028")} !important;
  } */
`;
export const ReferralListContainer = styled.div``;
export const ReferrerInfo = styled.div``;
export const BonusInfo = styled.div``;
export const ReferralCard = styled.div<{ wasPaid?: boolean }>`
  /* ion-icon {
    color: #5DA0FB;
    width: 25px;
    height:25px;
    margin:0 5%;
  }
  ${(props) =>
    props.wasPaid &&
    `span {
      color:#828282 !important;
    }`}
    background-color: ${(props) => (!props.wasPaid ? "#ffffff;" : "#E0E0E0;")}

    font-family: 'SF Pro';
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 20px rgba(156, 156, 156, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%
     */
`;
export const ReferrerName = styled.span``;
export const ReferrerCode = styled.strong``;
export const BonusAmount = styled.span``;
export const BonusStatus = styled.span``;
