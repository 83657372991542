import { CreateAnimation } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { HomeIcon, LocationIcon } from "./icons";

type PropTypes = {
  visibleFacilities: boolean;
  onClick: () => void;
  boundCount: number;
};

export const alertDiv = document.createElement("div");

export const AlertButton = ({
  visibleFacilities,
  onClick,
  boundCount,
}: PropTypes): JSX.Element | null => {
  const animationRef = useRef<CreateAnimation>(null);

  useEffect(() => {
    if (animationRef.current !== null) {
      animationRef.current.animation.play();
    }
  });

  const animation = visibleFacilities
    ? {
        property: "opacity",
        fromValue: "1",
        toValue: "0",
      }
    : {
        property: "opacity",
        fromValue: "0",
        toValue: "1",
      };

  const text =
    boundCount < 3 ? (
      <div>Sorry, no facilities here. Tap to zoom out!</div>
    ) : (
      <div style={{ textAlign: "center", verticalAlign: "middle" }}>
        Sorry, no facilities here. Tap to zoom out, or use the{" "}
        <HomeIcon style={{ verticalAlign: "middle" }} width={16} height={16} />{" "}
        home or{" "}
        <LocationIcon
          style={{ verticalAlign: "middle" }}
          width={16}
          height={16}
        />{" "}
        current location buttons to re-center.
      </div>
    );

  return visibleFacilities ? null : (
    <CreateAnimation ref={animationRef} duration={500} fromTo={animation}>
      <div
        onClick={onClick}
        style={{
          borderRadius: 90,
          marginTop: 10,
          border: "2px solid #5DA0FB",
          padding: "10px 20px",
          background: "white",
          color: "#5DA0FB",
          animation: "fadeinout 4s linear 1 forwards",
        }}
      >
        {text}
      </div>
    </CreateAnimation>
  );
};
