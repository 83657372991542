import React from "react";
import { Link } from "react-router-dom";
import { RouterPath } from "../../routing/constant";
import { supportText } from "../../../constants/ShiftInstructionMessages";

const SupportText = () => (
  <div>
    <p>
      {supportText}
      <Link to={RouterPath.NEED_HELP}>Help Center</Link>
    </p>
  </div>
);

export { SupportText };
