import { useState } from "react";
import { CustomAlert } from "@app/hcpShifts/shiftDetails/alerts";
import { hackAlertChange } from "@app/utils/alertChangeHelper";
import { NFCReadStatus } from "src/lib/interface/src";
import {
  alertBrowserNFCAccessMessage,
  alertCancelEditedTimeMessage,
  alertContactHCFAdminMessage,
  alertNFCAccessMessage,
  alertNFCNotWorkingMessage,
  alertNoNetworkConnectionMessage,
  alertOtherIssueMessage,
  alertSkipNFCScanMessage,
  alertUserToRequestHCFToChangeToBackupMessage,
  getAlertNFCErrorMessage,
} from "./utils/nfcAlertMessage";
export const useAlertsForNFC = () => {
  const [alert, setAlert] = useState<CustomAlert | null>(null);

  const dismissAlert = () => setAlert(null);

  const alertSkipNFCScan = (opts: {
    goBackBtnHandler: () => void;
    acceptNonInstantPayBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Non-InstantPay",
      message: alertSkipNFCScanMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Accept Non-InstantPay",
          handler: opts.acceptNonInstantPayBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertNFCAccess = (opts: {
    openNFCSettingsFn: () => void;
    skipNfcBtnHandler: () => void;
    isEdit?: boolean;
  }) => {
    setAlert({
      header: "NFC Access",
      message: alertNFCAccessMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip NFC Scan",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertNFCAccess(opts);
                  },
                  cancelEditBtnHandler: opts.skipNfcBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipNFCScan({
                  goBackBtnHandler: () => {
                    alertNFCAccess(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipNfcBtnHandler,
                });
              }),
        },
        {
          text: "Grant Access",
          handler: opts.openNFCSettingsFn,
        },
      ],
    });
  };

  const alertNFCError = (opts: {
    nfcHcpToggleEnabled: boolean | undefined;
    showNFCNotWorking: boolean;
    isEdit?: boolean;
    nfcReadStatus: NFCReadStatus;
    tryAgainBtnHandler: () => void;
    skipNfcBtnHandler: () => void;
    nfcNotWorkingYesButtonHandler: () => void;
    okayButtonHandler: () => void;
  }) => {
    let buttons = [
      {
        cssClass: "danger-color",
        text: "Skip NFC Scan",
        handler: opts.isEdit
          ? hackAlertChange(() => {
              alertCancelEditedTime({
                goBackBtnHandler: () => {
                  alertNFCError(opts);
                },
                cancelEditBtnHandler: opts.skipNfcBtnHandler,
              });
            })
          : hackAlertChange(() => {
              alertSkipNFCScan({
                goBackBtnHandler: () => {
                  alertNFCError(opts);
                },
                acceptNonInstantPayBtnHandler: opts.skipNfcBtnHandler,
              });
            }),
      },
      {
        text: "Try Again",
        handler: hackAlertChange(opts.tryAgainBtnHandler),
      },
    ];
    // Add tried NFC scan minumum of 2 times
    if (opts.showNFCNotWorking) {
      buttons.push({
        text: "NFC is not working",
        handler: hackAlertChange(() => {
          if (opts.nfcHcpToggleEnabled) {
            return alertNFCNotWorking({
              yesButtonHandler: opts.nfcNotWorkingYesButtonHandler,
              noButtonHandler: hackAlertChange(() => alertNFCError(opts)),
            });
          }
          return alertContactHCFAdmin({
            okayButtonHandler: opts.okayButtonHandler,
          });
        }),
      });
    }
    setAlert({
      header: "NFC Error",
      message: getAlertNFCErrorMessage(opts.nfcReadStatus),
      buttons,
    });
  };

  const alertBrowserNFCAccess = (opts: { gotItButtonHandler: () => void }) => {
    setAlert({
      header: "Open Browser Settings",
      message: alertBrowserNFCAccessMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItButtonHandler,
        },
      ],
    });
  };

  const alertUserToRequestHCFToChangeToBackup = (opts: {
    gotItBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Device doesn’t support NFC",
      message: alertUserToRequestHCFToChangeToBackupMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItBtnHandler,
        },
      ],
    });
  };

  const alertNoNetworkConnection = (opts: { gotItBtnHandler: () => void }) => {
    setAlert({
      header: "No Network Connection",
      message: alertNoNetworkConnectionMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItBtnHandler,
        },
      ],
    });
  };

  const alertNFCNotWorking = (opts: {
    yesButtonHandler: () => void;
    noButtonHandler: () => void;
  }) => {
    setAlert({
      header: "NFC is Not Working",
      message: alertNFCNotWorkingMessage,
      buttons: [
        {
          text: "Yes",
          handler: opts.yesButtonHandler,
        },
        {
          text: "No",
          handler: opts.noButtonHandler,
        },
      ],
    });
  };

  const alertOtherIssue = (opts: { gotItButtonHandler: () => void }) => {
    setAlert({
      header: "Something went wrong",
      message: alertOtherIssueMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItButtonHandler,
        },
      ],
    });
  };

  const alertContactHCFAdmin = (opts: { okayButtonHandler: () => void }) => {
    setAlert({
      header: "NFC is Not Working",
      message: alertContactHCFAdminMessage,
      buttons: [
        {
          text: "Okay",
          handler: opts.okayButtonHandler,
        },
      ],
    });
  };

  const alertCancelEditedTime = (opts: {
    goBackBtnHandler: () => void;
    cancelEditBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Cancel Edit",
      message: alertCancelEditedTimeMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Do Not Edit Times",
          handler: opts.cancelEditBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  return {
    alert,
    dismissAlert,
    alertNFCAccess,
    alertNFCError,
    alertBrowserNFCAccess,
    alertSkipNFCScan,
    alertUserToRequestHCFToChangeToBackup,
    alertNoNetworkConnection,
    alertNFCNotWorking,
    alertOtherIssue,
    alertCancelEditedTime,
  };
};

export type NFCAlerts = ReturnType<typeof useAlertsForNFC>;
