import { SelectedFile } from "@app/shiftSignature/timecard/model";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { useRef, useState } from "react";
import { SignatureContainerRef } from "../../shiftSignature/model";
import { SignatureContainer } from "../../shiftSignature/signatureContainer";

const SignatureConfirmation = ({
  onSubmit,
  isUploadingImage,
  isShiftTimeEdit,
}: {
  onSubmit: (file: SelectedFile, facilityEmployeeName: string) => void;
  isUploadingImage: boolean;
  isShiftTimeEdit: boolean;
}) => {
  const signaturePad = useRef<SignatureContainerRef>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [signature, setSignature] = useState<string>("");
  const [facilityEmployeeName, setFacilityEmployeeName] = useState<string>("");
  const [isGetEmpNameFlow, setIsGetEmpNameFlow] = useState<boolean>(true);
  const [displayHeight, setDisplayHeight] = useState<number>(
    Math.min(window.innerWidth, window.innerHeight)
  );

  const onContinue = () => {
    setIsGetEmpNameFlow(false);
  };

  const onBack = () => {
    onClear();
    setIsGetEmpNameFlow(true);
  };

  const onClear = () => {
    signaturePad?.current?.clearSignature();
    setDisableSubmit(true);
  };

  const submitFile = () => {
    const file = {
      file: signature,
      type: "png",
    };
    onSubmit(file, facilityEmployeeName);
  };

  const onSignatureUpdated = (updatedSignature: string) => {
    setSignature(updatedSignature);
    setDisableSubmit(!updatedSignature || updatedSignature === "");
  };

  const getSignaturePadHeight = () => {
    const totalHeight = displayHeight - 40;
    const titleHeight = document.getElementsByClassName(
      "signature-pad-name-item"
    )[0]?.clientHeight;
    const buttonsHeight = document.getElementsByClassName(
      "sign-button-container"
    )[0]?.clientHeight;
    return totalHeight - (titleHeight + buttonsHeight + 5);
  };

  return (
    <>
      <IonContent>
        {isGetEmpNameFlow ? (
          <div id="facilityEmpNameSection">
            <div className="timesheet-summary-text signature-confirmation-prompt">
              <span>Please ask a facility employee to</span>
              <br />
              <span>verify your times and sign</span>
            </div>
            <IonItem lines="none" className="signature-employee-division">
              <IonLabel
                position="fixed"
                className="signature-employee-name-label"
              >
                Name
              </IonLabel>
              <IonInput
                className="signature-employee-name signature-employee-input"
                placeholder="Facility Employee Name"
                value={facilityEmployeeName}
                type="text"
                onIonChange={(e) =>
                  setFacilityEmployeeName(
                    e.detail.value != undefined ? e.detail.value : ""
                  )
                }
                clearInput
              ></IonInput>
            </IonItem>
            <IonButton
              fill="solid"
              shape="round"
              color="primary"
              disabled={!/[a-zA-Z]/.test(facilityEmployeeName)}
              className="ion-margin sign-button sign-confirmation-continue-button"
              onClick={onContinue}
            >
              Continue
            </IonButton>
          </div>
        ) : (
          <div id="facilityEmpSignSection">
            <div className="signature-pad-name-item">
              <p className="signature-pad-name">
                {facilityEmployeeName}
                <span>, please sign below.</span>
              </p>
            </div>
            <div className="signature-pad-canvas">
              <SignatureContainer
                ref={signaturePad}
                onChange={onSignatureUpdated}
                getHeight={getSignaturePadHeight}
              />
            </div>
            <div className="sign-button-container">
              <IonButton
                fill="outline"
                shape="round"
                className="ion-margin sign-button"
                onClick={onBack}
              >
                Back
              </IonButton>
              <IonButton
                fill="outline"
                shape="round"
                className="ion-margin sign-button"
                onClick={onClear}
              >
                Clear
              </IonButton>
              <IonButton
                shape="round"
                color="primary"
                className="ion-margin sign-button"
                onClick={submitFile}
                disabled={disableSubmit || isUploadingImage || isShiftTimeEdit}
              >
                <IonSpinner
                  hidden={!isUploadingImage}
                  slot="start"
                  name="crescent"
                />
                {!isUploadingImage ? "Submit" : "Submitting"}
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
    </>
  );
};

export { SignatureConfirmation };
