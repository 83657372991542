import React from "react";
import { IonImg } from "@ionic/react";

const BackgroundImageLayout: React.FC = () => {
  return (
    <div className="container-fluid assets-container">
      <div className="sky-container">
        <IonImg
          className="background-asset cloud-one"
          src="../../assets/images/lastMinuteShiftsBg/Cloud1.svg"
        />
        <IonImg
          className="background-asset cloud-two"
          src="../../assets/images/lastMinuteShiftsBg/Cloud2.svg"
        />
      </div>
      <div className="land-container">
        <div className="building-tree-container">
          <IonImg
            className="background-asset"
            src="../../assets/images/lastMinuteShiftsBg/B2.svg"
          />
          <IonImg src="../../assets/images/lastMinuteShiftsBg/T2.svg" />
        </div>
        <div className="building-tree-container">
          <IonImg src="../../assets/images/lastMinuteShiftsBg/T1.svg" />
          <IonImg
            className="background-asset"
            src="../../assets/images/lastMinuteShiftsBg/B1.svg"
          />
        </div>
      </div>
    </div>
  );
};

export { BackgroundImageLayout };
