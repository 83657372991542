import React, { useState, useEffect } from "react";
import { IonItem, IonToggle } from "@ionic/react";
import { ToggleChangeEventDetail } from "@ionic/core";

const SedationForm = ({
  hasSedationExperience,
  experience,
  updateDetails,
  agentId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [localSedation, setLocalSedation] = useState<boolean>(false);

  useEffect(() => {
    setLocalSedation(hasSedationExperience);
  }, [hasSedationExperience]);

  const onSedationChanged = async (
    event: CustomEvent<ToggleChangeEventDetail>
  ) => {
    const { checked } = event.detail;
    try {
      setLocalSedation(checked);
      setLoading(true);
      const specialities = {
        experience,
        hasSedationExperience: checked,
        hasTrayAssemblyExperience: false,
        experienceDetails: {},
      };
      await updateDetails(specialities, agentId);
    } catch (error) {
      setLocalSedation(!checked);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IonItem lines="none">
        Conscious / Moderate Sedation
        <IonToggle
          slot="end"
          color="primary"
          disabled={loading}
          checked={localSedation}
          onIonChange={onSedationChanged}
        />
      </IonItem>
    </>
  );
};

export default SedationForm;
