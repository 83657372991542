const workerMobilePrefix = "Worker Mobile:";

export const USER_EVENTS = {
  APP_LAUNCHED: `${workerMobilePrefix} App Launched`,
  APP_RESTORED: `${workerMobilePrefix} App Restored`,
  TAPPED_SIGN_IN: `${workerMobilePrefix} Sign In Tapped`,
  TAPPED_SIGN_UP: `${workerMobilePrefix} Sign Up Tapped`,
  TAPPED_LEARN_MORE: `${workerMobilePrefix} Learn More Tapped`,
  ENTERED_SIGN_IN_CREDENTIAL: `${workerMobilePrefix} Sign In Credential Entered`,
  ENTERED_SIGN_UP_CREDENTIAL: `${workerMobilePrefix} Sign Up Credential Entered`,
  ENTERED_TWO_FACTOR_CODE: `${workerMobilePrefix} Two-Factor Code Entered`,
  TWO_FACTOR_CODE_ACCEPTED: `${workerMobilePrefix} Two-Factor Code Accepted`,
  TWO_FACTOR_CODE_DENIED: `${workerMobilePrefix} Two-Factor Code Denied`,
  SIGNED_IN: `${workerMobilePrefix} Signed In`,
  SIGNED_UP: `${workerMobilePrefix} Signed Up`,
  TAPPED_LOGOUT: `${workerMobilePrefix} Logout Tapped`,
  LOGGED_OUT: `${workerMobilePrefix} Logged Out`,
  VIEWED_OPEN_SHIFTS: `${workerMobilePrefix} Open Shifts Viewed`,
  VIEW_CALENDAR: `${workerMobilePrefix} Calendar Viewed`,
  VIEW_MAP: `${workerMobilePrefix} Map Viewed`,
  TAP_FACILITY_PIN: `${workerMobilePrefix} Map Facility Pin Tapped`,
  MOVED_MAP: `${workerMobilePrefix} Map Moved`,
  MARKED_INTEREST_IN_SHIFT: `${workerMobilePrefix} Marked Interest in Shift`,
  TAPPED_LAST_MINUTE_SHIFTS_BANNER: `${workerMobilePrefix} Last Minute Shifts Banner Tapped`,
  VIEWED_LAST_MINUTE_SHIFTS: `${workerMobilePrefix} Last Minute Shifts Viewed`,
  SUCCESSFULLY_INSTANT_BOOKED_LAST_MINUTE_SHIFT: `${workerMobilePrefix} Shift Booked`,
  SUCCESSFULLY_BOOKED_LAST_MINUTE_SHIFT: `${workerMobilePrefix} Shift Booked`,
  DISCOVERED_AN_INSTANT_BOOK_SHIFT: `${workerMobilePrefix} Instant Book Shift Discovered`,
  TAPPED_INSTANT_BOOK: `${workerMobilePrefix} Book Shift Tapped`,
  TAPPED_CONFIRM_INSTANT_BOOK: `${workerMobilePrefix} Shift Booked`,
  SUCCESSFULLY_INSTANT_BOOKED_A_SHIFT: `${workerMobilePrefix} Shift Booked`,
  VIEWED_MY_SHIFTS: `${workerMobilePrefix} My Shifts Viewed`,
  VIEWED_UNVERIFIED_SHIFTS: `${workerMobilePrefix} Unverified Shifts Viewed`,
  SHIFT_OPENED: `${workerMobilePrefix} Shift Opened`,
  TAPPED_GO_TO_OPEN_SHIFTS: `${workerMobilePrefix} Go to Open Shifts Tapped`,
  VIEWED_ONGOING_SHIFTS: `${workerMobilePrefix} Ongoing Shifts Viewed`,
  TAPPED_ONGOING_SHIFT: `${workerMobilePrefix} Ongoing Shift Tapped`,
  TAPPED_UPCOMING_SHIFT: `${workerMobilePrefix} Upcoming Shift Tapped`,
  SUBMITTED_FACILITY_REVIEW: `${workerMobilePrefix} Facility Review Submitted`,
  TAPPED_TO_UPLOAD_TIMECARD_PHOTO: `${workerMobilePrefix} Upload Timecard Photo Tapped`,
  TAPPED_TO_UPLOAD_INSTANTPAY_TIMECARD_PHOTO: `${workerMobilePrefix} Upload Timecard Photo Tapped`,
  SUBMITTED_INSTANTPAY_TIMECARD_PHOTO: `${workerMobilePrefix} Instant Timesheet Uploaded`,
  TAPPED_GET_SHIFT_SIGNED_OFF: `${workerMobilePrefix} Get Shift Signed Off Tapped`,
  SHIFT_SIGNED_OFF: `${workerMobilePrefix} Shift Signed Off`,
  TAPPED_COMPLETE_SHIFT: `${workerMobilePrefix} Shift Completed`,
  VIEWED_ACCOUNT: `${workerMobilePrefix} My Account Viewed`,
  VIEWED_CONNECTION_ISSUE: `${workerMobilePrefix} Connection Issue Viewed`,
  TAPPED_REFRESH: `${workerMobilePrefix} Refresh Tapped`,
  TAPPED_CALENDAR_DATE: `${workerMobilePrefix} Calendar Date Tapped`,
  VIEWED_CALENDAR_DATE_SHIFTS: `${workerMobilePrefix} Shifts on Date Viewed`,
  VIEWED_FACILITY_PROFILE: `${workerMobilePrefix} Facility Profile Viewed`,
  VIEWED_FACILITY_SHIFTS: `${workerMobilePrefix} Facility Shifts Viewed`,
  VIEWED_DISTANCE_PREFERENCE: `${workerMobilePrefix} Distance Preference Viewed`,
  UPDATED_DISTANCE_PREFERENCE: `${workerMobilePrefix} Distance Preference Updated`,
  OPEN_DISTANCE_PREFERENCE_MODAL: `${workerMobilePrefix} Distance Preference Edited`,
  MINIMUM_PAY_PREFERENCE_EDITED: `${workerMobilePrefix} Minimum Pay Preference Edited`,
  MINIMUM_PAY_PREFERENCE_UPDATED: `${workerMobilePrefix} Minimum Pay Preference Updated`,
  VIEWED_DOCUMENTS: `${workerMobilePrefix} Documents Viewed`,
  VIEWED_PAYROLL: `${workerMobilePrefix} Payroll Viewed`,
  VIEWED_ADDRESS: `${workerMobilePrefix} Address Viewed`,
  UPDATED_ADDRESS: `${workerMobilePrefix} Address Updated`,
  VIEWED_PROFILE: `${workerMobilePrefix} Profile Viewed`,
  UPDATED_PROFILE_PHOTO: `${workerMobilePrefix} Profile Photo Updated`,
  VIEWED_REFER_A_CO_WORKER: `${workerMobilePrefix} Refer a Co-worker Viewed`,
  TAPPED_LINK_TO_REFER_A_CO_WORKER: `${workerMobilePrefix} Co-Worker Referred`,
  REFERRAL_LINK_ERROR: `${workerMobilePrefix} Referral Link Error Occurred`,
  VIEWED_HELP: `${workerMobilePrefix} Help Viewed`,
  TAPPED_HELP_CALL: `${workerMobilePrefix} Help - Call Tapped`,
  TAPPED_HELP_EMAIL: `${workerMobilePrefix} Help - Email Tapped`,
  PUSH_NOTIFICATION_RECEIVED: `${workerMobilePrefix} Push Notification Received`,
  PUSH_NOTIFICATION_TAPPED: `${workerMobilePrefix} Push Notification Tapped`,
  DEEP_LINK_OPENED: `${workerMobilePrefix} Deep Link Opened`,
  MISSED_INSTANTPAY_CLOCK_IN: `${workerMobilePrefix} InstantPay Clock In Missed`,
  TOOK_TIMESHEET_PHOTO: `${workerMobilePrefix} Timesheet Photo Taken`,
  CHOSE_TIMESHEET_PHOTO: `${workerMobilePrefix} Timesheet Photo Chosen`,
  APPLICATION_CRASHED: `${workerMobilePrefix} App Crashed`,
  API_FAILURE: `${workerMobilePrefix} API Failed`,
  TAPPED_OPEN_MAPS: `${workerMobilePrefix} Open In Maps Tapped`,
  TAPPED_GOOGLE_MAPS: `${workerMobilePrefix} Open Google Maps Tapped`,
  TAPPED_APPLE_MAPS: `${workerMobilePrefix} Open Apple Maps Tapped`,
  VIEWED_CHECK_IN_INSTRUCTIONS: `${workerMobilePrefix} Check In Instructions Viewed`,
  VIEWED_COVID_STATUS: `${workerMobilePrefix} COVID-19 Status Viewed`,
  TAPPED_BOOK_SHIFT: `${workerMobilePrefix} Book Shift Tapped`,
  VIEWED_BOOK_SHIFTS: `${workerMobilePrefix} Book Shifts Viewed`,
  VIEWED_CBH_MISSING_DOCS_POPUP: `${workerMobilePrefix} Missing Docs Alert - CBH Viewed`,
  VIEWED_CONFIRM_BOOK_POPUP: `${workerMobilePrefix} Shift Confirm Viewed`,
  VIEWED_CONFIRM_INSTANTBOOK_POPUP: `${workerMobilePrefix} Shift Confirm Viewed`,
  BOOKED_A_SHIFT: `${workerMobilePrefix} Shift Booked`,
  VIEWED_TERMS: `${workerMobilePrefix} Terms and Agreements Viewed`,
  VIEWED_CONTRACT_COMPONENT: `${workerMobilePrefix} Onboarding - Contract Component Viewed`,
  SIGNED_CONTRACT: `${workerMobilePrefix} Onboarding - Contract Signed`,
  ENTERED_SSN: `${workerMobilePrefix} Onboarding - SSN Entered`,
  VIEWED_STRIPE_SET_UP: `${workerMobilePrefix} Stripe Setup Viewed`,
  STARTED_STRIPE_SET_UP: `${workerMobilePrefix} Stripe Set Up Started`,
  DISMISSED_STRIPE_PROMPT: `${workerMobilePrefix} Stripe Prompt Dismissed`,
  COMPLETED_STRIPE_FLOW: `${workerMobilePrefix} Stripe Flow Completed`,
  INSTANT_PAYOUT_REQUESTED: `${workerMobilePrefix} Instant Payout Requested`,
  INSTANT_PAYOUT_CONFIRMED: `${workerMobilePrefix} Instant Payout Confirmed`,
  INSTANT_PAYOUT_REJECTED: `${workerMobilePrefix} Instant Payout Rejected`,
  AUTO_WITHDRAW_REQUESTED: `${workerMobilePrefix} Auto Withdraw Requested`,
  AUTO_WITHDRAW_CONFIRMED: `${workerMobilePrefix} Auto Withdraw Confirmed`,
  AUTO_WITHDRAW_REJECTED: `${workerMobilePrefix} Auto Withdraw Rejected`,
  AUTO_WITHDRAW_DISABLED: `${workerMobilePrefix} Auto Withdraw Disabled`,
  ONBOARDING_ERROR: `${workerMobilePrefix} Self-Onboarding Error Occurred`,
  VIEWED_FACILITY_RATINGS: `${workerMobilePrefix} Facility Ratings Viewed`,
  FACILITY_REVIEW_FILTERED: `${workerMobilePrefix} Facility Review Filtered`,
  GENERATE_PROOF_INCOME: `${workerMobilePrefix} Proof Of Income Generated`,
  EMAIL_PAYSTUB: `${workerMobilePrefix} Paystub Emailed`,
  NEED_HELP_PROOF_INCOME: `${workerMobilePrefix} Need Help on Proof Of Income Tapped`,
  BOOK_INSTANTBOOK_SHIFT: `${workerMobilePrefix} Shift Booked`,
  REQUEST_NON_INSTANTBOOK_SHIFT: `${workerMobilePrefix} Non-InstantBook Shift Requested`,
  CLOCK_IN: `${workerMobilePrefix} Clocked In`,
  START_BREAK: `${workerMobilePrefix} Break Started`,
  END_BREAK: `${workerMobilePrefix} Break Ended`,
  CLOCK_OUT: `${workerMobilePrefix} Clocked Out`,
  VIEWED_WELCOME_TO_INSTANTPAY: `${workerMobilePrefix} Welcome to InstantPay Modal Viewed`,
  COMPRESS_TIMESHEET_PHOTO_FAILED: `${workerMobilePrefix} Compress Timesheet Photo Failed`,
  UPLOAD_TIMESHEET_STARTED: `${workerMobilePrefix} Timesheet Upload Started`,
  UPLOAD_TIMESHEET_SUCCESS: `${workerMobilePrefix} Timesheet Upload Succeeded`,
  UPLOAD_TIMESHEET_FAILED: `${workerMobilePrefix} Timesheet Upload Failed`,
  UPLOAD_CLOUDINARY_SUCCESS: `${workerMobilePrefix} Cloudinary Upload Succeeded`,
  UPLOAD_API_SUCCESS: `${workerMobilePrefix} API Upload Succeeded`,
  VIEWED_EXTRA_TIME_PAY_ALERT: `${workerMobilePrefix} Extra Time Pay Modal Viewed`,
  VIEWED_EXTRA_TIME_PAY_PANEL: `${workerMobilePrefix} Extra Time Pay Panel Viewed`,
  CONTACT_STRIPE_CLICKED: `${workerMobilePrefix} Contact Stripe Clicked`,
  PROFILE_CHANGE_STARTED: `${workerMobilePrefix} Profile Edited`,
  PROFILE_CHANGE_COMPLETED: `${workerMobilePrefix} Profile Updated`,
  DISTANCE_REDIRECT: `${workerMobilePrefix} Distance Redirect Tapped`,
  TAPPED_UPLOAD_REQUIRED_DOCS: `${workerMobilePrefix} Missing Docs Alert - Upload Required Docs Tapped`,
  TAPPED_UPLOAD_MISSING_DOCS_POPUP_BUTTON: `${workerMobilePrefix} Missing Docs Alert - Upload Docs Tapped`,
  TAPPED_CANCEL_MISSING_DOCS_POPUP_BUTTON: `${workerMobilePrefix} Missing Docs Alert - Cancel Tapped`,
  SUCCESSFULLY_UPLOADED_DOCUMENT: `${workerMobilePrefix} Document Upload Succeeded`,
  DOCUMENT_UPLOAD_FAILED: `${workerMobilePrefix} Document Upload Failed`,
  INSTANT_BOOK_FAILURE_AVAILABLE_VIEWED: `${workerMobilePrefix} InstantBook Failure Message - Available Viewed`,
  INSTANT_BOOK_FAILURE_AVAILABLE_NONE: `${workerMobilePrefix} InstantBook Failure Message - Available None`,
  TIMESHEET_PHOTO_FORGOTTEN: `${workerMobilePrefix} Timesheet Photo Forgotten`,
  TIMECARD_SENT_ANOTHER_WAY: `${workerMobilePrefix} Timecard Sent Another Way`,
  SHIFT_NOT_ATTENDED: `${workerMobilePrefix} Shift Not Attended`,
  CHAT_STARTED: `${workerMobilePrefix} Chat Started`,
  REQUESTED_SIGNATURE_FROM_HCF: `${workerMobilePrefix} HCF Signature Requested`,
  TAPPED_INSTANTBOOK_SHIFT: `${workerMobilePrefix} Book Shift Tapped`,
  REVIEW_AND_SIGN_CANCEL: `${workerMobilePrefix} IC Agreement Cancelled`,
  API_ERROR_RETRY: `${workerMobilePrefix} API Call Retried`,
  VIEWED_URGENT_SHIFTS: `${workerMobilePrefix} Urgent Shifts Viewed`,
  LICENSES_VIEWED: `${workerMobilePrefix} Licenses Viewed`,
  ADD_LICENSE_TAPPED: `${workerMobilePrefix} Add License Tapped`,
  LICENSE_VIEWED: `${workerMobilePrefix} License Viewed`,
  LICENSE_REMOVED: `${workerMobilePrefix} License Removed`,
  LICENSE_SUBMITTED: `${workerMobilePrefix} License Submitted`,
  STRIPE_IDENTITY_FLOW_BEGUN: `${workerMobilePrefix} Stripe Identity Flow Begun`,
  STRIPE_IDENTITY_FLOW_COMPLETED: `${workerMobilePrefix} Stripe Identity Flow Completed`,
  TRIP_TRACKING_STARTED_ON_APP_OPEN: `${workerMobilePrefix} Trip Tracking Started on App Open`,
  TRIP_TRACKING_CALLED_ON_APP_OPEN: `${workerMobilePrefix} Trip Tracking Called on App Open`,
  TRIP_TRACKING_STARTED_ON_SILENT_PUSH: `${workerMobilePrefix} Trip Tracking Started on Silent Push`,
};
