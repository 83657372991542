export const licenseTypes = [
  {
    type: "CNA",
    name: "Certified Nursing Assistant",
    value: "CNA",
  },
  {
    type: "LVN / LPN",
    name: "Licensed Vocational Nurse / Licensed Practical Nurse",
    value: "LVN",
  },
  {
    type: "RN",
    name: "Registered Nurse",
    value: "RN",
  },
  {
    type: "CRNA",
    name: "Certified RN Anesthetist",
    value: "CRNA",
  },
  {
    type: "NP",
    name: "Nurse Practitioner",
    value: "NP",
  },
  {
    type: "PACU RN",
    name: "Post-anesthesia care unit",
    value: "PACU RN",
  },
  {
    type: "OR RN",
    name: "Operating Room RN",
    value: "OR RN",
  },
  {
    type: "SPD TECH",
    name: "Sterile Processing Technician",
    value: "SPD TECH",
  },
  {
    type: "SURG TECH",
    name: "Surgical Technician",
    value: "SURG TECH",
  },
  {
    type: "MLT",
    name: "Medical Lab Technicians",
    value: "MLT",
  },
  {
    type: "Phlebotomist",
    name: "Phlebotomist",
    value: "Phlebotomist",
  },
  {
    type: "Medical Assistant",
    name: "Medical Assistant",
    value: "Medical Assistant",
  },
  {
    type: "",
    name: "Caregiver",
    value: "CAREGIVER",
  },
  {
    type: "QMAP",
    name: "Qualified Medication Administration Personnel",
    value: "QMAP",
  },
  {
    type: "Non Clinical",
    name: "Non Clinical",
    value: "Non Clinical",
  },
  {
    type: "Site Lead",
    name: "Site Lead",
    value: "Site Lead",
  },
  {
    type: "other",
    name: "Other",
    value: "other",
  },
];

export const otherLicenseTypes = [
  {
    value: "Physical Therapist",
    type: "PT",
  },
  {
    value: "Physical Therapist Assistant",
    type: "PTA",
  },
  {
    value: "Home Health Aide",
    type: "HHA",
  },
  {
    value: "Emergency Medical Technician",
    type: "EMT",
  },
  {
    value: "Respiratory Therapist",
    type: "Respiratory Therapist",
  },
  {
    value: "Paramedic",
    type: "Paramedic",
  },
  {
    value: "Pharmacy Technician",
    type: "Pharm Tech",
  },
  {
    value: "Medical Technician",
    type: "Medical Technician",
  },
  {
    type: "Medical Aide",
    value: "Medical Aide",
  },
];
