import {
  IonButton,
  IonCard,
  IonGrid,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonText,
  isPlatform,
} from "@ionic/react";
import { camera, cloudUpload, openOutline } from "ionicons/icons";
import React, { Fragment, useRef } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CameraSource } from "@capacitor/camera";

import { AgentUploadDocumentProps } from "./model";
import { CustomContainer } from "../../documents/style";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { isImage } from "../../utils/isImage";
import { capturePhoto } from "@app/utils/mediaUpload";
import { logOnboardingError } from "../util/logging";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { FeatureFlag } from "../../../constants/FEATURE_FLAGS";
import {
  DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID,
  STRIPE_IDENTIFY_SDK,
} from "src/constants/stripeDocumentAutoVerification.constants";

const AgentDocumentUploader: React.FC<AgentUploadDocumentProps> = ({
  agent,
  document,
  uploading,
  uploaded,
  selectedFiles = [],
  setSelectedFiles,
  clearSelectedFiles,
  handleFileUpload,
  handleStripeUpload,
  stripeLoading,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const ldFlags = useFlags();
  const cameraImageQuality = ldFlags[FeatureFlag.CAMERA_IMAGE_QUALITY];
  const isFileStorageDualWriteEnabled =
    ldFlags[FeatureFlag.DUAL_WRITES_FILE_STORAGE_SERVICE];
  const selectedFilesExist = selectedFiles?.length > 0;
  const canUploadMultiplePages = isImage(selectedFiles[0]?.type);
  const docVerificationProcess =
    ldFlags[FeatureFlag.DOCUMENT_VERIFICATION_PROCESS];

  const stripeAutoVerifyEnabled =
    docVerificationProcess === STRIPE_IDENTIFY_SDK &&
    document.reqId === DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID;

  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) return;

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFiles({
        file: reader.result,
        ...(isFileStorageDualWriteEnabled ? { fileBlob: file } : {}),
        type,
      });
    };
    event.target.value = null;
  };

  const openGallery = async () => {
    try {
      const { file, fileBlob, type } = await capturePhoto(
        cameraImageQuality || 85,
        CameraSource.Prompt,
        isFileStorageDualWriteEnabled
      );
      setSelectedFiles({ file, fileBlob, type });
    } catch (error) {
      logOnboardingError(
        ONBOARDING_STAGES.DOCUMENTS,
        (error as Error).message,
        agent?.userId
      );
    }
  };

  const handleTakePhotoClick = () => {
    fireOnboardingSegmentEvent(
      ONBOARDING_SEGMENT_EVENT_NAMES.ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP,
      {
        hcpId: agent.userId as string,
        preference_distance: agent?.preference?.distance,
        requirementId: document._id,
      }
    );
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery() : inputFileRef?.current?.click();
  };

  return (
    <IonGrid
      style={{ display: "flex", flexDirection: "column", padding: "20px" }}
    >
      <IonLabel>REQUIREMENTS</IonLabel>
      <IonText>
        <p style={{ color: "#828282", fontSize: "14px" }}>
          Add a photo of your {document.name}
        </p>
      </IonText>
      {selectedFilesExist && (
        <Fragment>
          {selectedFiles.map((selectedFile) => (
            <IonCard>
              {isImage(selectedFile.type) ? (
                <img alt="timecard" src={selectedFile.file} />
              ) : (
                <CustomContainer>
                  {selectedFile.type
                    ? selectedFile.type.toUpperCase()
                    : "Document"}
                </CustomContainer>
              )}
            </IonCard>
          ))}
        </Fragment>
      )}

      {uploading && (
        <IonButton expand="block" color="primary" disabled={true}>
          <IonSpinner slot="start" name="crescent" />
          Uploading
        </IonButton>
      )}

      {!uploaded && (
        <Fragment>
          {stripeAutoVerifyEnabled ? (
            <IonButton
              color="primary"
              mode="ios"
              disabled={stripeLoading}
              onClick={() => {
                handleStripeUpload(document);
              }}
            >
              {stripeLoading ? (
                <IonSpinner slot="start" name="crescent" />
              ) : (
                <>
                  <IonIcon slot="start" icon={openOutline} mode="ios" />
                  <IonText color="light">{"Verify ID with Stripe"}</IonText>
                </>
              )}
            </IonButton>
          ) : (
            <>
              {!selectedFilesExist && (
                <IonButton
                  style={{ alignSelf: "center" }}
                  onClick={handleTakePhotoClick}
                >
                  <IonIcon mode="ios" icon={camera}></IonIcon>
                  <span style={{ padding: "0 5px" }}> Add Photo</span>
                </IonButton>
              )}

              {!uploading && selectedFilesExist && !canUploadMultiplePages && (
                <IonButton
                  expand="block"
                  color="primary"
                  disabled={uploading}
                  onClick={() => {
                    handleFileUpload(document);
                  }}
                >
                  <IonIcon
                    slot="start"
                    icon={uploaded ? camera : cloudUpload}
                    mode="ios"
                  />
                  Upload
                </IonButton>
              )}

              {!uploading && selectedFilesExist && canUploadMultiplePages && (
                <div className="ion-text-center ion-margin">
                  <IonButton onClick={handleTakePhotoClick}>
                    <IonIcon slot="start" icon={camera} mode="ios" />
                    Add more pages
                  </IonButton>

                  <IonButton
                    onClick={() => {
                      handleFileUpload(document);
                    }}
                  >
                    <IonIcon slot="start" icon={cloudUpload} mode="ios" />
                    Finish and upload
                  </IonButton>
                </div>
              )}

              {!uploading && selectedFilesExist && (
                <IonButton
                  color="light"
                  mode="ios"
                  onClick={clearSelectedFiles}
                >
                  <span
                    style={{
                      color: "#727272",
                      borderBottom: "1px solid #727272",
                      paddingBottom: "3px",
                      fontSize: "1rem",
                    }}
                  >
                    Delete {selectedFiles.length > 1 ? "Files" : "File"}
                  </span>
                </IonButton>
              )}
            </>
          )}
        </Fragment>
      )}

      <input
        type="file"
        hidden
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </IonGrid>
  );
};

export { AgentDocumentUploader };
