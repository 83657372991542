import styled from "@emotion/styled";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { colors } from "./colors";
import { PayrollItemProps } from "./models";
import { chevronForward } from "ionicons/icons";

const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const BubbleContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 0px 20px rgba(156, 156, 156, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

export const PayrollItem: React.FC<PayrollItemProps> = ({
  icone,
  name,
  onClick,
}) => {
  return (
    <BubbleContainer
      className="bubbleContainer"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ padding: 8 }}>
          <IonCol size="2" style={center}>
            <div className="icon-background">
              <IonIcon icon={icone} />
            </div>
          </IonCol>
          <IonCol size="8" style={{ textAlign: "left" }}>
            <h1 style={{ color: colors.gray1, fontSize: 18, marginTop: 8 }}>
              {name}
            </h1>
          </IonCol>

          <IonCol size="2" style={center}>
            <IonIcon style={{ color: "gray" }} icon={chevronForward} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BubbleContainer>
  );
};
