import { Balance } from "./models";
import { logEvent } from "src/lib/analytics";
import { PayrollLineItem, BonusList } from "src/lib/interface";
import { RefresherEventDetail } from "@ionic/core";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { Store } from "../store/store.model";
import { Summary } from "./summary";
import { USER_EVENTS } from "../../constants/userEvents";
import { useSelector } from "react-redux";
import {
  fetchBalance,
  fetchLifeTimeEarnings,
  fetchPayrollData,
  getShiftsDetailsForBonuses,
} from "./api";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { groupBy, keyBy, last, map, sumBy, uniq, flatMap } from "lodash";
import moment from "moment-timezone";

const PayrollNewPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [balance, setBalance] = useState<Balance>();
  const [payroll, setPayroll] = useState<PayrollLineItem[]>([]);
  const [relativeWeek, setRelativeWeek] = useState<number>(0);
  const [earnings, setEarnings] = useState<number | undefined>(0);

  const { networkStatus } = useSelector((state: Store) => state.session);

  const getPayrollData = useCallback(async () => {
    const { shifts, bonuses } = await fetchPayrollData({ relativeWeek });
    const shiftIds = uniq(
      flatMap(bonuses, (bonus) =>
        bonus.type === "TBIO.SINGLE" ? [bonus.shiftId] : bonus.group
      )
    );
    const shiftDetails = await getShiftsDetailsForBonuses(
      shiftIds,
      "name facility.tmz start end"
    );

    const shiftsById = keyBy(shiftDetails, "_id");
    const groupedBonuses = groupBy(bonuses, (bonus) =>
      bonus.type === "TBIO.BACK_TO_BACK"
        ? bonus.group.join("-")
        : bonus.type === "TBIO.SINGLE"
        ? bonus.shiftId
        : bonus._id
    );

    const formattedBonuses = map(groupedBonuses, (group, id) => {
      const [bonus] = group;

      let status = "PENDING";
      if (group.every((bonus) => bonus.status === "PAID")) {
        status = "PAID";
      } else if (group.some((bonus) => bonus.status === "PAID")) {
        status = "PARTIAL";
      }

      let reason = bonus.reason;
      let amount = bonus.amount;
      if (bonus.reason === "Extra Time Pay") {
        reason = "";
        if (bonus.type === "TBIO.SINGLE") {
          reason += `${moment(bonus.payPeriodStart).format(
            "MM/DD"
          )} ${shiftsById[bonus.shiftId].name.toUpperCase()} shift`;
          amount = sumBy(group, "amount");
        } else if (bonus.type === "TBIO.BACK_TO_BACK") {
          const info = bonus.group.reduce((res, cur) => {
            const shift = shiftsById[cur];
            const period = moment(shift.start).format("MM/DD");
            res[period] = res[period] || [];
            res[period].push(shift.name);
            return res;
          }, {});
          reason += `${map(
            info,
            (shiftNames, period) => `${period} ${uniq(shiftNames).join(" & ")}`
          )
            .join(" & ")
            .toUpperCase()} back-to-back shifts`;
          amount = sumBy(group, "amount");
        } else {
          reason += `40+ hours ${moment(bonus.payPeriodStart).format(
            "MM/DD/YY"
          )} - ${moment(bonus.payPeriodEnd).format("MM/DD/YY")}`;
        }
      }

      return {
        _id: id,
        amount,
        reason,
        status,
        createdAt: last(group).createdAt,
        shiftId: last(group).shiftId,
        type: "BONUS",
        originalReason: bonus.reason,
      };
    }) as BonusList[];
    const filteredBonuses = formattedBonuses.filter(
      (bonus) => bonus.status !== "REVERSED"
    );

    const payrollLineItems: PayrollLineItem[] = [];
    const lineItemsByShiftId = groupBy(filteredBonuses, "shiftId");
    shifts.forEach((shift) => {
      payrollLineItems.push({ type: "SHIFT", ...shift });
      if (lineItemsByShiftId[shift._id]) {
        payrollLineItems.push(...lineItemsByShiftId[shift._id]);
        delete lineItemsByShiftId[shift._id];
      }
    });
    payrollLineItems.push(
      ...(Object.values(lineItemsByShiftId).flat() as PayrollLineItem[])
    );
    setPayroll(payrollLineItems);
  }, [relativeWeek]);

  const fetchAndSetBalance = async () => {
    const balance = await fetchBalance();
    setBalance(balance);
    setLoading(false);
  };

  const getLifeTimeEarnings = async () => {
    const { summary } = await fetchLifeTimeEarnings();
    setEarnings(summary.total);
  };

  useEffect(() => {
    fetchAndSetBalance();
    getLifeTimeEarnings();
  }, []);

  useEffect(() => {
    getPayrollData();
  }, [relativeWeek, getPayrollData]);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_PAYROLL);
  });

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await Promise.all([
      fetchAndSetBalance(),
      getLifeTimeEarnings(),
      getPayrollData(),
    ]);
    event.detail.complete();
  };

  const handleBackClick = () => {
    setRelativeWeek(relativeWeek - 1);
  };
  const handleForwardClick = () => {
    setRelativeWeek(relativeWeek + 1);
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Payroll</IonTitle>
        </IonToolbar>
      </IonHeader>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Summary
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              items={payroll}
              balance={{
                ...(balance as Balance),
                lifetimeEarnings: earnings as number,
              }}
              setBalance={setBalance}
              relativeWeek={relativeWeek}
              loading={loading}
            />
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export { PayrollNewPage };
