import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { Market } from "@ionic-native/market";
import { isPlatform } from "@ionic/core";
import React, { useEffect, useState } from "react";
import semver from "semver";
import { fetchVersionInfo } from "../appVersion/api";
import { VersionInfoResponse } from "../appVersion/model";

export const Version = (): JSX.Element | null => {
  const [appVersion, setAppVersion] = useState("");
  const [latestVersion, setLatestVersion] = useState<VersionInfoResponse>();
  const isNative = isPlatform("capacitor");

  const isLatest = () => {
    const mobileVersion = latestVersion?.latestMobileVersion?.mobileVersion;
    if (mobileVersion?.length && appVersion?.length)
      return !semver.gt(mobileVersion, appVersion);
    else return true;
  };

  useEffect(() => {
    (async () => {
      if (isNative) {
        const { version } = await App.getInfo();
        setAppVersion(version);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { platform } = await Device.getInfo();

      if (appVersion) {
        const lastVer = await fetchVersionInfo(appVersion, platform);
        setLatestVersion(lastVer);
      }
    })();
  }, [appVersion]);

  const handleClick = async () => {
    if (isPlatform("android")) {
      await Market.open("health.clipboard.worker");
    } else if (isPlatform("ios") || isPlatform("ipad")) {
      await Market.open("id1472962791");
    }
  };

  return isNative ? (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        marginTop: 24,
        marginBottom: 80,
        flexDirection: "column",
      }}
    >
      <span style={{ color: "#BDBDBD" }}>version {appVersion}</span>
      {!isLatest() && (
        <span onClick={handleClick} style={{ color: "#679DE5" }}>
          new update available
        </span>
      )}
    </div>
  ) : null;
};
