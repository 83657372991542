import "./style.scss";
import { CategoryButton } from "../ratingCategory";
import { DetailReviewProps } from "../model";
import React from "react";
import { CreateAnimation, IonCardContent, IonLabel } from "@ionic/react";

const DetailReview: React.FC<DetailReviewProps> = ({
  feedbackText,
  reasons,
  onReasonChange,
  appFeedback,
  showReasons,
}) => {
  return (
    <CreateAnimation
      duration={500}
      iterations={1}
      keyframes={[
        { offset: 0, transform: "translateY(200px)", opacity: "0" },
        { offset: 0.5, opacity: "0" },
        { offset: 0.9, opacity: "1" },
        { offset: 1, transform: "translateY(0%)", opacity: "1" },
      ]}
      play
    >
      <IonLabel className="additional-feedback">{feedbackText}</IonLabel>
      {showReasons ? (
        <IonCardContent className="reason-buttons">
          {appFeedback.map((category) => (
            <CategoryButton
              category={category}
              isSelected={reasons.includes(category)}
              onChange={onReasonChange}
              key={category}
              isAppRating={true}
            />
          ))}
        </IonCardContent>
      ) : (
        <></>
      )}
    </CreateAnimation>
  );
};

export { DetailReview };
