import { get } from "lodash";

type StringSorter = (
  key: string,
  sortOrder?: number
) => (a: object, b: object) => number;

const stringSorter: StringSorter =
  (key, sortOrder = 1) =>
  (a, b) => {
    // _.get only checks if key exists. For null values, empty text is assigned
    const stringA = get(a, key, "") || "";
    const stringB = get(b, key, "") || "";

    return stringA > stringB
      ? 1 * sortOrder
      : stringA < stringB
      ? -1 * sortOrder
      : 0;
  };

export { stringSorter };
