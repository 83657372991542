import { ChevronProps } from "./models";
import React from "react";
import { chevronDown, chevronForward } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";

export const Chevron: React.FC<ChevronProps> = ({ expanded, setExpanded }) => {
  return (
    <IonButton
      onClick={() => setExpanded(!expanded)}
      shape="round"
      fill="clear"
      size="small"
    >
      <IonIcon
        style={{ color: "gray" }}
        icon={expanded ? chevronDown : chevronForward}
      />
    </IonButton>
  );
};
