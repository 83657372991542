import { Keyboard } from "@capacitor/keyboard";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, FormEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { isPlatform } from "@ionic/core";
import { LogoutButton, openBrowser } from "src/lib/ionic-components";

import { logout } from "../../store/session";
import { Store } from "../../store/store.model";
import { OnBoardingComponentProps, LastNameLocationStateProps } from "./model";
import { updateAgentData } from "./api";
import { StyledIonPage } from "./style";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import Alert from "./Alert";
import { logOnboardingError } from "../util/logging";
import { useFlags } from "launchdarkly-react-client-sdk";
import { OIG, OIG_VALIDATION } from "../constants/OIG_VALIDATION";
import { OigResponse } from "../util/oig";

const AgentLastName: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const { firebaseAuth, profile } = useSelector(
    (state: Store) => state.session
  );
  const [updatingData, setUpdatingData] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | undefined>("");
  const [lastName, setLastName] = useState<string | undefined>("");
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const history = useHistory();
  const { [OIG_VALIDATION]: oigValidationEnabled } = useFlags();

  useEffect(() => {
    const { firstName, lastName } =
      (history.location.state as LastNameLocationStateProps) || {};
    if (!firstName) history.push("/home/agentFirstName");
    setFirstName(firstName);
    setLastName(lastName);
  }, [history]);

  const onLastNameChange = (event: Event) => {
    const lastName = event.target?.["value"]
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    setLastName(lastName.trim());
  };

  const onNext = async (event?: FormEvent | MouseEvent) => {
    setLastName(lastName?.trim());
    event && event.preventDefault();
    if (isPlatform("capacitor")) Keyboard.hide();
    setShowErrorModal(false);
    try {
      setUpdatingData(true);
      await firebaseAuth.updateCurrentUserName(`${firstName} ${lastName}`);
      try {
        const updateAgentPayload = {
          stage: ONBOARDING_STAGES.NAME,
          firstName,
          lastName,
          ...(oigValidationEnabled && { requestOigValidation: true }),
        };

        const response = await updateAgentData(updateAgentPayload);

        if (oigValidationEnabled) {
          const oig = response[OIG] ? (response[OIG] as OigResponse) : null;
          if (oig?.requestFurtherValidation) {
            localStorage.setItem(
              OIG,
              JSON.stringify({
                validationId: oig.validationId,
                dobRequired: true,
              })
            );
          }
        }

        fireOnboardingSegmentEvent(
          ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LAST_NAME,
          {
            hcpId: agent?.userId as string,
            hcpEventValue: lastName,
          }
        );
        history.push(`${nextStagePath}`);
      } catch (err) {
        if (!err.response || !err.response.text || err.response.status > 500) {
          logOnboardingError(
            ONBOARDING_STAGES.NAME,
            (err as Error).message,
            agent?.userId
          );
          setShowErrorModal(true);
          return;
        }
        setPageError({
          isError: true,
          message: err.response.text,
        });
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setUpdatingData(false);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentFirstName/"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <IonToast
          isOpen={pageError.isError}
          onDidDismiss={onDismiss}
          message={pageError.message}
          color="danger"
          duration={2000}
          position="top"
        />

        <Alert isOpen={showErrorModal} closeAlert={closeErrorModal} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form onSubmit={onNext}>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>What's your last name?</h4>
                  </div>
                  <div>
                    <IonLabel className="form-label">Legal Last Name</IonLabel>
                    <IonInput
                      className="form-input"
                      type="text"
                      placeholder="Last Name"
                      onIonChange={onLastNameChange}
                      value={lastName}
                      autofocus
                      required
                    />
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <IonButton
              expand="block"
              size="large"
              class="ion-margin-top ion-margin-bottom continue-button"
              onClick={onNext}
              disabled={updatingData || (lastName?.trim() || "").length < 2}
            >
              Continue
              {updatingData && (
                <IonSpinner slot="end" class="ion-margin-start" name="lines" />
              )}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentLastName };
