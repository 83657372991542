import {
  IonButton,
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import React, { useState, FormEvent, useEffect } from "react";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { customCheckmark } from "../../../icons/customCheckmark";
import { fireOnboardingSegmentEvent } from "../util/segment";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { updateAgentData } from "./api";

const ProceedToRequirementInfo: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const history = useHistory();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    fireOnboardingSegmentEvent(
      ONBOARDING_SEGMENT_EVENT_NAMES.PROCEEDED_TO_REQUIREMENTS_INFO,
      {
        hcpId: agent?.userId as string,
      }
    );
    history.push(`${nextStagePath}`);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentQualification"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="cbh-logoh-wrapper">
                  <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
                </div>
                <div className="form-heading">
                  <h4>All done with Stripe! One last thing</h4>
                  <p>
                    We require a set of documents to be completed and uploaded
                    before we can place you in a shift. Don’t worry, you
                    probably have all of them already.
                  </p>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <ul className="cbh-welcome-text infosteps">
                  <li>
                    <span>
                      <IonIcon
                        icon={customCheckmark}
                        slot="end"
                        mode="ios"
                        color="medium"
                      />
                    </span>
                    <h4>Your personal information</h4>
                    <p>We’ll ask for your name, email and address</p>
                  </li>
                  <li>
                    <span>
                      <IonIcon
                        icon={customCheckmark}
                        slot="end"
                        mode="ios"
                        color="medium"
                      />
                    </span>
                    <h4>Your professional information</h4>
                    <p>
                      Tell us which licenses you have and which types of shifts
                      you’re interested in
                    </p>
                  </li>
                  <li>
                    <span>
                      <IonIcon
                        icon={customCheckmark}
                        slot="end"
                        mode="ios"
                        color="medium"
                      />
                    </span>
                    <h4>Your contract and pay information</h4>
                    <p>
                      You can get all set up to receive instant payments from
                      Clipboard Health
                    </p>
                  </li>
                  <li>
                    <span className="step doing">4</span>
                    <h4>Your required documents</h4>
                    <p>
                      Submit a few documents to qualify to book your first
                      shifts
                    </p>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                onClick={onNext}
              >
                Add some documents
              </IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { ProceedToRequirementInfo };
