import { TabRouterPath } from "@app/routing/constant/tabRoute";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonIcon,
  useIonViewDidEnter,
} from "@ionic/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "@store/index";
import React from "react";
import { useHistory, useParams } from "react-router";
import { useSendbirdStateContext } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import "./chatChannelsList.scss";
import { documentOutline } from "ionicons/icons";
import { fetchChannels } from "./chatProvider";
import { getChannelShiftTime, MessageTypes } from "./chat";
import { getSendHomeRequestMessage } from "./messages";

const getLastMessageDate = (channel) => {
  const useTimestamp = channel.lastMessage
    ? channel.lastMessage.createdAt
    : channel.createdAt;
  const lastMessage = moment(useTimestamp);

  if (moment().add(-1, "day").startOf("day").isAfter(lastMessage))
    return lastMessage.format("MM/DD/YYYY");
  if (moment().startOf("day").isAfter(lastMessage)) return "Yesterday";

  return lastMessage.format("hh:mm A");
};

const getLastMessageInfo = ({ lastMessage }, currentUserId) => {
  if (lastMessage) {
    if (lastMessage.customType === MessageTypes.FcmRequest) {
      const data = lastMessage.data ? JSON.parse(lastMessage.data) : {};
      return getSendHomeRequestMessage(data.facilityName);
    }

    const from = lastMessage.sender ? (
      <b>
        {lastMessage.sender.userId === currentUserId
          ? "You"
          : lastMessage.sender.nickname}
        :{" "}
      </b>
    ) : (
      <></>
    );
    if (lastMessage.messageType === "file")
      return (
        <>
          <IonIcon icon={documentOutline} /> {from} {lastMessage.name}{" "}
        </>
      );

    return (
      <>
        {from} {lastMessage.message}
      </>
    );
  }

  return "No messages";
};

export const ChatChannelsList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useAppSelector(
    (store) => store?.session?.agent?.userId
  ) as string;
  const { facilityId } = useParams<{ facilityId: string }>();
  const channels = useAppSelector((store) => store.chatStore.channels);
  const currentChannelUrl = facilityId + "_" + userId;
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const channelClick = (newChannelUrl) => {
    const [facilityId] = newChannelUrl.split("_");
    history.push(TabRouterPath.CHAT_CHANNELS_LIST + "/" + facilityId);
  };

  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags["channel-open-30-days-after-last-booked-shift"];

  useIonViewDidEnter(() => {
    fetchChannels(sdk, dispatch);
  }, [sdk]);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={TabRouterPath.MY_SHIFTS}
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Facility Chats</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {!channels.length && (
          <h3 style={{ textAlign: "center", margin: 20 }}>
            No channels loaded
          </h3>
        )}

        {!!channels.length && (
          <div className="channels-container">
            {channels.map((channel, index, array) => {
              const classes = ["channel"];

              if (channel.url === currentChannelUrl) classes.push("selected");

              if (index > 0) classes.push("channel-not-first");
              if (index === array.length - 1) classes.push("channel-last");

              const { facilityName } = channel.metadata;

              return (
                <div
                  key={channel.url}
                  className={classes.join(" ")}
                  onClick={() => channelClick(channel.url)}
                >
                  <div className="channel-name">
                    <b>
                      {channel.shift?.facility?.name ||
                        facilityName ||
                        channel.name}
                    </b>
                  </div>
                  <div className="channel-last-message">
                    {getLastMessageDate(channel)}
                  </div>
                  <div className="channel-message">
                    {getLastMessageInfo(channel, sdk.currentUser?.userId)}
                  </div>
                  {channel.unreadMessageCount > 0 ? (
                    <div className="channel-unread">
                      {channel.unreadMessageCount}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="channel-upcoming-shift">
                    {getChannelShiftTime(
                      isChatOpen30DaysAfterLastShift,
                      channel
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
