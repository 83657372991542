import { IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import React from "react";
import { RemoveLicenseAlertProps } from "./interfaces";
import "./style.scss";

export const RemoveLicenseAlert: React.FC<RemoveLicenseAlertProps> = ({
  isOpen,
  closeModal,
  onSubmit,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      cssClass="remove-license-modal"
    >
      <IonContent id="remove-license-modal-container">
        <div className="remove-license-modal-header">
          <IonText className="modal-title">
            <h2>Remove License</h2>
          </IonText>
          <IonText className="modal-subtitle">
            <p>
              Are you sure you want to remove this license from your account?
              This means you will no longer be able to work shifts in this
              state.
            </p>
          </IonText>
          <IonText className="remove-license-modal-subtitle">
            All booked shifts in this state will be cancelled immediately.
          </IonText>
        </div>
        <div className="remove-license-modal-action-container">
          <IonButton
            expand="full"
            shape="round"
            onClick={() => onSubmit()}
            style={{ "--border-color": "#CA4031", "--color": "#CA4031" }}
            fill="outline"
          >
            Remove
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            style={{
              "--border-color": "#828282",
              "--color": "#828282",
              marginTop: "10px",
            }}
            fill="outline"
            onClick={() => closeModal()}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
