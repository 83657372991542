import { RangeChangeEventDetail, RangeValue } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRange,
  IonText,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { banOutline, logoUsd } from "ionicons/icons";
import { get, round } from "lodash";
import React, { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgent } from "src/app/store/session";
import { Store } from "src/app/store/store.model";
import { updatePreference } from "src/app/openShifts/api";
import { MinPayPreferenceModalProps, MinimumPayView } from "./interface";
import "./style.scss";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "src/constants";
import { Agent } from "src/lib/interface";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";

export const MinPayPreference: React.FC<MinPayPreferenceModalProps> = ({
  isOpen,
  minPayHourlyPreference,
  minPayShiftPreference,
  closeModal,
  updateOpenShifts,
  baseRate,
  searchMode,
}) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [minimumPayView, setMinimumPayView] = useState(
    minPayHourlyPreference
      ? MinimumPayView.PER_HOUR
      : minPayShiftPreference
      ? MinimumPayView.PER_SHIFT
      : MinimumPayView.PER_HOUR
  );
  const [minPayHourly, setMinPayHourly] = useState<RangeValue>(
    minPayHourlyPreference
  );
  const [minPayShift, setMinPayShift] = useState<RangeValue>(
    minPayHourlyPreference ? 0 : minPayShiftPreference
  );

  const ldFlags = useFlags();

  const sliderMaxPayValue = useMemo(() => {
    const baseRateFlag = ldFlags[FeatureFlag.BASE_RATE_PAY_FILTERS];
    const baseRateMultipleFlag =
      ldFlags[FeatureFlag.BASE_RATE_MULTIPLE_PAY_FILTERS];
    if (baseRateFlag !== 17 && baseRate == 17) {
      baseRate = baseRateFlag;
    }
    const baseRateMultiple = baseRate * baseRateMultipleFlag;

    if (minimumPayView === MinimumPayView.PER_SHIFT) {
      return round(baseRateMultiple * 0.8) * 10;
    }

    const baseRateMultipleHourly =
      round(baseRateMultiple) % 2 === 0
        ? round(baseRateMultiple)
        : round(baseRateMultiple) + 1;
    return baseRateMultipleHourly;
  }, [baseRate, ldFlags, minimumPayView]);

  const dispatch = useDispatch();

  const onMinimumPayViewChanged = (e) => {
    const selectedView = e.detail.value;
    if (selectedView === minimumPayView) {
      return;
    }

    setMinimumPayView(e.detail.value);
  };

  const onChange = (event: CustomEvent<RangeChangeEventDetail>) => {
    const { value } = event.detail;
    if (minimumPayView == MinimumPayView.PER_HOUR) {
      setMinPayHourly(value);
    } else if (minimumPayView == MinimumPayView.PER_SHIFT) {
      setMinPayShift(value);
    }
  };

  const minimumPayValueText = useMemo(() => {
    if (minimumPayView === MinimumPayView.PER_HOUR) {
      return minPayHourly > 0 ? `$${minPayHourly}/hour` : "any hourly rate";
    }
    return minPayShift > 0 ? `$${minPayShift}/shift` : "any shift total";
  }, [minimumPayView, minPayHourly, minPayShift]);

  const onSave = useCallback(async () => {
    const finalMinPayHourly =
      minimumPayView === MinimumPayView.PER_HOUR ? minPayHourly : 0;
    const finalMinPayShift =
      minimumPayView === MinimumPayView.PER_SHIFT ? minPayShift : 0;
    if (
      finalMinPayHourly !== minPayHourlyPreference ||
      finalMinPayShift !== minPayShiftPreference
    ) {
      let updatedAgent: Agent = agent;
      if (minimumPayView === MinimumPayView.PER_HOUR) {
        updatedAgent = await updatePreference(
          "minPayHourly",
          finalMinPayHourly as number
        );
      }
      if (minimumPayView === MinimumPayView.PER_SHIFT) {
        updatedAgent = await updatePreference(
          "minPayShift",
          finalMinPayShift as number
        );
      }
      logEvent(USER_EVENTS.MINIMUM_PAY_PREFERENCE_UPDATED, {
        new_minimum_hourly: finalMinPayHourly,
        new_minimum_shift: finalMinPayShift,
        previous_minimum_hourly: minPayHourlyPreference,
        previous_minimum_shift: minPayShiftPreference,
        source: searchMode,
      });

      updateOpenShifts?.();
      dispatch(updateAgent(updatedAgent));
    }
    closeModal?.();
  }, [minPayHourly, minPayShift, minimumPayView]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} cssClass="pay-modal">
      <IonContent id="pay-modal-container">
        <IonText className="modal-title">
          <h2>Minimum Pay</h2>
        </IonText>
        <IonText className="modal-subtitle">
          <p>what's the smallest amount you'll work for?</p>
        </IonText>
        <IonSegment
          data-testid="minimum-pay-pref-segment"
          mode="ios"
          onIonChange={onMinimumPayViewChanged}
          value={minimumPayView}
        >
          <IonSegmentButton
            data-testid="minimum-pay-pref-segment-hour-button"
            mode="ios"
            value={MinimumPayView.PER_HOUR}
            className="minimum-pay-view-button"
          >
            Per Hour
          </IonSegmentButton>
          <span className="ion-segment-button-separator"></span>
          <IonSegmentButton
            data-testid="minimum-pay-pref-segment-shift-button"
            mode="ios"
            value={MinimumPayView.PER_SHIFT}
            className="minimum-pay-view-button"
          >
            Per Shift
          </IonSegmentButton>
        </IonSegment>
        <div className="pay-modal-slider-container">
          <IonItem lines="none" className="minimum-pay-slider-label">
            <IonIcon icon={banOutline} color="black" />
            <IonLabel className="ion-text-center">
              <h1>{minimumPayValueText}</h1>
            </IonLabel>
            <IonIcon icon={logoUsd} color="black" />
          </IonItem>
          <IonRange
            data-testid="minimum-pay-value-slider"
            min={0}
            max={sliderMaxPayValue}
            value={
              minimumPayView == MinimumPayView.PER_SHIFT
                ? minPayShift
                : minPayHourly
            }
            onIonChange={onChange}
            style={{
              "--bar-background": "#10B981",
              "--bar-background-active": "lightgray",
              "--knob-background": "#10B981",
            }}
          ></IonRange>
        </div>
        <div className="pay-modal-action-container">
          <IonButton expand="full" shape="round" onClick={onSave}>
            Update
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            style={{
              "--border-color": "#828282",
              "--color": "#828282",
            }}
            fill="outline"
            onClick={() => closeModal?.()}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
