import { useFlags } from "launchdarkly-react-client-sdk";
import Alert from "antd/es/alert";
import { formatPhone } from "src/lib/utils";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  book,
  call,
  informationCircleOutline,
  mail,
  man,
  starOutline,
  pencilOutline,
} from "ionicons/icons";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonButton,
  IonText,
} from "@ionic/react";
import { timerOutline } from "ionicons/icons";
import { openBrowser, openInAppBrowser } from "src/lib/ionic-components";
import React, { Fragment } from "react";

import "./profile/style.scss";
import { getWorkerShiftTypeLabel } from "../../constants/qualificationTypes";
import { LicenseDetails } from "./profile/license";
import { logEvent } from "src/lib/analytics";
import { ProfileImage } from "./profile/profileImage";
import { RouterPath } from "../routing/constant";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { Specialities } from "./profile/specialities";
import { Store } from "../store/store.model";
import { USER_EVENTS } from "../../constants";
import { ContractorAgreement } from "./profile/contractorAgreement";
import { UpdatedContractorAgreement } from "./profile/UpdatedContractorAgreement";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { UPDATE_PROFILE_WORKFLOW } from "./profile/constants";

const ProfilePage = ({ history }) => {
  const agent = useSelector((state: Store) => state.session.agent);
  const { networkStatus } = useSelector((state: Store) => state.session);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_PROFILE);
  });

  if (!agent) {
    return <IonSpinner color="light" />;
  }

  const onRatingClick = () => {
    openInAppBrowser("https://www.clipboardhealth.com/understanding-reviews");
  };

  const updateEmail = () => {
    logEvent(USER_EVENTS.PROFILE_CHANGE_STARTED, {
      type: "EMAIL",
    });
    localStorage.setItem(
      "profileUpdateWorkflow",
      UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL
    );
    history.push(TabRouterPath.UPDATE_PROFILE);
  };

  const updatePhone = () => {
    logEvent(USER_EVENTS.PROFILE_CHANGE_STARTED, {
      type: "PHONE",
    });
    localStorage.setItem(
      "profileUpdateWorkflow",
      UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE
    );
    history.push(TabRouterPath.UPDATE_PROFILE);
  };

  const deleteDataHandler = async () => {
    history.push(TabRouterPath.DELETE_DATA);
  };

  const ldFlags = useFlags();
  const isEnableDeleteData = ldFlags["hcp_self_delete"] as boolean;
  const isRequestedDeletion = agent?.requestedAccountDeletion?.requested;
  const deleteText = isRequestedDeletion
    ? "Account Deletion Request Pending"
    : "Delete My Account";

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={RouterPath.ACCOUNT}
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <IonContent>
          <IonItem lines="none">
            <Alert
              type="info"
              style={{ margin: "10px 5px 0 0" }}
              message={
                <Fragment>
                  To update profile, please contact our{" "}
                  <Link to={RouterPath.SUPPORT}>support</Link>
                </Fragment>
              }
              showIcon={true}
            />
          </IonItem>
          <IonListHeader
            style={{
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 15,
            }}
          >
            <ProfileImage url={agent.profileImageUrl} />
            <h5 className="ion-margin-top">{agent.name?.toUpperCase()}</h5>
          </IonListHeader>
          <IonCard>
            <IonItem button onClick={updateEmail}>
              <IonIcon icon={mail} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Email</IonCardSubtitle>
                <h6>{agent.email}</h6>
              </IonLabel>
              <IonIcon icon={pencilOutline} />
            </IonItem>
            <IonItem button onClick={updatePhone}>
              <IonIcon icon={call} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Phone</IonCardSubtitle>
                <h6>{formatPhone(agent.phone as string)}</h6>
              </IonLabel>
              <IonIcon icon={pencilOutline} />
            </IonItem>
            <IonItem>
              <IonIcon icon={book} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Qualification</IonCardSubtitle>
                <h6>
                  {getWorkerShiftTypeLabel(agent.qualification) ||
                    `Other (${getWorkerShiftTypeLabel(
                      agent.otherQualification
                    )})`}
                </h6>
              </IonLabel>
            </IonItem>
            {agent.socialSecurityNumber && (
              <IonItem>
                <IonIcon icon={man} />
                <IonLabel class="ion-margin">
                  <IonCardSubtitle>Social Security Number</IonCardSubtitle>
                  <h6>XX-XXX-{agent.socialSecurityNumber}</h6>
                </IonLabel>
              </IonItem>
            )}
            <IonItem button detail={false} onClick={onRatingClick}>
              <IonIcon icon={starOutline} />
              <IonLabel class="ion-margin">
                <IonCardSubtitle>Rating</IonCardSubtitle>
                <h6>{get(agent, "rating.value", 0).toFixed(2)}</h6>
              </IonLabel>
              <IonIcon icon={informationCircleOutline} slot="end" />
            </IonItem>
          </IonCard>
          <Specialities
            specialities={agent.specialities}
            qualification={agent.qualification}
          />
          <LicenseDetails license={agent.license} hcpId={agent.userId} />
          <UpdatedContractorAgreement agentId={agent.userId as string} />
          <ContractorAgreement />
          {isEnableDeleteData && (
            <IonCard class="card-no-border ">
              <IonButton
                color="danger"
                expand="block"
                onClick={deleteDataHandler}
                shape="round"
                disabled={isRequestedDeletion}
                fill={isRequestedDeletion ? "outline" : "solid"}
                className="deletion-button"
              >
                {isRequestedDeletion && (
                  <IonIcon icon={timerOutline} className="ion-margin-end" />
                )}
                <IonText>{deleteText}</IonText>
              </IonButton>
            </IonCard>
          )}
        </IonContent>
      )}
    </IonPage>
  );
};

export { ProfilePage };
