import { environment } from "../../../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";
import { getAuthHeader } from "../../../superagent";
import { FssUploadResponse } from "@src/types/Fss/index.types";

const uploadProfileImage = async ({
  croppedImageFile,
  dualWriteEnabled,
  userId,
}): Promise<string | undefined> => {
  const uploadPromises: Array<any> = [];
  const { fileUploadURL, fileUploadPreset } = environment;
  const formData = new FormData();
  formData.append("file", croppedImageFile);
  formData.append("upload_preset", fileUploadPreset);
  uploadPromises.push(request.post(fileUploadURL).send(formData));
  if (dualWriteEnabled && userId) {
    uploadPromises.push(
      uploadProfilePicToFss(userId, croppedImageFile, "jpeg")
    );
  }
  try {
    const [cloudinaryResponse, fssResponse] = await Promise.all(uploadPromises);
    const { url } = cloudinaryResponse.body;
    const updatePayload = {
      url,
    };
    if (fssResponse) {
      updatePayload["profileImagefileStorageFileKey"] =
        fssResponse.fileStorageFileKey;
    }
    await request
      .put(`${environment.baseUrl}/agentprofile/profilePic`)
      .set(await getAuthHeader())
      .send(updatePayload);
    return url;
  } catch (error) {
    logApiFailureEvent(error);
  }
};
/**
 * @description use this util to Fetch PresignedURL and Upload the file to FSS
 */
const uploadProfilePicToFss = async (
  workerId: string,
  file: File,
  fileExtension: string
): Promise<FssUploadResponse> => {
  const preSignedURLResponse = await request
    .get(
      `${environment.baseUrl}/agentProfile/${workerId}/profile-image-presigned-upload-url`
    )
    .set(await getAuthHeader())
    .query({
      extension: fileExtension,
    });
  const { presigned_url, fileStorageFileKey } = preSignedURLResponse.body;
  await request.put(presigned_url).send(file);
  return { fileStorageFileKey };
};

export { uploadProfileImage };
