import getStripe from "src/utils/getStripe";
import { environment } from "@env/environment";
import { firebaseAuth } from "src/app/store/session/init";
import {
  DEFAULT_ERROR_MESSAGE,
  STRIPE_ERROR_MESSAGE,
  STRIPE_UPLOAD_CANCELLED,
} from "src/constants/stripeDocumentAutoVerification.constants";

export interface IVerificationSession {
  verificationSessionId: string;
  clientSecret: string;
}

export const getVerificationSessionClientSecret = async (
  hcpId: string,
  documentId: string
): Promise<{ verificationSession?: IVerificationSession; error?: string }> => {
  try {
    let response: Response | undefined;
    let token =
      (await firebaseAuth?.currentUser?.getIdToken()) ||
      localStorage.getItem("authToken");
    if (!token) throw new Error(DEFAULT_ERROR_MESSAGE);
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", token);

    // Create the VerificationSession in IdentityDocAutoVerification Service.
    response = await fetch(
      `${environment.identityDocAutoVerifyServiceBaseUrl}/v1/workers/${hcpId}/stripe/verification-sessions`,
      {
        method: "POST",
        body: JSON.stringify({
          documentId,
        }),
        headers,
      }
    );

    if (response?.status !== 201) {
      throw new Error(STRIPE_ERROR_MESSAGE);
    }

    const session = await response.json();
    return {
      verificationSession: {
        verificationSessionId: session.id,
        clientSecret: session.clientSecret,
      },
    };
  } catch (error) {
    return { error: error.message };
  }
};

export const loadStripeModal = async (clientSecret: string): Promise<void> => {
  const stripe = await getStripe();
  if (!stripe) {
    throw new Error(STRIPE_ERROR_MESSAGE);
  }
  const { error } = await stripe.verifyIdentity(clientSecret);
  if (error) {
    // check if session was cancelled by the user.
    // otherwise check for any other error message from stripe.
    const errorMsg =
      error?.code === "session_cancelled"
        ? STRIPE_UPLOAD_CANCELLED
        : error?.message ?? DEFAULT_ERROR_MESSAGE;
    throw new Error(errorMsg);
  }
};
