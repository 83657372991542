import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { FacilityDetails } from "../dayView/facilityDetails";
import { useParams } from "react-router-dom";
import { fetchFacilityProfile } from "./api";
import { Facility } from "src/lib/interface";

const FacilityDetailPage: React.FC<{}> = () => {
  const { facilityId } = useParams() as any;
  const [facilityDetails, setFacilityDetails] = useState<Facility>();

  useEffect(() => {
    const fetchProfileDetails = async () => {
      const { facilityInfo } = await fetchFacilityProfile(facilityId);
      setFacilityDetails(facilityInfo);
    };
    fetchProfileDetails();
  }, [facilityId]);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>{facilityDetails?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {facilityDetails && (
          <FacilityDetails
            onClose={() => {}}
            facility={facilityDetails}
            showHeader={false}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export { FacilityDetailPage };
