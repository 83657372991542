import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

import { GlobalRouterPath } from "./constant/globalRoute";
import { StatusRouteProps } from "./model";

const StatusRoute: React.FC<StatusRouteProps> = ({
  hasSession,
  render,
  ...rest
}) => {
  const renderRoute = (props: RouteComponentProps) => {
    const state = {
      from: props.location,
    };

    if (!hasSession) {
      return (
        <Redirect to={{ pathname: GlobalRouterPath.LOADING_PAGE, state }} />
      );
    }
    // render will always be passed to StatusRoute
    // when StatusRoute is called
    return render?.(props);
  };

  return <Route {...rest} render={renderRoute} />;
};

export { StatusRoute };
