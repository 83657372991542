import { ActionType, DocumentStore } from "./model";

const initialState: DocumentStore = {
  loading: false,
  status: {
    requirements: {},
    missing: [],
    pending: [],
    expired: [],
    completed: [],
    miscellaneous: [],
  },
  selectedRequirement: {
    requirement: null,
    requirementType: null,
  },
  checkrApplicationStatus: {
    invitationStatus: null,
    reportStatus: null,
  },
};

const documentStore = (currentState, action): DocumentStore => {
  const state = currentState || initialState;
  switch (action.type) {
    case ActionType.SET_REQUIREMENTS:
      if (!action.data) {
        return {
          ...state,
          status: initialState.status,
        };
      }
      return {
        ...state,
        ...action.data,
      };

    case ActionType.SET_REQUIREMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.SET_SELECTED_REQUIREMENT:
      return {
        ...state,
        selectedRequirement: {
          ...action.data,
        },
      };
    case ActionType.SET_CHECKR_APPLICATION_STATUS:
      return {
        ...state,
        checkrApplicationStatus: {
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export { documentStore };
