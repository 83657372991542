enum LoginErrorType {
  INVALID_PHONE_NUMBRER = "InvalidPhoneNumber",
  AGENT_NOT_FOUND = "AgentNotFound",
  EMAIL_NOT_FOUND = "EmailNotFound",
  FIREBASE_AUTH_ERROR = "FirebaseAuthError",
}

enum OnboardingErrors {
  "auth/email-already-in-use" = "Credentials already exist, please try again [email]",
}
export { LoginErrorType, OnboardingErrors };
