import { Camera } from "@capacitor/camera";
import { Filesystem } from "@capacitor/filesystem";
import { Device } from "@capacitor/device";
import { CameraSource, CameraResultType, Photo } from "@capacitor/camera";
import { Chooser } from "@ionic-native/chooser";

interface CapturedPhotoType {
  /**
   * The base64 encoded string representation of the image.
   */
  file?: string;

  /**
   * The blob representation of the image.
   */
  fileBlob?: Blob;
  /**
   * The format of the image, ex: jpeg, png, gif.
   */
  type: string;
}

const dataURLToBlob = async (dataURL: string): Promise<Blob> => {
  return await fetch(dataURL).then((res) => res.blob());
};

/**
 * Converts file uri to base64 string
 * @param {CameraPhoto} photo
 * @returns {string} base64 string of image
 */
const readAsBase64 = async (photo: Photo): Promise<string> => {
  const fileContents = await Filesystem.readFile({
    path: photo?.path || "",
  });
  const fileBase64 =
    "data:image/" + photo.format + ";base64," + fileContents?.data;
  return fileBase64;
};

/**
 * Captures image from mobile
 * @param {number} quality defines the image quality for the captured picture
 * @returns
 */
const capturePhoto = async (
  quality?: number,
  cameraSource: CameraSource = CameraSource.Prompt,
  getFileBlob: boolean = false
): Promise<CapturedPhotoType> => {
  const { manufacturer } = await Device.getInfo();

  //Using resultType as data url for xiaomi phones as it leads to crashes when using file uri. Reference issue:- https://github.com/ionic-team/capacitor/issues/2060
  const resultType =
    manufacturer === "Xiaomi" ? CameraResultType.DataUrl : CameraResultType.Uri;
  const fileData = await Camera.getPhoto({
    resultType,
    source: cameraSource,
    ...(quality ? { quality } : {}),
  });
  let file = fileData.dataUrl;
  if (manufacturer !== "Xiaomi") {
    file = await readAsBase64(fileData);
  }
  const fileBlob = getFileBlob ? await dataURLToBlob(file!) : undefined;
  return { file, fileBlob, type: fileData.format };
};

/**
 * Opens file browser on IOS
 */
const iosFileChooser = async (): Promise<CapturedPhotoType> => {
  const { uri, mediaType: type = "" } = (await Chooser.getFile()) || {};
  const fileContents = await Filesystem.readFile({
    path: uri || "",
  });
  const fileBase64 = "data:" + type + ";base64," + fileContents?.data;
  const fileBlob = await dataURLToBlob(fileBase64);

  return { fileBlob: fileBlob, file: fileBase64, type };
};

const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

const readBlobAsBase64 = async (blob: Blob) => {
  return (await convertBlobToBase64(blob)) as string;
};

export { iosFileChooser, capturePhoto, readBlobAsBase64, dataURLToBlob };
