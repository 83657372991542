import request from "superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import { VerificationPreferences } from "src/lib/interface";
import { environment } from "@env/environment";
import { getAuthHeader } from "@app/superagent";
import { logFailedApiRetry } from "@app/utils/api_retry";

const { baseUrl } = environment;

export const fetchFacilityDetails = async (facilityId: string) => {
  try {
    const { body } = await request
      .get(
        `${baseUrl}/facilityProfile/verificationPreferences?facilityId=${facilityId}`
      )
      .retry(1, (err) => {
        logFailedApiRetry(
          err,
          `/facilityProfile/verificationPreferences?facilityId=${facilityId}`
        );
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return Promise.resolve({});
  }
};

export const fetchFacilitiesVerificationPreferences = async (
  facilityIds: string[]
): Promise<{ data?: VerificationPreferences[] }> => {
  try {
    const { body } = await request
      .post(`${baseUrl}/facilityProfile/verificationPreferences`)
      .set(await getAuthHeader())
      .send({ facilityIds });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return Promise.resolve({});
  }
};
