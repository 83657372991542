/* eslint-disable no-shadow */
export enum Pages {
  HARRASSMENT = "harrassment",
  DEMENTIA = "dementia",
  HIPAA = "hipaa",
  INFECTION = "infection",
  ELDER = "elder",
  LGBTQ = "lgbtq",
  TRAININGS = "trainings",
}

export const PageMetaMap = new Map([
  [
    "harrassment",
    {
      title: "Harassment Training",
      url: "https://drive.google.com/file/d/1I2E7lUBKrNneSSJdTE3wqfVSVYjN5TT-/preview",
    },
  ],
  [
    "dementia",
    {
      title: "Dementia Course",
      url: "https://drive.google.com/file/d/16jIj9T37QfPseGH8H8gw_dhPoiD7tP4H/preview",
    },
  ],
  [
    "hipaa",
    {
      title: "HIPAA Course",
      url: "https://drive.google.com/file/d/1MZxatLn8EdXnCSea0pvwJA6_b3-rJ_7m/preview",
    },
  ],
  [
    "infection",
    {
      title: "Infection Control Course",
      url: "https://drive.google.com/file/d/1NoNoCN3BTFu9cv54dmSTt2cqkl9mpmas/preview",
    },
  ],
  [
    "elder",
    {
      title: "Elder Abuse Course",
      url: "https://drive.google.com/file/d/12TUJKoT854SVLa6bLxfO_bGGLm6uHc4R/preview",
    },
  ],
  [
    "lgbtq",
    {
      title: "LGBTQ Course",
      url: "https://drive.google.com/file/d/1s3cQ8ISjtjqxCQlBL6oRID1y_FzwljJA/preview",
    },
  ],
  [
    "trainings",
    {
      title: "Trainings Quiz",
      url: "https://docs.google.com/forms/d/1bAKcuXZzk-OVBgmMnXfhMNKd07Cse1Ew73egrTpPdm8/viewform?edit_requested=true",
    },
  ],
]);
