import { openBrowser, openInAppBrowser } from "src/lib/ionic-components";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "antd/es/alert/style/css";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../store/store.model";

const PrivacyPolicyNotificationPage = ({ match, history }) => {
  const agent = useSelector((state: Store) => state.session.agent);
  const { networkStatus } = useSelector((state: Store) => state.session);
  if (!agent) {
    return <IonSpinner color="light" />;
  }

  const onRatingClick = () => {
    openInAppBrowser("https://www.clipboardhealth.com/understanding-reviews");
  };

  const onNotificationPreferenceClick = () =>
    history.push(`${match.url}/notification-preference`);
  const onPrivacyPolicyClick = () =>
    openBrowser("https://clipboardhealth.com/privacy-policy");

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text="Account"
              defaultHref="/home/account"
              mode="ios"
            />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>
            Privacy and Notifications
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <HeaderType2 title={'Privacy and Notifications'} titleBack={'Account'} backLink={'/home/account'}> </HeaderType2>*/}
      <IonContent>
        <IonItem button onClick={onNotificationPreferenceClick} detail>
          <IonLabel>
            <h4>Notification Preferences</h4>
          </IonLabel>
        </IonItem>
        <IonItem button onClick={onPrivacyPolicyClick} detail>
          <IonLabel>
            <h4>Privacy Policy</h4>
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export { PrivacyPolicyNotificationPage };
