import { LA_CENTER } from "src/lib/ionic-components";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { memo, useEffect, useState } from "react";
import { googleMapContainerStyle } from "../openShifts/map/googleMapShifts.const";
import iconMarkerCurrentLocation from "../../assets/icons/google-map/markerCurrentLocation.svg";

const AddressViewMapComponent = ({
  location,
  zoom = 17,
  defaultCenter = LA_CENTER,
  MapMarkerIcon,
}) => {
  const [mapLocation, setMapLocation] = useState(defaultCenter);

  useEffect(() => {
    if (!location) {
      return;
    }
    setTimeout(() => {
      setMapLocation(location);
    }, 500);
  }, [location]);

  return (
    <GoogleMap
      mapContainerStyle={googleMapContainerStyle}
      zoom={zoom}
      center={mapLocation}
      options={{
        disableDefaultUI: true,
      }}
    >
      {mapLocation && (
        <Marker position={mapLocation} icon={iconMarkerCurrentLocation} />
      )}
    </GoogleMap>
  );
};

const AddressViewMap = memo(AddressViewMapComponent);

export { AddressViewMap };
