import React from "react";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPushNotificationPreference } from "../store/Notifications/actions";

const InstantUpdate = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const askPushNotifications = () => {
    hideVisibleUpdate(true);
    if (!history.location.pathname.includes("/home/myShifts")) {
      //don't go to my shifts if already there add this check
      history.push("/home/myShifts");
    }
  };
  const hideVisibleUpdate = (flag: boolean) => {
    dispatch(setPushNotificationPreference(flag));
    onClose();
  };
  return (
    <div style={{ position: "absolute", background: "white", height: "92.5%" }}>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}
      >
        <img src="../../assets/logo/bunny.png" style={{ height: "100px" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "5%",
          flexDirection: "column",
          padding: "5% 5% 0% 5%",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Want Instant Updates?</h3>
        <p style={{ textAlign: "center" }}>
          Clipboard Health sends push notifications to give you real-time
          updates on your shifts. Click <strong>"OK"</strong> to allow push
          notifications.
        </p>
        <p style={{ textAlign: "center" }}>
          <strong>We will not use</strong> notifications to send you product
          announcements or any promotions.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          padding: "5% 35% 0% 35%",
        }}
      >
        <IonButton size="small" expand="block" onClick={askPushNotifications}>
          OK
        </IonButton>
        <p
          style={{ textAlign: "center", fontSize: "12px", marginTop: "10px" }}
          onClick={() => hideVisibleUpdate(false)}
        >
          Not Now
        </p>
      </div>
    </div>
  );
};
export default InstantUpdate;
