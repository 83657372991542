import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import {
  AgentVersionRequest,
  RolloutMsaResponse,
  VersionInfoResponse,
} from "./model";
import { logFailedApiRetry } from "../utils/api_retry";

const fetchVersionInfo = async (
  appVersion: string,
  platform
): Promise<VersionInfoResponse> => {
  return await request
    .get(`${environment.baseUrl}/version/mobile_v2`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/version/mobile_v2`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ appVersion, platform })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updateAgentVersion = async (req: AgentVersionRequest): Promise<void> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/version`)
    .set(await getAuthHeader())
    .send(req)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchBookingRolloutMsas = async (): Promise<RolloutMsaResponse> => {
  return await request
    .get(`${environment.baseUrl}/msa/bookingRolloutMSAs`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/msa/bookingRolloutMSAs`);
      return true;
    })
    .set(await getAuthHeader())
    .query({})
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { fetchVersionInfo, updateAgentVersion, fetchBookingRolloutMsas };
