import { CSSProperties } from "react";

export const CustomBadge = {
  position: "absolute",
  borderRadius: 26,
  backgroundColor: "var(--ion-color-danger)",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: 0,
  left: "54%",
  top: "11%",
  minWidth: 17,
  fontSize: 13,
  height: 18,
  fontWeight: "bold",
} as CSSProperties;
