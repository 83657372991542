export const clockInBeforeOneHourError: string =
  "Clock-in cannot exceed one hour before posted start time";
export const clockInAfterShiftEndError: string =
  "Clock-in cannot be after shift end time";
export const clockOutAfterTwoHoursError: string =
  "Clock-out cannot exceed two hours after posted end time";
export const clockOutPostActualClockOut: string =
  "Clock-out cannot exceed current time";
export const clockOutBeforeShiftStart: string =
  "Clock-out cannot be before shift start time";
export const lunchInBeforeLunchOut: string =
  "End Break cannot be before Start Break";
export const clockOutBeforeClockIn: string =
  "Clock-in cannot be after clock-out";
export const eventTimeNotInOrder: string = "Event times must be in order";
export const clockInEmpty: string = "Enter clock-in time";
export const clockOutEmpty: string = "Enter clock-out time";
export const clockInOutEmpty: string = "Enter clock-in and clock-out times";
export const lunchInOutEmpty: string = "Enter break time";
