import moment from "moment-timezone";
import {
  NFCReadStatus,
  NFCShiftConversionReason,
  Shift,
  ShiftStages,
} from "src/lib/interface/src";
import { getHumanReadableTag } from "src/lib/utils/src";

export const getNFCReadLogReason = (
  apiFailed = false,
  nfcReadStatus: NFCReadStatus | null = null,
  currentStage: ShiftStages,
  shift: Shift,
  isEdit?: boolean
): string => {
  let failureReason = `Failed to ${getHumanReadableTag(
    currentStage
  )} to shift ${shift._id} (starts at ${shift.start} and ends at ${
    shift.end
  }) in facility ${
    shift.facility?.name
  }. Reason: NFC hash verification failed. Error details: Unknown error occurred`;

  if (apiFailed) {
    failureReason = `NFC Validation Failure - While Editing and saving the time for shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}. Reason: NFC hash verification API failed. Error details: NFC hash is inactive or not present for the given facility.`;
  }

  if (nfcReadStatus === NFCReadStatus.FAILED) {
    isEdit
      ? (failureReason = `NFC Read Failure - While Editing and saving the time for shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}. Reason: NFC hash verification failed. Error details: NFC Scan failed or Timeout`)
      : (failureReason = `NFC Read Failure - While ${getHumanReadableTag(
          currentStage
        )} to shift ${shift._id} (starts at ${shift.start} and ends at ${
          shift.end
        }) in facility ${
          shift.facility?.name
        }. Reason: NFC hash verification failed. Error details: NFC Scan failed or Timeout`);
  }

  if (nfcReadStatus === NFCReadStatus.INVALID_HASH) {
    isEdit
      ? (failureReason = `NFC Validation Failure - While Editing and saving the time for shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}. Reason: NFC hash verification failed. Error details: NFC tag was not created by CBH.`)
      : (failureReason = `NFC Validation Failure - While ${getHumanReadableTag(
          currentStage
        )} to shift ${shift._id} (starts at ${shift.start} and ends at ${
          shift.end
        }) in facility ${
          shift.facility?.name
        }. Reason: NFC hash verification failed. Error details: NFC tag was not created by CBH.`);
  }
  return failureReason;
};

export const getNFCShiftConversionLogReason = (
  nfcShiftConversionReason: NFCShiftConversionReason | null = null,
  shiftId: string | undefined,
  currentStage?: ShiftStages,
  shift?: Shift
): string => {
  let conversionReason = `While ${getHumanReadableTag(currentStage)} to shift ${
    shift?._id
  } (starts at ${shift?.start} and ends at ${shift?.end}) in facility ${
    shift?.facility?.name
  }, shift nfc check has been set to false`;

  if (
    nfcShiftConversionReason === NFCShiftConversionReason.SKIP_NFC_VALIDATION
  ) {
    conversionReason = `Shift converted to non instant pay - While ${getHumanReadableTag(
      currentStage
    )} to shift ${shift?._id} (starts at ${shift?.start} and ends at ${
      shift?.end
    }) in facility ${shift?.facility?.name}.
    Reason: HCP skipped NFC verification`;
  }

  if (
    nfcShiftConversionReason === NFCShiftConversionReason.NFC_TAG_NOT_WORKING
  ) {
    let conversionStage = getHumanReadableTag(currentStage);
    if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE) {
      conversionStage = "Editing and saving";
    }
    conversionReason = `Shift converted to location check - While ${conversionStage} to shift ${shift?._id} (starts at ${shift?.start} and ends at ${shift?.end}) in facility ${shift?.facility?.name}.
    Reason: HCP reported that NFC tag read is not working.`;
  }

  if (nfcShiftConversionReason === NFCShiftConversionReason.NO_NFC_DEVICE) {
    conversionReason = `Shift converted to location check - shift ${shiftId} got converted at ${moment()}.
    Reason: NFC not supported by device.`;
  }
  return conversionReason;
};
