import {
  AuthorizedSignatory,
  Facility,
  Shift,
  Timecard,
} from "src/lib/interface";
import { IonButton, IonSpinner } from "@ionic/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState, Fragment } from "react";

import { ShiftSignMode, SignButtonIsDisabled, SignButtonProps } from "./model";

const getButtonText = (signMode: ShiftSignMode): string => {
  switch (signMode) {
    case ShiftSignMode.WORKER:
      return "Get this shift signed off";

    case ShiftSignMode.AUTHORIZED:
      return "Request Signature";

    case ShiftSignMode.REQUESTED:
      return "Ok";

    case ShiftSignMode.MANUAL:
      return "Sign";

    case ShiftSignMode.RATING:
      return "Submit and Sign";

    case ShiftSignMode.MANUAL_SIGNATURE:
      return "Submit";

    default:
      return "";
  }
};

const isWorkerDisabled = (shiftInfo: Shift, signature: string): boolean => {
  const { facility, timecard } = shiftInfo;
  const { files } = timecard || ({} as Timecard);
  const { verificationPreference } = facility || ({} as Facility);

  const noTimecardUploaded =
    verificationPreference?.usesTimesheets && isEmpty(files);

  return (
    isEmpty(shiftInfo.unit) ||
    isEmpty(shiftInfo.clockInOut) ||
    isEmpty(shiftInfo.lunchInOut) ||
    isEmpty(signature) ||
    noTimecardUploaded ||
    false
  );
};

const isManualDisabled = (signatoryInfo: AuthorizedSignatory): boolean => {
  return isEmpty(signatoryInfo) ||
    isEmpty(signatoryInfo.name) ||
    isEmpty(signatoryInfo.role) ||
    isEmpty(signatoryInfo.phone) ||
    signatoryInfo.phone === undefined
    ? false
    : signatoryInfo.phone.length < 10;
};

const isDisabled: SignButtonIsDisabled = (
  signMode,
  { shift, signature, signatory }
) => {
  switch (signMode) {
    case ShiftSignMode.WORKER:
      return isWorkerDisabled(shift, signature);

    case ShiftSignMode.AUTHORIZED:
      return isEmpty(signatory);

    case ShiftSignMode.MANUAL:
      return isManualDisabled(signatory);

    case ShiftSignMode.MANUAL_SIGNATURE:
      return isEmpty(signature);

    default:
      return false;
  }
};

const SignButton: React.FC<SignButtonProps> = ({
  signMode,
  loading,
  shift,
  signatory,
  signature,
  isDisabled: isButtonDisabled,
  ...props
}) => {
  const [text, setText] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    setText(getButtonText(signMode));
  }, [signMode]);

  useEffect(() => {
    setDisabled(isDisabled(signMode, { shift, signatory, signature }));
  }, [signMode, shift, signatory, signature]);

  return (
    <Fragment>
      <IonButton
        expand="block"
        color="primary"
        className="ion-margin"
        disabled={disabled || loading || isButtonDisabled}
        {...props}
      >
        {loading && <IonSpinner slot="start" name="lines" />}
        {text}
      </IonButton>
    </Fragment>
  );
};

export { SignButton };
