import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { setReferralRate } from "@store/session/actions";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateAgentVersion } from "./api";

const WorkerAppVersion: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const updateVersionInfo = async () => {
      dispatch(setReferralRate());
      const { version, build } = await App.getInfo();
      const { platform } = await Device.getInfo();

      await updateAgentVersion({
        platform,
        version: version,
        build: Number(build),
      });
    };

    updateVersionInfo();
  }, []);

  return <Fragment />;
};

export { WorkerAppVersion };
