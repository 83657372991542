import { forEach } from "lodash";
import { MappingLevel } from "src/lib/constants";
import { Store } from "@store/store.model";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { GET_REQUIREMENTS_STATUS } from "../../documents/gql";
import { Shift } from "src/lib/interface";
import { UseMissingDocumentsResult } from "./interfaces";

const useMissingDocuments = (
  shift: Shift | null | undefined
): UseMissingDocumentsResult => {
  const { userId: hcpId } = useSelector((state: Store) => state.session);
  const { data, loading } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
  });
  const [status, setStatus] = useState({
    loading,
  } as UseMissingDocumentsResult);

  const { hcpRequirementStatus } = data || {};
  useEffect(() => {
    if (!shift || loading) return;

    const cbh = new Set<string>();
    const state = new Set<string>();
    const msa = new Set<string>();
    const county = new Set<string>();
    const hcf = new Set<string>();

    const pending = new Set<any>();
    const completed = new Set(hcpRequirementStatus?.completed);
    const missingDocs = new Set<any>();
    forEach(hcpRequirementStatus?.requirements || [], (req) => {
      if (
        !req.visibleToHCP ||
        completed.has(req.reqId) ||
        pending.has(req.reqId)
      )
        return;
      if (!shift.facility || !shift.facility.fullAddress) return;

      const isDocumentRequiredforFacilityType =
        req.hcfType.includes(shift.facility.type) &&
        (req.requiredBy.includes(shift.facility.fullAddress.state) ||
          req.requiredBy.includes(
            shift.facility.fullAddress.metropolitanStatisticalArea
          ) ||
          req.requiredBy.includes(shift.facility.fullAddress.region));
      const isDocumentRequired =
        isDocumentRequiredforFacilityType ||
        req.requiredBy.includes(shift.facility.userId) ||
        req.level === MappingLevel.GLOBAL;
      if (
        isDocumentRequired &&
        !hcpRequirementStatus?.pending.includes(req.reqId)
      ) {
        missingDocs.add(req);
      }

      if (
        isDocumentRequired &&
        hcpRequirementStatus?.pending.includes(req.reqId)
      ) {
        pending.add(req.name);
      }
    });

    setStatus({
      ...(cbh.size && { cbh }),
      ...(state.size && { state }),
      ...(msa.size && { msa }),
      ...(county.size && { county }),
      ...(hcf.size && { hcf }),
      ...(missingDocs && { missingDocs }),
      ...(pending.size && { pending }),
    });
  }, [hcpRequirementStatus, loading, shift]);

  return status;
};

export { useMissingDocuments };
