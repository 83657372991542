import axios from "axios";

const getSignInLink = async (email) => {
  try {
    const appDomain = window.location.origin;

    let isMobileApp = appDomain.includes("localhost")
      ? appDomain.split(":")[2] === "4300"
      : appDomain.includes("hcp-webapp");

    const signInEndpoint = isMobileApp
      ? "/api/testHelpers/appSignInEmailLink"
      : "/api/testHelpers/signInEmailLink";

    let signInLink = await axios.post(
      `${process.env.REACT_APP_API_MAIN_URL || ""}${signInEndpoint}`,
      {
        email,
        appDomain,
      }
    );

    if (!isMobileApp) return signInLink.data.newUrl;

    const signInURL = new URL(signInLink.data.link);
    const mainLink = new URL(signInURL.searchParams.get("link"));

    const newUrl = `${mainLink.searchParams.get(
      "continueUrl"
    )}?apiKey=${mainLink.searchParams.get(
      "apiKey"
    )}&oobCode=${mainLink.searchParams.get(
      "oobCode"
    )}&mode=${mainLink.searchParams.get(
      "mode"
    )}&email=${email}&keepMeLogin=true`;
    localStorage.setItem("emailToVerify", email);

    return newUrl.replace("emailVerify", "linkVerify");
  } catch (e) {
    console.error("Autologin Error: ", e);
    return false;
  }
};

const isLoggedIn = () => {
  return (
    localStorage.getItem("AUTH_TOKEN") &&
    localStorage.getItem("AUTH_TOKEN").length > 0
  );
};

export { getSignInLink, isLoggedIn };
