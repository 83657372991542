/**
 * Shows all the alert message for a shift item.
 * Ex: Alert Message when a shift facility require a covid test.
 */

import { alertCircle, addCircle } from "ionicons/icons";
import React from "react";
import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from "@ionic/react";

import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";
import { ShiftInlineAlertsProps, ShiftItemCategory } from "../model";

const CovidStatusText = () => {
  return (
    <IonRow class="ion-justify-content-center ion-align-items-center">
      <IonCol size="12" className="shift-item__inline-alerts">
        <IonLabel className="inline-alert">
          <IonIcon icon={alertCircle} className="inline-alert-icon covid-pay" />
          COVID-19 positive facility: Hazard Pay is included in the pay rate
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};

const CovidTestingAvailabilityText: React.FC<{
  message?: string;
}> = ({ message }) => (
  <IonRow class="ion-justify-content-center ion-align-items-center">
    <IonCol size="12" className="shift-item__inline-alerts">
      <IonLabel className="inline-alert">
        <img
          src="../../assets/icons/test-tubes.svg"
          alt="covid-test"
          className="inline-alert-icon covid-test"
        />
        {message}
      </IonLabel>
    </IonCol>
  </IonRow>
);

CovidTestingAvailabilityText.defaultProps = {
  message: "This facility performs an on-site COVID test before each shift.",
};

const ShiftInlineAlerts = ({ shift, category }: ShiftInlineAlertsProps) => {
  const { missingDocs } = useMissingDocuments(shift);

  const isCovidTestAvailable =
    shift?.facility?.covidData?.testingAvailabilityOnsite;
  const hasMissingDocs = category === ShiftItemCategory.MISSING_CBH_DOCUMENTS;

  const MissingDocumentsText = () => {
    const missingDocsNum = missingDocs ? missingDocs.size : 0;
    const documentsPlural = missingDocsNum > 1 ? "documents" : "document";
    return (
      <IonRow class="ion-justify-content-center ion-align-items-center">
        <IonCol size="12" className="shift-item__inline-alerts">
          <IonLabel className="inline-alert">
            <IonIcon
              icon={addCircle}
              className="inline-alert-icon missing-doc"
            />
            {"You're missing "}
            <span className="missing-doc-count">{missingDocsNum}</span>
            {` required ${documentsPlural}`}
          </IonLabel>
        </IonCol>
      </IonRow>
    );
  };

  const facilityIsCovidActive = shift.facility?.covidData?.covidActive;
  return (
    <IonGrid>
      {facilityIsCovidActive && <CovidStatusText />}
      {!hasMissingDocs && isCovidTestAvailable && (
        <CovidTestingAvailabilityText />
      )}
      {hasMissingDocs && <MissingDocumentsText />}
    </IonGrid>
  );
};

export { ShiftInlineAlerts };
