import { IonCard, IonItem, IonLabel, IonNote, IonIcon } from "@ionic/react";
import "./style.scss";
import { CancelShiftConfig, AttendanceScorePolicyConfiguration } from "./types";
import { informationCircle } from "ionicons/icons";
import { defaultAttendanceScorePolicy } from "./constants";

export const AttendanceScorePolicyDetailCard = ({
  policy,
}: {
  policy: AttendanceScorePolicyConfiguration | undefined;
}) => {
  const { workShift, cancelShift, noCallNoShow } =
    policy ?? defaultAttendanceScorePolicy;

  const formatLeadTime = ({ leadTime }: CancelShiftConfig) => {
    if (leadTime[1]) {
      return leadTime.join("-");
    }
    return `${leadTime[0]}+`;
  };

  return (
    <IonCard
      class="attendance-score-policy-detail"
      data-testid="attendance-score-policy-detail-card"
    >
      <div className="attendance-score-policy-detail-header">
        <IonItem lines="none">
          <h1 className="ratings-card-title">Attendance Score Points</h1>
        </IonItem>
        <IonItem lines="none" className="ratings-card-sub-title">
          How your Attendance Score changes based on your activity
        </IonItem>
      </div>
      {workShift?.points && (
        <IonItem lines="none">
          <IonLabel slot="start">{`Work a shift`}</IonLabel>
          <IonNote
            slot="end"
            color="success"
          >{`+${workShift?.points}`}</IonNote>
        </IonItem>
      )}

      {noCallNoShow?.points && (
        <IonItem lines="none">
          <IonLabel slot="start">{`No Call No Show`}</IonLabel>
          <IonNote
            slot="end"
            color="danger"
          >{`${noCallNoShow.points}`}</IonNote>
        </IonItem>
      )}

      {cancelShift?.length && (
        <>
          <IonItem lines="none">
            <IonLabel slot="start">{`Cancel a shift:`}</IonLabel>
          </IonItem>

          {cancelShift.map((item: CancelShiftConfig, index: number) => {
            return (
              <IonItem lines="none" key={index}>
                <IonLabel>{`${formatLeadTime(
                  item
                )} hours in advance`}</IonLabel>
                <IonNote slot="end" color="danger">{`${item.points}`}</IonNote>
              </IonItem>
            );
          })}
        </>
      )}
    </IonCard>
  );
};
