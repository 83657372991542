import { getRecommendations, getShift } from "./api";
import { Store } from "../store/store.model";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonModal,
  IonButton,
  IonList,
  IonCard,
  IonCardContent,
  IonAlert,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import _, { get } from "lodash";
import moment from "moment-timezone";

import { logEvent } from "src/lib/analytics";
import { Facility, Shift } from "src/lib/interface";

import { InstantBookShiftItem } from "../dayView/instantBookShiftItem";
import { USER_EVENTS } from "../../constants/userEvents";
import { FacilityDetails } from "../dayView/facilityDetails";
import { instantPayStatus, isHCPInstantPayAllowed } from "../dayView/api";

const getShiftFlag = (flag: "holidayCheck" | "claimCheck") => {
  const parsedShiftFlag = localStorage.getItem(flag);
  if (!parsedShiftFlag) return;
  return JSON.parse(parsedShiftFlag);
};

const ShiftRecommendationPage = () => {
  const agent = useSelector((state: Store) => state.session.agent);
  const { shiftId } = useParams() as any;
  const payVersion = useSelector((state: Store) =>
    get(state, "session.payVersion")
  );
  const [recommendationShifts, setRecomendationShifts] = useState<Shift[]>([]);
  const [originalShift, setOriginalShift] = useState<Shift>({});
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [canClaim, setCanClaim] = useState<boolean>(false);
  const [openHolidayAlert, setOpenHolidayAlert] = useState<boolean>(false);
  const [facilityDetails, setFacilitydetails] = useState<Facility>();
  const [shiftDetails, setShiftDetails] = useState<Shift>();
  const [instantBookShifts, setInstantBookShifts] = useState<Shift[]>([]);
  const [clickedInterestInShift, setClickedInterestInShift] = useState(false);
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isHoliday) {
      return;
    }
    const holidayCheck = getShiftFlag("holidayCheck");
    if (!holidayCheck) {
      setOpenHolidayAlert(true);
      localStorage.setItem("holidayCheck", "true");
    }
  }, [isHoliday]);

  useEffect(() => {
    const loadRecommendations = async () => {
      const { recommendations } = await getRecommendations(
        shiftId,
        agent?.userId
      );
      const { shiftInfo } = await getShift(shiftId);
      setOriginalShift(shiftInfo);

      if (recommendations.length > 0) {
        setRecomendationShifts(recommendations);
      } else {
        history.push("/home/openShifts");
      }
    };
    loadRecommendations();
  }, [shiftId]);

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  const handleShiftFlagFound = (type: "holiday" | "claim") => {
    if (type === "holiday" && !isHoliday) {
      setIsHoliday(true);
    } else if (type === "claim" && !canClaim) {
      setCanClaim(true);
    }
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(undefined);
    setShiftDetails(undefined);
  };

  const closeHolidayAlert = () => {
    setOpenHolidayAlert(false);
  };

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    isInstantBook: boolean
  ) => {
    if (isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setFacilitydetails(shift.facility);
    setShiftDetails(shift);
  };

  const onShiftClaim = (shiftId) => {
    const updatedList = recommendationShifts.map((shift, idx) => {
      if (shift.shiftId == shiftId) {
        shift["isClaimed"] = true;
      }
      return shift;
    });
    setInstantBookShifts(updatedList);
    setClickedInterestInShift(true);
  };

  const fetchHcpInstantPayStatus = async () => {
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
  };

  const handleShiftActivityLog = (shiftId) => {
    //Getting Mark Interest Shifts and filtering the null items
    const markInterestShifts = recommendationShifts.filter((el) => {
      return (
        el != null &&
        instantBookShifts.findIndex((k) => k.shiftId === el._id) < 0
      );
    });

    // Combining mark and self claim shifts
    const combinedShifts = instantBookShifts.concat(markInterestShifts);

    //Using to set the position of the shift
    let currentIndex = 1;

    //filtering the mark interest shifts and is instant check
    const shiftsToLog = combinedShifts.reduce<Shift[]>((filtered, option) => {
      if (
        option.interested?.indexOf(agent?.userId as string) === -1 &&
        !option["isClaimed"]
      ) {
        const shift = {
          ...option,
          position: currentIndex,
          isInstantBook:
            instantBookShifts.findIndex((a) => a.shiftId === option.shiftId) >
            -1,
        };
        filtered.push(shift);
      }
      currentIndex = currentIndex + 1;
      return filtered;
    }, []);
  };

  const onViewAllShifts = () => {
    history.push("/home/openShifts");
  };

  return (
    <Fragment>
      <IonPage>
        <IonHeader no-border>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                text=""
                defaultHref="/home/openShifts"
                mode="ios"
              />
            </IonButtons>
            <IonTitle>Replacement Shifts</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {originalShift && (
            <IonCard>
              <IonCardContent>
                {`These are recommended replacement shifts for ${
                  originalShift.facility?.name
                } canceled ${originalShift.name} shift on ${moment(
                  originalShift.start
                ).format("YYYY-MM-DD")}`}
              </IonCardContent>
            </IonCard>
          )}
          <IonList class="shift-list1">
            {recommendationShifts.map((shift) => (
              <InstantBookShiftItem
                shiftPay={shift.finalPay}
                key={shift.shiftId}
                shift={shift}
                shiftName={shift.facility?.name as string}
                handleShiftFlagFound={handleShiftFlagFound}
                onFacilityDetailsClick={onClickOnItemFacilityDetails}
                isAnimated={false}
                onShiftClaim={onShiftClaim}
                isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                payVersion={payVersion}
                handleShiftActivityLog={handleShiftActivityLog}
              />
            ))}
          </IonList>
          <IonButton
            fill="outline"
            expand="block"
            className="ion-margin"
            onClick={onViewAllShifts}
          >
            View all open shifts
          </IonButton>
        </IonContent>
      </IonPage>
      {/* <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && (
          <FacilityDetails
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails}
          />
        )}
      </IonModal> */}
      <IonAlert
        cssClass="shift-alerts"
        isOpen={openHolidayAlert}
        onDidDismiss={closeHolidayAlert}
        subHeader="You just clicked on a Holiday Shift!"
        message={`The Holiday Shift you just clicked on indicates a Facility will pay time-and-a-half as a means of thanking you for working on this particular day.<br /><br />
<strong>Interested in finding more of these Time-and-a-Half Shifts?</strong><br /><br />

Look out for the shifts with Holiday Icons ( examples include: 🎄, 🎊, ⭐️) Shifts with Holiday Icons represent time-and-half pay. Enjoy!`}
        buttons={["OK"]}
      />
    </Fragment>
  );
};

export { ShiftRecommendationPage };
