import { IonItem } from "@ionic/react";
import { get, isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";

import { InfoTitleItem } from "./infoTitleItem";
import { WorkerSignatureProps } from "./model";
import { SignatureContainer } from "./signatureContainer";

const WorkerSignature: React.FC<WorkerSignatureProps> = ({
  shift,
  onChange,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    const { unit, clockInOut, lunchInOut, facility = {}, timecard } = shift;
    const { files = [] } = timecard || {};
    const noTimecardUploaded =
      facility.verificationPreference?.usesTimesheets && isEmpty(files);

    const signatureDisabled =
      isEmpty(unit) ||
      isEmpty(clockInOut) ||
      isEmpty(lunchInOut) ||
      noTimecardUploaded ||
      false;

    setIsDisabled(signatureDisabled);
  }, [shift]);

  if (isDisabled) return <Fragment />;

  const agentName = get(shift, "agent.name", "");

  return (
    <Fragment>
      <InfoTitleItem>
        I, {agentName} verify that this information is correct
      </InfoTitleItem>
      <IonItem lines="none">
        <SignatureContainer onChange={onChange} />
      </IonItem>
    </Fragment>
  );
};

export { WorkerSignature };
