import request from "superagent";
import { getAuthHeader } from "../../superagent";
import { environment } from "../../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import {
  Agent,
  SalesforceContactsResult,
  SalesforceCreateContactResult,
  Shift,
} from "src/lib/interface";
import {
  CheckEmailResponse,
  CheckrFeatureFlagValidationResponse,
  CheckrResponse,
  OnboardingApiData,
  RequestOpenShiftOptions,
  DoBPageFeatureFlagValidationResponse,
  UpdateUnder18Data,
} from "./model";
import { Requirement } from "@store/documents/model";
import { PackageType } from "../constants/BACKGROUND_CHECK_CONSTANTS";
import { logFailedApiRetry } from "../../utils/api_retry";

const updateAgentData = async (updateData: OnboardingApiData): Promise<any> => {
  try {
    const { body } = await request
      .put(`${environment.baseUrl}/onboarding/updateAgent`)
      .set(await getAuthHeader())
      .send(updateData);
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const updateUnder18 = async (
  updateUnder18Data: UpdateUnder18Data
): Promise<void> => {
  try {
    await request
      .put(`${environment.baseUrl}/onboarding/updateUnder18`)
      .set(await getAuthHeader())
      .send(updateUnder18Data);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const getMSA = async (zipCode: string): Promise<any> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/msa/search/${zipCode}`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/msa/search/${zipCode}`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const saveSalesforceContact = async (
  agentId: string
): Promise<SalesforceCreateContactResult> => {
  return await request
    .post(`${environment.baseUrl}/onboarding/salesforce/saveContact`)
    .set(await getAuthHeader())
    .send({ agentId })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

const fireVerifyDocumentZapierHook = async (
  agent: Agent,
  requirement: {
    document: Requirement;
    url?: string;
  }
): Promise<void> => {
  return await request
    .post(`${environment.baseUrl}/onboarding/document/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent, requirement })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

const fireVerifyLicenseZapierHook = async (agent: Agent): Promise<void> => {
  return await request
    .post(`${environment.baseUrl}/onboarding/license/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent })
    .then(({ body }) => body)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

const checkIfEmailExists = async (
  email: string
): Promise<CheckEmailResponse> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/user/checkIfEmailExists`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/user/checkIfEmailExists`);
        return true;
      })
      .set(await getAuthHeader())
      .query({ email });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const checkIfPhoneExists = async (
  phone: string
): Promise<CheckEmailResponse> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/user/checkIfPhoneExists`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/user/checkIfPhoneExists`);
        return true;
      })
      .set(await getAuthHeader())
      .query({ phone });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const completeSignup = async (): Promise<void> => {
  try {
    await request
      .put(`${environment.baseUrl}/onboarding/completeSignup`)
      .set(await getAuthHeader());
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const fetchPotentialShifts = async (
  options: RequestOpenShiftOptions
): Promise<Shift[]> => {
  return await request
    .get(`${environment.baseUrl}/calendar/potentialOpenShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/potentialOpenShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body.openAgentShifts)
    .catch((error) => {
      logApiFailureEvent(error);
      throw error;
    });
};

const showSSNPage = async (): Promise<any> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/onboarding/validateSsnFeatureFlag`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/onboarding/validateSsnFeatureFlag`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const createCheckrInvite = async (
  email: string | undefined,
  packageType: PackageType
): Promise<CheckrResponse> => {
  try {
    const { body } = await request
      .post(`${environment.baseUrl}/onboarding/checkr/invitation`)
      .set(await getAuthHeader())
      .send({ email, packageType });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const showCheckrInviteLink =
  async (): Promise<CheckrFeatureFlagValidationResponse> => {
    try {
      const { body } = await request
        .get(
          `${environment.baseUrl}/onboarding/validateCheckrBackgroundCheckFeatureFlag`
        )
        .retry(1, (err) => {
          logFailedApiRetry(
            err,
            `/onboarding/validateCheckrBackgroundCheckFeatureFlag`
          );
          return true;
        })
        .set(await getAuthHeader());
      return body;
    } catch (error) {
      logApiFailureEvent(error);
      throw error;
    }
  };

const verifyEmail = async (email: string): Promise<boolean> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/onboarding/validateEmail`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/onboarding/validateEmail`);
        return true;
      })
      .set(await getAuthHeader())
      .query({ email });
    return body?.isEmailDomainValid;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const showDoBPage = async (): Promise<DoBPageFeatureFlagValidationResponse> => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/onboarding/validateDoBFeatureFlag`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/onboarding/validateDoBFeatureFlag`);
        return true;
      })
      .set(await getAuthHeader());
    return body?.data;
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const markAgentActive = async (userId): Promise<any> => {
  try {
    const { body } = await request
      .put(`${environment.baseUrl}/onboarding/operations/markActive`)
      .set(await getAuthHeader())
      .send({ userId });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export {
  updateAgentData,
  checkIfEmailExists,
  completeSignup,
  fetchPotentialShifts,
  saveSalesforceContact,
  fireVerifyDocumentZapierHook,
  fireVerifyLicenseZapierHook,
  getMSA,
  showSSNPage,
  createCheckrInvite,
  showCheckrInviteLink,
  checkIfPhoneExists,
  verifyEmail,
  showDoBPage,
  markAgentActive,
  updateUnder18,
};
