import { CheckrApplicationStatus } from "@app/documents/model";
import { Dispatch } from "redux";
import { ActionType, HcpRequirementResponse } from "./model";

const setSelectedRequirement =
  (requirement: HcpRequirementResponse, requirementType: string) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: ActionType.SET_SELECTED_REQUIREMENT,
      data: {
        requirement,
        requirementType,
      },
    });
  };

const setCheckrApplicationStatus =
  (checkrApplicationStatus: CheckrApplicationStatus) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: ActionType.SET_CHECKR_APPLICATION_STATUS,
      data: checkrApplicationStatus,
    });
  };

export { setSelectedRequirement, setCheckrApplicationStatus };
