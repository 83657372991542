import React, { useEffect, useState } from "react";

import { getZendeskJWTMessagingSDK } from "../api/zendesk";
import { environment } from "@env/environment";

const { zendesk } = environment;

type JwtFnCallback = (token: string) => void;

declare global {
  interface Window {
    zE: Function;
  }
}

const useZendeskMessaging = (): { webWidgetRendered: Boolean } => {
  const [webWidgetRendered, setWebWidgetRendered] = useState<boolean>(false);

  // Doc ref - https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/#authentication
  useEffect(() => {
    if (!webWidgetRendered) return;
    window.zE("messenger", "loginUser", function (callback: JwtFnCallback) {
      getZendeskJWTMessagingSDK().then(callback);
    });
  }, [webWidgetRendered]);

  useEffect(() => {
    // Doc ref - https://support.zendesk.com/hc/en-us/articles/4500748175258-Installing-the-Web-Widget-in-a-website-or-Help-Center
    const script = document.createElement("script");
    script.id = zendesk.messagingWidgetID ?? "";
    script.src = zendesk.messagingWidgetUrl ?? "";
    document.body.appendChild(script);
    script.addEventListener("load", () => setWebWidgetRendered(true));

    return () => {
      window.zE("messenger", "logoutUser");
      document.body.removeChild(script);
    };
  }, []);

  return { webWidgetRendered };
};

export default useZendeskMessaging;
