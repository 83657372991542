/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { environment } from "@env/environment";
let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${environment.stripePublicKey}`);
  }
  return stripePromise;
};

export default getStripe;
