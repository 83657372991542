import { useHistory } from "react-router";

import { chevronForward, documentTextOutline } from "ionicons/icons";
import { IonCard, IonIcon, IonItem, IonLabel } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { getICAgreementStatus } from "../../featureFlag/api";
import { OnboardingRouterPath } from "../../routing/constant/onboardingRoute";
import { TabRouterPath } from "../../routing/constant/tabRoute";

const UpdatedContractorAgreement: React.FC<{ agentId: string }> = ({
  agentId,
}) => {
  const [showUpdatedContractorAgreement, setShowUpdatedContractorAgreement] =
    useState(false);
  const history = useHistory();

  useEffect(() => {
    const checkAgentICAgreementStatus = async (agentId) => {
      const agentAgreementStatus = await getICAgreementStatus(agentId);
      if (agentAgreementStatus?.shouldSignAgreement) {
        setShowUpdatedContractorAgreement(true);
      }
    };
    checkAgentICAgreementStatus(agentId);
  }, [agentId]);

  const openICAgreement = () => {
    history.push(TabRouterPath.UPDATED_CONTRACTOR_AGREEMENT, {
      redirectTo: history.location.pathname,
    });
  };

  return (
    <Fragment>
      {showUpdatedContractorAgreement && (
        <IonCard>
          <IonItem class="ion-margin-bottom">
            <IonIcon icon={documentTextOutline} />
            <IonLabel>
              <h2 style={{ marginLeft: 15, color: "darkred" }}>
                Updated Contractor Agreement
              </h2>
            </IonLabel>
          </IonItem>
          <IonItem
            class="license-content ion-margin-horizontal"
            lines="none"
            onClick={openICAgreement}
            style={{ cursor: "pointer" }}
          >
            <h6 style={{ marginBottom: 0 }} slot="start">
              Review and Sign
            </h6>
            <IonIcon slot="end" icon={chevronForward} />
          </IonItem>
        </IonCard>
      )}
    </Fragment>
  );
};

export { UpdatedContractorAgreement };
