import { TypedUseSelectorHook, useSelector } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { rootReducer } from "./reducer";
import { Store } from "./store.model";

const initialState = {};

const enhancers: any[] = [];
const middleware = [thunk];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window["__REDUX_DEVTOOLS_EXTENSION__"];

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension({ trace: true }));
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

const useAppSelector: TypedUseSelectorHook<Store> = useSelector;

export { store, composedEnhancers, useAppSelector };
