import React, { useState, useEffect } from "react";
import { IonItem, IonToggle } from "@ionic/react";
import { ToggleChangeEventDetail } from "@ionic/core";

const TrayAssemblyForm = ({
  hasTrayAssemblyExperience,
  experience,
  updateDetails,
  agentId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [trayExperience, setTrayExperience] = useState<boolean>(false);

  useEffect(() => {
    setTrayExperience(hasTrayAssemblyExperience);
  }, [hasTrayAssemblyExperience]);

  const onToggleChange = async (
    event: CustomEvent<ToggleChangeEventDetail>
  ) => {
    const { checked } = event.detail;
    try {
      setTrayExperience(checked);
      setLoading(true);
      const specialities = {
        experience,
        hasTrayAssemblyExperience: checked,
        hasSedationExperience: false,
        experienceDetails: {},
      };
      await updateDetails(specialities, agentId);
    } catch (error) {
      setTrayExperience(!checked);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IonItem lines="none">
        Tray Assembly Experience
        <IonToggle
          disabled={loading}
          slot="end"
          color="primary"
          checked={trayExperience}
          onIonChange={onToggleChange}
        />
      </IonItem>
    </>
  );
};

export default TrayAssemblyForm;
