import React from "react";
import { RecordTimeButton } from "../components/recordTime";
import { ShiftActionFooter } from "./shiftActionFooter";
import { ShiftActionFooterProps } from "./types";

export const AttendancePolicyShiftActionFooter: React.VFC<
  ShiftActionFooterProps
> = ({
  shift,
  isShiftLoading,
  hasMissingDocs,
  canShowUploadButton,
  ...recordTimeProps
}) => {
  return (
    <>
      {canShowUploadButton && (
        <RecordTimeButton shift={shift} {...recordTimeProps} />
      )}
      <ShiftActionFooter
        isShiftLoading={isShiftLoading}
        shift={shift}
        hasMissingDocs={hasMissingDocs}
        isAttendancePolicyEnabled={true}
      />
    </>
  );
};
