export const MISSING_DOCS_ALERT_INFO_MESSAGES = {
  MULTIPLE_DOCS_MISSING_CURRENTLY_MESSAGE:
    "To complete your booking, the following documents must be uploaded and approved:",
  SINGLE_DOC_MISSING_CURRENTLY_MESSAGE:
    "To complete your booking, the following document must be uploaded and approved:",
  // visible missing docs message when we have only visible docs missing or both visible & invisible docs missing
  MULTIPLE_VISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "You have documents that will expire before this shift. These must be uploaded and approved before you can book:",
  SINGLE_VISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "You have a document that will expire before this shift. This must be uploaded and approved before you can book:",
  // invisible missing docs message when we have only invisible docs missing
  ONLY_MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "You have documents that will expire before this shift. These will be renewed by Clipboard Health more than 14 days before they expire.",
  ONLY_SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "You have a document that will expire before this shift. This will be renewed by Clipboard Health more than 14 days before they expire.",
  // invisible missing docs message when we have both visible & invisible docs missing
  MULTIPLE_INVISIBLE_DOCS_MISSING_IN_FUTURE_MESSAGE:
    "And these documents will be renewed by Clipboard Health more than 14 days before they expire:",
  SINGLE_INVISIBLE_DOC_MISSING_IN_FUTURE_MESSAGE:
    "And this document will be renewed by Clipboard Health more than 14 days before they expire:",
};
