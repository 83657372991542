import React from "react";
import { ConfirmationModalProps } from "./model";
import { IonAlert } from "@ionic/react";
import "./style.scss";

const UrgentShiftsInfoModal: React.FC<ConfirmationModalProps> = ({
  visible,
  hideModal,
}) => (
  <IonAlert
    animated
    isOpen={visible}
    mode="ios"
    onDidDismiss={() => hideModal()}
    cssClass="urgent-shifts-info-modal"
    message={`
      <span><p><b>Urgent Shifts</b> are work opportunities at healthcare facilities that have become understaffed unexpectedly.</p></span>
      <br/>
      <span><p>If you book an Urgent Shift that says <b>“Now until…”</b> at the top of the shift, please leave for the facility immediately!</p></span>
      <br/>
      <span><p>Otherwise, please arrive at the facility on time.</p></span>
  `}
    buttons={[
      {
        text: "Ok",
        handler: () => hideModal(),
      },
    ]}
  />
);

export default UrgentShiftsInfoModal;
