import { Address, Location } from "src/lib/interface";

const ActionType = {
  SET_ADDRESS_EDIT_DETAILS: "SET_ADDRESS_EDIT_DETAILS",
  UPDATE_ADDRESS_LINE_1: "UPDATE_ADDRESS_LINE_1",
  UPDATE_REFERRAL_RATE: "UPDATE_REFERRAL_RATE",
};

interface AddressEditDetails {
  searchLocation?: string;
  address?: Address;
  location?: Location;
  addressLine1?: string;
}

interface AccountSettings {
  addressEditDetails?: AddressEditDetails;
}

export { ActionType, AccountSettings, AddressEditDetails };
