import { IonAlert } from "@ionic/react";
import React from "react";

import { RequestSignatoryConfirmAlertProps } from "./model";

const RequestSignatoryConfirmAlert: React.FC<
  RequestSignatoryConfirmAlertProps
> = ({ isOpen, signatory, onClose }) => (
  <IonAlert
    isOpen={isOpen}
    onDidDismiss={onClose(false)}
    header={`Request signature from ${signatory.name}, ${signatory.role}?`}
    message="Make sure to first tell them that you are requesting a signature. It's best to complete this process while they are standing next to you."
    buttons={[
      { text: "Cancel", role: "cancel" },
      {
        text: "Request",
        handler: onClose(true),
      },
    ]}
  />
);

export { RequestSignatoryConfirmAlert };
