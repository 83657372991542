import "./style.scss";

import {
  CreateAnimation,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { star, starOutline } from "ionicons/icons";
import { isEmpty, range } from "lodash";
import React from "react";

import { StarDescription } from "./constants";
import { StarButtonProps, StarRatingProps } from "./model";
import { RatingCard } from "./ratingCard";

const starValues = range(1, 6);
const StarButton: React.FC<StarButtonProps> = ({
  starValue,
  onChange,
  isSelected,
}) => {
  const onStarClick = () => onChange(starValue);
  return (
    <IonButton
      fill="clear"
      color="primary"
      mode="ios"
      size="large"
      onClick={onStarClick}
    >
      <IonIcon icon={isSelected ? star : starOutline} mode="ios" size="large" />
    </IonButton>
  );
};

const StarRating: React.FC<StarRatingProps> = ({
  rating,
  facilityName,
  onChange,
  starDescription = [],
}) => {
  let facility_name = facilityName;
  if (rating !== 0) {
    facility_name = "";
  }
  const description = !isEmpty(starDescription)
    ? starDescription
    : StarDescription;
  return (
    <RatingCard facilityName={facility_name} title={description[rating]}>
      <IonButtons className="star-container">
        {starValues.map((starValue) => (
          <StarButton
            key={starValue}
            starValue={starValue}
            onChange={onChange}
            isSelected={rating >= starValue}
          />
        ))}
      </IonButtons>
    </RatingCard>
  );
};

const AppStarRating: React.FC<StarRatingProps> = ({
  rating,
  onChange,
  starDescription,
  animate,
}) => {
  return (
    <CreateAnimation
      duration={500}
      iterations={1}
      keyframes={[
        { offset: 0, transform: "translateY(0%)" },
        { offset: 1, transform: "translateY(-104px)" },
      ]}
      play={animate}
    >
      <IonButtons className="review-star-buttons">
        {starValues.map((starValue) => (
          <StarButton
            key={starValue}
            onChange={onChange}
            starValue={starValue}
            isSelected={rating >= starValue}
          />
        ))}
      </IonButtons>
      {starDescription ? (
        <IonLabel className="review-star-description">
          {starDescription[rating - 1]}
        </IonLabel>
      ) : null}
    </CreateAnimation>
  );
};

export { AppStarRating, StarRating, StarButton };
