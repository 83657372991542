import {
  IonButton,
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar,
  IonText,
  IonSpinner,
  IonAlert,
} from "@ionic/react";
import React, { useState, FormEvent } from "react";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { customCheckmark } from "../../../icons/customCheckmark";
import { openInAppBrowser } from "src/lib/ionic-components";
import { environment } from "@env/environment";
import {
  generateDynamicLink,
  generatePaymentServiceOnboardingLink,
} from "../../onboardingStripe/api";

import { useHistory } from "react-router";
import { updateAgentData } from "./api";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import Alert from "./Alert";

const StripeOnboarding: React.FC<OnBoardingComponentProps> = ({
  agent,
  nextStagePath,
}) => {
  const [error, setError] = useState({ header: "", message: "" });
  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const [loadingCreateOnboardingLink, setLoadingCreateOnboardingLink] =
    useState<boolean>(false);
  const [showShiftSelectionAlert, setShowShiftSelectionAlert] =
    useState<boolean>(false);

  const handleStageUpdate = async (): Promise<boolean> => {
    try {
      await updateAgentData({
        stage: ONBOARDING_STAGES.STRIPE,
      });
      return true;
    } catch (error) {
      setError({
        header: "Oops! It looks like we ran into an issue",
        message:
          "We were unable to process your request. This is likely a network issue. Please try again.",
      });
      setShowError(true);
      return false;
    }
  };

  const continueToNextScreen = async () => {
    try {
      setShowShiftSelectionAlert(false);
      const isStageUpdated = await handleStageUpdate();
      if (!isStageUpdated) {
        return;
      }
      // @ts-ignore
      history.push(nextStagePath);
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, { message: error?.message });
    }
  };
  const handleCreateOnboardingLink = async () => {
    try {
      setLoadingCreateOnboardingLink(true);
      const isStageUpdated = await handleStageUpdate();
      if (!isStageUpdated) {
        setLoadingCreateOnboardingLink(false);
        return;
      }
      const redirectUrl = await generateDynamicLink(
        `${environment.webAppUrl}/home/requirementInfo`
      );
      const link = await generatePaymentServiceOnboardingLink(
        agent?.userId as string,
        {
          refreshUrl: redirectUrl,
          returnUrl: redirectUrl,
        }
      );
      setLoadingCreateOnboardingLink(false);
      openInAppBrowser(link, "_system");
    } catch (err) {
      setLoadingCreateOnboardingLink(false);
    }
  };

  function closeAlert() {
    setShowError(false);
  }

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/agentAddress"
              mode="ios"
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonAlert
        isOpen={showShiftSelectionAlert}
        onDidDismiss={() => setShowShiftSelectionAlert(false)}
        header="Skip Payment Set-Up?"
        message="It’s OK to skip now, but we’ll
          need you to set up before booking
          your first shift"
        buttons={[
          {
            text: "Skip",
            handler: () => continueToNextScreen(),
          },
          {
            text: "Never Mind",
            role: "cancel",
          },
        ]}
      />

      <Alert isOpen={showError} closeAlert={closeAlert} />

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="form-heading">
                  <h4>Fast Payments with Clipboard Health</h4>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <p>
                  We work with Stripe to send you instant payments directly to
                  your account.
                </p>
                <p>
                  Click below to complete set-up and
                  <IonText color="primary" style={{ fontWeight: "800" }}>
                    {" "}
                    remember to enter a debit card if you want instant payouts
                  </IonText>
                  . If you enter bank information for payouts, payouts will take
                  ~5 days to process.
                </p>
                <p>
                  Stripe will ask for some information to verify your account
                  and then take you immediately back to the Clipboard Health
                  App.
                </p>
              </IonCol>
            </IonRow>
          </div>

          <div
            className="signupform-footer footer-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <IonButton
              expand="block"
              size="large"
              className="ion-text-wrap ion-margin-top ion-margin-bottom continue-button"
              style={{ marginBottom: "1rem" }}
              onClick={handleCreateOnboardingLink}
            >
              {loadingCreateOnboardingLink ? (
                <IonSpinner color="light" name="crescent" />
              ) : (
                "Finish Set Up To Get Paid"
              )}
            </IonButton>
            <IonButton
              color="light"
              mode="ios"
              onClick={() => {
                setShowShiftSelectionAlert(true);
              }}
            >
              <span
                style={{
                  color: "#727272",
                  borderBottom: "1px solid #727272",
                  paddingBottom: "3px",
                  fontSize: "1rem",
                }}
              >
                Not Now
              </span>
            </IonButton>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
};

export { StripeOnboarding };
