import { PaymentServiceAccountStatus } from "src/lib/interface";

interface CreatePaymentServiceAccountDTO {
  agentId: string;
  email: string;
  msa: string;
  individual: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    birthday?: string;
    address?: {
      line1?: string;
      postalCode?: string;
      city?: string;
      state?: string;
    };
  };
}

const MINUTES_BEFORE_ASK_ONBOARD_AGAIN = 15;
enum OnboardingSteps {
  WELCOME_SCREEN = "WELCOME_SCREEN",
  ACCEPT_AGREEMENTS = "ACCEPT_AGREEMENTS",
}

const ShouldBeOnboardedStatuses = [
  PaymentServiceAccountStatus.ACCOUNT_CREATED,
  PaymentServiceAccountStatus.ACCOUNT_NEEDS_UPDATE,
  PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING,
  PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING,
];

const StripeEnabledStatuses = [
  PaymentServiceAccountStatus.STANDARD_PAYOUT_ENABLED,
  PaymentServiceAccountStatus.INSTANT_PAYOUT_ENABLED,
];

export {
  CreatePaymentServiceAccountDTO,
  OnboardingSteps,
  MINUTES_BEFORE_ASK_ONBOARD_AGAIN,
  ShouldBeOnboardedStatuses,
  StripeEnabledStatuses,
};
