import React, { useState } from "react";
import { IonActionSheet } from "@ionic/react";
import { AttendancePolicyWorkerCancelModal } from "./attendancePolicyWorkerCancelModal";
import { Shift } from "src/lib/interface/src";
import { FacilityCancelledMeModal } from "./facilityCancelledMeModal";

export const AttendancePolicyCancelActionSheet: React.VFC<{
  showCancelActionSheet: boolean;
  setShowCancelActionSheet: (v: boolean) => void;
  cancelSubHeaderText: string;
  shift: Shift;
}> = ({
  showCancelActionSheet,
  setShowCancelActionSheet,
  cancelSubHeaderText,
  shift,
}) => {
  const [openWorkerCancelModal, setOpenWorkerCancelModal] = useState(false);
  const [openFacilityCancelledMeModal, setOpenFacilityCancelledMeModal] =
    useState(false);

  return (
    <>
      <IonActionSheet
        data-testid="attendance-policy-cancel-action-sheet"
        header="Confirm Cancellation"
        subHeader={cancelSubHeaderText}
        mode="ios"
        isOpen={showCancelActionSheet}
        onDidDismiss={() => setShowCancelActionSheet(false)}
        cssClass="attendance-policy-cancel-action-sheet"
        buttons={[
          {
            text: "Cancel this shift",
            role: "destructive",
            handler: () => {
              setShowCancelActionSheet(false);
              setOpenWorkerCancelModal(true);
            },
          },
          {
            text: "Facility cancelled my shift",
            handler: () => {
              setShowCancelActionSheet(false);
              setOpenFacilityCancelledMeModal(true);
            },
          },
          {
            text: "Don't make a cancellation",
            role: "cancel",
          },
        ]}
      />
      <AttendancePolicyWorkerCancelModal
        isOpen={openWorkerCancelModal}
        closeModal={() => {
          setOpenWorkerCancelModal(false);
        }}
        shift={shift}
      />
      <FacilityCancelledMeModal
        isOpen={openFacilityCancelledMeModal}
        closeModal={() => {
          setOpenFacilityCancelledMeModal(false);
        }}
        shift={shift}
      />
    </>
  );
};
