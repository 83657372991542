import "./style.scss";

import { IonCard, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { card, pencilOutline } from "ionicons/icons";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { TabRouterPath } from "../../routing/constant/tabRoute";
import { useHistory } from "react-router-dom";
import { RequirementTypes } from "../../documents/model";
import {
  CREATE_HCP_DOCUMENT,
  GET_FILTERED_DOCUMENTS_URL,
  GET_HCP_REQUIREMENTS,
  GET_REJECTED_DOCUMENT,
  GET_REQUIREMENTS_STATUS,
} from "../../documents/gql";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "../../../constants";

const LicenseDetails = ({ license, hcpId }) => {
  if (!license) {
    return <Fragment />;
  }

  const history = useHistory();
  const { number, expires, state } = license || {};
  const [licenseRequirementId, setLicenseRequirementId] = useState(null);
  const { data: hcpRequirements, loading: hcpRequirementLoading } = useQuery(
    GET_REQUIREMENTS_STATUS,
    {
      variables: { hcpId },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    try {
      if (hcpRequirements) {
        const {
          hcpRequirementStatus: { requirements },
        } = hcpRequirements;
        if (requirements) {
          const licenseRequirement = requirements.find((requirement) => {
            return requirement.name == "CNA/STNA/LVN/LPN/RN State License";
          });

          console.log(licenseRequirement);
          setLicenseRequirementId(licenseRequirement.reqId);
        }
      }
    } catch (ex) {}
  }, [hcpRequirements]);

  const onEditLicense = () => {
    logEvent(USER_EVENTS.PROFILE_CHANGE_STARTED, {
      type: "LICENSE",
    });

    const params = new URLSearchParams();
    // @ts-ignore
    params.set("requirement", licenseRequirementId);
    params.set("requirementStatus", RequirementTypes.MISSING);
    params.set("updateProfile", "true");
    params.set("isDocReplacement", "true");
    history.push(`${TabRouterPath.DOCUMENT_VIEW}?${params.toString()}`);
  };

  return (
    <IonCard>
      <IonItem class="ion-margin-bottom" button onClick={onEditLicense}>
        <IonIcon icon={card} />
        <IonLabel>
          <h2 style={{ marginLeft: 15 }}>License Details</h2>
        </IonLabel>
        {licenseRequirementId && <IonIcon icon={pencilOutline} />}
      </IonItem>
      <IonItem class="license-content ion-margin-horizontal" lines="none">
        <h6 slot="start">Number</h6>
        <h6 slot="end">{number}</h6>
      </IonItem>
      <IonItem class="license-content ion-margin-horizontal" lines="none">
        <h6 slot="start">Expires</h6>
        <h6 slot="end">{moment(expires).format("DD MMM YYYY")}</h6>
      </IonItem>
      <IonItem class="license-content ion-margin-horizontal" lines="none">
        <h6 slot="start">State</h6>
        <h6 slot="end">{state}</h6>
      </IonItem>
    </IonCard>
  );
};

export { LicenseDetails };
