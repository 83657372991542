import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { Version } from "src/lib/interface";
import { Market } from "@ionic-native/market";
import { isPlatform } from "@ionic/core";
import { IonAlert } from "@ionic/react";
import { Store } from "@store/store.model";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../store/session/session.model";
import { fetchBookingRolloutMsas, fetchVersionInfo } from "./api";
import { AppState } from "@capacitor/app";

const AppVersion: React.FC<{}> = () => {
  const [isNewVersionAvailable, setVersionAvailable] = useState<boolean>(false);
  const [isDeprecated, setIsDeprecated] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { agent, bookingRolloutMsas } = useSelector(
    (state: Store) => state.session
  );

  const [versionInfo, setVersionInfo] = useState<Version | null>(null);
  const [latestMobileVersion, setLatestMobileVersion] =
    useState<Version | null>(null);

  useEffect(() => {
    const fetchVersion = async () => {
      const { version } = await App.getInfo();
      const { platform } = await Device.getInfo();

      const response = await fetchBookingRolloutMsas();
      const { rolloutMSAs } = response || {};

      dispatch({
        type: ActionType.SET_BOOKING_ROLLOUT_MSAS,
        data: { bookingRolloutMsas: rolloutMSAs ? rolloutMSAs.split(";") : [] },
      });

      if (isEmpty(version)) {
        return;
      }

      try {
        const { versionInfo = null, latestMobileVersion = null } =
          await fetchVersionInfo(version, platform);

        if (versionInfo && latestMobileVersion) {
          setVersionInfo(versionInfo);
          setLatestMobileVersion(latestMobileVersion);
          checkAppUpdate(platform, latestMobileVersion, versionInfo);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchVersion();

    const onStateChange = (state: AppState) => {
      if (!state.isActive) return;
      fetchVersion();
    };

    App.addListener("appStateChange", onStateChange);
  }, []);

  useEffect(() => {
    if (
      !agent ||
      isEmpty(versionInfo) ||
      isEmpty(latestMobileVersion) ||
      isEmpty(bookingRolloutMsas)
    ) {
      return;
    }

    if (
      bookingRolloutMsas?.includes(
        agent.address?.metropolitanStatisticalArea as string
      ) &&
      versionInfo?.mobileVersion !== latestMobileVersion?.mobileVersion
    ) {
      setIsDeprecated(true);
      setVersionAvailable(true);
    }
  }, [agent, bookingRolloutMsas, versionInfo, latestMobileVersion]);

  const closeAlert = () => setVersionAvailable(false);

  const checkAppUpdate = (
    platform: string,
    latestMobileVersion: Version,
    versionInfo: Version
  ) => {
    if (isEmpty(versionInfo) || isEmpty(latestMobileVersion)) {
      return;
    }

    if (latestMobileVersion.mobileVersion === versionInfo.mobileVersion) {
      return;
    }

    if (latestMobileVersion.published[platform]) {
      setIsDeprecated(versionInfo.deprecated);
      setVersionAvailable(true);
    }
  };

  const updateApp = () => {
    if (isPlatform(window, "ios")) {
      Market.open("id1472962791");
    }
    if (isPlatform(window, "android")) {
      Market.open("health.clipboard.worker");
    }
  };

  const cancelButton = [
    {
      text: "Later",
      role: "cancel",
    },
  ];

  return (
    <IonAlert
      isOpen={isNewVersionAvailable}
      onDidDismiss={closeAlert}
      backdropDismiss={false}
      header="Update Available"
      message={
        "A new version of the Clipboard Health app is available. Click Update and enjoy our new features!"
      }
      buttons={[
        ...(isDeprecated ? [] : cancelButton),
        {
          text: "Update",
          handler: updateApp,
        },
      ]}
    />
  );
};

export { AppVersion };
