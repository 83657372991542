const SHIFT_STARTS_NOW = `shift:
  length: 10
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24`;
const SHIFT_ENDS_NOW = `shift:
  length: 10
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24`;
const CUSTOM_TIME_SHIFT = `shift:
  start: 2021-05-22T12:00:00.000Z
  end: 2021-05-22T12:00:00.000Z
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24`;

const ASSIGN_SHIFT_YAML_DATA = {
  STARTS_NOW: SHIFT_STARTS_NOW,
  ENDS_NOW: SHIFT_ENDS_NOW,
  CUSTOM_TIME: CUSTOM_TIME_SHIFT,
};

export { ASSIGN_SHIFT_YAML_DATA };
