import { environment } from "@env/environment";
import { formatPhone } from "src/lib/utils";
import { informationCircleOutline, stopwatchOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { geoDistance } from "src/lib/utils";
import { DistanceNote } from "src/lib/ionic-components";
import {
  IonText,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { Fragment, useState, useMemo } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { ExtraTimePayAlert } from "@app/dayView/Alerts";
import { useAppSelector } from "@store/index";
import { Facility, Shift } from "src/lib/interface";

const FacilityDetails: React.VFC<{
  shift: Shift | null;
  onFacilityDetailsClick?: (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard: boolean
  ) => void;
  showCheckInInstructions: boolean;
  onCardClick: () => void;
  showMinimalDetails?: boolean;
}> = ({
  shift,
  onFacilityDetailsClick,
  showCheckInInstructions = true,
  onCardClick,
  showMinimalDetails = false,
}) => {
  const { geoLocation } = useAppSelector(
    (state) => state?.session?.agent ?? {}
  );

  if (!shift) {
    return <Fragment />;
  }

  const facility = shift.facility!;

  const distanceInMiles: {
    distance: number;
    isApproxDistance: boolean;
  } = useMemo(() => {
    const facilityCoordinates: number[] =
      shift?.facility?.geoLocation?.coordinates ?? [];
    const agentCoordinates: number[] = geoLocation?.coordinates ?? [];
    let distanceInMiles: { distance: number; isApproxDistance: boolean } = {
      distance: 0,
      isApproxDistance: false,
    };
    if (shift.facility?.distance) {
      distanceInMiles.distance = parseFloat(
        shift.facility?.distance?.toFixed(2)
      );
      distanceInMiles.isApproxDistance =
        shift.facility?.isApproxDistance ?? false;
    } else {
      distanceInMiles.distance = geoDistance(
        agentCoordinates,
        facilityCoordinates
      );
      distanceInMiles.isApproxDistance = true;
    }
    if (distanceInMiles.isApproxDistance) {
      distanceInMiles.distance = Math.round(2 * distanceInMiles.distance) / 2;
    }
    return distanceInMiles;
  }, [shift.facility, geoLocation]);

  const dayEnd = moment().endOf("day");
  const isShiftToday = dayEnd.isAfter(shift.start);

  const [showExtraTimePayAlert, setShowExtraTimePayAlert] = useState(false);
  const ldClient = useLDClient();

  const extraTimePayEnabled =
    facility.isETPFlagEnabled &&
    facility.tbioOptions?.enabled &&
    ldClient?.variation("display-extra-time-pay", false);

  const onClickOnFacilityDetails = (displayExtraTimePayCard = false) => {
    onFacilityDetailsClick?.(shift, facility, displayExtraTimePayCard);
  };
  return (
    <Fragment>
      <IonGrid>
        <IonRow className="ion-justify-content-start ion-align-items-center no-padding">
          <IonCol className="facility-info-icon-unverified">
            <IonIcon
              className="icon"
              icon={informationCircleOutline}
              onClick={(e) => onClickOnFacilityDetails()}
            />
          </IonCol>
          <IonCol
            onClick={onCardClick}
            className="no-padding-left"
            style={{ fontSize: ".9em" }}
          >
            <IonLabel>
              <h4 style={showMinimalDetails ? { marginTop: "-2px" } : {}}>
                {facility.name}
              </h4>
              {!showMinimalDetails ? (
                <>
                  <p>{facility.fullAddress?.formatted ?? ""}</p>
                  <p>
                    {formatPhone(facility.phone ?? "", environment.mobileCode)}
                  </p>
                  <IonRow className="ion-align-items-center">
                    <div className="distance-note">
                      <DistanceNote
                        distance={distanceInMiles.distance}
                        isApproxDistance={distanceInMiles.isApproxDistance}
                        homeIcon={true}
                      />
                    </div>
                    {extraTimePayEnabled && (
                      <IonText
                        onClick={(e) => {
                          setShowExtraTimePayAlert(true);
                          e.stopPropagation();
                        }}
                      >
                        <span id="extra-time-pay-link">
                          <IonIcon size="small" icon={stopwatchOutline} /> Extra
                          Time Pay Facility
                        </span>
                      </IonText>
                    )}
                  </IonRow>
                </>
              ) : null}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
      {facility.checkInInstructions && isShiftToday && showCheckInInstructions && (
        <div onClick={onCardClick}>
          <IonCardSubtitle className="title no-text-transform ion-margin-bottom">
            Check-in Instructions
          </IonCardSubtitle>
          <p className="ellipsis">{facility.checkInInstructions}</p>
          <p className="ion-text-center no-padding">
            <u>see more</u>
          </p>
        </div>
      )}
      <ExtraTimePayAlert
        isOpen={showExtraTimePayAlert}
        onDidDismiss={() => setShowExtraTimePayAlert(false)}
        onConfirm={() => onClickOnFacilityDetails(true)}
        shift={shift}
      />
    </Fragment>
  );
};

export { FacilityDetails };
