import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { AddressBody, AddressResponse } from "src/lib/interface";
import timezoneLookup from "tz-lookup";

const updateAddress = async (
  address: AddressBody
): Promise<AddressResponse | undefined> => {
  try {
    const tmz = timezoneLookup(address.location?.lat, address.location?.lng);
    const { body, status } = await request
      .post(`${environment.baseUrl}/agentprofile/address`)
      .set(await getAuthHeader())
      .send({ ...address, tmz });
    return { ...body, status };
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { updateAddress };
