import { Shift } from "src/lib/interface";
import moment from "moment-timezone";

const rateFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getShiftTitleString = ({
  shiftStart,
  shift,
}: {
  shiftStart?: string;
  shift: Shift | null;
}): string => {
  const now = moment();
  const dayEnd = moment().endOf("day");
  const dayEndTomorrow = moment().add(1, "day").endOf("day");
  const timeSheetExists =
    shift?.timecard?.files?.length || shift?.timecardNotAvailable?.reason;

  if (
    now.isAfter(shiftStart) &&
    dayEnd.isAfter(shiftStart) &&
    timeSheetExists
  ) {
    return "";
  } else if (now.isAfter(shiftStart)) {
    return "Shift In Progress";
  } else if (now.isBefore(shiftStart) && dayEnd.isAfter(shiftStart)) {
    return "Today";
  } else if (
    dayEnd.isBefore(shiftStart) &&
    dayEndTomorrow.isAfter(shiftStart)
  ) {
    return "Tomorrow";
  } else {
    return "";
  }
};

export const formatRateWithZeroes = (rate?: number): string => {
  if (rate === undefined) {
    return "";
  }
  return rateFormatter.format(rate);
};

export const formatRate = (rate?: number): string => {
  if (rate === undefined) {
    return "";
  }
  if (Math.floor(rate) !== rate) {
    return formatRateWithZeroes(rate);
  }
  return rate.toString();
};

export const formatDuration = (duration: number): string => {
  let formattedDuration = duration.toFixed(1);
  if (duration >= 1 && formattedDuration.endsWith(".0")) {
    // if bigger than 1 and round, don't show decimals
    return Math.floor(duration).toString();
  }
  return formattedDuration;
};
