import { useMemo } from "react";
import { IonAlert } from "@ionic/react";
import { MissingDocumentsItemProps } from "../model";
import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";

const PendingDocumentsAlert = ({
  shift,
  isOpen,
  onDidDismiss,
  onConfirm,
}: MissingDocumentsItemProps) => {
  const { loading, pending } = useMissingDocuments(shift);

  // Build a documents array which truncates more than 6 documents.
  const { truncatedDocuments, isPlural, hasMoreDocuments } = useMemo(() => {
    const truncatedDocuments: string[] = [];
    let isPlural = false;
    let hasMoreDocuments = false;
    if (!pending || pending.size < 1)
      return { truncatedDocuments, isPlural, hasMoreDocuments };
    pending.forEach((documentName) => {
      if (truncatedDocuments.length < 6) {
        truncatedDocuments.push(documentName);
      }
    });

    isPlural = truncatedDocuments.length > 1;
    hasMoreDocuments = pending && pending.size > 6;

    return { truncatedDocuments, isPlural, hasMoreDocuments };
  }, [pending]);

  if (loading || !pending) return null;
  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      message={`
        <h1><img alt='alert-circle' src='assets/icons/alert-circle.svg'/> <span>${
          pending && pending.size
        }</span> Document${isPlural ? "s" : ""} Awaiting Approval</h1>
        <p>To complete your booking, the following ${
          isPlural ? "documents" : "document"
        } must be approved:</p>
        <div class='information'>
          ${
            truncatedDocuments
              .map((name) => `<p>${name}</p>`)
              .join(
                ""
              ) /* As it's not react-jsx and doesn't maintain virtual-dom, no need of key prop. */
          }
        </div>
        ${
          hasMoreDocuments
            ? "<p>Go to the documents page to see the full list</p>"
            : ""
        }
      `} /** No need wrap above HTML inside a div */
      buttons={[
        { text: "Cancel", role: "cancel" },
        { text: isPlural ? "View Docs" : "View Doc", handler: onConfirm },
      ]}
    />
  );
};

export { PendingDocumentsAlert };
