enum NFC_FAIL_STATUS {
  nfc_tag_not_working = "nfc_tag_not_working",
  nfc_not_supported = "nfc_not_supported",
}
const SHOW_NFC_NOT_WORKING_BUTTON_COUNT = 2;

export const FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION = {
  toastName: "fcf_success_toast",
  header: "Your request has been sent",
  message:
    "The shift will remain in your calendar until the facility confirms your cancellation request.",
  duration: 5000,
};

export const FACILITY_CANCEL_ME_FAILURE_MESSAGE_OPTION = {
  toastName: "fcf_failure_toast",
  header: "Oops, your request didn't go through",
  message: "Please try again or contact the facility directly.",
  duration: 5000,
};

const HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION = 4;

const USE_MOCKED_NFC_FOR_DEVELOPMENT = false;

const DOC_PAGE_ROUTE = "/home/account/documents";

const ATTENDANCE_POLICY_WORKER_CANCEL_OPTIONS: [string, string][] = [
  ["TESTED_COVID+", "Tested COVID+ at home"],
  ["SICK_OTHER_ILLNESS", "Sick - other illness"],
  ["SWITCHING_SHIFTS", "Want to switch to another shift"],
  ["TRANSPORTATION", "Transportation issues"],
  ["NONE", "None of the above"],
];

const FACILITY_CANCELLED_ME_OPTIONS: [string, string][] = [
  ["TESTED_COVID+", "Tested COVID+ at facility"],
  ["SENT_HOME_BY_FACILITY", "Sent home by the facility"],
  ["NOT_ON_SCHEDULE", "The facility said I wasn't on the schedule"],
  ["FACILITY_ASKING_SWITCH_SHIFTS", "Facility asked me to switch shifts"],
  [
    "FACILITY_CANCELLED_SHIFTS",
    "Facility notified me about cancelling my shift",
  ],
  ["NONE", "None of the above"],
];

export {
  NFC_FAIL_STATUS,
  SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
  USE_MOCKED_NFC_FOR_DEVELOPMENT,
  DOC_PAGE_ROUTE,
  ATTENDANCE_POLICY_WORKER_CANCEL_OPTIONS,
  HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION,
  FACILITY_CANCELLED_ME_OPTIONS,
};
