import { URLTextComponent } from "src/lib/interface";
import { IonText } from "@ionic/react";
import React from "react";

const URLTextRenderer: React.FC<{
  URLTextComponents: Array<URLTextComponent>;
}> = ({ URLTextComponents = [] }) => {
  return (
    <IonText>
      {URLTextComponents.map(({ localText, localLinkText, localLinkURL }) => (
        <IonText>
          {localText}
          {localLinkText && localLinkURL && (
            <a
              href={localLinkURL}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noreferrer"
            >
              {localLinkText}
            </a>
          )}
        </IonText>
      ))}
    </IonText>
  );
};

export { URLTextRenderer };
