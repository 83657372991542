const ONBOARDING_STAGES = {
  EMAIL: "agentEmail",
  NAME: "agentName",
  LICENSE: "agentLicense",
  ADDRESS: "agentAddress",
  SSN: "agentSSN",
  STRIPE: "stripeOnboarding",
  DOCUMENTS: "uploadDocuments",
  DOB: "agentDoB",
  QUALIFICATION: "agentQualification",
  CHECKR: "checkrInvite",
  FINAL: "final",
};

export { ONBOARDING_STAGES };
