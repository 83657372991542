import "./style.scss";
import { ConfirmationModalProps } from "./model";
import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import React from "react";
import { USER_EVENTS } from "../../constants/userEvents";

import { IonAlert } from "@ionic/react";

const InstantBookConfirmationModal: React.FC<ConfirmationModalProps> = ({
  shift,
  confirmationModalHandler,
  visible,
  hideModal,
}) => {
  const timeFormat = "hh:mm a";
  const timeRange = (shift) =>
    `${moment(shift.start).format(timeFormat)} - ${moment(shift.end).format(
      timeFormat
    )}`;

  const handleClickInstantBookButton = () => {
    logEvent(USER_EVENTS.TAPPED_CONFIRM_INSTANT_BOOK, {
      instant_book: true,
      last_minute: false,
      instant_pay: false,
    });
    confirmationModalHandler?.();
  };

  return (
    <>
      <IonAlert
        animated
        isOpen={visible}
        onDidDismiss={() => hideModal?.()}
        cssClass="instant-book-confirmation-modal"
        header={"Congratulations!"}
        message={`
                    We can put you directly on schedule. Can you confirm that you will work from \n <b> ${timeRange(
                      shift
                    )} (${shift?.["time"]} hrs) at \n ${
          shift?.["facility"].name
        }</b>
                    <span>Address</span>
                    <a>${shift?.["facility"].fullAddress.formatted} </a>
                    <span> Description </span>
                    <a>${
                      shift?.["description"]
                        ? shift?.["description"]
                        : "No description found"
                    }</a>
                    <span>Check-in Instructions</span>
                    <a>${
                      shift?.["checkInInstructions"] &&
                      shift?.["checkInInstructions"] !== ""
                        ? shift?.["checkInInstructions"]
                        : "Check in instructions are not available."
                    }</a>
                `}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              hideModal?.();
            },
          },
          {
            text: "I'll be there",
            role: "Ok",
            handler: () => {
              handleClickInstantBookButton();
            },
          },
        ]}
      />
    </>
  );
};

export default InstantBookConfirmationModal;
