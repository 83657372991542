import React, { useEffect, useMemo, useState } from "react";
import {
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonLabel,
  IonRadio,
  IonItem,
  IonContent,
  IonModal,
  IonIcon,
  IonButton,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";
import {
  ATTENDANCE_POLICY_WORKER_CANCEL_OPTIONS,
  HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION,
} from "../constants";
import { closeCircleSharp } from "ionicons/icons";
import { TextareaChangeEventDetail } from "@ionic/core";
import { Shift, AgentPreference } from "src/lib/interface/src";
import { useSelector } from "react-redux";
import { Store } from "src/app/store/store.model";
import { AttendanceScoreCancellationPolicy } from "src/app/attendancePolicy/types";
import { get } from "lodash";
import moment from "moment-timezone";
import { getAttendanceScoreCancellationPolicy } from "src/app/attendancePolicy/api";
import { logErrors } from "src/app/errorBoundary/api";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { AttendancePolicy } from "src/constants/attendancePolicy";
import { CancelShiftPayload } from "src/app/api/shift";
import { getOrWatchCurrentLocation } from "src/app/common/location";
import { api } from "@app/api";
import { useHistory } from "react-router-dom";
import { SHIFT_PREFERENCE } from "src/constants";

export const AttendancePolicyWorkerCancelModal: React.VFC<{
  isOpen: boolean;
  closeModal: () => void;
  shift: Shift;
}> = ({ isOpen, closeModal, shift }) => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [cancelReasonDescription, setCancelReasonDescription] =
    useState<string>("");
  const [isTextAreaActive, setIsTextAreaActive] = useState<boolean>(false);

  const {
    userId,
    preference: agentPreference,
  }: { userId: string; preference: AgentPreference } = useSelector(
    (state: Store) => get(state, "session.agent", {})
  );
  const [cancellationPolicy, setCancellationPolicy] =
    useState<AttendanceScoreCancellationPolicy>();

  const cancelLeadTime = moment(shift.start)
    .diff(moment(), "hours", true)
    .toFixed(1);

  const ldClient = useLDClient();
  const isAttendanceScorePolicyEnabled =
    ldClient?.variation(FeatureFlag.ATTENDANCE_POLICY) ===
    AttendancePolicy.ATTENDANCE_SCORE;
  const attendanceScorePolicyLinks = ldClient?.variation(
    FeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS
  );

  useEffect(() => {
    if (!isAttendanceScorePolicyEnabled || !isOpen) {
      return;
    }
    const fetchAttendanceScoreCancellationPolicy = async () => {
      try {
        const attendanceScoreCancelPolicy =
          await getAttendanceScoreCancellationPolicy(userId, cancelLeadTime);
        setCancellationPolicy(attendanceScoreCancelPolicy);
      } catch (error) {
        logErrors({
          message: JSON.stringify({
            error: error?.stack || error,
            message:
              "Oops, we can't get your attendance score cancellation policy. Please refresh the page, and if the issue persists contact Clipboard Health",
          }),
          app: "hcp-mobile",
        });
      }
    };
    fetchAttendanceScoreCancellationPolicy();
  }, [userId, cancelLeadTime, isOpen]);

  const cancelButtonText = useMemo<string>(() => {
    if (!cancellationReason) {
      return "Please select a reason";
    }
    return cancelReasonDescription
      ? "Cancel your shift"
      : "Add description to cancel";
  }, [cancellationReason, cancelReasonDescription]);

  const onCancelShift = async () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    let payload: CancelShiftPayload = {
      shiftId: shift._id as string,
      reasonType: cancellationReason,
      reasonDescription: cancelReasonDescription,
      filterMinPayRate:
        agentPreference?.minPayHourly || SHIFT_PREFERENCE.PAY_HOUR,
      filterMinPayTotal:
        agentPreference?.minPayShift || SHIFT_PREFERENCE.PAY_SHIFT,
    };

    if (cancelLeadTime <= HOURS_FROM_SHIFT_START_TO_INCLUDE_LOCATION) {
      const { location, error: positionError } =
        await getOrWatchCurrentLocation();

      if (!positionError) {
        payload.coordinates = location;
      }
    }

    await api.shift.selfCancelShift(payload);
    setLoading(false);
    onDismiss();
    history.push("/home/myShifts");
  };

  const onDismiss = () => {
    setCancellationReason("");
    setCancelReasonDescription("");
    closeModal();
  };

  return (
    <IonModal
      cssClass="attendance-policy-cancel-modal"
      data-testid="attendance-policy-worker-cancel-modal"
      isOpen={isOpen}
      swipeToClose
      backdropDismiss
      onDidDismiss={onDismiss}
    >
      <IonContent data-testid="attendance-policy-worker-cancel">
        <IonList>
          <IonRadioGroup
            value={cancellationReason}
            onIonChange={(e) => setCancellationReason(e.detail.value)}
          >
            <IonListHeader>
              <IonLabel>Select your cancellation reason</IonLabel>
              <IonIcon
                onClick={onDismiss}
                icon={closeCircleSharp}
                mode="ios"
                class="ion-float-right"
                className="cancel-button-attendance-policy-cancel-modal"
                data-testid="cancel-button-attendance-policy-worker-cancel"
              />
            </IonListHeader>

            {ATTENDANCE_POLICY_WORKER_CANCEL_OPTIONS.map(([value, text]) => (
              <IonItem key={value} className="modal-items" lines="none">
                <IonLabel>{text}</IonLabel>
                <IonRadio
                  value={value}
                  slot="start"
                  mode="md"
                  className="modal-radio"
                />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
        {cancellationReason && (
          <div className="description-container">
            <h4 className="tell-us font-600">Tell us what happened</h4>
            <IonTextarea
              onIonFocus={() => setIsTextAreaActive(true)}
              onIonBlur={() => setIsTextAreaActive(false)}
              className={`reason-description ${
                isTextAreaActive ? "active" : ""
              }`}
              placeholder="Describe why you are cancelling your shift (required)"
              data-testid="cancel-shift-reason-textarea"
              onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) =>
                setCancelReasonDescription(e.detail.value ?? "")
              }
              value={cancelReasonDescription}
            />
          </div>
        )}
      </IonContent>
      {isAttendanceScorePolicyEnabled && cancellationPolicy?.policy?.points && (
        <>
          <div
            className="cancellation-policy-message"
            data-testid="cancellation-policy-message"
          >
            This cancellation will penalize your Attendance Score by{" "}
            <span className="red-color">
              {cancellationPolicy.policy.points}
            </span>
            . Your new Attendance Score will be {cancellationPolicy.scoreResult}
            .
          </div>
          <IonButton
            href={attendanceScorePolicyLinks?.howAttendanceWorks?.url}
            target="_blank"
            fill="clear"
            expand="block"
            className="how-attendance-score-work"
          >
            {attendanceScorePolicyLinks?.howAttendanceWorks?.label}
          </IonButton>
        </>
      )}
      <IonButton
        data-testid="cancel-shift-button"
        size="large"
        expand="block"
        shape="round"
        color="primary"
        disabled={!cancelReasonDescription}
        onClick={onCancelShift}
        className="shift-cancel-button margin-bottom-30"
      >
        {isLoading ? (
          <IonSpinner name="lines" data-testid="cancel-shift-button-loading" />
        ) : (
          cancelButtonText
        )}
      </IonButton>
    </IonModal>
  );
};
