export const LICENSE_CARD_BACKGROUND_COLORS = {
  ACTIVE: "#FFFFFF",
  INACTIVE: "#EEEEEE",
};

export const LICENSE_CARD_BORDER_STYLES = {
  EXPIRED: "2px solid #CA4031",
  REJECTED: "2px solid #CA4031",
  EXPIRING: "2px solid #D88C33",
};

export const LICENSES_SUMMARY_TEXT_COLORS = {
  ACTIVE: "#000000",
  EXPIRED: "#CA4031",
  REJECTED: "#CA4031",
  EXPIRING: "#D88C33",
  PENDING: "#828282",
};

export const LICENSE_HEADER_CONTENT_COLORS = {
  DEFAULT: "#000000",
  REJECTED: "#CA4031",
};

export const LICENSE_DETAILS_HEADER_TEXT = {
  ACTIVE:
    "To update your license info, please add a new license. You can also remove this license if it’s no longer valid.",
  PENDING:
    "You submitted this license to us on {LICENSE_CREATED_AT}. Please give us up to 72 hours to validate with state authorities",
  EXPIRED: "Please add a new license if you’d like to work in this state.",
  EXPIRED_AND_HAS_PENDING_LICENSE:
    "You have a license pending approval in this state! You can remove this one now, or we’ll hide it automatically once the other is approved.",
  REJECTED:
    "This license was rejected on {LICENSE_REJECTED_AT}. Please submit a new license to work in this state!",
  REJECTED_AND_HAS_PENDING_LICENSE:
    "This license was rejected on {LICENSE_REJECTED_AT}, but you have another license pending approval in this state! You can remove this one now, or we’ll hide it automatically once the other is approved.",
};

export const LICENSE_DETAILS_INIT = {
  multiState: false,
  state: "",
  qualification: "",
  number: "",
  expiresAt: "",
};
