import { Shift, ShiftName } from "src/lib/interface";
import moment from "moment-timezone";
import { ActionType } from "./model";
import { Dispatch } from "redux";

const groupByShiftName = (shift: Shift): string => {
  if (shift.name === ShiftName.CUSTOM) {
    const hour = moment(shift.start).format("HH");

    if (hour >= "04" && hour < "12") {
      return ShiftName.AM;
    }

    if (hour >= "12" && hour < "20") {
      return ShiftName.PM;
    }

    return ShiftName.NOC;
  }

  return shift.name as ShiftName;
};

const updateundoShiftActions = (dispatch: Dispatch, undoShiftActions: any) => {
  localStorage.setItem("isSignup", "true");
  dispatch({
    type: ActionType.UNDO_INTEREST,
    data: undoShiftActions,
  });
};

const setShowToast =
  (toastName: string = "") =>
  (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_SHOW_TOAST,
      data: { showToast: toastName },
    });
  };

export { groupByShiftName, updateundoShiftActions, setShowToast };
