import { logEvent } from "src/lib/analytics";
import { AppType, LocationType } from "@app/common/location";
import styled from "@emotion/styled";
import { IonButton, IonCard, IonSpinner, isPlatform } from "@ionic/react";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { USER_EVENTS } from "../../../constants/userEvents";
import { AppModal } from "../../layout/modal";
import { actionUploadTimeCard } from "../../store/ongoingShifts";
import { SelectedFile, TimecardUploadProps } from "./model";

const CustomContainer = styled.div`
  padding: 30px 0;
  background: #f1efef;
  text-align: center;
`;

const TimecardUpload: React.FC<TimecardUploadProps> = ({
  setShowUploadTimecardModal,
  setShowImageConfirmation,
  selectedFile,
  shiftId,
  updateShift,
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState<boolean>(false);

  const { file, type } = selectedFile || ({} as SelectedFile);

  const closeTimecardUploadModal = () => setShowUploadTimecardModal(false);

  const handleFileUpload = async () => {
    logEvent(USER_EVENTS.TAPPED_TO_UPLOAD_TIMECARD_PHOTO);
    setUploading(true);
    const timecard = await dispatch<any>(
      actionUploadTimeCard(
        file,
        shiftId,
        LocationType.LIVE,
        isPlatform("capacitor") ? AppType.MOBILE : AppType.WEB
      )
    );
    updateShift({ timecard });

    setUploading(false);
    closeTimecardUploadModal();
    setShowImageConfirmation(true);
  };

  if (!selectedFile) return <Fragment />;

  const isImage = ["jpg", "jpeg", "png"].includes(type);

  return (
    <AppModal title="Upload Timecard" onBack={closeTimecardUploadModal}>
      <IonCard>
        {isImage ? (
          <img alt="timecard" src={file} />
        ) : (
          <CustomContainer>PDF</CustomContainer>
        )}
      </IonCard>
      <div className="ion-text-center ion-margin-top">
        <IonButton
          disabled={uploading}
          fill="outline"
          onClick={closeTimecardUploadModal}
        >
          Cancel
        </IonButton>
        <IonButton disabled={uploading} onClick={handleFileUpload}>
          {uploading ? (
            <Fragment>
              <IonSpinner slot="start" name="crescent" />
              Uploading
            </Fragment>
          ) : (
            "Upload"
          )}
        </IonButton>
      </div>
    </AppModal>
  );
};

export { TimecardUpload };
