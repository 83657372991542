import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { GetRatingRequest } from "./model";
import { logFailedApiRetry } from "../utils/api_retry";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getReview = async (req: GetRatingRequest) => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/rating`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/rating`);
        return true;
      })
      .set(await getAuthHeader())
      .query(req);

    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateAgentAppRatingStatus = async (userId: string) => {
  try {
    const { body } = await request
      .put(`${environment.baseUrl}/agentProfile/appRatingStatus`)
      .set(await getAuthHeader())
      .send({
        agentId: userId,
      });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { getReview, updateAgentAppRatingStatus };
