import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import { RefresherEventDetail } from "@ionic/core";
import { ShiftLoader } from "src/lib/ionic-components";
import { Facility, Shift } from "src/lib/interface";
import {
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "@store/index";
import { fetchAgentUnverifiedShifts } from "@store/ongoingShifts/apiV2";
import { FacilityDetails } from "../dayView/facilityDetails";
import { fetchCurrentTimezone } from "../dayView/api";
import { MyShiftDetailsPage } from "./shiftDetails";
import { NoMyShiftsAvailable } from "./emptyState";
import { ShiftItem } from "./shiftItem";
import { UnverifiedShiftsCount } from "../unverifiedShifts/unverifiedShiftsCount";
import { USER_EVENTS } from "../../constants";
import { addVerificationPreferencesToShiftFacility } from "../utils/shiftHelper";
import { HeaderMessagesIcon } from "./headerMessagesIcon";
import { api } from "@app/api";
import { FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION } from "./constants";
import { useDispatch } from "react-redux";
import { setShowToast } from "../store/shift/actions";

const MyShiftList = () => {
  const [isLoading, setLoading] = useState(true);
  const [isFetchingShifts, setIsFetchingShifts] = useState(true);
  const [unverifiedShifts, setUnverifiedShifts] = useState<Shift[]>([]);
  const [nextTwoDaysShifts, setNextTwoDaysShifts] = useState<Shift[]>([]);
  const [futureShifts, setFutureShifts] = useState<Shift[]>([]);
  const [sortedShifts, setSortedShifts] = useState<Shift[]>([]);
  const [facilityDetails, setFacilitydetails] = useState<
    Facility | undefined
  >();
  const dispatch = useDispatch();
  const [shiftDetails, setShiftDetails] = useState<Shift | undefined>();
  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);
  const { channels } = useAppSelector((store) => store.chatStore);
  const showShiftCancelSuccessToast = useAppSelector(
    (store) =>
      store.shiftStore.showToast ===
      FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION.toastName
  );
  const agent = useAppSelector((state) => state?.session?.agent ?? {});

  const setIsFetchingShiftsToFalse = (hasShifts) => {
    if (hasShifts > -1 && isFetchingShifts) {
      setIsFetchingShifts(false);
    }
  };

  const requestNext2DaysShifts = async (tmz: string) => {
    const nextTwoDaysShiftsList = await api.shift.fetchNextTwoDaysShifts(tmz);
    setNextTwoDaysShifts(nextTwoDaysShiftsList);
    setIsFetchingShiftsToFalse(nextTwoDaysShiftsList.length);
  };

  const requestFutureShifts = async (tmz: string) => {
    const futureShifts = await api.shift.fetchFutureShifts(tmz);
    setFutureShifts(futureShifts);
    setIsFetchingShiftsToFalse(futureShifts.length);
  };

  const requestUnverifiedList = async () => {
    const unverifiedShiftsList = await fetchAgentUnverifiedShifts();
    const facilityIds = unverifiedShiftsList
      .map((shift) => shift.facility?.userId!)
      .filter(Boolean);
    if (facilityIds.length) {
      const { data: facilitiesPreferences } =
        await api.facility.fetchFacilitiesVerificationPreferences(facilityIds);
      setUnverifiedShifts(
        addVerificationPreferencesToShiftFacility(
          unverifiedShiftsList,
          facilitiesPreferences
        )
      );
    } else {
      setUnverifiedShifts(unverifiedShiftsList);
    }
    setIsFetchingShiftsToFalse(unverifiedShiftsList.length);
  };

  const fetchShifts = async () => {
    const tmz = await fetchCurrentTimezone();

    await Promise.all([
      requestNext2DaysShifts(tmz),
      requestFutureShifts(tmz),
      requestUnverifiedList(),
    ]);
  };

  useIonViewDidEnter(async () => {
    setLoading(true);
    await fetchShifts();
    setLoading(false);
  });

  useEffect(() => {
    const allShifts = [...nextTwoDaysShifts, ...futureShifts];

    const sortedList = allShifts.sort(
      (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
    );
    setSortedShifts(sortedList);
  }, [nextTwoDaysShifts, futureShifts]);

  const justifyContent = unverifiedShifts.length ? "" : "center";

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard = false
  ) => {
    if (shift.isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    const facilityDetail = facility || shift.facility;

    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilitydetails(facilityDetail);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(undefined);
    setShiftDetails(undefined);
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setLoading(true);
    fetchShifts();
    setLoading(false);
    event.detail.complete();
  };

  let unreadChannelCount: string | number = 0;
  for (const channel of channels) {
    if (channel.unreadMessageCount)
      unreadChannelCount += channel.unreadMessageCount;
  }
  if (unreadChannelCount > 99) unreadChannelCount = "99+";

  return (
    <IonPage>
      <IonHeader color="primary" no-border>
        <IonToolbar>
          <IonTitle class="ion-no-padding ion-text-center">My Shifts</IonTitle>
          <HeaderMessagesIcon />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {unverifiedShifts.length > 0 && (
          <UnverifiedShiftsCount unverifiedShiftsData={unverifiedShifts} />
        )}
        {!sortedShifts.length && (
          <ShiftLoader loading={isLoading || isFetchingShifts} count={1} />
        )}
        {!sortedShifts.length && (
          <NoMyShiftsAvailable
            hasShifts={!!sortedShifts.length}
            isLoadingShifts={isLoading || isFetchingShifts}
            justifyContent={justifyContent}
          />
        )}
        {sortedShifts.map((shift) => {
          const channel = channels.find(
            (channel) =>
              channel.url === `${shift.facility?.userId}_${agent.userId}`
          );
          return (
            <ShiftItem
              onClickOnItemFacilityDetails={onClickOnItemFacilityDetails}
              shift={
                {
                  ...shift,
                  chatInfo: {
                    unreadMessageCount: channel?.unreadMessageCount,
                    url: channel?.url,
                  },
                } as Shift
              }
              key={shift._id}
            />
          );
        })}
      </IonContent>
      {
        <IonModal
          isOpen={!!facilityDetails}
          onDidDismiss={closeFacilityDetails}
        >
          {facilityDetails && (
            <FacilityDetails
              onClose={closeFacilityDetails}
              facility={facilityDetails}
              shift={shiftDetails}
              displayExtraTimePayCard={displayExtraTimePayCard}
            />
          )}
        </IonModal>
      }
      <IonToast
        isOpen={showShiftCancelSuccessToast}
        header={FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION.header}
        onDidDismiss={() => dispatch(setShowToast())}
        message={FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION.message}
        duration={FACILITY_CANCEL_ME_SUCCESS_MESSAGE_OPTION.duration}
        color="success"
        position="top"
        data-testid="fcf-success-message"
      />
    </IonPage>
  );
};

export { MyShiftList, MyShiftDetailsPage };
