import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
} from "@ionic/react";
import React from "react";

import { ShiftNameLoaderProps } from "./model";

const ShiftNameLoader: React.FC<ShiftNameLoaderProps> = ({
  listLength = 1,
}) => {
  const loadingList: JSX.Element[] = [];
  for (let i = 0; i < listLength; i++) {
    loadingList.push(
      <IonItem key={i}>
        <IonAvatar slot="start">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
    );
  }
  return <IonList>{loadingList}</IonList>;
};

export { ShiftNameLoader };
