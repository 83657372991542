import { formatPhone } from "src/lib/utils";
import { IonGrid, IonList } from "@ionic/react";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Store } from "../../store/store.model";
import { Timecard } from "../timecard";
import { ListData, ShiftViewListProp } from "./model";
import { getShiftInOutRange } from "./shiftRange";
import { ShiftViewItem } from "./shiftViewItem";
import { UnitInput } from "./unitInput";

const ShiftViewList: React.FC<ShiftViewListProp> = ({
  shift,
  showAgentContact,
  isEditMode,
  updateShift,
}) => {
  const [listData, setListData] = useState<ListData[][]>([]);
  const { agent } = useSelector((state: Store) => state.session);
  const shiftInfoList = useSelector((state: Store) => {
    const { ongoingShiftStore } = state;
    return ongoingShiftStore.shiftInfoList;
  });

  useEffect(() => {
    if (isEmpty(shift) || isEmpty(agent) || isEmpty(shift.facility)) return;
    if (!agent || !shift || !shift.facility) return;

    const { facility, name, start, end } = shift;
    const { verificationPreference } = facility;

    let usesTimesheets = verificationPreference?.usesTimesheets;

    if (!usesTimesheets) {
      usesTimesheets = shiftInfoList.find((shiftN) => shiftN._id === shift._id)
        ?.facility?.verificationPreference?.usesTimesheets;
    }

    const shiftDate = facility.tmz
      ? moment(start).tz(facility.tmz).format("MMM DD YYYY")
      : moment(start).format("MMM DD YYYY");

    const shiftDifference = getShiftInOutRange({ start, end }, false);

    const agentPhone = showAgentContact
      ? `(${formatPhone(agent.phone as string)})`
      : "";

    const clockInOut = getShiftInOutRange(shift.clockInOut || {});
    const lunchInOut = getShiftInOutRange(shift.lunchInOut || {});
    let unit: string | ReactNode;

    if (isEditMode) {
      unit = (
        <UnitInput
          shiftId={shift._id as string}
          defaultValue={shift.unit as string}
          updateShift={updateShift}
        />
      );
    } else {
      unit = shift.unit;
    }
    const data = [
      [
        {
          title: "Facility",
          description: facility.name,
        },
        {
          title: "Shift",
          description: `${name?.toUpperCase()} (${shiftDifference}) ${shiftDate}`,
        },
      ],
      [
        {
          title: "Worker",
          description: `${agent.name} ${agentPhone}`,
        },
        {
          title: "Clock IN/OUT",
          description: clockInOut,
        },
      ],
      [
        {
          title: "Unit",
          description: unit,
          isReact: isEditMode,
        },
        {
          title: "Break IN/OUT",
          description: lunchInOut,
        },
      ],
    ];

    if (usesTimesheets) {
      data.push([
        {
          title: "",
          description: (
            <Timecard
              shift={shift}
              editMode={isEditMode}
              updateShift={updateShift}
            />
          ),
          isReact: true,
        },
      ]);
    }

    setListData(data);
  }, [shift, showAgentContact, isEditMode, agent, updateShift]);

  return (
    <Fragment>
      <IonGrid>
        <IonList lines="none" class="ion-no-margin">
          {listData.map((itemList, index) => (
            <ShiftViewItem items={itemList} key={index} />
          ))}
        </IonList>
      </IonGrid>
    </Fragment>
  );
};

export { ShiftViewList };
