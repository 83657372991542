import React from "react";
import { ActionType } from "../store/session/session.model";
import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { IonItem, IonAlert } from "@ionic/react";
import { setNotificationPreference } from "../privacyPolicy/notificationPreference/api";
import { useDispatch } from "react-redux";
import { setPushNotificationPreference } from "../store/Notifications/actions";
import { pushNotificationProps } from "./model";

const PushNotificationAlert: React.FC<pushNotificationProps> = ({
  onClosed,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const setNotificationPopUpShown = async () => {
    const { body: agent } = await request
      .post(`${environment.baseUrl}/agentProfile/setNotificationAlertShown`)
      .set(await getAuthHeader());
    dispatch({
      type: ActionType.UPDATE_AGENT,
      data: { agent },
    });
  };

  const onClose = async (isAllow: boolean) => {
    if (isAllow) {
      await setNotificationPreference({
        notificationPreference: { key: "push", value: true },
      });
      onClosed?.();
    } else {
      await setNotificationPreference({
        notificationPreference: { key: "push", value: false },
      });
      onClosed?.();
    }
    setNotificationPopUpShown();
    dispatch(setPushNotificationPreference(false));
  };
  return (
    <IonItem lines="full">
      <IonAlert
        header={`"Clipboard Health" \n would like to send you`}
        subHeader={`Notifications may include alerts, sounds, icons badges. These can be configured in settings`}
        isOpen={isOpen as boolean}
        buttons={[
          {
            text: "Don't Allow",
            role: "cancel",
            handler: () => onClose(false),
          },
          {
            text: "Allow",
            handler: () => onClose(true),
          },
        ]}
      />
    </IonItem>
  );
};
export default PushNotificationAlert;
