import styled from "@emotion/styled";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { Store } from "@store/store.model";
import { Agent } from "src/lib/interface";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouterPath } from "../../routing/constant";
import { getAgreement } from "./api";
import "./style.scss";

const Page = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 950px;
  margin: auto;
`;

const Envolve = styled.div`
  overflow-y: auto;
  padding: 4vw 18px 18px 18px;
`;

export const ContractPage = () => {
  const agent = useSelector((state: Store) => state.session.agent);
  const [page, setPage] = useState<string>("<div>Loading...</div>");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [showSendEmailAlert, setShowSendEmailAlert] = useState(false);

  const { email } = agent as Agent;

  const emailAgreement = async () => {
    setToastMessage("The contract has been sent!");
    setShowToast(true);
    try {
      await getAgreement(true);
    } catch (error) {
      setToastMessage("There was an error on sending the contract");
      setShowToast(true);
    }
  };

  useEffect(() => {
    getAgreement().then((agreement) => setPage(agreement as string));
  }, []);

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={RouterPath.PROFILE}
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Contract Agreement</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Page>
          <IonCard
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            className="ion-margin"
          >
            <IonItem style={{ flexShrink: 0 }}>
              <IonLabel>
                <h2 style={{ marginLeft: 15 }}>See agreement below</h2>
              </IonLabel>
            </IonItem>
            <Envolve dangerouslySetInnerHTML={{ __html: page }}></Envolve>
          </IonCard>
          <IonButton
            className="ion-margin"
            style={{ marginTop: 0 }}
            onClick={() => setShowSendEmailAlert(true)}
          >
            Email Me My Agreement
          </IonButton>
        </Page>
      </IonContent>

      <IonAlert
        isOpen={showSendEmailAlert}
        onDidDismiss={() => setShowSendEmailAlert(false)}
        header={"Email Your Agreement?"}
        message={`We'll send an email to ${email}`}
        backdropDismiss={true}
        buttons={[
          {
            text: "Never Mind",
            role: "cancel",
            handler: () => setShowSendEmailAlert(false),
          },
          {
            text: "Yes, send",
            handler: emailAgreement,
          },
        ]}
      />

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        position="top"
        duration={3000}
      />
    </IonPage>
  );
};
