import request from "superagent";
import { getAuthHeader } from "../../superagent";
import { environment } from "../../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { Response } from "../../utils/response";
import { logFailedApiRetry } from "../../utils/api_retry";

const setNotificationPreference = async (
  notificationPreferences
): Promise<Response> => {
  return await request
    .patch(`${environment.baseUrl}/notificationPreferences/update`)
    .set(await getAuthHeader())
    .send({ ...notificationPreferences })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getNotificationPreference = async (): Promise<Response> => {
  return await request
    .get(`${environment.baseUrl}/notificationPreferences/fetch`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/notificationPreferences/fetch`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { setNotificationPreference, getNotificationPreference };
