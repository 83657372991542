import { SelectChangeEventDetail } from "@ionic/core";
import { IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../../store/store.model";

import { AuthorizedSignatoryProps } from "./model";

const SignatorySelect: React.FC<AuthorizedSignatoryProps> = ({
  shiftInfo,
  onChange,
}) => {
  let { authorizedSignatories = [] } = shiftInfo.facility || {};

  authorizedSignatories = useSelector((state: Store) => {
    if (authorizedSignatories.length) return authorizedSignatories;
    const { ongoingShiftStore } = state;
    const foundShift = ongoingShiftStore.shiftInfoList.find(
      (shift) => shift._id === shiftInfo._id
    );
    return foundShift &&
      foundShift.facility &&
      foundShift.facility.authorizedSignatories
      ? foundShift.facility.authorizedSignatories
      : [];
  });

  const onSignatoryChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    const { value: signatoryId } = event.detail;
    const authroized = authorizedSignatories.find(
      (signatory) => signatory._id === signatoryId
    );
    if (!authroized) return;
    onChange(authroized);
  };

  return (
    <IonSelect
      onIonChange={onSignatoryChange}
      mode="ios"
      placeholder="Select your authorized signer"
      interfaceOptions={{ header: "Select your authorized signer" }}
    >
      {authorizedSignatories.map((signatory) => (
        <IonSelectOption key={signatory._id} value={signatory._id}>
          {signatory.name}, {signatory.role}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export { SignatorySelect };
