import { RefresherEventDetail } from "@ionic/core";
import { useSelector } from "react-redux";
import { filter, get, isEmpty } from "lodash";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import {
  Agent,
  PaymentServiceAccountStatus,
  PayrollList,
  Summary as SummaryType,
} from "src/lib/interface";
import React, { useCallback, useEffect, useState } from "react";

import { dolars } from "../../utils/format";
import { InvalidPaymentAccountStatus } from "../payrollNew/models";
import { logEvent } from "src/lib/analytics";
import { ShiftList } from "./shiftList";
import { ShiftListHeader } from "./shiftListHeader";
import { ShiftListLoader } from "./shiftListLoader";
import { ShowAPIFailError } from "../404Pages/showAPIFailError";
import { Store } from "../store/store.model";
import { Summary } from "./summary";
import { USER_EVENTS } from "../../constants/userEvents";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { fetchLifeTimeEarnings, fetchPayrollData } from "./api";
import { openInAppBrowser } from "src/lib/ionic-components";
import { environment } from "@env/environment";
import {
  generateDynamicLink,
  generatePaymentServiceOnboardingLink,
} from "../onboardingStripe/api";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { useLocation } from "react-router-dom";

const getWeeklyTotalEarnings = (payroll: PayrollList[]) => {
  const total = payroll.reduce(
    (total, shift) => (total += shift.totalAmount),
    0
  );
  return dolars(total);
};

const getWeeklyTotalHours = (payroll: PayrollList[]) =>
  payroll.reduce((total, shift) => (total += shift.time), 0).toFixed(2);

const getTotalShifts = (payroll: PayrollList[]) => payroll.length;

const PayrollPage: React.FC = () => {
  const NO_SHIFTS = "No Shifts This Week";
  const userId = useSelector((state: Store) =>
    get(state, "session.agent.userId")
  );
  // @ts-ignore
  const { paymentAccountInfo } = useSelector(
    (state: Store) => state.session.agent
  );
  const [relativeWeek, setRelativeWeek] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [payroll, setPayroll] = useState<PayrollList[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [summary, setSummary] = useState<SummaryType>({} as SummaryType);
  const [shiftMessage, setShiftMessage] = useState<string>("");
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const shouldBeOnboardedInPaymentService = useShouldBeOnboarded();
  const [loadingLink, setLoadingLink] = useState<boolean>(false);
  const [toastError, setToastError] = useState({ isOpen: false, message: "" });
  const location = useLocation();

  const handleBackClick = () => setRelativeWeek(relativeWeek - 1);
  const handleForwardClick = () => setRelativeWeek(relativeWeek + 1);
  const { networkStatus } = useSelector((state: Store) => state.session);

  const onboardUser = async () => {
    if (loadingLink) return;
    try {
      setLoadingLink(true);
      const [returnUrl, refreshUrl] = await Promise.all([
        generateDynamicLink(
          `${environment.webAppUrl}${TabRouterPath.SUCCESS_ONBOARDING}`
        ),
        generateDynamicLink(`${environment.webAppUrl}${location.pathname}`),
      ]);
      const onboardingLink = await generatePaymentServiceOnboardingLink(
        agent?.userId as string,
        {
          returnUrl,
          refreshUrl,
        }
      );
      setLoadingLink(false);
      openInAppBrowser(onboardingLink, "_system");
    } catch (err) {
      setLoadingLink(false);
      setToastError({
        isOpen: true,
        message:
          "Sorry we are experiencing technical problems. Please contact support.",
      });
    }
  };
  useEffect(() => {
    async function getLifeTimeEarnings() {
      const { summary } = await fetchLifeTimeEarnings();
      setSummary(summary);
    }
    getLifeTimeEarnings();

    if (!paymentAccountInfo) return;

    if (
      paymentAccountInfo.status ===
      PaymentServiceAccountStatus.NO_DOCUMENTS_REQUIRED
    ) {
      setShowToast(true);
    }
  }, [paymentAccountInfo]);

  useIonViewDidEnter(() => {
    logEvent(USER_EVENTS.VIEWED_PAYROLL);
  });

  const getPayrollData = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoading(true);
    setShiftMessage("");
    const data = await fetchPayrollData({ relativeWeek });
    setPayroll(data);
    setLoading(false);
    if (isEmpty(data)) {
      setShiftMessage(NO_SHIFTS);
    } else {
      const message = `Earned ${getWeeklyTotalEarnings(data)} by working
            ${getWeeklyTotalHours(data)} hours in ${getTotalShifts(
        data
      )} Shifts`;
      setShiftMessage(message);
    }
  }, [relativeWeek, userId]);

  useEffect(() => {
    getPayrollData();
  }, [getPayrollData]);

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await getPayrollData();
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Payroll</IonTitle>
        </IonToolbar>
      </IonHeader>
      {shouldBeOnboardedInPaymentService && (
        <span
          style={{
            borderRadius: 4,
            backgroundColor: "#FEBCBC",
            padding: "5px 20px",
            color: "#000",
            fontSize: "12px",
            margin: 1,
          }}
        >
          You have not completed your Stripe signup. Please {` `}
          <span
            style={{ color: "#1C69D1", textDecoration: "underline" }}
            onClick={onboardUser}
          >
            click here
          </span>
          {` `}to get started with Stripe.
        </span>
      )}
      {!networkStatus?.connected ? (
        <ShowAPIFailError networkStatus={networkStatus?.connected} />
      ) : (
        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Summary
              earnings={summary.total || 0}
              shifts={summary.shifts as number}
            />
            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
                overflowY: "scroll",
                height: "70vh",
              }}
            >
              <ShiftListHeader
                shiftMessage={shiftMessage}
                handleBackClick={handleBackClick}
                handleForwardClick={handleForwardClick}
                relativeWeek={relativeWeek}
              />
              {loading ? <ShiftListLoader /> : <ShiftList shifts={payroll} />}
            </div>
          </div>
        </IonContent>
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Your Stripe account is now in processing! If this takes longer than a few hours, contact us and we'll help."
        duration={10000}
        position="top"
      />
      <IonToast
        color="danger"
        isOpen={toastError.isOpen}
        message={toastError.message}
        onDidDismiss={() => setToastError({ isOpen: false, message: "" })}
        position="top"
        duration={2000}
      />
    </IonPage>
  );
};

export { PayrollPage };
function setShiftMessage(message: string) {
  throw new Error("Function not implemented.");
}
