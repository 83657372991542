import { Shift } from "src/lib/interface";
import { useState } from "react";
import { Network } from "@capacitor/network";
import { ConnectionMode } from "@app/common/location";
import { formatShiftFinishSuccessMessage } from "../../../utils/shiftSuccessMessage";
import { hackAlertChange } from "src/app/utils/alertChangeHelper";

export type CustomAlert = {
  header: string;
  message: string;
  buttons: Array<{
    text: string;
    handler: () => void;
    cssClass?: string;
  }>;
};

export const useAlertsForShiftDetails = () => {
  const [alert, setAlert] = useState<CustomAlert | null>(null);

  const dismissAlert = () => setAlert(null);

  const alertSkipLocationConfirmation = (opts: {
    goBackBtnHandler: () => void;
    acceptNonInstantPayBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Non-InstantPay",
      message: [
        "If we cannot confirm your location, your shift will be converted to Non-InstantPay.",
        "That means you will receive payment only when the shift is verified.",
      ].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Accept Non-InstantPay",
          handler: opts.acceptNonInstantPayBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertCancelEditedTime = (opts: {
    goBackBtnHandler: () => void;
    cancelEditBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Cancel Edit",
      message: [
        "If we cannot confirm your location, you cannot edit your times.",
      ].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Do Not Edit Times",
          handler: opts.cancelEditBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertShiftFinishedSuccessfully = (opts: {
    shift: Shift;
    continueBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Nice work!",
      message: formatShiftFinishSuccessMessage(opts.shift).trim(),
      buttons: [
        {
          text: "Continue",
          handler: opts.continueBtnHandler,
        },
      ],
    });
  };

  const alertNoNetworkConnection = (opts: { isEdit?: boolean }) => {
    let firstLineOfMessage =
      "Your shift is done, but you don’t have service! Connect to WiFi or cellular data to get paid as soon as possible.";
    let secondLineOfMessage =
      "If you can’t get service now, you have up to 24 hours to connect. Just open the app to try again!";
    if (opts.isEdit) {
      firstLineOfMessage =
        "Connect to WiFi or cellular data to enable location access.";
      secondLineOfMessage = "Otherwise, you cannot edit times.";
    }
    setAlert({
      header: "No Network Connection",
      message: [firstLineOfMessage, secondLineOfMessage].join("\n\n"),
      buttons: [
        {
          text: "Later",
          handler: () => setAlert(null),
        },
        {
          text: "Try again",
          handler: async () => {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const status = await Network.getStatus();
            const connectivityMode = status.connected
              ? ConnectionMode.ONLINE
              : ConnectionMode.OFFLINE;
            if (connectivityMode === ConnectionMode.OFFLINE) {
              alertNoNetworkConnection({ isEdit: opts.isEdit ?? false });
            }
          },
        },
      ],
    });
  };

  const alertLocationAccess = (opts: {
    openLocationSettingsFn: () => void;
    skipLocationBtnHandler: () => void;
    isEdit?: boolean;
  }) => {
    setAlert({
      header: "Location Access",
      message:
        "We need to verify that you’re at the facility. Please grant us location access in your device settings.",
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip Location Confirmation",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertLocationAccess(opts);
                  },
                  cancelEditBtnHandler: opts.skipLocationBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipLocationConfirmation({
                  goBackBtnHandler: () => {
                    alertLocationAccess(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipLocationBtnHandler,
                });
              }),
        },
        {
          text: "Grant Access",
          handler: opts.openLocationSettingsFn,
        },
      ],
    });
  };

  const alertBrowserLocationAccess = () => {
    setAlert({
      header: "Open Browser Settings",
      message:
        "Please open your browser settings and re-enable location access to this site",
      buttons: [
        {
          text: "Got it",
          handler: dismissAlert,
        },
      ],
    });
  };

  const alertReturnToTheFacility = (opts: {
    facilityName?: string;
    isEdit?: boolean;
    tryAgainBtnHandler: () => void;
    skipLocationBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Too Far Away",
      message: [
        `It looks like you’re not located at ${
          opts.facilityName || "the facility"
        }.`,
        "Connecting to WiFi or stepping outside may improve your device's accuracy and help us confirm your location. Please try again when you are at the facility.",
      ].join("\n\n"),
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip Location Confirmation",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertReturnToTheFacility(opts);
                  },
                  cancelEditBtnHandler: opts.skipLocationBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipLocationConfirmation({
                  goBackBtnHandler: () => {
                    alertReturnToTheFacility(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipLocationBtnHandler,
                });
              }),
        },
        {
          text: "Try Again",
          handler: hackAlertChange(opts.tryAgainBtnHandler),
        },
      ],
    });
  };

  const alertUploadTimesheet = (opts: {
    formattedRemainingAmount: string;
    is100InstantPayEnabled?: boolean;
    uploadTimesheetFn: () => void;
  }) => {
    setAlert({
      header: "Upload Shift",
      message: opts.is100InstantPayEnabled
        ? "Please upload your timesheet so that you can be paid."
        : `Nice work! Your shift is complete, and you’ll receive the rest of your payment $${opts.formattedRemainingAmount} when the shift is verified.`,
      buttons: [
        {
          text: "Upload Later",
          handler: dismissAlert,
        },
        {
          text: "Upload Now",
          handler: opts.uploadTimesheetFn,
        },
      ],
    });
  };

  return {
    alert,
    dismissAlert,
    alertShiftFinishedSuccessfully,
    alertNoNetworkConnection,
    alertLocationAccess,
    alertBrowserLocationAccess,
    alertReturnToTheFacility,
    alertUploadTimesheet,
    alertCancelEditedTime,
  };
};

export type ShiftDetailsAlerts = ReturnType<typeof useAlertsForShiftDetails>;
