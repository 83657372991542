import { Shift, ShiftStages } from "src/lib/interface";

const getStageText = (stage: ShiftStages): string => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return "Clock In";
    case ShiftStages.LUNCH_IN:
      return "End Break";
    case ShiftStages.LUNCH_OUT:
      return "Start Break";
    case ShiftStages.CLOCK_OUT:
      return "Clock Out";
    case ShiftStages.SHIFT_TIME_DONE:
      return "Upload Your Timesheet";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "Get Facility Signature";
    default:
      return "";
  }
};

export const getStageObject = (shift: Shift, isSignatureSubmission?: any) => {
  const { verified = false, clockInOut = null, lunchInOut = null } = shift;
  if (verified || (clockInOut && clockInOut.end)) {
    if (isSignatureSubmission) {
      return {
        currentStage: ShiftStages.SHIFT_TIME_DONE,
        currentStageText: getStageText(ShiftStages.GET_FACILITY_SIGNATURE),
        nextStage: ShiftStages.SHIFT_TIME_DONE,
        nextStageText: getStageText(ShiftStages.GET_FACILITY_SIGNATURE),
        currentStageLog: "",
      };
    }
    return {
      currentStage: ShiftStages.SHIFT_TIME_DONE,
      currentStageText: getStageText(ShiftStages.SHIFT_TIME_DONE),
      nextStage: ShiftStages.SHIFT_TIME_DONE,
      nextStageText: getStageText(ShiftStages.SHIFT_TIME_DONE),
      currentStageLog: "",
    };
  }

  if (!clockInOut || !clockInOut.start) {
    return {
      currentStage: ShiftStages.CLOCK_IN,
      currentStageText: getStageText(ShiftStages.CLOCK_IN),
      nextStage: ShiftStages.LUNCH_OUT,
      nextStageText: getStageText(ShiftStages.LUNCH_OUT),
      currentStageLog: "CLOCK_IN",
    };
  }

  if (lunchInOut && (lunchInOut.end || lunchInOut.isSkipped)) {
    return {
      currentStage: ShiftStages.CLOCK_OUT,
      currentStageText: getStageText(ShiftStages.CLOCK_OUT),
      nextStage: ShiftStages.SHIFT_TIME_DONE,
      nextStageText: getStageText(ShiftStages.SHIFT_TIME_DONE),
      currentStageLog: "CLOCK_OUT",
    };
  }

  if (lunchInOut && lunchInOut.start) {
    return {
      currentStage: ShiftStages.LUNCH_IN,
      currentStageText: getStageText(ShiftStages.LUNCH_IN),
      nextStage: ShiftStages.CLOCK_OUT,
      nextStageText: getStageText(ShiftStages.CLOCK_OUT),
      currentStageLog: "END_BREAK",
    };
  }

  return {
    currentStage: ShiftStages.LUNCH_OUT,
    currentStageText: getStageText(ShiftStages.LUNCH_OUT),
    nextStage: ShiftStages.LUNCH_IN,
    nextStageText: getStageText(ShiftStages.LUNCH_IN),
    currentStageLog: "START_BREAK",
  };
};
