import React from "react";
import ReactDOM from "react-dom";
import { logEvent } from "src/lib/analytics";
import { AlertButton, alertDiv } from "./alertButton";
import { MAP_EVENTS } from "./events";
import { HomeIcon, LocationIcon } from "./icons";

// restricting map to the extreme lat lng of USA
const restriction: google.maps.MapRestriction = {
  latLngBounds: { east: 180, north: 84, south: -68, west: -180 },
};

export const googleMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  keyboardShortcuts: false,
  minZoom: 4,
  restriction,
  styles: [
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

export const googleMapContainerStyle = {
  width: "100%",
  height: "100%",
};

export const GOOGLE_MAP_CONTROL_POSITION = {
  BOTTOM: 11,
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT: 7,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP: 2,
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3,
};

export type LatLng = {
  lng: number;
  lat: number;
};

// Default location center of USA
export const defaultlongLat = {
  lng: -94.574267,
  lat: 39.084582,
};

const ButtonContainer = ({ children }: { children: React.ReactNode }) => (
  <div
    id="buttonContainer"
    style={{
      backgroundColor: "white",
      border: "2px solid white",
      borderRadius: "8px",
      boxShadow: "0 2px 6px rgba(0,0,0,.3)",
      cursor: "pointer",
      marginLeft: 12,
      marginBottom: 25,
      textAlign: "center",
    }}
  >
    {children}
  </div>
);

const HomeButton = ({ onClick }: { onClick: () => void }) => (
  <div
    onClick={onClick}
    style={{
      color: "gray",
      width: "3em",
      height: "3em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: "1px solid rgba(50,50,50,0.2)",
    }}
  >
    <HomeIcon />
  </div>
);

const LocationButton = ({ onClick }: { onClick: () => void }) => (
  <div
    onClick={onClick}
    style={{
      color: "gray",
      width: "3em",
      height: "3em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <LocationIcon />
  </div>
);

export const createCustomControl = (
  map: google.maps.Map,
  computeGoogleMapCurrentLocation: () => Promise<LatLng | undefined>,
  computeGoogleMapHome: () => LatLng,
  computeMapBounds: () => void
) => {
  const controlDiv = document.createElement("div");

  const onHomeButtonClick = () => {
    logEvent(MAP_EVENTS.TAPPED_MAP_HOME_BUTTON);
    computeMapBounds();
    map.setCenter(computeGoogleMapHome());
  };

  const onLocationButtonClick = async () => {
    logEvent(MAP_EVENTS.TAPPED_MAP_LOCATION_BUTTON);
    const currentLocation = await computeGoogleMapCurrentLocation();
    if (currentLocation) {
      map.setCenter(currentLocation);
    }
  };

  ReactDOM.render(
    <ButtonContainer>
      <HomeButton onClick={onHomeButtonClick} />
      <LocationButton onClick={onLocationButtonClick} />
    </ButtonContainer>,
    controlDiv
  );

  map.controls[GOOGLE_MAP_CONTROL_POSITION.LEFT_BOTTOM].push(controlDiv);
};

export const createCustomAlert = (
  map: google.maps.Map,
  visibleFacilities: boolean,
  boundCount: number
) => {
  const onClick = () => {
    logEvent(MAP_EVENTS.TAPPED_MAP_EMPTY_STATE);
    const mapCurrentZoomLevel = map.getZoom() as number;
    map.setZoom(mapCurrentZoomLevel - 1);
  };

  if (!visibleFacilities) {
    logEvent(MAP_EVENTS.DISPLAYED_MAP_EMPTY_STATE);
  }

  ReactDOM.render(
    <AlertButton
      boundCount={boundCount}
      onClick={onClick}
      visibleFacilities={visibleFacilities}
    />,
    alertDiv
  );
  map.controls[GOOGLE_MAP_CONTROL_POSITION.TOP_CENTER].clear();
  map.controls[GOOGLE_MAP_CONTROL_POSITION.TOP_CENTER].push(alertDiv);
};
