import React, { useEffect, useState } from "react";
import { IonAlert } from "@ionic/react";
import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import { TIME_FORMAT } from "../constants";
import { getHumanReadableTime, timeRangeFormatter } from "src/lib/utils";

import { USER_EVENTS } from "../../../constants";
import { Facility } from "src/lib/interface";
import { UrgencyType } from "../../openShifts/urgentShifts/constants";

import { isLocationEnabledAndHasFullLocationPermission } from "@app/openShifts/urgentShifts/utils";
import NoLocationPermissionBookingAlert from "@app/openShifts/urgentShifts/noLocationPermissionBookingAlert";

const ShiftBookingWarningAlert = ({
  isOpen,
  onDidDismiss,
  onConfirm,
  shift,
  isInstantBookingShift,
  isUrgentShift,
}) => {
  useEffect(() => {
    if (isOpen) {
      if (isInstantBookingShift) {
        logEvent(USER_EVENTS.VIEWED_CONFIRM_INSTANTBOOK_POPUP, {
          instant_book: true,
        });
      } else {
        logEvent(USER_EVENTS.VIEWED_CONFIRM_BOOK_POPUP);
      }
    }
  }, [isOpen, isInstantBookingShift]);

  const { tmz: facilityTimeZone } = shift.facility as Facility;

  const date = moment(shift.start).tz(facilityTimeZone).format("dddd, MMMM DD");
  let timeText = `${timeRangeFormatter(TIME_FORMAT, {
    start: shift.start,
    end: shift.end,
  })} (${getHumanReadableTime(shift.time)})`;

  let subTitle = isInstantBookingShift
    ? `Once you book this shift, it's all yours!`
    : `After you request this shift, a Clipboard Health team member will notify the facility to confirm this shift.`;

  let bodyText = isInstantBookingShift
    ? "By booking this shift, you're committing to working this shift."
    : "Once a shift is officially booked, you are committing to working that shift and any other pending requests will be cancelled.";

  if (isUrgentShift && shift.urgency === UrgencyType.COMMUTE_MODULE) {
    subTitle =
      "After you book this Urgent Shift, please leave for the facility immediately.";
    bodyText =
      "Clipboard Health will inform the facility about your assignment to this shift after you confirm.";
    timeText = `Now - ${moment(shift.end)
      .tz(facilityTimeZone)
      .format("hh:mm a")}`;
  }

  let title;

  if (isUrgentShift) {
    title = "Book This Urgent Shift";
  } else {
    title =
      (isInstantBookingShift
        ? "<img alt='lightning' src='assets/icons/lightning-blue.svg'/> Book"
        : "Request") + " This Shift";
  }

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      cssClass="shift-item-alert"
      message={`
        <h1>${title}</h1>
        <p>${subTitle}</p>
        <p>${bodyText}</p>
        <div class='information'>
          <p>${date}</p>
          <p>${timeText}</p>
          <p>${shift.facility.name}</p>
        </div>
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: isInstantBookingShift || isUrgentShift ? "Book" : "Request",
          role: "confirm",
          handler: onConfirm,
        },
      ]}
    />
  );
};

export { ShiftBookingWarningAlert };
