const CONTRACTOR_AGREEMENT_VERSIONS = {
  V1: "V1",
  V2: "V2",
};

const LATEST_CONTRACTOR_AGREEMENT = CONTRACTOR_AGREEMENT_VERSIONS.V2;
const CONTRACTOR_AGREEMENT_VERSIONS_LIST = Object.values(
  CONTRACTOR_AGREEMENT_VERSIONS
);
export {
  CONTRACTOR_AGREEMENT_VERSIONS,
  CONTRACTOR_AGREEMENT_VERSIONS_LIST,
  LATEST_CONTRACTOR_AGREEMENT,
};
