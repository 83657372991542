import "./style.scss";
import { useSelector } from "react-redux";
import { difference, filter, keyBy, isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { isPlatform } from "@ionic/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import {
  findShiftsOffImg,
  findShiftsOnImg,
  myAccountOffImg,
  myAccountOnImg,
  myShiftsOffImg,
  myShiftsOnImg,
  attendancePolicyMyShiftsOnImg,
  attendancePolicyMyShiftsOffImg,
  ratingsOnImg,
  ratingsOffImg,
} from "../routing/constant/bottomNavigationTabRoutes";
import { AccountPage } from "../account";
import { OngoingShiftSync } from "../ongoing/ongoingShiftSync";
import { WorkerAppVersion } from "../appVersion/workerAppVersion";
import { AppVersion } from "../appVersion";
import { LastMinuteShifts } from "../lastMinuteShifts";
import { NotificationsToast } from "../NotificationToast";
import { OpenShifts } from "../openShifts";
import { OpenShiftDayView } from "../dayView";
import { ShiftRecommendationPage } from "../recommendations";
import { PayrollPage } from "../payroll";
import { PayrollNewPage } from "../payrollNew";
import { ProfilePage } from "../profile";
import { ReferralPage } from "../referral";
import { AddressEdit, AddressView, SearchLocationEdit } from "../address";
import { DocumentsPage, DocumentView } from "../documents";
import { MyShiftDetailsPage, MyShiftList } from "../hcpShifts";

/**
 * Privacy Policy and Notification Routes Links
 */
import { NotificationPreferencePage } from "../privacyPolicy/notificationPreference";
import { PrivacyPolicyNotificationPage } from "../privacyPolicy";
import { PrivacyPolicyPage } from "../privacyPolicy/privacyPolicy";

import { PushNotificationComponent } from "../pushNotifications";
import { RatingPage } from "../rating";
import { ShiftSignature } from "../shiftSignature";
import { SpecialitiesPage } from "../specialities";
import { SupportPage } from "../support";
import { TabRouterPath } from "../routing/constant/tabRoute";
import { UnverifiedShiftsList } from "../unverifiedShifts/unverifiedShiftsList";
import { Store } from "../store/store.model";
import { OnboardingSucess } from "../onboardingStripe/components/OnStripeSetUp";
import { SetUpStripe } from "../onboardingStripe/components/SetUpStripe";
import { useShouldBeOnboarded } from "../onboardingStripe/hooks";
import { CoWorkerReferralPage } from "../referral/pages/CoWorkerReferral";
import { InvalidPaymentAccountStatus } from "../payrollNew/models";
import { ContractPage } from "../profile/profile/contractPage";
import { OnboardToStripe } from "../onboardingStripe";
import { ProofIncome } from "../payrollNew/proofEarning";
import { FacilityDetailPage } from "../facilityDetail";
import { Agent } from "src/lib/interface";
import { ChatPage } from "@app/chat/chat";
import NeedHelpPage from "@app/needhelp";
import HelpDetailsPage from "@app/needhelp/helpDetails";
import TrainingResourcesPage from "@app/needhelp/trainingResources";
import { UpdateProfile } from "@app/profile/profile/updateProfile";
import { DeleteData } from "@app/profile/profile/deleteData";
import { GET_REQUIREMENTS_STATUS } from "../documents/gql";
import { ChatChannelsList } from "@app/chat/chatChannelsList";
import { useFlags } from "launchdarkly-react-client-sdk";
import UrgentShiftPermissionContainer from "@app/openShifts/urgentShifts/urgentShiftPermissionContainer";
import { animationBuilder } from "src/app/utils/routeUtils";
import {
  DISMISSED_STRIPE_POPUP,
  WAS_APP_PAUSED,
  IS_SIGNUP,
} from "@app/onboardingStripe/constants";
import { HcpBonusesPage } from "@app/hcpBonuses/hcpBonuses";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";
import { AttendancePolicy } from "src/constants/attendancePolicy";
import AttendancePolicyRatingPage from "../attendancePolicy/attendancePolicyRatingPage";
import {
  locationService,
  RemoveHandler,
} from "../openShifts/urgentShifts/locationService";
import { LicenseManager } from "src/app/licenseManager";
import { LicenseDetails } from "src/app/licenseManager/licenseDetails";
import { AddLicense } from "src/app/licenseManager/addLicense";

const NavigationRoutes = [
  TabRouterPath.OPEN_SHIFTS,
  TabRouterPath.MY_SHIFTS,
  TabRouterPath.RATINGS,
  TabRouterPath.ACCOUNT,
];

const ExcludedPages = Object.freeze([
  TabRouterPath.PROFILE,
  TabRouterPath.DOCUMENT_VIEW,
]);

const AppTabsComponent: React.FC<RouteComponentProps> = ({ match }) => {
  const [tabStyle, setTabStyle] = useState({});
  const [tabActive, setTabActive] = useState<string>("");
  const { pathname } = useLocation();
  const history = useHistory();
  const isIOS = isPlatform("ios");
  const { paymentAccountInfo, license, address, name, email, phone } =
    useSelector((state: Store) => state.session.agent as Agent);
  const { userId: hcpId } = useSelector((state: Store) => state.session);

  const unreadCount = useSelector(
    (state: Store) => state.notificationStore.unreadCount
  );

  const { data } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId },
    fetchPolicy: "cache-and-network",
  });
  const ldFlags = useFlags();

  const { requirements, expired, missing, pending } =
    data?.hcpRequirementStatus || {};

  const requirementsById = keyBy(requirements, "reqId");
  const isVisibleToHCP = (reqId) => requirementsById[reqId].visibleToHCP;

  const pendingActions =
    filter(difference(missing, pending), isVisibleToHCP).length ||
    filter(difference(expired, pending), isVisibleToHCP).length;

  const shouldBeOnboarded = useShouldBeOnboarded();
  const notificationsCount = useMemo(() => {
    const count = unreadCount || 0;
    return count + (shouldBeOnboarded ? 1 : 0);
  }, [unreadCount, shouldBeOnboarded]);

  const dismissedStripePopup = localStorage.getItem(DISMISSED_STRIPE_POPUP);
  const isSignup = localStorage.getItem(IS_SIGNUP);
  const wasAppPaused = localStorage.getItem(WAS_APP_PAUSED);

  const attendancePolicy = ldFlags[FeatureFlag.ATTENDANCE_POLICY];
  const showAttendancePolicyNavigation = [
    AttendancePolicy.ATTENDANCE_SCORE,
    AttendancePolicy.UPGRADED_STATUS_QUO,
  ].includes(attendancePolicy);

  const attendancePolicyTabStyle = showAttendancePolicyNavigation
    ? {}
    : { display: "none" };
  const defaultPolicyTabStyle = showAttendancePolicyNavigation
    ? { display: "none" }
    : {};

  // For business logic refer https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2457534599/Stripe+onboarding+popup
  useEffect(() => {
    if (shouldBeOnboarded) {
      if (dismissedStripePopup) {
        return;
      }

      if (ExcludedPages.includes(pathname)) {
        localStorage.removeItem(WAS_APP_PAUSED);
        return;
      }

      if (isSignup === "true") {
        localStorage.setItem(IS_SIGNUP, "false");
      } else if (isSignup === "false" && wasAppPaused) {
        localStorage.removeItem(WAS_APP_PAUSED);
        history.replace(TabRouterPath.PAYMENT_SERVICE_ONBOARDING);
      }
    }
  }, [
    shouldBeOnboarded,
    history,
    wasAppPaused,
    isSignup,
    dismissedStripePopup,
  ]);

  useEffect(() => {
    if (NavigationRoutes.includes(pathname)) {
      if (
        pathname === TabRouterPath.RATINGS &&
        !showAttendancePolicyNavigation
      ) {
        openFindShiftsTab();
      } else {
        setTabActive(pathname);
      }
      setTabStyle({});
    } else {
      setTabStyle({ display: "none" });
    }
  }, [pathname, showAttendancePolicyNavigation]);

  useEffect(() => {
    if (!hcpId || !isPlatform("capacitor")) return;

    locationService.setUserId(hcpId);

    let removeLocationEventListener: RemoveHandler;

    (async function setLocationEventListener() {
      removeLocationEventListener =
        await locationService.addLocationEventListener((result) => {
          if (!result.user.trip) {
            locationService.stopTracking();
          }
        });
    })();

    return () => {
      if (removeLocationEventListener) {
        removeLocationEventListener();
      }
    };
  }, [hcpId]);

  const openAccountTab = () => {
    history.replace(TabRouterPath.ACCOUNT);
  };

  const openMyShiftTab = () => {
    history.replace(TabRouterPath.MY_SHIFTS);
  };

  const openFindShiftsTab = () => {
    history.replace(TabRouterPath.OPEN_SHIFTS);
  };

  const showNewPayrollPage =
    paymentAccountInfo &&
    paymentAccountInfo.enabled &&
    !InvalidPaymentAccountStatus.includes(paymentAccountInfo.status);

  return (
    <Fragment>
      <Route path={`${match.url}`} component={WorkerAppVersion} />
      <Route path={`${match.url}`} component={OngoingShiftSync} />
      <Route path={`${match.url}`} component={PushNotificationComponent} />
      <Route path={`${match.url}`} component={NotificationsToast} />
      <Route path={`${match.url}`} component={AppVersion} />

      <IonTabs>
        <IonRouterOutlet animation={animationBuilder}>
          <Route path={TabRouterPath.MY_SHIFTS} component={MyShiftList} exact />
          <Route
            path={TabRouterPath.OPEN_SHIFTS}
            component={OpenShifts}
            exact
          />
          <Route
            path={TabRouterPath.RATINGS}
            render={() => (
              <AttendancePolicyRatingPage attendancePolicy={attendancePolicy} />
            )}
            exact
          />
          <Route
            path={TabRouterPath.OPEN_SHIFT_DAY}
            component={OpenShiftDayView}
            exact
          />
          <Route
            path={TabRouterPath.SHIFT_REPLACEMENTS}
            component={ShiftRecommendationPage}
            exact
          />
          <Route path={TabRouterPath.ACCOUNT} component={AccountPage} exact />
          <Route path={TabRouterPath.CHAT} component={ChatPage} exact />
          <Route
            path={TabRouterPath.CHAT_CHANNELS_LIST}
            component={ChatChannelsList}
            exact
          />
          <Route path={TabRouterPath.SUPPORT} component={SupportPage} exact />
          <Route path={TabRouterPath.ADDRESS} component={AddressView} exact />
          <Route
            path={TabRouterPath.ADDRESS_SEARCH}
            component={SearchLocationEdit}
            exact
          />
          <Route
            path={TabRouterPath.FACILITY_DETAIL}
            component={FacilityDetailPage}
            exact
          />
          <Route
            exact
            path={TabRouterPath.UPDATED_CONTRACTOR_AGREEMENT}
            render={() => <OnboardToStripe />}
          />
          <Route
            path={`${match.url}/account/searchAddressLocation`}
            component={SearchLocationEdit}
            exact
          />
          <Route
            path={TabRouterPath.ADDRESS_EDIT}
            component={AddressEdit}
            exact
          />
          <Route
            path={TabRouterPath.SPECIALITIES}
            component={SpecialitiesPage}
            exact
          />
          <Route
            path={TabRouterPath.PAYROLL}
            exact
            render={(props) =>
              showNewPayrollPage ? (
                <PayrollNewPage></PayrollNewPage>
              ) : (
                <PayrollPage></PayrollPage>
              )
            }
          />
          <Route
            path={TabRouterPath.NEED_HELP}
            component={NeedHelpPage}
            exact
          />
          <Route
            path={TabRouterPath.HELP_DETAILS}
            component={HelpDetailsPage}
            exact
          />
          <Route
            path={TabRouterPath.TRAINING_RESOURCES}
            component={TrainingResourcesPage}
            exact
          />
          <Route
            path={TabRouterPath.PROOF_INCOME}
            component={ProofIncome}
            exact
          />
          <Route
            path={TabRouterPath.DOCUMENTS}
            component={DocumentsPage}
            exact
          />
          <Route
            path={TabRouterPath.HCP_BONUSES}
            component={HcpBonusesPage}
            exact
          />
          <Route
            path={TabRouterPath.DOCUMENT_VIEW}
            component={DocumentView}
            exact
          />
          <Route path={TabRouterPath.PROFILE} component={ProfilePage} exact />
          <Route
            path={TabRouterPath.PROFILE_CONTRACT}
            component={ContractPage}
            exact
          />
          <Route path={TabRouterPath.REFERRAL} component={ReferralPage} exact />
          <Route
            path={TabRouterPath.CO_WORKER_REFERRAL}
            component={CoWorkerReferralPage}
            exact
          />
          <Route
            path={TabRouterPath.MY_SHIFT_DAY}
            component={MyShiftList}
            exact
          />
          <Route
            path={TabRouterPath.UNVERIFIED_SHIFTS}
            component={UnverifiedShiftsList}
            exact
          />
          <Route
            path={TabRouterPath.SUCCESS_ONBOARDING}
            component={OnboardingSucess}
            exact
          />
          <Route
            path={TabRouterPath.PAYMENT_SERVICE_ONBOARDING}
            component={SetUpStripe}
            exact
          />
          {/*--------- Privacy Policy and Notification Routes Starts --------*/}
          <Route
            path={`${match.url}/account/privacy-notification-setting`}
            component={PrivacyPolicyNotificationPage}
            exact
          />
          <Route
            path={`${match.url}/account/privacy-notification-setting/notification-preference`}
            component={NotificationPreferencePage}
            exact
          />
          <Route
            path={`${match.url}/account/privacy-notification-setting/privacy-policy`}
            component={PrivacyPolicyPage}
            exact
          />
          {/*--------- Privacy Policy and Notification Routes Ends --------*/}

          {/* Last Minute Shifts Route */}
          <Route
            path={TabRouterPath.LAST_MINUTE_SHIFTS}
            component={LastMinuteShifts}
            exact
          />

          <Route
            path={TabRouterPath.MY_SHIFT_DETAIL}
            component={MyShiftDetailsPage}
            exact
          />
          <Route
            path={TabRouterPath.MY_SHIFT_RATING}
            component={RatingPage}
            exact
          />
          <Route
            path={TabRouterPath.MY_SHIFT_SIGNATURE}
            component={ShiftSignature}
            exact
          />
          <Route
            exact
            path={match.url}
            render={() => <Redirect to={TabRouterPath.OPEN_SHIFTS} />}
          />
          <Route
            exact
            path={TabRouterPath.UPDATE_PROFILE}
            component={UpdateProfile}
          />

          {/* License Manager Routes */}

          <Route
            path={TabRouterPath.LICENSE_MANAGER}
            component={LicenseManager}
            exact
          />
          <Route
            path={TabRouterPath.ADD_LICENSE}
            component={AddLicense}
            exact
          />
          <Route
            path={TabRouterPath.LICENSE_DETAILS}
            component={LicenseDetails}
            exact
          />

          <Route
            exact
            path={TabRouterPath.DELETE_DATA}
            component={DeleteData}
          />
          <Route render={() => <Redirect to={TabRouterPath.OPEN_SHIFTS} />} />
        </IonRouterOutlet>
        {/* As of now, we don't have a workaround to hide tabs. Hence, disabling error
  // @ts-ignore */}
        <IonTabBar
          className={
            showAttendancePolicyNavigation
              ? "tab-bar-bottom-attendance-policy"
              : "tab-bar-bottom"
          }
          slot="bottom"
          style={tabStyle}
          role="tabpanel"
        >
          {/* Upgraded tabs for attendance policy */}
          <IonTabButton
            className="attendance-policy open-shifts"
            tab="open-shifts-attendance-policy"
            role="tab"
            aria-label="open-shifts"
            aria-hidden={!showAttendancePolicyNavigation}
            data-testid="open-shifts-attendance-policy"
            href={TabRouterPath.OPEN_SHIFTS}
            style={attendancePolicyTabStyle}
          >
            <IonIcon
              className="tab-button-icon"
              icon={
                tabActive === TabRouterPath.OPEN_SHIFTS
                  ? findShiftsOnImg
                  : findShiftsOffImg
              }
            />
            <IonLabel>Find Shifts</IonLabel>
          </IonTabButton>
          <IonTabButton
            className="attendance-policy my-shifts"
            tab="my-shifts-attendance-policy"
            role="tab"
            aria-label="my-shifts"
            aria-hidden={!showAttendancePolicyNavigation}
            data-testid="my-shifts-attendance-policy"
            href={TabRouterPath.MY_SHIFTS}
            style={attendancePolicyTabStyle}
          >
            <IonIcon
              className="tab-button-icon"
              icon={
                tabActive === TabRouterPath.MY_SHIFTS
                  ? attendancePolicyMyShiftsOnImg
                  : attendancePolicyMyShiftsOffImg
              }
            />
            <IonLabel>My Shifts</IonLabel>
          </IonTabButton>
          <IonTabButton
            className="attendance-policy ratings"
            tab="ratings"
            role="tab"
            aria-label="ratings"
            aria-hidden={!showAttendancePolicyNavigation}
            data-testid="ratings-attendance-policy"
            href={TabRouterPath.RATINGS}
            style={attendancePolicyTabStyle}
          >
            <IonIcon
              className="tab-button-icon"
              icon={
                tabActive === TabRouterPath.RATINGS
                  ? ratingsOnImg
                  : ratingsOffImg
              }
            />
            <IonLabel>Ratings</IonLabel>
          </IonTabButton>
          <IonTabButton
            className="attendance-policy account"
            tab="account-attendance-policy"
            role="tab"
            aria-label="account"
            aria-hidden={!showAttendancePolicyNavigation}
            data-testid="account-attendance-policy"
            href={TabRouterPath.ACCOUNT}
            onClick={openAccountTab}
            style={attendancePolicyTabStyle}
          >
            <IonIcon
              className="tab-button-icon"
              icon={
                tabActive === TabRouterPath.ACCOUNT
                  ? myAccountOnImg
                  : myAccountOffImg
              }
            />
            <IonLabel>My Account</IonLabel>
            {showAttendancePolicyNavigation &&
              (notificationsCount != 0 ||
                pendingActions ||
                shouldBeOnboarded ||
                isEmpty(license) ||
                isEmpty(address)) && (
                <IonBadge
                  color="danger"
                  mode="ios"
                  class="account-badge-attendance-policy"
                />
              )}
          </IonTabButton>

          {/* Default tabs */}
          <IonTabButton
            className="tab-bar-bottom-button-open-shifts"
            tab="openShifts"
            role="tab"
            aria-label="open-shifts"
            aria-hidden={showAttendancePolicyNavigation}
            data-testid="open-shifts-default"
            href={TabRouterPath.OPEN_SHIFTS}
            style={defaultPolicyTabStyle}
          >
            <IonIcon
              className="tab-bar-bottom-button-icon"
              icon={
                tabActive === TabRouterPath.OPEN_SHIFTS
                  ? findShiftsOnImg
                  : findShiftsOffImg
              }
            />
            <IonLabel>Find Shifts</IonLabel>
          </IonTabButton>
          <IonTabButton
            className="tab-bar-bottom-button-account"
            tab="account"
            role="tab"
            aria-label="account"
            aria-hidden={showAttendancePolicyNavigation}
            data-testid="account-default"
            href={TabRouterPath.ACCOUNT}
            onClick={openAccountTab}
            style={defaultPolicyTabStyle}
          >
            <IonIcon
              className="tab-bar-bottom-button-icon"
              icon={
                tabActive === TabRouterPath.ACCOUNT
                  ? myAccountOnImg
                  : myAccountOffImg
              }
            />
            <IonLabel>My Account</IonLabel>
            {!showAttendancePolicyNavigation &&
              (notificationsCount != 0 ||
                pendingActions ||
                shouldBeOnboarded ||
                isEmpty(license) ||
                isEmpty(address)) && <IonBadge color="danger" mode="ios" />}
          </IonTabButton>
        </IonTabBar>
      </IonTabs>

      {!showAttendancePolicyNavigation && (
        <IonTabButton
          className={
            isIOS
              ? "tab-bar-bottom-button-myshift iphone-home-padding"
              : "tab-bar-bottom-button-myshift"
          }
          tab="myShifts"
          role="tab"
          aria-label="my-shifts"
          data-testid="my-shifts-default"
          style={tabStyle}
          onClick={openMyShiftTab}
        >
          <IonIcon
            className="tab-bar-bottom-button-icon-myshifts"
            icon={
              tabActive === TabRouterPath.MY_SHIFTS
                ? myShiftsOnImg
                : myShiftsOffImg
            }
          />
          <IonLabel
            className={
              tabActive === TabRouterPath.MY_SHIFTS
                ? "myshifts-label-active"
                : ""
            }
          >
            My Shifts
          </IonLabel>
        </IonTabButton>
      )}

      <UrgentShiftPermissionContainer />
    </Fragment>
  );
};

export const AppTabs = withRouter(AppTabsComponent);
