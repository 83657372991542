import { IonButton, IonIcon, IonItem, isPlatform } from "@ionic/react";
import Alert from "antd/es/alert";
import { cloudUpload } from "ionicons/icons";
import { isEmpty } from "lodash";
import React, { Fragment, useRef } from "react";

import { AppModal } from "../../layout/modal";
import { FilePreviewList } from "./filePreviewList";
import { TimecardListProps } from "./model";
import { capturePhoto } from "@app/utils/mediaUpload";

const TimecardList: React.FC<TimecardListProps> = ({
  shift,
  setSelectedFile,
  setShowUploadTimecardModal,
  setTimecardModalVisibility,
  setShowCDPH530RequiredImageModalVisibility,
}) => {
  const inputFileRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const { timecard } = shift;
  const { files = [] } = timecard || {};

  const openGallery = async () => {
    const { file, type } = await capturePhoto();
    setSelectedFile({ file, type });
    setShowUploadTimecardModal(true);
  };

  const getFileFromSystem = (event) => {
    event.preventDefault();
    if (!event.target.files) return;

    const reader = new FileReader();
    const {
      target: {
        files: [file],
      },
    } = event;
    const [_, type] = file.type.split("/");

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFile({ file: reader.result, type });
      setShowUploadTimecardModal(true);
    };
    event.target.value = null;
  };

  const handleUploadClick = () => {
    const isNative = isPlatform("capacitor");
    return isNative ? openGallery() : inputFileRef?.current?.click();
  };

  const AlertCDPH530RequiredDescription = (
    <p>
      <a onClick={setShowCDPH530RequiredImageModalVisibility(true)}>
        Make sure that your uploaded CDPH 530 form is fully compliant{" "}
      </a>
      <strong>or you will not be paid.</strong>
    </p>
  );

  return (
    <Fragment>
      <AppModal title="Timecard" onBack={setTimecardModalVisibility(false)}>
        <IonItem lines="none" className="ion-margin-top">
          <Alert
            message={`${shift.facility?.name} requires a photo of the timecard for this shift in order for the
                shift to be verified.`}
            type="info"
            showIcon={true}
          />
        </IonItem>
        {shift.facility?.fullAddress?.state === "California" ? (
          <IonItem lines="none" className="ion-margin-top">
            <Alert
              message="CALIFORNIA LAW: VALID CDPH 530 FORM REQUIRED"
              type="warning"
              showIcon={true}
              description={AlertCDPH530RequiredDescription}
            />
          </IonItem>
        ) : (
          ""
        )}

        {isEmpty(files) && (
          <IonItem lines="none">
            <Alert
              message="No timecard uploaded."
              type="warning"
              showIcon={true}
            />
          </IonItem>
        )}

        <FilePreviewList fileList={files} />

        <div className="ion-text-center ion-margin">
          <IonButton onClick={handleUploadClick}>
            <IonIcon slot="start" icon={cloudUpload} mode="ios" />
            Upload
          </IonButton>
        </div>
      </AppModal>

      <input
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </Fragment>
  );
};

export { TimecardList };
