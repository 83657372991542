import { AppRate } from "@ionic-native/app-rate";
import { IonContent, IonImg, IonPopover, IonTextarea } from "@ionic/react";
import _ from "lodash";
import React, { useEffect, useState, SetStateAction, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { environment } from "../../../environments/environment";
import { actionAddRating } from "../../store/ongoingShifts";
import { Store } from "../../store/store.model";
import { updateAgentAppRatingStatus } from "../api";
import {
  FeedbackPrompt,
  RatingReasons,
  StarDescriptionApp,
} from "../constants";
import { AppReviewPrompt } from "../model";
import { AppStarRating } from "../starRating";
import { BottomButtons } from "./bottomButtons";
import { DetailReview } from "./detailReview";
import { InitialText } from "./initialText";
import {
  enterAnimation,
  leaveAnimation,
  PopoverContentAnimation,
} from "./popoverAnimation";
import "./style.scss";

const ReviewPrompt: React.FC<AppReviewPrompt> = ({ onDidDismiss }) => {
  const [rating, setRating] = useState<number>(0);
  const [dismissed, setDismiss] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState<string>("");
  const { appFeedback } = RatingReasons;
  const { prompt } = FeedbackPrompt;
  const feedbackText = rating > 4 ? prompt[1] : prompt[2];
  const userId = useSelector((state: Store) =>
    _.get(state, "session.agent.userId")
  );
  const dispatch = useDispatch();
  const [playedLowRateAnimation, setPlayedLowRateAnimation] =
    useState<boolean>(false);
  const [playRevAnimation, setPlayRevAnimation] = useState<boolean>(false);
  const [shouldOtherAnimation, setShouldOtherAnimation] =
    useState<boolean>(false);

  const el = document.querySelector(".review-prompt .popover-content")!;
  const {
    fiveStarAnimation,
    lowRatingAnimation,
    otherRatingAnimation,
    reverseOtherAnimation,
  } = PopoverContentAnimation(el);

  const handleStarClick = (starValue: number) => {
    if (rating === 0) {
      setRating(starValue);
    }
  };

  const handleReasonChange = (category: string) => {
    if (category === "Other Reason" && reasons.includes("Other Reason")) {
      setPlayRevAnimation(true);
    } else if (category == "Other Reason") {
      setShouldOtherAnimation(true);
    }
    setReasons((prevState) => _.xor(prevState, [category]));
  };

  const handleOtherReasonChange = (event: any) => {
    setOtherReason(event.target.value);
  };

  // @ts-ignore
  useEffect(() => {
    const updateAgent = async () => {
      try {
        await updateAgentAppRatingStatus(userId);
      } catch (e) {
        console.error(e);
      }
    };

    updateAgent();

    // Clean up if unmounted
    return () => null;
  }, []);

  const handleSubmit = (event: any) => {
    dispatch<any>(
      actionAddRating({
        rating,
        reasons,
        otherReason,
        reviewFor: "APP",
        id: userId,
      })
    );
    if (rating === 5) {
      AppRate.preferences.storeAppURL = environment.ReviewAppURL;
      AppRate.navigateToAppStore();
    }
    setDismiss(true);
  };

  useEffect(() => {
    if (rating === 5) {
      fiveStarAnimation.play();
    } else if (playRevAnimation) {
      reverseOtherAnimation.onFinish(() => setPlayRevAnimation(false)).play();
    } else if (
      reasons.includes("Other Reason") &&
      !playRevAnimation &&
      shouldOtherAnimation
    ) {
      otherRatingAnimation
        .onFinish(() => setShouldOtherAnimation(false))
        .play();
    } else if (!playedLowRateAnimation && rating > 0 && reasons.length === 0) {
      lowRatingAnimation.onFinish(() => setPlayedLowRateAnimation(true)).play();
    }
  }, [
    reasons,
    otherRatingAnimation,
    lowRatingAnimation,
    playedLowRateAnimation,
    fiveStarAnimation,
    reverseOtherAnimation,
    rating,
    playRevAnimation,
    shouldOtherAnimation,
  ]);

  return (
    <IonContent>
      <IonContent>
        <IonPopover
          isOpen={!dismissed}
          showBackdrop={true}
          backdropDismiss={false}
          enterAnimation={enterAnimation}
          leaveAnimation={leaveAnimation}
          cssClass="review-prompt"
          onDidDismiss={onDidDismiss}
        >
          {rating === 0 && (
            <IonImg
              onClick={() => setDismiss(true)}
              src="../../assets/icons/X.svg"
              className="popover-dismiss"
            />
          )}
          <InitialText animate={rating !== 0} />
          <AppStarRating
            facilityName=""
            rating={rating}
            onChange={handleStarClick as Dispatch<SetStateAction<number>>}
            starDescription={StarDescriptionApp}
            animate={rating !== 0}
          />
          {rating > 0 && (
            <>
              <DetailReview
                feedbackText={feedbackText}
                reasons={reasons}
                appFeedback={appFeedback}
                showReasons={rating < 5}
                onReasonChange={handleReasonChange}
              />
              <BottomButtons
                setDismiss={setDismiss}
                handleSubmit={handleSubmit}
                disabled={rating < 5 && reasons.length === 0}
              />
            </>
          )}
          {reasons.includes("Other Reason") && (
            <IonTextarea
              className="other-text"
              placeholder="Other Reason"
              value={otherReason}
              onIonChange={handleOtherReasonChange}
              autofocus
            />
          )}
        </IonPopover>
      </IonContent>
    </IonContent>
  );
};

export { ReviewPrompt };
