import { Dispatch } from "redux";
import { ActionType, ToastMessage } from "./model";

const setToastStatus =
  (msg: ToastMessage[], messageId: string) => (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_TOAST,
      data: msg,
      messageId: messageId,
    });
  };

const removeToastStatus = (arr: ToastMessage[]) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.REMOVE_TOAST,
    data: arr,
  });
};

const changeToastBoolean = () => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.CHANGE_TOAST_BOOLEAN,
  });
};

const setPushNotificationPreference =
  (state: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_PUSH_NOTIFICATION_PREFERENCE,
      data: state,
    });
  };

const setUnreadCount = (count: number) => (dispatch: Dispatch) => {
  dispatch({
    type: ActionType.SET_UNREAD_COUNT,
    count,
  });
};

export {
  setToastStatus,
  changeToastBoolean,
  removeToastStatus,
  setPushNotificationPreference,
  setUnreadCount,
};
