import _ from "lodash";
import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { SendBirdProvider, useSendbirdStateContext } from "sendbird-uikit";
import { v4 as uuidv4 } from "uuid";
import {
  fetchAndSetUpcomingShifts,
  setChannels,
  updateChannel,
} from "@store/chat";
import SendBird from "sendbird";
import { Dispatch } from "redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "@store/index";
import { environment } from "@env/environment";
import { Agent } from "src/lib/interface";
import { useLocation } from "react-router-dom";

const CHAT_CHANNEL_QUERY_LIMIT = 100;

export const ChatProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sdk || !sdk.GroupChannel) return undefined;

    const uuid = uuidv4();

    const channelHandler = new sdk.ChannelHandler();

    channelHandler.onChannelChanged = (channel: SendBird.GroupChannel) =>
      updateChannel(dispatch, channel);

    channelHandler.onUserReceivedInvitation = (
      channel: SendBird.GroupChannel
    ) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
    };

    channelHandler.onMessageReceived = (channel, message) => {
      if (message.isAdminMessage()) {
        fetchAndSetUpcomingShifts(dispatch);
      }
    };

    sdk.addChannelHandler(uuid, channelHandler);

    return () => sdk?.removeChannelHandler?.(uuid);
  }, [sdk]);

  useEffect(() => {
    fetchChannels(sdk, dispatch);
  }, [sdk]);

  return children;
};

interface WrapSendbirds {
  userId: string;
  agent: Agent;
}

export const WrapSendbird: React.FC<WrapSendbirds> = ({
  children,
  userId,
  agent,
}) => {
  const dispatch = useDispatch();
  const ldFlags = useFlags();
  const sendBirdAccessToken = useAppSelector(
    (store) => store?.session?.sendBirdAccessToken
  );
  const upcomingShifts = useAppSelector(
    (store) => store.chatStore.upcomingShifts
  );
  const location = useLocation();

  let appId = environment.sendBirdAppId;
  if (
    (!ldFlags.chat || !Object.values(upcomingShifts).length) &&
    !location.pathname.startsWith("/home/account/chat")
  ) {
    appId = "";
  }

  useEffect(() => {
    fetchAndSetUpcomingShifts(dispatch);
  }, []);

  useEffect(() => {
    if (location.pathname === "/home/myShifts") {
      fetchAndSetUpcomingShifts(dispatch);
    }
  }, [location.pathname]);

  return (
    <SendBirdProvider
      appId={appId}
      userId={userId}
      nickname={agent.name}
      accessToken={sendBirdAccessToken}
    >
      {children}
    </SendBirdProvider>
  );
};

export const fetchChannels = (
  sdk: SendBird.SendBirdInstance,
  dispatch: Dispatch
) => {
  if (!sdk || !sdk.GroupChannel) {
    setChannels(dispatch, []);
    return;
  }

  const query = sdk.GroupChannel.createMyGroupChannelListQuery();
  query.includeEmpty = true;
  query.memberStateFilter = "joined_only";
  query.order = "latest_last_message";
  query.limit = CHAT_CHANNEL_QUERY_LIMIT;

  query.next().then((list) => {
    setChannels(dispatch, list);
  });
};
