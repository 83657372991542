import { Network } from "@capacitor/network";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "src/app/api";
import { AppType, ConnectionMode } from "src/app/common/location/enable";
import { useAppSelector } from "src/app/store";
import {
  actionRecordShiftTimeFailure,
  actionRecordShiftTimeWithNfcV2,
} from "src/app/store/ongoingShifts/actions";
import { NFC_SCAN_MAX_TIME, USER_EVENTS } from "src/constants";
import { logEvent } from "src/lib/analytics/src";
import {
  DeviceNFCCapabilityForShift,
  NFCReadStatus,
  NFCShiftConversionReason,
  Shift,
  ShiftStages,
  SHIFT_MARKED_NON_IP_REASONS,
} from "src/lib/interface/src";
import { makeInstantpayLogParameters } from "src/utils/logEvent";
import { getStageObject } from "../../components/shift/Stage";
import {
  NFC_FAIL_STATUS,
  SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
} from "../constants";
import { useAlertsForNFC } from "./nfc/alert";
import {
  cancelNFCScan,
  deviceNFCCapabilityForShift,
  getNFCReadStatus,
  readNFCTagInfo,
} from "./nfc/nfcHelper";
import {
  getNFCReadLogReason,
  getNFCShiftConversionLogReason,
} from "./nfc/utils/nfcLogReasons";
import "./style.scss";

const NfcScannerModal: React.VFC<{
  shift: Shift;
  modalTitle: string;
  showNFCScanner: boolean;
  currentStage: ShiftStages;
  closeNFCScanner: (boolean) => void;
  loadShiftDetails: (string?: string) => void;
  getFacilitySignature: (boolean?: boolean) => void;
  onNFCValidSuccess: () => void;
  showUploadNowOrLaterModal: () => void;
  isRefactoredVersionEnabled: boolean;
  isNFCExternalWriteEnabled: boolean;
}> = ({
  shift,
  modalTitle = "NFC scan",
  showNFCScanner,
  currentStage,
  closeNFCScanner,
  loadShiftDetails,
  getFacilitySignature,
  onNFCValidSuccess,
  showUploadNowOrLaterModal,
  isRefactoredVersionEnabled,
  isNFCExternalWriteEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let nfcScanCounter = 0;
  const nfcAlerts = useAlertsForNFC();
  const { alert } = nfcAlerts;
  const { currentStageLog } = getStageObject(shift);
  const dispatch = useDispatch();
  const { agent = {} } = useAppSelector((state) => state.session);

  const onCloseOrCancel = (onEditSuccess: boolean) => {
    closeNFCScanner(onEditSuccess);
  };

  const openNFCSettings = async () => {
    if (isPlatform("capacitor")) {
      if (isPlatform("android")) {
        await OpenNativeSettings.open("nfc_settings");
        onCloseOrCancel(false);
      } else {
        await OpenNativeSettings.open("settings");
        onCloseOrCancel(false);
      }
    } else {
      setTimeout(() => {
        nfcAlerts.alertBrowserNFCAccess({
          gotItButtonHandler: () => {
            onCloseOrCancel(false);
          },
        });
      }, 500);
    }
  };

  const convertToNonInstantPay = async () => {
    await api.shift.markShiftAsNonInstantPay(
      shift._id as string,
      SHIFT_MARKED_NON_IP_REASONS.NO_LOCATION
    );
    createAccountLogForShiftConversion(
      NFCShiftConversionReason.SKIP_NFC_VALIDATION
    );
    await loadShiftDetails(shift._id);
    closeNFCScanner(false);
  };

  const showNoNetworkError = () => {
    nfcAlerts.alertNoNetworkConnection({
      gotItBtnHandler: () => {
        onCloseOrCancel(false);
      },
    });
  };

  const showAlertForOtherError = () => {
    nfcAlerts.alertOtherIssue({
      gotItButtonHandler: () => {
        onCloseOrCancel(false);
      },
    });
  };

  const checkNetworkConnection = async () => {
    const status = await Network.getStatus();
    const connectivityMode = status.connected
      ? ConnectionMode.ONLINE
      : ConnectionMode.OFFLINE;
    return connectivityMode;
  };

  const isDeviceSupported = async () => {
    const deviceNFCCapability = await deviceNFCCapabilityForShift();
    if (deviceNFCCapability === DeviceNFCCapabilityForShift.NO_NFC) {
      nfcAlerts.alertUserToRequestHCFToChangeToBackup({
        gotItBtnHandler: () => {
          onCloseOrCancel(false);
        },
      });
      return false;
    }
    if (deviceNFCCapability === DeviceNFCCapabilityForShift.NFC_DISABLED) {
      const isEdit = currentStage === ShiftStages.GET_FACILITY_SIGNATURE;
      nfcAlerts.alertNFCAccess({
        openNFCSettingsFn: () => {
          openNFCSettings();
        },
        skipNfcBtnHandler: isEdit
          ? () => onCloseOrCancel(false)
          : convertToNonInstantPay,
        isEdit: isEdit,
      });
      return false;
    }

    if (deviceNFCCapability !== DeviceNFCCapabilityForShift.NFC_ENABLED) {
      showAlertForOtherError();
      return false;
    }

    return deviceNFCCapability === DeviceNFCCapabilityForShift.NFC_ENABLED;
  };

  const showAlertForNFCError = (
    nfcReadStatus: NFCReadStatus = NFCReadStatus.INVALID_HASH,
    skipNfcBtnHandler: () => void,
    showNFCNotWorking = false,
    isEdit = false
  ) => {
    nfcAlerts.alertNFCError({
      nfcHcpToggleEnabled: shift?.facility?.nfcHcpToggleEnabled,
      showNFCNotWorking,
      isEdit: isEdit,
      nfcReadStatus,
      tryAgainBtnHandler: () => {
        scanTag();
      },
      nfcNotWorkingYesButtonHandler: () => {
        reportNFCNotWorking();
      },
      skipNfcBtnHandler: skipNfcBtnHandler,
      okayButtonHandler: () => closeNFCScanner(false),
    });
  };

  const scanTag = async () => {
    const isDeviceOnline = await checkNetworkConnection();
    if (isDeviceOnline === ConnectionMode.OFFLINE) {
      showNoNetworkError();
      return;
    }
    const canDeviceScan = await isDeviceSupported();
    if (canDeviceScan) {
      nfcScanCounter++;
      let facilityNFCHash: string | undefined;
      const timerForUpdateStage = setTimeout(() => {
        cancelNFCScan();
        updateStage(facilityNFCHash, shift);
      }, NFC_SCAN_MAX_TIME);
      const onSuccessRead = (readNFCData) => {
        updateStage(readNFCData, shift);
        clearTimeout(timerForUpdateStage);
      };
      facilityNFCHash = await readNFCTagInfo(
        isNFCExternalWriteEnabled,
        onSuccessRead
      );
    }
  };

  const updateStage = async (facilityNFCHash, shift) => {
    const nfcReadStatus = getNFCReadStatus(facilityNFCHash);
    if (nfcReadStatus !== NFCReadStatus.SUCCESS) {
      if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE)
        showAlertForNFCError(
          nfcReadStatus,
          () => onCloseOrCancel(false),
          nfcScanCounter >= SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
          true
        );
      else
        showAlertForNFCError(
          nfcReadStatus,
          convertToNonInstantPay,
          nfcScanCounter >= SHOW_NFC_NOT_WORKING_BUTTON_COUNT
        );
      createAccountLogForNFCFailure(
        false,
        nfcReadStatus,
        currentStage === ShiftStages.GET_FACILITY_SIGNATURE
      );
    } else {
      if (currentStage === ShiftStages.SHIFT_TIME_DONE) {
        getFacilitySignature();
      } else if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE) {
        const isDeviceOnline = await checkNetworkConnection();
        if (isDeviceOnline === ConnectionMode.OFFLINE) {
          showNoNetworkError();
          return;
        }
        let result = await api.shift.validateFacilityNFCHash(
          shift.facility.userId!,
          facilityNFCHash
        );
        if (result) {
          onNFCValidSuccess();
        } else {
          showAlertForNFCError(
            NFCReadStatus.INVALID_HASH,
            () => onCloseOrCancel(false),
            nfcScanCounter >= SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
            true
          );
          // Report failed reason to DB
          createAccountLogForNFCFailure(true);
        }
      } else {
        const isDeviceOnline = await checkNetworkConnection();
        if (isDeviceOnline === ConnectionMode.OFFLINE) {
          showNoNetworkError();
          return;
        }
        await dispatch(
          actionRecordShiftTimeWithNfcV2(
            shift._id,
            currentStage,
            shift,
            isPlatform("capacitor") ? AppType.MOBILE : AppType.WEB,
            isDeviceOnline,
            facilityNFCHash,
            currentStage === ShiftStages.CLOCK_OUT
              ? () => {
                  showUploadNowOrLaterModal();
                }
              : () => {}
          )
        );
        logEvent(
          USER_EVENTS[currentStageLog],
          makeInstantpayLogParameters(shift, shift.isInstantPay, null)
        );
        onCloseOrCancel(false);
      }
    }
  };

  const reportNFCNotWorking = async () => {
    try {
      setIsLoading(true);
      await api.shift.changeShiftClockInMethodToBackUp(
        shift._id as string,
        NFC_FAIL_STATUS.nfc_tag_not_working,
        isRefactoredVersionEnabled,
        createAccountLogForShiftConversion
      );
    } finally {
      // Re load shift details
      await loadShiftDetails(shift._id);
      setIsLoading(false);
      closeNFCScanner(false);
    }
  };

  const createAccountLogForShiftConversion = (
    nfcShiftConversionReason: NFCShiftConversionReason
  ) => {
    const conversionReason = getNFCShiftConversionLogReason(
      nfcShiftConversionReason,
      shift._id,
      currentStage,
      shift
    );
    dispatch(
      actionRecordShiftTimeFailure(conversionReason, agent, shift._id as string)
    );
    return;
  };

  const createAccountLogForNFCFailure = (
    apiFailed = false,
    nfcReadStatus: NFCReadStatus | null = null,
    isEdit?: boolean
  ) => {
    const failureReason = getNFCReadLogReason(
      apiFailed,
      nfcReadStatus,
      currentStage,
      shift,
      isEdit
    );
    dispatch(
      actionRecordShiftTimeFailure(failureReason, agent, shift._id as string)
    );
    return;
  };

  useEffect(() => {
    scanTag();
    return () => {
      // DidUnmount
    };
  }, []);

  return (
    <IonContent className="nfc-scanner-modal-container">
      <IonAlert
        header={alert?.header}
        message={alert?.message}
        isOpen={!!alert}
        // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
        onWillDismiss={nfcAlerts.dismissAlert}
        buttons={alert?.buttons}
        mode="ios"
      />
      <IonModal
        isOpen={showNFCScanner}
        cssClass="my-custom-class nfc-scanner-modal"
        swipeToClose={true}
        onDidDismiss={() => onCloseOrCancel(true)}
      >
        <IonHeader translucent className="nfc-scanner-header">
          <IonToolbar>
            <IonTitle>{modalTitle}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                routerDirection="back"
                onClick={() => onCloseOrCancel(false)}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="nfc-scanner-modal-container">
          <div className="nfc-scanner-content">
            <h4 className="nfc-scanner-info title">
              Tap your phone to the NFC tag at the facility.
            </h4>
            <p className="nfc-scanner-info description">
              The NFC tag is placed on a Clipboard Health poster by the check-in
              desk.
            </p>
            <div className="nfc-image-container">
              <img
                className="nfc-scanner-img"
                src="assets/images/nfc_scan.png"
                alt="nfc-scaning"
              />
            </div>
          </div>
        </IonContent>
        <IonFooter
          className="ion-no-border ion-text-center"
          style={{ paddingBottom: 15 }}
        >
          <>
            {isLoading && (
              <IonSpinner name="crescent" className="dot-spinner" />
            )}
          </>
        </IonFooter>
      </IonModal>
    </IonContent>
  );
};

export { NfcScannerModal };
