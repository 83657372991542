import React from "react";
import { TabRouterPath } from "@app/routing/constant/tabRoute";
import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { useAppSelector } from "@store/index";
import { chatbubblesOutline } from "ionicons/icons";
import { CustomBadge } from "@app/chat/customBadge";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory } from "react-router-dom";

export const HeaderMessagesIcon: React.FC = () => {
  const { channels } = useAppSelector((store) => store.chatStore);
  const ldFlags = useFlags();
  const history = useHistory();

  if (!ldFlags.chat) return <React.Fragment />;

  let unreadChannelCount: string | number = 0;
  for (const channel of channels) {
    if (channel.unreadMessageCount)
      unreadChannelCount += channel.unreadMessageCount;
  }
  if (unreadChannelCount > 99) unreadChannelCount = "99+";

  return (
    <>
      <IonButtons slot="start">
        <IonButton disabled>
          <IonIcon slot="icon-only" />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton
          onClick={() => history.push(TabRouterPath.CHAT_CHANNELS_LIST)}
        >
          <IonIcon
            slot="icon-only"
            icon={chatbubblesOutline}
            style={{ color: "white" }}
          />
        </IonButton>
        {unreadChannelCount != 0 && (
          <div style={CustomBadge}>{unreadChannelCount}</div>
        )}
      </IonButtons>
    </>
  );
};
