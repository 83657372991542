import { Agent } from "./agent";
import { CBHDocument } from "./document";
import { GeoLocation } from "./util";
import mongoose from "mongoose";

enum ShiftName {
  AM = "am",
  PM = "pm",
  NOC = "noc",
  CUSTOM = "custom",
}

enum SurgeryShiftName {
  SURG = "surg",
}

enum SignatoryStatus {
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  SIGNED = "SIGNED",
}

enum ShiftInOut {
  CLOCK = "clockInOut",
  LUNCH = "lunchInOut",
}

export enum TimeSystems {
  PAPER = "paper",
  NFC = "nfc",
  IPAD = "ipad",
}
export enum ClockInMethods {
  PRIMARY = "primary",
  BACKUP = "backup",
}

enum ShiftActivityType {
  SHIFT_OPEN = "SHIFT_OPEN",
  SHIFT_ASSIGN = "SHIFT_ASSIGN",
  SHIFT_REASSIGN = "SHIFT_REASSIGN",
  SHIFT_UNASSIGN = "SHIFT_UNASSIGN",
  SHIFT_DELETE = "SHIFT_DELETE",
  SHIFT_DELETE_FILLED = "SHIFT_DELETE_FILLED",
  FACILITY_CANCEL = "FACILITY_CANCEL",
  FACILITY_CANCEL_FILLED = "FACILITY_CANCEL_FILLED",
  WORKER_CANCEL = "WORKER_CANCEL",
  NO_CALL_NO_SHOW = "NO_CALL_NO_SHOW",
  SHIFT_CLAIM = "SHIFT_CLAIM",
  SHIFT_VERIFY = "SHIFT_VERIFY",
  CLOCK_IN_OUT = "CLOCK_IN_OUT",
  LUNCH_IN_OUT = "LUNCH_IN_OUT",
}

interface VerificationPreferenceDetailsProps {
  /**
   * When you are fetching verification preference for a single facility, use this.
   */
  facilityId?: string;
  /**
   * When a list of facilities are rendered, we should pass this property to avoid
   * making different requests for each resource.
   */
  verificationPreference?: VerificationPreferences;
  // Used in unferifiedSHiftListCard.tsx for extra condition
  timeSheetExists?: any;
}

type hazardCharge = {
  covid: {
    charges: {
      [key: string]: number;
    };
    pay: number;
  };
};

interface Facility {
  _id?: string;
  userId?: string;
  name?: string;
  phone?: string;
  fullAddress?: {
    state?: string;
    region?: string;
    formatted?: string;
    metropolitanStatisticalArea?: string;
  };
  status?: string;
  tmz?: string;
  distance?: number;
  isApproxDistance?: boolean;
  description?: string;
  profileDescription?: string;
  authorizedSignatories?: AuthorizedSignatory[];
  geoLocation?: GeoLocation;
  instantBook?: boolean | string;
  requiredDocuments?: CBHDocument[];
  rates?: {
    CNA?: number;
    LVN?: number;
    RN?: number;
    NP?: number;
    PT?: number;
    PTA?: number;
    HHA?: number;
  };
  checkInInstructions?: string;
  isInstantPayEnabled?: boolean;
  agentHasAllDocuments?: boolean;
  verification?: VerificationPreferences;
  verificationPreference?: VerificationPreferences;
  facilityNotes?: unknown[];
  locationAwareness?: string;
  profileDecriptionlastManualUpdate?: Date;
  covidData?: {
    confirmedResidentLastMonth?: number;
    confirmedResidentLastWeek?: number;
    confirmedStaffLastMonth?: number;
    confirmedStaffLastWeek?: number;
    covidActive?: boolean;
    hasEyeProtection?: boolean;
    hasGowns?: boolean;
    hasN95Masks?: boolean;
    hasSanitizer?: boolean;
    hasSurgicalMasks?: boolean;
    lastManualUpdate?: Date;
    occupiedBeds?: number;
    totalBeds?: number;
    testingAvailabilityOnsite?: boolean;
  };
  tbioOptions?: {
    threshold: number;
    enabled: boolean;
    backToBack: boolean;
    multiplier: number;
    multiplier2?: number;
    threshold2?: number;
    forty?: number | boolean;
    single?: boolean;
  };
  type?: string;
  shiftsCount?: number;
  filteredShiftsCount?: number;
  shifts?: Shift[];
  rating?: {
    count: number;
    value: number;
  };
  requireTimecardPhoto?: boolean;
  isETPFlagEnabled?: boolean;
  is100InstantPayEnabled?: boolean;
  hazardCharge?: hazardCharge;
  clockInMethod?: string;
  timeSystem?: string;
  isTestAccount?: boolean;
  nfcHcpToggleEnabled?: boolean;
  nfcTag?: boolean;
}

enum VerificationMethod {
  TIMESHEET = "Timesheet Only",
  OUTBOUND = "Outbound Verification",
  SHEET = "External Google Sheet",
  OTHER = "Other",
}
type ContactMethod = "PHONE" | "EMAIL" | "TEXT";
type Day =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";
interface ContactAvailability {
  day: Day;
}
interface VerificationContact {
  archived: boolean;
  contactMethod: {
    name: ContactMethod;
  };
  availability: ContactAvailability[];
}
export interface VerificationPreferences {
  mongodbId: string;
  name: string;
  maxOutreachAttemptsPerDay: number;
  usesTimesheets: boolean;
  verificationMethod: {
    name: VerificationMethod;
  };
  contacts: VerificationContact[];
  outboundContactList?: VerificationContact[];
  preferenceType?: string;
  verificationAttempt?: number;
  requiresLunchBreak?: boolean;
}

interface AuthorizedSignatory {
  _id?: string;
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
}

interface RequestedSignatory extends AuthorizedSignatory {
  status: SignatoryStatus;
}

interface TimeRange {
  startCard?: string;
  endCard?: string;
  start?: string;
  end?: string;
  isSkipped?: boolean;
  startImage?: string;
  endImage?: string;
}

interface DisputedTimeRange {
  stamp: Date;
  stage: string;
}

interface TimecardFiles {
  _id: string;
  url: string;
}
interface Timecard {
  createdAt?: Date;
  files?: TimecardFiles[];
  status?: number;
}

interface TimecardV2 extends Timecard {
  success: boolean;
  error: string | null;
  response: object;
}

interface SurgerySpeciality {
  speciality: string;
  hasTrayAssemblyExperience: boolean;
  hasSedationExperience: boolean;
}

interface MissingDoc {
  hasMissing: boolean;
  docs: string[];
}

/**
 * Most of these enums were copied from backend-main/src/constants or similar project files
 * We should centralize them here in the future
 */

enum InstantPayPaymentTypes {
  INITIAL = "INITIAL",
  REMAINING = "REMAINING",
}

enum InstantPayPaymentVia {
  STRIPE = "STRIPE",
  DAILY_PAY = "DAILY_PAY",
  RIPPLING = "RIPPLING",
}

enum InstantPayTimeLogStates {
  ASSIGNED = "ASSIGNED",
  ASSIGNED_FAILED = "ASSIGNED_FAILED",
  CLOCK_IN = "CLOCK_IN",
  CLOCK_OUT = "CLOCK_OUT",
  LUNCH_IN = "LUNCH_IN",
  LUNCH_OUT = "LUNCH_OUT",
  ADMIN_CLOCK = "ADMIN_CLOCK",
  ADMIN_LUNCH = "ADMIN_LUNCH",
  PARTIALY_PAID = "PARTIALY_PAID",
  FULL_PAID = "FULL_PAID",
  CANCELED = "CANCELLED",
  VERIFIED = "VERIFIED",
  TIME_ADJUSTED = "TIME_ADJUSTED",
  SKIP_LUNCH = "SKIP_LUNCH",
  SHIFT_NOTE_UPDATE = "SHIFT_NOTE_UPDATE",
  SHIFT_TIME_CHANGE = "SHIFT_TIME_CHANGE",
  POST_VERIFY_CLOCK_IN = "POST_VERIFY_CLOCK_IN",
  POST_VERIFY_CLOCK_OUT = "POST_VERIFY_CLOCK_OUT",
  POST_VERIFY_LUNCH_IN = "POST_VERIFY_LUNCH_IN",
  POST_VERIFY_LUNCH_OUT = "POST_VERIFY_LUNCH_OUT",
}

interface InstantPayShiftPayment {
  amount: number;
  paidVia: InstantPayPaymentVia;
  type: InstantPayPaymentTypes;
}

interface InstantPayShiftDetails {
  shiftId: string;
  hcpId: string;
  start: string;
  end: string;
  verified: boolean;
  deleted: boolean;
  status?: InstantPayTimeLogStates;
  action: InstantPayTimeLogStates;
  payments: InstantPayShiftPayment[];
  charge: number;
  time: number;
  pay: number;
  instantPayRate: number;
  totalAmount: number;
  paidAmount: number;
  remaingAmount: number;
  hcpClockIn?: string;
  hcpLunchOut?: string;
  hcpLunchIn?: string;
  hcpClockOut?: string;
  adminClockIn?: string;
  adminLunchIn?: string;
  adminLunchOut?: string;
  adminClockOut?: string;
  completedDate?: string;
  postVerifyClockIn?: string;
  postVerifyClockOut?: string;
  postVerifyLunchIn?: string;
  postVerifyLunchOut?: string;
  is100InstantPayEnabled?: boolean | undefined;
  nfcTag?: boolean;
  submitClockIn?: string;
  submitLunchIn?: string;
  submitLunchOut?: string;
  submitClockOut?: string;
}

interface Shift {
  _id?: string;
  name?: ShiftName;
  verified?: boolean;
  signed?: boolean;
  agentReq?: string;
  confirmed?: boolean;
  filtered?: boolean;
  start?: string;
  end?: string;
  time?: number;
  description?: string;
  surgerySpeciality?: SurgerySpeciality;
  charge?: number;
  pay?: number;
  distance?: number;
  isApproxDistance?: boolean;
  finalPay?: number;
  facility?: Facility;
  facilityId?: mongoose.Types.ObjectId;
  agentId?: string | mongoose.Types.ObjectId;
  agent?: Agent;
  requestedSignatories?: RequestedSignatory[];
  interested?: Agent[] & string[];
  signatory?: AuthorizedSignatory;
  unit?: string;
  clockInOut?: TimeRange;
  lunchInOut?: TimeRange;
  disputedTimeout?: DisputedTimeRange;
  agentSignature?: string;
  timecard?: Timecard;
  isHolidayShift?: boolean;
  holidayName?: string;
  rating?: {
    FACILITY?: number;
    AGENT?: number;
  };
  cancellationReason?: {
    type: string;
  };
  status?: number;
  isHCFInstantPayProhibited?: boolean;
  instantPayDetails?: InstantPayShiftDetails;
  agentHasAllDocuments?: boolean;
  isInstantPayEnabled?: boolean;
  shiftId?: string;
  isInstantBook?: boolean;
  isInstantPay?: boolean;
  isChangedToNonInstantPay?: boolean;
  instruction?: string;
  isHCFConfirmed?: boolean;
  /**
   * Only present in response for lastMinuteShifts.
   */
  isInstantForAgent?: boolean;
  isInstantPayWorkedBefore?: boolean;
  groupId?: string;
  urgency?: number;
  hidden?: number;
  bookable?: boolean;
  timecardNotAvailable?: {
    reason: string;
    setBy: string;
    updatedAt: string;
  };
  clockInMethod?: string;
  payBreakDown?: {
    hazard?: number;
    boostPay?: number;
    basePay?: number;
    configuredPay?: number;
  };
  isStripePayment?: boolean;
  reminderNotify?: [];
  submitClockInOut?: TimeRange;
  submitLunchInOut?: TimeRange;
  autoClockedOut?: boolean;
  deleted?: boolean;
  isBillable?: boolean;
  nfcTag?: boolean;
  originalAmount?: number;
}

interface Shift_NEW {
  response: [] | object | null;
  error: string | null;
  success: boolean;
  instantPayDetails?: { [key: string]: any };
  agentHasAllDocuments?: boolean;
}

interface MissingDocument {
  _id: string;
  name: string;
}
interface UnverifiedShift extends Shift {
  businessrules?: {
    key: string;
    value: string;
    description: string;
  };
}

enum SHIFT_MARKED_NON_IP_REASONS {
  NO_LOCATION = "HCP failed to confirm location",
  MOBILE_FORGOT_TO_CLOCK_IN = "Mobile: HCP failed to clock in",
  API_CALL = "/markNonInstant API call",
  CAUGHT_ON_AUTO_CLOCK_OUT = "HCP forgot to clock in, caught on auto clock out",
  REACHED_VERIFICATION_WITHOUT_PAYMENT = "SUSPICIOUS, Reached the verification step without initial payment",
}

enum ShiftStages {
  CLOCK_IN = "CLOCK_IN",
  LUNCH_IN = "LUNCH_IN",
  LUNCH_OUT = "LUNCH_OUT",
  CLOCK_OUT = "CLOCK_OUT",
  SHIFT_TIME_DONE = "SHIFT_TIME_DONE",
  SKIP_LUNCH = "SKIP_LUNCH",
  UPLOAD_TIME_CARD = "UPLOAD_TIME_CARD",
  GET_FACILITY_SIGNATURE = "GET_FACILITY_SIGNATURE",
}

enum DeviceNFCCapabilityForShift {
  NFC_ENABLED = "NFC_ENABLED",
  NO_NFC = "NO_NFC",
  NFC_DISABLED = "NFC_DISABLED",
}

enum NFCReadStatus {
  SUCCESS = "SUCCESS",
  INVALID_HASH = "INVALID_HASH",
  FAILED = "FAILED",
}

enum NFCShiftConversionReason {
  SKIP_NFC_VALIDATION = "SKIP_NFC_VALIDATION",
  NO_NFC_DEVICE = "NO_NFC_DEVICE",
  NFC_TAG_NOT_WORKING = "NFC_TAG_NOT_WORKING",
}

export type {
  Shift,
  AuthorizedSignatory,
  RequestedSignatory,
  Facility,
  TimeRange,
  Timecard,
  TimecardFiles,
  Shift_NEW,
  TimecardV2,
  UnverifiedShift,
  VerificationPreferenceDetailsProps,
  InstantPayShiftDetails,
  InstantPayShiftPayment,
};

export {
  ShiftName,
  SurgeryShiftName,
  SignatoryStatus,
  ShiftInOut,
  ShiftActivityType,
  SHIFT_MARKED_NON_IP_REASONS,
  InstantPayTimeLogStates,
  InstantPayPaymentVia,
  InstantPayPaymentTypes,
  VerificationMethod,
  ShiftStages,
  DeviceNFCCapabilityForShift,
  NFCReadStatus,
  NFCShiftConversionReason,
};
