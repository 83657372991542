import { formatRate } from "./helper";
import { logEvent } from "src/lib/analytics";
import { ShiftLoader } from "src/lib/ionic-components";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "@store/index";
import { IonButton, IonContent, IonLabel } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { USER_EVENTS } from "../../constants/userEvents";
import { api } from "@app/api";

const NoMyShiftsAvailable: React.VFC<{
  justifyContent: React.CSSProperties["justifyContent"];
  hasShifts: boolean;
  isLoadingShifts: boolean;
}> = ({ justifyContent, hasShifts, isLoadingShifts }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [maxEarnings, setMaxEarnings] = useState<{
    amount: number;
    date?: Date;
  }>({
    amount: 0,
  });
  const { agent } = useAppSelector((state) => state.session);
  const [shouldRenderComp, setShouldRenderComp] = useState(false);
  const history = useHistory();

  const goToOpenShifts = () => {
    logEvent(USER_EVENTS.TAPPED_GO_TO_OPEN_SHIFTS);
    history.push("/home/openShifts");
  };

  useEffect(() => {
    setShouldRenderComp(!isLoadingShifts && !hasShifts);
  }, [isLoadingShifts, hasShifts]);

  useEffect(() => {
    if (!shouldRenderComp) return;

    const fetchEstEarnings = async () => {
      setLoading(true);
      const response = await api.shift.fetchMaxEarnings();
      setMaxEarnings(response);
      setLoading(false);
    };

    fetchEstEarnings();
  }, [agent, shouldRenderComp]);

  if (!shouldRenderComp) {
    return <Fragment />;
  }

  const renderBody = () => {
    const { amount, date } = maxEarnings;

    if (amount === 0) {
      return (
        <IonLabel color="text" class="ion-padding ion-text-center">
          There are no available shifts nearby for you to book. <br />
          Expand your distance preferences in My Account or check back later
          when more shifts become available.
        </IonLabel>
      );
    }

    return (
      <>
        <IonLabel color="text" class="ion-padding ion-text-center">
          You haven’t booked any upcoming shifts!
          <br />
          Go to Find Shifts to start booking now
          <br />
          <IonLabel color="text" class="ion-padding ion-text-center">
            Earn up to <b>${formatRate(amount)}</b> per shift
            {moment().isSame(date, "day") && <b> today</b>}!
          </IonLabel>
        </IonLabel>
        <IonButton shape="round" class="primary-btn" onClick={goToOpenShifts}>
          Go to Find Shifts
        </IonButton>
      </>
    );
  };

  if (isLoading) {
    return (
      <IonContent>
        <ShiftLoader loading={isLoading} count={1} />
      </IonContent>
    );
  }

  return (
    <IonContent>
      <div
        className="centered-empty-state-my-shifts ion-margin-bottom"
        style={{ justifyContent }}
      >
        <img
          style={{ width: "160px" }}
          src="assets/logo/bunny.png"
          alt="Clipboard logo"
        />
        {renderBody()}
      </div>
    </IonContent>
  );
};

export { NoMyShiftsAvailable };
