import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { App, AppState } from "@capacitor/app";
import { useSelector, useDispatch } from "react-redux";
import { Diagnostic } from "@ionic-native/diagnostic";
import { isEmpty } from "lodash";

import { Store } from "../../store/store.model";
import { updateAgent } from "../../store/session";
import GeneralLocationPermissionModal from "./generalLocationPrimerModal";
import FullLocationPermissionModal from "./alwaysAllowLPermissionPrimerModal";
import { PrimerPage, UrgentShiftLocalStorage } from "./constants";
import {
  getLocationPermissionFlowModal,
  requestLocationAccess,
  requestFullLocationAccess,
  setLocationAccessDenied,
  getCurrentLocationStatus,
  hasAlwaysOnLocationPermission,
  handleException,
  canAskFullLocationPermission,
} from "./utils";

import { updateAgentData } from "./api";

interface urgentShiftPermissionContainerInterface {}

const UrgentShiftPermissionContainer: React.FC<
  urgentShiftPermissionContainerInterface
> = () => {
  const [showingGeneralPermissionModal, setShowingGeneralPermissionModal] =
    useState<boolean>(false);
  const [showingFullPermissionModal, setShowingFullPermissionModal] =
    useState<boolean>(false);
  const { agent } = useSelector((state: Store) => state.session);
  const [appIsActive, setAppIsActive] = useState(false);

  const dispatch = useDispatch();
  const ldClient = useLDClient();

  const urgentShiftsEnabled = ldClient?.variation("urgent-shifts");
  const urgentShiftsConfig = ldClient?.variation("urgent-shifts-config");

  const showGeneralPermissionModal = () => {
    setShowingGeneralPermissionModal(true);
  };

  const showFullPermissionModal = () => {
    setShowingFullPermissionModal(true);
  };

  const updateLocationServiceStatus = async () => {
    const data = await getCurrentLocationStatus();
    const fields = ["isLocationServicesEnabled", "locationServicesPermission"];
    const updateInfo = {};

    let dataUpdated = false;
    fields.forEach((field) => {
      updateInfo[`mobile.${field}`] = data[field];

      dataUpdated = dataUpdated || data[field] !== agent?.mobile?.[field];
    });

    if (!dataUpdated || isEmpty(updateInfo)) return;

    await updateAgentData(agent?.userId, updateInfo);
    dispatch(
      updateAgent({
        mobile: {
          ...agent?.mobile,
          ...data,
        },
      })
    );
  };

  const handlePrimerPageDisplay = async () => {
    const modal = await getLocationPermissionFlowModal({
      locationPermissionAskFrequency:
        urgentShiftsConfig?.locationPermissionAskFrequency,
    });

    if (showingGeneralPermissionModal && modal !== PrimerPage.GENERAL) {
      setShowingGeneralPermissionModal(false);
    } else if (
      showingFullPermissionModal &&
      modal !== PrimerPage.ALWAYS_ALLOW
    ) {
      setShowingFullPermissionModal(false);
    }

    if (modal === PrimerPage.GENERAL) {
      showGeneralPermissionModal();
    } else if (modal === PrimerPage.ALWAYS_ALLOW) {
      showFullPermissionModal();
    }
  };

  const onStateChange = (state: AppState) => {
    setAppIsActive(state.isActive);
  };

  // Event listeners
  useEffect(() => {
    const appStateChangeListener = App.addListener(
      "appStateChange",
      onStateChange
    );

    handleException(updateLocationServiceStatus)();
    handleException(handlePrimerPageDisplay)();

    return () => {
      appStateChangeListener.remove();
    };
  }, []);

  useEffect(() => {
    if (appIsActive) {
      handleException(updateLocationServiceStatus)();
      handleException(handlePrimerPageDisplay)();
    }
  }, [appIsActive]);

  const hideGeneralPermissionModal = () => {
    setShowingGeneralPermissionModal(false);
  };

  const hideFullPermissionModal = () => {
    setShowingFullPermissionModal(false);
  };

  const isGeneralPermissionModalOpen = (): boolean =>
    urgentShiftsEnabled && showingGeneralPermissionModal;

  const isFullPermissionModalOpen = (): boolean =>
    urgentShiftsEnabled &&
    !isGeneralPermissionModalOpen() &&
    showingFullPermissionModal;

  const handleAllowLocationClick = async () => {
    const res = await requestLocationAccess();

    if (
      [
        Diagnostic.permissionStatus.DENIED_ALWAYS,
        Diagnostic.permissionStatus.DENIED_ONCE,
      ].includes(res)
    ) {
      setLocationAccessDenied();
    }

    handleException(updateLocationServiceStatus)();
    hideGeneralPermissionModal();
  };

  const onAllowLocationClick = () => {
    handleException(handleAllowLocationClick)();
  };

  const onGoToLocationSettingClicked = () => {
    hideFullPermissionModal();
    requestFullLocationAccess();
  };

  const onDenyLocationPermissionClick = () => {
    localStorage.setItem(
      UrgentShiftLocalStorage.LAST_LOCATION_ACCESS_ASKED,
      moment().toISOString()
    );
    hideGeneralPermissionModal();
  };

  const onDenyFullLocationPermissionClick = () => {
    localStorage.setItem(
      UrgentShiftLocalStorage.LAST_FULL_LOCATION_ACCESS_ASKED,
      moment().toISOString()
    );
    hideFullPermissionModal();
  };

  return (
    <>
      <GeneralLocationPermissionModal
        isOpen={isGeneralPermissionModalOpen()}
        onAllowLocationClick={onAllowLocationClick}
        onDeny={onDenyLocationPermissionClick}
        onDidDismiss={handlePrimerPageDisplay}
      />
      <FullLocationPermissionModal
        isOpen={isFullPermissionModalOpen()}
        onGoToLocationSettingClicked={onGoToLocationSettingClicked}
        onDeny={onDenyFullLocationPermissionClick}
      />
    </>
  );
};

export default UrgentShiftPermissionContainer;
