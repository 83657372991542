import { combineReducers } from "redux";

import { session } from "./session";
import { shiftStore } from "./shift";
import { specialitiesStore } from "./specialities";
import { documentStore } from "./documents";
import { accountSettingsStore } from "./accountSettings";
import { ongoingShiftStore } from "./ongoingShifts";
import { notificationStore } from "./Notifications";
import { chatStore } from "./chat";

export const rootReducer = combineReducers({
  session,
  shiftStore,
  specialitiesStore,
  documentStore,
  accountSettingsStore,
  ongoingShiftStore,
  notificationStore,
  chatStore,
});
