import "../style.scss";

import { chevronBackOutline } from "ionicons/icons";
import { logEvent } from "src/lib/analytics";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _, { get, sortBy } from "lodash";
import {
  Agent,
  Facility,
  PaymentServiceAccountStatus,
  Shift,
} from "src/lib/interface";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Store } from "../../store/store.model";
import { USER_EVENTS } from "../../../constants/userEvents";
import {
  addAgentActivityLog,
  fetchInstantBookShifts,
  instantPayStatus,
  isHCPInstantPayAllowed,
} from "../api";
import { ShiftFilters } from "src/app/components/shiftFilters";
import { FacilityDetails } from "../facilityDetails";
import { getNotificationPreference } from "../../privacyPolicy/notificationPreference/api";
import { HCP_ACTIONS } from "../constants";
import InstantUpdate from "../../InstantUpdate";
import { ReviewPrompt } from "../../rating/reviewPrompt";
import { ShiftItem } from "../shiftItem";
import { ShiftListProps } from "../model";
import { showToast } from "../../layout/toast";
import {
  checkRolloutMsa,
  isNonConflictingShift,
  removeConflictingShifts,
} from "../../utils/shiftHelper";
import { api } from "@app/api";

const getShiftFlag = (flag: "holidayCheck" | "claimCheck") => {
  const shiftFlagString = localStorage.getItem(flag);
  let shiftFlag;
  if (shiftFlagString !== null && shiftFlagString !== "null") {
    shiftFlag = JSON.parse(shiftFlagString);
  }
  return shiftFlag;
};

const shouldShiftLeadToMyDetailsPage = (
  shift: Shift,
  agent?: Agent
): boolean => {
  const isAssignedToCurrentUser = shift.agentId === agent?.userId;
  const isIPAndUserClockedIn = shift.isInstantPay && shift.clockInOut?.start;
  const hasShiftEnded = moment(shift.end).isBefore(moment());
  return isAssignedToCurrentUser && (isIPAndUserClockedIn || hasShiftEnded);
};

const ShiftListPage: React.FC<ShiftListProps> = ({
  day,
  shiftName,
  shifts,
  onClose,
  goToDocumentsPage,
  getOpenAndMyShifts,
  loadingShiftList,
  searchMode,
  filterDistance,
  filterMinPayHourly,
  filterMinPayShift,
  filterDays,
  baseRate,
}) => {
  const header = `${moment(day).format("dddd, MMMM DD YYYY")}`;
  const { agent, bookingRolloutMsas } = useSelector(
    (state: Store) => state.session
  );
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [openHolidayAlert, setOpenHolidayAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isShiftLoaded, setIsShiftLoaded] = useState<boolean>(false);

  const [sortedShifts, setSortedShifts] = useState<Shift[]>([]);
  const [facilityDetails, setFacilitydetails] = useState<Facility>();
  const [shiftDetails, setShiftDetails] = useState<Shift>();
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);

  const [instantBookShifts, setInstantBookShifts] = useState<Shift[]>([]);
  const payVersion = useSelector((state: Store) =>
    get(state, "session.payVersion")
  );
  const [pushNotification, setPushNotification] = useState<{
    [key in string]: boolean;
  }>({});
  const { isPushSet } = useSelector((state: Store) => state.notificationStore);

  useEffect(() => {
    // This event is used to trigger intercom `mobile carousel`
    logEvent(USER_EVENTS.VIEWED_BOOK_SHIFTS);
  }, []);

  useEffect(() => {
    (async function () {
      const { isNotificationAlertShown } = agent as Agent;
      const response = await Promise.all([
        api.env.fetchEnvVariablesWithPushNotificationOnAccountTab(),
        getNotificationPreference(),
      ]);
      setPushNotification({
        accepted: response[1]?.data?.notificationPreferences?.push,
        pushFlag: response[0]?.PushNotificationOnAccountTab as boolean,
        isNotificationAlertShown: isNotificationAlertShown as boolean,
        isPushSet,
      });
    })();
  }, [setPushNotification, agent, isPushSet]);

  const fetchHcpInstantPayStatus = async () => {
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
  };

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  useEffect(() => {
    setSortedShifts(
      sortBy(removeConflictingShifts(shifts.open, shifts.assigned), [
        "facility.distance",
        "start",
      ])
    );
  }, [shifts.open, shifts.assigned]);

  const shiftValidatorBeforeSubmission = (shift: Shift) => {
    // Already assigned shifts doesn't appear on same shift type. So only checking on open shifts.
    if (
      isNonConflictingShift(
        [
          ...shifts.assigned,
          ...shifts.open.filter(
            ({ agentId, interested }) => agentId === agent?.userId
          ),
        ],
        shift
      )
    )
      return true;
    showToast(
      `Unable to book shift, it looks like you're already working at that time! If there's a mistake, please contact support for help`,
      5000,
      "bottom",
      "danger"
    );
    return false;
  };

  const history = useHistory();
  const onClickAssignedShiftItem = (shift: Shift) => () => {
    if (shouldShiftLeadToMyDetailsPage(shift, agent)) {
      history.push(`/home/myShifts/${shift._id!}`);
    }
  };

  const updateShiftList = (shift: Shift, isInstantBooking = false) => {
    if (getOpenAndMyShifts) {
      getOpenAndMyShifts();
    }
    sortedShifts.forEach((current) => {
      if (current._id === shift._id) {
        if (isInstantBooking) {
          current.agentId = agent?.userId;
          current.isHCFConfirmed = undefined;
        } else {
          if (checkRolloutMsa(shift, bookingRolloutMsas as string[])) {
            current.agentId = agent?.userId;
            current.isHCFConfirmed = false;
          } else {
            current.interested?.push(agent?.userId as string);
          }
        }
      }
    });
    setSortedShifts(sortedShifts);
    if (!isInstantBooking) {
      setClickedInterestInShift(true);
    }
  };

  const handleShiftFlagFound = (type: "holiday" | "claim") => {
    if (type === "holiday") {
      !isHoliday && setIsHoliday(true);
    }
  };

  useEffect(() => {
    if (!isHoliday) {
      return;
    }
    const holidayCheck = getShiftFlag("holidayCheck");
    if (!holidayCheck) {
      setOpenHolidayAlert(true);
      localStorage.setItem("holidayCheck", "true");
    }
  }, [isHoliday]);

  const closeHolidayAlert = () => {
    setOpenHolidayAlert(false);
  };

  const onClickOnItemFacilityDetails = (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard = false
  ) => {
    if (shouldShiftLeadToMyDetailsPage(shift, agent)) {
      return; // go directly to My Shifts details page (onClickAssignedShiftItem)
    }
    if (isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilitydetails(shift.facility);
    setShiftDetails(shift);
  };

  const closeFacilityDetails = () => {
    setFacilitydetails(undefined);
    setShiftDetails(undefined);
  };

  useEffect(() => {
    if (!isShiftLoaded) return;

    let currentIndex = 1;
    const shiftsToLog = [...shifts.open, ...shifts.assigned].reduce(
      (filtered: Shift[], option) => {
        if (option != null) {
          const shift = {
            ...option,
            position: currentIndex,
            isInstantBook:
              instantBookShifts.findIndex((a) => a.shiftId === option._id) > -1,
          };
          filtered.push(shift);
          currentIndex = currentIndex + 1;
        }
        return filtered;
      },
      []
    );

    logAgentActivity(shiftsToLog, HCP_ACTIONS.TIME_SLOT_CLICK);
  }, [isShiftLoaded]);

  const getInstantBookShifts = useCallback(async () => {
    setLoading(true);
    const instantbookShiftsLoaded = await fetchInstantBookShifts(day);

    const instantbookShifts = (instantbookShiftsLoaded || []).filter(
      (shift) => shiftName === shift.name || shift.name === "custom"
    );
    setInstantBookShifts(instantbookShifts);
    setLoading(false);
    setIsShiftLoaded(true);
  }, [day]);

  useEffect(() => {
    getInstantBookShifts();
  }, [getInstantBookShifts]);

  const [clickedInterestInShift, setClickedInterestInShift] = useState(false);
  const [openAppRatingPrompt, setOpenAppRating] = useState(false);

  const meetsCriteriaToShowAppRatingPrompt = (agent): boolean => {
    const { appRatingStatus, attendance } = agent;
    if (!appRatingStatus) return false;

    const hasCompetedThreeShiftsSinceLastSeenPrompt =
      attendance.completedShifts -
        appRatingStatus.countCompletedShiftsWhenLastShownPrompt >=
      3;

    return (
      appRatingStatus.appRatingSubmitted === false &&
      appRatingStatus.countPromptShown < 3 &&
      attendance.completedShifts >= 3 &&
      hasCompetedThreeShiftsSinceLastSeenPrompt
    );
  };

  useEffect(() => {
    const { appRatingStatus } = agent as Agent;
    if (
      clickedInterestInShift &&
      appRatingStatus &&
      !appRatingStatus.appRatingSubmitted
    ) {
      setOpenAppRating(meetsCriteriaToShowAppRatingPrompt(agent));
    }
  }, [clickedInterestInShift, agent]);

  const filterShiftsToLog = (shiftId = null) => {
    //Getting Mark Interest Shifts and filtering the null items
    const markInterestShifts = sortedShifts.filter((el) => {
      return (
        el != null &&
        instantBookShifts.findIndex((k) => k.shiftId === el._id) < 0
      );
    });
    // Combining mark and self claim shifts
    const combinedShifts = instantBookShifts.concat(markInterestShifts);
    //Using to set the position of the shift
    let currentIndex = 1;
    //filtering the mark interest shifts and is instant check
    const shiftsToLog = combinedShifts.reduce((filtered: Shift[], option) => {
      if (
        option.interested!.indexOf(agent?.userId as string) < 0 &&
        !option["isClaimed"] &&
        shiftId !== option.shiftId
      ) {
        const isInstantBook =
          instantBookShifts.findIndex(
            (instantShift) => instantShift.shiftId === option.shiftId
          ) > -1;
        const shift = {
          ...option,
          type: isInstantBook
            ? HCP_ACTIONS.SELF_CLAIM
            : HCP_ACTIONS.MARK_INTEREST,
          position: currentIndex,
          isInstantBook,
        };
        filtered.push(shift);
      }
      currentIndex = currentIndex + 1;
      return filtered;
    }, []);

    return shiftsToLog;
  };

  const shiftActivityLog = (shiftId) => {
    const shiftsToLog = filterShiftsToLog(shiftId);
    return { shifts: shiftsToLog };
  };

  const handleShiftActivityLog = (shiftId, action) => {
    const shiftsToLog = filterShiftsToLog(shiftId);
    //Recording Activity
    logAgentActivity(shiftsToLog, action, shiftId);
  };

  const logAgentActivity = (shiftsToLog, action: string, shiftId = null) => {
    const agentActivityLog = {
      day: day,
      shiftId,
      timeSlot: shiftName.toUpperCase(),
      agentId: agent?.userId,
      shifts: shiftsToLog,
      action,
    };
    addAgentActivityLog(agentActivityLog);
  };

  const [isFirstTimeInterested, setIsFirstTimeInterested] =
    useState<boolean>(false);
  const prevAgentInterestedAtRef = useRef<object>();

  useEffect(() => {
    prevAgentInterestedAtRef.current = agent?.interestedAt;
  }, []);

  useEffect(() => {
    if (prevAgentInterestedAtRef.current === null && agent?.interestedAt) {
      setIsFirstTimeInterested(true);
    }
  }, [agent?.interestedAt]);

  const availableShiftIds = useMemo(() => {
    return sortedShifts ? sortedShifts.map((shift) => shift._id!) : [];
  }, [sortedShifts]);

  const isStandardMissing = false;

  return (
    <>
      <IonHeader no-border>
        <IonToolbar className="relative" mode="ios">
          <IonButtons align-center>
            <IonButton
              routerDirection="back"
              fill="clear"
              color="light"
              onClick={onClose}
              className="shift-list__button-back"
            >
              <IonIcon icon={chevronBackOutline} mode="ios" size="large" />
            </IonButton>
          </IonButtons>
          <IonTitle className="shift-list__header-title">{header}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ShiftFilters
        totalShifts={sortedShifts.length}
        baseRate={baseRate}
        distancePreference={filterDistance}
        minPayHourlyPreference={filterMinPayHourly}
        minPayShiftPreference={filterMinPayShift}
        searchMode={searchMode}
        updateOpenShifts={getOpenAndMyShifts}
      />
      {loading || loadingShiftList ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            width="250"
            height="250"
            src="/assets/gifs/loadingAvailableShifts.gif"
            alt="Loading available shifts"
          />
        </div>
      ) : (
        <IonContent>
          <div className="list-title">
            <IonListHeader style={{ fontSize: "inherit" }}>
              {shiftName.toUpperCase()} Shifts
            </IonListHeader>
          </div>
          <IonList lines="none">
            {shifts.assigned.map((shift) => (
              <ShiftItem
                key={shift._id}
                shift={shift}
                shiftName={shiftName}
                handleShiftFlagFound={handleShiftFlagFound}
                onFacilityDetailsClick={onClickOnItemFacilityDetails}
                payVersion={payVersion}
                isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                onItemClick={onClickAssignedShiftItem(shift)}
              />
            ))}
            {!sortedShifts.length && !shifts.assigned.length && (
              <IonItem lines="none">
                <p style={{ paddingRight: "30px", color: "#616161" }}>
                  No shifts available.
                </p>
              </IonItem>
            )}
            {sortedShifts.map((shift) => {
              return (
                <ShiftItem
                  key={shift._id}
                  shift={shift}
                  isInstantBookingShift={
                    !!instantBookShifts.find(
                      (instantShift) => instantShift.shiftId === shift.shiftId
                    )
                  }
                  shiftName={shiftName}
                  goToDocumentsPage={goToDocumentsPage}
                  handleShiftFlagFound={handleShiftFlagFound}
                  updateShiftList={updateShiftList}
                  onFacilityDetailsClick={onClickOnItemFacilityDetails}
                  setClickedInterestInShift={setClickedInterestInShift}
                  isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                  payVersion={payVersion}
                  handleShiftActivityLog={handleShiftActivityLog}
                  validator={shiftValidatorBeforeSubmission}
                  pushNotification={pushNotification}
                  shiftActivityLog={shiftActivityLog}
                  searchMode={searchMode}
                  availableShiftIds={availableShiftIds}
                  filterDistance={filterDistance}
                  filterDays={filterDays}
                />
              );
            })}
          </IonList>
        </IonContent>
      )}

      {openAppRatingPrompt && (
        <ReviewPrompt onDidDismiss={() => setOpenAppRating(false)} />
      )}
      <IonAlert
        cssClass="shift-alerts"
        isOpen={openHolidayAlert}
        onDidDismiss={closeHolidayAlert}
        subHeader="You just clicked on a Holiday Shift!"
        message={`The Holiday Shift you just clicked on indicates a Facility will pay time-and-a-half as a means of thanking you for working on this particular day.<br /><br />
<strong>Interested in finding more of these Time-and-a-Half Shifts?</strong><br /><br />
Look out for the shifts with Holiday Icons ( examples include: 🎄, 🎊, ⭐️) Shifts with Holiday Icons represent time-and-half pay. Enjoy!`}
        buttons={["OK"]}
      />
      <IonModal isOpen={!!facilityDetails} onDidDismiss={closeFacilityDetails}>
        {facilityDetails && (
          <FacilityDetails
            onClose={closeFacilityDetails}
            facility={facilityDetails}
            shift={shiftDetails}
            displayExtraTimePayCard={displayExtraTimePayCard}
          />
        )}
      </IonModal>
      {/* If user marks interest for the first time show instant update */}

      {pushNotification.pushFlag && isFirstTimeInterested && (
        <InstantUpdate onClose={() => setIsFirstTimeInterested(false)} />
      )}
    </>
  );
};

export { ShiftListPage };
