import { ShiftItemActionType, ShiftItemState } from "../model";

const initialShiftItemState = {
  isLoading: false,
  category: null,
  alertOpen: false,
  submitting: false,
  submitted: false,
  workApproved: false,
  interested: false,
  toast: {},
  pushNotificationOpen: false,
  pushNotification: {
    isPushSet: null,
    accepted: null,
    pushFlag: null,
    isNotificationAlertShown: null,
  },
  missingDocs: new Set(),
  checkingDocs: false,
};

const shiftItemReducer = (state: ShiftItemState, action) => {
  switch (action.type) {
    case ShiftItemActionType.SET_CATEGORY: {
      return {
        ...state,
        alertOpen: false, // Closing this because on submit of shift, the category changes and will experience flicker between multiple alerts.
        ...action.payload,
      };
    }
    case ShiftItemActionType.OPEN_ALERT: {
      return {
        ...state,
        alertOpen: true,
      };
    }
    case ShiftItemActionType.CLOSE_ALERT: {
      return {
        ...state,
        alertOpen: false,
      };
    }
    case ShiftItemActionType.SUBMITTING: {
      return {
        ...state,
        submitting: true,
        submitted: false,
      };
    }
    case ShiftItemActionType.SUBMITTED: {
      return {
        ...state,
        submitting: false,
        submitted: true,
      };
    }
    case ShiftItemActionType.OPEN_TOAST: {
      return {
        ...state,
        toast: { ...action.payload.toast, isOpen: true },
      };
    }
    case ShiftItemActionType.CLOSE_TOAST: {
      return {
        ...state,
        toast: { ...state.toast, isOpen: false },
      };
    }
    case ShiftItemActionType.CONFIGURE_PUSH_NOTIFICATION: {
      return {
        ...state,
        pushNotification: {
          ...state.pushNotification,
          ...action.payload.pushNotification,
        },
      };
    }
    case ShiftItemActionType.OPEN_PUSH_NOTIFICATION: {
      const { isPushSet, accepted, pushFlag, isNotificationAlertShown } =
        state.pushNotification as NonNullable<typeof state.pushNotification>;
      return {
        ...state,
        pushNotificationOpen:
          isPushSet || (pushFlag && !accepted && !isNotificationAlertShown),
      };
    }
    case ShiftItemActionType.CLOSE_PUSH_NOTIFICATION: {
      return {
        ...state,
        pushNotificationOpen: false,
      };
    }
    case ShiftItemActionType.ERROR_SUBMITTING: {
      return {
        ...state,
        submitting: false,
        submitted: false,
      };
    }
    case ShiftItemActionType.WORK_APPROVED: {
      return {
        ...state,
        workApproved: true,
        interested: false,
      };
    }
    case ShiftItemActionType.SHOWED_INTEREST: {
      return {
        ...state,
        workApproved: false,
        interested: true,
      };
    }
    case ShiftItemActionType.SET_MISSING_DOCS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ShiftItemActionType.SET_CHECKING_DOCS: {
      return {
        ...state,
        checkingDocs: action.payload?.checkingDocs || false,
      };
    }
    default: {
      return state;
    }
  }
};

export { shiftItemReducer, initialShiftItemState };
