import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonButton,
  IonLabel,
  IonInput,
  IonDatetime,
  IonSpinner,
  IonRow,
  IonCol,
  IonToggle,
} from "@ionic/react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import { ToggleChangeEventDetail } from "@ionic/core";
import { states } from "src/lib/utils";
import { logEvent } from "src/lib/analytics";
import { Store } from "src/app/store/store.model";
import { USER_EVENTS } from "src/constants/userEvents";
import { workerTypeObj } from "src/lib/constants";
import { logApiFailureEvent } from "src/lib/analytics";
import { showModal } from "src/app/shared/modal";
import "./style.scss";
import { addLicense, getWorkTypes } from "./api";
import { AddLicenseFormProps, ICreateLicense, IWorkerType } from "./interfaces";
import { LICENSE_DETAILS_INIT } from "./constants";

const anyOption = { key: "Any", name: "Any" };
const { Option } = Select;
const AddLicenseForm: React.FC<AddLicenseFormProps> = ({
  licenseDetails,
  submitLicense,
  addingLicense,
  forOnboarding,
  disableForm,
}) => {
  const [license, setLicense] = useState(licenseDetails);
  const [workTypesList, setWorkTypesList] = useState<IWorkerType[]>([]);

  const handleLicenseChange = (key: string, value: string) => {
    setLicense((prevLicenseDetails) => ({
      ...prevLicenseDetails,
      [key]: value,
    }));
  };

  const statesWithAnyOption = useMemo(() => {
    return [anyOption, ...states];
  }, [states]);
  const handleLicenseMultiStateChange = (
    event: CustomEvent<ToggleChangeEventDetail>
  ) => {
    const { checked } = event.detail;
    setLicense((prevLicenseDetails) => ({
      ...prevLicenseDetails,
      multiState: checked,
    }));
  };

  const fetchAndSetWorkTypes = useCallback(async () => {
    try {
      const { data } = await getWorkTypes();
      setWorkTypesList(data.workerTypes);
    } catch (error) {
      logApiFailureEvent(error);
      showModal("error", "Error while getting qualifications.");
    }
  }, []);

  useEffect(() => {
    fetchAndSetWorkTypes();
    if (!licenseDetails.state) {
      handleLicenseChange("state", anyOption.name);
    }
  }, []);
  const showLicenseNumberField = useMemo(() => {
    if (
      license.qualification === workerTypeObj.MEDICAL_ASSISTANT ||
      license.qualification === workerTypeObj.NON_CLINICAL ||
      license.qualification === workerTypeObj.PHLEBOTOMIST ||
      (license.qualification === workerTypeObj.CNA &&
        license.state === "Illinois")
    ) {
      return false;
    }
    return true;
  }, [license.qualification, license.state]);

  const enableSubmitButton = useMemo(() => {
    if (license.qualification && license.state && license.expiresAt) {
      if (
        showLicenseNumberField &&
        (!license.number || !license.number.trim())
      ) {
        return false;
      }
      return true;
    }
    return false;
  }, [license]);

  const headerTextContent = useMemo(() => {
    let headerText =
      "Enter your new license info here. We will validate with the state license authority and notify you when we’ve accepted your license.";
    if (forOnboarding) {
      headerText =
        "Add your primary license now; you can add more once you’ve completed signup.";
    }
    return headerText;
  }, [forOnboarding]);

  return (
    <IonContent className="add-license">
      <div className="add-license-header">
        {forOnboarding && <h4>Which license do you have?</h4>}
        <p>{headerTextContent}</p>
      </div>
      <IonCard
        className="add-license-card"
        id="safe-area-to-open-dropdown"
        data-testid="licenses-card"
      >
        <IonCardContent className="add-license-card-content">
          <div className="form-control">
            <IonLabel class="ion-text-wrap" color="#4F4F4F">
              <p>WORKER TYPE</p>
            </IonLabel>
            <Select
              getPopupContainer={() =>
                document.getElementById(
                  "safe-area-to-open-dropdown"
                ) as HTMLElement
              }
              data-testid="add-license-qualification"
              showSearch
              value={license.qualification || undefined}
              className="form-input"
              style={{ width: "100%", border: "none" }}
              placeholder="choose license type"
              onChange={(value) => {
                handleLicenseChange("qualification", value);
              }}
              disabled={disableForm}
            >
              {workTypesList.map((licenseType) => (
                <Option key={licenseType.id} value={licenseType.name}>
                  {licenseType.description}
                </Option>
              ))}
            </Select>
          </div>
          <IonRow className="ion-align-items-center no-padding license-state-row">
            <IonCol size="8" className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>STATE</p>
              </IonLabel>
              <Select
                data-testid="add-license-state"
                showSearch
                value={license.state || undefined}
                className="form-input"
                style={{ width: "100%", border: "none" }}
                placeholder="choose a state"
                onChange={(value) => {
                  handleLicenseChange("state", value);
                }}
                disabled={disableForm}
              >
                {statesWithAnyOption.map((state) => (
                  <Option key={state.key} value={state.name}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </IonCol>

            <IonCol size="4" className="form-control license-multi-state-col">
              <IonLabel
                class="ion-text-wrap"
                color="#4F4F4F"
                className="license-multi-state-label"
              >
                <p>MULTI-STATE</p>
              </IonLabel>
              <IonToggle
                className="license-multi-state-toggle"
                color="primary"
                checked={license.multiState}
                onIonChange={handleLicenseMultiStateChange}
                disabled={disableForm}
              />
            </IonCol>
          </IonRow>
          {showLicenseNumberField && (
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>LICENSE NUMBER</p>
              </IonLabel>
              <IonInput
                data-testid="add-license-number"
                name="number"
                className="form-input"
                type="text"
                placeholder="123456"
                value={license.number}
                onIonChange={(event) =>
                  handleLicenseChange("number", event.detail.value!)
                }
                required
                disabled={disableForm}
              />
            </div>
          )}
          <div className="form-control">
            <IonLabel class="ion-text-wrap" color="#4F4F4F">
              <p>EXPIRATION DATE</p>
            </IonLabel>
            <IonDatetime
              data-testid="add-license-expiry"
              className="date-time-picker"
              displayFormat="MMM DD, YYYY"
              name="expiresAt"
              placeholder="mm/dd/yyyy"
              min={moment().add(1, "day").format("YYYY-MM-DD")}
              max={moment().add(50, "years").format("YYYY-MM-DD")}
              value={license.expiresAt}
              onIonChange={(event) =>
                handleLicenseChange("expiresAt", event.detail.value!)
              }
              disabled={disableForm}
            />
          </div>
        </IonCardContent>
      </IonCard>
      <IonButton
        expand="block"
        className="submit-license-button"
        onClick={() => {
          submitLicense(license);
        }}
        disabled={!enableSubmitButton}
      >
        {addingLicense && <IonSpinner></IonSpinner>}
        {forOnboarding ? "Next" : "Submit License"}
      </IonButton>
    </IonContent>
  );
};

const AddLicense: React.FC<{}> = () => {
  const history = useHistory();
  const userId = useSelector((state: Store) =>
    get(state, "session.agent.userId")
  );

  const [addingLicense, setAddingLicense] = useState<boolean>(false);

  const submitLicense = (license: ICreateLicense) => {
    (async () => {
      try {
        setAddingLicense(true);
        await addLicense({
          ...license,
          agentId: userId,
        });
        logEvent(USER_EVENTS.LICENSE_SUBMITTED);
        history.goBack();
      } catch (error) {
        logApiFailureEvent(error);
        showModal("error", "Error while adding license");
      } finally {
        setAddingLicense(false);
      }
    })();
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/account/licenses"
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Add License</IonTitle>
        </IonToolbar>
      </IonHeader>
      <AddLicenseForm
        licenseDetails={LICENSE_DETAILS_INIT}
        submitLicense={submitLicense}
        addingLicense={addingLicense}
        forOnboarding={false}
        disableForm={false}
      />
    </IonPage>
  );
};

export { AddLicense, AddLicenseForm };
