import "./style.scss";
import { IonCol, IonRow } from "@ionic/react";

const CovidTestingAvailabilityText: React.FC<{
  message?: string;
  customClass?: string;
}> = ({
  message = "This facility performs an on-site COVID test before each shift.",
  customClass = "",
}) => {
  return (
    <IonRow
      className={`ion-align-items-center ion-justify-content-start ${customClass}`}
    >
      <IonCol size="auto">
        <img src="../../assets/icons/test-tubes.svg" alt="covid-test" />
      </IonCol>
      <IonCol>
        <span>{message}</span>
      </IonCol>
    </IonRow>
  );
};

export { CovidTestingAvailabilityText };
