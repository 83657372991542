import { SignatoryStatus } from "src/lib/interface";
import { IonItem } from "@ionic/react";
import Alert from "antd/es/alert";
import { map } from "lodash";
import React, { Fragment } from "react";

import { RejectedSignatoriesProps } from "./model";

const RejectedSignatories: React.FC<RejectedSignatoriesProps> = ({
  requestedSignatories,
}) => (
  <Fragment>
    {map(requestedSignatories, (signatory) => {
      if (signatory.status === SignatoryStatus.REJECTED) {
        return (
          <IonItem lines="none">
            <Alert
              key={signatory._id}
              type="error"
              message={`${signatory.name}, ${signatory.role} refused to sign your shift.`}
              showIcon={true}
            />
          </IonItem>
        );
      }
    })}
  </Fragment>
);

export { RejectedSignatories };
