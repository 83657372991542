import "./style.scss";
import _ from "lodash";
import {
  IonButton,
  IonCol,
  IonContent,
  IonLabel,
  IonRow,
  IonSpinner,
  IonToast,
  IonAlert,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { ActionType, reloadAgentProfile } from "@store/session";
import { get } from "lodash";
import { LocalStorage } from "@store/session";
import { logout } from "../../store/session";
import { logEvent, addDataToIdentity } from "src/lib/analytics";

import { OtpInputs } from "../../components/auth/otpInputs";
import { Store } from "../../store/store.model";
import { requestOTP } from "./api";
import { validateAgentOTP } from "@store/session/api";
import { updateProfile } from "./api";
import { ProfileUpdateStepProps } from "./interface";
import { USER_EVENTS } from "../../../constants";
import { formatNumberToPhoneNumber } from "../../../hooks/useTelephoneNumber";
import { PROFILE_UPDATE_LOCAL_STORAGE_ITEMS } from "./constants";

const CODE_LENGTH = 6;

const UpdatePhone: React.FC<ProfileUpdateStepProps> = ({ stepFinished }) => {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [otpResendStatus, setOtpResendStatus] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtp] = useState("");
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [pageError, setPageError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const newPhone = localStorage.getItem(LocalStorage.NEW_PHONE) as string;
    requestOTP(newPhone);
    setPhone(newPhone);
  }, []);

  const onClickVerifyConfirmation = (newCode) => {
    setOtp(newCode);
    setShowConfirmationModal(true);
  };

  const verify = async () => {
    const otpCode = otp + "";
    if (isVerifying) return;
    if (otpCode.trim().length !== CODE_LENGTH) return;
    if (isButtonDisabled) setButtonDisabled(false);
    try {
      setIsVerifying(true);
      const { error } = await validateAgentOTP(phone, otpCode);
      if (error) {
        setPageError({
          isError: true,
          message: "Invalid OTP",
        });
        setIsVerifying(false);
        return;
      }

      addDataToIdentity(agent.userId, {
        email: agent.email,
        phone,
        type: "AGENT",
      });
      await updateProfile({ phone });
      localStorage.setItem(
        PROFILE_UPDATE_LOCAL_STORAGE_ITEMS.UPDATE_PROFILE_PHONE_NUMBER_CHANGED,
        "true"
      );
      setIsVerifying(false);
      dispatch(reloadAgentProfile(false));
      logEvent(USER_EVENTS.PROFILE_CHANGE_COMPLETED, {
        type: "PHONE",
      });
      stepFinished();
      dispatch(logout());
    } catch (error) {
      setIsVerifying(false);
      setPageError({
        isError: true,
        message: "Unexpected error occurred while verifying OTP",
      });
    } finally {
      dispatch({
        type: ActionType.VERIFICATION_COMPLETED,
      });
    }
  };

  const resendOTP = async () => {
    const { error } = await requestOTP(phone);
    // @ts-ignore
    if (!error) {
      setOtpResendStatus(true);
    }
  };

  const onDismiss = () => {
    setPageError({ isError: false, message: "" });
  };

  return (
    <IonContent>
      <IonToast
        isOpen={pageError.isError}
        onDidDismiss={onDismiss}
        message={pageError.message}
        color="danger"
        duration={2000}
        position="top"
      />
      <IonAlert
        mode="ios"
        isOpen={showConfirmationModal}
        backdropDismiss={false}
        header="You need to login again"
        message="To validate your new phone, you will need to logout and login again. This step is necessary for us to proper configure your account."
        buttons={[
          {
            text: "Cancel",
            handler: () => setShowConfirmationModal(false),
          },
          {
            text: "Confirm",
            handler: verify,
          },
        ]}
      />
      <div className="content-layout signup-content adjust-form-height verifyotp-content">
        <div className="form-container">
          <IonRow>
            <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
              <div className="form-heading">
                <h4>Enter your verification code</h4>
                <p>
                  We&#39;ve just sent a verification code to{" "}
                  {formatNumberToPhoneNumber(phone)}
                </p>
              </div>
              <div>
                <IonLabel className="form-label hcp-self-label">
                  Verification Code
                </IonLabel>
                <OtpInputs
                  verify={onClickVerifyConfirmation}
                  code={code}
                  setCode={setCode}
                  noBorder
                />
              </div>
              <a className="resend-otp-link" onClick={resendOTP}>
                Didn&#39;t get a code? Resend it now.
              </a>
            </IonCol>
          </IonRow>
        </div>

        <div className="signupform-footer footer-container">
          <IonButton
            expand="block"
            size="large"
            class="ion-margin-top ion-margin-bottom continue-button"
            onClick={onClickVerifyConfirmation}
            disabled={isVerifying || isButtonDisabled}
            id="verify-button"
          >
            Continue
            {isVerifying && (
              <IonSpinner slot="end" class="ion-margin-start" name="lines" />
            )}
          </IonButton>
        </div>
      </div>
      <IonToast
        isOpen={otpResendStatus}
        onDidDismiss={() => setOtpResendStatus(false)}
        message="OTP re-sent successfully."
        duration={5000}
        color="success"
        position="top"
      />
    </IonContent>
  );
};

export { UpdatePhone };
