import { useState, useEffect, useMemo } from "react";
import { IonButton, IonRow, IonItemDivider } from "@ionic/react";
import moment from "moment-timezone";
import {
  USER_EVENTS,
  SEARCH_MODE,
  MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY,
} from "src/constants";
import { ShiftFiltersInterface } from "./interface";
import { DistancePreference } from "./distancePreference";
import { MinPayPreference } from "./minPayPreference";
import { HiddenShiftsModal } from "./hiddenShiftsModal";
import { logEvent } from "src/lib/analytics";
import { get } from "lodash";
import { Store } from "src/app/store/store.model";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { FeatureFlag } from "src/constants/FEATURE_FLAGS";
import { SHIFTS_SUMMARY_HEADER_TEXT } from "./constants";

const isAlertShownAlready = (shownAt: string, format: string): boolean => {
  return moment().format(format) === moment(shownAt).format(format);
};

const hiddenShiftsModalShownLocalStorage = (
  key = "hiddenShiftsModalShown",
  minimumPayHiddenShiftsAlertFrequency = MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY.NEVER
) => {
  if (
    minimumPayHiddenShiftsAlertFrequency ===
    MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY.NEVER
  ) {
    return true;
  }
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return false;
  }
  const item = JSON.parse(itemStr);
  switch (minimumPayHiddenShiftsAlertFrequency) {
    case MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY.DAILY:
      if (isAlertShownAlready(item.shownAt, "DD/MM")) {
        // Same day
        return true;
      } else {
        localStorage.removeItem(key);
        return false;
      }
    case MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY.WEEKLY:
      if (isAlertShownAlready(item.shownAt, "WW")) {
        // Same week
        return true;
      } else {
        localStorage.removeItem(key);
        return false;
      }

    case MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY.MONTHLY:
      if (isAlertShownAlready(item.shownAt, "MM")) {
        // Same month
        return true;
      } else {
        localStorage.removeItem(key);
        return false;
      }
    default:
      return true;
  }
};

export const ShiftFilters = ({
  totalShifts,
  hiddenShifts = 0,
  baseRate,
  distancePreference,
  minPayHourlyPreference,
  minPayShiftPreference,
  searchMode,
  updateOpenShifts,
}: ShiftFiltersInterface) => {
  const agent =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};

  const [distancePreferenceModal, setDistancePreferenceModal] =
    useState<boolean>(false);
  const [minPayPreferenceModal, setMinPayPreferenceModal] =
    useState<boolean>(false);
  const [hiddenShiftModal, setHiddenShiftModal] = useState<boolean>(false);

  const ldFlags = useFlags();
  const minimumPayRollout = ldFlags[FeatureFlag.MINIMUM_PAY_FILTER_ACTIVE];
  const minimumPayHiddenShiftsAlertFrequency =
    ldFlags[FeatureFlag.MINIMUM_PAY_HIDDEN_SHIFTS];

  useEffect(() => {
    if (
      searchMode !== SEARCH_MODE.HCF_PROFILE &&
      totalShifts !== undefined &&
      hiddenShifts
    ) {
      const hiddenShiftsModalShown = hiddenShiftsModalShownLocalStorage(
        "hiddenShiftsModalShown",
        minimumPayHiddenShiftsAlertFrequency
      );
      if (!hiddenShiftsModalShown && totalShifts < 5 && hiddenShifts > 5) {
        setHiddenShiftModal(true);
        const item = {
          value: true,
          shownAt: moment().format(),
        };
        localStorage.setItem("hiddenShiftsModalShown", JSON.stringify(item));
      }
    }
  }, [totalShifts, hiddenShifts, minimumPayHiddenShiftsAlertFrequency]);

  const shiftsSummaryHeader = useMemo(() => {
    let summaryText = "";

    switch (searchMode) {
      case SEARCH_MODE.MAP:
        summaryText =
          totalShifts !== 0
            ? SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_FOR_NEXT_30_DAYS
            : SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_FOR_NEXT_30_DAYS;
        break;
      case SEARCH_MODE.HCF_PROFILE:
        if (minimumPayRollout) {
          summaryText =
            totalShifts !== 0
              ? SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_THAT_PAY
              : SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_RIGHT_NOW;
        }
        break;

      default:
        summaryText =
          totalShifts !== 0
            ? SHIFTS_SUMMARY_HEADER_TEXT.SHIFTS_WITHIN
            : SHIFTS_SUMMARY_HEADER_TEXT.NO_SHIFTS_WITHIN;
    }

    return `${summaryText} `;
  }, [totalShifts, searchMode, minimumPayRollout]);

  const minPayButtonText = useMemo(() => {
    let buttonText = "Min Pay";
    if (minPayHourlyPreference) {
      buttonText = `$${minPayHourlyPreference}/hour`;
    } else if (minPayShiftPreference > 0) {
      buttonText = `$${minPayShiftPreference}/shift`;
    }
    return buttonText;
  }, [minPayHourlyPreference, minPayShiftPreference]);

  const openDistancePreferenceModal = () => {
    setDistancePreferenceModal(true);
    logEvent(USER_EVENTS.OPEN_DISTANCE_PREFERENCE_MODAL, {
      distance: agent?.preference?.distance,
    });
  };

  const openMinPayPreferenceModal = () => {
    setMinPayPreferenceModal(true);
    setHiddenShiftModal(false);
    logEvent(USER_EVENTS.MINIMUM_PAY_PREFERENCE_EDITED, {
      minimum_hourly: agent?.preference?.minPayHourly,
      minimum_shift: agent?.preference?.minPayShift,
      source: searchMode,
    });
  };

  return (
    <>
      <IonRow
        className="ion-justify-content-center ion-align-items-center"
        style={{ marginTop: 5, cursor: "pointer" }}
      >
        <div
          style={{
            background: "white",
            width: "100%",
            color: "#032E68",
            textAlign: "center",
            padding: "4px 0px",
            marginTop: "-4px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {shiftsSummaryHeader}
          {![SEARCH_MODE.HCF_PROFILE, SEARCH_MODE.MAP].includes(searchMode) && (
            <IonButton
              size="small"
              fill="outline"
              className="open-modal-button"
              data-testid="open-modal-button"
              onClick={openDistancePreferenceModal}
            >
              {distancePreference} {distancePreference > 1 ? "miles" : "mile"}
            </IonButton>
          )}
          {minimumPayRollout && (
            <IonButton
              data-testid="minimum-pay-preference-button"
              size="small"
              fill={
                minPayHourlyPreference == 0 && minPayShiftPreference == 0
                  ? "outline"
                  : "solid"
              }
              style={{
                "--padding-start": "5px",
                "--padding-end": "5px",
                marginTop: "0px",
                marginBottom: "0px",
                "--background":
                  minPayHourlyPreference == 0 && minPayShiftPreference == 0
                    ? "transparent"
                    : "#10B981",
                "--background-activated": "#10B981",
                "--border-color": "#10B981",
                "--color":
                  minPayHourlyPreference == 0 && minPayShiftPreference == 0
                    ? "#10B981"
                    : "#FFFFFF",
                "--border-radius": "6px",
              }}
              onClick={openMinPayPreferenceModal}
            >
              {minPayButtonText}
            </IonButton>
          )}
        </div>
        <IonItemDivider
          style={{
            minHeight: "0",
          }}
        />
      </IonRow>
      {searchMode === SEARCH_MODE.HCF_PROFILE && <div className="h-line"></div>}
      {distancePreferenceModal && (
        <DistancePreference
          isOpen={distancePreferenceModal}
          distancePreference={distancePreference}
          closeModal={() => setDistancePreferenceModal(false)}
          updateOpenShifts={updateOpenShifts}
        />
      )}
      {minPayPreferenceModal && (
        <MinPayPreference
          isOpen={minPayPreferenceModal}
          minPayHourlyPreference={minPayHourlyPreference}
          minPayShiftPreference={minPayShiftPreference}
          baseRate={baseRate}
          closeModal={() => setMinPayPreferenceModal(false)}
          updateOpenShifts={updateOpenShifts}
          searchMode={searchMode}
        />
      )}
      {hiddenShiftModal &&
        searchMode !== SEARCH_MODE.HCF_PROFILE &&
        totalShifts !== undefined &&
        hiddenShifts && (
          <HiddenShiftsModal
            isOpen={hiddenShiftModal}
            hiddenShifts={hiddenShifts}
            closeModal={() => setHiddenShiftModal(false)}
            openMinPayPreferenceModal={openMinPayPreferenceModal}
          />
        )}
    </>
  );
};
