import request from "superagent";
import { replace, snakeCase } from "lodash";
import { logApiFailureEvent } from "src/lib/analytics";

import { environment } from "../../environments/environment";
import { GET_REQUIREMENTS_STATUS } from "./gql";
import { Requirement } from "../store/documents/model";
import { getAuthHeader } from "../superagent";
import { logFailedApiRetry } from "../utils/api_retry";
import { CheckrApplicationStatus, IFileUpload } from "./model";
import { getDocumentFileName } from "../utils/getDocumentFileName";
import {
  uploadFileToFileStorage,
  uploadFileToCloudinary,
} from "../utils/fileUpload";
import { SelectedFile } from "../shiftSignature/timecard/model";
import { logErrors } from "../errorBoundary/api";

interface UploadDocumentData {
  requirement: Requirement;
}

const uploadSelectedDocument = async (
  selectedFile: SelectedFile,
  data: UploadDocumentData,
  isFileStorageDualWriteEnabled: boolean,
  client,
  hcpId: string | undefined,
  email: string | undefined
): Promise<IFileUpload> => {
  const { requirement } = data || {};
  let result: IFileUpload;

  if (isFileStorageDualWriteEnabled && client && selectedFile.fileBlob) {
    const [cloudinaryUploadResult, fileStorageUploadResult] =
      await Promise.allSettled([
        uploadFileToCloudinary(selectedFile.file),
        uploadFileToFileStorage(
          selectedFile.fileBlob,
          selectedFile.type,
          requirement.reqId || "",
          client
        ),
      ]);
    if (cloudinaryUploadResult.status === "rejected") {
      throw new Error(cloudinaryUploadResult.reason);
    }
    const { url, filename } = cloudinaryUploadResult.value;
    const name = getDocumentFileName(requirement.name, filename);
    result = { name, url };

    if (fileStorageUploadResult.status === "rejected") {
      logErrors({
        app: "WorkerMobile",
        message: `[uploadSelectedDocument] Could not upload document file for hcp id: ${hcpId}, requirement: ${requirement}, id: ${requirement?._id}, reqId: ${requirement?.reqId}. Reason - ${fileStorageUploadResult?.reason}`,
      });
    }
    if (fileStorageUploadResult.status === "fulfilled") {
      const { fileStorageRootFolder, fileStorageFileKey } =
        fileStorageUploadResult.value;
      result = { ...result, fileStorageRootFolder, fileStorageFileKey };
    }
  } else {
    const { url, filename } = await uploadFileToCloudinary(selectedFile.file);
    const name = getDocumentFileName(requirement.name, filename);
    result = { url, name };
  }
  return result;
};

const updateCache =
  ({ covidTest, hcpId, selectedRequirement }) =>
  (cache): void => {
    const data = cache.readQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
    });
    const updatedData = { ...data.hcpRequirementStatus };
    const { requirement } = selectedRequirement;

    updatedData.pending = [...updatedData.pending, requirement.reqId];

    if (/covid/i.test(requirement.name)) {
      updatedData.mostRecentCovidDoc = { covidTest };
    }

    cache.writeQuery({
      query: GET_REQUIREMENTS_STATUS,
      variables: { hcpId },
      data: { hcpRequirementStatus: updatedData },
    });
  };

const fireDocumentReviewZapierHook = async (
  hcpId: string,
  requirement: {
    document: Requirement;
    url?: string;
  }
) => {
  return await request
    .post(`${environment.baseUrl}/agentprofile/documents/fireZapierHook`)
    .set(await getAuthHeader())
    .send({ agent: { userId: hcpId }, requirement })
    .then(({ body }) => body);
};

const getDocumentFromS3UsingDistributionUrl = async (
  distributionUrl: string
) => {
  return request
    .get(distributionUrl)
    .responseType("blob")
    .retry(1, (err) => {
      logFailedApiRetry(err, distributionUrl);
      return true;
    })
    .then(({ body }) => body);
};

const getCloudinaryDocument = async (_id: string) => {
  return request
    .get(`${environment.baseUrl}/v2/agentprofile/documents`)
    .responseType("blob")
    .retry(1, (err) => {
      logFailedApiRetry(err, `/v2/agentprofile/documents`);
      return true;
    })
    .set(await getAuthHeader())
    .query({ _id })
    .then(({ body }) => body);
};

const getCheckrApplicationStatus = async (): Promise<
  CheckrApplicationStatus | undefined
> => {
  try {
    const { data } = await request
      .get(`${environment.baseUrl}/agentprofile/checkr/application-status`)
      .set(await getAuthHeader())
      .then(({ body }) => body);

    return data;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

const resendCheckrInvite = async (): Promise<
  CheckrApplicationStatus | undefined
> => {
  try {
    const { data } = await request
      .get(`${environment.baseUrl}/agentprofile/checkr/create-invitation`)
      .set(await getAuthHeader())
      .then(({ body }) => body);

    return data;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export {
  uploadSelectedDocument,
  updateCache,
  fireDocumentReviewZapierHook,
  getCloudinaryDocument,
  getCheckrApplicationStatus,
  resendCheckrInvite,
  getDocumentFromS3UsingDistributionUrl,
};
