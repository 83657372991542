import { reduce } from "lodash";
import { ShiftDateMap } from "src/app/store/shift/model";
import { Facility } from "src/lib/interface";

export const computeShiftsCountDay = (
  shiftsData: ShiftDateMap,
  shiftType: string = "total"
): number => {
  if (shiftType == "hidden") {
    return (
      reduce(
        shiftsData,
        (acc, arr) =>
          acc + reduce(arr, (k, { filtered }) => k + (filtered ? 1 : 0), 0),
        0
      ) || 0
    );
  } else {
    return reduce(shiftsData, (acc, v) => acc + v.length, 0) || 0;
  }
};

export const computeShiftsCountCalendar = (
  openShifts,
  shiftType: string = "total"
): number => {
  if (shiftType == "hidden") {
    return (
      reduce(openShifts, (acc, { hidden }) => acc + (hidden ? hidden : 0), 0) ||
      0
    );
  } else {
    return reduce(openShifts, (acc, { total }) => acc + total, 0) || 0;
  }
};

export const computeShiftsCountMap = (
  facilityShifts: Facility[],
  shiftType: string = "total"
): number => {
  if (shiftType == "hidden") {
    return (
      reduce(
        facilityShifts,
        (acc, { filteredShiftsCount }) =>
          acc + (filteredShiftsCount ? filteredShiftsCount : 0),
        0
      ) || 0
    );
  } else {
    return (
      reduce(
        facilityShifts,
        (acc, { shiftsCount }) => acc + (shiftsCount ? shiftsCount : 0),
        0
      ) || 0
    );
  }
};
