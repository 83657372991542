import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  IonCard,
  IonCardSubtitle,
  IonButton,
  IonIcon,
  IonBadge,
} from "@ionic/react";
import { Facility, Shift } from "src/lib/interface";
import { FacilityDetails } from "../components/facilityDetails";
import { ShiftTitle } from "../components/shiftTitle";
import { useMissingDocuments } from "../../dayView/custom-hooks/useMissingDocuments";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "@store/index";
import { chatbubblesOutline } from "ionicons/icons";

const ShiftCard: React.FC<{
  shift: Shift;
  onClickOnItemFacilityDetails?: (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard?: boolean
  ) => void;
}> = ({ shift, onClickOnItemFacilityDetails }) => {
  const history = useHistory();
  const { cbh, state, msa, county, hcf } = useMissingDocuments(shift);
  const ldFlags = useFlags();
  const { channels } = useAppSelector((store) => store.chatStore);
  const { userId } = useAppSelector((store) => store.session);
  const currentChannel = channels.find(
    (channel) => channel.url === `${shift.facility?.userId}_${userId}`
  );

  const shouldRenderShift = () => {
    if (!shift) return false;

    const isShiftOngoing = moment().isAfter(shift.start);

    if (isShiftOngoing && !shift.agent) return false;

    return true;
  };

  if (!shouldRenderShift()) return null;

  const onCardClick = () => {
    history.push(`/home/myShifts/${shift._id}`);
  };

  const onChatButtonClick = () => {
    history.push(`/home/account/chat/${shift.facility?.userId}`);
  };

  const unreadMessageCount =
    (currentChannel?.unreadMessageCount || 0) <= 0
      ? false
      : currentChannel?.unreadMessageCount;
  const hasMissingDocs = !!(cbh || state || msa || county || hcf);

  const showChatButton = !!currentChannel?.url && ldFlags["chat"];

  return (
    <IonCard
      mode="ios"
      button={true}
      className="ion-padding no-text-transform new-flow shifts ongoing"
    >
      <ShiftTitle
        onShiftClick={onCardClick}
        shift={shift}
        hasMissingDocs={hasMissingDocs}
        showMissingDocs={!shift.agent}
      />
      <FacilityDetails
        onCardClick={onCardClick}
        shift={shift}
        onFacilityDetailsClick={onClickOnItemFacilityDetails}
        showCheckInInstructions={true}
      />
      <IonCardSubtitle
        onClick={onCardClick}
        style={{ fontSize: "14px" }}
        className="ion-text-center"
      >
        {!shift.agent && "Booked shift, Waiting for Confirmation"}
      </IonCardSubtitle>

      {showChatButton && (
        <IonButton
          onClick={onChatButtonClick}
          fill="outline"
          style={{ height: 34, color: "#4F4F4F", padding: 0 }}
          color="dark"
          shape="round"
          strong={true}
        >
          <SpacedElements gap={14}>
            <IonIcon
              style={{ height: 20, width: 20 }}
              icon={chatbubblesOutline}
              mode="ios"
            />
            <span style={{ color: "#4F4F4F" }}>Facility Chat</span>
            <IonBadge color="danger">{unreadMessageCount}</IonBadge>
          </SpacedElements>
        </IonButton>
      )}
    </IonCard>
  );
};

const SpacedElements = ({ children, gap }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap, height: "100%" }}>
      {children}
    </div>
  );
};

export { ShiftCard };
