import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
  IonBackButton,
  IonRow,
  IonCol,
} from "@ionic/react";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Store } from "../store/store.model";
import { setAddressEditDetails } from "../store/accountSettings";
import { LocationEdit } from "./locationEdit";
import { Place } from "./model";

interface SearchLocationProps {
  editPath?: string;
}

const SearchLocationEdit: React.FC<SearchLocationProps> = ({ editPath }) => {
  const { addressEditDetails } = useSelector(
    (state: Store) => state.accountSettingsStore
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const onPlaceChange = (place: Place) => {
    if (isEmpty(place.location) || isEmpty(place.address)) {
      return;
    }

    dispatch(
      setAddressEditDetails({
        searchLocation: place.address.formatted,
        address: place.address,
        location: place.location,
        addressLine1: place.address.line1 || "",
      })
    );
    history.push(editPath || `/home/account/editAddress`);
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref="/home/account/address"
              mode="ios"
            />
          </IonButtons>
          <IonTitle>Address</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow>
          <IonCol size="10" offset="1">
            <p>
              Please enter your physical address so we can find facility matches
              in your area.
            </p>
          </IonCol>
        </IonRow>
        <LocationEdit
          searchLocation={addressEditDetails?.searchLocation || ""}
          onPlaceChange={onPlaceChange}
        />
      </IonContent>
    </IonPage>
  );
};

export { SearchLocationEdit };
