import { Balance } from "./models";
import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";
import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";
import { logFailedApiRetry } from "../utils/api_retry";

import {
  PaymentAccountInfo,
  PayrollList,
  PayrollSummary,
} from "src/lib/interface";

const fetchPayrollData = async (queryData: object): Promise<any> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/mobile/v2`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/mobile/v2`);
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const getShiftsDetailsForBonuses = async (
  shiftIds: string[],
  projection
): Promise<any> => {
  return await request
    .post(`${environment.baseUrl}/shift/details`)
    .set(await getAuthHeader())
    .send({ shiftIds, projection })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchProofEarning = async (queryData: object): Promise<any> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/mobile/proof`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/mobile/proof`);
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ text }) => text)
    .catch(logApiFailureEvent);
};

const sendEmailProofEarning = async (queryData: object): Promise<any> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/mobile/sendEmail`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/mobile/sendEmail`);
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ text }) => text)
    .catch(logApiFailureEvent);
};

const fetchLifeTimeEarnings = async (): Promise<PayrollSummary> => {
  return await request
    .get(`${environment.baseUrl}/shift/payroll/summary`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/shift/payroll/summary`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const createZendeskSupportTicketForProofEarning = async (
  queryData: object
): Promise<any> => {
  return request
    .post(`${environment.baseUrl}/shift/payroll/mobile/create-zendesk-ticket`)
    .set(await getAuthHeader())
    .send(queryData)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchBalance = async (): Promise<Balance> => {
  return await request
    .get(`${environment.api.paymentsBaseUrl}/accounts/authenticated/balance`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/accounts/authenticated/balance`);
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const payout = async (): Promise<void> => {
  return await request
    .post(`${environment.api.paymentsBaseUrl}/accounts/authenticated/payout`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const createLoginLink = async (redirectUrl: string): Promise<string> => {
  try {
    return await request
      .post(
        `${environment.api.paymentsBaseUrl}/accounts/authenticated/create-login-link`
      )
      .send({ redirectUrl })
      .set(await getAuthHeader())
      .then(({ text }) => text);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

const updatePaymentSchedule = async (data: {
  interval: string;
}): Promise<PaymentAccountInfo> => {
  try {
    return await request
      .put(`${environment.api.paymentsBaseUrl}/accounts/authenticated/schedule`)
      .send({ interval: data.interval })
      .set(await getAuthHeader())
      .then(({ body }) => body);
  } catch (error) {
    logApiFailureEvent(error);
    throw error;
  }
};

export {
  fetchPayrollData,
  getShiftsDetailsForBonuses,
  fetchLifeTimeEarnings,
  fetchProofEarning,
  sendEmailProofEarning,
  fetchBalance,
  payout,
  createLoginLink,
  updatePaymentSchedule,
  createZendeskSupportTicketForProofEarning,
};
