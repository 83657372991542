import { IonButton } from "@ionic/react";
import { Shift } from "src/lib/interface/src";
import React, { useMemo, useState } from "react";
import { AttendancePolicyCancelActionSheet } from "./attendancePolicyCancelActionSheet";
import "./style.scss";
import { useSelector } from "react-redux";
import { Store } from "redux";
import { get } from "lodash";
import moment from "moment-timezone";
import { getNonIPAmount } from "@src/app/utils/shiftHelper";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { formatRate } from "../helper";

export const AttendancePolicyCancelShiftButton: React.VFC<{
  shift: Shift;
}> = ({ shift }) => {
  const ldClient = useLDClient();
  const [showCancelActionSheet, setShowCancelActionSheet] = useState(false);
  const { tmz: agentTmz }: { tmz: string } = useSelector((state: Store) =>
    get(state, "session.agent", {})
  );

  const { formattedDate, formattedPeriod, earnings } = useMemo((): {
    formattedDate: string;
    formattedPeriod: string;
    earnings: number;
  } => {
    const { instantPayDetails, isInstantPay } = shift;
    const shiftStart = moment(shift.start).tz(agentTmz);
    const shiftEnd = moment(shift.end).tz(agentTmz);
    const formattedDate = shiftStart.format("dddd, MMMM DD");

    const earnings =
      isInstantPay && instantPayDetails
        ? instantPayDetails.totalAmount * instantPayDetails.instantPayRate
        : getNonIPAmount(shift, ldClient!);

    const formattedPeriod = `${shiftStart.format("hh:mma")} - ${shiftEnd.format(
      "hh:mma"
    )} (${shift.time} hrs)`;

    return {
      formattedDate,
      formattedPeriod,
      earnings,
    };
  }, [shift, agentTmz, ldClient]);

  const cancelSubHeaderText = `Are you sure you want to cancel your ${formattedDate} ${formattedPeriod} shift with $${formatRate(
    earnings
  )} in total earnings?`;

  return (
    <>
      <IonButton
        data-testid="attendance-policy-cancellation-button"
        size="large"
        expand="block"
        fill="clear"
        className="attendance-policy-cancel-button"
        onClick={() => setShowCancelActionSheet(true)}
      >
        Make a cancellation
      </IonButton>
      <AttendancePolicyCancelActionSheet
        showCancelActionSheet={showCancelActionSheet}
        setShowCancelActionSheet={setShowCancelActionSheet}
        cancelSubHeaderText={cancelSubHeaderText}
        shift={shift}
      />
    </>
  );
};
