import { ActionType } from "@store/session";
import {
  Agent,
  AgentStages,
  PaymentServiceAccountStatus,
} from "src/lib/interface";
import { Geolocation } from "@capacitor/geolocation";
import moment from "moment";
import { Store } from "@store/store.model";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { logApiFailureEvent, logEvent } from "src/lib/analytics";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Diagnostic } from "@ionic-native/diagnostic";

import { HCP_ACTIONS } from "../constants";
import { showModal } from "../../shared/modal";
import { useHistory } from "react-router-dom";
import { useMissingDocuments } from "./useMissingDocuments";
import { USER_EVENTS, SHIFT_PREFERENCE } from "src/constants";
import { useShouldBeOnboarded } from "../../onboardingStripe/hooks";
import { GraphQlClient } from "src/app/graphql";
import { GET_MISSING_REQUIREMENTS_FOR_DATE } from "src/app/documents/gql";

import { claimAgentShift, fetchAgentProfile, markShiftInterest } from "../api";
import {
  initialShiftItemState,
  shiftItemReducer,
} from "../shiftItem/shiftItemReducer";
import {
  ShiftItemActionType,
  ShiftItemCategory,
  UseShiftItemHookReturnType,
} from "../model";
import { checkRolloutMsa } from "../../utils/shiftHelper";
import { getICAgreementStatus } from "../../featureFlag/api";
import { makeInstantpayLogParameters } from "../../../utils/logEvent";
import { showFacilityCovidTestingPopups } from "@app/utils/showFacilityCovidTestingPopup";
import { isLocationEnabledAndHasFullLocationPermission } from "../../openShifts/urgentShifts/utils";

const useShiftItemHook = ({
  shift,
  goToDocumentsPage,
  isInstantBookingShift,
  handleShiftActivityLog,
  updateShiftList,
  isLastMinuteShift,
  validator,
  pushNotification,
  shiftActivityLog,
  isUrgentShift,
  searchMode,
  availableShiftIds,
  filterDistance,
  filterDays,
}): UseShiftItemHookReturnType => {
  const { agent, bookingRolloutMsas } = useSelector(
    (state: Store) => state.session
  );
  const [state, dispatch] = useReducer(shiftItemReducer, initialShiftItemState);
  const [locationEnabled, setLocationEnabled] = useState(false);

  const storeDispatch = useDispatch();

  const {
    cbh,
    state: stateLoc,
    msa,
    county,
    hcf,
    covid,
    pending,
    loading: documentsLoading,
    missingDocs,
  } = useMissingDocuments(shift);

  const history = useHistory();

  const preventFromBook = useShouldBeOnboarded();

  useEffect(() => {
    if (shift.interested.find((agentId) => agentId === agent?.userId)) {
      dispatch({ type: ShiftItemActionType.SHOWED_INTEREST });
    }
  }, [
    shift.interested.length,
    agent?.userId,
    shift.agentId,
    shift.isHCFConfirmed,
  ]);

  useEffect(() => {
    if (isUrgentShift) {
      isLocationEnabledAndHasFullLocationPermission()
        .then((isEnabled) => {
          if (isEnabled !== locationEnabled) {
            setLocationEnabled(isEnabled);
          }
        })
        .catch(() => {});
    }
  }, [isUrgentShift]);

  useEffect(() => {
    if (shift.agentId === agent?.userId) {
      dispatch({ type: ShiftItemActionType.WORK_APPROVED });
    }
  }, [shift.agentId, agent?.userId, shift.isHCFConfirmed]);

  const loading = documentsLoading;

  useEffect(() => {
    if (loading) return;

    const payload = { category: ShiftItemCategory.BOOKING_WARNING };

    if (isUrgentShift && !locationEnabled) {
      payload.category = ShiftItemCategory.NO_LOCATION_PERMISSION;
    }

    if (
      agent?.paymentAccountInfo?.status ===
      PaymentServiceAccountStatus.NO_DOCUMENTS_REQUIRED
    ) {
      payload.category = ShiftItemCategory.NOT_READY_TO_BOOK_SHIFT;
    }
    if (missingDocs && missingDocs?.size > 0) {
      payload.category = ShiftItemCategory.MISSING_CBH_DOCUMENTS;
      payload["missingDocs"] = missingDocs;
    } else if (pending) {
      payload.category = ShiftItemCategory.WAITING_FOR_APPROVE;
    }
    dispatch({ type: ShiftItemActionType.SET_CATEGORY, payload });
  }, [
    cbh,
    dispatch,
    documentsLoading,
    hcf,
    isInstantBookingShift,
    loading,
    msa,
    stateLoc,
    county,
    state.interested,
    state.workApproved,
    agent,
    pending,
    locationEnabled,
  ]);

  useEffect(() => {
    if (pushNotification) {
      dispatch({
        type: ShiftItemActionType.CONFIGURE_PUSH_NOTIFICATION,
        payload: { pushNotification },
      });
      if (pushNotification.isPushSet && state.interested) {
        dispatch({ type: ShiftItemActionType.OPEN_PUSH_NOTIFICATION });
      }
    }
  }, [dispatch, pushNotification, state.interested]);

  const handleOnboardingProcess = useCallback(() => {
    return history.push(TabRouterPath.PAYMENT_SERVICE_ONBOARDING);
  }, [history]);

  const loadAlertDialog = useCallback(async () => {
    if (
      preventFromBook &&
      state.category === ShiftItemCategory.BOOKING_WARNING
    ) {
      return handleOnboardingProcess();
    }
    const agreementStatus = await getICAgreementStatus(agent?.userId as string);
    if (agreementStatus && agreementStatus.shouldSignAgreement) {
      storeDispatch({ type: ActionType.SHOW_IC_AGREEMENT_POPUP });
    } else {
      if (state.category === ShiftItemCategory.BOOKING_WARNING) {
        dispatch({
          type: ShiftItemActionType.SET_CHECKING_DOCS,
          payload: {
            checkingDocs: true,
          },
        });
        // checking requirements status as of shift end date
        const {
          data: { hcpMissingRequirementsForDate },
        } = await GraphQlClient.query({
          query: GET_MISSING_REQUIREMENTS_FOR_DATE,
          fetchPolicy: "network-only",
          variables: {
            hcfId: shift.facilityId,
            date: shift.end,
          },
        });
        dispatch({
          type: ShiftItemActionType.SET_CHECKING_DOCS,
          payload: {
            checkingDocs: false,
          },
        });
        if (hcpMissingRequirementsForDate?.length) {
          dispatch({
            type: ShiftItemActionType.SET_MISSING_DOCS,
            payload: {
              category:
                ShiftItemCategory.MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END,
              missingDocs: new Set(hcpMissingRequirementsForDate),
              alertOpen: true,
            },
          });
          return;
        }
      }
      dispatch({ type: ShiftItemActionType.OPEN_ALERT });
    }
    //SEGMENT LOG
    switch (state.category) {
      case ShiftItemCategory.MISSING_CBH_DOCUMENTS:
        logEvent(USER_EVENTS.TAPPED_UPLOAD_REQUIRED_DOCS, {
          missingDocs: !missingDocs
            ? ""
            : [...missingDocs.values()].map((i) => i.name),
          pending: !pending ? "" : [...pending.values()],
        });
        break;
    }
  }, [dispatch, preventFromBook, state, handleOnboardingProcess]);

  const handleClickUploadDocs = (): void => {
    switch (state.category) {
      case ShiftItemCategory.MISSING_CBH_DOCUMENTS:
        logEvent(USER_EVENTS.TAPPED_UPLOAD_MISSING_DOCS_POPUP_BUTTON);
        break;
    }
    goToDocumentsPage();
  };

  const showInstantPayAlert = () => {
    if (!shift.isHCFInstantPayProhibited) {
      // if instantpay === true set alert message for instantpay
      dispatch({
        type: ShiftItemActionType.SET_CATEGORY,
        payload: { category: ShiftItemCategory.WELCOME_TO_INSTANTPAY },
      });
      dispatch({ type: ShiftItemActionType.OPEN_ALERT });
    }
  };

  const instantBookShift = async (): Promise<void> => {
    if (validator && !validator(shift)) return;

    dispatch({ type: ShiftItemActionType.SUBMITTING });
    const shiftId = shift.shiftId || shift._id;
    try {
      let latitude, longitude;

      if (shift.urgency) {
        const locationAvailable = await Diagnostic.isLocationAvailable();

        if (locationAvailable) {
          const location = await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
          });

          if (location) {
            const { coords } = location;
            latitude = coords.latitude;
            longitude = coords.longitude;
          }
        }
      }

      const resp = await claimAgentShift({
        shiftId,
        hasLastMinuteGrabCheck: !!isLastMinuteShift,
        longitude,
        latitude,
        searchMode,
        availableShifts: availableShiftIds,
        filterDistance,
        filterDays,
        filterMinPayRate:
          agent?.preference?.minPayHourly || SHIFT_PREFERENCE.PAY_HOUR,
        filterMinPayTotal:
          agent?.preference?.minPayShift || SHIFT_PREFERENCE.PAY_SHIFT,
      });

      logEvent(USER_EVENTS.BOOK_INSTANTBOOK_SHIFT, {
        ...makeInstantpayLogParameters(
          shift,
          shift?.isHCFInstantPayProhibited === false,
          agent
        ),
        instant_book: true,
        last_minute: false,
        instant_pay: false,
        hcpId: agent?.userId as string,
        email: agent?.email as string,
      });
      showFacilityCovidTestingPopups(shift.facility, storeDispatch);
      if (updateShiftList) {
        updateShiftList(shift, true, resp);
      }
      if (!isLastMinuteShift && handleShiftActivityLog) {
        handleShiftActivityLog(shiftId, HCP_ACTIONS.SELF_CLAIM);
      }
      dispatch({ type: ShiftItemActionType.SUBMITTED });
    } catch (error) {
      dispatch({ type: ShiftItemActionType.ERROR_SUBMITTING });
      storeDispatch({ type: ActionType.HIDE_COVID_TESTING_POPUP });
      logApiFailureEvent(error);
      const errorMessage =
        error?.response?.body?.displayableMessage ||
        "Unable to claim this shift.";
      showModal("error", errorMessage);
    }
  };

  const bookShift = async (): Promise<void> => {
    /**
     * Validation like shift conflicts with already booked shifts are evaluated here.
     */
    if (validator && !validator(shift)) return;

    dispatch({ type: ShiftItemActionType.SUBMITTING });

    try {
      const activityLog = isLastMinuteShift ? {} : shiftActivityLog(shift._id);
      const response = await markShiftInterest(shift._id, activityLog);
      logEvent(
        USER_EVENTS.REQUEST_NON_INSTANTBOOK_SHIFT,
        makeInstantpayLogParameters(
          shift,
          shift?.isHCFInstantPayProhibited === false,
          agent
        )
      );
      if (response.error) {
        dispatch({ type: ShiftItemActionType.ERROR_SUBMITTING });

        return dispatch({
          type: ShiftItemActionType.OPEN_TOAST,
          payload: {
            toast: {
              message: response.error || "Cannot book this shift",
              duration: 5000,
              color: "danger",
            },
          },
        });
      }
      showFacilityCovidTestingPopups(shift.facility, storeDispatch);

      if (!agent?.interestedAt) {
        const response = await fetchAgentProfile();
        if (response) {
          storeDispatch({
            type: ActionType.UPDATE_AGENT,
            data: { agent: response },
          });
        }
      } else {
        dispatch({ type: ShiftItemActionType.OPEN_PUSH_NOTIFICATION });
      }

      if (!agent?.notificationPreferences?.push) {
        dispatch({
          type: ShiftItemActionType.OPEN_TOAST,
          payload: {
            toast: {
              message:
                "Your booking has been noted, a staffer will be in touch.",
              color: "success",
              duration: 5000,
            },
          },
        });
      }
      if (isLastMinuteShift) {
        logEvent(USER_EVENTS.SUCCESSFULLY_BOOKED_LAST_MINUTE_SHIFT, {
          hcpId: agent?.userId as string,
          email: agent?.email as string,
          hcpName: agent?.name as string,
          hcpQualification: agent?.qualification as string,
          shiftName: shift.name,
          shiftId: shift._id,
          shiftPay: shift.finalPay,
          facilityName: shift.facility.name,
          facilityId: shift.facility._id,
          startAt: shift.start,
          endAt: shift.end,
          timestamp: moment().format(),
          instant_book: false,
          last_minute: true,
          instant_pay: false,
        });
      } else {
        // Below data is required to send slack notifications.
        logEvent(USER_EVENTS.MARKED_INTEREST_IN_SHIFT, {
          status: response.agentHasAllDocuments ? "success" : "missing docs",
          hcpUserId: agent?.userId as string,
          hcpName: agent?.name as string,
          shiftId: shift._id,
          hcpQualification: agent?.qualification as string,
          shiftName: shift.name,
          facilityName: shift.facility.name,
          facilityId: shift.facility._id,
          startAt: shift.start,
          endAt: shift.end,
          missingDocReqIds: JSON.stringify(
            Array.from(missingDocs as Set<typeof missingDocs>).map(
              // @ts-ignore
              (doc) => doc.reqId
            )
          ),
          missingDocNames: JSON.stringify(
            Array.from(missingDocs as Set<typeof missingDocs>).map(
              // @ts-ignore
              (doc) => doc.name
            )
          ),
          timestamp: moment().format(),
        });
      }
      dispatch({ type: ShiftItemActionType.SUBMITTED });
      if (updateShiftList) {
        /**
         * Callback function to update `shifts` array.
         */
        updateShiftList(shift, false, response);
      }
    } catch (err) {
      dispatch({ type: ShiftItemActionType.ERROR_SUBMITTING });
      storeDispatch({ type: ActionType.HIDE_COVID_TESTING_POPUP });
    }
  };

  const onConfirm = async (): Promise<void> => {
    switch (state.category) {
      case ShiftItemCategory.BOOKING_WARNING:
        if (isInstantBookingShift && !shift.isHCFInstantPayProhibited) {
          showInstantPayAlert();
          instantBookShift();
        } else if (isInstantBookingShift) {
          instantBookShift();
        } else {
          bookShift();
        }
        break;
      case ShiftItemCategory.MISSING_CBH_DOCUMENTS:
      case ShiftItemCategory.MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END:
      case ShiftItemCategory.NOT_READY_TO_BOOK_SHIFT:
      case ShiftItemCategory.WAITING_FOR_APPROVE:
        handleClickUploadDocs();
        break;
      case ShiftItemCategory.WELCOME_TO_INSTANTPAY:
        if (isInstantBookingShift) {
          instantBookShift();
        }
        break;
    }
  };

  const onCancel = useCallback(() => {
    dispatch({ type: ShiftItemActionType.CLOSE_ALERT });
  }, [dispatch]);

  const onToastDidDismiss = useCallback(() => {
    dispatch({ type: ShiftItemActionType.CLOSE_TOAST });
  }, [dispatch]);

  const closePushNotification = useCallback(() => {
    dispatch({ type: ShiftItemActionType.CLOSE_PUSH_NOTIFICATION });
  }, [dispatch]);

  return {
    ...state,
    loadAlertDialog,
    onConfirm,
    onCancel,
    covid,
    loading,
    onToastDidDismiss,
    closePushNotification,
  };
};

export { useShiftItemHook };
