import { IonAlert } from "@ionic/react";
import React from "react";

import { ShiftTimeConfirmAlertProps } from "./model";

const ShiftTimeConfirmAlert: React.FC<ShiftTimeConfirmAlertProps> = ({
  isOpen,
  time,
  onClose,
}) => (
  <IonAlert
    isOpen={isOpen}
    onDidDismiss={onClose(false)}
    message={`Is the total shift time of <b>${time} hours</b> correct?`}
    buttons={[
      { text: "No", role: "cancel", handler: onClose(false) },
      {
        text: "Yes",
        handler: onClose(true),
      },
    ]}
  />
);

export { ShiftTimeConfirmAlert };
