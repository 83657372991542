import { LocationAlertType } from "@app/common/location";
import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { geoDistance } from "src/lib/utils";
import { Store } from "@store/store.model";
import { Shift as ShiftInterface } from "src/lib/interface";

const LOCATION_AWARENESS_ON = "on";

const checkLocationAwareness = (locationAwareness: string): boolean =>
  locationAwareness === LOCATION_AWARENESS_ON;

const getAlertText = (errorType?: LocationAlertType): string => {
  switch (errorType) {
    case LocationAlertType.NOT_CLOSE:
      return "You'll be able to clock your time once you are at the facility. Don't hesitate to contact our team if you have any questions!";
    default:
      return "Location services need to be enabled to clock time. Click here to allow location services.";
  }
};

const useCalculateHCPDistanceInMiles = (shift: ShiftInterface) => {
  const [distance, setDistance] = React.useState<number>(0);
  const { agent } = useSelector((state: Store) => state.session);
  const facilityCoordinates: number[] = get(
    shift,
    "facility.geoLocation.coordinates",
    []
  );
  const agentCoordinates: number[] = get(agent, "geoLocation.coordinates", []);

  React.useEffect(() => {
    const distanceInMiles = geoDistance(agentCoordinates, facilityCoordinates);
    setDistance(distanceInMiles);
  }, [shift._id, agentCoordinates]);

  return distance;
};

export { checkLocationAwareness, getAlertText, useCalculateHCPDistanceInMiles };
