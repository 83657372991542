import { Agent } from "src/lib/interface";
import { get } from "lodash";
import { Store } from "../store/store.model";
import { useSelector } from "react-redux";
export function usePaymentSchedule():
  | "daily"
  | "weekly"
  | "manual"
  | "instantly" {
  const agent: Partial<Agent | undefined> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  if (agent?.paymentAccountInfo?.schedule?.interval !== "daily")
    return agent?.paymentAccountInfo?.schedule?.interval as ReturnType<
      typeof usePaymentSchedule
    >;

  if (agent?.paymentAccountInfo?.autoWithdrawEnabled) {
    return "instantly";
  }
  return "daily";
}
