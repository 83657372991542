import { Radar, Location, RadarUser, RadarPlugin } from "capacitor-radar";

export type RemoveHandler = () => Promise<void>;

export type RadarForegroundOptions = {
  options: {
    title: string;
    text: string;
    updatesOnly?: boolean;
  };
};

interface LocationProvider {
  startTrip(params): Promise<void>;
  completeTrip(): Promise<void>;
  cancelTrip(): Promise<void>;
  startTracking(params): void;
  stopTracking(): void;
  setUserId(userId: string): void;
  addLocationEventListener(callback: Function): Promise<RemoveHandler>;
  initialize(publishableKey: string): void;
  setForegroundServiceOptions(options: RadarForegroundOptions): void;
  trackOnce(
    latitude?: number,
    longitude?: number,
    accuracy?: number
  ): Promise<void>;
}

export type RadarLocationEventHandler = (result: {
  location: Location;
  user: RadarUser;
}) => void;

export class RadarLocationProvider implements LocationProvider {
  private radarPlugin: RadarPlugin = Radar;

  constructor(radarPlugin?: RadarPlugin) {
    if (radarPlugin) {
      this.radarPlugin = radarPlugin;
    }
  }

  async addLocationEventListener(
    callback: RadarLocationEventHandler
  ): Promise<RemoveHandler> {
    const listenerHandle = await this.radarPlugin.addListener(
      "location",
      callback
    );
    return listenerHandle.remove;
  }

  async startTrip(params): Promise<void> {
    await this.radarPlugin.startTrip(params);
  }

  async completeTrip(): Promise<void> {
    await this.radarPlugin.completeTrip();
  }

  async cancelTrip(): Promise<void> {
    await this.radarPlugin.cancelTrip();
  }

  startTracking(params): void {
    this.radarPlugin.startTrackingCustom(params);
  }

  stopTracking(): void {
    this.radarPlugin.stopTracking();
  }

  setUserId(userId: string): void {
    this.radarPlugin.setUserId({ userId });
  }

  initialize(publishableKey): void {
    this.radarPlugin.initialize({ publishableKey });
  }

  setForegroundServiceOptions({ options }: RadarForegroundOptions): void {
    this.radarPlugin.setForegroundServiceOptions({
      options,
    });
  }

  async trackOnce(
    latitude?: number,
    longitude?: number,
    accuracy?: number
  ): Promise<void> {
    await this.radarPlugin.trackOnce({ latitude, longitude, accuracy });
  }
}

export class LocationService {
  constructor(private readonly provider: LocationProvider) {}

  async startTrip(params): Promise<void> {
    await this.provider.startTrip(params);
  }

  async completeTrip(): Promise<void> {
    await this.provider.completeTrip();
  }

  async cancelTrip(): Promise<void> {
    await this.provider.cancelTrip();
  }

  startTracking(params): void {
    this.provider.startTracking(params);
  }

  stopTracking(): void {
    this.provider.stopTracking();
  }

  setUserId(userId: string): void {
    this.provider.setUserId(userId);
  }

  async addLocationEventListener(callback: Function): Promise<RemoveHandler> {
    const listenerRemoveHandle = await this.provider.addLocationEventListener(
      callback
    );
    return listenerRemoveHandle;
  }

  initialize(publishableKey): void {
    this.provider.initialize(publishableKey);
  }

  setForegroundServiceOptions(options: RadarForegroundOptions): void {
    this.provider.setForegroundServiceOptions(options);
  }

  async trackOnce(
    latitude?: number,
    longitude?: number,
    accuracy?: number
  ): Promise<void> {
    await this.provider.trackOnce(latitude, longitude, accuracy);
  }
}

const locationProvider = new RadarLocationProvider();
export const locationService = new LocationService(locationProvider);
