import { IonButton, IonDatetime, IonLabel, IonNote } from "@ionic/react";
import moment from "moment-timezone";
import "./style.scss";
const ShiftActionTime = ({
  shiftActionTime,
  title,
  isEditable,
  helperText,
  facilityTimeZone,
  onChange,
}: {
  shiftActionTime?: string | undefined;
  title: string;
  isEditable: boolean;
  helperText: String;
  facilityTimeZone: string;
  onChange: (value: string) => void;
}) => {
  const shiftTimeView = isEditable ? "view-in-column" : "view-in-row";
  return (
    <div className={`edit-time-component ${shiftTimeView}`}>
      <div className={`shift-time-component ${shiftTimeView}`}>
        <IonLabel className="edit-time-label">{title}</IonLabel>
        <IonButton
          disabled={!isEditable}
          color="transparent"
          className={`time-selector-button ${shiftTimeView}`}
        >
          <IonDatetime
            displayTimezone={facilityTimeZone}
            hour-cycle="h12"
            className={"time-selector"}
            placeholder="--:--"
            displayFormat="h:mm a"
            value={shiftActionTime}
            onIonChange={(e) => onChange(e.detail.value!)}
          ></IonDatetime>
        </IonButton>
      </div>
      {!isEditable && helperText && (
        <div className="edit-helper-text-container">
          <IonNote className="edit-helper-text">Edited from </IonNote>
          <IonNote className="edit-helper-text">
            {!helperText.includes("skipped")
              ? moment(helperText.toString())
                  .tz(facilityTimeZone)
                  .format("hh:mm a")
              : helperText}
          </IonNote>
        </div>
      )}
    </div>
  );
};
export { ShiftActionTime };
