import { IonItem, IonLabel, IonText } from "@ionic/react";
import React from "react";

const InfoTitleItem: React.FC<{}> = ({ children }) => (
  <IonItem lines="none">
    <IonLabel color="primary">
      <IonText color="primary">
        <h4 className="ion-text-wrap" color="primary">
          {children}
        </h4>
      </IonText>
    </IonLabel>
  </IonItem>
);

export { InfoTitleItem };
