import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React from "react";

import { HeaderProps } from "./model";

const AppHeader: React.FC<HeaderProps> = ({ title, onBack }) => (
  <IonHeader no-border>
    <IonToolbar>
      <IonButtons slot="start">
        <IonButton
          routerDirection="back"
          fill="clear"
          size="small"
          color="light"
          onClick={onBack}
        >
          <IonIcon icon={arrowBack} mode="ios" />
        </IonButton>
      </IonButtons>
      <IonTitle>{title}</IonTitle>
    </IonToolbar>
  </IonHeader>
);

export { AppHeader };
