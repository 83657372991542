import { workerTypeObj } from "src/lib/constants"; // eslint-disable-line import/no-extraneous-dependencies

/* eslint-disable no-shadow */
export enum Pages {
  CONTACT = "contact",
  QUESTION = "question",
  TERMS = "terms",
  TRAINING = "training",
  ASSESSMENT = "assessment",
}

export const PageMetaMap = new Map([
  [
    "terms",
    {
      title: "Terms of Service",
      url: "https://drive.google.com/file/d/1iF4XBMxsJDwqg8-Cl7Xm_28idBqQqM3G/preview",
    },
  ],
  [
    "question",
    {
      title: "Have a Question?",
      url: "https://intercom.help/clipboard-health-help-center/en",
    },
  ],
  ["contact", { title: "Contact", url: "" }],
  // path to the non-dynamic page (has it's own route defined)
  ["training", { path: "trainingres" }],
  [
    "assessment",
    {
      title: "Skills Assessment",
      urls: {
        [workerTypeObj.CNA]:
          "https://docs.google.com/forms/d/e/1FAIpQLSd3bKRgFg8ODrTHvgZPEgNfqBBglxkqLIDq5q0FhrZyiE2IpA/viewform?usp=sf_link",
        [workerTypeObj.LVN]:
          "https://docs.google.com/forms/d/e/1FAIpQLSdmUjpSlCQlWSRBBQc-Pj0ga_Bb8MMPUoG3GG3FmJIeZpsO1w/viewform?usp=sf_link",
        [workerTypeObj.RN]:
          "https://docs.google.com/forms/d/e/1FAIpQLSd-Z0zfMThKovvDzE6vWo0VTGEzbmSuxXe4Nk27L7gUwxp5wA/viewform?usp=sf_link",
      },
    },
  ],
]);
