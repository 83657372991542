const customBottomBarIconsPath = "../../../assets/icons/bottom-navigation";
export const myAccountOnImg = `${customBottomBarIconsPath}/my-account-on.svg`;
export const myAccountOffImg = `${customBottomBarIconsPath}/my-account-off.svg`;
export const findShiftsOnImg = `${customBottomBarIconsPath}/find-shifts-on.svg`;
export const findShiftsOffImg = `${customBottomBarIconsPath}/find-shifts-off.svg`;
export const myShiftsOnImg = `${customBottomBarIconsPath}/my-shifts-on.svg`;
export const myShiftsOffImg = `${customBottomBarIconsPath}/my-shifts-off.svg`;
export const attendancePolicyMyShiftsOnImg = `${customBottomBarIconsPath}/my-shifts-ap-on.svg`;
export const attendancePolicyMyShiftsOffImg = `${customBottomBarIconsPath}/my-shifts-ap-off.svg`;
export const ratingsOnImg = `${customBottomBarIconsPath}/ratings-on.svg`;
export const ratingsOffImg = `${customBottomBarIconsPath}/ratings-off.svg`;
