import request from "superagent";
import { getAuthHeader } from "../../superagent";
import { environment } from "../../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";

const updateAgent = async (specialities, agentId): Promise<void> => {
  await request
    .put(`${environment.baseUrl}/agentProfile/put`)
    .set(await getAuthHeader())
    .send({ agentId, specialities })
    .catch(logApiFailureEvent);
};

export { updateAgent };
