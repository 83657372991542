import { logEvent } from "src/lib/analytics";
import { Events } from "src/lib/constants";
import { getHumanReadableTag } from "src/lib/utils";
import { IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { USER_EVENTS } from "../../constants/userEvents";
import { segmentInstance } from "../../utils/cbhSegementHelper";
import { ToolTipProps } from "../dayView/model";
import { recordImpression } from "../pushNotifications/api";
import { removeToastStatus } from "../store/Notifications";
import { Store } from "../store/store.model";
import "./style.scss";

const NotificationsToast: React.FC<ToolTipProps> = (): any => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { status, msg } = useSelector(
    (state: Store) => state.notificationStore
  );
  const { userId } = useSelector((state: Store) => state.session);

  let toastMessage = { msg: {}, link: "", messageId: "" };
  if (msg && msg.length > 0) {
    toastMessage = msg[msg.length - 1];
  }
  const { msg: body, link, messageId } = toastMessage;

  let timer;

  const TOAST_DURATION = 7000;
  useEffect(() => {
    if (msg.length > 0) {
      timer = setTimeout(() => removeNotification(), TOAST_DURATION);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [msg]);

  const removeNotification = () => {
    const arr = [...msg];
    arr.pop();
    dispatch(removeToastStatus(arr));
    clearTimeout(timer);
  };

  const onClosePressed = async () => {
    const properties = {
      action: "Closed",
      name: msg[0].method,
      medium: "Push",
      content: msg[0].msg,
      source: "",
    };
    logEvent(USER_EVENTS.PUSH_NOTIFICATION_TAPPED, properties);
    recordImpression(messageId, "push");
    removeNotification();
  };

  const onOpenPressed = () => {
    const url = new URL(msg[0].link, "http://example.com");
    const methodValue = url.searchParams.get("method") || msg[0].method;
    const messageId = url.searchParams.get("messageId") || msg[0].messageId;

    segmentInstance.track(userId?.toString() as string, Events.messageOpened, {
      message_name: getHumanReadableTag(methodValue),
      by: "Worker",
      message: msg[0].msg,
      method: methodValue,
      channel: "push",
      messageId: messageId,
    } as any);
    const properties = {
      action: "Opened",
      name: methodValue,
      medium: "Push",
      content: msg[0].msg,
      source: "",
    };
    logEvent(USER_EVENTS.PUSH_NOTIFICATION_TAPPED, properties);
    recordImpression(messageId, "push");
    if (location.pathname.includes("/home/openShifts")) {
      history.goBack();
      setTimeout(() => {
        history.push(link);
      }, 500);
    } else {
      history.push(link);
    }
    removeNotification();
  };
  if (!toastMessage) return null;

  return (
    <IonPage style={{ zIndex: "auto", contain: "none" }}>
      {status && msg && msg.length > 0 ? (
        <div className="custom-toast">
          <button className="close-btn" onClick={onClosePressed}>
            x
          </button>
          <div onClick={onOpenPressed}>{body}</div>
        </div>
      ) : (
        ""
      )}
    </IonPage>
  );
};

export { NotificationsToast };
