import { fetchAgentUnverifiedShifts } from "@store/ongoingShifts/apiV2";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonAlert,
} from "@ionic/react";
import { useState } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { Shift } from "src/lib/interface";
import { logEvent } from "src/lib/analytics";
import moment from "moment";
import { USER_EVENTS } from "../../constants";
import { fetchCurrentTimezone } from "../dayView/api";
import { addVerificationPreferencesToShiftFacility } from "../utils/shiftHelper";
import { UploadFileModal } from "../components/uploadFile";
import { UnverifiedShiftsListCard } from "./unverifiedShiftListCard";
import { api } from "@app/api";

const UnverifiedShiftsList = () => {
  const [unverifiedShifts, setUnverifiedShifts] = useState<Shift[]>([]);
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] =
    useState<boolean>(false);
  const [shiftDetailsForUpload, setShiftDetailsForUpload] = useState<Shift>();
  const [reuploadAlert, setReuploadAlert] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    fetchShifts();
  });

  const fetchShifts = async () => {
    await fetchCurrentTimezone();
    const unverifiedShiftsList = await fetchAgentUnverifiedShifts();
    const sortedList = unverifiedShiftsList.sort(
      (a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()
    );
    const facilityIds = unverifiedShiftsList
      .map((shift) => shift.facility?.userId!)
      .filter(Boolean);
    if (facilityIds.length) {
      const { data: facilitiesPreferences } =
        await api.facility.fetchFacilitiesVerificationPreferences(facilityIds);
      setUnverifiedShifts(
        addVerificationPreferencesToShiftFacility(
          sortedList,
          facilitiesPreferences
        )
      );
    } else {
      setUnverifiedShifts(sortedList);
    }
  };

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    fetchShifts();
    event.detail.complete();
  };

  const onClickOnItemFacilityDetails = (shift: Shift) => {
    if (shift.isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
  };

  return (
    <IonPage>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/myShifts" mode="ios" />
          </IonButtons>
          <IonTitle>Unverified Shifts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        {unverifiedShifts.map((shift) => (
          <IonCard
            key={shift._id}
            mode="ios"
            button
            className="ion-padding no-text-transform"
          >
            <UnverifiedShiftsListCard
              reload={fetchShifts}
              shift={shift}
              openOverlay={(shift: Shift) => {
                setShowUploadTimesheetModal(true);
                setShiftDetailsForUpload(shift);
              }}
            />
          </IonCard>
        ))}
      </IonContent>
      <IonAlert
        mode="ios"
        isOpen={reuploadAlert}
        backdropDismiss={false}
        header="You reuploaded your timesheet"
        message="Our team will now verify this shift based on this new timesheet."
        buttons={[
          {
            text: "Thank you",
            handler: () => setReuploadAlert(false),
          },
        ]}
      />
      {showUploadTimesheetModal && shiftDetailsForUpload && (
        <UploadFileModal
          shift={shiftDetailsForUpload}
          onCloseOrCancel={() => {
            setShowUploadTimesheetModal(false);
          }}
          onSuccessfulUpload={() => {
            setShowUploadTimesheetModal(false);
            fetchShifts();
            const timesheetExists =
              (shiftDetailsForUpload?.timecard?.files?.length ?? 0) > 0;
            if (timesheetExists) {
              setReuploadAlert(true);
            }
          }}
        />
      )}
    </IonPage>
  );
};

export { UnverifiedShiftsList };
