import { IonIcon } from "@ionic/react";
import { star, starHalfOutline, starOutline } from "ionicons/icons";
import React from "react";
type Props = {
  value: number;
  size?: number;
  color?: string;
  count?: number;
};

const roundHalf = (num: number) => Math.round(num * 2) / 2;
const isDecimal = (num: number) => num % 1 != 0;

export const StarRating = ({
  value,
  size = 25,
  color = "#032E68",
  count = 5,
}: Props) => {
  const getStars = () => {
    const roundedValue = roundHalf(value);
    const decimal = isDecimal(roundedValue);
    const numOfFilledStars = Math.floor(roundedValue);
    let stars: JSX.Element[] = [];

    if (!value) {
      for (let i = 0; i < count; i++) {
        stars.push(
          <IonIcon
            id="star-outline"
            icon={starOutline}
            style={{ fontSize: size }}
          />
        );
      }
      return stars;
    }

    for (let i = 0; i < numOfFilledStars && i < count; i++) {
      stars.push(
        <IonIcon id="star-filled" icon={star} style={{ fontSize: size }} />
      );
    }

    for (
      let i = 0;
      i < (decimal ? count - numOfFilledStars - 1 : count - numOfFilledStars);
      i++
    ) {
      stars.push(
        <IonIcon
          id="star-outline"
          icon={starOutline}
          style={{ fontSize: size }}
        />
      );
    }

    if (decimal) {
      stars.splice(
        numOfFilledStars,
        0,
        <IonIcon
          id="star-halfoutline"
          icon={starHalfOutline}
          style={{ fontSize: size }}
        />
      );
    }
    return stars;
  };

  return (
    <div style={{ display: "flex", color, fontSize: size }}>{getStars()}</div>
  );
};
