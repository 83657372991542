import { IonInput, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

import { Store } from "../../store/store.model";
import { InfoTitleItem } from "../infoTitleItem";
import { ManualSignatoryProps } from "./model";

const ManualSignatory: React.FC<ManualSignatoryProps> = ({
  facility,
  onChange,
  signatoryInfo,
}) => {
  const { agent = {} } = useSelector((state: Store) => state.session);

  const onInputChange = (editKey) => (event) =>
    onChange({ ...signatoryInfo, [editKey]: event.target.value });

  return (
    <IonList>
      <InfoTitleItem>
        Authorized shift signatory, {facility.name}:
      </InfoTitleItem>
      <IonItem lines="none">
        <IonLabel>
          <h4>I,</h4>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonInput
          placeholder="Type name here"
          onIonChange={onInputChange("name")}
          value={signatoryInfo.name}
        />
      </IonItem>
      <IonItem>
        <IonInput
          placeholder="Type role here"
          onIonChange={onInputChange("role")}
          value={signatoryInfo.role}
        />
      </IonItem>
      <IonItem>
        <IonInput
          placeholder="Type phone here"
          type="tel"
          inputmode="tel"
          onIonChange={onInputChange("phone")}
          value={signatoryInfo.phone}
        />
      </IonItem>
      <IonItem lines="none">
        <IonLabel>
          <h4 className="ion-text-wrap">
            confirm that <b>{agent.name}</b> had completed the above shift.
          </h4>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export { ManualSignatory };
