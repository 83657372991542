import { logEvent } from "src/lib/analytics";
import { Agent } from "src/lib/interface";
import { openInAppBrowser } from "src/lib/ionic-components";
import styled from "@emotion/styled";
import { environment } from "@env/environment";
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonLoading,
  IonSpinner,
  IonToast,
} from "@ionic/react";
import {
  checkmark,
  cogOutline,
  flash,
  logoUsd,
  mailOutline,
  warning,
} from "ionicons/icons";
import { get } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { USER_EVENTS } from "../../constants/userEvents";
import { cents, dolars } from "../../utils/format";
import {
  generateDynamicLink,
  generatePaymentServiceOnboardingLink,
} from "../onboardingStripe/api";
import { Store } from "../store/store.model";
import { createLoginLink, payout } from "./api";
import { Bubble } from "./bubble";
import { usePaymentSchedule } from "./hooks";
import { LifetimeEarningsDropdown } from "./lifetimeEarningsDropdown";
import { SummaryProps } from "./models";
import { PayrollSettingsDropdown } from "./payrollSettingsDropdown";

import { PayrollItem } from "./payrollItem";
import { useHistory } from "react-router-dom";

const PayrollContainer = styled.div`
  padding: 18px 24px;
  max-width: 540px;
  width: 100%;
  margin: auto;
  font-family: "SF Pro";
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 399px) {
    padding: 12px;
  }
`;
const HelperText = styled.p`
  margin-top: -4px;
  padding: -8px 0px 0px 0px;
  font-family: "SF Pro";
  font-size: 12px;
  color: #828282;
  text-align: center;
  font-style: italic;
`;
const PaymentSettingsTexts = {
  instantly: "You’re on auto-withdraw",
  weekly: "You’re on the automatic weekly payout schedule",
  daily: "You’re on the automatic daily payout schedule",
  manual: "You’re on a manual payout schedule",
};
const Summary: React.FC<SummaryProps> = ({
  balance,
  loading,
  setBalance,
  items,
  handleBackClick,
  handleForwardClick,
  relativeWeek,
}) => {
  const [showFeeAlert, setShowFeeAlert] = useState("");
  const [showCreateLoginLink, setShowCreateLoginLink] = useState(false);
  const [loadingPayout, setLoadingPayout] = useState(false);
  const [showStripeContactHelp, setShowStripeContactHelp] = useState(false);
  const location = useLocation();
  const [toast, setToast] = useState({ message: "", show: false });

  const [loadingCreateOnboardingLink, setLoadingCreateOnboardingLink] =
    useState(false);
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const paymentInterval = usePaymentSchedule();
  const history = useHistory();
  const onClickCloseStripeContactHelp = useCallback(
    () => setShowStripeContactHelp(false),
    [setShowStripeContactHelp]
  );
  const onClickShowStripeContactHelp = useCallback(
    () => setShowStripeContactHelp(true),
    [setShowStripeContactHelp]
  );

  let hideDropDown: () => void;
  const storeHideDropDown = (fn: () => void) => (hideDropDown = fn);

  const getPaid = () => {
    logEvent(USER_EVENTS.INSTANT_PAYOUT_REQUESTED);
    setShowFeeAlert("manual");
  };

  const handleCreateOnboardingLink = async () => {
    try {
      setLoadingCreateOnboardingLink(true);
      const redirectUrl = await generateDynamicLink(
        `${environment.webAppUrl}${location.pathname}`
      );
      const link = await generatePaymentServiceOnboardingLink(
        agent.userId as string,
        {
          refreshUrl: redirectUrl,
          returnUrl: redirectUrl,
        }
      );
      openInAppBrowser(link, "_system");
      setLoadingCreateOnboardingLink(false);
    } catch (err) {
      setLoadingCreateOnboardingLink(false);
    }
  };

  const closeFeeModal = (confirm: boolean) => async () => {
    setShowFeeAlert("");

    const { autoWithdrawEnabled } = balance;
    if (!confirm) {
      logEvent(USER_EVENTS.INSTANT_PAYOUT_REJECTED);
      return;
    }
    logEvent(USER_EVENTS.INSTANT_PAYOUT_CONFIRMED);

    setLoadingPayout(true);
    await payout();
    setLoadingPayout(false);
    showToast("Payout has been sent successfully!");
    hideDropDown();
    setBalance({
      ...balance,
      availableNow: 0,
      availableNowMinusFee: -balance.withdrawalFee,
      autoWithdrawEnabled,
    });
  };

  const handleCreateLoginLink = async () => {
    const redirectUrl = await generateDynamicLink(
      `${environment.webAppUrl}${location.pathname}`
    );
    const link = await createLoginLink(redirectUrl);
    openInAppBrowser(link, "_system");
  };

  const insuficientBalance = (): boolean => {
    return balance.availableNowMinusFee <= 0;
  };

  const getEarningsText = useMemo(() => {
    if (loading) return "Loading...";
    if (paymentInterval === "instantly") return `You're on auto-withdraw`;
    if (balance.availableNowMinusFee <= 0)
      return `You don't have available funds to withdraw`;

    return `${cents(balance.availableNow)} is available now`;
  }, [paymentInterval, loading, balance]);

  const getEarningsOnTheirWayText = useMemo(() => {
    if ((balance.availableSoon || 0) === 0) {
      return "No funds on the way";
    }
    if (balance.availableSoon < 0) {
      return `You have a negative balance of ${cents(balance.availableSoon)}`;
    }
    return `${cents(balance.availableSoon)} will be available soon`;
  }, [balance.availableSoon]);

  const hideToast = () => {
    setToast({ ...toast, show: false });
  };
  const showToast = (message: string) => {
    setToast({ message, show: true });
  };

  const gotoPoE = () => {
    history.push("/home/account/payroll/proofEarning");
  };

  return (
    <>
      <PayrollContainer className="payrollContainer">
        {(loading || balance.hasDebitCard) && (
          <Bubble
            icone={flash}
            description="Earnings in Your Express Balance"
            loading={loading}
            value={<>{getEarningsText}</>}
            storeHideDropDown={storeHideDropDown}
            dropdown={
              <>
                {paymentInterval !== "instantly" && (
                  <div
                    style={{
                      backgroundColor: "#F3F3F3",
                      textAlign: "center",
                      padding: 21,
                    }}
                  >
                    <IonButton
                      style={{ minWidth: 154 }}
                      onClick={getPaid}
                      disabled={loading || insuficientBalance()}
                    >
                      GET PAID
                    </IonButton>
                  </div>
                )}
              </>
            }
          ></Bubble>
        )}
        {!loading && !balance.hasDebitCard && (
          <Bubble
            icone={flash}
            description="Earnings in Your Express Balance"
            loading={loading}
            value={
              <>Express balance is only available if you input a debit card</>
            }
            dropdown={
              <div
                style={{
                  padding: 21,
                  textAlign: "center",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <IonButton onClick={() => setShowCreateLoginLink(true)}>
                  ENTER DEBIT CARD
                </IonButton>
              </div>
            }
          ></Bubble>
        )}
        <Bubble
          icone={checkmark}
          description="Earnings on Their Way"
          loading={loading}
          value={<>{getEarningsOnTheirWayText}</>}
        ></Bubble>
        {!loading && balance?.availableSoon > 0 && (
          <HelperText className="helperText">
            These are funds that are already in transit to your account and will
            be available soon.
          </HelperText>
        )}
        {!loading && balance?.availableSoon < 0 && (
          <HelperText className="helperText">
            When you incur a negative balance, an amount equal to the negative
            balance will be removed from your future earnings. This is caused by
            a shift adjustment after your instant pay.
          </HelperText>
        )}
        <Bubble
          icone={logoUsd}
          description="Lifetime Clipboard Health Earnings"
          loading={loading}
          value={
            <>
              You&apos;ve made{" "}
              <span style={{ color: "#1C69D1", fontWeight: "bold" }}>
                {dolars(balance.lifetimeEarnings)}
              </span>{" "}
              so far!
            </>
          }
          valueFontSize={15}
          dropdown={
            <LifetimeEarningsDropdown
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              items={items}
              relativeWeek={relativeWeek}
            ></LifetimeEarningsDropdown>
          }
        ></Bubble>
        <Bubble
          icone={cogOutline}
          description="Pay Settings"
          loading={loading}
          value={<>{PaymentSettingsTexts[paymentInterval]}</>}
          storeHideDropDown={storeHideDropDown}
          dropdown={
            <PayrollSettingsDropdown
              balance={balance}
            ></PayrollSettingsDropdown>
          }
        ></Bubble>
        <PayrollItem
          icone={mailOutline}
          name="Proof of Earnings"
          onClick={gotoPoE}
        />
        <IonButton
          style={{ minWidth: 154 }}
          onClick={handleCreateOnboardingLink}
          disabled={loading || loadingCreateOnboardingLink}
        >
          {loadingCreateOnboardingLink ? (
            <IonSpinner color="light" name="crescent" />
          ) : (
            "Take me to my Stripe account"
          )}
        </IonButton>
        <IonButton
          color="light"
          shape="round"
          className="contact-stripe-button"
          onClick={onClickShowStripeContactHelp}
        >
          <IonIcon icon={warning} />
          Can&apos;t login to Stripe?
        </IonButton>
      </PayrollContainer>
      {/* Misc */}

      {!loading && (
        <>
          <IonAlert
            isOpen={showFeeAlert !== ""}
            onDidDismiss={closeFeeModal(false)}
            header={"Send Funds?"}
            message={
              `You'll receive ${cents(balance.availableNowMinusFee)} after we` +
              ` remove ${cents(balance.withdrawalFee)} to cover processing cost`
            }
            backdropDismiss={true}
            buttons={[
              {
                text: "Never Mind",
                role: "cancel",
                handler: closeFeeModal(false),
              },
              {
                text: "Get Paid Now",
                handler: closeFeeModal(true),
              },
            ]}
          />

          <IonAlert
            isOpen={showCreateLoginLink}
            onDidDismiss={() => setShowCreateLoginLink(false)}
            header={"Change Payout Method"}
            message={`We'll take you to your Stripe account where you can update your payout information`}
            backdropDismiss={true}
            buttons={[
              {
                text: "Never Mind",
                role: "cancel",
                handler: () => setShowCreateLoginLink(false),
              },
              {
                text: "Change It",
                handler: handleCreateLoginLink,
              },
            ]}
          />

          <IonLoading
            isOpen={loadingPayout}
            onDidDismiss={() => setLoadingPayout(false)}
            message={"Please wait..."}
          />

          <IonToast
            isOpen={toast.show}
            onDidDismiss={hideToast}
            message={toast.message}
            duration={3000}
            position="top"
          />
        </>
      )}
      <IonAlert
        isOpen={showStripeContactHelp}
        onDidDismiss={onClickCloseStripeContactHelp}
        header={"Do you want to contact Stripe?"}
        message={`If you can’t login to your Stripe account, you need to contact them directly to solve your issue. Don’t forget to mention your account is of the express type.`}
        backdropDismiss
        mode="ios"
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Contact",
            handler: () => {
              logEvent(USER_EVENTS.CONTACT_STRIPE_CLICKED);
              window.location.href =
                "https://support.stripe.com/contact?skip_login=true";
            },
          },
        ]}
      />
    </>
  );
};

export { Summary };
