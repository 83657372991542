import moment from "moment-timezone";
import { ILicenseDetails, LICENSE_STATUSES } from "./interfaces";
import { LICENSES_SUMMARY_TEXT_COLORS } from "./constants";

export const getLicensesCountByStatus = (
  licenses: ILicenseDetails[]
): { [key in LICENSE_STATUSES]?: number } => {
  const licensesCountByStatus = {};
  licenses.forEach((license) => {
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days");
    let status = license.status;
    if (daysUntilExpired <= 0) {
      status = LICENSE_STATUSES.EXPIRED;
    } else if (daysUntilExpired <= 90) {
      // Expiring in 3 months
      status = LICENSE_STATUSES.EXPIRING;
    }

    if (!status) {
      return;
    }

    if (status && !(status in licensesCountByStatus)) {
      licensesCountByStatus[status] = 0;
    }
    licensesCountByStatus[status] += 1;
  });
  return licensesCountByStatus;
};

export const getLicensesSummaryText = (licensesCountByStatus: {
  [key in LICENSE_STATUSES]?: number;
}): JSX.Element[] => {
  let summaryText: JSX.Element[] = [];
  for (let licenseStatus in LICENSE_STATUSES) {
    if (licensesCountByStatus[LICENSE_STATUSES[licenseStatus]]) {
      if (LICENSE_STATUSES[licenseStatus] === LICENSE_STATUSES.EXPIRING) {
        // Ignoring EXPIRING one as we are adding it with ACTIVE status
        continue;
      }

      if (
        LICENSE_STATUSES[licenseStatus] === LICENSE_STATUSES.ACTIVE &&
        licensesCountByStatus[LICENSE_STATUSES.EXPIRING]
      ) {
        // Logic to include active & expiring summary text
        summaryText.push(
          <>
            <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS.ACTIVE }}>
              {(licensesCountByStatus[LICENSE_STATUSES.ACTIVE] || 0) +
                (licensesCountByStatus[LICENSE_STATUSES.EXPIRING] || 0)}{" "}
              {LICENSE_STATUSES.ACTIVE}
            </span>
            &nbsp;
            <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS.EXPIRING }}>
              ({licensesCountByStatus[LICENSE_STATUSES.EXPIRING]}{" "}
              {LICENSE_STATUSES.EXPIRING})
            </span>
          </>
        );
      } else {
        summaryText.push(
          <span style={{ color: LICENSES_SUMMARY_TEXT_COLORS[licenseStatus] }}>
            {licensesCountByStatus[LICENSE_STATUSES[licenseStatus]]}{" "}
            {LICENSE_STATUSES[licenseStatus]}
          </span>
        );
      }
    }
  }
  return summaryText;
};
