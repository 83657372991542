import { GlobalRouterPath } from "./globalRoute";
import { RoutePathInfo } from "./interface";

const TabRouterPath = {
  MY_SHIFTS: `${GlobalRouterPath.HOME}/myShifts`,
  OPEN_SHIFTS: `${GlobalRouterPath.HOME}/openShifts`,
  RATINGS: `${GlobalRouterPath.HOME}/ratings`,
  SHIFT_REPLACEMENTS: `${GlobalRouterPath.HOME}/shiftRecommendations/:shiftId`,
  OPEN_SHIFT_DAY: `${GlobalRouterPath.HOME}/openShifts/:date`,
  ACCOUNT: `${GlobalRouterPath.HOME}/account`,
  CHAT: `${GlobalRouterPath.HOME}/account/chat/:facilityId`,
  CHAT_CHANNELS_LIST: `${GlobalRouterPath.HOME}/account/chat`,
  SUPPORT: `${GlobalRouterPath.HOME}/account/support`,
  ADDRESS: `${GlobalRouterPath.HOME}/account/address`,
  ADDRESS_SEARCH: `${GlobalRouterPath.HOME}/account/searchAddressLocation`,
  ADDRESS_EDIT: `${GlobalRouterPath.HOME}/account/editAddress`,
  SPECIALITIES: `${GlobalRouterPath.HOME}/account/specialities`,
  PAYROLL: `${GlobalRouterPath.HOME}/account/payroll`,
  PROOF_INCOME: `${GlobalRouterPath.HOME}/account/payroll/proofEarning`,
  DOCUMENTS: `${GlobalRouterPath.HOME}/account/documents`,
  HCP_BONUSES: `${GlobalRouterPath.HOME}/account/hcp-bonuses`,
  DOCUMENT_VIEW: `${GlobalRouterPath.HOME}/account/documentView`,
  PROFILE: `${GlobalRouterPath.HOME}/account/profile`,
  PROFILE_CONTRACT: `${GlobalRouterPath.HOME}/account/profile/contract`,
  REFERRAL: `${GlobalRouterPath.HOME}/account/referral`,
  CO_WORKER_REFERRAL: `${GlobalRouterPath.HOME}/account/referral/co-worker`,
  MY_SHIFT_DAY: `${GlobalRouterPath.HOME}/myShiftList/:shiftDate`,
  LAST_MINUTE_SHIFTS: `${GlobalRouterPath.HOME}/lastMinuteShifts`,
  MY_SHIFT_DETAIL: `${GlobalRouterPath.HOME}/myShifts/:shiftId`,
  MY_SHIFT_RATING: `${GlobalRouterPath.HOME}/myShift/:shiftId/rating`,
  MY_SHIFT_SIGNATURE: `${GlobalRouterPath.HOME}/myShift/:shiftId/signature`,
  UNVERIFIED_SHIFTS: `${GlobalRouterPath.HOME}/myShifts/unverifiedShifts`,
  SUCCESS_ONBOARDING: `${GlobalRouterPath.HOME}/alreadyOnboarded`,
  PAYMENT_SERVICE_ONBOARDING: `${GlobalRouterPath.HOME}/onboardingPayments`,
  UPDATED_CONTRACTOR_AGREEMENT: `${GlobalRouterPath.HOME}/updatedContractorAgreement`,
  FACILITY_DETAIL: `${GlobalRouterPath.HOME}/facility/:facilityId`,
  NEED_HELP: `${GlobalRouterPath.HOME}/account/needhelp`,
  HELP_DETAILS: `${GlobalRouterPath.HOME}/account/needhelp/details`,
  TRAINING_RESOURCES: `${GlobalRouterPath.HOME}/account/needhelp/trainingres`,
  UPDATE_PROFILE: `${GlobalRouterPath.HOME}/updateProfile`,
  DELETE_DATA: `${GlobalRouterPath.HOME}/deleteData`,
  LICENSE_MANAGER: `${GlobalRouterPath.HOME}/account/licenses`,
  LICENSE_DETAILS: `${GlobalRouterPath.HOME}/account/licenses/:licenseId/details`,
  ADD_LICENSE: `${GlobalRouterPath.HOME}/account/licenses/add`,
};

const TabRouterPathInfo: Record<keyof typeof TabRouterPath, RoutePathInfo> = {
  MY_SHIFTS: {
    name: "My Shifts",
  },
  OPEN_SHIFTS: {
    name: "Open Shift Calendar",
  },
  RATINGS: {
    name: "Attendance Rating",
  },
  OPEN_SHIFT_DAY: {
    name: "Open Shift Day View",
  },
  SHIFT_REPLACEMENTS: {
    name: "Shift Recommendations",
  },
  ACCOUNT: {
    name: "Account Page",
  },
  CHAT: {
    name: "Chat Page",
  },
  CHAT_CHANNELS_LIST: {
    name: "Facility Chats",
  },
  SUPPORT: {
    name: "Support Page",
  },
  ADDRESS: {
    name: "Address View",
  },
  ADDRESS_SEARCH: {
    name: "Address Search View",
  },
  ADDRESS_EDIT: {
    name: "Address Edit Page",
  },
  SPECIALITIES: {
    name: "Speciality View",
  },
  PAYROLL: {
    name: "Payroll View",
  },
  PROOF_INCOME: {
    name: "Proof of Earnings",
  },
  DOCUMENTS: {
    name: "Document List View",
  },
  HCP_BONUSES: {
    name: "HCP Bonuses",
  },
  DOCUMENT_VIEW: {
    name: "Document Detail View",
  },
  PROFILE: {
    name: "Profile View",
  },
  PROFILE_CONTRACT: {
    name: "Contract View",
  },
  REFERRAL: {
    name: "Referral Page",
  },
  CO_WORKER_REFERRAL: {
    name: "Referral Co-Worker Page",
  },
  MY_SHIFT_DAY: {
    name: "My Shift List Day View",
  },
  MY_SHIFT_DETAIL: {
    name: "Shift Detail Page",
  },
  MY_SHIFT_RATING: {
    name: "Shift Rating Page",
  },
  MY_SHIFT_SIGNATURE: {
    name: "Shift Signature Page",
  },
  UNVERIFIED_SHIFTS: {
    name: "Unverified Shifts Page",
  },
  LAST_MINUTE_SHIFTS: {
    name: "Last Minute Shifts",
  },
  SUCCESS_ONBOARDING: {
    name: "Success Onboarded",
  },
  PAYMENT_SERVICE_ONBOARDING: {
    name: "Onboarding Payment Service",
  },
  UPDATED_CONTRACTOR_AGREEMENT: {
    name: "Updated Contractor Agreement",
  },
  FACILITY_DETAIL: {
    name: "Facility Detail",
  },
  NEED_HELP: {
    name: "Need Help?",
  },
  HELP_DETAILS: {
    name: "Help Details",
  },
  TRAINING_RESOURCES: {
    name: "Training Resources",
  },
  UPDATE_PROFILE: {
    name: "Update Profile",
  },

  DELETE_DATA: {
    name: "Delete Data",
  },
  LICENSE_MANAGER: {
    name: "License Manager",
  },
  LICENSE_DETAILS: {
    name: "License Details Page",
  },
  ADD_LICENSE: {
    name: "Add License",
  },
};

export { TabRouterPath, TabRouterPathInfo };
