import request from "superagent";
import { getAuthHeader } from "../../superagent";
import { environment } from "../../../environments/environment";
import { isEmpty } from "lodash";
import { logApiFailureEvent } from "src/lib/analytics";
import { showModal } from "../../shared/modal";
import {
  AuthorizedSignatory,
  Shift,
  ShiftStages,
  Timecard,
  TimeRange,
} from "src/lib/interface";
import { FetchAgentShiftRequest, RatingRequest } from "./model";
import { logFailedApiRetry } from "../../utils/api_retry";

const fetchAgentShifts = async (
  query: FetchAgentShiftRequest
): Promise<Shift[]> => {
  return await request
    .get(`${environment.baseUrl}/calendar/agentShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/calendar/agentShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(query)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const createAccountLog = async (
  text: string,
  accountId: string,
  shiftId: string
): Promise<object> => {
  return await request
    .post(`${environment.baseUrl}/account-log/create`)
    .set(await getAuthHeader())
    .send({ text, accountID: accountId, addedBy: accountId, meta: { shiftId } })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const recordShiftTime = async (
  shiftId: string,
  stage: ShiftStages,
  location: number[],
  timecard,
  locationType: string,
  appType: string,
  connectivityMode: string
): Promise<Shift> => {
  if (isEmpty(timecard)) {
    return await request
      .post(`${environment.baseUrl}/shift/agentSignature/recordTime`)
      .set(await getAuthHeader())
      .send({
        shiftId,
        stage,
        location,
        timecard,
        locationType,
        appType,
        connectivityMode,
      })
      .then(({ body }) => body)
      .catch((error) => {
        logApiFailureEvent(error);
        showModal("error", error.message, "Error Clocking Time");
      });
  } else {
    let result;
    try {
      const formData = new FormData();
      formData.append("file", timecard);
      formData.append("upload_preset", environment.fileUploadPreset);
      formData.append("folder", "timecard");
      result = await request.post(environment.fileUploadURL).send(formData);
    } catch (error) {
      logApiFailureEvent(error);
      showModal("error", "Error Uploading Time Card", "Upload Error");
    }
    const { url } = result.body;

    return await request
      .post(`${environment.baseUrl}/shift/agentSignature/recordTime`)
      .set(await getAuthHeader())
      .send({
        shiftId,
        stage,
        location,
        timecard: url,
        locationType,
        appType,
        connectivityMode,
      })
      .then(({ body }) => body)
      .catch((error) => {
        logApiFailureEvent(error);
        showModal("error", error.message, "Error Clocking Time");
      });
  }
};

const setShiftStage = async (
  shiftId: string,
  clockInOut: TimeRange,
  lunchInOut: TimeRange
): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/shift/setShiftStage`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      clockInOut,
      lunchInOut,
    })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const recordDisputedTime = async (
  shiftId: string,
  stage: ShiftStages,
  location: number[]
): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/shift/agentSignature/recordDisputedTime`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      stage,
      location,
    })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updateShiftUnit = async (shiftId: string, unit: string): Promise<Shift> =>
  await request
    .post(`${environment.baseUrl}/shift/unit`)
    .set(await getAuthHeader())
    .send({ shiftId, unit })
    .then(({ body }) => body.shift)
    .catch(logApiFailureEvent);

interface UploadTimecardResponse {
  status: Number;
  body: {
    response: {
      timecard: Timecard;
    };
  };
}

const uploadTimecard = async (
  selectedFile,
  _id: string,
  locationType: string,
  appType: string,
  connectivityMode: string
): Promise<UploadTimecardResponse> => {
  const formData = new FormData();
  formData.append("file", selectedFile);
  formData.append("upload_preset", environment.fileUploadPreset);
  formData.append("folder", "timecard");
  const result = await request.post(environment.fileUploadURL).send(formData);
  const { url, original_filename: filename } = result.body;
  const fileUploadResult = [{ url, filename }];

  return await request
    .put(`${environment.baseUrl}/v1/shifts/timecard/${_id}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      locationType,
      appType,
      connectivityMode,
    })
    .then(({ body, status }) => ({ ...body.timecard, status }))
    .catch(logApiFailureEvent);
};

const updateWorkerSignature = async (
  shiftId: string,
  signature: string
): Promise<Shift> =>
  await request
    .post(`${environment.baseUrl}/shift/workerSignature`)
    .set(await getAuthHeader())
    .send({ shiftId, signature })
    .then(({ body }) => body.shift)
    .catch(logApiFailureEvent);

const requestAuthorizedSignature = async (
  shiftId: string,
  signatoryInfo: AuthorizedSignatory
): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/shift/verification/requestSignature`)
    .set(await getAuthHeader())
    .send({ shiftId, signatoryInfo })
    .then(({ body }) => body.shift)
    .catch(logApiFailureEvent);
};

const signWithSignature = async (
  shiftId: string,
  signatoryInfo: AuthorizedSignatory,
  signature: string
): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/shift/verification/signWithSignature`)
    .set(await getAuthHeader())
    .send({ shiftId, signatoryInfo, signature })
    .then(({ body }) => body.shift)
    .catch(logApiFailureEvent);
};

const addRating = async (req: RatingRequest): Promise<Shift> => {
  return await request
    .post(`${environment.baseUrl}/rating`)
    .set(await getAuthHeader())
    .send({ reviewFor: "FACILITY", ...req })
    .then(({ body, status }) => ({ ...body, status }))
    .catch(logApiFailureEvent);
};

export {
  fetchAgentShifts,
  recordShiftTime,
  createAccountLog,
  recordDisputedTime,
  updateShiftUnit,
  uploadTimecard,
  updateWorkerSignature,
  requestAuthorizedSignature,
  signWithSignature,
  addRating,
  setShiftStage,
};
