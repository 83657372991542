import { IonIcon } from "@ionic/react";
import { Moment } from "moment";
import moment from "moment-timezone";
import { ribbon } from "ionicons/icons";
import { ShiftCountDateMap } from "../model";
import {
  getBonusesForDate,
  HcpBonus,
} from "@app/hcpBonuses/useFetchHcpBonuses";
import "./style.scss";

interface ShiftCalendarDateProps {
  date: Moment;
  renderShiftChip: () => JSX.Element;
  openShiftMap?: ShiftCountDateMap;
  isHcpBonusesEnabled: boolean;
  bonuses: HcpBonus[];
}

export function ShiftCalendarDate(props: ShiftCalendarDateProps) {
  const day = props.date.format("YYYY-MM-DD");
  let className = `ant-picker-cell-inner ant-picker-calendar-date`;
  const isToday = props.date.isSame(moment(), "day");
  if (isToday) {
    className += " ant-picker-calendar-date-today";
  }

  const { total = 0 } = props.openShiftMap?.[day] || {};
  const dayHasOpenShifts = total > 0;
  const shouldDisplayBonusBadge =
    props.isHcpBonusesEnabled &&
    dayHasOpenShifts &&
    getBonusesForDate(props.bonuses, props.date).length > 0;

  return (
    <div className={className}>
      <div className={`ant-picker-calendar-date-value`}>
        <div className="shift-calendar-date">
          {moment(props.date).format("DD")}
          {shouldDisplayBonusBadge && (
            <span className="shift-calendar-date-bonus-indicator">
              <IonIcon icon={ribbon} />
            </span>
          )}
        </div>
      </div>
      <div className={`ant-picker-calendar-date-content`}>
        {props.renderShiftChip()}
      </div>
    </div>
  );
}
