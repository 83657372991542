import { logEvent } from "src/lib/analytics";
import { IonButton, IonLabel, IonSpinner } from "@ionic/react";
import { Geolocation } from "@capacitor/geolocation";
import { Diagnostic } from "@ionic-native/diagnostic";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "@store/index";
import { SessionEnv } from "@store/session";
import { Shift, TimeSystems } from "src/lib/interface";
import { USER_EVENTS } from "../../../constants/userEvents";
import { InstantShiftButton } from "../../components/shift/InstantShiftButton";
import { logErrors } from "../../errorBoundary/api";
import { ShiftDetailsAlerts } from "../shiftDetails/alerts";
import { api } from "@app/api";

const RecordTimeButton: React.VFC<{
  shift: Shift | null;
  loadShiftDetails: (shiftId: string) => void;
  uploadTimeSheet: (boolean?: boolean) => void;
  setOpenNoTimeSheetOptions: (v: boolean) => void;
  hasMissedClockIn: boolean;
  timeSheetExists: boolean;
  isTimeSheetRequired: boolean;
  isNewTimeSheetEnabled: boolean;
  shiftDetailsAlerts: ShiftDetailsAlerts;
  isSignatureSubmission: boolean;
  isNFCEnabled: boolean;
  isDigitalSignatureEnabled: boolean;
  isRefactoredVersionEnabled: boolean;
  isNFCExternalWriteEnabled: boolean;
}> = ({
  shift,
  loadShiftDetails,
  uploadTimeSheet,
  setOpenNoTimeSheetOptions,
  hasMissedClockIn,
  timeSheetExists,
  isTimeSheetRequired,
  isNewTimeSheetEnabled,
  shiftDetailsAlerts,
  isSignatureSubmission,
  isNFCEnabled,
  isDigitalSignatureEnabled,
  isRefactoredVersionEnabled,
  isNFCExternalWriteEnabled,
}) => {
  const [timeElapsed, setTimeElapsed] = useState<boolean>(false);
  const env = useAppSelector(
    (state) => state?.session?.env ?? ({} as SessionEnv)
  );
  const [isShiftStarted, setIsShiftStarted] = useState(false);

  const [loading, setLoading] = useState(true);
  const [showAttendanceButton, setShowAttendanceButton] = useState(false);
  const [submittingAttendance, setSubmittingAttendance] = useState(false);
  const ldClient = useLDClient();
  const urgentShiftsEnabled = ldClient?.variation("urgent-shifts", false);
  const urgentShiftsConfig = ldClient?.variation("urgent-shifts-config", null);

  const checkAttendance = async (
    urgentShiftsServiceUrl: string,
    shift: Shift
  ) => {
    setLoading(true);

    try {
      const { attendanceCheck } = await api.shift.checkAttendanceConfirmation(
        urgentShiftsServiceUrl,
        { shiftId: shift._id }
      );

      if (!attendanceCheck) {
        return;
      }

      setShowAttendanceButton(true);
    } catch (err: any) {
      logErrors({
        message: JSON.stringify(err?.stack || err),
        app: "hcp-mobile",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAttendance = async () => {
    setSubmittingAttendance(true);
    try {
      let latitude, longitude;

      const locationAvailable = await Diagnostic.isLocationAvailable();

      if (locationAvailable) {
        const location = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });

        if (location) {
          const { coords } = location;
          latitude = coords.latitude;
          longitude = coords.longitude;
        }
      }

      await api.shift.confirmAttendance(
        urgentShiftsConfig.urgentShiftsServiceUrl,
        {
          shiftId: shift!._id,
          latitude,
          longitude,
        }
      );
    } catch (err: any) {
      logErrors({
        message: JSON.stringify(err?.stack || err),
        app: "hcp-mobile",
      });
    } finally {
      setSubmittingAttendance(false);
      setShowAttendanceButton(false);
    }
  };

  useEffect(() => {
    if (
      !urgentShiftsEnabled ||
      !urgentShiftsConfig?.urgentShiftsServiceUrl ||
      !shift
    ) {
      setLoading(false);
      return;
    }

    checkAttendance(urgentShiftsConfig.urgentShiftsServiceUrl, shift);
  }, [urgentShiftsEnabled, urgentShiftsConfig, shift]);

  useEffect(() => {
    if (!shift) return;

    const diff = moment(shift?.start)
      .add(env?.minToAllowTimeCardUpload, "minutes")
      .diff(moment());
    setTimeElapsed(diff <= 0);
    const timeout = setTimeout(() => {
      setTimeElapsed(true);
    }, diff);

    return () => clearTimeout(timeout);
  }, [env, shift, timeElapsed]);

  useEffect(() => {
    if (!shift) return;
    const isShiftStartedDiff = moment(shift?.start).diff(moment());
    setIsShiftStarted(isShiftStartedDiff <= 0);
    const timeout = setTimeout(() => {
      setIsShiftStarted(true);
    }, isShiftStartedDiff);

    return () => clearTimeout(timeout);
  }, [env, shift]);

  if (!shift) {
    return null;
  }

  const handleUploadTimeSheet = () => {
    const event = shift?.isInstantPay
      ? USER_EVENTS.TAPPED_TO_UPLOAD_INSTANTPAY_TIMECARD_PHOTO
      : USER_EVENTS.TAPPED_TO_UPLOAD_TIMECARD_PHOTO;
    logEvent(event, {
      instant_pay: shift?.isInstantPay === true,
    });
    uploadTimeSheet();
  };

  const isMissed =
    hasMissedClockIn ||
    (shift?.instantPayDetails && shift?.instantPayDetails.deleted);

  const showUploadTimesheet =
    (shift.isChangedToNonInstantPay &&
      (isRefactoredVersionEnabled
        ? !isNewTimeSheetEnabled || !isDigitalSignatureEnabled
        : shift.facility?.timeSystem === TimeSystems.PAPER)) ||
    !shift.isChangedToNonInstantPay;

  const openNoTimeSheet = () => {
    logEvent("Doesn’t Have Timecard");
    setOpenNoTimeSheetOptions(true);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IonSpinner name="crescent" />
      </div>
    );
  }

  if (showAttendanceButton) {
    return (
      <IonButton
        size="large"
        expand="block"
        shape="round"
        color="primary"
        className="ion-margin"
        onClick={handleConfirmAttendance}
        disabled={submittingAttendance}
      >
        Confirm Attendance{" "}
        {submittingAttendance && <IonSpinner name="crescent" />}
      </IonButton>
    );
  }

  return shift?.isInstantPay && !isMissed && !timeSheetExists ? (
    <InstantShiftButton
      shift={shift}
      loadShiftDetails={loadShiftDetails}
      uploadTimeSheet={uploadTimeSheet}
      onClickNoTimeSheet={openNoTimeSheet}
      shiftDetailsAlerts={shiftDetailsAlerts}
      isNewTimeSheetEnabled={isNewTimeSheetEnabled}
      isSignatureSubmission={isSignatureSubmission}
      isNFCEnabled={isNFCEnabled}
      isDigitalSignatureEnabled={isDigitalSignatureEnabled}
      isRefactoredVersionEnabled={isRefactoredVersionEnabled}
      isNFCExternalWriteEnabled={isNFCExternalWriteEnabled}
    />
  ) : isTimeSheetRequired &&
    isShiftStarted &&
    !timeSheetExists &&
    showUploadTimesheet ? (
    <>
      {timeElapsed && !isSignatureSubmission && (
        <IonLabel className="no-timesheet-select" onClick={openNoTimeSheet}>
          I don’t have a timesheet
        </IonLabel>
      )}
      <IonButton
        size="large"
        expand="block"
        shape="round"
        color="primary"
        className="ion-margin"
        onClick={handleUploadTimeSheet}
        disabled={!timeElapsed}
      >
        {isNewTimeSheetEnabled && isSignatureSubmission
          ? "Get Facility Signature"
          : "Upload Your Timesheet"}
      </IonButton>
    </>
  ) : null;
};

export { RecordTimeButton };
