import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateDetails } from "../store/specialities";
import { Store } from "../store/store.model";
import { Experience } from "./experience";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDetails,
    },
    dispatch
  );

const mapStateToProps = (state: Store) => ({
  agent: state.session.agent,
  specialities: state.specialitiesStore,
});

const SpecialitiesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Experience);

export { SpecialitiesPage };
