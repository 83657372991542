import { InputChangeEventDetail } from "@ionic/core";
import { IonInput, IonItem } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { UnitInputProps } from "./model";
import { actionUpdateShiftUnit } from "../../store/ongoingShifts";

const UnitInput: React.FC<UnitInputProps> = ({
  shiftId,
  defaultValue,
  updateShift,
}) => {
  const dispatch = useDispatch();
  const [unit, setUnit] = useState<string>("");

  useEffect(() => {
    setUnit(defaultValue);
  }, [defaultValue]);

  const onChange = ({ detail }: CustomEvent<InputChangeEventDetail>) => {
    setUnit(detail.value as string);
    updateShift({ _id: shiftId, unit: detail.value as string });
  };

  const onBlur = async () => {
    const shift = await dispatch<any>(actionUpdateShiftUnit(shiftId, unit));
    updateShift(shift);
  };

  return (
    <IonItem lines="full">
      <IonInput
        autofocus={true}
        placeholder="Unit/Locatio1"
        name="unit"
        value={unit}
        onIonChange={onChange}
        onIonBlur={onBlur}
      />
    </IonItem>
  );
};

export { UnitInput };
