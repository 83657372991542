import moment from "moment-timezone";
import {
  allowedClockInDifferenceInMinutes,
  allowedClockOutDifferenceInMinutes,
} from "../constants/shiftEventTimeDifference";
import {
  clockInAfterShiftEndError,
  clockInBeforeOneHourError,
  clockInEmpty,
  clockInOutEmpty,
  clockOutAfterTwoHoursError,
  clockOutBeforeClockIn,
  clockOutBeforeShiftStart,
  clockOutEmpty,
  clockOutPostActualClockOut,
  eventTimeNotInOrder,
  lunchInBeforeLunchOut,
  lunchInOutEmpty,
} from "../constants/shiftEventTimeErrorMessages";

export const isValidClockInTime = (time, shiftStart, shiftEnd): string => {
  if (
    moment(shiftStart).diff(moment(time), "minutes") >
    allowedClockInDifferenceInMinutes
  )
    return clockInBeforeOneHourError;
  if (moment(shiftEnd).diff(moment(time), "minutes") < 0)
    return clockInAfterShiftEndError;
  return "";
};

export const isValidClockOutTime = (
  time,
  shiftStart,
  actualClockOutTime,
  shiftEnd
): string => {
  if (
    moment(time).diff(moment(shiftEnd), "minutes") >
    allowedClockOutDifferenceInMinutes
  )
    return clockOutAfterTwoHoursError;
  if (moment(time).isAfter(moment(actualClockOutTime)))
    return clockOutPostActualClockOut;
  if (moment(time).isBefore(moment(shiftStart)))
    return clockOutBeforeShiftStart;
  return "";
};

export const isValidLunchInTime = (time, lunchOutTime): string => {
  if (moment(time).isBefore(moment(lunchOutTime))) return lunchInBeforeLunchOut;
  return "";
};

export const isValidShiftTime = (
  clockInTime,
  clockOutTime,
  lunchOutTime,
  lunchInTime
): string => {
  let errorMessage = isShiftTimesFilled(
    clockInTime,
    clockOutTime,
    lunchOutTime,
    lunchInTime
  );
  if (errorMessage) return errorMessage;
  //check when break is skipped clock-in and clock-out are in order
  if (!lunchOutTime && !lunchInTime && !!clockInTime && !!clockOutTime) {
    if (moment(clockInTime).isAfter(moment(clockOutTime)))
      return clockOutBeforeClockIn;
  }
  //check event times are in order - clock-in<lunch-in<lunch-out<clock-out
  if (!!lunchOutTime && !!lunchInTime && !!clockInTime && !!clockOutTime) {
    if (
      moment(lunchInTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(lunchInTime)) ||
      moment(clockInTime).isAfter(moment(lunchOutTime)) ||
      moment(clockInTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(clockOutTime)) ||
      moment(lunchOutTime).isAfter(moment(lunchInTime))
    )
      return eventTimeNotInOrder;
  }
  return "";
};

export const isShiftTimesFilled = (
  clockInTime,
  clockOutTime,
  lunchOutTime,
  lunchInTime
): string => {
  //Check if clock-in is filled and clock-out is not
  if (!clockInTime && !!clockOutTime) return clockInEmpty;
  //Check if clock-out is filled and clock-in is not
  if (!!clockInTime && !clockOutTime) return clockOutEmpty;
  //Check if lunch-in and lunch-out are filled and clock-in and clock-out are not
  if ((!clockInTime || !clockOutTime) && (!!lunchOutTime || !!lunchInTime))
    return clockInOutEmpty;
  //Check if lunch-in is filled and lunch-out is not or vice versa
  if ((!lunchOutTime && !!lunchInTime) || (!lunchInTime && !!lunchOutTime))
    return lunchInOutEmpty;
  return "";
};
