import {
  DISMISSED_STRIPE_POPUP,
  WAS_APP_PAUSED,
  IS_SIGNUP,
} from "../onboardingStripe/constants";

export default function handleStripePopup(isActive: boolean) {
  if (isActive) {
    const isSignup = localStorage.getItem(IS_SIGNUP);
    if (isSignup === "true") {
      localStorage.removeItem(WAS_APP_PAUSED);
    }
  } else {
    localStorage.removeItem(DISMISSED_STRIPE_POPUP);
    localStorage.setItem(WAS_APP_PAUSED, "true");
  }
}
