import { IonRouterOutlet } from "@ionic/react";
import { get } from "lodash";
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

import { VerifyPage } from "../welcome";
import { OnboardingRouterPath } from "../routing/constant/onboardingRoute";
import {
  AgentAddress,
  AgentEmail,
  AgentSSN,
  AgentFirstName,
  AgentLastName,
  AgentQualification,
  AgentOnboardingLicense,
  AgentSignUpInfo1,
  AgentSignUpInfo2,
  AgentSignUpInfo3,
  WelcomeAgent,
  AgentPotentialShifts,
  AgentDoB,
} from "./components";
import { OnBoardingRouteProps, STAGES } from "./model";
import { ContractorAgreementReview } from "./components/ContractorAgreementReview";
import { OnboardToStripe } from "../onboardingStripe";
import { StripeOnboarding } from "./components/StripeOnboarding";
import { ProceedToRequirementInfo } from "./components/ProceedToRequirementsInfo";
import { AgentUploadDocuments } from "./components/AgentUploadDocuments";
import { animationBuilder } from "src/app/utils/routeUtils";

const OnBoardingRouter: React.FC<OnBoardingRouteProps> = (props) => {
  const { match, agent } = props;
  return (
    <IonRouterOutlet animation={animationBuilder}>
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_INFO1}
        render={() => (
          <AgentSignUpInfo1 {...props} {...STAGES.agentSignUpInfo1} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_INFO2}
        render={() => (
          <AgentSignUpInfo2 {...props} {...STAGES.agentSignUpInfo2} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_INFO3}
        render={() => (
          <AgentSignUpInfo3 {...props} {...STAGES.agentSignUpInfo3} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_ADDRESS}
        render={() => <AgentAddress {...props} {...STAGES.agentAddress} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_QUALIFICATION}
        render={() => (
          <AgentQualification {...props} {...STAGES.agentQualification} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_LICENSE}
        render={() => (
          <AgentOnboardingLicense {...props} {...STAGES.agentQualification} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_FIRST_NAME}
        render={() => <AgentFirstName {...props} {...STAGES.agentFirstName} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_LAST_NAME}
        render={() => <AgentLastName {...props} {...STAGES.agentLastName} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_EMAIL}
        render={() => <AgentEmail {...props} {...STAGES.agentEmail} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_POTENTIAL_SHIFTS}
        render={() => (
          <AgentPotentialShifts {...props} {...STAGES.agentPotentialShifts} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT_REVIEW}
        render={() => (
          <ContractorAgreementReview
            {...props}
            {...STAGES.contractorAgreementReview}
          />
        )}
      />

      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_CONTRACTOR_AGREEMENT}
        render={() => (
          <OnboardToStripe {...props} {...STAGES.contractorAgreement} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_STRIPE}
        render={() => (
          <StripeOnboarding {...props} {...STAGES.stripeOnboarding} />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_PROCEED_TO_REQUIREMENT_INFO}
        render={() => (
          <ProceedToRequirementInfo
            {...props}
            {...STAGES.proceedToRequirementsInfo}
          />
        )}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_UPLOAD_DOCUMENTS}
        render={() => (
          <AgentUploadDocuments {...props} {...STAGES.uploadDocuments} />
        )}
      />

      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_WELCOME}
        render={() => <WelcomeAgent {...props} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_REVERIFY}
        component={VerifyPage}
      />
      <Route
        exact
        path={match.path}
        render={() => {
          return <Redirect to={OnboardingRouterPath.ONBOARDING_INFO1} />;
        }}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_SSN}
        render={() => <AgentSSN {...props} {...STAGES.agentSSN} />}
      />
      <Route
        exact
        path={OnboardingRouterPath.ONBOARDING_DOB}
        render={() => <AgentDoB {...props} {...STAGES.agentDOB} />}
      />
    </IonRouterOutlet>
  );
};

export const OnBoardingRoutes = withRouter(OnBoardingRouter);
