/* eslint-disable max-len*/
import { License } from "src/lib/interface";
import moment from "moment-timezone";

const today = moment().format("YYYY-MM-DD");
export const allAgreements = [
  {
    title: "You are an Independent Contractor",
    details: ({ qualification = "", license = {} as License }): string => `
    This independent contractor agreement (this "<b>Agreement</b>") sets forth the terms and conditions whereby you agree to provide certain services (as described in Schedule 1) to certain health care providers (“HCPs”) who have contracted with Twomagnets Inc., dba Clipboard Health a Delaware Corporation (the "<b>Company</b>") under a Master Services Agreement (“MSA”).  You and the Company are referred to herein as “the Parties.”
    <br />
    <br />
    Under this Agreement, the Company will refer you to HCPs in exchange for the Company referring you to said HCPs.
    <br />
    <br />
    You shall provide to HCPs the services listed on the description of the shift (the "<b>Services</b>") pursuant to such referral.
    <br />
    <br />
    The Company shall not control or direct the manner or means by which you perform the Services to HCPs, including but not limited to, the time and place you perform the Services and the manner in which you perform the Services, which will be based on your professional judgment as an independent, licensed healthcare professional. You are not required to accept any shifts offered to you, and there will be no consequences for your refusal to accept any shifts. You agree that you are customarily engaged in an independently established trade, occupation, or business of the same nature as the Services performed.
    <br />
    <br />
    Unless otherwise stated on Schedule 1 or otherwise agreed to in writing, you shall furnish, at your own expense, the materials, equipment, supplies, and other resources necessary to perform the Services. 
    <br />
    <br />
    You shall comply with all third-party access rules and procedures communicated to you in writing by the HCP, including those related to safety, security, and confidentiality.  You understand and agree that any such rules and procedures are determined by third-party HCPs, not by the Company.  
    <br />
    <br />
    You are an independent contractor, and this Agreement shall not be construed to create any association, partnership, joint venture, employment, or agency relationship between you and the Company for any purpose. You have no authority (and shall not hold yourself out as having authority) to bind the Company and you shall not make any agreements or representations on the Company's behalf without the Company's prior written consent.
    <br />
    <br />
    <b>Without limiting the prior paragraph, you will not be eligible to participate in any vacation, group medical or life insurance, disability, profit sharing or retirement benefits, or any other fringe benefits or benefit plans offered by the Company to its employees, and the Company will not be responsible for withholding or paying any income, payroll, Social Security, or other federal, state, or local taxes, making any insurance contributions, including for unemployment or disability, or obtaining workers' compensation insurance on your behalf. You shall be responsible for, and shall indemnify the Company against liability to the Company for all such taxes or contributions that you fail to pay, including penalties and interest.</b> Any persons employed or engaged by you in connection with the performance of the Services shall be your employees or contractors, and you shall be fully responsible for them and fully indemnify the Company against any claims made by or on behalf of any such employee or contractor or because of the acts of such employee or contractor.
    <br />
    <br />
    You may be engaged or employed in any other business, trade, profession, or other activity while providing services to the Company, in which case you agree to abide by the terms of the Sections titled “Mutual Promises” and “Confidentiality.”
    `,
    showAgreement: false,
  },
  {
    title: "How long our relationship lasts",
    details: (): string =>
      `The term of this Agreement shall commence on the date on which you sign this Agreement and shall continue until the Services are completed, unless earlier terminated in accordance with Section 10 or otherwise agreed to by you and the HCP to which you perform the Services (the "<b>Term</b>"). 
      <br />
      The Company may freely assign its rights and obligations under this Agreement at any time. Subject to the limits on assignment stated above, this Agreement will inure to the benefit of, be binding on, and be enforceable against each of the Parties and their respective successors and assigns.
      
      `,
    showAgreement: false,
  },
  {
    title: "How you get paid",
    details: (): string =>
      `In consideration of the Services and terms of this Agreement, you shall receive the pay set forth in Schedule 1 (“Fees”). You acknowledge that you shall be solely responsible for all federal, state, and local taxes, as set out in Section 4.2.
    <br />
    Unless otherwise agreed to between you and the HCP to which you perform the Services, you are solely responsible for any travel or other costs or expenses incurred by you in connection with the performance of the Services, and in no event shall the Company reimburse you for any such costs or expenses.
    <br />
    All undisputed Fees shall be be paid to you within 7 business days after the Company's receipt of an invoice submitted by you and confirmed by the HCP.
    `,
    showAgreement: false,
  },
  {
    title: "How you use our app",
    details: (): string =>
      `As between you and the Company, the Company is, and will remain, the sole and exclusive owner of all right, title, and interest in and to any documents, specifications, data, know-how, methodologies, software, and other materials provided to you by the Company ("<b>Company Materials</b>"), and all Intellectual Property Rights therein. You have no right or license to reproduce or use any Company Materials except solely during the Term to the extent necessary to perform your obligations under this Agreement. All other rights in and to the Company Materials are expressly reserved by the Company. You have no right or license to use the Company's trademarks, service marks, trade names, logos, symbols, or brand names.
    `,
    showAgreement: false,
  },
  {
    title: "Confidentiality",
    details:
      (): string => `You acknowledge that you may have access to information that is treated as confidential and proprietary by the Company and HCPs including, without limitation the existence and terms of this Agreement, trade secrets, technology, and information pertaining to business operations and strategies, customers, pricing, marketing, finances, sourcing, personnel, patient information, or operations of the Company, its affiliates, or their suppliers or customers, in each case whether spoken, written, printed, electronic, or in any other form or medium (collectively, the "<b>Confidential Information</b>").
    <br />
    <br />
    You acknowledge you are required to abide by all HCP, company, local, state, and federal regulations concerning the confidentiality of records. You are also required to abide by the federal HIPAA, ARRA, and HITECH (Health Insurance Portability and Accountability Act, American Recovery and Reinvestment Act, Health Information Technology for Economic and Clinical Health) regulations concerning the confidentiality and security of individually identifiable health information. Compliance with this policy is required as a condition of maintaining your relationship with Company. Violation of this provision will constitute a material breach of this Agreement. It is Company’s policy to closely guard and protect confidential information to assure the dignity and the privacy of all patients and personnel with whom Company personnel may have contact. Confidential information includes all Company or HCP patient-related information and personnel information including personally identifiable, medical, financial or employment related information.
    <br />
    You understand and acknowledge that:
    <br />
    <ul style="list-style-type: none">
      <li style="text-align: justify">
        <span>(a)</span> <span style="padding-left: 4em">You may have access to confidential information including patient, employee, financial and operations (written and electronic) in different facilities and that you are to safeguard this information.</span>
      </li>
      <li style="text-align: justify">
       <span>(b)</span> <span style="padding-left: 4em">In those cases, where you are provided protected information, you will ensure that both the data and the physical medium (paper report, diskette and/or tape, etc.) is maintained in a secure work location and will not be removed, duplicated, or copied and will protect information and materials from unauthorized access or disclosure.</span>
      </li>
      <li style="text-align: justify">
        <span>(c)</span> <span style="padding-left: 4em">If you are assigned a computer code in a facility, you will be responsible for preventing unauthorized disclosure of information through misuse of my user code. You recognize that your user code is the equivalent of your signature and must remain under your control at all times.</span>
      </li>
      <li style="text-align: justify">
        <span>(d)</span> <span style="padding-left: 4em">You are to follow all HCP policies, state, and federal regulations regarding confidentiality of patient and employee information (including federal HIPAA, ARRA, and HITECH regulations).</span>
      </li>
    </ul>
    <br />
    Any Confidential Information that you access or develop in connection with the Services, including but not limited to any Work Product, shall be subject to the terms and conditions of this clause. You agree to treat all Confidential Information as strictly confidential, not to disclose Confidential Information or permit it to be disclosed, in whole or part, to any third party without the prior written consent of the Company and the applicable HCP in each instance, and not to use any Confidential Information for any purpose except as required in the performance of the Services. You shall notify the Company immediately in the event you become aware of any loss or disclosure of any Confidential Information.
    <br />
    Confidential Information shall not include information that:
    <br />
    <ul style="list-style-type: none">
      <li style="text-align: justify">
        <span>(a)</span> <span style="padding-left: 4em">is or becomes generally available to the public other than through your breach of this Agreement; or </span>
      </li>
      <li style="text-align: justify">
        <span>(b)</span> <span style="padding-left: 4em">is communicated to you by a third party that had no confidentiality obligations with respect to such information.</span>
      </li>
    </ul>
    <br />
    Nothing in this Agreement shall be construed to prevent disclosure of Confidential Information as may be required by applicable law or regulation, or pursuant to the valid order of a court of competent jurisdiction or an authorized government agency, provided that the disclosure does not exceed the extent of disclosure required by such law, regulation, or order. You agree to provide written notice of any such order to an authorized officer of the Company within 2 business days of receiving such order, but in any event sufficiently in advance of making any disclosure to permit the Company to contest the order or seek confidentiality protections, as determined in the Company's sole discretion.
    <br />
    Notice of Immunity Under the Defend Trade Secrets Act of 2016 ("DTSA"). Notwithstanding any other provision of this Agreement:
    <br/>
    <ul style="list-style-type: none">
      <li style="text-align: justify">
      (a)         	You will not be held criminally or civilly liable under any federal or state trade secret law for any disclosure of a trade secret that:
        <ul style="list-style-type: none">
        <li style="text-align: justify">
          (i)          	is made: (A) in confidence to a federal, state, or local government official, either directly or indirectly, or to an attorney; and (B) solely for the purpose of reporting or investigating a suspected violation of law; or
        </li>
        <li style="text-align: justify">
          (ii)        	is made in a complaint or other document that is filed under seal in a lawsuit or other proceeding.
        </li>
        </ul>
      </li>
      <li style="text-align: justify">
      (b)         	If you file a lawsuit for retaliation by the Company for reporting a suspected violation of law, you may disclose the Company's trade secrets to your attorney and use the trade secret information in the court proceeding if you:
      <ul style="list-style-type: none">
        <li style="text-align: justify">
          (i)          	file any document containing the trade secret under seal; and
        </li>
        <li style="text-align: justify">
          (ii)        	do not disclose the trade secret, except pursuant to court order.
        </li>
      </ul>
      </li>
    </ul>
    <br />
    In the event you breach or threaten to breach this Section of this Agreement, you acknowledge and agree that the Company shall be entitled to seek, in addition to other available remedies, a temporary or permanent injunction or other equitable relief restraining such breach or threatened breach from any court of competent jurisdiction, and that money damages would not afford an adequate remedy, without the necessity of showing any actual damages, and without the necessity of posting any bond or other security. This equitable relief shall be in addition to, not in lieu of, legal remedies, monetary damages, or other available forms of relief.
    
    `,
    showAgreement: false,
  },
  {
    title: "Mutual promises",
    details: (): string => `You represent and warrant to the Company that:
    <br/>
    <ul style="list-style-type: none">
    <li style="text-align: justify">
      (a)         	you have the right to enter into this Agreement, to grant the rights granted in this Agreement, and to perform fully all of your obligations in this Agreement;
    </li>
    <li style="text-align: justify">
      (b)         	your entering into this Agreement with the Company and your performance of the Services do not and will not conflict with or result in any breach or default under any other agreement to which you are subject;
    </li>
    <li style="text-align: justify">
      (c)         	you have the required skill, experience, and qualifications to perform the Services, you shall perform the Services in a professional and workmanlike manner in accordance with best industry standards for similar services, and you shall devote sufficient resources to ensure that the Services are performed in a timely and reliable manner;
    </li>
    <li style="text-align: justify">
      (d)         	you shall perform the Services in compliance with all applicable federal, state, and local laws and regulations, including by maintaining all licenses, permits, registrations required to perform the Services;
    </li>
    <li style="text-align: justify">
      (e)         	you are customarily engaged in an independently established trade, occupation, or business of the same nature as the Services performed and plan to continue to engage in that trade, occupation, or business after the expiration or termination of this Agreement. 
    </li>
    </ul>
   
    <p>The Company represents and warrants to you that:</p>
    <ul style="list-style-type: none">
    <li style="text-align: justify">
      (a)         	it has the full right, power, and authority to enter into this Agreement and to perform its obligations hereunder; and
    </li>
    <li style="text-align: justify">
      (b)         	the execution of this Agreement by its representative, whose signature is set forth at the end of this Agreement, has been duly authorized by all necessary corporate action
    </li>
    </ul>

    `,
    showAgreement: false,
  },
  {
    title: "Liability and Insurance",
    details:
      (): string => `You shall defend, indemnify, and hold harmless the Company and its affiliates and their officers, directors, employees, agents, successors, and assigns from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, or expenses of whatever kind (including reasonable attorneys' fees) arising out of or resulting from:
    <br />
    <ul style="list-style-type: none">
    <li style="text-align: justify">
      (a)         	bodily injury, death of any person, or damage to real or tangible personal property resulting from your acts or omissions; or
    </li>
    <li style="text-align: justify">
      (b)         	your breach of any representation, warranty, or obligation under this Agreement.
    </li>
    </ul>
    <br />
    <b>During the Term, you shall maintain in force adequate workers' compensation, commercial general liability, errors and omissions, and other forms of insurance, in each case with insurers reasonably acceptable to the Company, with policy limits sufficient to protect and indemnify the Company and its affiliates, and each of their officers, directors, agents, employees, subsidiaries, partners, members, controlling persons, and successors and assigns, from any losses resulting from your acts or omissions or the acts or omissions of your agents, contractors, servants, or employees.</b> The Company shall be listed as an additional insured under such policy, and you shall forward a certificate of insurance verifying such insurance upon the Company's written request, which certificate will indicate that such insurance policies may not be canceled before the expiration of a 30 calendar day notification period and that the Company will be immediately notified in writing of any such notice of termination.
    
    `,
    showAgreement: false,
  },
  {
    title: "Dealing with problems",
    details:
      (): string => `<i>Agreement to Arbitrate.</i>  Any dispute, controversy, or claim arising out of or related to this Agreement,  any breach or termination of this Agreement, your relationship with the Company, and/or the Services you provide under this Agreement, including but not limited to any alleged violation of any federal, state, or local statute, regulation, common law, or public policy, whether sounding in contract, tort, or statute, shall be submitted to and decided by binding arbitration.  This agreement to arbitrate does not cover any claims that cannot be compelled to arbitration as a matter of applicable law, including certain administrative claims with local, state, or federal government agencies.  In addition, nothing in this Agreement shall be deemed to excuse a party from bringing an administrative claim before any agency in order to fulfill the party’s obligation to exhaust administrative remedies before making a claim in arbitration.  Notwithstanding the above, to the extent a party wishes to pursue claims after the administrative proceedings have concluded (or wishes to appeal an administrative decision), and those further proceedings would ordinarily be heard in a court, the party must resolve those further proceedings through arbitration under this Agreement, and not in a court.  The Parties understand and fully agree that by entering into this Agreement to arbitrate they are giving up their right to have a trial by jury, and are giving up their normal rights of appeal following the rendering of a decision except as applicable law provides for judicial review of arbitration proceedings. The Parties anticipate that by entering into this Agreement, they will gain the benefits of a speedy and less expensive dispute resolution procedure.
    <br />
    <br />
    <i>Class, Collective, and Representative Action Waiver.</i>  Except for claims which cannot be waived under applicable law and which are therefore excluded from this Agreement, the Parties waive the right to assert, participate in, or receive money or any other relief from any class, representative, or collective claims against each other in court, arbitration, or any other proceeding. Each party shall only submit their own individual claims against the other and will not seek to represent the interests of any other person. The arbitrator shall have no jurisdiction or authority to adjudicate any class, representative, or collective claim, or to consolidate different arbitration proceedings with or join any other party to an arbitration between the Parties. The arbitrator, not any court, shall have exclusive authority to resolve any dispute relating to the enforceability or formation of this Agreement and the arbitrability of any dispute between the Parties, except for any dispute relating to the enforceability or scope of the class and collective action waiver, which shall be determined by a court of competent jurisdiction.  If this Section is deemed to be unenforceable for any reason with respect to a particular claim, then such claim shall not proceed in arbitration but rather shall be resolved in a court of competent jurisdiction.  If that occurs, than this Agreement will still be fully enforceable as to any and all other claims, which, if covered by Section 15.1, must be resolved in arbitration.  In such a case, all arbitrable claims will be decided first, and any claim which must be decided in court shall be stayed until the full and final resolution of the arbitrable claims.
    <br />
    <br />
    <i>Arbitration Procedures.</i>  Arbitration shall be initiated upon the written notice of either party.  The party initiating arbitration must give the other party notice within the applicable statute of limitations for the type of claim(s) asserted.  Any notice of arbitration provided to the Company by you must be sent to 340 S Lemon Ave #5028,Walnut, CA 91789-2706. Any notice of arbitration provided to you by the Company will be sent to your current residential address on file with the Company.  The notice must identify and describe the nature of all claims asserted and the facts upon which such claims are based.  Arbitration shall be held in the county in which you have contracted to provide services under this Agreement before a single arbitrator mutually selected by the parties, or, if the parties cannot agree, by the parties alternatively striking the names of seven (7) arbitrators provided by the American Arbitration Association (AAA) (with the party to strike first being decided by a coin toss).  Each party shall be permitted to take reasonable discovery consistent with the expedited nature of arbitration.  The Arbitrator shall conduct and preside over an arbitration hearing of reasonable length, to be determined by the Arbitrator. The Arbitrator shall provide the Parties with a written decision explaining his or her findings and conclusions. Any arbitral award determination shall be final and binding upon the Parties. Judgment on the arbitrator's award may be entered in any court of competent jurisdiction.
    <br />
    <br />
    <i>Substantive Law.</i>  This Section 15, in all respects, is governed by the Federal Arbitration Act, 9 U.S.C. § 1 et seq., and evidences a transaction involving interstate commerce.  The FAA applies to this Agreement in its entirety, including all the FAA’s provisions, whether substantive or procedural.  The Arbitrator shall apply the substantive state or federal law (and the law of remedies, if applicable) as applicable to the claim(s) asserted in arbitration. The Arbitrator shall conduct and preside over an arbitration hearing of reasonable length, to be determined by the Arbitrator. The Arbitrator shall provide the Parties with a written decision explaining his or her findings and conclusions. The Arbitrator's decision shall be final and binding upon the Parties although subject to judicial review pursuant to the law applicable to judicial review of arbitration proceedings.
    <br />
    <br />
    <i>Arbitration Fees.</i>  The Company will be responsible for all costs unique to arbitration, including any fees and expenses of the Arbitration beyond the amount that you would have to pay if the claims had been brought in court.  However, if any party prevails on a statutory claim which affords the prevailing party attorneys' fees and costs, the Arbitrator may award reasonable attorneys' fees and costs to the prevailing party consistent with applicable law.  The Arbitrator may not award attorneys’ fees to a party that would not otherwise be entitled to such an award under applicable law.  Any dispute as to the reasonableness of any fee or cost shall be resolved by the Arbitrator. 
    <br />
    <br />
    <u>Governing Law, Jurisdiction, and Venue.</u> Except for this Section of the Agreement, which shall be governed by the Federal Arbitration Act, this Agreement and all related documents including all schedules attached and all matters arising out of or relating to this Agreement and the Services provided hereunder, whether sounding in contract, tort, or statute are governed by and construed in accordance with the laws of the State of California. Any action or proceeding by either of the Parties to enforce this Agreement shall be brought only in any state or federal court located in the State of California, County of San Francisco. The Parties irrevocably submit to the non-exclusive jurisdiction of these courts and waive the defense of inconvenient forum to the maintenance of any action or proceeding in such venue.`,
    showAgreement: false,
  },
  {
    title: "Ending our relationship",
    details:
      (): string => `You or the Company may terminate this Agreement without cause upon written notice to the other party to this Agreement. In the event of termination pursuant to this clause, you shall receive on a pro-rata basis any Fees then due and payable for any Services completed, up to and including the date of such termination.
    <br />
    <br />
    Upon expiration or termination of this Agreement for any reason, or at any other time upon the Company's written request, you shall promptly after such expiration or termination:
    <ul style="list-style-type: none">
    <li style="text-align: justify">
      (a)         	deliver to the Company all tangible documents and other media, including any copies, containing, reflecting, incorporating, or based on the Confidential Information;
    </li>
    <li style="text-align: justify">
      (b)         	permanently erase all of the Confidential Information from your computer and phone systems; and
    </li>
    <li style="text-align: justify">
      (c)         	certify in writing to the Company that you have complied with the requirements of this clause.
    </li>
    </ul>
    <br />
    The terms and conditions of this Section and the Sections titled “You are an Independent Contractor, “How you use our App”, “Mutual Promises” and “Dealing with Problems,”shall survive the expiration or termination of this Agreement.
    `,
    showAgreement: false,
  },
  {
    title: "Miscellaneous",
    details:
      (): string => `You shall not export, directly or indirectly, any technical data acquired from the Company, or any products utilizing any such data, to any country in violation of any applicable export laws or regulations.
    <br />
    <br />
    All notices, requests, consents, claims, demands, waivers, and other communications (each, a "<b>Notice</b>") shall be in writing and addressed to the Parties at the addresses set forth on the first page of this Agreement (or to such other address that may be designated by the receiving party from time to time in accordance with this Section). All Notices shall be delivered by personal delivery or certified or registered mail (in each case, return receipt requested, postage prepaid). Except as otherwise provided in this Agreement, a Notice is effective only if (a) the receiving party has received the Notice, and (b) the party giving the Notice has complied with the requirements of this Section.
    <br />
    <br />
    This Agreement, together with any other documents incorporated by reference including related exhibits and schedules, constitutes the sole and entire agreement of the Parties to this Agreement with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.
    <br />
    <br />
    This Agreement may only be amended, modified, or supplemented by an agreement in writing signed by each party, and any of the terms thereof may be waived, only by a written document signed by each party to this Agreement or, in the case of waiver, by the party or parties waiving compliance.
    <br />
    <br />
    Except as otherwise provided in Section 15.2, if any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
    <br />
    <br />
    This Agreement may be executed in multiple counterparts and by electronic signature, each of which shall be deemed an original and all of which together shall constitute one instrument.
    
    `,
    showAgreement: false,
  },
];
