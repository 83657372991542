import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonToast,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import moment from "moment-timezone";
import { useFlags } from "launchdarkly-react-client-sdk";
import { openInAppBrowser } from "src/lib/ionic-components";
import { Store } from "../store/store.model";
import { getAttendanceStats } from "./api";
import { StatsContainer } from "./statsContainer";
import { FeatureFlag } from "../../constants/FEATURE_FLAGS";
import { defaultAttendanceStats, defaultErrorProps } from "./constants";
import "./style.scss";
import { LearnMoreCard } from "./learnMoreCard";
import { RefresherEventDetail } from "@ionic/core";

const MarketplacePolicyRatingPage = () => {
  const agent = useSelector((state: Store) => state.session.agent);

  const [attendanceStats, setAttendanceStats] = useState(
    defaultAttendanceStats
  );
  const [errorProps, setErrorProps] = useState(defaultErrorProps);

  const ldFlags = useFlags();
  const statusQuoAttendancePolicyLinks =
    ldFlags[FeatureFlag.STATUS_QUO_ATTENDANCE_POLICY_LINKS];

  const onViewCancellationPolicy = () => {
    if (statusQuoAttendancePolicyLinks?.cancellationPolicy) {
      openInAppBrowser(statusQuoAttendancePolicyLinks?.cancellationPolicy);
    } else {
      setErrorProps({
        isOpen: true,
        message: "Oops, the link you clicked can't be resolved",
        duration: 2500,
      });
    }
  };

  const fetchAttendanceStats = async (
    refreshEvent?: CustomEvent<RefresherEventDetail>
  ) => {
    if (!agent?.userId) {
      return;
    }
    try {
      const attendanceStats = await getAttendanceStats(agent.userId);

      setAttendanceStats({ ...attendanceStats });
    } catch (error) {
      setErrorProps({
        isOpen: true,
        message:
          "Oops, we can't get your attendance stats. Please refresh the page, and if the issue persists contact Clipboard Health",
        duration: 4500,
      });
    }
    refreshEvent?.detail.complete();
  };

  useEffect(() => {
    fetchAttendanceStats();
  }, [agent?.userId]);

  return (
    <>
      <IonHeader no-border>
        <IonToolbar>
          <IonTitle
            class="ion-no-padding ion-text-center"
            data-testid="ratings-title"
          >
            My Ratings
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={fetchAttendanceStats}>
          <IonRefresherContent />
        </IonRefresher>
        {!agent || !attendanceStats.accountStatus ? (
          <IonContent className="ion-text-center ion-padding-top">
            <IonSpinner
              name="crescent"
              color="dark"
              data-testid="ratings-spinner"
            />
          </IonContent>
        ) : (
          <IonContent>
            <IonCard
              class="ion-padding-vertical"
              data-testid="ratings-account-status-card"
            >
              <IonItem lines="none">
                <h1 className="ratings-card-title">Account status</h1>
              </IonItem>
              <IonItem lines="none">
                {attendanceStats.accountStatus && (
                  <div className="ratings-account-status" slot="start">
                    {attendanceStats.accountStatus}
                  </div>
                )}
                {attendanceStats.reEnrollmentDate && (
                  <div
                    className="ratings-re-enrollment-date"
                    slot="end"
                    data-testid="ratings-re-enrollment-date"
                  >
                    Your account will be re-enrolled on{" "}
                    {`${moment(attendanceStats.reEnrollmentDate)
                      .tz(agent.tmz || "America/Los_Angeles")
                      .format("MM/DD/YYYY")} at ${moment(
                      attendanceStats.reEnrollmentDate
                    )
                      .tz(agent.tmz || "America/Los_Angeles")
                      .format("hh:mma")}`}
                  </div>
                )}
              </IonItem>
              <div
                className="ratings-view-cancellation-policy"
                onClick={onViewCancellationPolicy}
              >
                How Cancellations Work
              </div>
            </IonCard>
            <IonCard
              class="ion-padding-vertical"
              data-testid="ratings-current-attendance-stats-card"
            >
              <IonItem lines="none">
                <h1 className="ratings-card-title">Current Attendance Stats</h1>
              </IonItem>
              <StatsContainer
                stats={[
                  {
                    label: "No Call No Shows",
                    count: attendanceStats.noCallNoShows,
                  },
                  { label: "Call-Offs", count: attendanceStats.callOffs },
                  {
                    label: "Standard Cancels",
                    count: attendanceStats.standardCancels,
                  },
                  {
                    label: "Worked Shifts",
                    count: attendanceStats.workedShifts,
                  },
                ]}
              />
            </IonCard>
            <LearnMoreCard
              links={[
                {
                  label: "How Cancellations Work",
                  url: statusQuoAttendancePolicyLinks?.howAttendanceWorks,
                },
                {
                  label: `Suspensions & Deactivations`,
                  url: statusQuoAttendancePolicyLinks?.suspensionDeactivationPolicy,
                },
                {
                  label: "What are Do Not Returns (DNRs)",
                  url: statusQuoAttendancePolicyLinks?.doNotReturnPolicy,
                },
              ]}
              setErrorProps={setErrorProps}
            />
          </IonContent>
        )}
      </IonContent>
      <IonToast
        isOpen={errorProps.isOpen}
        onDidDismiss={() => setErrorProps({ ...defaultErrorProps })}
        message={errorProps.message}
        duration={errorProps.duration}
        color="danger"
        position="top"
        data-testid="ratings-error-message"
      />
    </>
  );
};

export default MarketplacePolicyRatingPage;
