import React, { useMemo } from "react";
import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { card, chevronForwardOutline } from "ionicons/icons";
import "./style.scss";
import { ILicenseDetails, LICENSE_STATUSES } from "./interfaces";
import {
  LICENSE_CARD_BACKGROUND_COLORS,
  LICENSE_CARD_BORDER_STYLES,
  LICENSE_HEADER_CONTENT_COLORS,
} from "./constants";

const License: React.FC<{ license: ILicenseDetails }> = ({ license }) => {
  const history = useHistory();

  const onLicenseClick = () => history.push(`licenses/${license._id}/details`);

  const headerIonColor = useMemo(() => {
    let color = LICENSE_HEADER_CONTENT_COLORS.DEFAULT;
    if (license.status === LICENSE_STATUSES.REJECTED) {
      color = LICENSE_HEADER_CONTENT_COLORS.REJECTED;
    }
    return color;
  }, [license.status]);

  const computedLicenseCardProps = useMemo(() => {
    const computedProps = {
      cardStyle: { background: LICENSE_CARD_BACKGROUND_COLORS.ACTIVE },
      status: license.status,
    };
    const daysUntilExpired = moment(license?.expiresAt).diff(moment(), "days");
    if (license.status !== LICENSE_STATUSES.ACTIVE) {
      computedProps.cardStyle["background"] =
        LICENSE_CARD_BACKGROUND_COLORS.INACTIVE;
    }
    if (daysUntilExpired <= 0 || license.status === LICENSE_STATUSES.EXPIRED) {
      computedProps.cardStyle["expirationTextColor"] = "danger";
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.EXPIRED;
      computedProps.cardStyle["background"] =
        LICENSE_CARD_BACKGROUND_COLORS.INACTIVE;
      computedProps.status = LICENSE_STATUSES.EXPIRED;
    } else if (daysUntilExpired <= 90) {
      // Expiring in 3 months
      computedProps.cardStyle["expirationTextColor"] = "warning";
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.EXPIRING;
      computedProps.status = LICENSE_STATUSES.EXPIRING;
    }
    if (license.status === LICENSE_STATUSES.REJECTED) {
      computedProps.cardStyle["border"] = LICENSE_CARD_BORDER_STYLES.REJECTED;
    }
    return computedProps;
  }, [license.expiresAt, license.status]);

  return (
    <IonCard
      data-testid="license-card"
      className="license-card"
      onClick={onLicenseClick}
      style={computedLicenseCardProps.cardStyle}
      key={license._id}
    >
      <IonRow className="license-card-header" no-padding>
        <IonRow class="ion-align-items-center">
          <IonIcon
            data-testid="license-card-header-left-icon"
            icon={card}
            style={{
              fontSize: "22px",
              color: headerIonColor,
              marginRight: "12px",
            }}
          />
          <IonCardTitle
            className="license-card-title"
            style={{ color: headerIonColor }}
            data-testid="license-title"
          >
            {`${license.qualification} License (${computedLicenseCardProps.status})`}
          </IonCardTitle>
        </IonRow>
        <IonIcon
          data-testid="license-card-header-right-icon"
          icon={chevronForwardOutline}
          style={{ fontSize: "24px", color: headerIonColor }}
        />
      </IonRow>

      <IonCardContent className="license-card-content">
        <IonRow className="license-card-content-detail">
          <IonText>State</IonText>
          <IonText>
            {license.state} {license.multiState && "(multi-state)"}
          </IonText>
        </IonRow>
        <IonRow className="license-card-content-detail">
          <IonText>Number</IonText>
          <IonText>{license.number || "--"}</IonText>
        </IonRow>
        <IonRow className="license-card-content-detail">
          <IonText>Expires</IonText>
          <IonText
            data-testid="license-expiration-date"
            color={computedLicenseCardProps.cardStyle["expirationTextColor"]}
          >
            {moment(license.expiresAt).format("DD MMM YYYY")}
          </IonText>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export { License };
