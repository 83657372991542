import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { subscribe, NEW_FLOW } from "@store/getters";

import { PageProps } from "./model";

const AppPage: React.FC<PageProps> = ({
  title,
  defaultHref,
  children,
  footer,
}) => {
  subscribe();
  const { pathname } = useLocation();
  const noBackButton = NEW_FLOW && pathname.includes("rating");

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={defaultHref}
              mode="ios"
              className={noBackButton ? "displayNone" : ""}
            />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{children}</IonContent>
      {footer}
    </IonPage>
  );
};

export { AppPage };
