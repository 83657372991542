import "./style.scss";
import { Agent } from "src/lib/interface";
import { dolars } from "../../utils/format";
import { get } from "lodash";
import { logEvent } from "src/lib/analytics";
import { PayrollSettingsDropdownProps } from "./models";
import { Store } from "../store/store.model";
import { ToggleChangeEventDetail } from "@ionic/core";
import { updateAgentProfileState } from "../onboardingStripe/actions";
import { usePaymentSchedule } from "./hooks";
import { USER_EVENTS } from "../../constants/userEvents";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { payout, updatePaymentSchedule } from "./api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

type IntervalOptions = "daily" | "weekly" | "instantly";
const PayrollSettingsDropdown: React.FC<PayrollSettingsDropdownProps> = ({
  balance,
}) => {
  const dispatch = useDispatch();
  const [selectedWithdrawOption, setSelectedWithdrawOption] =
    useState<IntervalOptions>("daily");
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const [automaticallySend, setAutomaticallySend] = useState(
    agent?.paymentAccountInfo?.autoWithdrawEnabled
  );
  const [loading, setLoading] = useState(false);

  const [modalSettings, setModalSettings] = useState<
    Partial<{
      isOpen: boolean;
      message: string;
      title: string;
      confirmTextButton: string;
      confirmFunction: () => void;
      cancelFunction: () => void;
    }>
  >();

  const paymentInterval = usePaymentSchedule();
  useEffect(() => {
    if (!agent?.paymentAccountInfo) return;
    if (paymentInterval !== "manual") {
      setSelectedWithdrawOption(paymentInterval);
    }
  }, [agent, paymentInterval]);
  const paymentMethod = balance?.hasDebitCard ? "card" : "bank account";

  const submitPaymentInterval = async (
    interval: "manual" | "weekly" | "daily" | "instantly"
  ) => {
    try {
      setLoading(true);
      const paymentAccountInfoUpdated = await updatePaymentSchedule({
        interval,
      });
      dispatch(
        updateAgentProfileState({
          paymentAccountInfo: paymentAccountInfoUpdated,
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const handleToggle = (e: CustomEvent<ToggleChangeEventDetail>) => {
    e.preventDefault();

    const { checked } = e.detail;
    if (!checked) {
      setModalSettings({
        title: "Turn off automatic payouts?",
        message:
          "This means you won’t receive any payouts unless you manually withdraw your funds",
        isOpen: true,
        cancelFunction: () => {
          setAutomaticallySend(!checked);
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmFunction: async () => {
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
          await submitPaymentInterval("manual");

          setAutomaticallySend(checked);
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmTextButton: "Turn it Off",
      });
    } else {
      logEvent(USER_EVENTS.AUTO_WITHDRAW_REQUESTED);
      setModalSettings({
        title: "Turn on Auto-Withdraw?",
        message: `After each transfer, we will immediately sent to your ${paymentMethod}
        and we will remove ${dolars(
          agent?.paymentAccountInfo?.withdrawalFee as number
        )} to cover processing costs.`,
        isOpen: true,
        cancelFunction: () => {
          logEvent(USER_EVENTS.AUTO_WITHDRAW_REJECTED);
          setAutomaticallySend(!checked);
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmFunction: async () => {
          logEvent(USER_EVENTS.AUTO_WITHDRAW_CONFIRMED);
          await submitPaymentInterval("instantly");
          setAutomaticallySend(checked);
          setModalSettings({ ...modalSettings, isOpen: false });
          setSelectedWithdrawOption("instantly");
          await payout();
        },
        confirmTextButton: "Turn it On",
      });
    }
    // setAutomaticallySend(e.detail.checked)
  };
  const handleSelectedOption = (option: IntervalOptions) => {
    if (option === selectedWithdrawOption) return;
    if (option === "instantly") {
      logEvent(USER_EVENTS.AUTO_WITHDRAW_REQUESTED);
      setModalSettings({
        title: "Turn on Auto-Withdraw?",
        message: `After each transfer, we will immediately sent to your ${paymentMethod}
          and we will remove ${dolars(
            agent?.paymentAccountInfo?.withdrawalFee as number
          )} to cover processing costs.`,
        isOpen: true,
        cancelFunction: () => {
          logEvent(USER_EVENTS.AUTO_WITHDRAW_REJECTED);
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmFunction: async () => {
          await submitPaymentInterval("instantly");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_CONFIRMED);

          setModalSettings({ ...modalSettings, isOpen: false });
          setSelectedWithdrawOption("instantly");
          await payout();
        },
        confirmTextButton: "Turn it On",
      });
    } else if (option === "daily") {
      setModalSettings({
        title: "Turn on Daily Payouts?",
        message: `If there’s balance in your account at the end of the day,
          Stripe will send funds for free to your ${paymentMethod} -- it will take 2-3 business days.`,
        isOpen: true,
        cancelFunction: () => {
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmFunction: async () => {
          await submitPaymentInterval("daily");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
          setModalSettings({ ...modalSettings, isOpen: false });
          setSelectedWithdrawOption("daily");
        },
        confirmTextButton: "Turn it On",
      });
    } else if (option === "weekly") {
      setModalSettings({
        title: "Turn on Weekly Payouts?",
        message: `Once a week on Thursday, Stripe will check your account
          to see if there’s a balance in your account. If there is,
          funds will reach your ${paymentMethod} for free on the following Monday`,
        isOpen: true,
        cancelFunction: () => {
          setModalSettings({ ...modalSettings, isOpen: false });
        },
        confirmFunction: async () => {
          await submitPaymentInterval("weekly");
          logEvent(USER_EVENTS.AUTO_WITHDRAW_DISABLED);
          setModalSettings({ ...modalSettings, isOpen: false });
          setSelectedWithdrawOption("weekly");
        },
        confirmTextButton: "Turn it On",
      });
    }
  };
  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            offset="0"
            size={balance?.hasDebitCard ? "8" : "12"}
            style={{ textAlign: "center" }}
          >
            Stripe sends money to my account automatically
          </IonCol>
          {balance?.hasDebitCard && (
            <IonCol size="4">
              <IonToggle
                disabled={loading}
                color="primary"
                checked={automaticallySend}
                onIonChange={handleToggle}
              />
            </IonCol>
          )}
        </IonRow>
        <hr style={{ height: 1, marginBottom: 5 }}></hr>
        {automaticallySend && (
          <IonRow style={{ padding: 0, margin: "10px 0px" }}>
            {balance?.hasDebitCard && (
              <IonCol
                size={"4"}
                style={{
                  padding: 0,
                  margin: "0px 0px",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor:
                    "instantly" === selectedWithdrawOption
                      ? "#1c69d1"
                      : "#E0E0E0",
                  borderRadius: "4px 0px 0px 4px",
                  borderRight: "0px",
                }}
              >
                <IonButton
                  fill={
                    "instantly" === selectedWithdrawOption
                      ? "default"
                      : "default"
                  }
                  onClick={() => handleSelectedOption("instantly")}
                  disabled={false}
                  expand="block"
                  style={{
                    minHeight: "34px",
                    padding: "0px 1px",
                    margin: "-1px -1px",
                    fontSize: 11,
                    color:
                      "instantly" === selectedWithdrawOption
                        ? "#fff"
                        : "#1c69d1",
                    backgroundColor:
                      "instantly" === selectedWithdrawOption
                        ? "#1c69d1"
                        : "#fff",
                  }}
                >
                  Instantly withdraw
                </IonButton>
              </IonCol>
            )}

            <IonCol
              size={balance?.hasDebitCard ? "4" : "6"}
              style={{
                padding: 0,
                margin: "0px 0px",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor:
                  "daily" === selectedWithdrawOption ? "#1c69d1" : "#E0E0E0",
                borderRadius: balance?.hasDebitCard
                  ? undefined
                  : "4px 0px 0px 4px",
                borderRight: "0px",
              }}
            >
              <IonButton
                fill={
                  "daily" === selectedWithdrawOption ? "default" : "default"
                }
                onClick={() => handleSelectedOption("daily")}
                disabled={false}
                expand="block"
                style={{
                  minHeight: "34px",
                  padding: "0px 1px",
                  margin: "-1px -1px",
                  fontSize: 11,
                  color:
                    "daily" === selectedWithdrawOption ? "#fff" : "#1c69d1",
                  backgroundColor:
                    "daily" === selectedWithdrawOption ? "#1c69d1" : "#fff",
                }}
              >
                At the end of the day
              </IonButton>
            </IonCol>

            <IonCol
              size={balance?.hasDebitCard ? "4" : "6"}
              style={{
                padding: 0,
                margin: "0px 0px",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor:
                  "weekly" === selectedWithdrawOption ? "#1c69d1" : "#E0E0E0",
                borderRadius: "0px 4px 4px 0px",
              }}
            >
              <IonButton
                fill={
                  "weekly" === selectedWithdrawOption ? "default" : "default"
                }
                onClick={() => handleSelectedOption("weekly")}
                disabled={false}
                expand="block"
                style={{
                  minHeight: "34px",
                  padding: "0px 1px",
                  margin: "-1px -1px",
                  fontSize: 11,
                  color:
                    "weekly" === selectedWithdrawOption ? "#fff" : "#1c69d1",
                  backgroundColor:
                    "weekly" === selectedWithdrawOption ? "#1c69d1" : "#fff",
                }}
              >
                Every Thursday
              </IonButton>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      <IonAlert
        isOpen={modalSettings?.isOpen as boolean}
        onDidDismiss={() =>
          setModalSettings({ ...modalSettings, isOpen: false })
        }
        header={modalSettings?.title}
        message={modalSettings?.message}
        backdropDismiss={true}
        buttons={[
          {
            text: "Never Mind",
            role: "cancel",
            handler: modalSettings?.cancelFunction,
          },
          {
            text: modalSettings?.confirmTextButton as string,
            handler: modalSettings?.confirmFunction,
          },
        ]}
      />
    </>
  );
};

export { PayrollSettingsDropdown };
