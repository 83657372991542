import "../styles.scss";
import { Agent } from "src/lib/interface";
import { get } from "lodash";
import { logEvent } from "src/lib/analytics";
import { Store } from "@store/store.model";
import { TabRouterPath } from "../../routing/constant/tabRoute";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

const OnboardingSucess: React.FC = () => {
  const agent: Partial<Agent> =
    useSelector((state: Store) => get(state, "session.agent", {})) || {};
  const history = useHistory();

  const handleFinishingOnboard = useCallback(() => {
    history.replace(TabRouterPath.OPEN_SHIFTS);
  }, [history]);

  return (
    <IonPage>
      <IonContent>
        <IonHeader no-border>
          <IonToolbar>
            <IonTitle size="small" className="header">
              Working with Clipboard Health
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <IonText
            color="title"
            class="secondary-heading ion-margin-bottom"
            style={{ fontSize: "16px" }}
          >
            Fast Payments With Clipboard Health
          </IonText>
          <img
            style={{ width: "45%", maxWidth: "300px", margin: "0.5rem 2.5rem" }}
            src="assets/logo/bunny.png"
            alt="Clipboard logo"
          />
          <p className="space" style={{ fontSize: "13px" }}>
            Woohoo! Now that we sent Stripe the info they need, it’ll take a few
            days to get your account ready to receive funds.
          </p>
          <p className="space" style={{ fontSize: "13px" }}>
            {!agent?.isFirstSession &&
              `${"Until then, you’ll continue to be paid using your existing method."} `}
            <IonText color="primary" style={{ fontWeight: "bold" }}>
              We’ll send you a notification once you’re paid through Stripe.
              You’ll be able to view your balances and withdraw funds from the
              Payroll page in the Account tab
            </IonText>
            .
          </p>
          <div style={{ marginBottom: "20px" }}>
            <IonButton
              fill="outline"
              className="footer"
              onClick={handleFinishingOnboard}
            >
              Find Open Shifts
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { OnboardingSucess };
