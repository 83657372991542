import { FileExplorer } from "@app/components/uploadFile/fileExplorer";
import { SelectedFile } from "@app/shiftSignature/timecard/model";
import { isImage } from "@app/utils/isImage";
import {
  IonButton,
  IonContent,
  IonImg,
  IonItemGroup,
  IonPopover,
  IonSpinner,
  IonText,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Shift } from "src/lib/interface";
import {
  enterAnimation,
  leaveAnimation,
} from "../../rating/reviewPrompt/popoverAnimation";
import "./style.scss";

const TimeSheetSubmission: React.VFC<{
  shift: Shift;
  OnSubmit: (file: SelectedFile | null) => void;
  isShiftTimeEdit: boolean;
  showFileExplorer: boolean;
}> = ({ shift, OnSubmit, isShiftTimeEdit, showFileExplorer }) => {
  const [showUploadTimesheetModal, setShowUploadTimesheetModal] =
    useState<boolean>(false);
  const [canShowUploadButton, setCanShowUploadButton] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onUpload = (SelectedFile) => {
    if (isImage(SelectedFile.type)) {
      setSelectedFile(SelectedFile);
      setShowUploadTimesheetModal(false);
    } else {
      setUploadErrorMessage("File format not supported");
    }
  };

  useEffect(() => {
    showFileExplorer && setShowUploadTimesheetModal(true);
  }, []);

  return (
    <IonContent>
      <div className="timesheet-submission">
        <IonToast
          isOpen={!!uploadErrorMessage}
          message={uploadErrorMessage}
          onDidDismiss={() => {
            setUploadErrorMessage("");
          }}
          color="danger"
          position="top"
          duration={5000}
        />
        <div>
          <div className="timesheet-summary-text">
            Confirm your times match your timesheet
          </div>
        </div>
        {selectedFile ? (
          <IonImg
            className="timesheet-image"
            src={selectedFile["file"]}
            style={{ maxHeight: window.innerHeight - 115 }}
          />
        ) : (
          <div className="empty-timesheet-image"></div>
        )}
        {showUploadTimesheetModal && shift && (
          <IonPopover
            isOpen
            showBackdrop
            backdropDismiss={false}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
            cssClass="upload-prompt"
          >
            <div id="overlay-section" className="timesheet-upload">
              <IonItemGroup className="upload-time-group">
                <FileExplorer onFileSelected={onUpload} />
                <div className="ion-text-center ion-margin file-upload-cancel-btn">
                  <IonText onClick={() => setShowUploadTimesheetModal(false)}>
                    Cancel
                  </IonText>
                </div>
              </IonItemGroup>
            </div>
          </IonPopover>
        )}
        <div className="timesheet-submit-buttons">
          <IonButton
            color="primary"
            shape="round"
            className="timesheet-upload-button"
            onClick={() => setShowUploadTimesheetModal(true)}
          >
            {selectedFile != null ? "Retake Picture" : "Take Picture"}
          </IonButton>
          <IonButton
            color="primary"
            shape="round"
            className="timesheet-submit-button"
            onClick={() => {
              setDisableSubmit(true);
              OnSubmit(selectedFile);
            }}
            disabled={selectedFile == null || isShiftTimeEdit || disableSubmit}
          >
            <IonSpinner hidden={!disableSubmit} slot="start" name="crescent" />
            {disableSubmit ? "Submitting" : "Submit"}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
};

export { TimeSheetSubmission };
