import { TextareaChangeEventDetail } from "@ionic/core";
import { IonItem, IonTextarea } from "@ionic/react";
import React, { Fragment } from "react";

import { ReviewProps } from "./model";
import { RatingCard } from "./ratingCard";

const Review: React.FC<ReviewProps> = ({ rating, review, setReview }) => {
  const onInputChange = (event: CustomEvent<TextareaChangeEventDetail>) =>
    setReview(event.detail.value);

  if (!rating) {
    return <Fragment />;
  }

  return (
    <RatingCard
      facilityName=""
      title="Is there anything else you would like to tell us?"
    >
      <IonItem>
        <IonTextarea
          placeholder="Tell us more..."
          onIonChange={onInputChange}
          value={review}
        />
      </IonItem>
    </RatingCard>
  );
};

export { Review };
