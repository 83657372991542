import { IonItem } from "@ionic/react";
import React, { Fragment } from "react";

import { InfoTitleItem } from "../infoTitleItem";
import { AuthorizedSignatoryProps } from "./model";
import { SignatorySelect } from "./signatorySelect";

const AuthorizedSignatoryContainer: React.FC<AuthorizedSignatoryProps> = (
  props
) => {
  return (
    <Fragment>
      <InfoTitleItem>
        Type or find your charge nurse below to request their signature.
      </InfoTitleItem>
      <IonItem class="has-select" lines="inset">
        <SignatorySelect {...props} />
      </IonItem>
    </Fragment>
  );
};

export { AuthorizedSignatoryContainer };
