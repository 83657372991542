import request from "superagent";
import { getAuthHeader } from "../superagent";
import { environment } from "../../environments/environment";
import { logApiFailureEvent } from "src/lib/analytics";
import { AddressBody, AddressResponse, Agent } from "src/lib/interface";

const updateAddress = async (
  address: AddressBody
): Promise<AddressResponse | undefined> => {
  try {
    const { body } = await request
      .post(`${environment.baseUrl}/agentprofile/address`)
      .set(await getAuthHeader())
      .send(address);
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};
const updateDistancePreference = async (distance: number): Promise<Agent> => {
  return await request
    .post(`${environment.baseUrl}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: "distance", value: distance })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { updateAddress, updateDistancePreference };
