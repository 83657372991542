import { Link } from "react-router-dom";
import { arrowBack, arrowForward } from "ionicons/icons";
import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";

import { ArrowButton } from "src/lib/ionic-components";
import Button from "antd/es/button";
import moment from "moment-timezone";
import React, { useEffect } from "react";

import { logEvent } from "src/lib/analytics";
import { RouterPath } from "../routing/constant";
import { USER_EVENTS } from "../../constants/userEvents";

interface ShowApiFailErrorProps {
  onRefresh?: (date?: moment.Moment) => void;
  date?: moment.Moment;
  networkStatus?: boolean;
}
const ShowAPIFailError: React.FC<ShowApiFailErrorProps> = (props) => {
  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_CONNECTION_ISSUE);
  }, []);

  const handleClickRefresh = () => {
    logEvent(USER_EVENTS.TAPPED_REFRESH);
    props.onRefresh?.();
  };

  return (
    <>
      {!props.networkStatus ? (
        <></>
      ) : (
        <IonGrid class="ion-text-center">
          <IonRow justify-content-center align-items-center>
            <IonCol size="3">
              <ArrowButton icon={arrowBack} disabled />
            </IonCol>
            <IonCol
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              size="6"
            >
              <IonLabel
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  // @ts-ignore
                }}
              >
                {props?.date?.format("MMMM YYYY")}
              </IonLabel>
            </IonCol>
            <IonCol size="3">
              <ArrowButton icon={arrowForward} disabled />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      <IonGrid class="ion-text-center">
        <IonRow>
          <IonCol style={{ marginTop: `25%` }} size="12">
            <IonItem lines="none">
              <img
                style={{ margin: "0 auto" }}
                className="cbh-logo"
                src="assets/images/sad_bunny.png"
                alt="Clipboard logo"
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            style={{ margin: `0`, padding: `0`, marginTop: `25px` }}
            size="12"
          >
            <IonLabel style={{ fontSize: "20px" }}>
              We're sorry - it's not you. It's us.
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            style={{ margin: `0`, padding: `0`, color: `#595959` }}
            size="12"
          >
            <IonLabel style={{ fontSize: "12px" }}>
              {props.networkStatus
                ? "There seems to be an internet issue with your device."
                : "We require an Internet Connection for this page. Please check your connection and try again."}
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            style={{ padding: `0`, margin: `20px 0px 20px 0px` }}
            size="12"
          >
            <Button type={"primary"} onClick={handleClickRefresh}>
              Refresh to try again
            </Button>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ margin: `0`, padding: `0` }} size="12">
            <IonLabel
              style={{
                maxWidth: "250px",
                fontSize: "12px",
                display: "inline-block",
                color: `#595959`,
              }}
            >
              If you're still having trouble,{" "}
              <Link to={RouterPath.SUPPORT}>click here</Link> to contact our
              support team.
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export { ShowAPIFailError };
