import { PayRateText } from "src/lib/ionic-components";
import {
  FULL_ZONE_NAMES,
  getHumanReadableTime,
  getOffsetFormattedTextBetweenTimezones,
  timeRangeFormatter,
} from "src/lib/utils";
import { Facility, Agent } from "src/lib/interface";
import {
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  homeOutline,
  informationCircleOutline,
  stopwatchOutline,
  locationOutline,
} from "ionicons/icons";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { geoDistance } from "src/lib/utils";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import BlueCheckSvg from "../../../assets/icons/blue-check.svg";
import { TIME_FORMAT } from "../constants";
import { ShiftItemViewProps } from "../model";
import "../style.scss";
import { ShiftActionButtonRow } from "./ShiftItemActionButtonRow";
import { ShiftInlineAlerts } from "./ShiftItemInlineAlerts";
import { formatRate } from "../../hcpShifts/helper";
import { useMissingDocuments } from "../custom-hooks/useMissingDocuments";
import { ExtraTimePayAlert } from "../Alerts/ExtraTimePayAlert";
import { UrgencyType } from "@app/openShifts/urgentShifts/constants";
import { Store } from "../../store/store.model";
import { useAppSelector } from "@src/app/store";
import { getNonIPAmount } from "@src/app/utils/shiftHelper";

const ShiftItemView = ({
  shift,
  payVersion,
  onClickOnFacilityDetails,
  isInstantPayAllowed,
  interested,
  workApproved,
  category,
  loadAlertDialog,
  isLastMinuteShift,
  submitting,
  showFacilityDetails,
  onItemClick,
  isUrgentShift,
  checkingDocs,
}: ShiftItemViewProps) => {
  /**
   * Gets shift rates label based on `payVersion`
   */
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };
  const ldClient = useLDClient();
  const agent: Partial<Agent> = useAppSelector(
    (state: Store) => state?.session?.agent ?? {}
  );
  const shiftPayRate = useMemo(() => {
    const originalAmount = getNonIPAmount(shift, ldClient!);
    if (isUrgentShift) {
      return (
        <>
          <strong>${formatRate(originalAmount)} total earnings</strong>
        </>
      );
    }

    if (payVersion === "b") {
      return (
        <>
          ~<strong>${formatRate(shift.finalPay || 0)}</strong> per hour
        </>
      );
    }

    if (payVersion === "a") {
      return (
        <>
          <strong>${formatRate(originalAmount)}</strong> for this shift
        </>
      );
    }
    return null;
  }, [shift, payVersion, ldClient]);

  const { pending, missingDocs } = useMissingDocuments(shift);
  const hasMissingDocs = missingDocs && missingDocs?.size > 0;

  const shiftFacilityDistanceText = useMemo(() => {
    const facilityCoordinates: number[] = get(
      shift,
      "facility.geoLocation.coordinates",
      []
    );
    const agentCoordinates: number[] = get(
      agent,
      "geoLocation.coordinates",
      []
    );
    let isApproxDistance: boolean;
    let distance: number;
    if (shift.distance) {
      distance = shift.distance;
      isApproxDistance = shift.isApproxDistance ?? false;
    } else if (shift.facility?.distance) {
      distance = shift.facility?.distance;
      isApproxDistance = shift.facility?.isApproxDistance ?? false;
    } else {
      distance = geoDistance(agentCoordinates, facilityCoordinates);
      isApproxDistance = true;
    }
    if (isApproxDistance) {
      distance = Math.round(2 * distance) / 2;
    }

    const distFrom = isUrgentShift ? "you" : "home";
    const isApproxDistanceText = isApproxDistance ? "~" : "";
    if (!distance) return "";
    if (distance < 1)
      return `${isApproxDistanceText}${distance.toFixed(
        1
      )} miles from ${distFrom}`;
    if (distance === 1)
      return `${isApproxDistanceText}${distance} mile from ${distFrom}`;
    return `${isApproxDistanceText}${distance.toFixed(
      1
    )} miles from ${distFrom}`;
  }, [shift, agent?.geoLocation]);

  const {
    tbioOptions,
    isETPFlagEnabled,
    tmz: facilityTimezone,
  } = shift.facility as Facility;
  const { tmz: agentTimezone } = agent;
  const [showExtraTimePayAlert, setShowExtraTimePayAlert] = useState(false);

  const extraTimePayEnabled =
    isETPFlagEnabled &&
    tbioOptions?.enabled &&
    ldClient?.variation("display-extra-time-pay", false);
  const showRestrictedETP =
    !isETPFlagEnabled &&
    tbioOptions?.enabled &&
    (tbioOptions?.threshold as number) < (shift.time as number);

  const getShiftTimeInformation = (
    start: Date | string | undefined,
    end: Date | string | undefined,
    urgentShift: boolean | undefined,
    lastMinuteShift: boolean | undefined
  ) => {
    if (urgentShift) {
      if (shift.urgency === UrgencyType.COMMUTE_MODULE) {
        return `Now until ${moment(end)
          .tz(facilityTimezone)
          .format("MMM DD, hh:mm a")}`;
      }

      if (shift.urgency === UrgencyType.LATE_CANCELLATION) {
        // Fri Feb 04, 11:00 pm - 7:00 am
        return `${moment(start)
          .tz(facilityTimezone)
          .format("ddd MMM DD, hh:mm a")} - ${moment(end)
          .tz(facilityTimezone)
          .format("hh:mm a")}`;
      }
    }

    let text = `${timeRangeFormatter(
      TIME_FORMAT,
      {
        start: shift.start as string,
        end: shift.end as string,
      },
      facilityTimezone
    )} (${getHumanReadableTime(shift.time as number)})`;

    if (lastMinuteShift) {
      text = `${moment(shift.start)
        .tz(facilityTimezone)
        .format("ddd MMM DD")} ${text}`;
    }
    return text;
  };

  return (
    <>
      <IonGrid onClick={onItemClick}>
        {showFacilityDetails && showRestrictedETP && (
          <IonRow className="ion-label">
            <IonCol size="12">
              <IonLabel
                style={{ color: "limegreen" }}
                className={`ion-text-wrap ${
                  isUrgentShift ? "urgent-shift-time" : ""
                }`}
              >
                This shift qualifies for a {tbioOptions?.multiplier}x bonus for
                hours worked over {tbioOptions?.threshold}
              </IonLabel>
            </IonCol>
          </IonRow>
        )}
        <IonRow className="shift-ion-time-range-text ion-label">
          <IonCol size="12">
            <IonLabel className="ion-text-wrap">
              {getShiftTimeInformation(
                shift.start,
                shift.end,
                isUrgentShift,
                isLastMinuteShift
              )}
            </IonLabel>
            {agentTimezone &&
              facilityTimezone &&
              moment(shift.start).tz(agentTimezone).utcOffset() !==
                moment(shift.start).tz(facilityTimezone).utcOffset() && (
                <IonLabel
                  className="ion-text-wrap"
                  style={{
                    background: "#D88C33",
                    borderRadius: "4px",
                    width: "fit-content",
                    padding: "1px 4px",
                    color: "#FFFFFF",
                    fontWeight: 600,
                  }}
                >
                  {`${moment()
                    .tz(facilityTimezone)
                    .zoneName()} (${getOffsetFormattedTextBetweenTimezones(
                    shift.start as string,
                    agentTimezone as string,
                    facilityTimezone as string
                  )})`}
                </IonLabel>
              )}
          </IonCol>
        </IonRow>
        {shiftPayRate && (
          <IonRow>
            <IonCol size="12">
              <PayRateText
                totalPay={shiftPayRate}
                instantPay={
                  isInstantPayAllowed ? <strong>InstantPay</strong> : undefined
                }
              />
            </IonCol>
          </IonRow>
        )}
        {showFacilityDetails && (
          <>
            {shift.isHolidayShift && (
              <IonChip color="dark" className="small-chip">
                <IonLabel>It's {shift.holidayName}</IonLabel>
              </IonChip>
            )}
            <IonRow onClick={() => onClickOnFacilityDetails()}>
              <IonCol size="1" className="shift-item__info-icon">
                <IonIcon icon={informationCircleOutline} />
              </IonCol>
              <IonCol size="11" className="shift-item__description">
                <IonText>
                  <h3>{shift?.facility?.name}</h3>
                </IonText>
                <IonText>
                  <h6>{shift?.facility?.fullAddress?.formatted}</h6>
                </IonText>
                <IonRow className="etp-dist-wrapper ion-align-items-center">
                  {shiftFacilityDistanceText && (
                    <IonText>
                      <p className="distance-text">
                        <IonIcon
                          className="icon distance-icon"
                          icon={isUrgentShift ? locationOutline : homeOutline}
                          color={isUrgentShift ? "#828282" : undefined}
                        />
                        {shiftFacilityDistanceText}
                      </p>
                    </IonText>
                  )}
                  {extraTimePayEnabled && (
                    <IonText
                      onClick={(e) => {
                        setShowExtraTimePayAlert(true);
                        e.stopPropagation();
                      }}
                    >
                      <p className="extra-time-pay-link">
                        <IonIcon size="small" icon={stopwatchOutline} /> Extra
                        Time Pay Facility
                      </p>
                    </IonText>
                  )}
                </IonRow>
              </IonCol>
            </IonRow>
          </>
        )}
        {!workApproved && interested && (
          <IonRow>
            <IonCol
              size="12"
              text-center
              className="shift-item__text-waiting-confirmation"
            >
              Shift Request Sent, Waiting for Confirmation
            </IonCol>
          </IonRow>
        )}
        {showFacilityDetails &&
          (workApproved ? (
            <IonRow>
              <IonCol size="12" className="shift-item__work-confirmed">
                <IonLabel className="ion-label">
                  <img src={BlueCheckSvg} alt="work confirmed" />
                  You're Working This Shift!
                </IonLabel>
              </IonCol>
            </IonRow>
          ) : (
            <ShiftInlineAlerts
              shift={shift}
              interested={interested}
              category={category}
            />
          ))}
        {!workApproved && (
          <ShiftActionButtonRow
            shift={shift}
            submitting={submitting}
            onClick={loadAlertDialog}
            category={category}
            isInterested={interested}
            missingDocs={hasMissingDocs || pending}
            checkingDocs={checkingDocs}
          />
        )}
      </IonGrid>
      <ExtraTimePayAlert
        isOpen={showExtraTimePayAlert}
        onDidDismiss={() => setShowExtraTimePayAlert(false)}
        onConfirm={() => onClickOnFacilityDetails(true)}
        shift={shift}
      />
    </>
  );
};

export { ShiftItemView };
