import styled from "@emotion/styled";
import { IonItem, IonRouterLink } from "@ionic/react";
import React from "react";
import { ReferralRate } from "../api";
import "./style.scss";
const Card = styled.div``;
const BulletItem = styled.div``;
const Row = styled.div``;
const stepItems = (referralRate: string): Array<string> => [
  "Send your code to a friend",
  "They work a shift",
  `You each get up to $${referralRate}!`,
];
const Item = ({ title, number }) => {
  return (
    <Row className="row">
      <BulletItem className="bullet-item">{number}</BulletItem>
      <p
        data-testid={`step-item-${number}`}
        style={{ marginLeft: 12, fontSize: 18 }}
      >
        {title}
      </p>
    </Row>
  );
};
const HowItWorksCard = ({ referralRate }: ReferralRate) => {
  return (
    <Card className="how-it-works-card">
      <h1>How it works</h1>
      {stepItems(referralRate).map((step, index) => (
        <Item title={step} number={index + 1} key={step}></Item>
      ))}
      <IonItem lines="none" style={{ alignSelf: "center", color: "#1C69D1" }}>
        <IonRouterLink
          href="https://www.clipboardhealth.com/understanding-referrals"
          target="_blank"
        >
          Earnings vary. Learn more here.
        </IonRouterLink>
      </IonItem>
    </Card>
  );
};

export { HowItWorksCard };
