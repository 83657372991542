import React, { useRef } from "react";
import { IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { template, isEmpty } from "lodash";

import {
  AlwaysAllowLocationPrimerModalProps,
  AlwaysAllowPrimerModalLabels,
} from "./model";
import getReactComponents from "./getReactComponents";
import { getLabelsFromPageConfig } from "./utils";

import "./style.scss";

const defaultAlwaysAllowPrimerPageLabels = {} as AlwaysAllowPrimerModalLabels;

const AlwaysAllowLocationPrimerModal: React.FC<
  AlwaysAllowLocationPrimerModalProps
> = ({ isOpen, onGoToLocationSettingClicked, onDeny }) => {
  if (!isOpen) return null;

  const ldClient = useLDClient();

  const urgentShiftsConfig = ldClient?.variation("urgent-shifts-config");

  const pageConfig = (urgentShiftsConfig?.alwaysAllowPrimerPage ||
    {}) as AlwaysAllowPrimerModalLabels;

  const labels = getLabelsFromPageConfig(
    pageConfig
  ) as AlwaysAllowPrimerModalLabels;

  const templateVars = {
    ...labels,
  };

  const getLabel = (key: string): string =>
    key in labels
      ? getReactComponents(key, labels[key], templateVars)
      : defaultAlwaysAllowPrimerPageLabels[key];

  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      cssClass="always-allow-primer-modal"
      mode="ios"
      data-testid="always-allow-primer-modal"
    >
      <IonContent>
        <div className="modal-body primer-modal">
          <div className="bunny-icon">
            <img src="assets/logo/bunny.png" alt="Clipboard logo" />
          </div>
          {labels.header && (
            <span className="modal-title">{getLabel("header")}</span>
          )}
          {labels.reason && (
            <IonText className="message">{getLabel("reason")}</IonText>
          )}
          {labels.explanation && (
            <IonText className="message">{getLabel("explanation")}</IonText>
          )}
          {labels.link && (
            <IonText className="message">{getLabel("link")}</IonText>
          )}

          <div className="action-container">
            <IonButton
              mode="ios"
              color="primary"
              className="allow-location-btn"
              onClick={() => onGoToLocationSettingClicked?.()}
            >
              {getLabel("allowButtonLabel")}
            </IonButton>
            <IonButton
              mode="ios"
              fill="clear"
              onClick={() => onDeny?.()}
              className="deny-button"
            >
              {getLabel("denyButtonLabel")}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AlwaysAllowLocationPrimerModal;
