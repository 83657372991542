import { camera, cloudUpload, document, image } from "ionicons/icons";
import {
  IonButton,
  IonFooter,
  IonIcon,
  IonSpinner,
  IonActionSheet,
  isPlatform,
} from "@ionic/react";
import React, { Fragment, useState } from "react";
import { CameraSource } from "@capacitor/camera";

import { DocumentViewFooterProps } from "./model";
import { logEvent } from "src/lib/analytics";
import { USER_EVENTS } from "@constants/userEvent";

const DocumentViewFooter: React.FC<DocumentViewFooterProps> = ({
  allowMultipleUpload,
  selectedFiles = [],
  uploading,
  handleFileUpload,
  handleTakePhotoClick,
  handleSelectDocumentClick,
  disableUpload,
}) => {
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const haveSelectedFiles = selectedFiles.length > 0;
  const buttons = [
    {
      text: "Take a photo",
      icon: camera,
      handler: () => {
        handleTakePhotoClick(CameraSource.Camera);
      },
    },
    {
      text: "Select a photo from camera roll",
      icon: image,
      handler: () => {
        handleTakePhotoClick(CameraSource.Photos);
      },
    },
  ];

  if (!haveSelectedFiles && !uploading) {
    buttons.push({
      text: "Upload from file manager",
      icon: document,
      handler: handleSelectDocumentClick,
    });
  }

  return (
    <IonFooter no-border>
      {!haveSelectedFiles && !uploading && (
        <div className="ion-text-center ion-margin">
          <IonButton onClick={() => setShowActionSheet(true)}>
            <IonIcon slot="start" icon={document} mode="ios" />
            Upload Document
          </IonButton>
        </div>
      )}
      <IonActionSheet
        isOpen={showActionSheet && !uploading}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={buttons}
      />
      {haveSelectedFiles && uploading && (
        <IonButton
          expand="block"
          color="primary"
          disabled={uploading || disableUpload}
        >
          <Fragment>
            <IonSpinner slot="start" name="crescent" />
            Uploading
          </Fragment>
        </IonButton>
      )}

      {haveSelectedFiles && !uploading && !allowMultipleUpload && (
        <div className="ion-text-center ion-margin">
          <IonButton onClick={handleFileUpload}>
            <IonIcon slot="start" icon={cloudUpload} mode="ios" />
            Finish and upload
          </IonButton>
        </div>
      )}

      {haveSelectedFiles && !uploading && allowMultipleUpload && (
        <div className="ion-text-center ion-margin">
          <IonButton onClick={() => setShowActionSheet(true)}>
            <IonIcon slot="start" icon={camera} mode="ios" />
            Add more pages
          </IonButton>

          <IonButton onClick={handleFileUpload}>
            <IonIcon slot="start" icon={cloudUpload} mode="ios" />
            Finish and upload
          </IonButton>
        </div>
      )}
    </IonFooter>
  );
};

export { DocumentViewFooter };
