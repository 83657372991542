import React, { Fragment } from "react";
import { IonCard } from "@ionic/react";
import { FilePreviewProps } from "./model";

const FilePreviewList: React.FC<FilePreviewProps> = ({ fileList }) => {
  return (
    <Fragment>
      {fileList &&
        fileList.map((file) => (
          <IonCard key={file.url}>
            <img src={file.url} alt="timecard" />
          </IonCard>
        ))}
    </Fragment>
  );
};

export { FilePreviewList };
