import { IonItem, IonLabel } from "@ionic/react";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../../store/store.model";
import { StarRating } from "./starRating";

type PropTypes = {
  review?: string;
  createdAt: string;
  rating: number;
  agentId: string;
  reasons?: string[];
};

export const FacilityReviewsItem = ({
  createdAt,
  rating,
  review,
  agentId,
  reasons = [],
}: PropTypes): JSX.Element => {
  const readableDate = moment(createdAt).format("MMM Y");
  const userId = useSelector((state: Store) =>
    get(state, "session.agent.userId")
  );
  const isSelfPostedReview = userId === agentId;
  const filters = ["Other Reason", "Other"];
  const filteredReasons = reasons.filter((reason) => !filters.includes(reason));

  return (
    <IonItem className="ion-no-padding ion-text-wrap">
      <IonLabel
        className="ion-text-wrap"
        style={{
          padding: "0 8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <StarRating
            value={rating}
            size={16}
            color={isSelfPostedReview ? "#1890ff" : "#032E68"}
          />
          <span
            style={{
              color: isSelfPostedReview ? "#1890ff" : "#032E68",
              fontSize: 14,
            }}
          >
            {readableDate}
          </span>
        </div>
        {filteredReasons.length > 0 &&
          filteredReasons.map((reason) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ color: "#828282" }}>{reason}</p>
            </div>
          ))}
        {review && <p style={{ color: "#828282" }}>{review}</p>}
      </IonLabel>
    </IonItem>
  );
};
