import "./style.scss";
import React, { Fragment, useState } from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
} from "@ionic/react";
import { forOwn, has } from "lodash";
import { closeCircle } from "ionicons/icons";
import moment from "moment-timezone";
import CheckedSvg from "../../../assets/images/facilityDetails/checked-status.svg";

enum COVID19WeeklySupply {
  EYE_PROTECTION = "hasEyeProtection",
  GOWNS = "hasGowns",
  N95_MASKS = "hasN95Masks",
  SANITIZER = "hasSanitizer",
  SURGICAL_MASKS = "hasSurgicalMasks",
}

enum ConfirmedCovidCases {
  RESIDENT_LAST_MONTH = "confirmedResidentLastMonth",
  RESIDENT_LAST_WEEK = "confirmedResidentLastWeek",
  STAFF_LAST_MONTH = "confirmedStaffLastMonth",
  STAFF_LAST_WEEK = "confirmedStaffLastWeek",
}

const CovidStatusCard = ({ facility, shift }) => {
  const date = moment().format("YYYY-MM-DD");
  const covidWeeklySupply: { [key: string]: string }[] = [];
  const confirmedCovid19Cases: { [key: string]: string }[] = [];
  const covidSupply = Object.values<string>(COVID19WeeklySupply);
  const covidCases = Object.values<string>(ConfirmedCovidCases);
  const { covidData } = facility;

  forOwn(covidData, (value, key) => {
    if (covidSupply.includes(key)) covidWeeklySupply.push({ [key]: value });
    if (covidCases.includes(key)) confirmedCovid19Cases.push({ [key]: value });
  });

  return (
    <Fragment>
      <IonItem lines="none" class="covid-item-background">
        <span className="covid-input-title">
          {" "}
          {`As of ${moment(date).format("MMM DD, YYYY")} `}{" "}
        </span>
      </IonItem>
      <IonItem lines="none" class="covid-item-background">
        <img alt="covid-19-status" src={CheckedSvg} />
        <span className="covid-input-title"> Has a COVID-19 unit </span>
      </IonItem>
      <IonList class="ion-no-padding covid-list-background">
        <IonItem lines="none" class="covid-item-background">
          <span className="covid-input-number"> {covidData.occupiedBeds} </span>
          <span className="covid-input-title"> Total beds </span>
        </IonItem>
        <IonItem lines="none" class="covid-item-background">
          <span className="covid-input-number"> {covidData.totalBeds} </span>
          <span className="covid-input-title"> Occupied beds </span>
        </IonItem>
      </IonList>

      {/* covid testing requirements section */}

      <IonList className="ion-no-padding covid-list-background">
        {covidData && covidData.testingAvailabilityOnsite && (
          <IonItem lines="none" class="covid-item-background">
            <img alt="covid-19-status" src={CheckedSvg} />
            <span className="covid-input-title">On-Site-Testing </span>
          </IonItem>
        )}
        {covidData && covidData.testingAvailabilityOnsite && (
          <span className="covid-input-text">
            {" "}
            {covidData.testingAvailabilityOnsite}{" "}
          </span>
        )}
      </IonList>

      {/* covid cofirmed cases section */}

      {confirmedCovid19Cases.length > 0 && (
        <IonListHeader className="ion-margin-top">
          <IonLabel color="text" className="ion-no-margin covid-list-header">
            {" "}
            Confirmed COVID-19 Cases:
          </IonLabel>
        </IonListHeader>
      )}

      <IonList className="ion-no-padding covid-list-background">
        {has(covidData, ConfirmedCovidCases.RESIDENT_LAST_WEEK) && (
          <IonItem lines="none" className="covid-item-background">
            <span className="covid-input-number">
              {" "}
              {covidData.confirmedResidentLastWeek}{" "}
            </span>
            <span className="covid-input-title">
              {" "}
              <b>Resident</b> cases last week{" "}
            </span>
          </IonItem>
        )}

        {has(covidData, ConfirmedCovidCases.RESIDENT_LAST_MONTH) && (
          <IonItem lines="none" className="covid-item-background">
            <span className="covid-input-number">
              {" "}
              {covidData.confirmedResidentLastMonth}{" "}
            </span>
            <span className="covid-input-title">
              {" "}
              <b>Resident</b> cases in last four weeks{" "}
            </span>
          </IonItem>
        )}

        {has(covidData, ConfirmedCovidCases.STAFF_LAST_WEEK) && (
          <IonItem lines="none" className="covid-item-background">
            <span className="covid-input-number">
              {" "}
              {covidData.confirmedStaffLastWeek}{" "}
            </span>
            <span className="covid-input-title">
              {" "}
              <b>Staff</b> cases last week{" "}
            </span>
          </IonItem>
        )}

        {has(covidData, ConfirmedCovidCases.STAFF_LAST_MONTH) && (
          <IonItem lines="none" className="covid-item-background">
            <span className="covid-input-number">
              {" "}
              {covidData.confirmedStaffLastMonth}{" "}
            </span>
            <span className="covid-input-title">
              {" "}
              <b>Staff</b> cases in last four weeks{" "}
            </span>
          </IonItem>
        )}
      </IonList>

      {/* covid supply section */}

      {covidWeeklySupply.length > 0 && (
        <IonListHeader className="ion-margin-top">
          <IonLabel
            color="text"
            className="ion-text-capitalize ion-no-margin shift-detail-title covid-list-header"
          >
            {" "}
            Has a week’s supply of:
          </IonLabel>
        </IonListHeader>
      )}

      <IonList class="ion-no-padding covid-list-background">
        {has(covidData, COVID19WeeklySupply.N95_MASKS) && (
          <IonItem lines="none" className="covid-item-background">
            {covidData.hasN95Masks ? (
              <img alt="N95-masks-status-checked" src={CheckedSvg} />
            ) : (
              <IonIcon icon={closeCircle} />
            )}
            <span className="covid-input-title"> N95 Masks </span>
          </IonItem>
        )}

        {has(covidData, COVID19WeeklySupply.SURGICAL_MASKS) && (
          <IonItem lines="none" className="covid-item-background">
            {covidData.hasSurgicalMasks ? (
              <img alt="surgical-masks-status-checked" src={CheckedSvg} />
            ) : (
              <IonIcon icon={closeCircle} />
            )}
            <span className="covid-input-title"> Surgical Masks </span>
          </IonItem>
        )}

        {has(covidData, COVID19WeeklySupply.EYE_PROTECTION) && (
          <IonItem lines="none" className="covid-item-background">
            {covidData.hasEyeProtection ? (
              <img alt="eye-protection-status-checked" src={CheckedSvg} />
            ) : (
              <IonIcon icon={closeCircle} />
            )}
            <span className="covid-input-title"> Eye Protection </span>
          </IonItem>
        )}

        {has(covidData, COVID19WeeklySupply.GOWNS) && (
          <IonItem lines="none" className="covid-item-background">
            {covidData.hasGowns ? (
              <img alt="gowns-status-checked" src={CheckedSvg} />
            ) : (
              <IonIcon icon={closeCircle} />
            )}
            <span className="covid-input-title"> Gowns </span>
          </IonItem>
        )}

        {has(covidData, COVID19WeeklySupply.SANITIZER) && (
          <IonItem lines="none" className="covid-item-background">
            {covidData.hasSanitizer ? (
              <img alt="hand-sanitizer-status-checked" src={CheckedSvg} />
            ) : (
              <IonIcon icon={closeCircle} />
            )}
            <span className="covid-input-title"> Hand Sanitizer </span>
          </IonItem>
        )}
      </IonList>
    </Fragment>
  );
};

export { CovidStatusCard };
