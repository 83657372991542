import { surgeryWorkerTypes } from "src/lib/constants";
import { ShiftName, SurgeryShiftName } from "src/lib/interface";
import { ShiftChip } from "src/lib/ionic-components";
import { get, groupBy, map } from "lodash";
import { Moment } from "moment";
import React, { Fragment } from "react";

import { groupByShiftName } from "../../store/shift";
import { ShiftDateMap } from "../../store/shift/model";

import { ShiftCountDateMap } from "../model";

interface ShiftCalendarDateChipsProps {
  date: Moment;
  openShiftMap?: ShiftCountDateMap;
  myShiftMap?: ShiftDateMap;
  qualification: string;
}

export function ShiftCalendarDateChips(props: ShiftCalendarDateChipsProps) {
  const day = props.date.format("YYYY-MM-DD");
  const hasOpenShift = (shiftName: string) => {
    const openShiftLength = get(
      props.openShiftMap?.[day] || {},
      `${shiftName}`,
      0
    );
    return openShiftLength > 0;
  };

  const myShifts = groupBy(props.myShiftMap?.[day] || [], groupByShiftName);
  const hasStaffedShift = (shiftName: string) => {
    const myShiftLength = get(myShifts, `${shiftName}.length`, 0);
    return myShiftLength > 0;
  };

  if (!props.myShiftMap && !props.openShiftMap) {
    return <Fragment />;
  }

  const shiftName = {
    ...(props.qualification && surgeryWorkerTypes.includes(props.qualification)
      ? SurgeryShiftName
      : ShiftName),
  };

  if (!props.qualification) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {map(shiftName, (name) => {
        return (
          <ShiftChip
            key={name}
            name={name}
            hasShift={{
              hasStaffedShift: hasStaffedShift(name),
              hasOpenShift: hasOpenShift(name),
            }}
          />
        );
      })}
    </Fragment>
  );
}
