import React from "react";
import { IonAlert } from "@ionic/react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { requestFullLocationAccess, getOS } from "./utils";
import { NoLocationPermissionBookingAlertProps } from "./model";
import "./style.scss";

const defaultMessage = {
  ios: "Please go to Settings > Privacy > Location Service and select Always Allow Location Access…",
  android:
    "Please go to Settings > Location > App access to Location > Clipboard Health app > and select Allow all the time Location Access…",
};

const NoLocationPermissionBookingAlert: React.FC<
  NoLocationPermissionBookingAlertProps
> = ({
  isOpen,
  onDidDismiss,
}: NoLocationPermissionBookingAlertProps): React.ReactElement => {
  const ldClient = useLDClient();
  const os = getOS();

  const noLocationBookingAlertMessage =
    ldClient?.variation("urgent-shifts-config")
      ?.noLocationBookingAlertMessage?.[os] || defaultMessage[os];

  const enableLocationClicked = () => {
    requestFullLocationAccess();
  };

  return (
    <IonAlert
      isOpen={isOpen}
      mode="ios"
      onDidDismiss={() => onDidDismiss()}
      header="Enable Location Permissions"
      cssClass="no-location-booking-alert"
      message={`
        <p>${noLocationBookingAlertMessage}</p>
      `} /** No need wrap above HTML inside a div */
      buttons={[
        {
          text: "Dismiss",
          role: "cancel",
        },
        {
          text: "Go to Settings",
          role: "confirm",
          handler: enableLocationClicked,
        },
      ]}
    />
  );
};

export default NoLocationPermissionBookingAlert;
