import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonAlert,
  IonToast,
} from "@ionic/react";
import moment from "moment-timezone";
import { InlineLoader } from "src/lib/ionic-components";
import { calendarOutline } from "ionicons/icons";
import { Calendar, Select, Col, Row, Button, Radio } from "antd";
import { Store } from "@store/store.model";
import { logEvent } from "src/lib/analytics";

import {
  fetchProofEarning,
  sendEmailProofEarning,
  createZendeskSupportTicketForProofEarning,
} from "../api";
import { USER_EVENTS } from "../../../constants/userEvents";
import { Agent } from "src/lib/interface";
import { showToast } from "@app/layout/toast";

const ProofIncome: React.FC = () => {
  const { email, userId } = useSelector(
    (state: Store) => state.session.agent as Agent
  );
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(moment().startOf("year").toDate());
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSupportScreen, setShowSupportScreen] = useState(false);
  const [html, setHtml] = useState("");
  const [selectStartDate, setSelectStartDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showEmailScreen, setShowEmailScreen] = useState(false);
  const [showDateSelectionErrorToast, setShowDateSelectionErrorToast] =
    useState(false);

  useEffect(() => {
    fetchProof();
  }, []);

  const fetchProof = async () => {
    setHtml("");
    setLoading(true);
    const proof = await fetchProofEarning({
      start: moment(fromDate).format("YYYY-MM-DD"),
      end: moment(toDate).format("YYYY-MM-DD"),
    });

    logEvent(USER_EVENTS.GENERATE_PROOF_INCOME, {
      startDate: moment(fromDate).format("YYYY-MM-DD"),
      endDate: moment(toDate).format("YYYY-MM-DD"),
      dateRange: moment(toDate).diff(moment(fromDate), "days"),
    });

    setLoading(false);
    setHtml(proof);
  };

  const sendEmail = () => {
    sendEmailProofEarning({
      start: moment(fromDate).format("YYYY-MM-DD"),
      end: moment(toDate).format("YYYY-MM-DD"),
    });

    logEvent(USER_EVENTS.EMAIL_PAYSTUB, {
      startDate: moment(fromDate).format("YYYY-MM-DD"),
      endDate: moment(toDate).format("YYYY-MM-DD"),
      dateRange: moment(toDate).diff(moment(fromDate), "days"),
      userId,
    });
  };

  const createZendeskTicket = async () => {
    const response = await createZendeskSupportTicketForProofEarning({
      start: moment(fromDate).format("YYYY-MM-DD"),
      end: moment(toDate).format("YYYY-MM-DD"),
    });

    if (response?.success) {
      logEvent(USER_EVENTS.NEED_HELP_PROOF_INCOME, {
        startDate: moment(fromDate).format("YYYY-MM-DD"),
        endDate: moment(toDate).format("YYYY-MM-DD"),
        dateRange: moment(toDate).diff(moment(fromDate), "days"),
      });
    } else {
      showToast(
        `Error while sending the proof of income, please contact support.`,
        3000,
        "bottom",
        "danger"
      );
    }
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle>Proof of Income</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className="bubbleContainer"
          style={{ margin: 8, padding: 8, paddingTop: 16 }}
        >
          <IonRow
            style={{ cursor: "pointer" }}
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <IonCol size="4">
              <h5>Start Date:</h5>
            </IonCol>
            <IonCol size="7">
              <h5>{moment(fromDate).format("MMM Do, YYYY")}</h5>
            </IonCol>
            <IonCol size="1">
              <IonIcon
                icon={calendarOutline}
                mode="ios"
                style={{ fontSize: "24px" }}
                color="primary"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <h5>End Date:</h5>
            </IonCol>
            <IonCol size="7">
              <h5>{moment(toDate).format("MMM Do, YYYY")}</h5>
            </IonCol>
          </IonRow>
        </div>
        {showCalendar && (
          <Calendar
            fullscreen={false}
            headerRender={({ value, onChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions: JSX.Element[] = [];

              const current = value.clone();
              const localeData = value.localeData();
              const months: string[] = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }

              for (let index = start; index < end; index++) {
                monthOptions.push(
                  <Select.Option
                    className="month-item"
                    key={`${index}`}
                    value={`${index}`}
                  >
                    {months[index]}
                  </Select.Option>
                );
              }
              const month = value.month();

              const year = value.year();
              const options: JSX.Element[] = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>
                );
              }
              return (
                <div style={{ padding: 8 }}>
                  <Row gutter={8}>
                    <Col>
                      <Radio.Group
                        size="small"
                        onChange={(e) =>
                          setSelectStartDate(e.target.value === "from")
                        }
                        value={selectStartDate ? "from" : "to"}
                      >
                        <Radio.Button value="from">From</Radio.Button>
                        <Radio.Button value="to">To</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={(newYear) => {
                          const now = value.clone().year(parseInt(newYear));
                          onChange(now);
                        }}
                        value={String(year)}
                      >
                        {options}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        onChange={(selectedMonth) => {
                          const newValue = value.clone();
                          newValue.month(parseInt(selectedMonth, 10));
                          onChange(newValue);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        if (moment(toDate).diff(moment(fromDate), "days") < 0) {
                          setShowDateSelectionErrorToast(true);
                          return;
                        }
                        setShowCalendar(false);
                        fetchProof();
                      }}
                    >
                      Confirm
                    </Button>
                  </Row>
                </div>
              );
            }}
            value={moment(selectStartDate ? fromDate : toDate)}
            onChange={(date) => {
              if (selectStartDate) {
                setFromDate(date.toDate());
              } else {
                setToDate(date.toDate());
              }
            }}
          />
        )}

        {loading && (
          <div style={{ textAlign: "center", marginTop: 8 }}>
            <InlineLoader loading={true} />
          </div>
        )}

        {html && (
          <div className="bubbleContainer" style={{ margin: 8, padding: 16 }}>
            <h5>
              {moment(fromDate).format("MMM Do, YYYY")} -{" "}
              {moment(toDate).format("MMM Do, YYYY")} Proof of Earnings
            </h5>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        )}
        <div style={{ padding: 16 }}>
          <IonRow>
            <IonCol>
              <IonButton
                color="primary"
                style={{ width: "100%" }}
                onClick={() => setShowEmailScreen(true)}
              >
                Email Me My Proof Earnings
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="danger"
                style={{ width: "100%" }}
                onClick={() => setShowSupportScreen(true)}
              >
                Is something wrong?
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
        <IonAlert
          isOpen={showEmailScreen}
          onDidDismiss={() => setShowEmailScreen(false)}
          header={"Email Your Proof of Earnings?"}
          message={`We'll send an email to ${email}`}
          backdropDismiss={true}
          buttons={[
            {
              text: "Never Mind",
              role: "cancel",
              handler: () => setShowEmailScreen(false),
            },
            {
              text: "Yes, send",
              handler: sendEmail,
            },
          ]}
        />
        <IonAlert
          isOpen={showSupportScreen}
          onDidDismiss={() => setShowSupportScreen(false)}
          header="Is something wrong"
          message="If there is something wrong with this document, please send it over email to our support team."
          backdropDismiss
          buttons={[
            {
              text: "Never Mind",
              role: "cancel",
              handler: () => setShowSupportScreen(false),
            },
            {
              text: "Yes, send",
              handler: createZendeskTicket,
            },
          ]}
        />
        <IonToast
          isOpen={showDateSelectionErrorToast}
          onDidDismiss={() => setShowDateSelectionErrorToast(false)}
          message="Please select a valid range."
          duration={10000}
          position="top"
        />
      </IonContent>
    </IonPage>
  );
};

export { ProofIncome };
