import { logEvent } from "src/lib/analytics";
import moment from "moment";
import { addDataToIdentity } from "src/lib/analytics";

import { USER_EVENTS } from "../../../constants";
import {
  DOCUMENT_EVENTS,
  ONBOARDING_SEGMENT_EVENT_NAMES,
  PERSONAL_INFO_EVENTS,
  INTERCOM_ATTR_MAP,
} from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";

interface OnbaordingFunnelData {
  email?: string;
  preference_distance?: number;
  phone?: string;
  hcpId: string;
  hcpEventValue?: string;
  requirementId?: string;
  licenseType?: string;
  licenseExpiration?: string;
  licenseState?: string;
  licenseNumber?: string;
}

const fireOnboardingSegmentEvent = (
  eventName: string,
  data: OnbaordingFunnelData
): void => {
  try {
    const { hcpId } = data;

    const timestamp = moment().toISOString();

    const {
      hcpEventValue,
      requirementId,
      licenseType,
      licenseExpiration,
      licenseState,
      licenseNumber,
    } = data;

    logEvent(eventName, {
      hcpId,
      ...(PERSONAL_INFO_EVENTS.includes(eventName) ? { hcpEventValue } : {}),
      ...(DOCUMENT_EVENTS.includes(eventName) ? { requirementId } : {}),
      ...(ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LICENSE_INFORMATION
        ? { licenseType, licenseExpiration, licenseState, licenseNumber }
        : {}),
      timestamp,
    });

    if (INTERCOM_ATTR_MAP[eventName]) {
      const payload = {};
      payload[INTERCOM_ATTR_MAP[eventName]] = new Date();
      addDataToIdentity(hcpId, payload);
    }
  } catch (error) {
    logEvent(USER_EVENTS.ONBOARDING_ERROR, {
      message: "Unable to fire segment log",
      eventName,
      agentId: data.hcpId,
    });
  }
};

export { fireOnboardingSegmentEvent };
