import React, {
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  useState,
} from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { SignatureContainerProps, SignatureContainerRef } from "./model";

const SignatureContainer = React.forwardRef<
  SignatureContainerRef,
  SignatureContainerProps
>(({ onChange, getHeight = () => NaN }, ref) => {
  const signaturePad = useRef<SignaturePad>(null);
  const [current, setCurrent] = useState<SignaturePad | null>(
    signaturePad.current
  );

  useLayoutEffect(() => {
    setCurrent(signaturePad.current);
    if (current === null) {
      return;
    }
    const onEndStroke = () => {
      onSigned();
    };
    const onBeginStroke = () => {
      current.handleResize();
    };

    current.instance.addEventListener("endStroke", onEndStroke);
    current.instance.addEventListener("beginStroke", onBeginStroke);

    return () => {
      current.instance.removeEventListener("endStroke", onEndStroke);
      current.instance.removeEventListener("beginStroke", onBeginStroke);
    };
  }, [current]);

  useImperativeHandle(ref, () => ({
    clearSignature() {
      current?.clear();
    },
  }));

  const onSigned = () => {
    if (current !== null) {
      const signature = current.toDataURL();
      if (!signature) return;
      onChange(signature);
    }
  };

  const canvasHeight = getHeight();

  return canvasHeight ? (
    <div style={{ height: canvasHeight }}>
      <SignaturePad ref={signaturePad} redrawOnResize height={canvasHeight} />
    </div>
  ) : (
    <SignaturePad ref={signaturePad} redrawOnResize />
  );
});

export { SignatureContainer };
