import { logApiFailureEvent } from "src/lib/analytics";
import request from "superagent";

import { environment } from "../../environments/environment";
import { getAuthHeader } from "../superagent";
import { RequestLastMinuteShiftsOptions } from "./model";
import { Response } from "../../utils/response";
import { logFailedApiRetry } from "../utils/api_retry";

const fetchLastMinuteShifts = async (
  options: RequestLastMinuteShiftsOptions
): Promise<Response> => {
  return await request
    .get(`${environment.baseUrl}/lastMinuteShifts`)
    .retry(1, (err) => {
      logFailedApiRetry(err, `/lastMinuteShifts`);
      return true;
    })
    .set(await getAuthHeader())
    .query(options)
    .then(({ body }) => body);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const instantPayStatus = async () => {
  try {
    const { body } = await request
      .get(`${environment.baseUrl}/agentprofile/instant-pay-status`)
      .retry(1, (err) => {
        logFailedApiRetry(err, `/agentprofile/instant-pay-status`);
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export { fetchLastMinuteShifts, instantPayStatus };
