import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonNote,
  IonRow,
} from "@ionic/react";
import moment from "moment-timezone";
import React from "react";

const ShiftTimelineItem = ({
  title,
  disabled,
  timeRange,
  helperText = "",
  facilityTimeZone,
}: {
  title: string;
  disabled: boolean;
  timeRange?: string | undefined;
  helperText?: string;
  facilityTimeZone: string;
}) => {
  const time: string = timeRange || "";
  const timeString = time
    ? moment(time).tz(facilityTimeZone).format("hh:mm A")
    : "--:--";

  const className = !disabled ? "payment-text-color" : "";
  const visibility = disabled ? "hidden" : "visible";

  return !disabled ? (
    <div className="hcp-time-line">
      <IonItem lines="none">
        <IonLabel className="ion-text-left hcp-action" style={{ visibility }}>
          {title}
        </IonLabel>
        <IonContent>
          <IonInput
            className="hcp-time"
            data-testid={`hcp-time-input-${title}`}
            value={timeString}
            style={{ visibility }}
            disabled
          ></IonInput>
          {helperText ? (
            <IonNote className="hcp-time-note" style={{ visibility }}>
              {helperText}
            </IonNote>
          ) : (
            ""
          )}
        </IonContent>
      </IonItem>
    </div>
  ) : null;
};

export { ShiftTimelineItem };
