export const DEFAULT_ERROR_MESSAGE =
  "Sorry, something went wrong. Please try again!";
export const STRIPE_ERROR_MESSAGE =
  "Could not start Stripe session. Please try again later!";
export const STRIPE_UPLOAD_CANCELLED = "You have cancelled Stripe upload";

export const DRIVING_LICENSE_STATE_ID_FILE_NAME =
  "driver_s_license_or_state_id";
export const DRIVING_LICENSE_STATE_ID_PLACEHOLDER_IMAGE_URL =
  "https://clipboardhealth.com/wp-content/uploads/2020/05/722082_CBHLogo_051520-05-300x121.png";
export const DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID =
  "5b932fcb38aeaa0014c3688f";

export const STRIPE_IDENTIFY_SDK = "STRIPE_IDENTIFY_SDK";
