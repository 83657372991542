import { IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import React from "react";
import { HiddenShiftsModalProps } from "./interface";
import "./style.scss";

export const HiddenShiftsModal: React.FC<HiddenShiftsModalProps> = ({
  isOpen,
  hiddenShifts,
  closeModal,
  openMinPayPreferenceModal,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      cssClass="distance-modal hidden-shifts-modal"
    >
      <IonContent id="distance-modal-container">
        <div className="distance-slider-container">
          <IonText className="modal-title">
            <h2>Hidden Shifts</h2>
          </IonText>
          <IonText className="modal-subtitle">
            <p>
              You&apos;re hiding {hiddenShifts} shifts with your current filter
              settings. Try changing your Minimum Pay to see more!
            </p>
          </IonText>
        </div>
        <div className="distance-modal-action-container">
          <IonButton
            expand="full"
            shape="round"
            fill="outline"
            style={{
              "--border-color": "#10B981",
              "--color": "#10B981",
            }}
            onClick={openMinPayPreferenceModal}
          >
            Update
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            style={{
              "--border-color": "#828282",
              "--color": "#828282",
              marginTop: "10px",
            }}
            fill="outline"
            onClick={() => closeModal?.()}
          >
            Dismiss
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
