import { ShiftCard } from "./shiftCard";
import React from "react";

const ShiftItem = ({ shift, onClickOnItemFacilityDetails }) => {
  return (
    <>
      <ShiftCard
        onClickOnItemFacilityDetails={onClickOnItemFacilityDetails}
        shift={shift}
      />
    </>
  );
};

export { ShiftItem };
