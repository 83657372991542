/*
  This class mocks the functionality of the NFCHelper functions. The mock functions is helpful while developing to bypass NFC check.
  This can be used in following scenario:
    1. To bypass actual NFC check and proceed to further flow while developing and testing using browser.
    2. To bypass actual NFC check and proceed with the phones that does not have NFC in it.
  How to use it?
    1. Change the constant USE_MOCKED_NFC_FOR_DEVELOPMENT in constant file to true to use this while developing.
    2. Use relevant hash in scanData.
    3. Please change it back to false once done with development.
*/
import { NFCReadStatus } from "src/lib/interface/src/lib/shift";

export class MockNFCHelper {
  static getReadStatus = (
    facilityNFCHash: string | undefined
  ): NFCReadStatus => {
    return NFCReadStatus.SUCCESS;
  };

  static deviceNFCCapabilityForShift() {
    return "NFC_ENABLED";
  }

  static cancelNFCScan() {}

  static async scanData() {
    return "CBH-$2b$10$G.pyhvucMcEemLkqDJUU.uUWGkdEZ9QtqfvWEXjMMuabcuMtoDdom";
  }

  static async readNFCTagInfo(
    isNFCExternalWriteEnabled: boolean,
    onSuccessRead: (tagData: string) => void
  ) {
    let tagData = "";
    tagData = await MockNFCHelper.scanData();
    if (tagData) onSuccessRead(tagData);
    return tagData;
  }
}
