import {
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { logEvent } from "src/lib/analytics";
import { Events } from "src/lib/constants";
import { getHumanReadableTag } from "src/lib/utils";
import { toastController } from "@ionic/core";
import { isPlatform } from "@ionic/react";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useHistory } from "react-router-dom";
import { USER_EVENTS } from "../../constants/userEvents";
import { segmentInstance } from "../../utils/cbhSegementHelper";
import { setToastStatus, setUnreadCount } from "../store/Notifications";
import { Store } from "../store/store.model";
import { recordImpression, updatePushToken } from "./api";
import { logErrors } from "../errorBoundary/api";
import { handleCommuteEvent } from "./helpers";
import { SegmentCapacitorPlugin } from "capacitor-segment-plugin";
import { FCM as FCMPlugin } from "@capacitor-community/fcm";
import { Plugins } from "@capacitor/core";

const { RemotePnPlugin } = Plugins;

const PushNotificationComponent: React.FC<{}> = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const ldClient = useLDClient();
  const featureEnabled = ldClient?.variation("urgent-shifts", false);

  const radarSdkConfig = ldClient?.variation("radar-sdk-config");

  const radarConfigsByPlatform = isPlatform("ios")
    ? radarSdkConfig?.ios
    : radarSdkConfig?.android;

  const { userId } = useSelector((state: Store) => state.session);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Checks if notification has expired
   * @param ttlSeconds Time to live in seconds. (string)
   * @param createdTimeUTC Created time of notification (ISO string)
   */
  const isNotificationExpired = (ttlSeconds, createdTimeUTC) => {
    if (!ttlSeconds || !createdTimeUTC) return false;

    if (typeof ttlSeconds === "string") ttlSeconds = parseInt(ttlSeconds, 10);

    return moment()
      .utc()
      .isAfter(moment(createdTimeUTC).add(ttlSeconds, "second"));
  };

  /**
   * Navigates user to the home page and shows
   * a toast message that notification has expired
   */
  const goToNotificationExpiredView = async () => {
    history.push("/home/openShifts");

    const toast = await toastController.create({
      message: "This notification has been expired",
      duration: 3000,
      position: "bottom",
    });
    await toast.present();
  };

  const onPushNotificationReceived = useCallback(
    async (payload: PushNotificationSchema) => {
      if (payload.data.commute) {
        await handleCommuteEvent(payload.data, radarConfigsByPlatform);
        return;
      }

      const notificationArr = [
        {
          msg: payload.body as string,
          link: payload.data.link,
          shiftId: payload.data.shiftId,
          messageId: payload.data.messageId,
          method: payload.data.method,
        },
      ];

      segmentInstance.track(
        userId?.toString() as string,
        Events.messageDelivered,
        {
          message: payload.body,
          message_name: `${getHumanReadableTag(payload.data.method).replace(
            " Notification",
            ""
          )} Notification`,
          to: "HCP",
          method: payload.data.method,
          channel: "push",
          notifyId: payload.data.messageId,
        } as any
      );
      const properties = {
        name: payload.data.method,
        medium: "Push",
        content: payload.body as string,
        source: "",
      };
      logEvent(USER_EVENTS.PUSH_NOTIFICATION_RECEIVED, properties);
      dispatch(setToastStatus(notificationArr, payload.id));
    },
    [radarConfigsByPlatform]
  );

  useEffect(() => {
    if (!isPlatform("capacitor")) return;
    PushNotifications.requestPermissions().then((result) => {
      setTimeout(() => {
        if (result.receive === "granted") {
          PushNotifications.register();
          setHasPermission(true);
        }
      }, 100);
    });
  }, []);

  useEffect(() => {
    if (!isPlatform("capacitor")) return;

    async function addListeners() {
      await PushNotifications.removeAllListeners();

      await PushNotifications.addListener("registration", onRegistration);

      await PushNotifications.addListener(
        "registrationError",
        onRegistrationError
      );

      await PushNotifications.addListener(
        "pushNotificationReceived",
        onPushNotificationReceived
      );

      await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        onPushNotificationActionPerformed
      );
    }

    addListeners();

    SegmentCapacitorPlugin.addListener("updateCountBadge", onUpdateUnreadCount);

    return () => {
      if (SegmentCapacitorPlugin.removeAllListeners) {
        SegmentCapacitorPlugin.removeAllListeners();
      }
    };
  }, [onPushNotificationReceived]);

  const onRegistration = ({ value: token }: Token) => {
    const existingToken = localStorage.getItem("pushToken");
    if (existingToken === token) {
      return;
    }

    localStorage.setItem("pushToken", token);
    updatePushToken(token);
    if (isPlatform("android")) SegmentCapacitorPlugin.sendPushToken({ token });
  };

  const onRegistrationError = (error: any) => {
    console.log("Error on registration: " + JSON.stringify(error));
  };

  const onUpdateUnreadCount = (info: any) => {
    dispatch(setUnreadCount(info.unreadCount));
  };

  const onPushNotificationActionPerformed = async (payload: any) => {
    if (payload && payload.notification) {
      const { id, data } = payload.notification;
      let { ttlSeconds, createdTimeUTC, link, method, messageId } = data;

      recordImpression(messageId, "push");

      segmentInstance.track(
        userId?.toString() as string,
        Events.messageOpened,
        {
          message_name: getHumanReadableTag(method),
          by: "Worker",
          message: payload.body,
          method: method,
          channel: "push",
          notifiyId: messageId,
        } as any
      );

      const isExpired = isNotificationExpired(ttlSeconds, createdTimeUTC);
      if (isExpired) {
        goToNotificationExpiredView();
      } else {
        history.push(link);
      }
    }
  };

  useEffect(() => {
    if (
      !featureEnabled ||
      !hasPermission ||
      !isPlatform("capacitor") ||
      !radarConfigsByPlatform
    ) {
      // Remove FCM instance
      // Plugin is undefined in web version
      FCMPlugin &&
        FCMPlugin.unsubscribeFrom({ topic: `commute-${userId}` })
          .then(() => console.log("deleted instance"))
          .catch((err) => {
            logErrors({
              message: `Could not unsubscribe to the topic commute-${userId}. ${JSON.stringify(
                err?.stack || err
              )}`,
              app: "hcpMobile",
            });
          });

      return;
    }

    if (isPlatform("ios") && RemotePnPlugin) {
      RemotePnPlugin.addListener("OnRemoteNotification", async (payload) => {
        await handleCommuteEvent(payload.data, radarConfigsByPlatform);
      });
    }

    FCMPlugin.subscribeTo({ topic: `commute-${userId}` })
      .then(() => console.log(`subscribed to topic`))
      .catch((err) => {
        logErrors({
          message: `Could not subscribe to the topic commute-${userId}. ${JSON.stringify(
            err?.stack || err
          )}`,
          app: "hcpMobile",
        });
      });
  }, [
    featureEnabled,
    hasPermission,
    handleCommuteEvent,
    radarConfigsByPlatform,
  ]);

  return <Fragment />;
};

export { PushNotificationComponent };
