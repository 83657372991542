import { IonCard, IonItem, IonLabel } from "@ionic/react";
import { openInAppBrowser } from "src/lib/ionic-components";
import { LearnMoreProps } from "./types";
import "./style.scss";

export const LearnMoreCard = ({ links, setErrorProps }: LearnMoreProps) => {
  const onClick = (url: string) => () => {
    if (url) {
      openInAppBrowser(url);
    } else {
      setErrorProps({
        isOpen: true,
        message: "Oops, the link you clicked can't be resolved",
        duration: 2500,
      });
    }
  };

  return (
    <IonCard class="ion-padding-vertical" data-testid="ratings-learn-more-card">
      <IonItem lines="none">
        <h1 className="ratings-card-title">Learn More</h1>
      </IonItem>
      <>
        {links.map(({ label, url }, index) => (
          <IonItem
            key={index}
            class="ion-margin-bottom"
            button
            detail
            onClick={onClick(url)}
          >
            <IonLabel>
              <h2 className="ratings-learn-more-label">{label}</h2>
            </IonLabel>
          </IonItem>
        ))}
      </>
    </IonCard>
  );
};
