/*
 * Polyfill stable language features.
 * It's recommended to use @babel/preset-env and browserslist
 * to only include the polyfills necessary for the target browsers.
 */
import "core-js/stable";
import "regenerator-runtime/runtime";

// @clipboard/utils require this polyfill to run w/o Node
// Mongoose require TextEncoder
// config as per main repo's frontend-hcpmobile/polyfill
if (typeof window["TextEncoder"] !== "function") {
  const TextEncodingPolyfill = require("text-encoding");
  (window as any).TextEncoder = TextEncodingPolyfill.TextEncoder;
  (window as any).TextDecoder = TextEncodingPolyfill.TextDecoder;
}

/**
 * This file contains polyfills loaded on all browsers
 **/
