import "./style.scss";
import moment from "moment-timezone";
import { actionRecordShiftTimeFailure } from "../../store/ongoingShifts";
import { actionRecordShiftTimeV2 } from "@store/ongoingShifts";
import { getHumanReadableTag } from "src/lib/utils";
import { getStageObject } from "./Stage";
import { logEvent } from "src/lib/analytics";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { Network } from "@capacitor/network";
import { SkipLunchButton } from "./skipLunch";
import { USER_EVENTS } from "../../../constants/userEvents";
import { checkLocationAwareness } from "../../utils/locationHelper";
import React, { useCallback, useEffect, useState } from "react";
import {
  Shift,
  GeoLocation,
  SHIFT_MARKED_NON_IP_REASONS,
  ShiftStages,
  TimeSystems,
  DeviceNFCCapabilityForShift,
} from "src/lib/interface";
import { useDispatch } from "react-redux";
import { geoDistance } from "src/lib/utils";
import {
  AppType,
  ConnectionMode,
  LocationAlertType,
  LocationAlertTypeReason,
  LocationType,
  getOrWatchCurrentLocation,
} from "@app/common/location";
import {
  IonButton,
  IonFooter,
  IonLabel,
  IonSpinner,
  isPlatform,
  IonToast,
} from "@ionic/react";
import { formatRate } from "../../hcpShifts/helper";
import { makeInstantpayLogParameters } from "../../../utils/logEvent";
import { ShiftDetailsAlerts } from "../../hcpShifts/shiftDetails/alerts";
import { useAppSelector } from "@store/index";
import { api } from "@app/api";
import { NfcScannerModal } from "src/app/hcpShifts/components/nfcScanner";
import { deviceNFCCapabilityForShift } from "src/app/hcpShifts/components/nfc/nfcHelper";
import { NFC_FAIL_STATUS } from "src/app/hcpShifts/constants";

const FACILITY_SAFE_DISTANCE_MILES = 0.75;

interface InstantShiftButtonProps {
  shift: Shift;
  uploadTimeSheet: (boolean?: boolean) => void;
  onClickNoTimeSheet?: () => void;
  loadShiftDetails?: any;
  shiftDetailsAlerts: ShiftDetailsAlerts;
  isNewTimeSheetEnabled: boolean;
  isSignatureSubmission: boolean;
  isNFCEnabled: boolean;
  isDigitalSignatureEnabled: boolean;
  isRefactoredVersionEnabled: boolean;
  isNFCExternalWriteEnabled: boolean;
}

const InstantShiftButton: React.FC<InstantShiftButtonProps> = ({
  shift,
  loadShiftDetails,
  uploadTimeSheet,
  onClickNoTimeSheet,
  shiftDetailsAlerts,
  isNewTimeSheetEnabled,
  isSignatureSubmission,
  isNFCEnabled,
  isDigitalSignatureEnabled,
  isRefactoredVersionEnabled,
  isNFCExternalWriteEnabled,
}) => {
  const { agent = {}, env } = useAppSelector((state) => state.session);
  const dispatch = useDispatch();
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [isSkipLunch, setIsSkipLunch] = useState<boolean>(false);
  const [clockActionLoading, setClockActionLoading] = useState<boolean>(false);
  const [skipActionLoading, setSkipActionLoading] = useState<boolean>(false);
  const [isClockInEnabled, setIsClockInEnabled] = useState<boolean>(false);
  const { currentStage, currentStageText, currentStageLog } = getStageObject(
    shift,
    isNewTimeSheetEnabled && isSignatureSubmission
  );
  const [disableClockButtonAction, setdisableClockButtonAction] =
    useState(false);

  const [showScanner, setShowScanner] = useState<boolean>(false);
  const isNFCCheckNeeded =
    isNFCEnabled && currentStage !== ShiftStages.SHIFT_TIME_DONE;

  const isShiftCanForceClockOut =
    shift &&
    moment().isAfter(
      moment(shift.start).add(env!.minInstantpayShiftWorkTime, "minutes")
    );
  const disabledClockButton =
    currentStage === ShiftStages.CLOCK_OUT
      ? !isShiftCanForceClockOut
      : !isClockInEnabled;

  const remainingAmount = 0;

  useEffect(() => {
    const limit = env?.minBeforeShiftStart;
    const diff = moment(shift.start).subtract(limit, "minutes").diff(moment());
    setIsClockInEnabled(diff <= 0);
    const timeout = setTimeout(() => {
      setIsClockInEnabled(true);
    }, diff);
    return () => clearTimeout(timeout);
  }, [env, shift]);

  const openLocationSettings = async () => {
    if (isPlatform("capacitor")) {
      await OpenNativeSettings.open("location");
    } else {
      setTimeout(() => {
        shiftDetailsAlerts.alertBrowserLocationAccess();
      }, 500);
    }
  };

  const closeNFCScanner = () => {
    if (isSkipLunch) setIsSkipLunch(false);
    setShowScanner(false);
    setdisableClockButtonAction(false);
  };

  const openNFCScanner = () => {
    setShowScanner(true);
  };

  const skipLunchScanInNFC = () => {
    setIsSkipLunch(true);
    openNFCScanner();
  };

  const updateStage =
    (
      id: string,
      shift: Shift,
      stage: ShiftStages,
      setActionLoading: (boolean) => void
    ) =>
    async () => {
      if (stage === ShiftStages.SHIFT_TIME_DONE) {
        setdisableClockButtonAction(false);
        uploadTimeSheet();
        return;
      }

      setActionLoading(true);
      const humanReadableStage = getHumanReadableTag(stage);
      const {
        location,
        error: positionError,
        errorDetails: positionErrorDetails,
      } = await getOrWatchCurrentLocation();
      if (positionError) {
        const failureReason = [
          `Failed to ${humanReadableStage} to shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}.`,
          `Reason: ${LocationAlertTypeReason[positionError]}.`,
          `Error details: ${positionErrorDetails || "N/A"}`,
        ].join(" ");

        dispatch(
          actionRecordShiftTimeFailure(
            failureReason,
            agent,
            shift._id as string
          )
        );

        shiftDetailsAlerts.alertLocationAccess({
          openLocationSettingsFn: openLocationSettings,
          skipLocationBtnHandler: convertToNonInstantPayNoLocation,
        });
        setActionLoading(false);
        setdisableClockButtonAction(false);
        return;
      }

      const { coordinates } = shift.facility?.geoLocation as GeoLocation;
      const distance = geoDistance(coordinates, location as number[]);
      const isLocationAware = checkLocationAwareness(
        shift?.facility?.locationAwareness as string
      );
      const safeDistanceFromFacility =
        env?.facilitySafeDistanceMiles || FACILITY_SAFE_DISTANCE_MILES;

      if (isLocationAware && distance > safeDistanceFromFacility) {
        const failureReason = `Failed to ${humanReadableStage} to a shift that starts
       on ${shift.start} and ends on ${shift.end} from facility ${
          shift.facility?.name
        }
       because ${LocationAlertTypeReason[LocationAlertType.NOT_CLOSE]}. Facility
       is at Long/Lat: ${coordinates} and user is at Long/Lat ${location} and the
       distance is ${distance} miles, which is not within the safe facility distance
       of ${safeDistanceFromFacility} miles`;

        dispatch(
          actionRecordShiftTimeFailure(
            failureReason,
            agent,
            shift._id as string
          )
        );
        shiftDetailsAlerts.alertReturnToTheFacility({
          facilityName: shift.facility?.name,
          tryAgainBtnHandler: async () => {
            await updateStage(id, shift, stage, setActionLoading)();
          },
          skipLocationBtnHandler: convertToNonInstantPayNoLocation,
        });
        setActionLoading(false);
        setdisableClockButtonAction(false);
        return;
      }

      const status = await Network.getStatus();
      const connectivityMode = status.connected
        ? ConnectionMode.ONLINE
        : ConnectionMode.OFFLINE;
      if (
        stage === ShiftStages.CLOCK_OUT &&
        connectivityMode === ConnectionMode.OFFLINE
      ) {
        shiftDetailsAlerts.alertNoNetworkConnection({});
      }
      await dispatch(
        actionRecordShiftTimeV2(
          id,
          stage,
          location as number[],
          shift,
          "",
          LocationType.LIVE,
          isPlatform("capacitor") ? AppType.MOBILE : AppType.WEB,
          connectivityMode,
          () => {
            loadShiftDetails(shift._id);
            setdisableClockButtonAction(false);
            setActionLoading(false);
          }
        )
      );
      logEvent(
        USER_EVENTS[currentStageLog],
        makeInstantpayLogParameters(shift, shift.isInstantPay, null)
      );
      setdisableClockButtonAction(false);
      setActionLoading(false);
      if (
        stage === ShiftStages.CLOCK_OUT &&
        connectivityMode === ConnectionMode.ONLINE
      )
        showUploadNowOrLaterModal();
    };
  const uploadTimeSheetFromAlert = () => uploadTimeSheet(true);

  const convertToNonInstantPayNoLocation = async () => {
    try {
      await api.shift.markShiftAsNonInstantPay(
        shift._id as string,
        SHIFT_MARKED_NON_IP_REASONS.NO_LOCATION
      );
      await loadShiftDetails(shift._id);
    } catch (error) {
      setToastErrorMessage(error?.response?.body?.error || `Unknown error`);
    }
  };

  if (!shift?.isInstantPay) return null;

  if (
    currentStage === ShiftStages.SHIFT_TIME_DONE &&
    !(
      shift.facility?.verificationPreference?.usesTimesheets ||
      shift.facility?.requireTimecardPhoto
    )
  ) {
    return null;
  }

  const showUploadNowOrLaterModal = () => {
    if (isRefactoredVersionEnabled) {
      if (!isNewTimeSheetEnabled || !isDigitalSignatureEnabled) {
        shiftDetailsAlerts.alertUploadTimesheet({
          formattedRemainingAmount: formatRate(remainingAmount || 0),
          is100InstantPayEnabled:
            shift.instantPayDetails?.is100InstantPayEnabled,
          uploadTimesheetFn: uploadTimeSheetFromAlert,
        });
      }
    } else {
      if (shift.facility?.timeSystem === TimeSystems.PAPER) {
        shiftDetailsAlerts.alertUploadTimesheet({
          formattedRemainingAmount: formatRate(remainingAmount || 0),
          is100InstantPayEnabled:
            shift.instantPayDetails?.is100InstantPayEnabled,
          uploadTimesheetFn: uploadTimeSheetFromAlert,
        });
      }
    }
  };
  return (
    <IonFooter>
      {showScanner && (
        <NfcScannerModal
          shift={shift}
          modalTitle={""}
          showNFCScanner={showScanner}
          currentStage={isSkipLunch ? ShiftStages.SKIP_LUNCH : currentStage}
          closeNFCScanner={closeNFCScanner}
          loadShiftDetails={loadShiftDetails}
          getFacilitySignature={uploadTimeSheet}
          onNFCValidSuccess={closeNFCScanner}
          showUploadNowOrLaterModal={showUploadNowOrLaterModal}
          isRefactoredVersionEnabled={isRefactoredVersionEnabled}
          isNFCExternalWriteEnabled={isNFCExternalWriteEnabled}
        ></NfcScannerModal>
      )}
      {currentStage === ShiftStages.SHIFT_TIME_DONE && !isSignatureSubmission && (
        <IonLabel className="no-timesheet-select" onClick={onClickNoTimeSheet}>
          I don’t have a timesheet
        </IonLabel>
      )}
      <SkipLunchButton
        stage={currentStage}
        onClick={
          isNFCCheckNeeded
            ? skipLunchScanInNFC
            : updateStage(
                shift._id as string,
                shift,
                ShiftStages.SKIP_LUNCH,
                setSkipActionLoading
              )
        }
        loading={skipActionLoading}
      />
      <IonButton
        data-testid="shift-clock-button"
        size="large"
        expand="block"
        shape="round"
        color="primary"
        disabled={disabledClockButton || disableClockButtonAction}
        className="ion-margin"
        onClick={() => {
          setdisableClockButtonAction(true);
          isNFCCheckNeeded
            ? openNFCScanner()
            : updateStage(
                shift._id as string,
                shift,
                currentStage,
                setClockActionLoading
              )();
        }}
      >
        {clockActionLoading && <IonSpinner name="crescent" />}
        {currentStageText}
      </IonButton>
      <IonToast
        isOpen={!!toastErrorMessage}
        onDidDismiss={() => setToastErrorMessage("")}
        message={toastErrorMessage}
        duration={5000}
        color="danger"
        position="top"
      />
    </IonFooter>
  );
};

export { InstantShiftButton };
