export * from "./userEvents";
export * from "./shift";

export const DISTANCE_PREFERENCE_MAX_VALUE = 150;

export const CHECKR_REQUIREMENT_ID = "5b9327c238aeaa0014c3688d";

export const STATE_LICENSE_REQUIREMENT_ID = "5be9750b06c86c0004a1deca";

export const SHIFT_PREFERENCE = {
  DISTANCE: 150,
  PAY_HOUR: 0,
  PAY_SHIFT: 0,
  BASE_RATE: 17,
};

export const SEARCH_MODE = {
  CALENDAR: "CALENDAR",
  DATE: "DATE",
  TIME_SLOT: "TIME_SLOT",
  MAP: "MAP",
  HCF_PROFILE: "HCF_PROFILE",
  URGENT_SHIFTS: "URGENT_SHIFTS",
};

export const FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS = 30;

export const REFERRALS = {
  REFER_A_FRIEND: "Refer a Friend",
};

export const MINIMUM_PAY_HIDDEN_SHIFTS_MODAL_FREQUENCY = {
  NEVER: "NEVER",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};
