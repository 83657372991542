const workerMobilePrefix = "Worker Mobile:";

const ONBOARDING_SEGMENT_EVENT_NAMES = {
  CONTINUED_SIGNUP: `${workerMobilePrefix} Signup Continued`,
  RESTARTED_SIGNUP: `${workerMobilePrefix} Onboarding - Signup Restarted`,
  ENTERED_FIRST_NAME: `${workerMobilePrefix} Onboarding - First Name Entered`,
  ENTERED_LAST_NAME: `${workerMobilePrefix} Onboarding - Last Name Entered`,
  ENTERED_EMAIL: `${workerMobilePrefix} Onboarding - Email Entered`,
  ENTERED_ADDRESS: `${workerMobilePrefix} Onboarding - Address Entered`,
  PROCEEDED_TO_PROFESSIONAL_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Professional Info`,
  ENTERED_LICENSE_INFORMATION: `${workerMobilePrefix} Onboarding - License Info Entered`,
  PROCEEDED_TO_PAY_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Pay Info`,
  VIEWED_CONTRACTOR_BENEFITS: `${workerMobilePrefix} Onboarding - Contractor Benefits Viewed`,
  PROCEEDED_TO_REQUIREMENTS_INFO: `${workerMobilePrefix} Onboarding - Proceeded to Requirements Info`,
  ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP: `${workerMobilePrefix} Onboarding - Document Add Attempted`,
  ADDED_DOCUMENT_DURING_SIGNUP: `${workerMobilePrefix} Onboarding - Document Added`,
  FINISHED_SUBMITTING_DOCUMENTS: `${workerMobilePrefix} Submitting Documents Finished`,
  ENTERED_APP: `${workerMobilePrefix} App Entered`,
  ENTERED_SSN: `${workerMobilePrefix} Onboarding - SSN Entered`,
  SELECTED_EXAMPLE_SHIFT: `${workerMobilePrefix} Example Shifts Selected`,
  ENTERED_DOB: `${workerMobilePrefix} Onboarding - DoB Entered`,
};

const PERSONAL_INFO_EVENTS = [
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_FIRST_NAME,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LAST_NAME,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL,
  ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS,
];

const DOCUMENT_EVENTS = [
  ONBOARDING_SEGMENT_EVENT_NAMES.ATTEMPTED_TO_ADD_DOCUMENT_DURING_SIGNUP,
  ONBOARDING_SEGMENT_EVENT_NAMES.ADDED_DOCUMENT_DURING_SIGNUP,
];

const INTERCOM_ATTR_MAP = {
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_FIRST_NAME]: `selfob_entered_first_name_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LAST_NAME]: `selfob_entered_last_name_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_EMAIL]: `selfob_entered_email_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_ADDRESS]: `selfob_entered_address_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.PROCEEDED_TO_PROFESSIONAL_INFO]: `selfob_proceed_to_professional_info_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_LICENSE_INFORMATION]: `selfob_entered_license_information_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.VIEWED_CONTRACTOR_BENEFITS]: `selfob_viewed_contractor_benefits_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.PROCEEDED_TO_REQUIREMENTS_INFO]: `selfob_proceed_to_requirements_info_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.FINISHED_SUBMITTING_DOCUMENTS]: `selfob_finished_submitted_docs_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_APP]: `selfob_entered_app_at`,
  [ONBOARDING_SEGMENT_EVENT_NAMES.ENTERED_SSN]: `selfob_entered_ssn_at`,
};

export {
  ONBOARDING_SEGMENT_EVENT_NAMES,
  PERSONAL_INFO_EVENTS,
  DOCUMENT_EVENTS,
  INTERCOM_ATTR_MAP,
};
