import { useFlags } from "launchdarkly-react-client-sdk";
import { isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  book,
  medkit,
  receipt,
  school,
  send,
  helpOutline,
  chatbox,
} from "ionicons/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Store } from "../store/store.model"; /* eslint-disable-line import/extensions */
import { PageMetaMap, Pages } from "./constants";
import "./style.scss";
import { SegmentCapacitorPlugin } from "capacitor-segment-plugin";

declare global {
  interface Window {
    Intercom: any;
  }
}

const NeedHelpPage = () => {
  const history: any = useHistory();
  const match: any = useRouteMatch();
  const isCapacitor = isPlatform(window, "capacitor");
  const agent = useSelector((state: Store) => state.session.agent);
  const ldFlags = useFlags();
  const enableZendeskHelpCenter = ldFlags["enable-zendesk-help-center"];

  if (!agent) {
    return <IonSpinner color="light" />;
  }

  const handleHelp = (page: Pages) => {
    const pageMeta: any = PageMetaMap.get(page) || {};

    if (page === Pages.QUESTION) {
      invokeIntercom();
    } else if (page === Pages.TRAINING) {
      history.push(`${match.url}/${pageMeta.path}`);
    } else if (page === Pages.CONTACT) {
      invokeIntercom();
    } else if (page === Pages.ASSESSMENT) {
      if (!pageMeta.urls[agent.qualification || ""]) {
        return;
      }

      history.push(`${match.url}/details`, {
        title: pageMeta.title,
        url: pageMeta.urls[agent.qualification || ""],
      });
    } else if (pageMeta.url) {
      history.push(`${match.url}/details`, pageMeta);
    }
  };

  const invokeIntercom = () => {
    if (isCapacitor) {
      SegmentCapacitorPlugin.showMessenger();
    } else {
      window.Intercom("showNewMessage");
    }
  };

  const openZendesk = async () => {
    window.zE("messenger", "open");
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>Help Center</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.TERMS)}
            detail
          >
            <IonIcon className="listIcons" icon={book} />
            <IonLabel>
              <p>The contractor role & how we work together</p>
              <h2>Terms of Service</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.TRAINING)}
            detail
          >
            <IonIcon className="listIcons" icon={school} />
            <IonLabel>
              <p>Take a refresher course on best practices </p>
              <h2>Training Resources</h2>
            </IonLabel>
          </IonItem>
          <IonItem
            className="help-item"
            onClick={() => handleHelp(Pages.ASSESSMENT)}
            detail
          >
            <IonIcon className="listIcons" icon={medkit} />
            <IonLabel>
              <p>Show us what you know!</p>
              <h2>Skills Assessment</h2>
            </IonLabel>
          </IonItem>
          {enableZendeskHelpCenter ? (
            <React.Fragment>
              <IonItem className="help-item" onClick={openZendesk} detail>
                <IonIcon className="listIcons" icon={helpOutline} />
                <IonLabel>
                  <p>Read help articles and contact us</p>
                  <h2>Leave a message</h2>
                </IonLabel>
              </IonItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IonItem
                className="help-item"
                onClick={() => handleHelp(Pages.QUESTION)}
                detail
              >
                <IonIcon className="listIcons" icon={receipt} />
                <IonLabel>
                  <p>Read help articles written by our team</p>
                  <h2>Get Help</h2>
                </IonLabel>
              </IonItem>
              <IonItem
                className="help-item"
                onClick={() => handleHelp(Pages.CONTACT)}
                detail
              >
                <IonIcon className="listIcons" icon={send} />
                <IonLabel>
                  <p>Still have questions after reading our articles?</p>
                  <h2>Contact Us</h2>
                </IonLabel>
              </IonItem>
            </React.Fragment>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NeedHelpPage;
