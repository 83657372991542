const ActionType = {
  SET_TOAST: "SET_TOAST",
  CHANGE_TOAST_BOOLEAN: "CHANGE_TOAST_BOOLEAN",
  REMOVE_TOAST: "REMOVE_TOAST",
  SET_PUSH_NOTIFICATION_PREFERENCE: "SET_PUSH_NOTIFICATION_PREFERENCE",
  SET_UNREAD_COUNT: "SET_UNREAD_COUNT",
};

interface ToastMessage {
  msg: string;
  link: string;
  shiftId?: string;
  messageId: string;
  method: string;
}

interface ToastSettings {
  status: false;
  msg: ToastMessage[];
  messageId: string | null;
}

interface NotificationSettings extends ToastSettings {
  isPushSet: boolean;
  unreadCount: number;
}

export { ActionType, NotificationSettings, ToastMessage };
